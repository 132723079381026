/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux"
import { usuariosServicesState } from "../../slices/usuariosServicesSlice"
import { useEffect, useState } from "react";
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { AreasExperienciaForm } from "../../app/forms/registro/datos-basicos";
import InputComp from "../generales/inputComponent";
import { deleteAreaExperiencia, postAreaExperiencia } from "../../services/entidad";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { fillForm, formatForm, validateForm } from "../../app/commons";
import { ALERT_FAIL, ALERT_SUCCESS, CVE_PROGRAMAS } from "../../app/constantes";
import { getConsultorData, registroConsultorState } from "../../slices/registroConsultor";
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { getCatalogos } from "../../services/catalogos";
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';

export default function AreasExperienciaConsultor({onlyView}) {
    const dispatch = useDispatch()
    const appSt = useSelector(appState)
    const userSt = useSelector(usuariosServicesState)
    const consultorSt = useSelector(registroConsultorState)
    const catalogosSt = useSelector(catalogosServiceState)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(AreasExperienciaForm)))
    const [ambitos, setAmbitos] = useState([])
    const [subambitos, setSubambitos] = useState([])
    const [criterios, setCriterios] = useState([])
    const [isDisabled, setDisabled] = useState(false)

    const handleSubmit = async () => {
        const formValid = validateForm(form)
        if (formValid.length === 0) {
            const formatedForm = formatForm(form)
            await postAreaExperiencia({
                ...appSt.serviceData,
                body: {
                    cveSubambito: formatedForm.cveSubambito, 
                    cveTamanio: formatedForm.cveTamanio, 
                    idConsultorAcreditado: userSt.persona.idPersona,
                }
            })
            dispatch(setAlert({
                show: true,
                message: "Área agregada",
                type: ALERT_SUCCESS
            }))
            dispatch(getConsultorData({
                ...appSt.serviceData,
                idConsultorAcreditado: userSt.persona.idPersona
            }))
        } else {
            dispatch(setAlert({
                show: true,
                message: "Favor de llenar los campos requeridos",
                type: ALERT_FAIL
            }))
        }
    }

    const deleteItem = async (registro) => {
        dispatch(setLoading(true))
        try {
            await deleteAreaExperiencia({
                ...appSt.serviceData,
                idAreaExperienciaConsultor: registro.idAreaExperienciaConsultor
            })
            dispatch(setLoading(false))
            dispatch(setAlert({
                show: true,
                message: "Área eliminada",
                type: ALERT_SUCCESS
            }))
            dispatch(getConsultorData({
                ...appSt.serviceData,
                idConsultorAcreditado: userSt.persona.idPersona
            }))
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))    
            dispatch(setAlert({
                show: true,
                message: "Error al eliminar área",
                type: ALERT_FAIL
            }))        
        }
    }

    const getAmbitos = async () => {
        dispatch(setLoading(true))
        try {
            const resp = await getCatalogos(appSt.serviceData.area, 'ambito', appSt.serviceData.token, form.cveCriterio.value)
            dispatch(setLoading(false))
            setAmbitos(resp)
        } catch (error) {
            dispatch(setLoading(false))
            console.log(error)
        }

    }

    const getSubambitos = async () => {
        dispatch(setLoading(true))
        try {
            const resp = await getCatalogos(appSt.serviceData.area, 'subAmbito', appSt.serviceData.token, form.cveAmbito.value)
            dispatch(setLoading(false))
            setSubambitos(resp)
        } catch (error) {
            dispatch(setLoading(false))
            console.log(error)
        }

    }

    useEffect(() => {
        if (consultorSt.consultorData) {
            const clone = fillForm(form, consultorSt.consultorData)
            setForm(clone)
            setDisabled(consultorSt.consultorData.cemAreaExperienciaConsultors.length >= 3)
        }
    }, [consultorSt.consultorData])

    useEffect(() => {
        if (form.cveCriterio.value) {
            getAmbitos()
            setSubambitos([])
        } else {
            setAmbitos([])
            setSubambitos([])
        }
    }, [form.cveCriterio])

    useEffect(() => {
        if (form.cveAmbito.value) {
            getSubambitos()
        } else {
            setSubambitos([])
        }
    }, [form.cveAmbito])

    useEffect(() => {
        if (catalogosSt.criterios) {
            console.log(catalogosSt.criterios)
            const filter = catalogosSt.criterios.filter(item => item.cveCriterio !== CVE_PROGRAMAS.cv && item.cveCriterio !== CVE_PROGRAMAS.entidadesPromotoras)
            setCriterios(filter)
        }
    }, [catalogosSt.criterios])

    return (<section>
        <div className="sub-section">
            <h4>Áreas de Experiencia</h4>
            <div className="tip">
                <InfoIcon />
                <p>Favor de agregar 3 Áreas de Experiencia.</p>
            </div>
            {!onlyView && (<>
                <div className="four-col">
                    {criterios && <>
                        <InputComp 
                            label="Criterio" 
                            name="cveCriterio" 
                            type="select" 
                            catalogos={criterios} 
                            catalogoLabel={"descCriterio"} 
                            catalogoValue={"idCriterio"} 
                            form={form} 
                            setForm={setForm} 
                        />
                        <InputComp 
                            label="Ámbito" 
                            name="cveAmbito" 
                            type="select" 
                            disabled={ambitos.length === 0}
                            catalogos={ambitos} 
                            catalogoLabel={"descAmbito"} 
                            catalogoValue={"idAmbito"} 
                            form={form} 
                            setForm={setForm} 
                        />
                        <InputComp 
                            label="Subámbito" 
                            name="cveSubambito" 
                            type="select" 
                            catalogos={subambitos} 
                            disabled={subambitos.length === 0}
                            catalogoLabel={"descSubambito"} 
                            catalogoValue={"cveSubambito"} 
                            form={form} 
                            setForm={setForm} 
                        />
                    </>
                    }
                    {catalogosSt.tamanioSectorSe && 
                        <InputComp 
                            label="Tamaño" 
                            name="cveTamanio" 
                            type="select" 
                            catalogos={catalogosSt.tamanioSectorSe} 
                            catalogoLabel={"descTamanio"} 
                            catalogoValue={"cveTamanio"} 
                            form={form} 
                            setForm={setForm} 
                        />}
                </div>
                <div className="actions">
                    <button disabled={isDisabled} onClick={handleSubmit}>Agregar</button>
                </div>
            </>)}

            <div className="list">
                <table>
                    <thead>
                        <tr>
                            <th>Criterio</th>
                            <th>Ámbito</th>
                            <th>Subámbito</th>
                            <th>Tamaño</th>
                            {!onlyView &&
                                <th>Acciones</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {consultorSt.consultorData && consultorSt.consultorData.cemAreaExperienciaConsultors && consultorSt.consultorData.cemAreaExperienciaConsultors.map((item, key) => {
                            return <tr key={key}>
                                <td>{item.descCriterio}</td>
                                <td>{item.descAmbito}</td>
                                <td>{item.descSubambito}</td>
                                <td>{item.descTamanio}</td>
                                {!onlyView &&
                                    <td>
                                        <button onClick={() => deleteItem(item)} className="small-icon-btn tooltip" data-tool="Eliminar">
                                            <DeleteIcon />
                                        </button>
                                    </td>
                                }
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </section>)
}