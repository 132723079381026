import { getRequest, putRequest, postRequest, deleteRequest, getFileRequest } from './http-service';
const url = process.env.REACT_APP_CURSO_SERVICE;
const urlFactura = process.env.REACT_APP_COBROS_SERVICE;

export const obtenerCursosPorEntidades = async (data, body, filter) => {
    const requestUrl = `${url}/area/${data.area}/curso/obtenerCursosPorEntidad/${body.idEntidad}?`;
    const searchParams = new URLSearchParams(filter);
    // const searchParams = new URLSearchParams(body);
    const resp = await getRequest(requestUrl + searchParams, data.token);
    if (resp.error && resp.error.length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.error[0].descripcionError,
        };
    }
    return resp.payload;
};

export const obtenerCursosCobrosPorEntidad = async (data, body, filter) => {
    const requestUrl = `${url}/area/${data.area}/curso/obtenerCursosCobrosPorEntidad/${body.idEntidad}?`;
    const searchParams = new URLSearchParams(filter);
    const resp = await getRequest(requestUrl + searchParams, data.token);
    if (resp.error && resp.error.length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.error[0].descripcionError,
        };
    }
    return resp.payload;
};

export const registrarEntidadesCursos = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/curso/asignarCursos`;
    const resp = await postRequest(requestUrl, data.token, { payload: body });
    if (resp.error && resp.error.length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.error[0].descripcionError,
        };
    }
    return resp;
}

export const actualizarCursosEntidades = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/curso/actualizarCursosEntidad`;
    const resp = await putRequest(requestUrl, data.token, { payload: body });
    if (resp.error && resp.error.length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.error[0].descripcionError,
        };
    }
    return resp;
}

export const registrarCursosCobros = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/curso/registrarCursosCobros`;
    const resp = await postRequest(requestUrl, data.token, { payload: body });
    if (resp.error && resp.error.length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.error[0].descripcionError,
        };
    }
    return resp;
}

export const getCobroCursosComprobantes = async (data) => {
    const requestUrl = `${url}/area/${data.area}/curso/${data.idEntidad}/documento-comprobante-pago${data.idConvocatoria ? `?idConvocatoria=${data.idConvocatoria}` : ""}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getCursosCobros = async (data) => {
    const requestUrl = `${url}/area/${data.area}/curso/${data.idEntidad}/resumenpagos/cursos${data.idConvocatoria ? `?idConvocatoria=${data.idConvocatoria}` : ""}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putCobroCursoAplicado = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/curso/cobro-curso-aplicado/operacion`;
    const resp = await putRequest(requestUrl, data.token, {payload: body});
    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }
    return resp.payload;
};
export const putCursoReferenciaPasarela = async (data) => {
    const requestUrl = `${url}/area/${data.area}/curso/cobro-curso/${data.idCobroCurso}/pasarela`;
    const resp = await putRequest(requestUrl, data.token, {payload: data.body});
    if (resp.error && resp.error.length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.error[0].descripcionError,
        };
    }
    return resp;
};
export const putCursoCobro = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/curso/cobro-curso/operacion/${body.idCobroCurso}/${body.idCobroCursoAplicado}`;

    const resp = await putRequest(requestUrl, data.token, { payload: body });

    if (resp.payload.length === 0 || (resp.error && resp.error.length > 0)) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area: data.area, idEntidad: data.idEntidad, body },
            payload: resp.payload,
        };
    }

    return resp.payload;
};
export const postCobroCursoAplicadoDocumento = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/curso/documento-cobro-curso/operacion`;
    const resp = await postRequest(requestUrl, data.token, {payload: body});
    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }
    return resp.payload;
};

export const deleteCobroCursoAplicadoDocumento = async (data) => {
    const requestUrl = `${url}/area/${data.area}/curso/documento-cobro-curso/${data.idDocumentoCobroCurso}`;
    const resp = await deleteRequest(requestUrl, data.token);
    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getCobroCursoFacturas = async (data) => {
    const requestUrl = `${url}/area/${data.area}/curso/cobro-curso-aplicado/entidad/${data.idEntidad}/documento-factura${data.idConvocatoria ? `?idConvocatoria=${data.idConvocatoria}` : ""}`;
    const resp = await getRequest(requestUrl, data.token);
    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }
    return resp;
};

export const deleteCursoServicioExtemporaneo = async (data) => {
    const requestUrl = `${url}/area/${data.area}/curso/servicio-extemporaneo/curso-entidad/${data.idCursoEntidad}/cobro-curso-aplicado/${data.idCobroCursoAplicado}/cobro-curso/${data.idCobroCurso}`;
    const resp = await deleteRequest(requestUrl, data.token);
    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }
    return resp.payload;
};

export const postComplementoPagoCurso = async (data) => {
    const requestUrl = `${urlFactura}/facturacion/emitirComplementoPagoCurso`;
    const resp = await postRequest(requestUrl, data.token, {payload: data.body});
    if (resp.error) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.error[0].descripcionError
        };
    }
    return resp.payload;
};

export const getCobroCursoReport = async (data) => {
    let requestUrl = `${urlFactura}/area/${data.area}/reporte-cobro/orden-pago/idEntidad/${data.idEntidad}`;
    if (data.isDesdeEntidad) {
        requestUrl += `?inConvocatoria=0`;
    }
    const resp = await getFileRequest(requestUrl, data.token, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }
    return resp;
};

export const getEstatusCursoEntidad = async (data) => {
    const requestUrl = `${url}/area/${data.area}/curso/catalogo/obtenerCatEstatusCursos`;
    const resp = await getRequest(requestUrl, data.token);
    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }
    return resp;
};