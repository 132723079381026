import React from 'react'
import { ListCursos } from '../components/publicacion/cursos'
import { useParams } from 'react-router-dom'

export default function Cursos() {
    const params = useParams();
    return <div className="publicacion">
        <ListCursos idEntidad={params.idEntidad} />
    </div>
}
