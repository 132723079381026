import { useEffect, useState } from "react";

import { ReactComponent as DownloadIcon } from '../../assets/icons/download_file.svg';

import '../../styles/template-styles/documentos.scss';
import '../../styles/template-styles/templates.scss';

import convocatoria from '../../assets/images/background-image-100.jpg';

export default function DocumentosPreview({ data }) {
    /**
     * Set Configuration state
     */
    const [moduleStyles, setModuleStyles] = useState({
        backgroundColor: '',
    });
    const [contentStyles, setContentStyles] = useState({
        '--cardBackground': '',
        '--textColor': '',
        '--iconColor': '',
    });

  /**
   * Check config values
   */
  useEffect(() => {
    if (data) {
        const styles = {
            backgroundColor: data.backgroundColor,            
        };
        if (data.alturaFija) {
            styles.height = `${data.height}px`
        }
        setModuleStyles(styles)
        setContentStyles({
            '--cardBackground': data.contenido.cardBackground,
            '--textColor': data.contenido.textColor,
            '--iconColor': data.contenido.iconColor,
        })
    }
  }, [data])

  return (
      <div className={`${data.layout}-conv`} style={moduleStyles}>
          {data.backgroundImage &&
              <img src={convocatoria} alt="fondo" />
          }
          <div className={data.wrapContent ? 'wrapper-conv' : ''}>
            <div className={`content-conv ${data.contenido.estilo}`} style={contentStyles}>
                {data.contenido.documentos && data.contenido.documentos.map((documento, index) => {
                    return <div key={index} className="documento">
                        <div className="icon">
                            <DownloadIcon />
                        </div>
                        <div className="document'info">
                            <p className="nombre">{documento.nombre}</p>
                        </div>
                    </div>
                })}
            </div>
          </div>
      </div>
  );
}