import { useDispatch, useSelector } from "react-redux";
import { appState, setLoading } from "../../slices/appSlice";
import { firebaseDb } from '../../firebaseConfig';
import { catalogosServiceState, getAmbitosInstrumentoCatalogo, getCriteriosInstrumentoCatalogo, getIndicadoresInstrumentoCatalogo, getSubambitosInstrumentoCatalogo } from "../../slices/catalogosServiceSlice";
import { ReactComponent as ProgressIcon } from '../../assets/icons/in-progress.svg';
import { ReactComponent as CompletedIcon } from '../../assets/icons/completed.svg';
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import { changeAmbitoProgress, changeAmbitoSelected, changeCriterioProgress, changeCriterioSelected, changeIndicadorProgress, changeIndicadorSelected, changePregunta, changePreguntas, changeRespuestaSeleccionada, changeSubambitoProgress, changeSubambitoSelected, instrumentoServicesState, obtenerInstrumentoMedicionConvocatoria, obtenerTotalAmbito, obtenerTotalCriterio, obtenerTotalIndicador, obtenerTotalSubAmbito, setQuery } from "../../slices/instrumentoServiceSlice";
import { useEffect } from "react";
import { FIREBASE, INSTRUMENTO, INSTRUMENTO_PROGRESS } from "../../app/constantes";

export default function CriteriosNav() {
    const dispatch = useDispatch();
    const catalogosSt = useSelector(catalogosServiceState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const instrumentoSt = useSelector(instrumentoServicesState);
    const appSt = useSelector(appState);

    const getCatalogo = async (type, selection) => {
        dispatch(changePreguntas(null));
        dispatch(changePregunta(null));
        const data = {
            ...appSt.serviceData,
            idTipoEntidad: registroConvocatoriaSt.empresaSeleccionada.idTipoEntidad,
            idTamanio: registroConvocatoriaSt.empresaSeleccionada.idTamanio,
            idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
        }
        const idEntidad = registroConvocatoriaSt.empresaSeleccionada.idEntidad;
        const idConvocatoria = registroConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria
        let query;
        switch (type) {
            case 'ambitos':
                query = {
                    cveCriterio: selection.cveCriterio,
                }
                dispatch(obtenerTotalCriterio({
                    ...appSt.serviceData,
                    idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
                    cveCriterio: selection.cveCriterio
                }))
                dispatch(changeCriterioSelected(selection));
                dispatch(changeRespuestaSeleccionada(null));
                dispatch(changeAmbitoProgress(0));
                dispatch(changeSubambitoProgress(0));
                dispatch(changeIndicadorProgress(0));
                dispatch(changeAmbitoSelected(null));
                dispatch(changeSubambitoSelected(null));
                dispatch(changeIndicadorSelected(null));
                dispatch(getAmbitosInstrumentoCatalogo({ ...appSt.serviceData, idCriterio: selection.idCriterio, idEntidad, idConvocatoria }));
                dispatch(setQuery(query));
                break;
            case 'subambitos':
                query = {
                    cveCriterio: instrumentoSt.criterioSelected.cveCriterio,
                    cveAmbito: selection.cveAmbito,
                }
                dispatch(obtenerTotalAmbito({
                    ...appSt.serviceData,
                    idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
                    cveAmbito: selection.cveAmbito
                }))
                dispatch(changeAmbitoSelected(selection));                
                dispatch(changeRespuestaSeleccionada(null));
                dispatch(changeSubambitoProgress(0));
                dispatch(changeIndicadorProgress(0));
                dispatch(changeSubambitoSelected(null));
                dispatch(changeIndicadorSelected(null));
                dispatch(getSubambitosInstrumentoCatalogo({ ...appSt.serviceData, idCriterio: instrumentoSt.criterioSelected.idCriterio, idAmbito: selection.idAmbito, idEntidad, idConvocatoria }));
                dispatch(setQuery(query));
                break;
            case 'indicadores':
                query = {
                    cveCriterio: instrumentoSt.criterioSelected.cveCriterio,
                    cveAmbito: instrumentoSt.ambitoSelected.cveAmbito,
                    cveSubambito: selection.cveSubambito,
                }
                dispatch(obtenerTotalSubAmbito({
                    ...appSt.serviceData,
                    idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
                    cveSubambito: selection.cveSubambito
                }))
                dispatch(changeRespuestaSeleccionada(null));
                dispatch(changeSubambitoSelected(selection));
                dispatch(changeIndicadorSelected(null));
                dispatch(changeIndicadorProgress(0));
                dispatch(getIndicadoresInstrumentoCatalogo({ ...appSt.serviceData, idCriterio: instrumentoSt.criterioSelected.idCriterio, idAmbito: instrumentoSt.ambitoSelected.idAmbito, idSubAmbito: selection.idSubambito, idEntidad, idConvocatoria }));
                dispatch(setQuery(query));
                break;
            case 'instrumento':
                dispatch(changeIndicadorSelected(selection));
                dispatch(changeRespuestaSeleccionada(null));
                dispatch(obtenerTotalIndicador({
                    ...appSt.serviceData,
                    idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
                    cveIndicador: selection.cveIndicador
                }))
                query = {
                    cveCriterio: instrumentoSt.criterioSelected.cveCriterio,
                    cveAmbito: instrumentoSt.ambitoSelected.cveAmbito,
                    cveSubambito: instrumentoSt.subambitoSelected.cveSubambito,
                    cveIndicador: selection.cveIndicador
                }
                dispatch(obtenerInstrumentoMedicionConvocatoria({ ...data, query }));
                dispatch(setQuery(query));
                break;
            default:
                break;
        }
    }

    const getFirebaseProgress = async () => {
        const progress = await firebaseDb.collection(FIREBASE.instrumentProgressCollection).doc(registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria.toString()).get();
        if (progress.exists) {
            dispatch(setLoading(true));
            const idEntidad = registroConvocatoriaSt.empresaSeleccionada.idEntidad;
            const idConvocatoria = registroConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria;

            //firebase data
            const data = progress.data();

            //populamos los segmentos
            dispatch(changeCriterioSelected(data.criterioSelected));
            dispatch(getCriteriosInstrumentoCatalogo({...appSt.serviceData, idEntidad, idConvocatoria}));
            dispatch(changeAmbitoSelected(data.ambitoSelected));
            dispatch(getAmbitosInstrumentoCatalogo({ ...appSt.serviceData, idCriterio: data.criterioSelected.idCriterio, idEntidad, idConvocatoria }));
            dispatch(changeSubambitoSelected(data.subambitoSelected));
            dispatch(getSubambitosInstrumentoCatalogo({ ...appSt.serviceData, idCriterio: data.criterioSelected.idCriterio, idAmbito: data.ambitoSelected.idAmbito, idEntidad, idConvocatoria }));
            dispatch(changeIndicadorSelected(data.indicadorSelected));
            dispatch(getIndicadoresInstrumentoCatalogo({ ...appSt.serviceData, idCriterio: data.criterioSelected.idCriterio, idAmbito: data.ambitoSelected.idAmbito, idSubAmbito: data.subambitoSelected.idSubambito, idEntidad, idConvocatoria }));


            //populamos los progresos y totales
            dispatch(obtenerTotalCriterio({
                ...appSt.serviceData,
                idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
                cveCriterio: data.criterioSelected.cveCriterio
            }))
            dispatch(obtenerTotalAmbito({
                ...appSt.serviceData,
                idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
                cveAmbito: data.ambitoSelected.cveAmbito
            }))
            dispatch(obtenerTotalSubAmbito({
                ...appSt.serviceData,
                idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
                cveSubambito: data.subambitoSelected.cveSubambito
            }))
            dispatch(obtenerTotalIndicador({
                ...appSt.serviceData,
                idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
                cveIndicador: data.indicadorSelected.cveIndicador
            }))

            if (data.query) {
                dispatch(setQuery(data.query));
            }


            // populamos las preguntas y respuesta seleccionada
            dispatch(changePregunta(data.preguntaSeleccionada));
            dispatch(changeRespuestaSeleccionada(data.respuestaSeleccionada));
            const query = {
                cveCriterio: data.criterioSelected.cveCriterio,
                cveAmbito: data.ambitoSelected.cveAmbito,
                cveSubambito: data.subambitoSelected.cveSubambito,
                cveIndicador: data.indicadorSelected.cveIndicador
            }
            const serviceData = {
                ...appSt.serviceData,
                idTipoEntidad: registroConvocatoriaSt.empresaSeleccionada.idTipoEntidad,
                idTamanio: registroConvocatoriaSt.empresaSeleccionada.idTamanio,
                idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
            }
            dispatch(obtenerInstrumentoMedicionConvocatoria({ ...serviceData, query }));
            dispatch(setLoading(false));
        }
    }

    //Validaciones

    const isPaso1 = () => {
        return !instrumentoSt.criterioSelected && !instrumentoSt.ambitoSelected;
    }

    const isPaso2 = () => {
        return instrumentoSt.criterioSelected && !instrumentoSt.ambitoSelected && !instrumentoSt.subambitoSelected;
    }

    const isPaso3 = () => {
        return instrumentoSt.criterioSelected && instrumentoSt.ambitoSelected && !instrumentoSt.subambitoSelected && !instrumentoSt.indicadorSelected;
    }

    const isPaso4 = () => {
        return instrumentoSt.criterioSelected && instrumentoSt.ambitoSelected && instrumentoSt.subambitoSelected && !instrumentoSt.indicadorSelected && !instrumentoSt.preguntas;
    }

    useEffect(() => {
        if (catalogosSt.indicadores && catalogosSt.indicadores.length > 0) {
            const completed = catalogosSt.indicadores.filter(item => item.pendientes === 0);
            dispatch(changeIndicadorProgress(completed.length));
        } 
        if (catalogosSt.ambitos && catalogosSt.ambitos.length > 0) {
            const completed = catalogosSt.ambitos.filter(item => item.pendientes === 0);
            dispatch(changeAmbitoProgress(completed.length));
        } 
        if (catalogosSt.subambitos && catalogosSt.subambitos.length > 0) {
            const completed = catalogosSt.subambitos.filter(item => item.pendientes === 0);
            dispatch(changeSubambitoProgress(completed.length));
        } 
        if (catalogosSt.criterios && catalogosSt.criterios.length > 0) {
            const completed = catalogosSt.criterios.filter(item => item.pendientes === 0);
            dispatch(changeCriterioProgress(completed.length));
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogosSt.criterios, catalogosSt.ambitos, catalogosSt.subambitos, catalogosSt.indicadores, instrumentoSt.instrumento])

    useEffect(() => {
        if (registroConvocatoriaSt.activeView === INSTRUMENTO_PROGRESS) {            
            getFirebaseProgress();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registroConvocatoriaSt.activeView])

    return <>
        {isPaso1() &&
            <h3 className="step-title"><b>Paso 1:</b> Selecciona un Criterio</h3>
        }
        {isPaso2() &&
            <h3 className="step-title"><b>Paso 2:</b> Selecciona un Ambito</h3>
        }
        {isPaso3() &&
            <h3 className="step-title"><b>Paso 3:</b> Selecciona un Sub Ambito</h3>
        }
        {isPaso4() &&
            <h3 className="step-title"><b>Paso 4:</b> Selecciona un Indicador</h3>
        }
        <nav>
            <div className={`${isPaso1() ? 'custom-select pulse' : 'custom-select'}`}>
                <label>Criterio
                    <span className={instrumentoSt.criterioProgress !== 0 && catalogosSt.criterios && instrumentoSt.criterioProgress === catalogosSt.criterios.length ? 'completed' : ''}>
                        {instrumentoSt.criterioProgress ? instrumentoSt.criterioProgress : 0}/{catalogosSt.criterios ? catalogosSt.criterios.length : 0}
                    </span>
                </label>
                <p>{instrumentoSt.criterioSelected ? instrumentoSt.criterioSelected.descCriterio : 'Selecciona un Criterio'}</p>
                {catalogosSt.criterios && <ul>
                    {catalogosSt.criterios.map((criterio, index) => {
                        return <li className={criterio.pendientes === 0 ? 'completed' : ''} key={index} onClick={() => getCatalogo('ambitos', criterio)}>
                            {criterio.pendientes === 0 ? <CompletedIcon /> : <ProgressIcon />}
                            <p>{criterio.descCriterio}</p>
                        </li>
                    })}
                </ul>
                }

            </div>
            <div className={`custom-select ${!instrumentoSt.criterioSelected ? 'disabled' : ''} ${isPaso2() ? 'pulse' : ''}`}>
                <label>Ámbitos
                    <span className={instrumentoSt.ambitoProgress !== 0 && catalogosSt.ambitos && instrumentoSt.ambitoProgress === catalogosSt.ambitos.length ? 'completed' : ''}>
                        {instrumentoSt.ambitoProgress ? instrumentoSt.ambitoProgress : 0}/{catalogosSt.ambitos ? catalogosSt.ambitos.length : 0}
                    </span>
                </label>
                <p>{instrumentoSt.ambitoSelected ? instrumentoSt.ambitoSelected.descAmbito : 'Selecciona un Ámbito'}</p>
                {catalogosSt.ambitos &&
                    <ul>
                        {catalogosSt.ambitos.map((ambito, index) => {
                            return <li className={ambito.pendientes === 0 ? 'completed' : ''}  key={index} onClick={() => getCatalogo('subambitos', ambito)}>
                                {ambito.pendientes === 0 ? <CompletedIcon /> : <ProgressIcon />}
                                <p>{ambito.descAmbito}</p>
                            </li>
                        })}
                    </ul>
                }
            </div>
            <div className={`custom-select ${!instrumentoSt.ambitoSelected ? 'disabled' : ''} ${isPaso3() ? 'pulse' : ''}`}>
                <label>Sub Ámbitos
                    <span className={instrumentoSt.subambitoProgress !== 0 && catalogosSt.subambitos && instrumentoSt.subambitoProgress === catalogosSt.subambitos.length ? 'completed' : ''}>
                        {instrumentoSt.subambitoProgress ? instrumentoSt.subambitoProgress : 0}/{catalogosSt.subambitos ? catalogosSt.subambitos.length : 0}
                    </span>
                </label>
                <p>{instrumentoSt.subambitoSelected ? instrumentoSt.subambitoSelected.descSubambito : 'Selecciona un Sub Ámbito'}</p>
                {catalogosSt.subambitos &&
                    <ul>
                        {catalogosSt.subambitos.map((subambito, index) => {
                            return <li className={subambito.pendientes === 0 ? 'completed' : ''}  key={index} onClick={() => getCatalogo('indicadores', subambito)}>
                                {subambito.pendientes === 0 ? <CompletedIcon /> : <ProgressIcon />}                                
                                <p>{subambito.descSubambito}</p>
                            </li>
                        })}
                    </ul>
                }
            </div>
            <div className={`custom-select ${!instrumentoSt.subambitoSelected ? 'disabled' : ''} ${isPaso4() ? 'pulse' : ''}`}>
                <label>Indicador
                    <span className={instrumentoSt.indicadorProgress !== 0 && catalogosSt.indicadores && instrumentoSt.indicadorProgress === catalogosSt.indicadores.length ? 'completed' : ''}>
                        {instrumentoSt.indicadorProgress ? instrumentoSt.indicadorProgress : 0}/{catalogosSt.indicadores ? catalogosSt.indicadores.length : 0}
                    </span>
                </label>
                <p>{instrumentoSt.indicadorSelected ? instrumentoSt.indicadorSelected.descIndicador : 'Selecciona un Indicador'}</p>
                {catalogosSt.indicadores &&
                    <ul>
                        {catalogosSt.indicadores.map((indicador, index) => {
                            return <li className={indicador.pendientes === 0 ? 'completed' : ''}  key={index} onClick={() => getCatalogo('instrumento', indicador)}>
                                {indicador.pendientes === 0 ? <CompletedIcon /> : <ProgressIcon />}                                
                                <p>{indicador.descIndicador}</p>
                            </li>
                        })}
                    </ul>
                }
            </div>
        </nav>
    </>
}