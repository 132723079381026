import { useState } from 'react';
import { useEffect } from 'react';
import { formatCurrency } from '../../../app/commons';
import { ReactComponent as AwardIcon } from '../../../assets/icons/award.svg';
import Counter from '../../generales/counter';
import { ReactComponent as SimpleArrowIcon } from '../../../assets/icons/simple-arrow.svg';


const bucketUrl = process.env.REACT_APP_BUCKET_STORAGE;

export function CobrosEstatuillas({
    concepto,
    input,
    handleEstatuaChange,
    sendEstatuaData,
    tip,
    type
}) {
    const totalSlides = 10;
    const slideClases = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve']
    const [currentSlide, setCurrentSlide] = useState('one');
    const [counter, setCounter] = useState(0);

    const nextSlide = () => {
        if (counter !== totalSlides) {
            setCounter(counter+1);
            setCurrentSlide(slideClases[counter+1]);
        }
    }

    const prevSlide = () => {
        if (counter !== 0) {
            setCounter(counter-1);
            setCurrentSlide(slideClases[counter-1]);
        }
    }

    useEffect(() => {
        if (concepto && !type) {
            sendEstatuaData(concepto);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);
    return (
        <div className="chip estatuilla">
            <div className="tooltip">
                {tip && <span>{tip}</span>}
                <AwardIcon />
            </div>
            <div className="text">
                <label>{concepto.descConceptoCobroConvocatoria}</label>
                <p>{formatCurrency(concepto.monto)}</p>
                <div className="preview">
                    {counter !== 0 && 
                        <button onClick={prevSlide} className="prev">
                            <SimpleArrowIcon />
                        </button>                
                    }
                    <div className={`carrousel ${currentSlide}`}>
                        <img src={`${bucketUrl}ESR%20-%20Color%20(1).png`} alt="estautilla" />
                        <img src={`${bucketUrl}ESR%20-%20Color%20(2).png`} alt="estautilla" />
                        <img src={`${bucketUrl}IMG_1203.jpg`} alt="estautilla" />
                        <img src={`${bucketUrl}IMG_1213.jpg`} alt="estautilla" />
                        <img src={`${bucketUrl}IMG_1403%202.jpg`} alt="estautilla" />
                        <img src={`${bucketUrl}IMG_1664.jpg`} alt="estautilla" />
                        <img src={`${bucketUrl}IMG_1665-copy-0.jpg`} alt="estautilla" />
                        <img src={`${bucketUrl}IMG_1665.jpg`} alt="estautilla" />
                        <img src={`${bucketUrl}IMG_1701.jpg`} alt="estautilla" />
                        <img src={`${bucketUrl}IMG_2708%202.jpg`} alt="estautilla" />
                        <img src={`${bucketUrl}IMG_2721%202.jpg`} alt="estautilla" />
                    </div>
                    {counter !== totalSlides && 
                        <button onClick={nextSlide} className="next">
                            <SimpleArrowIcon />
                        </button>                
                    }
                </div>
            </div>
            <Counter
                handleEstatuaChange={handleEstatuaChange}
                counter={input}
            />
        </div>
    );
}