import { useEffect, useState } from 'react';
import { ReactComponent as BackIcon } from '../assets/icons/back.svg';
import { ALERT_FAIL, ALERT_SUCCESS, CONVOCATORIAS_DISPONIBLES, FIREBASE, INSTRUMENTOS_CVE_ESTATUS, LOGO_EMPRESA, NOTIFICACIONES, SELECCION_PREGUNTA } from '../app/constantes';

import { useDispatch, useSelector } from 'react-redux';
import { changeView, registroConvocatoriaState } from '../slices/registroConvocatoriaSlice';
import { changeInstrumento, changePregunta, instrumentoServicesState, resetInstrumento, setInstrumento, getInstrumentoReport as getInstrumentoReportSeguimiento, changeRespuestaSeleccionada } from '../slices/instrumentoServiceSlice';

// import { ReactComponent as ProgressIcon } from '../assets/icons/in-progress.svg';
import { ReactComponent as CompleteIcon } from '../assets/icons/completed.svg';
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/download.svg';
import { ReactComponent as SaveIcon } from '../assets/icons/save.svg';

import { firebaseDb } from '../firebaseConfig';

import '../styles/instrumentos.scss';
import PreguntaComponent from '../components/instrumento/pregunta';
import CriteriosNav from '../components/instrumento/criterios-nav';
import { getInstrumentoProgressReport, getObjetivoPregunta, putInstrumentoRegistro, getInstrumentoReport, setFechaLiberacion } from '../services/instrumento';
import { appState, changeLogo, setAlert, setLoading } from '../slices/appSlice';
import { getMessageByModulo, getTips } from '../services/catalogos';
import ConfirmModal from '../components/generales/confirmModal';
import { catalogosServiceState, resetSegementos } from '../slices/catalogosServiceSlice';
import { postNotificacion } from '../services/notificaciones';
import { usuariosServicesState } from '../slices/usuariosServicesSlice';
import moment from 'moment/moment';
import InstrumentoReporte from '../components/instrumento/modal-reporte';
import { setLiberacionInstrumento } from '../services/evaluacion';

export default function Instrumentos() {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const instrumentoSt = useSelector(instrumentoServicesState);
    const usuarioSt = useSelector(usuariosServicesState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const catalogosSt = useSelector(catalogosServiceState);
    const [preguntas, setPreguntas] = useState(null);
    const [isLiberacionDisabled, setLiberacionDisabled] = useState(true);
    const [messages, setMessages] = useState(null);
    const [onlyView, setOnlyView] = useState(null);

    const [modalData, setModalData] = useState({
        show: false,
        title: '',
        desc: '',
        info: null,
        list: null
    });

    // Cierra Modal de confirmacion
    const closeModal = () => {
        setModalData({
            ...modalData,
            show: false
        })
    }

    const confirmLiberacion = () => {
        setModalData({
            show: true,
            title: 'Liberación definitiva',
            desc: '¿Deseas confirmar la liberación definitiva? Al dar continuar, ya no podrás hacer cambios.',
            info: null,
            action: () => liberacionDefinitiva()
        })
    }

    const liberacionDefinitiva = async () => {
        dispatch(setLoading(true));
        try {
            const resp = await putInstrumentoRegistro({ ...appSt.serviceData, idInstrumentoRegistro: instrumentoSt.instrumento.idInstrumentoRegistro }, {
                cveEstatusInstrumentoRegistro: INSTRUMENTOS_CVE_ESTATUS.liberado
            });
            await setFechaLiberacion({ ...appSt.serviceData, idInstrumentoRegistro: instrumentoSt.instrumento.idInstrumentoRegistro }, {});
            await setLiberacionInstrumento({ ...appSt.serviceData, idInstrumentoRegistro: instrumentoSt.instrumento.idInstrumentoRegistro }, {});
            if (resp) {
                dispatch(setInstrumento(resp));
            }
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: 'El instrumento se liberó con éxito',
                type: ALERT_SUCCESS
            }))
            postNotificacion(appSt.serviceData, {
                to: [
                    { email: usuarioSt.usuario.data.email, name: usuarioSt.usuario.data.nombre }
                ],
                templateData: {
                    convocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria,
                    fechaHora: moment().format('DD-MM-YYYY h:mm a'),
                    usuario: usuarioSt.usuario.data.userName
                },
                templateId: NOTIFICACIONES.templatesIds.liberacionInstrumento
            })
            closeModal();
        } catch (error) {
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: 'Error al liberar instrumento',
                type: ALERT_FAIL
            }))

        }
    }

    const goToView = (view) => {
        dispatch(changeView(view));
        dispatch(changeLogo(LOGO_EMPRESA.cemefi));
        if (view === SELECCION_PREGUNTA) {
            console.log('aqui pasa el error raro:', instrumentoSt.instrumento)
            dispatch(getInstrumentoReportSeguimiento({...appSt.serviceData, idRegistroConvocatoria: instrumentoSt.instrumento.idRegistroConvocatoria}));
        }
        dispatch(resetSegementos());
        dispatch(resetInstrumento());
    }

    const selectPregunta = async (pregunta) => {
        const objetivos = await getObjetivoPregunta({ ...appSt.serviceData, idPreguntaIndicador: pregunta.idPreguntaIndicador });
        dispatch(changePregunta({ ...pregunta, objetivos }));
        if (pregunta.respuesta && pregunta.respuesta.length > 0) {
            dispatch(changeRespuestaSeleccionada(pregunta.respuesta[0]));
        }
    }

    const selectInstrumento = (instrumento) => {
        dispatch(changeInstrumento({
            ...instrumento.idInstrumentoMedicionConvocatoria2.idInstrumentoMedicion2,
            idInstrumentoRegistro: instrumento.idInstrumentoRegistro,
            idRegistroConvocatoria: instrumento.idRegistroConvocatoria
        }))
    }

    const downloadAll = async () => {
        dispatch(setLoading(true));
        const blob = await getInstrumentoProgressReport({ ...appSt.serviceData, idRegistroConvocatoria: instrumentoSt.instrumento.idRegistroConvocatoria });
        if (blob) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Gestión Avance';
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
        dispatch(setLoading(false));
    }

    const checkInstrumentoProgress = async () => {
        if (instrumentoSt.preguntaSeleccionada && instrumentoSt.preguntaSeleccionada.respuesta[0].idTipoRespuesta === catalogosSt.idsTipoRespuesta.nivelMadurez) {
            try {
                const resp = await getInstrumentoReport({
                    ...appSt.serviceData,
                    idInstrumentoMedicion: instrumentoSt.preguntaSeleccionada.idInstrumentoMedicion,
                    cveTipoRespuesta: instrumentoSt.preguntaSeleccionada.respuesta[0].cveTipoRespuesta,
                    idInstrumentoMedicionConvocatoria: instrumentoSt.preguntaSeleccionada.idInstrumentoMedicionConvocatoria,
                    idInstrumentoRegistro: instrumentoSt.preguntaSeleccionada.idInstrumentoRegistro
                });
                setLiberacionDisabled(!resp.instrumentoHabilitadoFaseFinal || !registroConvocatoriaSt.visibilidadData.isVisible);
            } catch (error) {
                console.log(error);

            }
        }
    }

    const getToolTips = async () => {
        if (messages === null) {
            const tips = {
                seleccion: null,
                sinInstrumento: null
            }
            const resp = await getTips({
                ...appSt.serviceData,
                claveCatalogo: 'parametro',
                clave: 'MENSAJESELECCIONNIVEL'
            });
            const sinInstrumentoTip = await getMessageByModulo(
                appSt.serviceData.area,
                appSt.serviceData.token,
                'REGISTROINSTRUMENTO',
                'MENSAJESININSTRUMENTO'
            );
            if (resp.payload) {
                tips.seleccion = resp.payload.valor;
            }
            if (sinInstrumentoTip.payload) {
                tips.sinInstrumento = sinInstrumentoTip.payload.valor;
            }
            setMessages(tips)
        }
    }

    /**
     * Guardamos el progreso, segementos, pregunta, nivel seleccionado
     */
    const saveFirebaseProgress = () => {
        const firebaseBody = {
            indicadorSelected: instrumentoSt.indicadorSelected,
            subambitoSelected: instrumentoSt.subambitoSelected,
            ambitoSelected: instrumentoSt.ambitoSelected,
            criterioSelected: instrumentoSt.criterioSelected,
            preguntaSeleccionada: instrumentoSt.preguntaSeleccionada,
            respuestaSeleccionada: instrumentoSt.respuestaSeleccionada
        }
        firebaseDb.collection(FIREBASE.instrumentProgressCollection).doc(registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria.toString()).set(firebaseBody).then(doc => {
            dispatch(setAlert({
                show: true,
                message: 'Progreso Guardado',
                type: ALERT_SUCCESS
            }))
        })
    }

    const saveProgressAndExit = () => {
        saveFirebaseProgress();
        goToView(CONVOCATORIAS_DISPONIBLES);
    }

    useEffect(() => {
        getToolTips();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (instrumentoSt.preguntas) {
            const array = instrumentoSt.preguntas;
            const sorted = JSON.parse(JSON.stringify(array)).sort((a, b) => a.order - b.order);
            setPreguntas(sorted);
            if (instrumentoSt.preguntaSeleccionada === null && sorted.length > 0) {
                selectPregunta(sorted[0]);
            }
        } else {
            setPreguntas([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instrumentoSt.preguntas, instrumentoSt.indicadorSelected])
    
    useEffect(() => {
        checkInstrumentoProgress();        
        setOnlyView(instrumentoSt.instrumento.idEstatusInstrumentoRegistro === catalogosSt.idsEstatusInstrumentoRegistro.liberado || !registroConvocatoriaSt.visibilidadData.isVisible) 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instrumentoSt.preguntaSeleccionada])


    return (
        <section className="wrapper instrumentos">
            <div className="main-section-title">
                <button onClick={() => goToView(SELECCION_PREGUNTA)}>
                    <BackIcon />
                </button>
                <div className="text">
                    {instrumentoSt.instrumento &&
                        <h3>{instrumentoSt.instrumento.descInstrumentoMedicion}</h3>
                    }
                    <p>Contesta y sube las evidencias para obtener tu Distintivo</p>
                </div>
            </div>
            {instrumentoSt.instrumentos &&
                <nav>
                    {instrumentoSt.instrumentos.map(instrumentoToSelect => {
                        return <button onClick={() => selectInstrumento(instrumentoToSelect)} className={instrumentoToSelect.idInstrumentoRegistro === instrumentoSt.instrumento.idInstrumentoRegistro ? 'active' : ''}>{
                            instrumentoToSelect.idInstrumentoMedicionConvocatoria2.idInstrumentoMedicion2.descInstrumentoMedicion
                        }</button>
                    })}
                </nav>
            }
                <div className="card">
                    <div className={instrumentoSt.instrumento && instrumentoSt.instrumento.idEstatusInstrumentoRegistro === catalogosSt.idsEstatusInstrumentoRegistro.liberado ? 'actions end' : 'actions'}>
                        
                        {instrumentoSt.instrumento && instrumentoSt.instrumento.idEstatusInstrumentoRegistro === catalogosSt.idsEstatusInstrumentoRegistro.liberado
                             ? <p>Instrumento Liberado</p>
                             : <p>Instrumento</p>
                        }
                        <button className='btn-icon' disabled={instrumentoSt.respuestaSeleccionada === null || (instrumentoSt.instrumento && instrumentoSt.instrumento.idEstatusInstrumentoRegistro === catalogosSt.idsEstatusInstrumentoRegistro.liberado)} onClick={saveProgressAndExit}>
                            <SaveIcon />
                            <p>Continuar Después y Guardar</p>
                        </button>
                        {instrumentoSt.instrumento && instrumentoSt.instrumento.idEstatusInstrumentoRegistro !== catalogosSt.idsEstatusInstrumentoRegistro.liberado &&
                            <button disabled={isLiberacionDisabled} onClick={confirmLiberacion} className="tooltip btn-icon" data-tool="Hasta que termines el instrumento se te permitirá liberar definitivamente">
                                <CompleteIcon />
                                <p>Liberación definitiva</p>
                            </button>
                        }
                        {/* <button onClick={goToReport}>Consultar Avance</button> */}
                        <button className='btn-icon' onClick={downloadAll}>
                            <DownloadIcon />
                            <p>Descargar Reporte</p>
                        </button>
                    </div>
                    <div className="instrumento-wrapper">
                        <div className="title">
                            <h3>Segmentos</h3>
                        </div>
                        {catalogosSt.criterios && catalogosSt.criterios.length === 0 && messages && messages.sinInstrumento &&
                            <div className='tip'>
                                <InfoIcon />
                                <p>{messages.sinInstrumento}</p>
                            </div>
                        }

                        <CriteriosNav />

                        <div className={preguntas && preguntas.length > 0 ? 'pregunta-container show' : 'pregunta-container'}>
                            <div className="title">
                                <h3>
                                    Preguntas.
                                </h3>
                            </div>

                            <div className="preguntas-wrapper">
                                <ul className="preguntas">
                                    {preguntas && preguntas.map((pregunta, index) => {
                                        return <li
                                            key={index}
                                            onClick={() => selectPregunta(pregunta)}
                                            className={instrumentoSt.preguntaSeleccionada && pregunta.idPreguntaIndicador === instrumentoSt.preguntaSeleccionada.idPreguntaIndicador ? 'active' : ''}>
                                            <div className="desc">
                                                <small>Pregunta {index + 1}</small>
                                                <p>{pregunta.descPreguntaIndicador}</p>
                                            </div>
                                        </li>
                                    })}
                                    {preguntas && preguntas.length === 0 &&
                                        <li>
                                            <p>Sin preguntas</p>
                                        </li>
                                    }
                                </ul>

                                <div className='pregunta-component'>
                                    {preguntas && preguntas.length > 0 && messages && messages.seleccion && <span>{messages.seleccion}</span>}
                                    <PreguntaComponent onlyView={onlyView} preguntas={preguntas} />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <ConfirmModal data={modalData} cancel={closeModal} />
                <InstrumentoReporte />
        </section>
    )
}