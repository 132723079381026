/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { CONVOCATORIAS_DISPONIBLES, FIREBASE, INSTRUMENTO, INSTRUMENTO_PROGRESS } from "../../app/constantes";
import { firebaseDb } from "../../firebaseConfig";
import { getInstrumentoByConvocatoriaId } from "../../services/instrumento";
import { appState } from "../../slices/appSlice";
import { setInstrumento } from "../../slices/instrumentoServiceSlice";
import { changeView, registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";

import SeguimientoInstrumento from "./seguimiento";


export default function SeleccionPregunta() {
    const dispatch = useDispatch();
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const appSt = useSelector(appState);
    const [progress, setProgress] = useState(false);

    const goBack = () => {
        dispatch(changeView(CONVOCATORIAS_DISPONIBLES));
    }

    const goToInstrumento = (type) => {
        dispatch(changeView(type ? type : INSTRUMENTO));
    }

    const checkProgress = async () => {
        const instrumento = await getInstrumentoByConvocatoriaId({ ...appSt.serviceData, idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria });
        
        if (instrumento.length > 0) {
            dispatch(setInstrumento(instrumento[0]))
        }

        const progress = await firebaseDb.collection(FIREBASE.instrumentProgressCollection).doc(registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria.toString()).get();

        if (progress.exists) {
            setProgress(true);
        }
    }

    useEffect(() => {
        checkProgress();
    }, []);

    return (
        <div className="seleccion-pregunta">
            <SeguimientoInstrumento afterSelection={goToInstrumento} />
            <div className="actions">
                <button onClick={goBack}>Atras</button>
                {progress &&
                    <button onClick={() => goToInstrumento(INSTRUMENTO_PROGRESS)}>Continuar desde tu progreso</button>
                }
            </div>
        </div>
    )
}