import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CVES_TIPODOCS_USOLOGO, CVE_DIRECCION, CVE_ESQUEMA_PAGO, CVE_ESTATUS_CARTALOGO, CVE_ESTATUS_COBRO, CVE_ESTATUS_CONSULTOR, CVE_ESTATUS_CORP_SEDE, CVE_ESTATUS_ENTIDAD, CVE_ESTATUS_REGISTRO_CONVOCATORIA, CVE_MODO_PAGO, CVE_PERFILES_USUARIO, CVE_TIPODOC_CEMEFI, CVE_TIPO_CONTACTO, CVE_TIPO_RESPUESTA, INSTRUMENTOS_CVE_ESTATUS } from '../app/constantes';
import { getMessageByModulo, getCatalogos, getDocumentCatalog, getInstrumentoCatalogosAmbito, getInstrumentoCatalogosCriterio, getInstrumentoCatalogosIndicador, getInstrumentoCatalogosSubambito, getParametrosPorModulo, getTableSector, getAreas, getTamanioByConvocatoria, getAmbitosByConvocatoria, getTips } from '../services/catalogos';
import { getDocumentosConv } from '../services/entidad';
import { getAniosParticipacionConvocatoria, getAniosProgramaConvocatoria } from '../services/convocatorias';


const initialState = {
    loading: false,
    pais: null,
    tipoEntidad: null,
    tipoContacto: null,
    tipoDireccion: null,
    industrias: null,
    sectores: [],
    subsectores: [],
    tamanioSectorSe: null,
    sectorSe: null,
    redes: null,
    tipoDocumentos: null,
    programas: null,
    modoParticipacion: null,
    cantidadCompanion: null,
    areas: null,
    listadoAreas: null,
    nivelMadurez: null,
    parametros: null,
    parametrosConvocatoria: null,
    perfilUsuario: null,
    estatusUsuario: null,
    estatusEntidad: null,
    tableData: null,
    criterios: null,
    ambitos: null,
    subambitos: null,
    indicadores: null,
    documentosRegistroConvocatoria: null,
    estatusVinculacion: null,
    estatusRegistroConvocatoria: null,
    estatusCobro: null,
    tipoRespuesta: null,
    modoPago: null,
    tipoDocumentosCemefi: null,
    metodoPago: null,
    formaPago: null,
    usoCfdi: null,
    estatusInstrumentoRegistro: null,
    tiposDocumentoCartaLogo: null,
    estatusCartaLogo: null,
    estados: null,
    tamanioConvocatoria: null,
    aniosConvocatoria: null,
    estatusCorpSede: null,
    documentosEvidenciaInstitucional: null,
    documentosRevertir: null,
    documentosConsultorAcreditado: null,
    regimen: null,
    aniosPrograma: null,
    ambitosConvocatoria: null,
    esquemaCobro: null,
    estatusConsultorAcreditado: null,
    estatusRelacionPrograma: null,
    documentosRelacion: null,
    documentosPromotora: null,

    idsEsquemaCobro: null,
    idsEstatusEntidad: null,
    idsTipoRespuesta: null,
    idsEstatusCobro: null,
    idsEstatusRegistroConvocatoria: null,
    idModoPagoTotal: null,
    idsPerfilUsuario: null,
    idsTipoDocumentoCemefi: null,
    idsEstatusInstrumentoRegistro: null,
    idsEstatusCartaLogo: null,
    idsTiposDocumentoCartaLogo: null,
    idsEstatusCorpSede: null,
    idsTiposDireccion: null,
    idsTipoContacto: null,
    idsEstatusConsultor: null
}

export const getCatalogoDocumentosPromotora = createAsyncThunk('catalogosService/getCatalogoDocumentosPromotora', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getTips(data);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getCatalogoDocumentosRelacion = createAsyncThunk('catalogosService/getCatalogoDocumentosRelacion', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getTips(data);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getEstatusRelacionPrograma = createAsyncThunk('catalogosService/getEstatusRelacionPrograma', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'estatusRelacionPrograma', data.token);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getEstatusConsultorAcredito = createAsyncThunk('catalogosService/getEstatusConsultorAcredito', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'estatusConsultorAcreditado', data.token);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getEsquemaCobroCatalogo = createAsyncThunk('catalogosService/getEsquemaCobroCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'esquemaCobro', data.token);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getEstatusCorpSede = createAsyncThunk('catalogosService/getEstatusCorpSede', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'estatusCorporativoSede', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})
export const getRegimenCatalogo = createAsyncThunk('catalogosService/getRegimenCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'regimenFiscal', data.token);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getAmbitosConvocatoria = createAsyncThunk('catalogosService/getAmbitosConvocatoria', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getAmbitosByConvocatoria(data);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getTamanioConvocatoria = createAsyncThunk('catalogosService/getTamanioConvocatoria', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getTamanioByConvocatoria(data);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getAniosByConvocatoria = createAsyncThunk('catalogosService/getAniosByConvocatoria', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getAniosParticipacionConvocatoria(data);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getAniosPrograma = createAsyncThunk('catalogosService/getAniosPrograma', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getAniosProgramaConvocatoria(data);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getEstatusInstrumentoRegistro = createAsyncThunk('catalogosService/getEstatusInstrumentoRegistro', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'estatusInstrumentoRegistro', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getCatalogoEstados = createAsyncThunk('catalogosService/getCatalogoEstados', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'estado', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})


export const getEstatusCartaLogo = createAsyncThunk('catalogosService/getEstatusCartaLogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'estatusCartaUsoLogo', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})


export const getUsoCfdi = createAsyncThunk('catalogosService/getUsoCfdi', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'usoCfdi', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getMetodosPago = createAsyncThunk('catalogosService/getMetodosPago', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'metodoPago', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getFormasPago = createAsyncThunk('catalogosService/getFormasPago', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'formaPago', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * estatus cobro
 */
export const getTipoDocumentoCemefi = createAsyncThunk('catalogosService/getTipoDocumentoCemefi', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'documentoCemefi', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getTipoRespuesta = createAsyncThunk('catalogosService/getTipoRespuesta', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'tipoRespuesta', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getAllAreas = createAsyncThunk('catalogosService/getAllAreas', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getAreas(data.token);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * estatus cobro
 */
export const getEstatusCobro = createAsyncThunk('catalogosService/getEstatusCobro', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'estatusCobro', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * registro convocatoria
 */
export const getEstatusRegistroConvocatoria = createAsyncThunk('catalogosService/getEstatusRegistroConvocatoria', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'estatusRegistroConvocatoria', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Indicadores
 */
export const getEstatusVinculacion = createAsyncThunk('catalogosService/getEstatusVinculacion', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'estatusVinculacion', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Indicadores
 */
export const getEstatusEntidad = createAsyncThunk('catalogosService/getEstatusEntidad', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'estatusEntidad', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Indicadores
 */
 export const getIndicadoresCatalogo = createAsyncThunk('catalogosService/getIndicadoresCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'indicador', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})
/**
 * Sub Ambitos
 */
 export const getSubAmbitosCatalogo = createAsyncThunk('catalogosService/getSubAmbitosCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'subAmbito', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Ambitos
 */
 export const getAmbitosCatalogo = createAsyncThunk('catalogosService/getAmbitosCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'ambito', data.token, data.idCatalogoPadre);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Criterios
 */
 export const getCriteriosCatalogo = createAsyncThunk('catalogosService/getCriteriosCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'criterio', data.token);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Criterios Instrumento
 */
export const getCriteriosInstrumentoCatalogo = createAsyncThunk('catalogosService/getCriteriosInstrumentoCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getInstrumentoCatalogosCriterio(data);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Criterios Instrumento
 */
export const getAmbitosInstrumentoCatalogo = createAsyncThunk('catalogosService/getAmbitosInstrumentoCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getInstrumentoCatalogosAmbito(data);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Criterios Instrumento
 */
export const getSubambitosInstrumentoCatalogo = createAsyncThunk('catalogosService/getSubambitosInstrumentoCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getInstrumentoCatalogosSubambito(data);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Criterios Instrumento
 */
export const getIndicadoresInstrumentoCatalogo = createAsyncThunk('catalogosService/getIndicadoresInstrumentoCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getInstrumentoCatalogosIndicador(data);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Table
 */
 export const getTable = createAsyncThunk('catalogosService/getTable', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getTableSector(data.area, data.token);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de usuario status
 */
 export const getStatusUsuario = createAsyncThunk('catalogosService/getStatusUsuario', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'estatusRegistro', data.token);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de usuario status
 */
 export const getPerfilesUsuario = createAsyncThunk('catalogosService/getPerfilesUsuario', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'perfil', data.token);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de tipo entidad
 */
 export const getTipoEntidadCatalogo = createAsyncThunk('catalogosService/getTipoEntidadCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'tipoEntidad', data.token);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de pais
 */
export const getPaisCatalogo = createAsyncThunk('catalogosService/getPaisCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'pais', data.token);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de tipo contacto
 */
 export const getTipoContactoCatalogo = createAsyncThunk('catalogosService/getTipoContactoCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'tipoContactoEntidad', data.token);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de industria
 */
 export const getIndustriaCatalogo = createAsyncThunk('catalogosService/getIndustriaCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'industria', data.token, '', data.idEntidad);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de sector
 */
 export const getSectorCatalogo = createAsyncThunk('catalogosService/getSectorCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'sector', data.token, data.idIndustria, data.idEntidad);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de industria
 */
 export const getSubsectorCatalogo = createAsyncThunk('catalogosService/getSubsectorCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'subsector', data.token, data.idSector, data.idEntidad);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de tipo de direccion
 */
 export const getTipoDireccionCatalogo = createAsyncThunk('catalogosService/getTipoDireccionCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'tipoDireccion', data.token);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de tamaño sector
 */
 export const getTamanioSectorSeCatalogo = createAsyncThunk('catalogosService/getTamanioSectorSeCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'tamanio', data.token, data.idEntidad);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de sector
 */
 export const getSectorSeCatalogo = createAsyncThunk('catalogosService/getSectorSeCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'sectorSe', data.token, '', data.idEntidad);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de redes
 */
 export const getRedesCatalogo = createAsyncThunk('catalogosService/getRedesCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'redContacto', data.token);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de redes
 */
export const getDocumentosRegistroConvocatoria = createAsyncThunk('catalogosService/getDocumentosRegistroConvocatoria', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getDocumentosConv(data);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de tipo documentos
 */
 export const getDocumentosCatalogo = createAsyncThunk('catalogosService/getDocumentosCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        const respCat = await getCatalogos(data.area, 'tipoDocumento', data.token);
        if (respCat && respCat.length > 0) {
            const tipoDoc = respCat.find(docType => docType.cveTipoDocumento === data.cveTipoDocumento);
            if (tipoDoc) {
                const respValidDocs = await getDocumentCatalog(data.area, data.token, tipoDoc.idTipoDocumento, data.idEstatusRegistro);
                if (respValidDocs && respValidDocs.length > 0) {
                    resp = respValidDocs;
                }
            }
        }
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getDocumentosEvidenciaCatalogo = createAsyncThunk('catalogosService/getDocumentosEvidenciaCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        const respCat = await getCatalogos(data.area, 'tipoDocumento', data.token);
        if (respCat && respCat.length > 0) {
            const tipoDoc = respCat.find(docType => docType.cveTipoDocumento === data.cveTipoDocumento);
            if (tipoDoc) {
                const respValidDocs = await getDocumentCatalog(data.area, data.token, tipoDoc.idTipoDocumento, data.idEstatusRegistro);
                if (respValidDocs && respValidDocs.length > 0) {
                    resp = respValidDocs;
                }
            }
        }
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de tipo documentos
 */
export const getDocumentosCartaLogoCatalogo = createAsyncThunk('catalogosService/getDocumentosCartaLogoCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        const respCat = await getCatalogos(data.area, 'tipoDocumento', data.token);
        if (respCat && respCat.length > 0) {
            const tipoDoc = respCat.find(docType => docType.cveTipoDocumento === data.cveTipoDocumento);
            if (tipoDoc) {
                const respValidDocs = await getDocumentCatalog(data.area, data.token, tipoDoc.idTipoDocumento, data.idEstatusRegistro);

                if (respValidDocs && respValidDocs.length > 0) {
                    resp = respValidDocs;
                }
            }
        }
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getDocumentosConsultorAcreditado = createAsyncThunk('catalogosService/getDocumentosConsultorAcreditado', async (data, {rejectWithValue}) => {
    let resp;
    try {
        const respCat = await getCatalogos(data.area, 'tipoDocumento', data.token);
        if (respCat && respCat.length > 0) {
            const tipoDoc = respCat.find(docType => docType.cveTipoDocumento === data.cveTipoDocumento);
            if (tipoDoc) {
                const respValidDocs = await getDocumentCatalog(data.area, data.token, tipoDoc.idTipoDocumento, data.idEstatusRegistro);

                if (respValidDocs && respValidDocs.length > 0) {
                    resp = respValidDocs;
                }
            }
        }
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getDocumentosRevertirCatalogo = createAsyncThunk('catalogosService/getDocumentosRevertirCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        const respCat = await getCatalogos(data.area, 'tipoDocumento', data.token);
        if (respCat && respCat.length > 0) {
            const tipoDoc = respCat.find(docType => docType.cveTipoDocumento === data.cveTipoDocumento);
            if (tipoDoc) {
                const respValidDocs = await getDocumentCatalog(data.area, data.token, tipoDoc.idTipoDocumento, data.idEstatusRegistro);

                if (respValidDocs && respValidDocs.length > 0) {
                    resp = respValidDocs;
                }
            }
        }
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de tipo entidad
 */
 export const getProgramasCatalogo = createAsyncThunk('catalogosService/getProgramasCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'programa', data.token);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    
    return resp;
})

/**
 * Catalogo de tipo entidad
 */
 export const getAreasCatalogo = createAsyncThunk('catalogosService/getAreasCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'area', data.token);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    
    return resp;
})


/**
 * Catalogo de tipo entidad
 */
 export const getModalidadParticipacion = createAsyncThunk('catalogosService/getModalidadParticipacion', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'modoParticipacion', data.token);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de tipo entidad
 */
export const getModalidadPago = createAsyncThunk('catalogosService/getModalidadPago', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'modalidadPago', data.token);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de tipo entidad
 */
 export const getNivelMadurezCatalogo = createAsyncThunk('catalogosService/getNivelMadurezCatalogo', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getCatalogos(data.area, 'nivelMadurez', data.token);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de tipo entidad
 */
 export const getCantidadCompanion = createAsyncThunk('catalogosService/getCantidadCompanion', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getMessageByModulo(data.area, data.token, 'REGISTRO', 'CANTIDADENTIDADESACOMPANIANTE');
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Catalogo de parámetros para el módulo Registro de entidad - Información general
 */
 export const getRegistroEntidadInformacionGeneral = createAsyncThunk('catalogosService/getRegistroEntidadInformacionGeneral', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getParametrosPorModulo(data.area, data.token, 'REGISTROENTIDADINFORMACIONGENERAL');
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

 export const getRegistroConvocatorias = createAsyncThunk('catalogosService/getRegistroConvocatorias', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getParametrosPorModulo(data.area, data.token, 'REGISTROCONVOCATORIA');
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const catalogosServiceSlice = createSlice({
    name: 'catalogosService',
    initialState,
    reducers: {
        resetSegementos:  (state, action) => {
            state.criterios = null;
            state.ambitos = null;
            state.subambitos = null;
            state.indicadores = null;
        },
        resetRegistro:  (state) => {
            state.industrias = null;
            state.sectores = null;
            state.sectorSe = null;
            state.subsectores = null;
        },
        resetCatalogos: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase((
            getCatalogoDocumentosPromotora.pending ||
            getCatalogoDocumentosRelacion.pending ||
            getEstatusRelacionPrograma.pending ||
            getDocumentosConsultorAcreditado.pending ||
            getEstatusConsultorAcredito.pending ||
            getEsquemaCobroCatalogo.pending ||
            getRegimenCatalogo.pending ||
            getDocumentosEvidenciaCatalogo.pending ||
            getAmbitosConvocatoria.pending ||
            getEstatusCorpSede.pending ||
            getAniosByConvocatoria.pending ||
            getAniosPrograma.pending ||
            getTamanioConvocatoria.pending ||
            getCatalogoEstados.pending ||
            getDocumentosRevertirCatalogo.pending ||
            getDocumentosCartaLogoCatalogo.pending ||
            getEstatusInstrumentoRegistro.pending ||
            getEstatusCartaLogo.pending ||
            getTipoDocumentoCemefi.pending ||
            getMetodosPago.pending ||
            getFormasPago.pending ||
            getUsoCfdi.pending ||
            getAllAreas.pending ||
            getTipoRespuesta.pending ||
            getEstatusEntidad.pending ||
            getEstatusCobro.pending ||
            getEstatusRegistroConvocatoria.pending ||
            getIndicadoresInstrumentoCatalogo.pending ||
            getSubambitosInstrumentoCatalogo.pending ||
            getAmbitosInstrumentoCatalogo.pending ||
            getCriteriosInstrumentoCatalogo.pending ||
            getEstatusVinculacion.pending ||
            getDocumentosRegistroConvocatoria.pending ||
            getIndicadoresCatalogo.pending ||
            getSubAmbitosCatalogo.pending ||
            getAmbitosCatalogo.pending ||
            getCriteriosCatalogo.pending ||
            getTable.pending ||
            getStatusUsuario.pending ||
            getPerfilesUsuario.pending ||
            getNivelMadurezCatalogo.pending ||
            getPaisCatalogo.pending ||
            getIndustriaCatalogo.pending ||
            getSectorCatalogo.pending ||
            getSubsectorCatalogo.pending ||
            getTamanioSectorSeCatalogo.pending ||
            getSectorSeCatalogo.pending ||
            getRedesCatalogo.pending ||
            getDocumentosCatalogo.pending ||
            getTipoEntidadCatalogo.pending ||
            getProgramasCatalogo.pending ||
            getModalidadParticipacion.pending ||
            getModalidadPago.pending ||
            getCantidadCompanion.pending ||
            getRegistroEntidadInformacionGeneral.pending ||
            getRegistroConvocatorias.pending ||
            getAreasCatalogo.pending ||
            getTipoContactoCatalogo.pending
        ), (state) => {
            state.loading = true
        })
        builder.addCase((
            getCatalogoDocumentosPromotora.rejected ||
            getCatalogoDocumentosRelacion.rejected ||
            getEstatusRelacionPrograma.rejected ||
            getDocumentosConsultorAcreditado.rejected ||
            getEstatusConsultorAcredito.rejected ||
            getEsquemaCobroCatalogo.rejected ||
            getDocumentosEvidenciaCatalogo.rejected ||
            getRegimenCatalogo.rejected ||
            getEstatusCorpSede.rejected ||
            getAmbitosConvocatoria.rejected ||
            getTamanioConvocatoria.rejected ||
            getAniosByConvocatoria.rejected ||
            getAniosPrograma.rejected ||
            getEstatusCartaLogo.rejected ||
            getCatalogoEstados.rejected ||
            getDocumentosRevertirCatalogo.rejected ||
            getDocumentosCartaLogoCatalogo.rejected ||
            getEstatusInstrumentoRegistro.rejected ||
            getUsoCfdi.rejected ||
            getFormasPago.rejected ||
            getMetodosPago.rejected ||
            getTipoDocumentoCemefi.rejected ||
            getEstatusEntidad.rejected ||
            getTipoRespuesta.rejected ||
            getAllAreas.rejected ||
            getEstatusCobro.rejected ||
            getEstatusRegistroConvocatoria.rejected ||
            getIndicadoresInstrumentoCatalogo.rejected ||
            getSubambitosInstrumentoCatalogo.rejected ||
            getAmbitosInstrumentoCatalogo.rejected ||
            getCriteriosInstrumentoCatalogo.rejected ||
            getEstatusVinculacion.rejected ||
            getDocumentosRegistroConvocatoria.rejected ||
            getIndicadoresCatalogo.rejected ||
            getSubAmbitosCatalogo.rejected ||
            getAmbitosCatalogo.rejected ||
            getCriteriosCatalogo.rejected ||
            getTable.rejected ||
            getStatusUsuario.rejected ||
            getPerfilesUsuario.rejected ||
            getNivelMadurezCatalogo.rejected ||
            getPaisCatalogo.rejected ||
            getIndustriaCatalogo.rejected ||
            getSectorCatalogo.rejected ||
            getSubsectorCatalogo.rejected ||
            getTamanioSectorSeCatalogo.rejected ||
            getSectorSeCatalogo.rejected ||
            getRedesCatalogo.rejected ||
            getTipoContactoCatalogo.rejected ||
            getTipoEntidadCatalogo.rejected ||
            getProgramasCatalogo.rejected ||
            getModalidadParticipacion.rejected ||
            getModalidadPago.rejected ||
            getCantidadCompanion.rejected ||
            getRegistroEntidadInformacionGeneral.rejected ||
            getRegistroConvocatorias.rejected ||
            getAreasCatalogo.rejected ||
            getDocumentosCatalogo.rejected
        ), (state) => {
            state.loading = false
        })
        builder.addCase(getCatalogoDocumentosPromotora.fulfilled, (state, action) => {
            state.loading = false;
            state.documentosPromotora = action.payload
        })
        builder.addCase(getCatalogoDocumentosRelacion.fulfilled, (state, action) => {
            state.loading = false;
            state.documentosRelacion = action.payload
        })
        builder.addCase(getEstatusRelacionPrograma.fulfilled, (state, action) => {
            state.loading = false;
            state.estatusRelacionPrograma = action.payload
        })
        builder.addCase(getTipoRespuesta.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload && action.payload.length > 0) {
                const cumplimiento = action.payload.find(item => item.cveTipoRespuesta === CVE_TIPO_RESPUESTA.cumplimiento);
                const nivelMadurez = action.payload.find(item => item.cveTipoRespuesta === CVE_TIPO_RESPUESTA.nivelMadurez);
                const textoAbierto = action.payload.find(item => item.cveTipoRespuesta === CVE_TIPO_RESPUESTA.textoAbierto);
                const opcionMultiple = action.payload.find(item => item.cveTipoRespuesta === CVE_TIPO_RESPUESTA.opcionMultiple);
                const magnitud = action.payload.find(item => item.cveTipoRespuesta === CVE_TIPO_RESPUESTA.magnitud);

                state.idsTipoRespuesta = {
                    cumplimiento: cumplimiento.idTipoRespuesta,
                    nivelMadurez: nivelMadurez.idTipoRespuesta,
                    textoAbierto: textoAbierto.idTipoRespuesta,
                    opcionMultiple: opcionMultiple.idTipoRespuesta,
                    magnitud: magnitud.idTipoRespuesta,
                }
            }
            state.tipoRespuesta = action.payload
        })
        builder.addCase(getEstatusCorpSede.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload && action.payload.length > 0) {
                const registrada = action.payload.find(item => item.cveEstatusCorporativoSede === CVE_ESTATUS_CORP_SEDE.registrada);
                const enRevision = action.payload.find(item => item.cveEstatusCorporativoSede === CVE_ESTATUS_CORP_SEDE.enRevision);
                const confirmada = action.payload.find(item => item.cveEstatusCorporativoSede === CVE_ESTATUS_CORP_SEDE.confirmada);
                const rechazada = action.payload.find(item => item.cveEstatusCorporativoSede === CVE_ESTATUS_CORP_SEDE.rechazada);

                state.idsEstatusCorpSede = {
                    registrada: registrada.idEstatusCorporativoSede,
                    enRevision: enRevision.idEstatusCorporativoSede,
                    confirmada: confirmada.idEstatusCorporativoSede,
                    rechazada: rechazada.idEstatusCorporativoSede,
                }
            }
            state.estatusCorpSede = action.payload;
        })
        builder.addCase(getTipoDocumentoCemefi.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload && action.payload.length > 0) {
                const evidenciaNm = action.payload.find(item => item.cveDocumentoCemefi === CVE_TIPODOC_CEMEFI.evidenciaNm);
                const evidencia = action.payload.find(item => item.cveDocumentoCemefi === CVE_TIPODOC_CEMEFI.evidencia);
                const actaConstitutiva = action.payload.find(item => item.cveDocumentoCemefi === CVE_TIPODOC_CEMEFI.actaConstitutiva);

                state.idsTipoDocumentoCemefi = {
                    evidenciaNm: evidenciaNm.idDocumentoCemefi,
                    evidencia: evidencia.idDocumentoCemefi,
                    actaConstitutiva: actaConstitutiva.idDocumentoCemefi,
                }
            }
            state.tipoDocumentosCemefi = action.payload
        })
        builder.addCase(getEstatusCartaLogo.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload && action.payload.length > 0) {
                const aceptado = action.payload.find(item => item.cveDescEstatusCartaUsoLogo === CVE_ESTATUS_CARTALOGO.aceptado);
                const rechazado = action.payload.find(item => item.cveDescEstatusCartaUsoLogo === CVE_ESTATUS_CARTALOGO.rechazado);
                const sinValidar = action.payload.find(item => item.cveDescEstatusCartaUsoLogo === CVE_ESTATUS_CARTALOGO.sinValidar);
                const pendiente = action.payload.find(item => item.cveDescEstatusCartaUsoLogo === CVE_ESTATUS_CARTALOGO.pendiente);

                state.idsEstatusCartaLogo = {
                    aceptado: aceptado.idEstatusCartaUsoLogo,
                    rechazado: rechazado.idEstatusCartaUsoLogo,
                    sinValidar: sinValidar.idEstatusCartaUsoLogo,
                    pendiente: pendiente.idEstatusCartaUsoLogo,
                }
            }
            state.estatusCartaLogo = action.payload
        })
        builder.addCase(getEstatusCobro.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload && action.payload.length > 0) {
                const registrado = action.payload.find(item => item.cveEstatusCobro === CVE_ESTATUS_COBRO.registrado);
                const revision = action.payload.find(item => item.cveEstatusCobro === CVE_ESTATUS_COBRO.revision);
                const confirmado = action.payload.find(item => item.cveEstatusCobro === CVE_ESTATUS_COBRO.confirmado);
                const rechazado = action.payload.find(item => item.cveEstatusCobro === CVE_ESTATUS_COBRO.rechazado);
                const exento = action.payload.find(item => item.cveEstatusCobro === CVE_ESTATUS_COBRO.exento);
                const revertir = action.payload.find(item => item.cveEstatusCobro === CVE_ESTATUS_COBRO.revertir);

                state.idsEstatusCobro = {
                    registrado: registrado.idEstatusCobro,
                    revision: revision.idEstatusCobro,
                    confirmado: confirmado.idEstatusCobro,
                    rechazado: rechazado.idEstatusCobro,
                    exento: exento.idEstatusCobro,
                    revertir: revertir.idEstatusCobro,
                }
            }
            state.estatusCobro = action.payload
        })
        builder.addCase(getEstatusRegistroConvocatoria.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload && action.payload.length > 0) {
                
                const registrada = action.payload.find(item => item.cveEstatusRegistroConvocatoria === CVE_ESTATUS_REGISTRO_CONVOCATORIA.registrada);
                const aceptada = action.payload.find(item => item.cveEstatusRegistroConvocatoria === CVE_ESTATUS_REGISTRO_CONVOCATORIA.aceptada);
                const rechazada = action.payload.find(item => item.cveEstatusRegistroConvocatoria === CVE_ESTATUS_REGISTRO_CONVOCATORIA.rechazada);
                const revision = action.payload.find(item => item.cveEstatusRegistroConvocatoria === CVE_ESTATUS_REGISTRO_CONVOCATORIA.revision);

                state.idsEstatusRegistroConvocatoria = {
                    registrada: registrada.idEstatusRegistroConvocatoria,
                    revision: revision.idEstatusRegistroConvocatoria,
                    aceptada: aceptada.idEstatusRegistroConvocatoria,
                    rechazada: rechazada.idEstatusRegistroConvocatoria,
                }
            }
            state.estatusRegistroConvocatoria = action.payload
        })
        builder.addCase(getEstatusEntidad.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload && action.payload.length > 0) {
                const registrada = action.payload.find(item => item.cveEstatusEntidad === CVE_ESTATUS_ENTIDAD.registrado);
                const enRevision = action.payload.find(item => item.cveEstatusEntidad === CVE_ESTATUS_ENTIDAD.enRevision);
                const confirmada = action.payload.find(item => item.cveEstatusEntidad === CVE_ESTATUS_ENTIDAD.confirmada);
                const rechazada = action.payload.find(item => item.cveEstatusEntidad === CVE_ESTATUS_ENTIDAD.rechazada);

                state.idsEstatusEntidad = {
                    registrada: registrada.idEstatusEntidad,
                    enRevision: enRevision.idEstatusEntidad,
                    confirmada: confirmada.idEstatusEntidad,
                    rechazada: rechazada.idEstatusEntidad,
                }
            }
            state.estatusEntidad = action.payload
        })
        builder.addCase(getMetodosPago.fulfilled, (state, action) => {
            state.loading = false;
            state.metodoPago = action.payload
        })
        builder.addCase(getEstatusConsultorAcredito.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload && action.payload.length > 0) {
                const revision = action.payload.find(item => item.cveEstatusConsultorAcreditado === CVE_ESTATUS_CONSULTOR.revision);
                const invitado = action.payload.find(item => item.cveEstatusConsultorAcreditado === CVE_ESTATUS_CONSULTOR.invitado);
                const rechazado = action.payload.find(item => item.cveEstatusConsultorAcreditado === CVE_ESTATUS_CONSULTOR.rechazado);

                state.idsEstatusConsultor = {
                    revision: revision.idEstatusConsultorAcreditado,
                    invitado: invitado.idEstatusConsultorAcreditado,
                    rechazado: rechazado.idEstatusConsultorAcreditado,
                }
            }
            state.estatusConsultorAcreditado = action.payload.payload
        })
        builder.addCase(getAmbitosConvocatoria.fulfilled, (state, action) => {
            state.loading = false;
            state.ambitosConvocatoria = action.payload.payload
        })
        builder.addCase(getRegimenCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.regimen = action.payload
        })
        builder.addCase(getCatalogoEstados.fulfilled, (state, action) => {
            state.loading = false;
            state.estados = action.payload
        })
        builder.addCase(getFormasPago.fulfilled, (state, action) => {
            state.loading = false;
            state.formaPago = action.payload
        })
        builder.addCase(getUsoCfdi.fulfilled, (state, action) => {
            state.loading = false;
            state.usoCfdi = action.payload
        })
        builder.addCase(getDocumentosEvidenciaCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.documentosEvidenciaInstitucional = action.payload
        })
        builder.addCase(getIndicadoresInstrumentoCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.indicadores = action.payload.payload
        })
        builder.addCase(getAllAreas.fulfilled, (state, action) => {
            state.loading = false;
            state.listadoAreas = action.payload.payload
        })
        builder.addCase(getSubambitosInstrumentoCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.subambitos = action.payload.payload
        })
        builder.addCase(getAmbitosInstrumentoCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.ambitos = action.payload.payload
        })
        builder.addCase(getCriteriosInstrumentoCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.criterios = action.payload.payload
        })
        builder.addCase(getEstatusVinculacion.fulfilled, (state, action) => {
            state.loading = false;
            state.estatusVinculacion = action.payload
        })
        builder.addCase(getDocumentosRegistroConvocatoria.fulfilled, (state, action) => {
            state.loading = false;
            state.documentosRegistroConvocatoria = action.payload
        })
        builder.addCase(getIndicadoresCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.indicadores = action.payload
        })
        builder.addCase(getSubAmbitosCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.subambitos = action.payload
        })
        builder.addCase(getAmbitosCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.ambitos = action.payload
        })
        builder.addCase(getCriteriosCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.criterios = action.payload
        })
        builder.addCase(getStatusUsuario.fulfilled, (state, action) => {
            state.loading = false;
            state.estatusUsuario = action.payload
        })
        builder.addCase(getTable.fulfilled, (state, action) => {
            state.loading = false;
            state.tableData = action.payload
        })
        builder.addCase(getPerfilesUsuario.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload && action.payload.length > 0) {
                const colaboradorEntidad = action.payload.find(item => item.cvePerfil === CVE_PERFILES_USUARIO.colaboradorEntidad);
                const encargadoEntidad = action.payload.find(item => item.cvePerfil === CVE_PERFILES_USUARIO.encargadoEntidad);
                const consultorAcreditado = action.payload.find(item => item.cvePerfil === CVE_PERFILES_USUARIO.consultorAcreditado);

                state.idsPerfilUsuario = {
                    colaboradorEntidad: colaboradorEntidad.idPerfil,
                    encargadoEntidad: encargadoEntidad.idPerfil,
                    consultorAcreditado: consultorAcreditado.idPerfil,
                }
            }
            state.perfilUsuario = action.payload
        })
        builder.addCase(getEsquemaCobroCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload && action.payload.length > 0) {
                const offline = action.payload.find(item => item.cveEsquemaCobro === CVE_ESQUEMA_PAGO.offline);
                const online = action.payload.find(item => item.cveEsquemaCobro === CVE_ESQUEMA_PAGO.online);

                state.idsEsquemaCobro = {
                    offline: offline.idEsquemaCobro,
                    online: online.idEsquemaCobro,
                }
            }
            state.esquemaCobro = action.payload
        })  
        builder.addCase(getNivelMadurezCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.nivelMadurez = action.payload
        })
        builder.addCase(getTipoEntidadCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.tipoEntidad = action.payload
        })
        builder.addCase(getPaisCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.pais = action.payload
        })
        builder.addCase(getTipoContactoCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload) {
                const principal = action.payload.find(item => item.cveTipoContactoEntidad === CVE_TIPO_CONTACTO.principal);
                const directorGeneral = action.payload.find(item => item.cveTipoContactoEntidad === CVE_TIPO_CONTACTO.directorGeneral);
                state.idsTipoContacto = {
                    principal: principal ? principal.idTipoContactoEntidad : null,
                    directorGeneral: directorGeneral ? directorGeneral.idTipoContactoEntidad : null
                }

            }
            state.tipoContacto = action.payload
        })
        builder.addCase(getIndustriaCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.industrias = action.payload
        })
        builder.addCase(getSectorCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.sectores = action.payload
        })
        builder.addCase(getSubsectorCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.subsectores = action.payload
        })
        builder.addCase(getTamanioSectorSeCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.tamanioSectorSe = action.payload
        })
        builder.addCase(getSectorSeCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.sectorSe = action.payload
        })
        builder.addCase(getTipoDireccionCatalogo.fulfilled, (state, action) => {
            if (action.payload && action.payload.length > 0) {
                const entrega = action.payload.find(item => item.cveTipoDireccion === CVE_DIRECCION.entrega);
                const fiscal = action.payload.find(item => item.cveTipoDireccion === CVE_DIRECCION.fiscal);
                const correspondencia = action.payload.find(item => item.cveTipoDireccion === CVE_DIRECCION.correspondencia);
                
                state.idsTiposDireccion = {
                    entrega: entrega ? entrega.idTipoDireccion : null,
                    fiscal: fiscal ? fiscal.idTipoDireccion : null,
                    correspondencia: correspondencia ? correspondencia.idTipoDireccion : null,
                }
            }
            state.tipoDireccion = action.payload
        })
        builder.addCase(getRedesCatalogo.fulfilled, (state, action) => {
            state.redes = action.payload
        })
        builder.addCase(getDocumentosCatalogo.fulfilled, (state, action) => {
            if (action.payload && action.payload.length > 0) {
                const membresia = action.payload.find(item => item.cveDocumentoCemefi === CVE_TIPODOC_CEMEFI.membresia);
                const comprobantePago = action.payload.find(item => item.cveDocumentoCemefi === CVE_TIPODOC_CEMEFI.comprobantePago);
                
                if (membresia || comprobantePago){
                    state.idsTipoDocumentoCemefi = {
                        membresia: membresia ? membresia.idDocumentoCemefi : null,
                        comprobante: comprobantePago ? comprobantePago.idDocumentoCemefi : null,
                    }
                }

            }
            state.tipoDocumentos = action.payload
        })
        builder.addCase(getProgramasCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.programas = action.payload
        })
        builder.addCase(getModalidadParticipacion.fulfilled, (state, action) => {
            state.loading = false;
            state.modoParticipacion = action.payload
        })
        builder.addCase(getModalidadPago.fulfilled, (state, action) => {
            state.loading = false;
            
            if (action.payload && action.payload.length > 0) {
                const total = action.payload.find(item => item.cveModalidadPago === CVE_MODO_PAGO.total);
                state.idModoPagoTotal = total.idModalidadPago;
            }
            state.modoPago = action.payload

        })
        builder.addCase(getCantidadCompanion.fulfilled, (state, action) => {
            state.loading = false;
            state.cantidadCompanion = action.payload.payload
        })
        builder.addCase(getRegistroEntidadInformacionGeneral.fulfilled, (state, action) => {
            state.loading = false;
            state.parametros = action.payload.payload
        })
        builder.addCase(getRegistroConvocatorias.fulfilled, (state, action) => {
            state.loading = false;
            state.parametrosConvocatoria = action.payload.payload
        })
        builder.addCase(getAreasCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.areas = action.payload
        })
        builder.addCase(getTamanioConvocatoria.fulfilled, (state, action) => {
            state.loading = false;
            state.tamanioConvocatoria = action.payload.payload
        })
        builder.addCase(getAniosByConvocatoria.fulfilled, (state, action) => {
            state.loading = false;
            state.aniosConvocatoria = action.payload
        })
        builder.addCase(getAniosPrograma.fulfilled, (state, action) => {
            state.loading = false;
            state.aniosPrograma = action.payload
        })
        builder.addCase(getDocumentosCartaLogoCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload && action.payload.length > 0) {
                const cartaLogo = action.payload.find(item => item.cveDocumentoCemefi === CVES_TIPODOCS_USOLOGO.cartaLogo);
                const cartaDictamen = action.payload.find(item => item.cveDocumentoCemefi === CVES_TIPODOCS_USOLOGO.cartaDictamen);
                const manualUso = action.payload.find(item => item.cveDocumentoCemefi === CVES_TIPODOCS_USOLOGO.manualUso);
                const reglas = action.payload.find(item => item.cveDocumentoCemefi === CVES_TIPODOCS_USOLOGO.reglasUso);
                const logoEsr = action.payload.find(item => item.cveDocumentoCemefi === CVES_TIPODOCS_USOLOGO.logoEsr);
                
                state.idsTiposDocumentoCartaLogo = {
                    cartaLogo: cartaLogo ? cartaLogo.idDocumentoCemefi : null,
                    cartaDictamen: cartaDictamen ? cartaDictamen.idDocumentoCemefi : null,
                    manualUso: manualUso ? manualUso.idDocumentoCemefi : null,
                    reglas: reglas ? reglas.idDocumentoCemefi : null,
                    logoEsr: logoEsr ? logoEsr.idDocumentoCemefi : null,
                }
            }
            state.tiposDocumentoCartaLogo = action.payload
        })

        builder.addCase(getDocumentosRevertirCatalogo.fulfilled, (state, action) => {
            state.loading = false;
            state.documentosRevertir = action.payload
        })
        builder.addCase(getDocumentosConsultorAcreditado.fulfilled, (state, action) => {
            state.loading = false;
            state.documentosConsultorAcreditado = action.payload
        })
        builder.addCase(getEstatusInstrumentoRegistro.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload && action.payload.length > 0) {
                const asignado = action.payload.find(item => item.cveEstatusInstrumentoRegistro === INSTRUMENTOS_CVE_ESTATUS.asignado);
                const enProceso = action.payload.find(item => item.cveEstatusInstrumentoRegistro === INSTRUMENTOS_CVE_ESTATUS.enProceso);
                const liberado = action.payload.find(item => item.cveEstatusInstrumentoRegistro === INSTRUMENTOS_CVE_ESTATUS.liberado);
                
                state.idsEstatusInstrumentoRegistro = {
                    asignado: asignado ? asignado.idEstatusInstrumentoRegistro : null,
                    enProceso: enProceso ? enProceso.idEstatusInstrumentoRegistro : null,
                    liberado: liberado ? liberado.idEstatusInstrumentoRegistro : null,
                }
            }
            state.estatusInstrumentoRegistro = action.payload
        })
    }
})

export const catalogosServiceState = (state) => state.catalogosService;
export const {resetSegementos, resetCatalogos, resetRegistro} = catalogosServiceSlice.actions;


export default catalogosServiceSlice.reducer;