import { getRequest, postRequest, putRequest } from "./http-service";

const url = process.env.REACT_APP_USUARIOS_SERVICE_URL;

export const getUsersByArea = async (data) => {
    const requestUrl = `${url}area/${data.area}/usuario`;
    return await getRequest(requestUrl, data.token);
}

export const getUserById = async (data) => {
    const requestUrl = `${url}area/${data.area}/usuario/${data.idUsuario}`;
    return await getRequest(requestUrl, data.token);  
}

export const getFederatedUser = async (data) => {
    const requestUrl = `${url}area/${data.area}/usuario/usuario-federado/${data.idUsuario}`;
    return await getRequest(requestUrl, data.token);  
}

export const postUsuario = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/usuario`;
    return await postRequest(requestUrl, data.token, {payload: body});
}


export const putUsuario = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/usuario/${data.idUsuario}`;
    return await putRequest(requestUrl, data.token, {payload: body});
}

export const postUsuarioToGroup = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/grupo`;
    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
}

export const putUsuarioToGroup = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/grupo/${data.idPerfilUsuario}`;
    const resp = await putRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
}

export const putUsuarioFederado = async (data, body) => {
    const requestUrl = `${url}usuario/${data.idUsuario}/area/${data.area}`;
    return await putRequest(requestUrl, data.token, {payload: body});
}

export const getUsersByUser = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/usuarios/usuario-federado/${data.idUsuario}`;
    return await getRequest(requestUrl, data.token, {payload: body});
}