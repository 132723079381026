/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { changeConfirmModal, changeStep, registroConvocatoriaState, triggerSaveDir } from "../../slices/registroConvocatoriaSlice";
import { DOCUMENTOS_STEP, MEMBRESIA_STEP, SERVICIO_ADICIONALES_STEP } from "../../app/constantes";
import DireccionEntregaAdicional from "./direccion-entrega-adicionales";
import { useState } from "react";
import { useEffect } from "react";
import ConfirmModal from "../generales/confirmModal";
import { useRef } from "react";
import { appState } from "../../slices/appSlice";
import { getMessageByModulo } from "../../services/catalogos";
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';

export default function DireccionEntrega({calculateProgress}) {
    const dispatch = useDispatch();
    const registrosConvocatoriaSt = useSelector(registroConvocatoriaState);
    const appSt = useSelector(appState);
    const [adicionales, setAdicionales] = useState(null);
    const [messages, setMessages] = useState(null);
    const [modalData, setModalData] = useState({
        show: false,
        title: '',
        desc: '',
        action: () => goToNextStep()
    });
    const modalRef = useRef(modalData);
    modalRef.current = modalData;


    const prevStep = () => {
        dispatch(changeStep(SERVICIO_ADICIONALES_STEP));
    }

    const nextStep = () => {
        dispatch(triggerSaveDir(true));
        setTimeout((e) => {
            validateNext(e)
        }, 2000)
    }
    
    const validateNext = (e) => {
        if (!modalRef.current.show) {
            goToNextStep();
        } else {
            console.log('no')
        }
    }
    
    const goToNextStep = () => {
        closeModal();
        if (registrosConvocatoriaSt.membresiaActiva && !registrosConvocatoriaSt.empresaSeleccionada.isMiembro  && appSt.serviceData.area === 1) {
            dispatch(changeStep(MEMBRESIA_STEP));
        } else {
            dispatch(changeStep(DOCUMENTOS_STEP));
        }

    }

    // Cierra Modal de confirmacion
    const closeModal = () => {
        dispatch(changeConfirmModal(false));
        dispatch(triggerSaveDir(false));
        setModalData({
            ...modalData,
            show: false
        })
    }

    /**
     * Tips
     */
    const getMessages = async () => {
        if (!messages) {
            const resp = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'REGISTROCONVOCATORIA', 'DIRECCIONENTREGA ');
            if (resp.payload && resp.payload.valor) {
                setMessages({...messages, topMessage: resp.payload.valor})
            }
        }
    }

    useEffect(() => {
        if (registrosConvocatoriaSt.entidadesAdicionales) {
            setAdicionales(registrosConvocatoriaSt.entidadesAdicionales);
        }
    }, [registrosConvocatoriaSt.entidadesAdicionales]);

    useEffect(() => {
        if (registrosConvocatoriaSt.showConfirmModal) {
            setModalData({
                show: true,
                title: 'Continuar',
                desc: 'Faltan campos requeridos, no se guardará la información de la dirección',
                info: null,
                action: () => goToNextStep()
            })

        }
    }, [registrosConvocatoriaSt.showConfirmModal])

    useEffect(() => {
        if (!messages) {
            getMessages();            
        }        
    }, [])

    return (
        <div className="registro-step direccion-entrega">
            {messages && appSt.areaData.idAreaCemefi === 2 &&
                <div className="tip">
                    <InfoIcon />
                    <p>{messages.topMessage}</p>
                </div>
            }

            <DireccionEntregaAdicional calculateProgress={calculateProgress} empresa={registrosConvocatoriaSt.empresaSeleccionada}/>

            {(registrosConvocatoriaSt.empresaSeleccionada.isCorporativo || registrosConvocatoriaSt.empresaSeleccionada.isSedeMarca || registrosConvocatoriaSt.empresaSeleccionada.isMiembro) && adicionales && adicionales.length > 0 && 
                adicionales.map((entidad, index) => {
                    return <DireccionEntregaAdicional key={index} empresa={entidad} adicional={true}/>
                })
            }
            <div className="actions">
                <button onClick={prevStep}>Regresar</button>
                <button onClick={nextStep}>Continuar</button>
            </div>
            <ConfirmModal data={modalData} cancel={closeModal} />
        </div>
    )
}