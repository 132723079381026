import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {  getEvidenciaRespuesta, getEvidenciaRespuestaNm, getInstrumentoProgressReportJSON, getProgresoAmbito, getProgresoCriterio, getProgresoIndicador, getProgresoSubAmbito, getRegistroInstrumento, getTotalAmbito, getTotalCriterio, getTotalIndicador, getTotalSubAmbito } from '../services/instrumento';

const initialState = {
    loading: false,
    instrumento: null,
    instrumentos: null,
    preguntas: null,
    preguntaSeleccionada: null,
    lastProgress: null,
    criterioSelected: null,
    criterioTotal: 0,
    criterioProgress: 0,
    ambitoSelected: null,
    ambitoTotal: 0,
    ambitoProgress: 0,
    subambitoSelected: null,
    subambitoTotal: 0,
    subambitoProgress: 0,
    indicadorSelected: null,
    indicadorTotal: 0,
    indicadorProgress: 0,
    query: '',
    evidencias: null,
    respuestaSeleccionada: null,
    instrumentoReport: null,
    modalReporte: {
        show: false,
        data: null
    }
}

export const obtenerProgresoIndicador = createAsyncThunk('instrumento/obtenerProgresoIndicador', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getProgresoIndicador(data);
    } catch (error) {
        console.log(error.response, error); 
        return rejectWithValue({success: false})
    }
    return resp;
})


export const obtenerTotalIndicador = createAsyncThunk('instrumento/obtenerTotalIndicador', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getTotalIndicador(data);
    } catch (error) {
        console.log(error.response, error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const obtenerProgresoSubAmbito = createAsyncThunk('instrumento/obtenerProgresoSubAmbito', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getProgresoSubAmbito(data);
    } catch (error) {
        console.log(error.response, error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const obtenerTotalSubAmbito = createAsyncThunk('instrumento/obtenerTotalSubAmbito', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getTotalSubAmbito(data);
    } catch (error) {
        console.log(error.response, error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const obtenerProgresoAmbito = createAsyncThunk('instrumento/obtenerProgresoAmbito', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getProgresoAmbito(data);
    } catch (error) {
        console.log(error.response, error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const obtenerTotalAmbito = createAsyncThunk('instrumento/obtenerTotalAmbito', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getTotalAmbito(data);
    } catch (error) {
        console.log(error.response, error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const obtenerProgresoCriterio = createAsyncThunk('instrumento/obtenerProgresoCriterio', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getProgresoCriterio(data);
    } catch (error) {
        console.log(error.response, error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const obtenerTotalCriterio = createAsyncThunk('instrumento/obtenerTotalCriterio', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getTotalCriterio(data);
    } catch (error) {
        console.log(error.response, error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const obtenerInstrumentoMedicionConvocatoria = createAsyncThunk('instrumento/obtenerInstrumentoMedicionConvocatoria', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getRegistroInstrumento(data);
    } catch (error) {
        console.log(error.response, error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getEvidencias = createAsyncThunk('instrumento/getEvidencias', async (data, {rejectWithValue}) => {
    let resp;
    try {
        if (data.type !== 'nm') {
            resp = await getEvidenciaRespuesta(data);
        } else {
            resp = await getEvidenciaRespuestaNm(data);
        }
    } catch (error) {
        console.log(error.response, error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getInstrumentoReport = createAsyncThunk('instrumento/getInstrumentoReport', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getInstrumentoProgressReportJSON(data);
    } catch (error) {
        console.log(error.response, error); 
        return rejectWithValue({success: false})
    }
    return resp;
})


export const instrumentoServicesSlice = createSlice({
    name: 'instrumentoServices',
    initialState,
    reducers: {
        resetInstrumento: (state) => {
            state.preguntaSeleccionada = null;
            state.preguntas = null;
            state.instrumento = null;
            state.instrumentos = null;
            state.indicadorSelected = null;
            state.indicadores = null;
            state.preguntaSeleccionada = null;
            state.criterioTotal = 0;
            state.criterioProgress = 0;
            state.ambitoProgress = 0;
            state.ambitoTotal = 0;
            state.subambitoProgress = 0;
            state.subambitoTotal = 0;
            state.indicadorProgress = 0;
            state.indicadorTotal = 0;
            state.lastProgress = 0;
            state.respuestaSeleccionada = null;
            state.indicadorSelected = null;
            state.ambitoSelected = null;
            state.subambitoSelected = null;
            state.criterioSelected = null;
            state.modalReporte = null;
        },
        changeCriterioSelected:  (state, action) => {
            state.criterioSelected = action.payload
        },
        changeSubambitoSelected:  (state, action) => {
            state.subambitoSelected = action.payload
        },
        changeAmbitoSelected:  (state, action) => {
            state.ambitoSelected = action.payload
        },
        changeIndicadorSelected:  (state, action) => {
            state.indicadorSelected = action.payload
        },
        changeRespuestaSeleccionada:  (state, action) => {
            state.respuestaSeleccionada = action.payload
        },
        changeLastProgress: (state, action) => {
            state.lastProgress = action.payload
        },
        changeCriterioProgress: (state, action) => {
            state.criterioProgress = action.payload
            state.criterioTotal = action.payload
        },
        changeAmbitoProgress: (state, action) => {
            state.ambitoProgress = action.payload
            state.ambitoTotal = action.payload
        },
        changeSubambitoProgress: (state, action) => {
            state.subambitoProgress = action.payload
            state.subambitoTotal = action.payload
        },
        changeIndicadorProgress: (state, action) => {
            state.indicadorProgress = action.payload
            state.indicadorTotal = action.payload
        },
        changePregunta: (state, action) => {
            state.preguntaSeleccionada = action.payload
        },
        changePreguntas: (state, action) => {
            state.preguntas = action.payload;
        },
        setInstrumento: (state, action) => {
            state.instrumento = action.payload;
        },
        changeInstrumento: (state, action) => {
            state.instrumento = action.payload
            state.preguntaSeleccionada = null;
            if (action.payload.cemIndicadorCrcInstrumentoMedicions.length > 0 ) {
                state.indicadorSelected = action.payload.cemIndicadorCrcInstrumentoMedicions[0];
                state.indicadores = action.payload.cemIndicadorCrcInstrumentoMedicions;
            } else {
                state.indicadorSelected = null
            }
        },
        changeIndicador: (state, action) => {
            state.preguntaSeleccionada = null;
            state.indicadorSelected = action.payload
        },
        setQuery: (state, action) => {
            state.query = action.payload
        },
        changeModalReport: (state, action) => {
            state.modalReporte = action.payload
        },
        resetInstrumentoState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase((
            getInstrumentoReport.pending ||
            getEvidencias.pending ||
            obtenerProgresoIndicador.pending ||
            obtenerProgresoSubAmbito.pending ||
            obtenerProgresoAmbito.pending ||
            obtenerProgresoCriterio.pending ||
            obtenerTotalIndicador.pending ||
            obtenerTotalSubAmbito.pending ||
            obtenerTotalAmbito.pending ||
            obtenerTotalCriterio.pending ||
            obtenerInstrumentoMedicionConvocatoria.pending),
        (state) => {
            state.loading = true
        })
        builder.addCase((
            getInstrumentoReport.rejected ||
            getEvidencias.rejected ||
            obtenerProgresoIndicador.rejected ||
            obtenerProgresoSubAmbito.rejected ||
            obtenerProgresoAmbito.rejected ||
            obtenerProgresoCriterio.rejected ||
            obtenerTotalIndicador.rejected || 
            obtenerTotalSubAmbito.rejected || 
            obtenerTotalAmbito.rejected || 
            obtenerTotalCriterio.rejected || 
            obtenerInstrumentoMedicionConvocatoria.rejected),
        (state) => {
            state.loading = false
        })
        builder.addCase(getEvidencias.fulfilled, (state, action) => {
            state.loading = false;
            console.log(action.payload)
            state.evidencias = action.payload;
        })
        builder.addCase(obtenerProgresoIndicador.fulfilled, (state, action) => {
            state.loading = false;
            state.indicadorProgress = action.payload[0].totalRespuestasPorIndicador;
        })
        builder.addCase(obtenerProgresoSubAmbito.fulfilled, (state, action) => {
            state.loading = false;
            state.subambitoProgress = action.payload[0].totalRespuestasPorSubAmbito;
        })
        builder.addCase(obtenerProgresoAmbito.fulfilled, (state, action) => {
            state.loading = false;
            state.ambitoProgress = action.payload[0].totalRespuestasPorAmbito;
        })
        builder.addCase(obtenerProgresoCriterio.fulfilled, (state, action) => {
            state.loading = false;
            state.criterioProgress = action.payload[0].totalRespuestasPorCriterio;
        })
        builder.addCase(obtenerTotalIndicador.fulfilled, (state, action) => {
            state.loading = false;
            state.indicadorTotal = action.payload[0].contadorIndicador;
        })
        builder.addCase(obtenerTotalSubAmbito.fulfilled, (state, action) => {
            state.loading = false;
            state.subambitoTotal = action.payload[0].contadorSubAmbito;
        })
        builder.addCase(obtenerTotalAmbito.fulfilled, (state, action) => {
            state.loading = false;
            state.ambitoTotal = action.payload[0].contadorAmbito;
        })
        builder.addCase(obtenerTotalCriterio.fulfilled, (state, action) => {
            state.loading = false;
            state.criterioTotal = action.payload[0].contadorCriterio;
        })
        builder.addCase(getInstrumentoReport.fulfilled, (state, action) => {
            state.loading = false;
            state.instrumentoReport = action.payload.payload;
        })
        builder.addCase(obtenerInstrumentoMedicionConvocatoria.fulfilled, (state, action) => {
            state.loading = false;
            state.preguntas = action.payload;
            if (state.preguntaSeleccionada) {
                const selected = action.payload.find(question => question.idPreguntaIndicador === state.preguntaSeleccionada.idPreguntaIndicador);
                if (selected) {
                    state.preguntaSeleccionada = selected;
                }
            }
        })
    }
})

export const {resetInstrumentoState, changeModalReport, setInstrumento, changeAmbitoSelected, changeCriterioSelected, changeSubambitoSelected, changeIndicadorSelected, changeRespuestaSeleccionada, changeLastProgress, setQuery, changePregunta, changeInstrumento, changeIndicador, resetInstrumento, changePreguntas, changeCriterioProgress, changeAmbitoProgress, changeSubambitoProgress, changeIndicadorProgress } = instrumentoServicesSlice.actions;
export const instrumentoServicesState = (state) => state.instrumentoServices;

export default instrumentoServicesSlice.reducer;