import { CONVOCATORIAS_ID } from "./constantes";

/**
* Change on the inputs
* @param {*} change 
*/
export const handleChangeGlobal = (change, setForm, form, type) => {
  let target = change.target;
  switch (type) {
    case 'upper':
      target.value = target.value.toUpperCase();
      break;
    case 'lower':
      target.value = target.value.toLowerCase();
      break;
    case 'checkbox':
      target.value = target.checked
      break
    case 'radio':
      target.checked = target.value === 'si';
      break
    default:
      break;
  }
  setForm({ ...form, [change.target.name]: { ...form[change.target.name], value: change.target.value } });
}

/**
 * Validate form
 */
 export const validateForm = (form) => {
  let invalidItems = [];

  for (const property in form) {
    const obj = form[property];
    if ((obj.required && obj.value === '') || obj.invalid) {
      invalidItems.push(obj);
      obj.invalid = true;
    }
  }

  return invalidItems;
}

/**
 * Format form
 * @returns 
 */
 export const formatForm = (form) => {
  let formEx = {}
  for (const property in form) {
    const obj = form[property];
    formEx[property] = obj.value;
  }
  return formEx;
}

export const formatCurrency = (money) => {
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  return formatter.format(money);
}

export const findCatalogById = (id, catalog, key, descKey) => {
  const filt = catalog.filter(item => item[key] === id);
  if (filt.length > 0) {
    return filt[0][descKey];
  } else {
    return id;
  }
}

export const fillForm = (form, object) => {
  const f = JSON.parse(JSON.stringify(form));

  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const element = object[key];
      if (f[key] && isValueNullOrEmpty(element)) {
        f[key].value = element;
        f[key].invalid = false;
      }
    }
  }

  return f;
}

export const getBase64 = (file) => {
  return new Promise(resolve => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL.replace(/^.*,/, ''));
    };
  });
}

export const cloneList = (list) => {
  return JSON.parse(JSON.stringify(list));
}

export const isUserRegistered = (user) => {
  return user !== null && user.registrado;
}

export const isValueNullOrEmpty = (value) => {
  return value && value !== null && value !== '';
}

export const isEntidadInvididual = (entidad) => {
  return !entidad.isCorporativo && !entidad.isSedeMarca && !entidad.isMiembro
}

export const isConvocatoriaInsignia = (convocatoria) => {
  return  convocatoria.idConvocatoria === CONVOCATORIAS_ID.insigniaInclusionSocial || convocatoria.idConvocatoria === CONVOCATORIAS_ID.insigniaInversionSocial
}

export const validaConvocatoriaDoc = (catalog, documentosConvocatoria) => {
  let valid = false;
  const required = [];
  const uploaded = [];
  if (catalog && documentosConvocatoria) {
    catalog.forEach(req => {
      if (req.isRequerido === 1) {
        required.push(req);
      }
      const exist = documentosConvocatoria.find(doc => doc.idDocumentoCemefi === req.idDocumentoCemefi && doc.idEstatusRegistro !== 0);
      if (exist) {
        uploaded.push(exist);
      }
    })
    if (required.length > 0 && uploaded.length >= required.length) {
      valid = true;
    } else if (required.length === 0) {
      valid = true;
    }
  }
  return valid;
}

/**
   * Checamos cuando archivos son requeridos en para el registro, para hacer la validacion
   */
 export const validateDocs = (catalogo, empresaSeleccionada, idDocumentoMembresia) => {
  let valid = false;
  const required = [];
  const uploaded = [];

  let memebresiaDoc = null;
  let membresia = undefined;
  // setteamos los documentos requeridos q menos pago de membresia
  catalogo.forEach(tipoMembresia => {
    if (tipoMembresia.cveDocumentoCemefi !== 'PAGOMEMBRESIAS') {
      required.push(tipoMembresia)
    }
  });

  // setteamos los documentos subidos por el usuario
  if (empresaSeleccionada.cemDocumentoCrcEntidads.length > 0) {
    empresaSeleccionada.cemDocumentoCrcEntidads.forEach(doc => {
      if (doc.idEstatusRegistro !== 0 && idDocumentoMembresia !== null && doc.idDocumentoCemefi !== idDocumentoMembresia) {
        uploaded.push(doc);
      }
      if (doc.idEstatusRegistro !== 0 && idDocumentoMembresia === null) {
        uploaded.push(doc)
      }
    });
  }

  // Buscamos si el usuario tiene membresia
  if (empresaSeleccionada.cemMembresiaCrcEntidads.length > 0) {
    membresia = empresaSeleccionada.cemMembresiaCrcEntidads.find(member => member.idEstatusRegistro === 1 && member.idEstatusRegistro !== 0);
    memebresiaDoc = empresaSeleccionada.cemDocumentoCrcEntidads.find(doc => idDocumentoMembresia && doc.idDocumentoCemefi === idDocumentoMembresia && doc.idEstatusRegistro !== 0);
    if (memebresiaDoc !== undefined) {
      required.push(memebresiaDoc);
      uploaded.push(memebresiaDoc)
    }
  }
  
  if (
    (required.length <= uploaded.length && membresia === undefined) || 
    (required.length <= uploaded.length &&  membresia !== undefined && (memebresiaDoc !== undefined && memebresiaDoc !== null))) {
    valid = true;
  }

  return valid  
}

export const validateIndustria = (empresaSeleccionada) => {
  // tal ves vuelvan
  // const productos = [];
  // const servicios = [];

  // empresaSeleccionada.cemProductoEntidads.forEach(producto => {
  //   if (producto.idEstatusRegistro !== 0) {
  //     productos.push(producto);
  //   }
  // })

  // empresaSeleccionada.cemServicioEntidads.forEach(servicio => {
  //   if (servicio.idEstatusRegistro !== 0) {
  //     servicios.push(servicio);
  //   }
  // })

  return empresaSeleccionada.idSubsectorInegi

}

export const validateContactos = (contactos, ids) => {
  let valid = false;
  let validos = contactos.filter(contacto => contacto.idEstatusRegistro !== 0);
  let principal = contactos.filter(contacto => contacto.idEstatusRegistro !== 0 && contacto.idTipoContactoEntidad === ids.principal);
  let directorGeneral = contactos.filter(contacto => contacto.idEstatusRegistro !== 0 && contacto.idTipoContactoEntidad === ids.directorGeneral);
  if (validos.length > 2 && principal.length > 0 && directorGeneral.length > 0) {
    valid = true;
  }
  return valid;
}

export const formatValor = (resp) => {
  let value = null;
  if (resp.payload && resp.payload.valor) {
      value = resp.payload.valor.split('<br>');
  }
  return value;
}

export const detectMimeType = (base64String, fileName) => {
  var ext = fileName.substring(fileName.lastIndexOf(".") + 1);
  if (ext === undefined || ext === null || ext === "") ext = "bin";
  ext = ext.toLowerCase();
  const signatures = {
    JVBERi0: "application/pdf",
    R0lGODdh: "image/gif",
    R0lGODlh: "image/gif",
    iVBORw0KGgo: "image/png",
    TU0AK: "image/tiff",
    "/9j/": "image/jpg",
    UEs: "application/vnd.openxmlformats-officedocument.",
    PK: "application/zip",
  };
  for (var s in signatures) {
    if (base64String.indexOf(s) === 0) {
      var x = signatures[s];
      // if an office file format
      if (ext.length > 3 && ext.substring(0, 3) === "ppt") {
        x += "presentationml.presentation";
      } else if (ext.length > 3 && ext.substring(0, 3) === "xls") {
        x += "spreadsheetml.sheet";
      } else if (ext.length > 3 && ext.substring(0, 3) === "doc") {
        x += "wordprocessingml.document";
      }
      // return
      return x;
    }
  }
  // if we are here we can only go off the extensions
  const extensions = {
    xls: "application/vnd.ms-excel",
    ppt: "application/vnd.ms-powerpoint",
    doc: "application/msword",
    xml: "text/xml",
    mpeg: "audio/mpeg",
    mpg: "audio/mpeg",
    txt: "text/plain",
  };
  for (var e in extensions) {
    if (ext.indexOf(e) === 0) {
      var xx = extensions[e];
      return xx;
    }
  }
  // if we are here – not sure what type this is
  return "unknown";
}

export const getContentName = (filePath) => {
  const path = filePath.split('/');
  return path.slice(-1);
}

/**
     * Checamos el array
     */
export const checkAcceptArray = (doc) => {
  if (doc.extension) {
      const exts = doc.extension.split(',');
      let accept = '';
      let acceptArray = [];
      exts.forEach(ext => {
          accept = `${accept} .${ext.toLowerCase()}`;
          acceptArray.push(`.${ext.replace(' ', '').toLowerCase()}`);
      });
      return {
          accept,
          acceptArray
      }
  }
}

export const getMontosRegalias = (item, programa) => {
  let montoRegalia
  let montoCouta
  if (programa.montoParticipacion === item.montoCobro) {
    montoRegalia = item.montoCobro
    montoCouta = item.montoCobro
  }
  if (programa.montoParticipacion < item.montoCobro) {
    montoRegalia = programa.montoParticipacion
    montoCouta = programa.montoParticipacion
  }
  if (programa.montoParticipacion > item.montoCobro) {
    montoRegalia = programa.montoParticipacion
    montoCouta = item.montoCobro - programa.montoParticipacion
  }
  return {
    montoRegalia, montoCouta
  }
}

export const getToNotification = (data, idsContacto) => {
  const array = []
  const principal = data.payload.cemContactoCrcEntidads.find(contacto => contacto.idTipoContactoEntidad === idsContacto.principal);
  data.payload.cemContactoCrcEntidads.forEach(item => {
    if (item.isNotificaCorreo) {
      array.push({
        email: item.email,
        name: item.nombre
      })
    }
  })

  if (array.length > 0) {
    return array
  } else if (principal) {
    return [{
      email: principal.email,
      name: principal.nombre
    }]
  }
}