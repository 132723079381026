import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fillForm } from '../../app/commons';
import { CVE_DIRECCION, MEXICO_ID } from '../../app/constantes';
import { ReactComponent as EmpresaIcon } from '../../assets/icons/empresa.svg';
import { getByCP, getCPbyId } from '../../services/sepomex';
import { appState, setLoading } from '../../slices/appSlice';
import { catalogosServiceState, getPaisCatalogo, getTipoDireccionCatalogo } from '../../slices/catalogosServiceSlice';
import InputComp from "./inputComponent";
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';


export default function DireccionComponent({ form, setForm, cveDireccion, empresaSeleccionada, setCorrespondencia, setDirEntrega, disabled, isFiscal, aviso }) {
    // Redux State
    const dispatch = useDispatch();
    const catalogosSt = useSelector(catalogosServiceState);
    const appSt = useSelector(appState);

    //Local State
    const [isNational, setisNational] = useState(true);
    const [label, setLabel] = useState('');
    const [error, setError] = useState({
        show: false,
        message: ''
    });
    const [catalogoCiudad, setCatalogoCiudad] = useState([]);
    const [catalogoEstados, setCatalogoEstados] = useState([]);
    const [catalogoMunicipio, setCatalogoMunicipio] = useState([]);
    const [catalogoColonia, setCatalogoColonia] = useState([]);
    const [asentamiento, setAsentamiento] = useState(null);
    
    /**
     * LLenar los campos de direccion con la respuesta del servicio de Codigo postal
     */
    const fillInputs = async (value, updateData, idAsentamiento) => {
        setError({
            show: false,
            message: ''
        })
        const data = {
            token: appSt.token,
            cp: parseInt(value)
        }
        const resp = await getByCP(data);

        if (resp.payload.ciudades.length > 0) {
            let clone;
            if (updateData) {
                clone = fillForm(form, updateData);
                clone.codigoPostal.value = resp.payload.codigoPostal;
            } else {
                clone = JSON.parse(JSON.stringify(form));
            }
            setCatalogoCiudad(resp.payload.ciudades);
            if (resp.payload.ciudades.length > 0) {
               setDesciptions('ciudad', resp.payload.ciudades[0].descripcion, clone);
            }
            setCatalogoEstados(resp.payload.estados);
            if (resp.payload.estados.length > 0) {
               setDesciptions('estado', resp.payload.estados[0].descripcion, clone);
            }
            setCatalogoMunicipio(resp.payload.municipios);
            if (resp.payload.estados.length > 0) {
                setDesciptions('municipioAlcaldia', resp.payload.municipios[0].descripcion, clone);
             }
            setCatalogoColonia(resp.payload.colonias);
            if (resp.payload.colonias.length > 0) {
                if (idAsentamiento || asentamiento) {
                    const v = resp.payload.colonias.find(col => col.idColonia === idAsentamiento || (asentamiento && col.idColonia === asentamiento.idColonia));
                    if (v) {
                        setAsentamiento(v);
                        setDesciptions('colonia', v.descripcion, clone);
                        // Id Asentamiento tendria q ser solo el id
                        // Esta aplicaba para ambos casos, si existia "v" o no, lo movi porq hubo un caso donde no existia v y el asentamiento no coincidia con el q se queria guardar
                        clone.idCodigoPostal.value = asentamiento ? asentamiento.idCodigoPostal : v.idCodigoPostal ? v.idCodigoPostal : idAsentamiento;
                    } else {
                        setDesciptions('colonia', resp.payload.colonias[0].descripcion, clone);
                        // Deje este porq si no existe "v" el idCodigoPostal tendria q ser el primero del de la respuesta, de lo contrario podria tomar el q ya estaba guardado
                        // Si hay mas colonias puede q este dato siempre ponga la primera 
                        clone.idCodigoPostal.value = resp.payload.colonias[0].idCodigoPostal;
                    }
                } else {
                    setDesciptions('colonia', resp.payload.colonias[0].descripcion, clone);
                    clone.idCodigoPostal.value = resp.payload.colonias[0].idCodigoPostal;
                }
             }
             setForm(clone);
        } else {
            setError({
                show: true,
                message: 'No se encontró el código postal, favor de intentar con otro'
            })
        }
    }

    /**
     * LLenamos la descripcioon de los campos de direccion
     * Estos solo sirven para referencia, no se llevan al servicio
     */
    const setDesciptions = (formKey, value, clone) => {
        clone[formKey].value = value
    }

    /**
     * Cada q se cambie la colonia, cambiamos su id q es requerido para el servicio
     */
    const setCPId = async (e) => {
        const updatedForm = JSON.parse(JSON.stringify(form));
        const val = catalogoColonia.filter(cat => cat.descripcion === e.target.value);

        if (val.length > 0) {
            updatedForm.idCodigoPostal.value = val[0].idCodigoPostal;
        }
        setForm(updatedForm);
    }

    const checkNationality = (value) => {
        if (value === MEXICO_ID) {
            setisNational(true);
        } else {
            setisNational(false);
        }
    }

    /**
     * LLenamos el form si ya habia un registro
     */
    const populateInputs = async (directionId) => {
        dispatch(setLoading(true));
        setAsentamiento(null);
        const filt = empresaSeleccionada.cemDireccionEntidads.filter(dir => dir.idTipoDireccion === directionId);
        
        if (filt.length > 0 && filt[0].idCodigoPostal) {
            const direction = filt[0];
            const data = {
                token: appSt.token,
                idCp: direction.idCodigoPostal
            }
            const resp = await getCPbyId(data);
            await fillInputs(resp.payload.dCodigo, direction, resp.payload.idAsentamiento);
        } else if (filt.length > 0 && filt[0].idPais !== MEXICO_ID) {
            setisNational(false);
            const f = fillForm(form, filt[0]);
            setForm(f);
        }
        dispatch(setLoading(false));
    }

    useEffect(() => {
        switch (cveDireccion) {
            case CVE_DIRECCION.correspondencia:
                setLabel('Dirección Correspondencia');                
                break;
            case CVE_DIRECCION.fiscal:
                setLabel('Dirección Fiscal');
                break;
            case CVE_DIRECCION.entrega:
                setLabel('Dirección de Entrega');
                break;
            default:
                break;
        }

        if (catalogosSt.pais === null) {
            dispatch(getPaisCatalogo(appSt.serviceData))
        }
        if (catalogosSt.tipoDireccion === null && !catalogosSt.loading) {
            dispatch(getTipoDireccionCatalogo(appSt.serviceData))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // Caso para registro, se setea la direccion de correspondencia
        if (empresaSeleccionada && empresaSeleccionada.cemDireccionEntidads.length > 0 && catalogosSt.tipoDireccion && !setCorrespondencia && !setDirEntrega && !isFiscal) {
            const dirValue = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.correspondencia);
            if (dirValue) {
                populateInputs(dirValue.idTipoDireccion);
            } else {
                console.log('no hay direccion de correspondencia asociada', catalogosSt.tipoDireccion, CVE_DIRECCION.correspondencia)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empresaSeleccionada])

    useEffect(() => {
        //caso para registro, se settea direccion fiscal
        if (empresaSeleccionada && empresaSeleccionada.cemDireccionEntidads.length > 0 && catalogosSt.tipoDireccion && isFiscal && !setDirEntrega) {
            const dirValue = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.fiscal);
            if (dirValue) {
                populateInputs(dirValue.idTipoDireccion);
            } else {
                console.log('no hay direccion fiscal asociada', catalogosSt.tipoDireccion, CVE_DIRECCION.fiscal)
            }
        }
        // caso para registro convocatoria, se settea inicialmente la direccion de correspondencia como la de entrega
        if (empresaSeleccionada && empresaSeleccionada.cemDireccionEntidads.length > 0 && catalogosSt.tipoDireccion && !isFiscal && !setDirEntrega) {
            const dirValue = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.correspondencia);
            if (dirValue) {
                populateInputs(dirValue.idTipoDireccion);
            } else {
                console.log('no hay direccion correspondencia asociada', catalogosSt.tipoDireccion, CVE_DIRECCION.correspondencia)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empresaSeleccionada])

    // Caso para registro convocatoria, se settea la direccion de entrega
    useEffect(() => {
        if (setDirEntrega && catalogosSt.tipoDireccion) {
            const dirValue = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.entrega);
            if (dirValue) {
                populateInputs(dirValue.idTipoDireccion);
            } else {
                console.log('no hay direccion de entrega asociada', catalogosSt.tipoDireccion, CVE_DIRECCION.entrega)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setDirEntrega])

    // Caso para cuando regresan al si en es la misma direccion en registro de convocatoria
    useEffect(() => {
        if (setCorrespondencia && catalogosSt.tipoDireccion) {
            const dirValue = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.correspondencia);
            if (dirValue) {
                populateInputs(dirValue.idTipoDireccion);
            } else {
                console.log('no hay direccion correspondencia asociada', catalogosSt.tipoDireccion, CVE_DIRECCION.correspondencia)
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCorrespondencia])

    /**
     * llamamos al servicio si el codigo postal llega a 5 caracteres
     */
    useEffect(() => {
        if (form.codigoPostal.value.length === 3 && (form.idPais.value === Number(MEXICO_ID) || form.idPais.value === MEXICO_ID)) {
            setAsentamiento(null)
        }
        if (form.codigoPostal.value.length === 5 && (form.idPais.value === Number(MEXICO_ID) || form.idPais.value === MEXICO_ID)) {
            fillInputs(form.codigoPostal.value);
        }
        if (form.codigoPostal.value.length === 0 && form.ciudad.value !== '' && form.idPais.value === Number(MEXICO_ID)) {
            const clone = JSON.parse(JSON.stringify(form));
            clone.ciudad.value = '';
            clone.municipioAlcaldia.value = '';
            clone.colonia.value = '';
            setForm(clone);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.codigoPostal.value])

    return catalogosSt.tipoDireccion && <div className="card-registro">
        <div className="head">
            <EmpresaIcon />
            <h3>{label} 
            </h3>
        </div>
        <div className="content direction">
            {error.show && <p className='top-error'>{error.message}</p>}
            {aviso && 
                <div className="tip">
                    <InfoIcon />
                    <div>
                        {aviso.map((p, index) => {
                            return <p key={index}>{p}</p>
                        })}
                    </div>
                </div>
            }
            
            <div className="two-col">
                {catalogosSt.pais && 
                    <InputComp disabled={disabled} onBlurAction={(e) => checkNationality(e.target.value)} label="País" name="idPais" type="select" form={form} setForm={setForm} catalogos={catalogosSt.pais} catalogoValue={'idPais'} catalogoLabel={'descPais'} />                
                }
                {isNational ? <>
                        <InputComp disabled={disabled} label="Código Postal" name="codigoPostal" type="number" form={form} setForm={setForm} max={7} />
                        <InputComp disabled={form.ciudad.value === '' || catalogoCiudad.length <= 1 || disabled} label="Ciudad" name="ciudad" type="select" form={form} setForm={setForm} catalogos={catalogoCiudad} catalogoValue={'descripcion'} catalogoLabel={'descripcion'} />
                        <InputComp disabled={form.estado.value === '' || catalogoEstados.length <= 1 || disabled} label="Estado" name="estado" type="select" form={form} setForm={setForm} catalogos={catalogoEstados} catalogoValue={'descripcion'} catalogoLabel={'descripcion'} />
                        <InputComp disabled={form.municipioAlcaldia.value === '' || catalogoMunicipio.length <= 1 || disabled} label="Municipio" name="municipioAlcaldia" type="select" form={form} setForm={setForm} catalogos={catalogoMunicipio} catalogoValue={'descripcion'} catalogoLabel={'descripcion'} />
                        <InputComp onBlurAction={setCPId} disabled={form.colonia.value === '' || catalogoColonia.length <= 1 || disabled} label="Colonia" name="colonia" type="select" form={form} setForm={setForm} catalogos={catalogoColonia} catalogoValue={'descripcion'} catalogoLabel={'descripcion'} />
                        <InputComp disabled={disabled} label="Municipio / Alcaldia" name="municipioAlcaldia" type="upper" form={form} setForm={setForm} />
                        <div className="two-col">
                            <InputComp disabled={disabled} label="Número Exterior" name="numeroExterior" type="text" form={form} setForm={setForm} />
                            <InputComp disabled={disabled} label="Número Interior" name="numeroInterior" type="text" form={form} setForm={setForm} />
                        </div>
                        <InputComp disabled={disabled} className="calle" label="Calle" name="calle" type="upper" form={form} setForm={setForm} />
                    </>
                    : <>
                        <InputComp disabled={disabled} label="Estado" name="estado" type="upper" form={form} setForm={setForm} />
                        <InputComp disabled={disabled} label="Colonia" name="colonia" type="upper" form={form} setForm={setForm} />
                        <InputComp disabled={disabled} label="Municipio / Alcaldia" name="municipioAlcaldia" type="upper" form={form} setForm={setForm} />
                        <InputComp disabled={disabled} label="Ciudad" name="ciudad" type="upper" form={form} setForm={setForm} />
                        <InputComp disabled={disabled} label="Código Postal" name="codigoPostal" type="text" form={form} setForm={setForm} />
                        <div className="two-col">
                            <InputComp disabled={disabled} label="Número Interior" name="numeroInterior" type="text" form={form} setForm={setForm} />
                            <InputComp disabled={disabled} label="Número Exterior" name="numeroExterior" type="text" form={form} setForm={setForm} />
                        </div>
                        <InputComp disabled={disabled} className="calle" label="Calle" name="calle" type="upper" form={form} setForm={setForm} />
                    </>
                }
            </div>
        </div>
    </div>
}