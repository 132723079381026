/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg"
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg"
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg"
import { ReactComponent as FileIcon } from "../../assets/icons/file.svg"
import { useEffect, useState } from "react"

export default function DocumentComp({
  documentosCargados,
  descDoc,
  doc,
  idDoc,
  deleteDoc,
  download,
  upload,
  onlyView
}) {
  const [uploaded, setUploaded] = useState(null)

  const findDoc = () => {
    const docUploaded = documentosCargados.find(
      (item) => item[idDoc] === doc[idDoc],
    )

    if (docUploaded) {
      setUploaded(docUploaded)
    } else {
      setUploaded(null)
    }
  }

  useEffect(() => {
    if (doc && documentosCargados) {
      findDoc()
    }
  }, [documentosCargados])
  return (
    <div className="document">
        <p>{doc[descDoc]}</p>
        {uploaded && (
          <>
            <FileIcon />
            <div className="actions">
              <button
                className="btn-icon tooltip"
                data-tool="Descargar"
                onClick={() => download(uploaded)}
              >
                <DownloadIcon />
                <p>Descargar</p>
              </button>
              {!onlyView && 
                <button
                    className="btn-icon tooltip"
                    data-tool="Eliminar"
                    onClick={() => deleteDoc(uploaded)}
                >
                    <DeleteIcon />
                    <p>Eliminar</p>
                </button>
              }
            </div>
          </>
        )}
        {!uploaded && !onlyView && (
          <div className="upload">
            <p>Subir Documento</p>
            <UploadIcon />
            <input type="file" onChange={(e) => upload(e, doc)} />
          </div>
        )}
    </div>
  )
}
