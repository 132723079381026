/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { CVE_TIPODOC_COBRO, SECTIONS } from "../../app/constantes";
import { appState, setLoading } from "../../slices/appSlice";
import { catalogosServiceState, getDocumentosCatalogo, getFormasPago, getMetodosPago, getRegimenCatalogo, getUsoCfdi } from "../../slices/catalogosServiceSlice";
import { changeSectionCobroCurso, getCobrosCursos, getPagosCursosDetails, pagosCursosState } from "../../slices/pagosCursosSlice";
import PagosPendientes from "./pagosCursosIncial";
import ListaCobros from "./listaCobrosCursos";
// import Facturas from "./facturas";
import '../../styles/pagos.scss';
import Facturas from "./facturas";
import PagoEnLinea from "./pagoEnLinea";
import { getCobroCursoReport } from "../../services/cursos";

export default function PagosCursos() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const appSt = useSelector(appState);
    const catalogosSt = useSelector(catalogosServiceState);
    const pagosSt = useSelector(pagosCursosState);
    const [categorias, setCategorias] = useState(null);
    const [registrosOrdenPago, setRegistrosOrdenPago] = useState({ pendientes: [], rechazados: [] })
    useEffect(() => {
        dispatch(setLoading(false));
        dispatch(getPagosCursosDetails({
            ...appSt.serviceData,
            idEntidad: params.idEntidad,
            idConvocatoria: pagosSt.currentConvocatoria ? pagosSt.currentConvocatoria.idConvocatoria : null,
            idModalidadPago: catalogosSt.idModoPagoTotal
        }))

        dispatch(getCobrosCursos({
            ...appSt.serviceData,
            idEntidad: params.idEntidad,
            idConvocatoria: pagosSt.currentConvocatoria ? pagosSt.currentConvocatoria.idConvocatoria : null,
        }))
        
        dispatch(getDocumentosCatalogo({...appSt.serviceData, cveTipoDocumento: CVE_TIPODOC_COBRO}));

        if (catalogosSt.usoCfdi === null) {
            dispatch(getUsoCfdi(appSt.serviceData));
        }

        if (catalogosSt.regimen === null) {
            dispatch(getRegimenCatalogo(appSt.serviceData));
        }

        if (catalogosSt.formaPago === null) {
            dispatch(getFormasPago(appSt.serviceData));
        }

        if (catalogosSt.metodoPago === null) {
            dispatch(getMetodosPago(appSt.serviceData));
        }
    }, []);

    useEffect(() => {
        if (pagosSt.cobrosAplicados && catalogosSt.idsEstatusCobro) {
            prepareValidations()
        }
    }, [pagosSt.cobrosAplicados, catalogosSt.idsEstatusCobro])

    useEffect(() => {
        if (categorias) {
            runValidations()
        }
    }, [categorias])
    useEffect(() => {
        if (pagosSt.pagoDetails && pagosSt.pagoDetails.cobroCursosDet && catalogosSt.idsEstatusCobro) {
            const newDet = [];
            for (let r of pagosSt.pagoDetails.cobroCursosDet) {
                if (r.cantidad && r.cantidad > 0)
                    newDet.push(r);
            }
            const pendientes = []
            const rechazados = [];
            newDet.forEach(cobro => {
                if (cobro.id_estatus_cobro_aplicado === catalogosSt.idsEstatusCobro.registrado) {
                    pendientes.push(cobro)
                }
                if (cobro.id_estatus_cobro_aplicado === catalogosSt.idsEstatusCobro.rechazado) {
                    rechazados.push(cobro)
                }
            })
            setRegistrosOrdenPago({ pendientes, rechazados });
        }
    }, [pagosSt.pagoDetails])
    

    const selectSection = (section) => {
        if (section !== SECTIONS.three) {
            runValidations()
        } else {
            dispatch(changeSectionCobroCurso(section));
        }
    }

    const prepareValidations = () => {
        const pendientesArray = []
        const inProcessArray = []
        const pagadosArray = []
        const pendientesSinFolio = []
        pagosSt.cobrosAplicados.forEach(item => {
            if (
                item.id_estatus_cobro === catalogosSt.idsEstatusCobro.registrado && 
                !item.cemDocumentoCrcCobroCurso.length === 0
            ) {
                pendientesArray.push(item)
            }
            if (
                item.id_estatus_cobro === catalogosSt.idsEstatusCobro.revision ||
                item.id_estatus_cobro === catalogosSt.idsEstatusCobro.rechazado ||
                (item.id_estatus_cobro === catalogosSt.idsEstatusCobro.registrado && item.cemDocumentoCrcCobroCurso.length > 0)
            ) {
                inProcessArray.push(item)
            }
            if (item.id_estatus_cobro === catalogosSt.idsEstatusCobro.confirmado) {
                pagadosArray.push(item)
            }
            if (item.id_estatus_cobro === catalogosSt.idsEstatusCobro.registrado && !item.folio_orden_pago) {
                pendientesSinFolio.push(item)
            }
        })
        setCategorias({
            pendientesArray, 
            inProcessArray, 
            pagadosArray,
            pendientesSinFolio
        })
    }
    
    const runValidations = () => {
        if (categorias?.inProcessArray?.length > 0) {
            dispatch(changeSectionCobroCurso(SECTIONS.two))
        } else {
            dispatch(changeSectionCobroCurso(SECTIONS.one))
        }
    }

    const downloadCobroReport = async () => {
        try {
            dispatch(setLoading(true));
            const dataReport = {
                ...appSt.serviceData, 
                idEntidad: params.idEntidad 
            };
            if (!(pagosSt.currentConvocatoria && pagosSt.currentConvocatoria.idConvocatoria)) {
                dataReport.isDesdeEntidad = true;
            }
            const blob = await getCobroCursoReport(dataReport);
            if (blob) {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'Orden de Pago';
                // some browser needs the anchor to be in the doc
                document.body.append(link);
                link.click();
                link.remove();
                // in case the Blob uses a lot of memory
                setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            }
            dispatch(getPagosCursosDetails({
                ...appSt.serviceData,
                idEntidad: params.idEntidad,
                idModalidadPago: catalogosSt.idModoPagoTotal
            }))
            dispatch(getCobrosCursos({
                ...appSt.serviceData,
                idEntidad: params.idEntidad
            }))
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * INICIO: debe haber al menos un pago en pendiente y ningun comprobante en pendiente y ninguno en rechazado, tramite
     * PAGOS: debe haber al menos uno rechazado, tramite
     * FACTURA: debe haber al menos uno en tramite y niguno en pendiente, rechazado
     * PAGADO: todo en disabled solo pueden agregar servicios extemporaneos
     */

    // console.log(pagosSt.viewCobroCurso);
    return (
        <section className="wrapper pagos">
            <div className="main-section-title">
                <button onClick={() => navigate(-1)}>
                    <BackIcon />
                </button>
                <div className="text">
                    <h3>Pagos Cursos</h3>
                </div>
            </div>
            <div className="card">
                <div className="head">
                   <div className="tabs">
                        <button 
                            className={(pagosSt.viewCobroCurso === SECTIONS.one || pagosSt.viewCobroCurso === SECTIONS.two || pagosSt.viewCobroCurso === SECTIONS.four) ? 'active' : ''}
                            onClick={() => selectSection(SECTIONS.one)}
                        >Pagos Cursos</button>  
                        <button 
                            className={pagosSt.viewCobroCurso === SECTIONS.three ? 'active' : ''}
                            onClick={() => selectSection(SECTIONS.three)}
                        >Facturas</button>
                    </div>
                    {registrosOrdenPago.pendientes.length > 0 || registrosOrdenPago.rechazados.length > 0
                        ? <button onClick={downloadCobroReport}>Descargar orden de pago</button> 
                        : <></>
                    }
                </div>
                <div className="content">
                    {pagosSt.viewCobroCurso === SECTIONS.one &&
                        <PagosPendientes idEntidad={params.idEntidad} />
                    }
                    {pagosSt.viewCobroCurso === SECTIONS.two &&
                        <ListaCobros idEntidad={params.idEntidad} />
                    }
                    {pagosSt.viewCobroCurso === SECTIONS.three &&
                        <Facturas idEntidad={params.idEntidad} />
                    }
                    {pagosSt.viewCobroCurso === SECTIONS.four &&
                        <PagoEnLinea idEntidad={params.idEntidad} />
                    }
                </div>
            </div>
        </section>
    );
}