export const instrumentoReporteFiltros = {
    descCriterio: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    descAmbito: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    descSubambito: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    descIndicador: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    filtro: {
        value: '1',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    }
}

export const filtrosReporte = [
    {
        label: 'Todas',
        value: 1
    },
    {
        label: 'Sin responder',
        value: 2
    },
    {
        label: 'Resueltas',
        value: 3
    }
]