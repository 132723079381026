import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    currentConvocatoria: false,
}

export const retroSlice = createSlice({
    name: 'retro',
    initialState,    
    reducers: {
        setCurrentConvocatoria: (state, action) => {
            state.currentConvocatoria = action.payload;
        },
    },
})

export const { 
    setCurrentConvocatoria,    
 } = retroSlice.actions;
export const retroState = (state) => state.retro;

export default retroSlice.reducer;