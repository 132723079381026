/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react"
import InputComp from "../generales/inputComponent";
import { filtrosEntidadesDestacadas } from "../../app/forms/publicacion/filtros-entidades-destacadas";

export default function EntidadesDestacadas({registros, search, ambitos}) {
    const [form, setForm] = useState(filtrosEntidadesDestacadas); 

    return (<section>
        <div className="form-section">
            <div className="four-col">
                {ambitos &&
                    <InputComp label="Ambito" name="idAmbito" type="select" form={form} setForm={setForm} catalogos={ambitos} catalogoLabel={'descAmbito'} catalogoValue={'idAmbito'}/>
                }
                <button onClick={() => search(form)}>Buscar</button>
            </div>
        </div>

        {registros && 
            <div className="table-container">
                {registros.length > 0 ? 
                    <table>
                        <thead>
                            <tr>
                                <th>Años ESR</th>
                                <th>Razon Social</th>
                                <th>Nombre de Empresa</th>
                                <th>Estado</th>
                                <th>Industria</th>
                                <th>Tamaño</th>
                            </tr>
                        </thead>
                        <tbody>
                            {registros.map((item, index) => {
                                return <tr key={index}>
                                    <td>{item.anios_participacion}</td>
                                    <td>{item.razon_social}</td>
                                    <td>{item.razon_social}</td>
                                    <td>{item.estado}</td>
                                    <td>{item.desc_industria}</td>
                                    <td>{item.desc_tamanio}</td>
                                </tr>
                            })}
                            <tr></tr>
                        </tbody>
                    </table>
                    : <p className="empty">Sin resultados</p>
                }
            </div>                                    
        }

    </section>)
}