/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detectMimeType, findCatalogById, formatCurrency, getBase64, getContentName, getToNotification } from "../../app/commons";
import { ALERT_FAIL, ALERT_SUCCESS, CVE_ESTATUS_COBRO, CVE_TIPODOC_CEMEFI, CVE_TIPODOC_COBRO, ERROR_MESSAGE, NOTIFICACIONES, SUCESS_MESSAGE } from "../../app/constantes";
import { deleteCobroAplicadoDocumento, postCobroAplicadoDocumento, putCobroAplicado } from "../../services/cobro";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import { postNotificacion } from "../../services/notificaciones";
import { getCobrosAplicados, getPagosDetails, pagosState, setModalRevertir } from "../../slices/pagosSlice";
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { ReactComponent as RevertIcon } from '../../assets/icons/revert.svg';
import moment from "moment/moment";
import { getDocument, getEntidad, uploadDocument } from "../../services/entidad";
import ModalRevertirPago from "./modalRevertir";
import ModalDatosFiscales from "./modalDatosFIscales";



export default function ListaCobros({ convocatoria }) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const catalogosSt = useSelector(catalogosServiceState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const pagosSt = useSelector(pagosState);
    const [formatedPagos, setFormatedPagos] = useState(null);
    const [allSet, setAllSet] = useState(false);
    const [showSend, setShowSend] = useState(true);
    const [canEdit, setCanEdit] = useState(false)
    const [modalData, setModalData] = useState({
        show: false,
        data: null
    })

    const openRevert = (registro) => {
        dispatch(setModalRevertir({
            show: true,
            data: registro
        }))
    }

    const checkPopUp = () => {
        let show = false
        const pendientesSinFactura = pagosSt.cobrosAplicados.filter(item => (
            (item.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado ||
                item.idEstatusCobro === catalogosSt.idsEstatusCobro.rechazado) && 
                !item.isRevertirPago &&
                !item.facAnticipada
        ))
        if (pendientesSinFactura.length > 0) {
            setModalData({
                show: true,
                data: null
            })
        } else {
            validatePay()
        }
        return show
    }

    /**
     * Mandamos el comprobante a validar
     */
    const validatePay = async () => {
        const body = [];
        const cuota = formatedPagos.find(item => item.descConceptoCobroConvocatoria.includes('CUOTA'));
        let valid = true
        const filtered = formatedPagos.filter(item => item.idEstatusCobro !== catalogosSt.idsEstatusCobro.revertir)
        filtered.forEach(item => {
            if (item.cemDocumentoCrcCobroRegistros.length === 0) {
                valid = false
            }
        })
        if (valid) {
            dispatch(setLoading(true));
            filtered.forEach(item => {
                body.push({
                    idCobroAplicado: item.idCobroAplicado,
                    idRegistroConvocatoria: item.idRegistroConvocatoria,
                    idConceptoCobroConvocatoria: item.idConceptoCobroConvocatoria,
                    cveEstatusCobro: CVE_ESTATUS_COBRO.revision,
                    monto: item.monto
                })
            })

            try {
                await putCobroAplicado(appSt.serviceData, body);
                dispatch(setLoading(false));
                refreshData()
                dispatch(setAlert({
                    show: true,
                    message: SUCESS_MESSAGE,
                    type: ALERT_SUCCESS
                }));
                const data = await getEntidad({ ...appSt.serviceData, idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad });
                if (cuota) {
                    postNotificacion(appSt.serviceData, {
                        to: getToNotification(data, catalogosSt.idsTipoContacto),
                        templateData: {
                            convocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria
                        },
                        templateId: NOTIFICACIONES.templatesIds.revisionPago
                    })
                } else {
                    postNotificacion(appSt.serviceData, {
                        to: getToNotification(data, catalogosSt.idsTipoContacto),
                        templateData: {
                            convocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria
                        },
                        templateId: NOTIFICACIONES.templatesIds.validacionCobro
                    })
                }
            } catch (error) {
                console.log(error);
                dispatch(setLoading(false));
                dispatch(setAlert({
                    show: true,
                    message: ERROR_MESSAGE,
                    type: ALERT_FAIL
                }));
            }
        } else {
            dispatch(setAlert({
                show: true,
                message: "Favor de subir al menos un comprobante para todos los cobros",
                type: ALERT_FAIL
            }))
        }
    }

    const deleteComprobante = async (cobro) => {
        dispatch(setLoading(true));

        try {
            await deleteCobroAplicadoDocumento({
                ...appSt.serviceData,
                idDocumentoCobroRegistro: cobro.idDocumentoCobroRegistro
            })
            dispatch(setLoading(false));
            refreshData()
            dispatch(
                setAlert({ show: true, message: 'Se elimino el registro', type: ALERT_SUCCESS })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                setAlert({ show: true, message: 'error al eliminar registro', type: ALERT_FAIL })
            );
        }
    }

    const downloadDoc = async (file) => {
        dispatch(setLoading(true));
        const docResp = await getDocument({
            ...appSt.serviceData,
            idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad,
            idContent: file.idContent
        });


        if (docResp.payload) {
            const dataType = detectMimeType(docResp.payload.base64, 'Evidencia Institucional');
            // const type = 'data:image/png'
            const file64 = docResp.payload.base64;
            const linkSource = `data:${dataType};base64,${file64}`;
            const downloadLink = document.createElement('a');
            const fileName = 'Evidencia Institucional';

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click()
        }
        dispatch(setLoading(false));
    }

    /**
     * vemos si el pago ya tiene un cobrante
     * puede q los comprobantes puedan venir de un servicio para evitar hacer esta logica 
     */
    const formatPagos = async () => {
        const array = [];
        pagosSt.cobrosAplicados.forEach(item => {
            if (item.idEstatusCobro !== catalogosSt.idsEstatusCobro.confirmado && item.idEstatusCobro !== catalogosSt.idsEstatusCobro.exento) {
                const obj = { ...item }
                const registro = item.idConceptoCobroConvocatoria2.cemCobroCrcRegistroConvocatorias[0]
                obj.descConceptoCobroConvocatoria = item.idConceptoCobroConvocatoria2.descConceptoCobroConvocatoria
                obj.cantidad = registro.cantidad
                obj.idCobroRegistroConvocatoria = registro.idCobroRegistroConvocatoria
                obj.montoCobro = registro.montoCobro
                array.push(obj)
            }
        });
        const pendientes = pagosSt.cobrosAplicados.filter(item => (
            (item.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado ||
                item.idEstatusCobro === catalogosSt.idsEstatusCobro.rechazado) && !item.isRevertirPago
        ))
        
        const conComprobante = pagosSt.cobrosAplicados.filter(item => item.cemDocumentoCrcCobroRegistros && (item.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado || item.idEstatusCobro === catalogosSt.idsEstatusCobro.rechazado))
        setAllSet(conComprobante.length === pendientes.length);
        setShowSend(pendientes.length > 0);
        setFormatedPagos(array);
    }

    const refreshData = () => {
        dispatch(getPagosDetails({
            ...appSt.serviceData,
            idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
            idModalidadPago: catalogosSt.idModoPagoTotal
        }))
        dispatch(getCobrosAplicados({
            ...appSt.serviceData,
            idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
        }))
    }

    const uploadComprobante = async (e, registro) => {
        dispatch(setLoading(true));
        try {
            const value = e.target.files[0];
            const base64 = await getBase64(value);
            const file = {
                cveTipoDocumento: CVE_TIPODOC_COBRO,
                cveDocumentoCemefi: CVE_TIPODOC_CEMEFI.comprobantePago,
                archivo: {
                    nombreArchivo: value.name,
                    tipo: value.type,
                    base64: base64
                }
            }
            const body = {
                idRegistroConvocatoria: registro.idRegistroConvocatoria,
                idConceptoCobroConvocatoria: registro.idConceptoCobroConvocatoria,
                monto: Number(registro.monto) * Number(registro.cantidad),
                facAnticipada: registro.facAnticipada,
                cveEstatusCobro: CVE_ESTATUS_COBRO.registrado,
                idCobroAplicado: registro.idCobroAplicado
            }
            const data = {
                ...appSt.serviceData,
                idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad
            }
            await putCobroAplicado(appSt.serviceData, [body]);
            const docResp = await uploadDocument(data, file)
            await postCobroAplicadoDocumento(appSt.serviceData, {
                idCobroRegistroConvocatoria: registro.idCobroRegistroConvocatoria,
                cveDocumentoCemefi: file.cveDocumentoCemefi,
                idContent: docResp.payload.contentId,
            })
            refreshData();
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: SUCESS_MESSAGE,
                type: ALERT_SUCCESS
            }));
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: ERROR_MESSAGE,
                type: ALERT_FAIL
            }));
        }
        e.target.value = '';
    }

    useEffect(() => {
        if (pagosSt.cobrosAplicados && pagosSt.pagoDetails && catalogosSt.idsTipoDocumentoCemefi) {
            formatPagos();
            // setFormatedPagos(pagosSt.cobrosAplicados)
        }

    }, [pagosSt.cobrosAplicados, pagosSt.pagoDetails, catalogosSt.idsTipoDocumentoCemefi])

    useEffect(() => {
        setCanEdit(convocatoria && Number(convocatoria.misPagos) === 1)
    }, [convocatoria])

    return (
        <div className="cobros">
            <h3>Subir Comprobantes</h3>
            <p>Para efecto de realizar tu transferencia, deberás indicar la referencia y pagar el importe exacto que señala la Orden de Pago. <br />Una vez cargados los comprobantes enviar a validar..</p>
            <table>
                <thead>
                    <tr>
                        <th>Estatus</th>
                        <th>Motivo de Rechazo</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Fecha</th>
                        <th>Monto Unitario</th>
                        <th>Monto Total</th>
                        <th>Orden de Pago</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {formatedPagos && formatedPagos.map((cobro, index) => {
                        return cobro.idEstatusRegistro !== 0 && <tr key={index}>
                            <td>{findCatalogById(cobro.idEstatusCobro, catalogosSt.estatusCobro, 'idEstatusCobro', 'descEstatusCobro')}</td>
                            <td>{cobro.motivoRechazo}</td>
                            <td>{cobro.idConceptoCobroConvocatoria2.descConceptoCobroConvocatoria}</td>
                            <td>{cobro.cantidad}</td>
                            <td>{moment(cobro.fecha).format('YYYY-MM-DD')}</td>
                            <td>{formatCurrency(cobro.montoCobro)}</td>
                            <td>{formatCurrency(cobro.montoCobro * cobro.cantidad)}</td>
                            <td>{cobro.folioOrdenPago}</td>
                            <td>
                                    <div className="table-actions">
                                        {canEdit && (
                                            <button
                                                disabled={!(cobro.isRevertirPago && cobro.idEstatusCobro === catalogosSt.idsEstatusCobro.rechazado)}
                                                className={`small-icon-btn tooltip ${cobro.tieneDocumento ? 'success' : ''}`}
                                                data-tool="Revertir pago"
                                                onClick={() => openRevert(cobro)}>
                                                <RevertIcon />
                                            </button>
                                        )}
                                        <div className="sublist">
                                            <div className="small-icon">
                                                <FileIcon />
                                            </div>
                                            <ul className="list">
                                                {cobro.cemDocumentoCrcCobroRegistros.length > 0 && cobro.cemDocumentoCrcCobroRegistros.map((file, i) => {
                                                    return <li key={i}>
                                                        <p>{getContentName(file.idContent)}</p>
                                                        <button className="small-icon-btn tooltip" data-tool="Descargar" onClick={() => downloadDoc(file)}>
                                                            <DownloadIcon />
                                                        </button>
                                                        {canEdit && (
                                                            <button
                                                                onClick={() => deleteComprobante(file)}
                                                                disabled={!((cobro.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado || cobro.idEstatusCobro === catalogosSt.idsEstatusCobro.rechazado) &&
                                                                    cobro.isRevertirPago !== 1)}
                                                                className="small-icon-btn tooltip"
                                                                data-tool="Eliminar">
                                                                <DeleteIcon />
                                                            </button>
                                                        )}
                                                    </li>
                                                })}
                                                {canEdit && 
                                                    <li className="upload">
                                                        <button
                                                            disabled={cobro.cemDocumentoCrcCobroRegistros.length > 2 || !((cobro.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado || cobro.idEstatusCobro === catalogosSt.idsEstatusCobro.rechazado) &&
                                                                cobro.isRevertirPago !== 1)}
                                                            className="icon-btn file-up"
                                                        >
                                                            <p>Subir comprobante</p>
                                                            <input type="file" onChange={(e) => uploadComprobante(e, cobro)} />
                                                        </button>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>

            {showSend && canEdit &&
                <div className="options">
                    <button disabled={!allSet} onClick={checkPopUp}>Enviar a Validar</button>
                </div>
            }

            <ModalRevertirPago convocatoria={convocatoria} />
            <ModalDatosFiscales formatedPagos={formatedPagos} validatePay={validatePay} setModalData={setModalData} modalData={modalData}/>
        </div>
    )
}