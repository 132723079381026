/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { filtrosReporte, instrumentoReporteFiltros } from "../../app/forms/registro/instrumentoReporte";
import { changeAmbitoSelected, changeCriterioSelected, changeIndicadorSelected, changePregunta, changePreguntas, changeRespuestaSeleccionada, changeSubambitoSelected, instrumentoServicesState, obtenerInstrumentoMedicionConvocatoria, obtenerTotalAmbito, obtenerTotalCriterio, obtenerTotalIndicador, obtenerTotalSubAmbito, setQuery } from "../../slices/instrumentoServiceSlice"
import InputComp from "../generales/inputComponent";

import { ReactComponent as OpenIcon } from '../../assets/icons/open.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { catalogosServiceState, getAmbitosInstrumentoCatalogo, getCriteriosInstrumentoCatalogo, getIndicadoresInstrumentoCatalogo, getNivelMadurezCatalogo, getSubambitosInstrumentoCatalogo } from "../../slices/catalogosServiceSlice";
import { appState, setLoading } from "../../slices/appSlice";
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import { getNivelesByInstrumentoRegistro } from "../../services/catalogos";


export default function SeguimientoInstrumento({ afterSelection }) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const instrumentoSt = useSelector(instrumentoServicesState);
    const catalogosSt = useSelector(catalogosServiceState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const [form, setForm] = useState(JSON.parse(JSON.stringify(instrumentoReporteFiltros)));
    const [data, setData] = useState(null);
    const [niveles, setNiveles] = useState(null);
    const [filterData, setFilteredData] = useState(null);

    const formatData = async () => {
        const formatedData = [];
        const nivelesResp = await getNivelesByInstrumentoRegistro({
            ...appSt.serviceData,
            idInstrumentoRegistro: instrumentoSt.instrumento.idInstrumentoRegistro
        })
        setNiveles(nivelesResp.payload)
        for (const pregunta of instrumentoSt.instrumentoReport) {
            const nivelesUsuario = []
            for (const resp of pregunta.respuesta) {
                for (const nivel of nivelesResp.payload) {
                    if (resp.valorRespuesta === nivel.idNivelMadurez) {
                        nivelesUsuario.push(resp)
                    }                     
                }
            }            
            formatedData.push({...pregunta, nivelesUsuario})               
        }
        setData(formatedData);
        setFilteredData(formatedData)
    }

    const filterTable = () => {
        let filt = JSON.parse(JSON.stringify(filterData));
        switch (Number(form.filtro.value)) {
            case 1:
                filt = JSON.parse(JSON.stringify(data))
                break;
            case 2:
                filt = data.filter(item => {
                    let completed = true;
                    item.respuesta.forEach(resp => {
                        if (resp.valorRespInstrReg !== null) {
                            completed = false;
                        }
                    })
                    return completed
                })
                break;
            case 3:
                filt = data.filter(item => {
                    let completed = false;
                    item.respuesta.forEach(resp => {
                        if (resp.valorRespInstrReg !== null) {
                            completed = true;
                        }
                    })
                    return completed
                })
                break;
            default:
                break;
        }
        setFilteredData(filt)
    }

    const openPregunta = (item) => {
        const idEntidad = registroConvocatoriaSt.empresaSeleccionada.idEntidad;
        const idConvocatoria = registroConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria
        const query = {
            cveCriterio: item.cveCriterio,
            cveAmbito: item.cveAmbito,
            cveSubambito: item.cveSubambito,
            cveIndicador: item.cveIndicador
        }
        dispatch(setLoading(true));
        dispatch(setQuery(query));

        //populamos segmentos
        dispatch(changeCriterioSelected({
            cveCriterio: item.cveCriterio,
            idCriterio: item.idCriterio,
            descCriterio: item.descCriterio
        }));
        dispatch(getCriteriosInstrumentoCatalogo({ ...appSt.serviceData, idEntidad, idConvocatoria }));
        dispatch(changeAmbitoSelected({
            cveAmbito: item.cveAmbito,
            idAmbito: item.idAmbito,
            descAmbito: item.descAmbito
        }));
        dispatch(getAmbitosInstrumentoCatalogo({ ...appSt.serviceData, idCriterio: item.idCriterio, idEntidad, idConvocatoria }));
        dispatch(changeSubambitoSelected({
            cveSubambito: item.cveSubambito,
            idSubambito: item.idSubambito,
            descSubambito: item.descSubambito
        }));
        dispatch(getSubambitosInstrumentoCatalogo({ ...appSt.serviceData, idCriterio: item.idCriterio, idAmbito: item.idAmbito, idEntidad, idConvocatoria }));
        dispatch(changeIndicadorSelected({
            cveIndicador: item.cveIndicador,
            idIndicador: item.idIndicador,
            descIndicador: item.descIndicador
        }));
        dispatch(getIndicadoresInstrumentoCatalogo({ ...appSt.serviceData, idCriterio: item.idCriterio, idAmbito: item.idAmbito, idSubAmbito: item.idSubambito, idEntidad, idConvocatoria }));

        //populamos los progresos y totales
        dispatch(obtenerTotalCriterio({
            ...appSt.serviceData,
            idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
            cveCriterio: item.cveCriterio
        }))
        dispatch(obtenerTotalAmbito({
            ...appSt.serviceData,
            idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
            cveAmbito: item.cveAmbito
        }))
        dispatch(obtenerTotalSubAmbito({
            ...appSt.serviceData,
            idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
            cveSubambito: item.cveSubambito
        }))
        dispatch(obtenerTotalIndicador({
            ...appSt.serviceData,
            idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
            cveIndicador: item.cveIndicador
        }))

        const instrumentoServiceData = {
            ...appSt.serviceData,
            idTipoEntidad: registroConvocatoriaSt.empresaSeleccionada.idTipoEntidad,
            idTamanio: registroConvocatoriaSt.empresaSeleccionada.idTamanio,
            idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
        }

        //Populamos pregunta
        dispatch(changePregunta(item));
        dispatch(changeRespuestaSeleccionada(item.respuesta[0]));
        dispatch(changePreguntas())
        dispatch(obtenerInstrumentoMedicionConvocatoria({ ...instrumentoServiceData, query }));
        dispatch(setLoading(false));
        afterSelection()
    }

    useEffect(() => {
        if (instrumentoSt.instrumentoReport && catalogosSt.nivelMadurez) {
            formatData();
            setForm(JSON.parse(JSON.stringify(instrumentoReporteFiltros)))
        }
        if (catalogosSt.nivelMadurez === null) {
            dispatch(getNivelMadurezCatalogo(appSt.serviceData));
        }
    }, [instrumentoSt.instrumentoReport, catalogosSt.nivelMadurez])

    useEffect(() => {
        if (data) {
            filterTable();
        }
    }, [form])

    return (
        <div className="content">
            <div className="head">
                <p><b>Seguimiento Avance</b></p>
            </div>
            <div className="filtros">
                    <InputComp label="Filtrar Por" name="filtro" type="select" form={form} setForm={setForm} catalogos={filtrosReporte} catalogoLabel={'label'} catalogoValue={'value'} />
                    <div className="tip">
                        <InfoIcon />
                        <p>Seleccione una pregunta para ir directamente a ella</p>
                    </div>
            </div>
            <h3>Preguntas</h3>
            <div className="table-wrapper">
                {filterData &&
                    <table>
                        <thead>
                            <tr>
                                <th>Pregunta</th>
                                <th>Criterio</th>
                                <th>Ambito</th>
                                <th>Subambito</th>
                                <th>Indicador</th>
                                {niveles && niveles.map((item, index) => {
                                    return <th key={index}>{item.descNivelMadurez}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {filterData && filterData.map((item, index) => {
                                return <tr key={index}>
                                    <td>
                                        <button className="tooltip small small-icon-btn" data-tool={'Ir a la pregunta'} onClick={() => openPregunta(item)}>
                                            <OpenIcon />
                                        </button>
                                        <button className="tooltip small-icon-btn" data-tool={item.descPreguntaIndicador}>
                                            <EyeIcon />
                                        </button>
                                    </td>
                                    <td>{item.descCriterio}</td>
                                    <td>{item.descAmbito}</td>
                                    <td>{item.descSubambito}</td>
                                    <td>{item.descIndicador}</td>
                                    {item.nivelesUsuario && item.nivelesUsuario.map((nivel, i) => {
                                        return <td key={i}>{nivel && nivel.valorRespInstrReg ? <CheckIcon className="success" /> : <CancelIcon className="danger" />}</td>
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}