import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { entidadServicesState, getEntidadPrincipal } from '../slices/entidadServicesSlice';
import { ReactComponent as BackIcon } from '../assets/icons/back.svg';
import '../styles/sedes.scss';
import AdministracionSede from '../components/registro/administracion-sede';
import { appState } from '../slices/appSlice';

export default function Sedes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const entidadServicesSt = useSelector(entidadServicesState);
  const appSt = useSelector(appState);

  const [entidades, setEntidades] = useState(null);

  const goBack = () => {
    navigate("/mis-entidades")
  }

  const selectSede = async (sede) => {
    dispatch(getEntidadPrincipal({...appSt.serviceData, idEntidad: sede.id_entidad}));
  }

  useEffect(() => {
    if (entidadServicesSt.misEmpresas) {
      const sedeExist = entidadServicesSt.misEmpresas.find(entidad => entidad.is_sede_marca);
      if (sedeExist) {
        selectSede(sedeExist);
      } else {
        const filter = entidadServicesSt.misEmpresas.filter(item => item.disponible && item.tieneMembresia === '0');
        setEntidades(filter);
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entidadServicesSt.misEmpresas])

  // useEffect(() => {
  //   if (entidadServicesSt.min)
  // }, [])

  return <section className="sedes-view">
    {!entidadServicesSt.principalSede &&
      <div className="wrapper">
        <div className="main-section-title">
          <button onClick={goBack}>
            <BackIcon />
          </button>
          <div className="text">
            <h3>Sedes</h3>
            <p>Selecciona la entidad principal de la sede</p>
          </div>
        </div>

        {entidades && entidades.length > 0 && 
          <table className='sedes-list'>
            <thead>
              <tr>
                <th>Razón Social</th>
                <th>Nombre Comercial</th>
                <th>RFC</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {entidades.map((entidad, index) => {
                return <tr key={index}>
                  <td>{entidad.razon_social}</td>
                  <td>{entidad.nombre_comercial}</td>
                  <td>{entidad.rfc}</td>
                  <td className='acciones'>
                    <button onClick={() => selectSede(entidad)}>
                      <BackIcon />
                    </button>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        }
      </div>
    }

    {entidadServicesSt.principalSede && <AdministracionSede />}
  </section>
}