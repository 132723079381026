/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';

import '../../styles/pagos.scss';
import { CONVOCATORIAS_DISPONIBLES, CVE_TIPODOC_COBRO, LOGO_EMPRESA, SECTIONS } from "../../app/constantes";
import { appState, changeLogo, setLoading } from "../../slices/appSlice";
import { useEffect, useState } from "react";
import { catalogosServiceState, getDocumentosCatalogo, getFormasPago, getMetodosPago, getRegimenCatalogo, getUsoCfdi } from "../../slices/catalogosServiceSlice";
import { changeSection, getCobrosAplicados, getPagosDetails, pagosState } from "../../slices/pagosSlice";
import PagosPendientes from "./pagosIncial";
import ListaCobros from "./listaCobros";
import Facturas from "./facturas";
import { changeView, registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import { getCobroReport } from "../../services/cobro";
import PagoEnLinea from "./pagoEnLinea";

export default function Pagos({convocatoria, empresaSeleccionada}) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const catalogosSt = useSelector(catalogosServiceState);
    const pagosSt = useSelector(pagosState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const [categorias, setCategorias] = useState(null);
    const [showOrden, setShowOrden] = useState(false)

    const closePayment = () => {
        dispatch(changeLogo(LOGO_EMPRESA.cemefi));
        dispatch(changeView(CONVOCATORIAS_DISPONIBLES));
    }

    const selectSection = (section) => {
        if (section !== SECTIONS.three) {
            runValidations()
        } else {
            dispatch(changeSection(section));
        }
    }

    const prepareValidations = () => {
        const pendientesArray = []
        const inProcessArray = []
        const pagadosArray = []
        const pendientesSinFolio = []
        const registradosArray = []
        const rechazadosArray = []
        pagosSt.cobrosAplicados.forEach(item => {
            if (
                item.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado && 
                !item.cemDocumentoCrcCobroRegistros.length === 0
            ) {
                pendientesArray.push(item)
            }
            if (
                item.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado
            ) {
                registradosArray.push(item)
            }
            if (
                item.idEstatusCobro === catalogosSt.idsEstatusCobro.rechazado
            ) {
                rechazadosArray.push(item)
            }
            if (
                item.idEstatusCobro === catalogosSt.idsEstatusCobro.revision ||
                item.idEstatusCobro === catalogosSt.idsEstatusCobro.rechazado ||
                (item.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado && item.cemDocumentoCrcCobroRegistros.length > 0)
            ) {
                inProcessArray.push(item)
            }
            if (item.idEstatusCobro === catalogosSt.idsEstatusCobro.confirmado) {
                pagadosArray.push(item)
            }
            if (item.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado && !item.folioOrdenPago) {
                pendientesSinFolio.push(item)
            }
        })

        setShowOrden((registradosArray.length > 0 || rechazadosArray.length > 0) && convocatoria && Number(convocatoria.misPagos) === 1)
        setCategorias({
            pendientesArray, 
            inProcessArray, 
            pagadosArray,
            pendientesSinFolio
        })
    }
    
    const runValidations = () => {
        if (categorias.inProcessArray.length > 0) {
            dispatch(changeSection(SECTIONS.two))
        } else {
            dispatch(changeSection(SECTIONS.one))
        }
    }

    const downloadCobroReport = async (idEntidad) => {
        dispatch(setLoading(true));
        const blob = await getCobroReport({...appSt.serviceData, idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria})
        if (blob) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Orden de Pago';
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
        dispatch(getPagosDetails({
            ...appSt.serviceData,
            idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
            idModalidadPago: catalogosSt.idModoPagoTotal
        }))
        dispatch(getCobrosAplicados({
            ...appSt.serviceData,
            idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
        }))
        dispatch(setLoading(false));
    }

    useEffect(() => {
        if (empresaSeleccionada && convocatoria) {
            dispatch(getPagosDetails({
                ...appSt.serviceData,
                idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
                idModalidadPago: catalogosSt.idModoPagoTotal
            }))
            dispatch(getCobrosAplicados({
                ...appSt.serviceData,
                idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
            }))
        }
        
        dispatch(getDocumentosCatalogo({...appSt.serviceData, cveTipoDocumento: CVE_TIPODOC_COBRO}));

        if (catalogosSt.usoCfdi === null) {
            dispatch(getUsoCfdi(appSt.serviceData));
        }

        if (catalogosSt.regimen === null) {
            dispatch(getRegimenCatalogo(appSt.serviceData));
        }

        if (catalogosSt.formaPago === null) {
            dispatch(getFormasPago(appSt.serviceData));
        }

        if (catalogosSt.metodoPago === null) {
            dispatch(getMetodosPago(appSt.serviceData));
        }

        // getMessages();        
    }, [convocatoria])

    useEffect(() => {
        if (pagosSt.cobrosAplicados && catalogosSt.idsEstatusCobro) {
            prepareValidations()
        }
    }, [pagosSt.cobrosAplicados, catalogosSt.idsEstatusCobro])

    useEffect(() => {
        if (categorias) {
            runValidations()
        }
    }, [categorias])

    /**
     * INICIO: debe haber al menos un pago en pendiente y ningun comprobante en pendiente y ninguno en rechazado, tramite
     * PAGOS: debe haber al menos uno rechazado, tramite
     * FACTURA: debe haber al menos uno en tramite y niguno en pendiente, rechazado
     * PAGADO: todo en disabled solo pueden agregar servicios extemporaneos
     */

    return (
        <section className="wrapper pagos">
            <div className="main-section-title">
                <button onClick={closePayment}>
                    <BackIcon />
                </button>
                <div className="text">
                    <h3>Pagos</h3>
                </div>
            </div>
            <div className="card">
                <div className="head">
                   <div className="tabs">
                        <button 
                            className={(pagosSt.view === SECTIONS.one || pagosSt.view === SECTIONS.two || pagosSt.view === SECTIONS.four) ? 'active' : ''}
                            onClick={() => selectSection(SECTIONS.one)}
                        >Pagos</button>
                        <button 
                            className={pagosSt.view === SECTIONS.three ? 'active' : ''}
                            onClick={() => selectSection(SECTIONS.three)}
                        >Facturas</button>
                    </div>
                    {showOrden && 
                        <button onClick={downloadCobroReport}>Descargar orden de pago</button> 
                    }
                </div>
                <div className="content">
                    {pagosSt.view === SECTIONS.one &&
                        <PagosPendientes convocatoria={convocatoria} />
                    }
                    {pagosSt.view === SECTIONS.two &&
                        <ListaCobros convocatoria={convocatoria} />
                    }
                    {pagosSt.view === SECTIONS.three &&
                        <Facturas convocatoria={convocatoria} />
                    }
                    {pagosSt.view === SECTIONS.four &&
                        <PagoEnLinea convocatoria={convocatoria} />
                    }
                </div>
            </div>
        </section>
    );
}