import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { changeDocumentosCompleted, changeStep } from "../../slices/registroSlice";
import { changeStep as changeStepConv, getDocsConvocatoria, registroConvocatoriaState, updateEmpresaSeleccionada } from "../../slices/registroConvocatoriaSlice";
import { catalogosServiceState, getDocumentosCatalogo, getDocumentosRegistroConvocatoria, getRegistroEntidadInformacionGeneral } from "../../slices/catalogosServiceSlice";
import { appState, setAlert, setLoading } from "../../slices/appSlice";

import { ALERT_FAIL, ALERT_SUCCESS, CVE_TIPODOC_CONVOCATORIA, CVE_TIPODOC_ENTIDAD, DIRECCION_ENTREGA_STEP, INSTRUMENTOS_CVE_ESTATUS, MEMBRESIA_STEP, NOTIFICACIONES, SERVICIO_ADICIONALES_STEP } from "../../app/constantes";
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { getCorporativo, getDocument, getEntidad, getEntidadesHijas, postCambioEstatusEntidad } from "../../services/entidad";
import { getToNotification, isConvocatoriaInsignia, validateContactos, validateDocs, validateIndustria } from "../../app/commons";

import DocumentComponent from "../generales/document";
import { deleteRegistroConvocatoria, getDocumentosConvocatoria, getEntidadRegistroConvocatoriaById, putRegistroConvocatoria } from "../../services/convocatorias";
import ConfirmModal from "../generales/confirmModal";
import aviso from '../../assets/AvisoDePrivacidad.pdf';
import { postNotificacion } from "../../services/notificaciones";
import { usuariosServicesState } from "../../slices/usuariosServicesSlice";
import { getInstrumentosByEntidad, postInstrumentoRegistro } from "../../services/instrumento";
import moment from "moment";
import { getMessageByModulo } from "../../services/catalogos";
import CartaCompromiso from '../../assets/CARTA_COMPROMISO_CV.docx';
import CartaCompromisoEP from '../../assets/CARTA_COMPROMISO_EP.docx';


export default function Documentos({ type, empresaSeleccionada, goBack, convocatoriaSeleccionada, entidadesAdicionales, disabled }) {
    const dispatch = useDispatch();
    const usuarioSt = useSelector(usuariosServicesState);
    const catalogosSt = useSelector(catalogosServiceState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const appSt = useSelector(appState);
    const [documents, setDocuments] = useState([]);
    const [showMembresia, setShowMembresia] = useState(false);
    const [adicionalDocs, setAdicionalDocs] = useState(null);
    const [validacionMessage, setValidacionMessage] = useState(null);

    const datosDocumentos = {
        validacionDocumentos: {
            tool: ''
        },
        formatoDocumentos: {
            tool: ''
        }
    }

    const [messages, setMessages] = useState(datosDocumentos);

    /**
     * Paso anterior
     */
    const prevStep = () => {
        if (type === CVE_TIPODOC_ENTIDAD) {
            dispatch(changeStep(MEMBRESIA_STEP));
        } else {
            if (registroConvocatoriaSt.membresiaActiva && !registroConvocatoriaSt.empresaSeleccionada.isMiembro) {
                dispatch(changeStepConv(MEMBRESIA_STEP));
            } else if(isConvocatoriaInsignia(registroConvocatoriaSt.convocatoriaSeleccionada)) {
                dispatch(changeStepConv(SERVICIO_ADICIONALES_STEP));
            } else {
                dispatch(changeStepConv(DIRECCION_ENTREGA_STEP));
            }
        }
    }

    // Local States
    const [modalData, setModalData] = useState({
        show: false,
        title: '',
        desc: '',
        info: null,
        list: null
    });

    // Cierra Modal de confirmacion
    const closeModal = () => {
        setModalData({
            ...modalData,
            show: false
        })
    }

    // Confirmar Enviar a Validacion
    const confirmValidation = async () => {
        dispatch(setLoading(true));
        const entidadData = await getEntidad({
            ...appSt.serviceData,
            idEntidad: empresaSeleccionada.idEntidad
        });

        if (entidadData.payload) {
            dispatch(setLoading(false));
            const informacionGeneralCompleted = entidadData.payload.cemDireccionEntidads.length >= 2;
            const datosContactoCompleted = validateContactos(entidadData.payload.cemContactoCrcEntidads, catalogosSt.idsTipoContacto);
            const datosIndustriaCompleted = validateIndustria(entidadData.payload);
            const documentosCompleted = validateDocs(catalogosSt.tipoDocumentos, entidadData.payload, catalogosSt.idsTipoDocumentoCemefi && catalogosSt.idsTipoDocumentoCemefi.membresia ? catalogosSt.idsTipoDocumentoCemefi.membresia : null);
            if (
                informacionGeneralCompleted &&
                datosContactoCompleted &&
                datosIndustriaCompleted &&
                documentosCompleted 
            ) {
                setModalData({
                    show: true,
                    title: 'Validar Registro de Empresa',
                    list: validacionMessage,
                    link: {
                        label: 'Al continuar estas aceptando nuestro Aviso de Privacidad, da clic aqui para descargarlo',
                        url: aviso
                    },
                    action: () =>  sendToValidateEntidad(empresaSeleccionada.idEntidad)
                })
            } else {
                const faltantes = [];
                if (!informacionGeneralCompleted) { faltantes.push('Datos de Generales') }
                if (!datosContactoCompleted) { faltantes.push('Datos de Contacto') }
                if (!datosIndustriaCompleted) { faltantes.push('Datos de Industria') }
                if (!documentosCompleted) { faltantes.push('Documentos') }

                dispatch(setAlert({
                    show: true,
                    message: `Falta información del registro (${faltantes.join()})`,
                    type: ALERT_FAIL
                }))
            }
        } else {
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: 'Error al validar el registro',
                type: ALERT_FAIL
            }))
        }
    }

    const getAdicionalesDocs = async () => {        
        try {
            const array = [];
            entidadesAdicionales.forEach(async (adicional) => {
                let docs = JSON.parse(JSON.stringify(catalogosSt.tipoDocumentos));
                const convocatoriaAdd = adicional.cemRegistroConvocatorias.find(conv => conv.idConvocatoria === convocatoriaSeleccionada.idConvocatoria);
                const docsAdicional =  await getDocumentosConvocatoria({...appSt.serviceData, idRegistroConvocatoria: convocatoriaAdd.idRegistroConvocatoria})
                docsAdicional.forEach(async (doc) => {
                    let docType = docs.find(tipo => tipo.idDocumentoCemefi === doc.idDocumentoCemefi && doc.idEstatusRegistro !== 0);
                    if (docType && docType.idEstatusRegistro !== 0) {
                        docType.idContent = doc.idContent
                        docType.idRegistroConvocatoriaDocumento = doc.idRegistroConvocatoriaDocumento
                    }
                })
                array.push({
                    ...adicional,
                    docs
                });
            })    
            setAdicionalDocs(array);
        } catch (error) {
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: 'Error en archivos adicionales',
                type: ALERT_FAIL
            }))             
        }

    }

    const getDocumentUploaded = async (docsUp, docs) => {
        await docsUp.forEach(async (doc) => {
            let docType = docs.find(tipo => tipo.idDocumentoCemefi === doc.idDocumentoCemefi && doc.idEstatusRegistro !== 0);
            
            if (docType && docType.idEstatusRegistro !== 0) {
                const data = {
                    ...appSt.serviceData,
                    idEntidad: empresaSeleccionada.idEntidad,
                    idContent: doc.idContent,
                }
                docType.idContent = doc.idContent
                if (type === CVE_TIPODOC_CONVOCATORIA) {
                    docType.idRegistroConvocatoriaDocumento = doc.idRegistroConvocatoriaDocumento
                } else {
                    docType.idDocumentoEntidad = doc.idDocumentoEntidad
                }
                const docBase = await getDocument(data);

                if (docBase.payload) {
                    docType.base64 = docBase.payload.base64;
                }
            }
        });
    }

    /**
     * Obtenemos los documentos subidos para presentarlo en la vista
     */
    const populateDocs = async () => {
        let docs;

        if (type === CVE_TIPODOC_CONVOCATORIA) {
            docs = JSON.parse(JSON.stringify(catalogosSt.documentosRegistroConvocatoria));
            await getDocumentUploaded(registroConvocatoriaSt.documentosConvocatoria, docs);
        } else {
            docs = JSON.parse(JSON.stringify(catalogosSt.tipoDocumentos));
            if (empresaSeleccionada.cemDocumentoCrcEntidads.length > 0) {
                await getDocumentUploaded(empresaSeleccionada.cemDocumentoCrcEntidads, docs);
            }
        }

        if (docs !== null && docs.length > 0) {
            docs.forEach(doc => {
                doc['legend'] = '';
                if (doc.cveDocumentoCemefi === 'LOGO DE EMPRESA' || doc.cveDocumentoCemefi === 'LOGO DE ORGANIZACIÓN') {
                    if (catalogosSt.parametros !== null && catalogosSt.parametros !== undefined) {
                        catalogosSt.parametros.forEach(item => {
                            if (item.nombreParametro === 'MENSAJE_RESOLUCION_LOGO') {
                                doc.legend = formatValor(item);
                            }
                        });
                    }
                }
                if (doc.cveDocumentoCemefi === 'SITUACIONFISCALSAT') {
                    if (catalogosSt.parametros !== null && catalogosSt.parametros !== undefined) {
                        catalogosSt.parametros.forEach(item => {
                            if (item.nombreParametro === 'MENSAJE_CONSTANCIA_SAT') {
                                doc.legend = formatValor(item);
                            }
                        });
                    }
                }
                if (doc.cveDocumentoCemefi === 'PAGOMEMBRESIAS') {
                    if (catalogosSt.parametros !== null && catalogosSt.parametros !== undefined) {
                        catalogosSt.parametros.forEach(item => {
                            if (item.nombreParametro === 'MENSAJE_MEMBRESIA') {
                                doc.legend = formatValor(item);
                            }
                        });
                    }
                }
            });
        }

        return docs;
    }

    const formatValor = (resp) => {
        let value = null;
        if (resp.valor) {
            value = resp.valor.split('<br>');
        }
        return value;
      }

    useEffect(() => {
        if (catalogosSt.parametros !== null && catalogosSt.parametros !== undefined) {
            catalogosSt.parametros.forEach(item => {
                if (item.nombreParametro === 'MENSAJE_VALIDACION_DOCUMENTOS') {
                    messages.formatoDocumentos.tool = '';
                    messages.validacionDocumentos.tool = formatValor(item);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogosSt.parametros])

    useEffect(() => {
        if (catalogosSt.parametrosConvocatoria !== null && catalogosSt.parametrosConvocatoria !== undefined) {
            catalogosSt.parametrosConvocatoria.forEach(item => {
                if (item.nombreParametro === 'MENSAJE_FORMATO_DOCUMENTOS') {
                    messages.validacionDocumentos.tool = '';
                    messages.formatoDocumentos.tool = item.valor;
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogosSt.parametrosConvocatoria])

    const sendToValidateEntidad = async (idEntidad) => {
        dispatch(setLoading(true));
        const putData = {
            ...appSt.serviceData,
            idEntidad: empresaSeleccionada.idEntidad
        }
        const body = {
            idEstatusEntidad: catalogosSt.idsEstatusEntidad.enRevision
        }
        const resp = await postCambioEstatusEntidad(putData, body);
        if (resp.payload) {
            closeModal();
            dispatch(setAlert({
                show: true,
                message: 'Solicitud Enviada',
                type: ALERT_SUCCESS
            }))
            goBack();
            postNotificacion(appSt.serviceData, {
                to: [
                    {email: usuarioSt.usuario.data.email,name: usuarioSt.usuario.data.nombre}
                ],
                templateId: NOTIFICACIONES.templatesIds.revisionRegistroEntidad
            })
            dispatch(setLoading(false));
        } else {
            dispatch(setAlert({
                show: true,
                message: 'Error al Enviar Solicitud',
                type: ALERT_FAIL
            }))
            dispatch(setLoading(false));
        }
    }

    const handleNotifications = async () => {
        const data = {
            ...appSt.serviceData,
            idRegistroConvocatoria:
            registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria
        };
        const invitadas = await getEntidadRegistroConvocatoriaById(data);
        if (invitadas && invitadas.length > 0) {
            for (const entidad of invitadas) {
                const data = await getEntidad({...appSt.serviceData, idEntidad: entidad.idEntidad});
                if (data) {                    
                    postNotificacion(appSt.serviceData, {
                        to: getToNotification(data, catalogosSt.idsTipoContacto),
                        templateData: {
                            convocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria,
                            entidad: registroConvocatoriaSt.empresaSeleccionada.nombreComercial,
                            programa: registroConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria
                        },
                        templateId: NOTIFICACIONES.templatesIds.revisionInvitado
                    })
                }
            }
            // Se comento porq parece q solo se le debe enviar correo a las invitadas y no a la q invita
            // postNotificacion(appSt.serviceData, {
            //     to: [
            //         {email: usuarioSt.usuario.data.email,name: usuarioSt.usuario.data.nombre}
            //     ],
            //     templateData: {
            //         convocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria,
            //         entidad: registroConvocatoriaSt.empresaSeleccionada.nombreComercial,
            //         programa: registroConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria
            //     },
            //     templateId: NOTIFICACIONES.templatesIds.revisionInvitado
            // })
        }

    }

    const registrarInstrumento = async (entidad) => {
        try {
            const respInstrumento = await getInstrumentosByEntidad({
                ...appSt.serviceData,
                idEntidad: entidad.idEntidad,
                idConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria
            });
            respInstrumento.forEach(async (instrumento) => {
                const instrumentoBody = {
                    idInstrumentoMedicionConvocatoria: instrumento.idInstrumentoMedicionConvocatoria,
                    idRegistroConvocatoria: instrumento.idRegistroConvocatoria,
                    cveEstatusInstrumentoRegistro: INSTRUMENTOS_CVE_ESTATUS.asignado,
                    fechaAsignacionEntidad: moment().format('YYYY-MM-DD'),
                    isProrrogaEntidad: 0,
                    fechaProrrogaEntidad: null
                }
                await postInstrumentoRegistro(appSt.serviceData, instrumentoBody);
            })
        } catch (error) {
            dispatch(setAlert({
                show: true,
                message: 'Error al registrar instrumento',
                type: ALERT_FAIL
            }))
        }
    }

    const sendToValidate = async () => {

        if (
            registroConvocatoriaSt.validations.datosParticipacionCompleted && 
            registroConvocatoriaSt.validations.invitacionCompleted && 
            registroConvocatoriaSt.validations.direccionEntregaCompleted && 
            registroConvocatoriaSt.validations.documentosCompleted 
        ) { 
            dispatch(setLoading(true));
            const data = {
                ...appSt.serviceData,
                idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria
            }
            const body = {
                idEstatusRegistroConvocatoria: catalogosSt.idsEstatusRegistroConvocatoria.revision
            }
            try {
                await putRegistroConvocatoria(data, body);
                if (registroConvocatoriaSt.empresaSeleccionada.isCorporativo || registroConvocatoriaSt.empresaSeleccionada.isSedeMarca || registroConvocatoriaSt.empresaSeleccionada.isMiembro) {
                    await handleCorp('validar');
                }
                dispatch(setAlert({
                    show: true,
                    message: 'Registro enviado a validacion',
                    type: ALERT_SUCCESS
                }))
                const convData = {
                    ...appSt.serviceData,
                    idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad
                }
                dispatch(updateEmpresaSeleccionada(convData));
                // dispatch(getConvocatorias(convData));
                if (registroConvocatoriaSt.convocatoriaSeleccionada.isInvitacion) {
                    handleNotifications();
                }
                postNotificacion(appSt.serviceData, {
                    to: [
                        {email: usuarioSt.usuario.data.email,name: usuarioSt.usuario.data.nombre}
                    ],
                    templateData: {
                        convocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria
                    },
                    templateId: NOTIFICACIONES.templatesIds.revisionRegistroConvocatoria
                })
                await registrarInstrumento(registroConvocatoriaSt.empresaSeleccionada);
                goBack();
                dispatch(setLoading(false));
            } catch (error) {      
                console.log(error)          
                dispatch(setLoading(false));
                dispatch(setAlert({
                    show: true,
                    message: 'Error al validar registro',
                    type: ALERT_FAIL
                }))
            }
        } else {
            const faltantes = [];
                if (!registroConvocatoriaSt.validations.datosParticipacionCompleted) { faltantes.push('Datos de Participación') }
                if (!registroConvocatoriaSt.validations.direccionEntregaCompleted) { faltantes.push('Dirección de Entrega') }
                if (!registroConvocatoriaSt.validations.documentosCompleted ) { faltantes.push('Documentos') }
                if (!registroConvocatoriaSt.validations.invitacionCompleted ) { faltantes.push('Invitacion') }
            dispatch(setAlert({
                show: true, 
                message: `Falta información (${faltantes.join()})`,
                type: ALERT_FAIL
            }));
        }
    }

    const handleCorp = async (type) => {
        try {
            let resp;
            if (registroConvocatoriaSt.empresaSeleccionada.isCorporativo || registroConvocatoriaSt.empresaSeleccionada.isMiembro) {
                resp = await getCorporativo({
                    ...appSt.serviceData,
                    idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad
                });
            } else {
                resp = await getEntidadesHijas({
                    ...appSt.serviceData,
                    idEntidadPadre: registroConvocatoriaSt.empresaSeleccionada.idEntidad
                })
            }

            const response = (registroConvocatoriaSt.empresaSeleccionada.isCorporativo || registroConvocatoriaSt.empresaSeleccionada.isMiembro) ? resp.payload : resp;
    
            if (response && registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria) {
                const promises = [];
                response.forEach((ent) => {
                    const entRegistro = ent.cemRegistroConvocatorias.find(registro => registro.idConvocatoria === registroConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria);
                    if (entRegistro && entRegistro.isEstatusRegistro !== 0 && type === 'delete') {
                        promises.push(deleteRegistroConvocatoria({
                            ...appSt.serviceData,
                            idRegistroConvocatoria: entRegistro.idRegistroConvocatoria
                        }))                    
                    }
                    if (entRegistro && entRegistro.isEstatusRegistro !== 0 && type === 'validar') {
                        const data = {
                            ...appSt.serviceData,
                            idRegistroConvocatoria: entRegistro.idRegistroConvocatoria
                        }
                        const body = {
                            idEstatusRegistroConvocatoria: catalogosSt.idsEstatusRegistroConvocatoria.revision
                        }
                        promises.push(putRegistroConvocatoria(data, body))
                    }
                })
                await Promise.allSettled(promises);
            }            
        } catch (error) {
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: 'Error al eliminar convocatorias adicionales',
                type: ALERT_FAIL
            }));
        }

    }

    const getValidationMessage = async () => {
        const resp = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'REGISTRO', 'VALIDARREGISTRO');
        if (resp && resp.payload && resp.payload.valor) {
            setValidacionMessage(formatValor(resp.payload));
        }
    }

    /**
     * Descargamos documentos
     * @param {*} item 
     */
    const downloadCartaCompromiso = async (type) => {
        const downloadLink = document.createElement('a');
        const fileName = "Carta Compromiso"
        downloadLink.href = type === "EP" ? CartaCompromisoEP : CartaCompromiso;
        downloadLink.download = fileName;
        downloadLink.click()
    }

    useEffect(() => {
        if (registroConvocatoriaSt.documentosConvocatoria && type === CVE_TIPODOC_CONVOCATORIA && catalogosSt.documentosRegistroConvocatoria) {
            populateDocs().then(docs => {
                setDocuments(docs);
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registroConvocatoriaSt.documentosConvocatoria, registroConvocatoriaSt.convocatoriaSeleccionada, empresaSeleccionada, catalogosSt.documentosRegistroConvocatoria])

    /**
     * obtenemos catalogos
     */
    useEffect(() => {
        if (type === CVE_TIPODOC_CONVOCATORIA) {
            if (catalogosSt.documentosRegistroConvocatoria === null) {
                const convData = {
                    ...appSt.serviceData,
                    idConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria,
                    cveEtapa: 'INSCRIPCION',
                    cveTipoDocumento: type
                }
                dispatch(getDocumentosRegistroConvocatoria(convData));
            }
            dispatch(getDocsConvocatoria({
                ...appSt.serviceData, 
                idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria
            }))
        } else {
            const data = {
                ...appSt.serviceData,
                cveTipoDocumento: type,
            };
            dispatch(getDocumentosCatalogo(data));
        }
        getValidationMessage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSt.serviceData])

    /**
     * validacion de documentos
     */
    useEffect(() => {
        if (catalogosSt.tipoDocumentos && empresaSeleccionada && type === CVE_TIPODOC_ENTIDAD) {
            populateDocs().then(docs => {
                setDocuments(docs);
            })
            // Buscamos si el usuario tiene membresia
            if (empresaSeleccionada.cemMembresiaCrcEntidads.length > 0) {
                const membresia = empresaSeleccionada.cemMembresiaCrcEntidads.find(member => member.idEstatusRegistro === 1);
                if (membresia !== undefined) {
                    setShowMembresia(true)
                } else {
                    setShowMembresia(false);
                }
            }
            const valid = validateDocs(catalogosSt.tipoDocumentos, empresaSeleccionada, catalogosSt.idsTipoDocumentoCemefi && catalogosSt.idsTipoDocumentoCemefi.membresia ? catalogosSt.idsTipoDocumentoCemefi.membresia : null);
            dispatch(changeDocumentosCompleted(valid))
            dispatch(getRegistroEntidadInformacionGeneral(appSt.serviceData))

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empresaSeleccionada, catalogosSt.tipoDocumentos, catalogosSt.documentosRegistroConvocatoria])

    useEffect(() => {
        if (entidadesAdicionales && adicionalDocs === null) {
            getAdicionalesDocs();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entidadesAdicionales])

    return (
        <div className="registro-step registro-documents">
            <div className="tip">
                <InfoIcon />
                <div>
                    {messages.validacionDocumentos !== null && messages.validacionDocumentos.tool !== '' && messages.validacionDocumentos.tool.map((item, index) => {
                        return <p key={index}>{item}</p>
                    })}
                    {messages.formatoDocumentos !== null && messages.formatoDocumentos.tool !== '' &&
                        <p>{messages.formatoDocumentos.tool}</p>
                    }
                </div>
            </div>
            <div className="documents-wrapper">
                <h3>Documentos Requeridos</h3>
                <div className="content">
                    <div className="documents">
                        {catalogosSt.tipoDocumentos && documents.map((doc, index) => {
                            return (
                                !catalogosSt.idsTipoDocumentoCemefi ||
                                !catalogosSt.idsTipoDocumentoCemefi.membresia ||
                                ((catalogosSt.idsTipoDocumentoCemefi && catalogosSt.idsTipoDocumentoCemefi.membresia && doc.idDocumentoCemefi !== catalogosSt.idsTipoDocumentoCemefi.membresia) || 
                                (catalogosSt.idsTipoDocumentoCemefi && catalogosSt.idsTipoDocumentoCemefi.membresia && doc.idDocumentoCemefi === catalogosSt.idsTipoDocumentoCemefi.membresia && showMembresia))) && 
                                    <DocumentComponent 
                                        disabled={disabled}
                                        type={type} 
                                        empresaSeleccionada={empresaSeleccionada} 
                                        doc={doc} 
                                        key={index} 
                                        convocatoriaSeleccionada={convocatoriaSeleccionada}/>
                        })}
                        {documents.length === 0 && <p>No se requieren documentos</p>}
                        {registroConvocatoriaSt.convocatoriaSeleccionada && registroConvocatoriaSt.convocatoriaSeleccionada.cvePrograma === "CADENAVALOR" && (
                            <div className="document">
                                <div className="doc-content">
                                    <div className="head">CARTA COMPROMISO</div>
                                    <FileIcon />
                                </div>
                                <div className="doc-actions">
                                    <button onClick={() => downloadCartaCompromiso("CV")}>Descargar</button>
                                </div>
                            </div>
                        )}
                        {registroConvocatoriaSt.convocatoriaSeleccionada && registroConvocatoriaSt.convocatoriaSeleccionada.cvePrograma === "ENTIDADPROMOTORA" && (
                            <div className="document">
                                <div className="doc-content">
                                    <div className="head">CARTA COMPROMISO</div>
                                    <FileIcon />
                                </div>
                                <div className="doc-actions">
                                    <button onClick={() => downloadCartaCompromiso("EP")}>Descargar</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="actions">
                <button onClick={prevStep}>Regresar</button>
                <button onClick={goBack}>Cerrar</button>
                {type === CVE_TIPODOC_CONVOCATORIA && !registroConvocatoriaSt.convocatoriaSeleccionada.isAcompaniante && !registroConvocatoriaSt.convocatoriaSeleccionada.isAdicional &&
                    <button onClick={sendToValidate}>Enviar a validar</button>
                }
                {type === CVE_TIPODOC_ENTIDAD && 
                    <button onClick={confirmValidation}>Enviar a validar</button>
                }
            </div>
            <ConfirmModal data={modalData} cancel={closeModal} />
        </div>

    )
}