/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as DocIcon } from '../../assets/icons/file.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { detectMimeType, getBase64 } from "../../app/commons";
import { ALERT_FAIL, ALERT_SUCCESS, CVE_TIPODOC_EVIDENCIA_INSTITUCIONAL } from "../../app/constantes";
import { deleteDocumentoEntidad, getDocument, postDocumentoEntidad, putDocumentoEntidad, uploadDocument } from "../../services/entidad";
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { useEffect, useState } from 'react';

export default function DocumentoEvidencia({onlyView, entidadPrincipal, evidenciaDoc, callback}) {
  const dispatch = useDispatch()
  const appSt = useSelector(appState)
  const catalogosSt = useSelector(catalogosServiceState)
  const [principal, setPrincipal] = useState(null)

  const deleteDoc = async () => {
    try {
        dispatch(setLoading(true));
        await deleteDocumentoEntidad({...appSt.serviceData, idDocumentoEntidad: evidenciaDoc.idDocumentoEntidad});
        dispatch(setLoading(false));
        callback();
    } catch (error) {
        dispatch(setLoading(false));
        console.log(error)
        dispatch(setAlert({
            show: true,
            messsage: "Error al eliminar documento",
            type: ALERT_FAIL
        }))
    }
  }

  const downloadFile = async () => {
    const docResp = await getDocument({
        ...appSt.serviceData,
        idEntidad: principal.idEntidad,
        idContent: evidenciaDoc.idContent
    });

    
    if (docResp.payload) {
        const dataType = detectMimeType(docResp.payload.base64, 'Evidencia Institucional');
        // const type = 'data:image/png'
        const file64 = docResp.payload.base64;
        const linkSource = `data:${dataType};base64,${file64}`;
        const downloadLink = document.createElement('a');
        const fileName = 'Evidencia Institucional';

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click()
    }
}

  const upload = async (event) => {
    const file = event.target.files[0];
    const extencion = `.${file.name.split('.').pop().toLowerCase()}`;
    const acceptArray = ['png', 'jpg', 'pdf', 'docx'];
    const acceptExt = acceptArray.toString();
    let valid = false;

    acceptArray.forEach(ext => {
      if (extencion.includes(ext)) {
        valid = true;
      }
    })

    if (valid && file) {
      dispatch(setLoading(true));
      const base64 = await getBase64(file);
      const body = {
        cveTipoDocumento: CVE_TIPODOC_EVIDENCIA_INSTITUCIONAL,
        cveDocumentoCemefi: CVE_TIPODOC_EVIDENCIA_INSTITUCIONAL,
        archivo: {
          nombreArchivo: file.name.replace('#', ''),
          tipo: file.type,
          base64: base64
        }
      }
      const data = {
        ...appSt.serviceData,
        idEntidad: principal.idEntidad
      }

      const resp = await uploadDocument(data, body);
      const evidenciaInst = catalogosSt.documentosEvidenciaInstitucional.find(doc => doc.cveDocumentoCemefi === CVE_TIPODOC_EVIDENCIA_INSTITUCIONAL);
      console.log(resp, evidenciaInst, catalogosSt.documentosEvidenciaInstitucional)
      if (resp.payload && evidenciaInst) {
        const bodyDocumentoEntidad = {
          idDocumentoCemefi: evidenciaInst.idDocumentoCemefi, // falta ver de donde viene este
          idContent: resp.payload.contentId
        }
        let respDocument
        if (evidenciaDoc) {
          respDocument = await putDocumentoEntidad(
            {
              ...appSt.serviceData,
              idDocumentoEntidad: evidenciaDoc.idDocumentoEntidad
            },
            {
              idEntidad: principal.idEntidad,
              ...bodyDocumentoEntidad
            }
          )
        } else {
          respDocument = await postDocumentoEntidad(appSt.serviceData, {
            ...bodyDocumentoEntidad,
            idEntidad: principal.idEntidad
          })
        }

        if (respDocument.payload || respDocument.idContent) {
          dispatch(setAlert({
            show: true,
            message: 'Documento Cargado',
            type: ALERT_SUCCESS
          }));
          callback();
        }
        dispatch(setLoading(false))
        console.log(respDocument);
      }

    } else {
      dispatch(setAlert({
        show: true,
        message: `El tipo de archivo no es valido, el archivo tiene que ser: ${acceptExt}`,
        type: ALERT_FAIL
      }))
    }
  }

  const getEvidenciaName = (filePath) => {
    const path = filePath.split('/');
    return path.slice(-1);
  }

  useEffect(() => {
    if (entidadPrincipal) {
      if (!entidadPrincipal.idEntidad && entidadPrincipal.id_entidad) {
        setPrincipal({
          ...entidadPrincipal,
          idEntidad: entidadPrincipal.id_entidad
        })
      } else {
        setPrincipal(entidadPrincipal)
      }
      console.log(entidadPrincipal)
    }

  }, [entidadPrincipal])

  return <div className={`doc-evidencias-inst ${evidenciaDoc ? 'uploaded' : 'upload'} ${onlyView ? 'disabled' : ''}` }>
  <div className="input-wrap">
    <input disabled={onlyView} type="file" onChange={e => upload(e)} />
    <div className="icon">
      <DocIcon />
    </div>
    <div className='label'>
      <p>Evidencia Institucional</p>
      {evidenciaDoc &&
        <p>{getEvidenciaName(evidenciaDoc.idContent)}</p>
      }
    </div>
  </div>
  {evidenciaDoc &&
    <div className="doc-actions">
      <button disabled={onlyView} className='btn-icon' onClick={deleteDoc}>
        <DeleteIcon />
        Eliminar
      </button>
      <button className='btn-icon' onClick={downloadFile}>
        <DownloadIcon />
        Descargar
      </button>
    </div>
  }
</div>
}