/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { ALERT_FAIL, SECTIONS } from "../app/constantes";
import EntidadesAprobadas from "../components/publicacion/entidades-aprobadas";
import EntidadesDestacadas from "../components/publicacion/entidades-destacadas";
import Banner from '../assets/images/Banner_provisional_plataforma.png';
import { postEntidadesAprobadasPubli } from "../services/publicacion";
import { getAmbitosPublicas2, getAreasPublicas } from "../services/catalogos";
import { filtrosPublicacionPublic } from "../app/forms/publicacion/filtros-entidades-destacadas";
import InputComp from "../components/generales/inputComponent";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../slices/appSlice";
import { getConvocatoriasPublicas } from "../services/convocatorias";

export default function PublicacionResultados({ back }) {
  const dispatch = useDispatch()
  const [view, setView] = useState(SECTIONS.one);
  const [form, setForm] = useState(JSON.parse(JSON.stringify(filtrosPublicacionPublic)))
  const [aprobadas, setAprobadas] = useState(null)
  const [destacadas, setDestacadas] = useState(null)
  const [areaCatalogo, setAreaCatalogo] = useState(null)
  const [ambitosCatalogo, setAmbitosCatalogo] = useState(null)
  const [convocatoriaCatalogo, setConvocatoriaCatalogo] = useState([])

  const searchAprobadas = async (e, aprobadas) => {
    console.log(e, aprobadas)
    dispatch(setLoading(true));
    try {
      const resp = await postEntidadesAprobadasPubli({
        area: form.idArea.value, 
        idConvocatoria: form.idConvocatoria.value,
        body: {
          idsAmbitos: e && e.idAmbito.value !== '' ? [e.idAmbito.value] : [],
          idsCriterio: [],
          rfc: null
        }
      }, aprobadas)
      if (aprobadas) {
        setAprobadas(resp)
      } else {
        setDestacadas(resp)
      }
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error)
      dispatch(setLoading(false));
      setAlert({
        show: true,
        message: 'Error al consultar',
        type: ALERT_FAIL
      })      
    }
  }

  const changeSection = (section) => {
    setDestacadas(null)
    setView(section);
  }

  const populateAreas = async () => {
    dispatch(setLoading(true))
    try {
      const areasResp = await getAreasPublicas();   
      setAreaCatalogo(areasResp.payload)
      dispatch(setLoading(false))
    } catch (error) {
      console.log(error)
      dispatch(setLoading(false))
      dispatch(setAlert({
        show: true,
        message: 'Error al consultar areas',
        type: ALERT_FAIL
      }))
      
    }
  }

  const getConvocatorias = async () => {
    if (form.anio.value === "" || Number(form.anio.value) < 2023) {
      dispatch(setAlert({
        show: true,
        message: 'El año no es valido, tiene que ser un año posterior al 2023',
        type: ALERT_FAIL
      }))
      return
    }
    dispatch(setLoading(true))
    try {
      const resp = await getConvocatoriasPublicas({area: form.idArea.value, anio: form.anio.value});   
      setConvocatoriaCatalogo(resp.payload)
      dispatch(setLoading(false))
    } catch (error) {
      console.log(error)
      dispatch(setLoading(false))
      dispatch(setAlert({
        show: true,
        message: 'Error al consultar areas',
        type: ALERT_FAIL
      }))
    }
  }

  useEffect(() => {
    populateAreas()
  }, [])

  useEffect(() => {
    if (form.idArea.value) {
      getConvocatorias()
    }
  }, [form.idArea.value])

  useEffect(() => {
    if (form.idArea.value) {
      const clone = JSON.parse(JSON.stringify(form))
      clone.idConvocatoria.value = ""
      clone.idConvocatoria.invalid = false
      setForm(clone);
    }
    if (form.idArea.value && form.anio.value) {
      getConvocatorias()
    }
  }, [form.anio.value])

  useEffect(() => {
    if (aprobadas || destacadas) {
      setAprobadas(null) 
      setDestacadas(null)
    }

  }, [form.idArea.value, form.anio.value])

  const handleBuscar = async () => {
    try {
      searchAprobadas(null, true)
      const ambitosResp = await getAmbitosPublicas2({ idConvocatoria: form.idConvocatoria.value });
      console.log(ambitosResp);
      setAmbitosCatalogo(ambitosResp.payload)
    } catch (error) {
      
    }
  }

  return (<div className="aprobacion public">
    <div className="wrapper">
      <div className="head">
        <h3>Empresas Acreditadas</h3>
      </div>
      <div className="banner">
        <img src={Banner} alt="banner" />
      </div>
      <div className="form">
        <div className="four-col">
          {areaCatalogo &&
            <InputComp
              label="Area"
              name="idArea"
              type="select"
              form={form}
              setForm={setForm}
              catalogos={areaCatalogo}
              catalogoLabel={'descAreaCemefi'}
              catalogoValue={'idAreaCemefi'}
            />
          }
           <InputComp
              label="Año"
              name="anio"
              type="number"
              form={form}
              setForm={setForm}
            />
          {convocatoriaCatalogo &&
            <InputComp
              label="Convocatoria"
              name="idConvocatoria"
              type="select"
              disabled={form.idArea.value === ''}
              form={form}
              setForm={setForm}
              catalogos={convocatoriaCatalogo}
              catalogoLabel={'descConvocatoria'}
              catalogoValue={'idConvocatoria'}
            />
          }
          <button disabled={form.idConvocatoria.value === ""} onClick={handleBuscar}>Buscar</button>
        </div>
      </div>
      <div className="card">
        {aprobadas && <>
          <nav>
            <button
              className={view === SECTIONS.one ? 'active' : ''}
              onClick={() => changeSection(SECTIONS.one)}
            >Empresas Acreditadas</button>
            <button
              data-tool="Si quieres conocer a las mejores empresas tenemos un apartado donde conocerás aquellas con las mejores prácticas"
              className={view === SECTIONS.two ? 'active' : 'tool-tip'}
              onClick={() => changeSection(SECTIONS.two)}>
              Practicas Destacadas</button>
          </nav>
          {view === SECTIONS.one &&
            <EntidadesAprobadas registros={aprobadas} />
          }
          {view === SECTIONS.two &&
            <EntidadesDestacadas registros={destacadas} search={(e) => searchAprobadas(e, false)} ambitos={ambitosCatalogo} />
          }
        </>}
      </div>
    </div>
  </div>)
}