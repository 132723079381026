import { useEffect, useState } from "react";

import '../../styles/template-styles/table-empresas.scss';
import '../../styles/template-styles/templates.scss';

import convocatoria from '../../assets/images/background-image-100.jpg';
import { useDispatch, useSelector } from "react-redux";
import { catalogosServiceState, getTable } from "../../slices/catalogosServiceSlice";
import { appState } from "../../slices/appSlice";

const tamanoEmpresaMock = [
    {
        nombre: 'Micro',
        clave: '1',
        descripcion: '',
        estatus: 1
    },
    {
        nombre: 'Pequeña',
        clave: '2',
        descripcion: '',
        estatus: 1
    },
    {
        nombre: 'Mediana',
        clave: '3',
        descripcion: '',
        estatus: 1
    },
    {
        nombre: 'Grande',
        clave: '4',
        descripcion: '',
        estatus: 1
    }
]

export default function TablaEmpresasPreview({ data }) {
    const dispatch = useDispatch();
    const catalogosSt = useSelector(catalogosServiceState);
    const appSt = useSelector(appState);

    /**
     * Set Configuration state
     */
    const [moduleStyles, setModuleStyles] = useState({
        backgroundColor: '',
    });
    const [contentStyles, setContentStyles] = useState({
        '--headBackgroundColor': '',
        '--headTextColor': '',
        '--oddBackgroundColor': '',
        '--oddTextColor': '',
        '--pairBackgroundColor': '',
        '--pairTextColor': '',
    });

    /**
     * Check config values
     */
    useEffect(() => {
        if (data) {
            const styles = {
                backgroundColor: data.backgroundColor,
            };
            if (data.alturaFija) {
                styles.height = `${data.height}px`
            }
            console.log(styles)
            setModuleStyles(styles)
            setContentStyles({
                '--headBackgroundColor': data.contenido.headBackgroundColor,
                '--headTextColor': data.contenido.headTextColor,
                '--oddBackgroundColor': data.contenido.oddBackgroundColor,
                '--oddTextColor': data.contenido.oddTextColor,
                '--pairBackgroundColor': data.contenido.pairBackgroundColor,
                '--pairTextColor': data.contenido.pairTextColor,
            })
        }
        if (!catalogosSt.tableSector) {
            dispatch(getTable(appSt.serviceData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div className={`${data.layout}-conv`} style={moduleStyles}>
            {data.backgroundImage &&
                <img src={convocatoria} alt="fondo" />
            }
            <div className={data.wrapContent ? 'wrapper-conv' : ''}>
                <div className={`content-conv ${data.contenido.estilo}`} style={contentStyles}>
                    <table>
                        <thead>
                            <tr>
                                <td>Sector</td>
                                {tamanoEmpresaMock && tamanoEmpresaMock.map((size, index) => {
                                    return <th key={index}>{size.nombre}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {catalogosSt.tableData && catalogosSt.tableData.map((sector, index) => {
                                return <tr key={index}>
                                    <th>{sector.descSectorSe}</th>
                                    {sector.tamanioInfoVo.map((size, index) => {
                                        return <td key={index}>{size.rangoNumEmpleados}</td>
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}