import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../slices/appSlice';
import registroReducer from '../slices/registroSlice';
import misEmpresasReducer from '../slices/misEmpresasSlice';
import registroConvocatoriaReducer from '../slices/registroConvocatoriaSlice';
import usuariosReducer from '../slices/usuariosSlice';
import entidadServicesReducer from '../slices/entidadServicesSlice';
import usuariosServicesReducer from '../slices/usuariosServicesSlice';
import catalogosServiceReducer from '../slices/catalogosServiceSlice';
import instrumentoServicesReducer from '../slices/instrumentoServiceSlice';
import publicacionReducer from '../slices/publicacionSlice';
import pagosReducer from '../slices/pagosSlice';
import pagosCursosReducer from '../slices/pagosCursosSlice';
import retroReducer from '../slices/retroSlice';
import registroConsultorReducer from '../slices/registroConsultor';

export const store = configureStore({
  reducer: {
    app: appReducer,
    registro: registroReducer,
    misEmpresas: misEmpresasReducer,
    registroConvocatoria: registroConvocatoriaReducer,
    usuarios: usuariosReducer,
    entidadServices: entidadServicesReducer,
    usuariosServices: usuariosServicesReducer,
    catalogosService: catalogosServiceReducer,
    instrumentoServices: instrumentoServicesReducer,
    publicacion: publicacionReducer,
    pagos: pagosReducer,
    pagosCursos: pagosCursosReducer,
    retro: retroReducer,
    registroConsultor: registroConsultorReducer,
  },
});
