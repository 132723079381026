import { NOTIFICACIONES } from "../app/constantes";
import { postRequest } from "./http-service";

const url = process.env.REACT_APP_NOTIFICACIONES_SERVICE;

export const postNotificacion = async (data, body) => {
    const requestUrl = `${url}/notificacion/correo`;

    const template = {
        from: NOTIFICACIONES.defaultFrom,
        personalizations: {
            to: body.to ? body.to : [NOTIFICACIONES.defaultTo],
            dynamicTemplateData: body.templateData ? body.templateData : {}
        },
        templateId: body.templateId,
        attachments: body.attachments ? body.attachments : []
    }

    const resp = await postRequest(requestUrl, data.token, {payload: template});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};