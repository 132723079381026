import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { USUARIO_NO_REGISTRADO } from '../app/constantes';
import { getAllContactosCemefi } from '../services/contactosCemefi';
import { getPersona } from '../services/persona';
import { getUserById, getUsersByUser } from '../services/usuarios';

const initialState = {
    loading: false,
    usuario: null,
    persona: null,
    usuarios: null,
    contactosCemefi: null
}

/**
 * Obtenemos los contactos de cemefi
 */
export const getContactosCemefi = createAsyncThunk('usuarios/getContactosCemefi', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getAllContactosCemefi(data);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Obtenemos la info de todos los usuarios por area
 */
export const getUsuarios = createAsyncThunk('usuarios/getUsuarios', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getUsersByUser(data);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Obtenermos la info de un usuario por su id
 */
export const getUsuarioPorId = createAsyncThunk('usuarios/getUserById', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getUserById(data);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Obtenermos la info de un usuario federado por su id
 */
 export const getFederatedUser = createAsyncThunk('usuarios/getFederatedUser', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getFederatedUser(data);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

/**
 * Obtenermos la info de persona de un usuario por su id
 */
 export const getPersonaById = createAsyncThunk('usuarios/getPersonaById', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getPersona(data);
    } catch (error) {
        console.log('error', error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const usuariosServicesSlice = createSlice({
    name: 'usuariosServices',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getUsuarioPorId.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getUsuarioPorId.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(getPersonaById.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getPersonaById.rejected, (state) => {
            state.loading = false
        })
        builder.addCase((
            getContactosCemefi.pending || 
            getUsuarios.pending || 
            getPersonaById.pending || 
            getUsuarioPorId.pending)
        , (state) => {
            state.loading = true
        })
        builder.addCase((
            getContactosCemefi.rejected || 
            getUsuarios.rejected || 
            getPersonaById.rejected || 
            getUsuarioPorId.rejected)
        , (state) => {
            state.loading = false
        })
        builder.addCase(getContactosCemefi.fulfilled, (state, action) => {
            state.loading = false;
            state.contactosCemefi = action.payload
        })
        builder.addCase(getUsuarios.fulfilled, (state, action) => {
            state.loading = false;
            state.usuarios = action.payload.payload
        })
        builder.addCase(getPersonaById.fulfilled, (state, action) => {
            state.loading = false;
            state.persona = action.payload.payload && action.payload.payload.personas ? action.payload.payload.personas[0] : null
        })
        builder.addCase(getUsuarioPorId.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload && action.payload.claveError === USUARIO_NO_REGISTRADO) {
                state.usuario = {
                    registrado: false,                    
                }
            } else if (action.payload && action.payload.payload.usuario) {
                state.usuario = {
                    data: action.payload.payload.usuario,
                    meta: action.payload.payload.metadatos.metadataUser,
                    consultor: action.payload.payload.consultorAcreditado,
                    registrado: true
                }
            }
        })
    }
})

// export const { setMisEmpresas } = usuariosServicesSlice.actions;
export const usuariosServicesState = (state) => state.usuariosServices;

export default usuariosServicesSlice.reducer;