/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import '../../styles/publicacion.scss';
import { filtrosReporteResultados } from "../../app/forms/publicacion/filtros-reporte-resultados";
import { useDispatch, useSelector } from "react-redux";
import { catalogosServiceState, getAmbitosCatalogo, getAmbitosConvocatoria, getAniosByConvocatoria, getAniosPrograma, getCatalogoEstados, getIndustriaCatalogo, getSectorCatalogo,  getSubsectorCatalogo, getTamanioConvocatoria } from "../../slices/catalogosServiceSlice";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import InputComp from "../generales/inputComponent";
import { formatForm } from "../../app/commons";
import { downloadResultReport, getIndicadorReporte, getReporteResultados } from "../../services/publicacion";
import { publicacionState } from "../../slices/publicacionSlice";
import { ALERT_FAIL } from "../../app/constantes";
import { getInstrumentoByConvocatoriaId } from "../../services/instrumento";
import { filtrosIndicador } from "../../app/forms/publicacion/filtros-indicador";
import { getInstrumentoCatalogosIndicador, getInstrumentoCatalogosSubambito } from "../../services/catalogos";
import { getEstatusEvaluacion, postEvaluacionInstrumentoRegistro } from "../../services/evaluacion";


export default function ReporteResultados({ empresaSeleccionada, back }) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const publicacionSt = useSelector(publicacionState);
    const catalogoSt = useSelector(catalogosServiceState);
    const [subambitos, setSubambitos] = useState([]);
    const [indicadores, setIndicadores] = useState([]);

    const [form, setForm] = useState(JSON.parse(JSON.stringify(filtrosReporteResultados)));
    const [formIndicador, setFormIndicador] = useState(JSON.parse(JSON.stringify(filtrosIndicador)));
    const [comparativo, setComparativo] = useState('global');
    const [tab, setTab] = useState('criterio');
    const [registros, setRegistros] = useState(null);
    const [registrosIndicadores, setRegistrosIndicadores] = useState(null);
    const [instrumentoData, setInstrumentoData] = useState(null);

    const changeComparativo = (e) => {
        setForm(JSON.parse(JSON.stringify(filtrosReporteResultados)));
        setComparativo(e);
    }

    const isFilterDisabled = () => {
        const formated = formatForm(form);
        return comparativo !== 'global' && 
            formated.idEstado === '' && 
            formated.idIndustria === '' &&
            formated.idSector === '' &&
            formated.idSubsector === '' &&
            formated.anioEsr === '' &&
            formated.idTamanio === ''
    }

    const search = async () => {
        dispatch(setLoading(true));
        try {
            const formated = formatForm(form);
            const body = {
                idEstado: formated.idEstado ? formated.idEstado : null,
                idTamanio: formated.idTamanio ? formated.idTamanio : null,
                idIndustria: formated.idIndustria ? formated.idIndustria : null,
                idSector: formated.idSector ? formated.idSector : null,
                idSubsector: formated.idSubsector ? formated.idSubsector : null,
                anioEsr: formated.anioEsr ? formated.anioEsr : null,
                isGlobal: comparativo === 'global'
            }
            console.log(empresaSeleccionada, publicacionSt);
            const resp = await getReporteResultados({
                ...appSt.serviceData,
                idInstrumentoRegistro: instrumentoData.idInstrumentoRegistro,
                // idInstrumentoRegistro: 281,
                idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria,
                // idConvocatoria: 2,
                body
            });
            dispatch(setLoading(false));
            setRegistros(resp);
        } catch (error) {
            console.log(error);
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: 'Error al consultar entidades',
                type: ALERT_FAIL
            }))
        }

    }

    const searchIndicadores = async () => {
        dispatch(setLoading(true));
        try {
            const formated = formatForm(form);
            const formatedIndicador = formatForm(formIndicador);
            console.log(formatedIndicador)
            const body = {
                idEstado: formated.idEstado ? formated.idEstado : null,
                idTamanio: formated.idTamanio ? formated.idTamanio : null,
                idIndustria: formated.idIndustria ? formated.idIndustria : null,
                idSector: formated.idSector ? formated.idSector : null,
                idSubsector: formated.idSubsector ? formated.idSubsector : null,
                anioEsr: formated.anioEsr ? formated.anioEsr : null,
                isGlobal: comparativo === 'global',
                idsAmbito: formatedIndicador.idAmbito ? [formatedIndicador.idAmbito] : [],
                idsSubambito: formatedIndicador.idSubambito ? [formatedIndicador.idSubambito] : [],
                idsIndicador: formatedIndicador.idIndicador ? [formatedIndicador.idIndicador] : [],
            }
            console.log(empresaSeleccionada, publicacionSt);
            const resp = await getIndicadorReporte({
                ...appSt.serviceData,
                idInstrumentoRegistro: instrumentoData.idInstrumentoRegistro,
                // idInstrumentoRegistro: 281,
                idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria,
                // idConvocatoria: 2,
                body
            });
            dispatch(setLoading(false));
            setRegistrosIndicadores(resp);
        } catch (error) {
            console.log(error);
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: 'Error al consultar entidades',
                type: ALERT_FAIL
            }))
        }

    }

    const getInstrumento = async () => {
        try {
            const resp = await getInstrumentoByConvocatoriaId({
                ...appSt.serviceData,
                idRegistroConvocatoria: publicacionSt.currentConvocatoria.idRegistroConvocatoria
            })
            if (resp && resp.length > 0) {
                setInstrumentoData(resp[0]);
            }
        } catch (error) {
            dispatch(setAlert({
                show: true,
                message: 'Error al consultar registro',
                type: ALERT_FAIL
            }))

        }
    }

    const changeTab = (select) => {
        setTab(select);
    }

    const downloadReport = async () => {
        let idTipoConsulta = null;
        dispatch(setLoading(true));
        const formated = formatForm(form);
        const formatedIndicador = formatForm(formIndicador);

        let body = {
            idEstado: formated.idEstado ? formated.idEstado : null,
            idTamanio: formated.idTamanio ? formated.idTamanio : null,
            idIndustria: formated.idIndustria ? formated.idIndustria : null,
            idSector: formated.idSector ? formated.idSector : null,
            idSubsector: formated.idSubsector ? formated.idSubsector : null,
            anioEsr: formated.anioEsr ? formated.anioEsr : null,
            isGlobal: comparativo === 'global'
        }
        switch (tab) {
            case 'indicador':
                idTipoConsulta = 1;
                body = {
                    ...body,
                    idsAmbito: formatedIndicador.idAmbito ? [formatedIndicador.idAmbito] : [],
                    idsSubambito: formatedIndicador.idSubambito ? [formatedIndicador.idSubambito] : [],
                    idsIndicador: formatedIndicador.idIndicador ? [formatedIndicador.idIndicador] : [],
                }
                break;
            case 'ambitos':
                idTipoConsulta = 3;
                break;
            case 'subambitos':
                idTipoConsulta = 2;
                break;
            case 'criterio':
                idTipoConsulta = 4;
                break;
            default:
                break;
        }
       
        const blob = await downloadResultReport({
            ...appSt.serviceData,
            idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria,
            idInstrumentoRegistro: instrumentoData.idInstrumentoRegistro,
            // idInstrumentoRegistro: 281,
            // idConvocatoria: 2,
            idTipoConsulta,
            body
        });
        dispatch(setLoading(false));
        if (blob) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Reporte de resultados';
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
    }

    const getSubambitos = async () => {
        dispatch(setLoading(true));
        setIndicadores([])
        const resp = await getInstrumentoCatalogosSubambito({...appSt.serviceData, idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria,  idEntidad: empresaSeleccionada.idEntidad, idAmbito: formIndicador.idAmbito.value})
        dispatch(setLoading(false));
        if (resp.payload) {
            setSubambitos(resp.payload)
        }
    }

    const getIndicadores = async () => {
        dispatch(setLoading(true));
        const resp = await getInstrumentoCatalogosIndicador({...appSt.serviceData, idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria, idEntidad: empresaSeleccionada.idEntidad, idSubAmbito: formIndicador.idSubambito.value})
        dispatch(setLoading(false));
        if (resp.payload) {
            setIndicadores(resp.payload)
        }
    }

    const checkEvaluacion = async () => {
        try {
            dispatch(setLoading(true));
            const resp = await getEstatusEvaluacion({
                ...appSt.serviceData, 
                idInstrumentoRegistro: publicacionSt.currentConvocatoria.idInstrumentoRegistro
            })
            console.log(resp)
            if (resp && resp.length > 0 && resp[0].promediar === '1') {
                await postEvaluacionInstrumentoRegistro({
                    ...appSt.serviceData, 
                    idInstrumentoRegistro: publicacionSt.currentConvocatoria.idInstrumentoRegistro
                })
            }
            dispatch(setLoading(false));
            
        } catch (error) {
            dispatch(setLoading(false));
            console.log(error)            
        }
    }

    useEffect(() => {
        if (!catalogoSt.estados) {
            dispatch(getCatalogoEstados(appSt.serviceData));
        }
        if (!catalogoSt.tamanioConvocatoria) {
            dispatch(getTamanioConvocatoria({...appSt.serviceData, idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria}));
        }
        if (!catalogoSt.aniosPrograma) {
            dispatch(getAniosPrograma({...appSt.serviceData, idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria}));
        }
        if (!catalogoSt.industrias) {
            dispatch(getIndustriaCatalogo(appSt.serviceData));
        }
        if (catalogoSt.sectores && catalogoSt.sectores.length === 0) {
            dispatch(getSectorCatalogo(appSt.serviceData));
        }
        if (catalogoSt.subsectores && catalogoSt.subsectores.length === 0) {
            dispatch(getSubsectorCatalogo(appSt.serviceData));
        }
        if (!catalogoSt.ambitosConvocatoria) {
            dispatch(getAmbitosConvocatoria({...appSt.serviceData, idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria, idEntidad: empresaSeleccionada.idEntidad }));
        }
        checkEvaluacion();
    }, [])

    useEffect(() => {
        if (formIndicador.idAmbito.value !== "") {
            getSubambitos()
        }
    }, [formIndicador.idAmbito.value])
    
    useEffect(() => {
        if (formIndicador.idSubambito.value !== "") {
            getIndicadores()
        }
    }, [formIndicador.idSubambito.value])

    useEffect(() => {
        if (publicacionSt.currentConvocatoria) {
            getInstrumento()
        }

    }, [publicacionSt.currentConvocatoria])

    return (<div className="reporte-resultados">
        <div className="wrapper">
            <div className="head">
                <button onClick={back}>
                    <BackIcon />
                </button>
                <h3>Reporte de Resultados</h3>
            </div>
            <div className="card filtros">
                <h3>Filtrar Empresas</h3>
                <p>Selecciona el grado de comparación con el que quieres medir los resultados de tu entidad</p>
                <div className="four-col">
                    <div className="input-wrapper">
                        <label>Comparativo</label>
                        <select value={comparativo} onChange={(e) => changeComparativo(e.target.value)}>
                            <option value="global">Global</option>
                            <option value="estado">Estado</option>
                            <option value="tamanio">Tamaño</option>
                            <option value="industria">Industria</option>
                            <option value="sector">Sector</option>
                            <option value="subsector">Subsector</option>
                            <option value="anios">Años ESR</option>
                        </select>
                    </div>
                    {catalogoSt.estados && comparativo === 'estado' &&
                        <InputComp label="Estado" name="idEstado" type="select" form={form} setForm={setForm} catalogos={catalogoSt.estados} catalogoLabel={'descEstado'} catalogoValue={'idEstado'} />
                    }
                    {catalogoSt.tamanioConvocatoria && comparativo === 'tamanio' &&
                        <InputComp label="Tamaño" name="idTamanio" type="select" form={form} setForm={setForm} catalogos={catalogoSt.tamanioConvocatoria} catalogoLabel={'descTamanio'} catalogoValue={'idTamanio'} />
                    }
                    {catalogoSt.industrias && comparativo === 'industria' &&
                        <InputComp label="Industria" name="idIndustria" type="select" form={form} setForm={setForm} catalogos={catalogoSt.industrias} catalogoLabel={'descIndustria'} catalogoValue={'idIndustria'} />
                    }
                    {catalogoSt.sectores && comparativo === 'sector' &&
                        <InputComp label="Sector" name="idSector" type="select" form={form} setForm={setForm} catalogos={catalogoSt.sectores} catalogoLabel={'descSector'} catalogoValue={'idSector'} />
                    }
                    {catalogoSt.subsectores && comparativo === 'subsector' &&
                        <InputComp label="Subsector" name="idSubsector" type="select" form={form} setForm={setForm} catalogos={catalogoSt.subsectores} catalogoLabel={'descSubsectorInegi'} catalogoValue={'idSubsectorInegi'} />
                    }
                    {catalogoSt.aniosPrograma && comparativo === 'anios' &&
                        <InputComp label="Años ESR" name="anioEsr" type="select" form={form} setForm={setForm} catalogos={catalogoSt.aniosPrograma} catalogoLabel={'aniosParticipacion'} catalogoValue={'aniosParticipacion'} />
                    }
                    <button disabled={isFilterDisabled()} onClick={search}>Filtrar</button>
                </div>
            </div>

            {registros &&
                <div className="card results">
                    <nav>
                        <button onClick={() => changeTab('criterio')} className={tab === 'criterio' ? 'active' : ''}>Criterio</button>
                        <button onClick={() => changeTab('ambitos')} className={tab === 'ambitos' ? 'active' : ''}>Ambito</button>
                        <button onClick={() => changeTab('subambitos')} className={tab === 'subambitos' ? 'active' : ''}>Subambito</button>
                        <button onClick={() => changeTab('indicador')} className={tab === 'indicador' ? 'active' : ''}>Indicador</button>
                    </nav>
                    {tab !== 'indicador' &&
                        <div className="table-container">
                            <div className="actions">
                                <button onClick={downloadReport}>Descargar Reporte</button>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Criterio</th>
                                        {(tab === 'ambitos' || tab === 'subambitos') && <th>Ambito</th>}
                                        {tab === 'subambitos' && <th>Subambitos</th>}
                                        <th>Nivel Desarrollo de la Entidad</th>
                                        <th>Nivel Desarrollo Comparativo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {registros[tab].map((item, index) => {
                                        return tab !== 'indicador' && <tr key={index}>
                                            <td>{item.desc_criterio}</td>
                                            {(tab === 'ambitos' || tab === 'subambitos') && <td>{item.desc_ambito}</td>}
                                            {tab === 'subambitos' && <td>{item.desc_subambito}</td>}
                                            <td>{item.nivelDesarrolloEntidad}</td>
                                            <td>{item.nivelSeleccionado}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                    {tab === 'indicador' &&
                        <div className="table-conatiner">
                            <div className="filtros">
                                <div className="four-col">
                                    {catalogoSt.ambitosConvocatoria &&
                                        <InputComp label="Ambito" name="idAmbito" type="select" form={formIndicador} setForm={setFormIndicador} catalogos={catalogoSt.ambitosConvocatoria} catalogoLabel={'descAmbito'} catalogoValue={'idAmbito'} />
                                    }
                                    <InputComp disabled={subambitos.length === 0} label="Subambito" name="idSubambito" type="select" form={formIndicador} setForm={setFormIndicador} catalogos={subambitos} catalogoLabel={'descSubambito'} catalogoValue={'idSubambito'} />
                                    <InputComp disabled={indicadores.length === 0} label="Indicador" name="idIndicador" type="select" form={formIndicador} setForm={setFormIndicador} catalogos={indicadores} catalogoLabel={'descIndicador'} catalogoValue={'idIndicador'} />
                                    <button disabled={formIndicador.idIndicador.value === ""} onClick={searchIndicadores}>Filtrar</button>
                                </div>
                            </div>
                            {registrosIndicadores && <>
                                <div className="actions">
                                    <button onClick={downloadReport}>Descargar Reporte</button>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Criterio</th>
                                            <th>Ambito</th>
                                            <th>Subambitos</th>
                                            <th>Nivel Desarrollo de la Entidad</th>
                                            <th>Nivel Desarrollo Comparativo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {registrosIndicadores.map((item, index) => {
                                            return <tr key={index}>
                                                <td>{item.desc_criterio}</td>
                                                <td>{item.desc_ambito}</td>
                                                <td>{item.desc_subambito}</td>
                                                <td>{item.nivelDesarrolloEntidad}</td>
                                                <td>{item.nivelSeleccionado}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </>}
                        </div>
                    }
                </div>
            }

        </div>
    </div>)
}