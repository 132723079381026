import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { catalogosServiceState, getDocumentosCatalogo } from "../../slices/catalogosServiceSlice";
import { formatCurrency } from "../../app/commons";
import { ALERT_FAIL, CVE_TIPODOC_COBRO } from "../../app/constantes";

import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';

import moment from "moment/moment";
import { getDocument } from "../../services/entidad";
import ModalDatosFactura from "./modalDatosFactura";
import { findCatalogById } from "../../app/commons";
import { getCobroCursoFacturas } from "../../services/cursos";
import { pagosCursosState } from "../../slices/pagosCursosSlice";

export default function Facturas({ idEntidad }) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const catalogosSt = useSelector(catalogosServiceState);
    const pagosSt = useSelector(pagosCursosState);
    const [formatedPagos, setFormatedPagos] = useState(null);
    const [modalData, setModalData] = useState({
        show: false,
        list: null
    });

    const getFacturas = async () => {
        dispatch(setLoading(true));
        let facturas = null;
        try {
            const resp = await getCobroCursoFacturas({
                ...appSt.serviceData, idEntidad,
                idConvocatoria: pagosSt.currentConvocatoria ? pagosSt.currentConvocatoria.idConvocatoria : null
            });
            formatPagos(resp.payload)
            facturas = resp;
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: 'Error al traer las facturas',
                type: ALERT_FAIL
            }))
            dispatch(setLoading(false));            
        }
        return facturas;
    }

    /**
     * vemos si el pago ya tiene un cobrante
     */
     const formatPagos = async (resp) => {
        const array = [];
        if (resp) {
            resp.forEach(item => {
                const obj = {...item};
                const registro = item;
                const notas = registro.documentos.filter(item => {
                    return item.cve_documento_cemefi.includes("NOTACREDITO")
                })
                const complementarios = registro.documentos.filter(item => {
                    return item.cve_documento_cemefi.includes("COMPLEMENTOPAAGO")
                })
                const facturas = registro.documentos.filter(item => {
                    return item.cve_documento_cemefi.includes("FACTURA")
                })
                obj.descCobroCurso = item.desc_cobro_curso;
                obj.cantidad = registro.cantidad
                obj.facturas = facturas
                obj.complementarios = complementarios
                obj.notas = notas
                array.push(obj)
            });
        }
        setFormatedPagos(array);
    }

    const getFileName = (filePath) => {
        const path = filePath.split('_');
        return path.slice(-1);
    }

    /**
     * Descargamos una evidencia
     * @param {*} evidencia 
     */
    const descargarFactura = async (file) => {
        dispatch(setLoading(true));
        const docResp = await getDocument({
            ...appSt.serviceData,
            idEntidad,
            idContent: file.id_content
        });
        const name = getFileName(file.id_content);
        const fileName = name[0]
        const ext = fileName.split('.').pop();

        if (docResp.payload) {
            const type = ext === 'pdf' ? 'data:application/pdf' : `data:application/xml`;
            // const type = 'data:application/png'
            const file64 = docResp.payload.base64;
            const linkSource = `${type};base64,${file64}`;
            const downloadLink = document.createElement('a');

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            dispatch(setLoading(false));
        }
    }

    const closeModal = () => {
        setModalData({show: false, list: null});
    }

    useEffect(() => {
        dispatch(getDocumentosCatalogo({...appSt.serviceData, cveTipoDocumento: CVE_TIPODOC_COBRO}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idEntidad]);

    useEffect(() => {
        getFacturas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    return (
        <div className="facturas-view">
            <h3>Facturas</h3>
            <table>
                <thead>
                    <tr>
                        <th>Estatus</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Fecha</th>
                        <th>Monto Unitario</th>
                        <th>Total</th>
                        <th>Facturas</th>
                        <th>Nota de Crédito</th>
                        <th>Complemento de Pago</th>
                    </tr>
                </thead>
                <tbody>
                    {formatedPagos && formatedPagos.map((pago, index) => {
                        return <tr key={index}>
                            <td>{findCatalogById(pago.id_estatus_cobro, catalogosSt.estatusCobro, 'idEstatusCobro', 'descEstatusCobro')}</td>
                            <td>{pago.desc_cobro_curso}</td>
                            <td>{pago.cantidad}</td>
                            <td>{moment(pago.created_at).format('YYYY-MM-DD')}</td>
                            <td>{formatCurrency(pago.monto_cobro)}</td>
                            <td>{formatCurrency(Number(pago.monto_cobro) * pago.cantidad)}</td>
                            <td>
                                {pago.facturas && pago.facturas.length > 0 &&
                                    <div className="submenu">
                                        <DownloadIcon />
                                        <ul className="menu">
                                            {pago.facturas.map((factura, index) => {
                                                return <li className="factura" key={index} onClick={() => descargarFactura(factura)}>
                                                    <DownloadIcon />                                                    
                                                    <p>{getFileName(factura.id_content)}</p>
                                                </li>
                                            })}
                                        </ul>

                                    </div>
                                }
                            </td>
                            <td>
                                {pago.notas && pago.notas.length > 0 &&
                                    <div className="submenu">
                                        <DownloadIcon />
                                        <ul className="menu">
                                            {pago.notas.map((factura, index) => {
                                                return <li className="factura" key={index} onClick={() => descargarFactura(factura)}>
                                                    <DownloadIcon />                                                    
                                                    <p>{getFileName(factura.id_content)}</p>
                                                </li>
                                            })}
                                        </ul>

                                    </div>
                                }
                            </td>
                            <td>
                                {pago.complementarios && pago.complementarios.length > 0 &&
                                    <div className="submenu">
                                        <DownloadIcon />
                                        <ul className="menu">
                                            {pago.complementarios.map((factura, index) => {
                                                return <li className="factura" key={index} onClick={() => descargarFactura(factura)}>
                                                    <DownloadIcon />                                                    
                                                    <p>{getFileName(factura.id_content)}</p>
                                                </li>
                                            })}
                                        </ul>

                                    </div>
                                }
                            </td>
                        </tr>
                    })}
                    {formatedPagos && formatedPagos.length === 0 && <p>Sin facturas disponibles</p>}
                </tbody>
            </table>
            <ModalDatosFactura data={modalData} cancel={closeModal} idEntidad={idEntidad} />
        </div>
    )
}