/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getEntidadesAprobadas, getEntidadesDestacadas, publicacionState, setEntidadesDestacadas } from "../../slices/publicacionSlice";
import { appState, setAlert } from "../../slices/appSlice";
import '../../styles/publicacion.scss';
import { ALERT_FAIL, SECTIONS } from "../../app/constantes";
import EntidadesAprobadas from "./entidades-aprobadas";
import EntidadesDestacadas from "./entidades-destacadas";
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import Banner from '../../assets/images/Banner_provisional_plataforma.png';
import { catalogosServiceState, getAmbitosCatalogo } from "../../slices/catalogosServiceSlice";
import { formatForm, validateForm } from "../../app/commons";
import { getAmbitosPublicas2 } from "../../services/catalogos";

export default function Aprobacion({back}) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const catalogosSt = useSelector(catalogosServiceState);
    const publicacionSt = useSelector(publicacionState);
    const [view, setView] = useState(SECTIONS.one);
    const [catAmbitos, setCatAmbitos] = useState([])

    const getAprobadas = () => {
        dispatch(getEntidadesAprobadas({
            ...appSt.serviceData,
            idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria,
            body: {
                idsAmbitos: [],
                idsCriterio: [],
                rfc: null
            }
        }))
    }

    const changeSection = (section) => {
        dispatch(setEntidadesDestacadas(null))
        setView(section);
    }

    const searchDestacadas = (form) => {
        const format = formatForm(form);
        const valid = validateForm(form);
        if (valid.length === 0) {
            dispatch(getEntidadesDestacadas({
                ...appSt.serviceData,
                idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria,
                body: {
                    idsAmbitos: format.idAmbito ?  [format.idAmbito] : [],
                    idsCriterio: format.idCriterio ?  [format.idCriterio] : [],
                    rfc: null
                }
            }))
        } else {
            dispatch(setAlert({
                show: true,
                message: "Favor de llenar los campos obligatorios",
                type: ALERT_FAIL
            }))
        }
    }

    useEffect(() => {
        getAprobadas();
    }, [])

    useEffect(() => {
        if (!catalogosSt.ambitos) {
            dispatch(getAmbitosCatalogo(appSt.serviceData));
        }
        const obtenerCatalogo = async () => {
            const ambitosResp = await getAmbitosPublicas2({idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria });
            console.log(ambitosResp);
            setCatAmbitos(ambitosResp.payload)
        }
        obtenerCatalogo();
    }, [])

    return (<div className="aprobacion">
        <div className="wrapper">
            <div className="head">
                <button onClick={back}>
                    <BackIcon />
                </button>
                <h3>Empresas Acreditadas</h3>
            </div>
            <div className="banner">
                <img src={Banner} alt="banner" />
            </div>
            <div className="card">
                {publicacionSt.entidadesAprobadas && <>
                    <nav>
                        <button 
                            className={view === SECTIONS.one ? 'active' : ''} 
                            onClick={() => changeSection(SECTIONS.one)}
                        >Empresas Acreditadas</button>
                        <button 
                            data-tool="Si quieres conocer a las mejores empresas tenemos un apartado donde conocerás aquellas con las mejores prácticas"
                            className={view === SECTIONS.two ? 'active' : 'tool-tip'} 
                            onClick={() => changeSection(SECTIONS.two)}>
                            Practicas Destacadas</button>
                    </nav>
                    {view === SECTIONS.one && 
                        <EntidadesAprobadas registros={publicacionSt.entidadesAprobadas} />
                    }
                    {view === SECTIONS.two && 
                        <EntidadesDestacadas registros={publicacionSt.entidadesDestacadas} search={searchDestacadas} ambitos={catAmbitos}/>
                    }
                </>}
            </div>
        </div>
    </div>)
}