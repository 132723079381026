/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';

import { appState } from '../slices/appSlice';
import {
    changeListaConvocatorias,
    changeView,
    registroConvocatoriaState,
    updateEmpresaSeleccionada,
} from '../slices/registroConvocatoriaSlice';
import {
    entidadServicesState,
} from '../slices/entidadServicesSlice';

import { ReactComponent as FileIcon } from '../assets/icons/file.svg';

import RegistroConvocatoria from './registroConvocatoria';
import SeleccionConvocatoria from '../components/registro-convocatoria/seleccion-convocatoria';
import Instrumentos from './instrumentos';
import Pagos from '../components/pagos/pagos';

import {
    CONVOCATORIAS_DISPONIBLES,
    EMPRESAS_LIST,
    INSTRUMENTO,
    REGISTRO_CONVOCATORIA,
    PAGOS,
    SELECCION_PREGUNTA,
    INSTRUMENTO_PROGRESS,
    ESTATUS_REGISTRO,
    PUBLICACION_VIEW,
    RETRO_VIEW,
    INVITACION_STEP,
} from '../app/constantes';
import SeleccionPregunta from '../components/instrumento/seleccionPregunta';
import { catalogosServiceState } from '../slices/catalogosServiceSlice';
import Publicacion from '../components/publicacion/publicacion';
import Retroalimentacion from '../components/retroalimentacion/retroalimentacion';
import InvitacionesView from '../components/registro-convocatoria/invitaciones';
import { pagosState } from '../slices/pagosSlice';

export default function Convocatorias() {
    const appSt = useSelector(appState);
    const misEmpresasState = useSelector(entidadServicesState);
    const catalogosSt = useSelector(catalogosServiceState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const pagosSt = useSelector(pagosState);
    const dispatch = useDispatch();

    const entities = misEmpresasState.misEmpresas;
    const confirmedEntites =
        entities?.filter(
            (entity) =>
                entity.id_estatus_entidad === catalogosSt.idsEstatusEntidad.confirmada
        ) || [];

    const openConvocatorias = (entityObj) => {
        if (entityObj.cve_estatus_registro !== ESTATUS_REGISTRO.enVinculacion && entityObj.cve_estatus_registro !== ESTATUS_REGISTRO.rechazada) {
            const data = {
                ...appSt.serviceData,
                idEntidad: entityObj.id_entidad
            }
            dispatch(updateEmpresaSeleccionada(data));
            dispatch(changeListaConvocatorias(null))
            // dispatch(getConvocatorias(data));
            dispatch(changeView(CONVOCATORIAS_DISPONIBLES));
        }
    };
    //se cambio funcionalidad a seleccion-convocatoria para seleccionar año
    // useEffect(() => {
    //     if (registroConvocatoriaSt.activeView === CONVOCATORIAS_DISPONIBLES && registroConvocatoriaSt.empresaSeleccionada) {
    //         const data = {
    //             ...appSt.serviceData,
    //             idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad
    //         }
    //         dispatch(getConvocatorias(data));

    //     }

    // }, [registroConvocatoriaSt.activeView, registroConvocatoriaSt.empresaSeleccionada])

    return (
        <section className="registro-convocatoria">
            {registroConvocatoriaSt.activeView === EMPRESAS_LIST && (
                <div className="wrapper">
                    <div className="main-section-title">
                        <FileIcon />
                        <div className="text">
                            <h3>Convocatoria</h3>
                            <p>
                                Aqui apareceran tus empresas aprobadas,
                                selecciona la empresa con la que quieres
                                participar, y a continuacion apareceran las
                                convocatorias a las que te puedes registrar.
                            </p>
                        </div>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Razón Social</th>
                                <th>Nombre Corto</th>
                                <th>RFC</th>
                                {/* TODO: Definir si esta seccion se va a mostrar 
                                <th>
                                    Número de convocatorias en las que participa
                                </th> */}
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {confirmedEntites.map((entity, index) => (
                                entity.cve_estatus_registro !== ESTATUS_REGISTRO.enVinculacion && entity.cve_estatus_registro !== ESTATUS_REGISTRO.rechazada && <tr key={index}>
                                    <td>{entity.razon_social}</td>
                                    <td>{entity.nombre_comercial}</td>
                                    <td>{entity.rfc}</td>
                                        <td className="acciones">
                                            <button
                                                onClick={() =>
                                                    openConvocatorias(entity)
                                                }>
                                                Ver Convocatorias
                                            </button>
                                        </td>
                                    
                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {registroConvocatoriaSt.activeView ===
                CONVOCATORIAS_DISPONIBLES && <SeleccionConvocatoria />}
            {registroConvocatoriaSt.activeView === REGISTRO_CONVOCATORIA && (
                <RegistroConvocatoria />
            )}
            {registroConvocatoriaSt.activeView === SELECCION_PREGUNTA && (
                <SeleccionPregunta />
            )}
            {(registroConvocatoriaSt.activeView === INSTRUMENTO || registroConvocatoriaSt.activeView === INSTRUMENTO_PROGRESS) && (
                <Instrumentos />
            )}
            {registroConvocatoriaSt.activeView === PAGOS && pagosSt.currentConvocatoria &&(
                <Pagos convocatoria={pagosSt.currentConvocatoria} empresaSeleccionada={registroConvocatoriaSt.empresaSeleccionada}/>
            )}
            {registroConvocatoriaSt.activeView === PUBLICACION_VIEW && (
                <Publicacion empresaSeleccionada={registroConvocatoriaSt.empresaSeleccionada}/>
            )}
            {registroConvocatoriaSt.activeView === RETRO_VIEW && (
                <Retroalimentacion empresaSeleccionada={registroConvocatoriaSt.empresaSeleccionada}/>
            )}
            {registroConvocatoriaSt.activeView === INVITACION_STEP && (
                <InvitacionesView empresaSeleccionada={registroConvocatoriaSt.empresaSeleccionada}/>
            )}
            
        </section>
    );
}