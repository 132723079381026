import { useEffect, useState } from "react";

import '../../styles/template-styles/contactos.scss';
import '../../styles/template-styles/templates.scss';

import convocatoria from '../../assets/images/background-image-100.jpg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';

export default function ContactosPreview({ data }) {
    /**
     * Set Configuration state
     */
    const [moduleStyles, setModuleStyles] = useState({
        backgroundColor: '',
    });
    const [contentStyles, setContentStyles] = useState({
        '--contactoBackground': '',
        '--nombreColor': '',
        '--areaColor': '',
        '--funcionColor': '',
        '--textColor': '',
    });

  /**
   * Check config values
   */
  useEffect(() => {
    if (data) {
        const styles = {
            backgroundColor: data.backgroundColor,            
        };
        if (data.alturaFija) {
            styles.height = `${data.height}px`
        }
        console.log(styles)
        setModuleStyles(styles)
        setContentStyles({
            '--contactoBackground': data.contenido.contactoBackground,
            '--nombreColor': data.contenido.nombreColor,
            '--areaColor': data.contenido.areaColor,
            '--funcionColor': data.contenido.funcionColor,
            '--textColor': data.contenido.textColor,
        })
    }
  }, [data])

  return (
      <div className={`${data.layout}-conv`} style={moduleStyles}>
          {data.backgroundImage &&
              <img src={convocatoria} alt="fondo" />
          }
          <div className={data.wrapContent ? 'wrapper-conv' : ''}>
            <div className={`content-conv ${data.contenido.estilo}`} style={contentStyles}>
                {data.contenido.contactos && data.contenido.contactos.map((contacto, index) => {
                    return <div key={index} className="contacto">
                        <div className="img">
                            <UserIcon />
                        </div>
                        <div className="contacto-info">
                            <p className="nombre">{contacto.nombre} {contacto.apellidoPaterno} {contacto.apellidoMetrno}</p>
                            <p className="area">{contacto.area}</p>
                            <p className="funcion">{contacto.funcion}</p>
                            <p className="correo">{contacto.email}</p>
                            <p className="telefono">{contacto.telefonoExt}</p>
                        </div>
                    </div>
                })}
            </div>
          </div>
      </div>
  );
}