export const informacionGeneralForm = {
    idTipoEntidad: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    razonSocial: {
        value: '',
        required: true,
        message: 'Validar que no tenga espacio al inicio, final de la razón social o dobles espacio entre palabras',
        tool: '',
        invalid: false,
    },
    rfc: {
        value: '',
        required: true,
        message: 'RFC inválido o con espacio al final',
        tool: '',
        invalid: false,
    },
    nombrePlaca: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: 'Es la escritura que aparece en su estatuilla en caso de obtener el distintivo',
        invalid: false,
    },
    nombreComercial: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    direccion_fiscal_correspondencia: {
        value: 'si',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    isSedeMarca: {
        value: 0,
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    nombrePlacaQuinquenio: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    }
}