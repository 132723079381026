import {
    deleteRequest,
    getRequest,
    getRequestPublic,
    postRequest,
    putRequest,
} from './http-service';

const url = process.env.REACT_APP_CONVOCATORIAS_SERVICE;

export const getConvocatoriasByIdEntidad = async (data) => {
    let requestUrl = `${url}/area/${data.area}/entidad/${data.idEntidad}`;

    if (data.query) {
        const searchParams = new URLSearchParams(data.query);
        requestUrl = `${requestUrl}?${searchParams}`
    }

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area: data.area, idEntidad: data.idEntidad },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getConvocatoriasPublicadas = async (data) => {
    const requestUrl = `${url}/area/${data.area}/estatus-registro/1/estatus-convocatoria/PUBLICADA`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area: data.area, idEntidad: data.idEntidad },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getConvocatoriaByIdConvocatoria = async (data) => {
    const requestUrl = `${url}/servicio_adicional/convocatoria/${data.idConvocatoria}/programa/${data.idPrograma}`;

    const resp = await getRequest(requestUrl, data.token);

    return resp.payload;
};

//https://convocatoriasvc-dev.cemefi.org/convocatoria/api/v1/servicio_extemporaneos/convocatoria/1082/programa/1
export const getServiciosExtemporaneos = async (data) => {
    const requestUrl = `${url}/servicio_extemporaneos/convocatoria/${data.idConvocatoria}/programa/${data.idPrograma}`;

    const resp = await getRequest(requestUrl, data.token);

    return resp.payload;
};

export const postRegistroConvocatoria = async (data, body) => {
    const requestUrl = `${url}/registro`;

    const resp = await postRequest(requestUrl, data.token, { payload: body });

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area: data.area, idEntidad: data.idEntidad, body },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putRegistroConvocatoria = async (data, body) => {
    const requestUrl = `${url}/registro/registro-convocatoria/${data.idRegistroConvocatoria}/area/${data.area}`;

    const resp = await putRequest(requestUrl, data.token, { payload: body });

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area: data.area, idEntidad: data.idEntidad, body },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteRegistroConvocatoria = async (data) => {
    const requestUrl = `${url}/registro/reigistro-convocatoria/${data.idRegistroConvocatoria}/area/${data.area}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postCobroRegistroConvocatoria = async (data, body) => {
    const requestUrl = `${url}/cobro`;

    const resp = await postRequest(requestUrl, data.token, { payload: body });

    if (resp.payload.length === 0 || (resp.error && resp.error.length > 0)) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area: data.area, idEntidad: data.idEntidad, body },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postCobroAplicadoConvocatoria = async (data, body) => {
    const requestUrl = `${url}/cobro-aplicado`;

    const resp = await postRequest(requestUrl, data.token, { payload: body });

    if (resp.payload.length === 0 || (resp.error && resp.error.length > 0)) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area: data.area, idEntidad: data.idEntidad, body },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postServicioExtemporaneo = async (data, body) => {
    const requestUrl = `${url}/cobro-aplicado-extemporaneo`;

    const resp = await postRequest(requestUrl, data.token, { payload: body });

    if (resp.payload.length === 0 || (resp.error && resp.error.length > 0)) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area: data.area, idEntidad: data.idEntidad, body },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putCobroRegistroConvocatoria = async (data, body) => {
    const requestUrl = `${url}/cobro/cobro-registro-convocatoria/${body.idCobroRegistroConvocatoria}/area/${data.area}`;

    const resp = await putRequest(requestUrl, data.token, { payload: body });

    if (resp.payload.length === 0 || (resp.error && resp.error.length > 0)) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area: data.area, idEntidad: data.idEntidad, body },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getCobroRegistroConvocatoria = async (data) => {
    const requestUrl = `${url}/cobro-registro-convocatoria/area/${data.area}/registro-convocatoria/${data.idRegistroConvocatoria}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getCobroData = async (data) => {
    const requestUrl = `${url}/cobro/cobro-registro-convocatoria/${data.idCobroRegistroConvocatoria}/area/${data.area}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

/** getEntidadRegistroConvocatoriaById
 * Retorna un array con las entidades relacionadas a la convocatoria
 * @param {Object} data
 */
export const getEntidadRegistroConvocatoriaById = async (data) => {
    const requestUrl = `${url}/area/${data.area}/convocatoria/entidad-registro-convocatoria/${data.idRegistroConvocatoria}`;

    const resp = await getRequest(requestUrl, data.token);

    return resp.payload;
};

/**
 *
 * @param {*} data
 * @param {*} query
 * @param {string} query.rfc
 * @returns
 */

export const getConvocatoriaByQuery = async (data, query) => {
    const requestUrl = `${url}/area/${data.area}/convocatoria?`;
    const searchParams = new URLSearchParams(query);

    const resp = await getRequest(requestUrl + searchParams, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            payload: resp.descripcionError,
        };
    }

    return resp.payload;
};


export const postDocumentoConvocatoria = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/registro-convocatoria-documento`;

    const resp = await postRequest(requestUrl, data.token, { payload: body });

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area: data.area, idEntidad: data.idEntidad, body },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putDocumentoConvocatoria = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/registro-convocatoria-documento/${data.idRegistroConvocatoriaDocumento}`;

    const resp = await putRequest(requestUrl, data.token, { payload: body });

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area: data.area, idEntidad: data.idEntidad, body },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getDocumentosConvocatoria = async (data) => {
    const requestUrl = `${url}/area/${data.area}/registro-convocatoria-documento/registro/${data.idRegistroConvocatoria}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area: data.area, idEntidad: data.idEntidad },
            payload: resp.payload,
        };
    }

    return resp.payload;
};


export const deleteDocumentosConvocatoria = async (data) => {
    const requestUrl = `${url}/area/${data.area}/registro-convocatoria-documento/${data.idRegistroConvocatoriaDocumento}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area: data.area, idEntidad: data.idEntidad },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getAniosParticipacionConvocatoria = async (data) => {
    const requestUrl = `${url}/area/${data.area}/convocatoria/${data.idConvocatoria}/anios-participacion`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getAniosProgramaConvocatoria = async (data) => {
    const requestUrl = `${url}/area/${data.area}/convocatoria/${data.idConvocatoria}/anios-participacion-programa-entidad`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};


export const getRegistrosConvocatoriaHijos = async (data) => {
    const requestUrl = `${url}/area/${data.area}/registro-convocatoria-documento/registro-hijos/entidad/${data.idEntidad}/convocatoria/${data.idConvocatoria}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getConvocatoriasPublicas = async (data) => {
    const requestUrl = `${url}/area/${data.area}/convocatoria-publica/anio/${data.anio}`;
    return await getRequestPublic(requestUrl, data.token);
}

//https://convocatoriasvc-dev.cemefi.org/convocatoria/api/v1/area/:idAreaCemefi/programa-convocatoria/:idRegistroConvocatoria
export const getProgramaConvocatoria = async (data) => {
    const requestUrl = `${url}/area/${data.area}/programa-convocatoria/${data.idRegistroConvocatoria}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putCobroFactura = async (data) => {
    const requestUrl = `${url}/cobro/cobro-registro-convocatoria/${data.idCobroRegistroConvocatoria}/area/${data.area}`;

    const resp = await putRequest(requestUrl, data.token, {payload: data.body});
    if (resp.error && resp.error.length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.error[0].descripcionError,
        };
    }

    return resp;
};

export const putReferenciaPasarela = async (data) => {
    const requestUrl = `${url}/cobro/cobro-registro-convocatoria/${data.idCobroRegistroConvocatoria}/area/${data.area}/pasarela`;

    const resp = await putRequest(requestUrl, data.token, {payload: data.body});
    if (resp.error && resp.error.length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.error[0].descripcionError,
        };
    }

    return resp;
};

export const getEsquemaCobroConvocatoria = async (data) => {
    const requestUrl = `${url}/area/${data.area}/esquema-cobro/convocatoria/${data.idConvocatoria}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.error) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getAniosConvocatoria = async (data) => {
    const requestUrl = `${url}/area/${data.area}/anios-convocatoria`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.error) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getEntidadesInvitadas = async (data) => {
    const requestUrl = `${url}/area/${data.area}/convocatoria/entidad/registro-convocatoria/${data.idRegistroConvocatoria}/invitadas`;

    const resp = await postRequest(requestUrl, data.token, { payload: data.body });

    if (resp.error) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getEntidadToInvite = async (data) => {
    const requestUrl = `${url}/area/${data.area}/convocatoria?rfc=${data.rfc}&rfcInvitante=${data.rfcInvitante}&membresia=0&idPrograma=${data.idPrograma}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.claveError) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: resp.descripcionError,
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteDocumentoRelacion = async (data) => {
    const requestUrl = `${url}/area/${data.area}/documento-relacion-programa/${data.idDocumentoRelacionPrograma}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};