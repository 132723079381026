/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import GeneralTable from "../generales/generalTable";

export default function EntidadesAprobadas({registros}) {
    const [tableFormat, setTableFormat] = useState(null)

    // const search = () => {
    //     dispatch(getEntidadesAprobadas({
    //         ...appSt.serviceData,
    //         //idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria,
    //         idConvocatoria: 2,
    //         body: {
    //             idsAmbitos: [],
    //             idsCriterio: [],
    //             rfc: form.rfc.value ? form.rfc.value : null
    //         }
    //     }))
    // }

    useEffect(() => {
        setTableFormat([
            {
                label: 'Años ESR',
                key: 'anios_participacion',
                orden: 2
            },
            {
                label: 'Razon Social',
                key: 'razon_social',
                orden: 2
            },
            {
                label: 'Nombre de Empresa',
                key: 'nombre_comercial',
                orden: 2
            },
            {
                label: 'Estado',
                key: 'estado',
                orden: 4
            },
            {
                label: 'Industria',
                key: 'desc_industria',
                orden: 5
            },
            {
                label: 'Tamaño',
                key: 'desc_tamanio',
                orden: 6
            },
        ])
    }, [])

    return (<section>
        <div className="tip">
            <InfoIcon />
            <div>
                <p>Para su comodidad podrá consultar la razón social con el siguiente buscador</p>
            </div>
        </div>
        {/* <div className="form-section">
            <div className="four-col">
                <InputComp label="RFC" name="rfc" type="text" form={form} setForm={setForm} />
                <button onClick={search}>Buscar</button>
            </div>
        </div> */}

        <GeneralTable registros={registros} headers={tableFormat}  showMaxItems={true} showBuscador={true}/>
    </section>)
}