import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { changeStep, registroState, updateEmpresaSeleccionada } from "../../slices/registroSlice";

import InputComp from "../generales/inputComponent";
import { datosIndustriaForm, datosIndustriaMessages } from "../../app/forms/registro/datos-industria";

import { ALERT_FAIL, ALERT_SUCCESS, ALERT_WARN, DATOS_CONTACTO_STEP, REDES_STEP } from "../../app/constantes";
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { useEffect } from "react";
import { catalogosServiceState, getIndustriaCatalogo, getSectorCatalogo, getSectorSeCatalogo, getSubsectorCatalogo, getTable, getTamanioSectorSeCatalogo } from "../../slices/catalogosServiceSlice";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { deleteProductoEntidad, deleteServicioEntidad, postProductoEntidad, postServicioEntidad, putEntidad } from "../../services/entidad";
import { fillForm, formatForm, formatValor, isValueNullOrEmpty, validateForm } from "../../app/commons";
import { getCatalogos, getMessageByModulo, getTips } from "../../services/catalogos";
import ConfirmModal from "../generales/confirmModal";

export default function DatosIndustria() {
    // Redux Estado
    const dispatch = useDispatch();
    const catalogosSt = useSelector(catalogosServiceState);
    const registroSt = useSelector(registroState);
    const appSt = useSelector(appState);

    // Estado local
    const [form, setForm] = useState(datosIndustriaForm);
    const [productoValue, setProductoValue] = useState('');
    const [servicioValue, setServicioValue] = useState('');
    const [tamanios, setTamanios] = useState(null);

    const [messages, setMessages] = useState(datosIndustriaMessages);
    // Local States
    const [modalData, setModalData] = useState({
        show: false,
        title: '',
        desc: '',
        info: null,
        list: null
    });

    // Cierra Modal de confirmacion
    const closeModal = () => {
        setModalData({
            ...modalData,
            show: false
        })
    }

    const prevStep = () => {
        dispatch(changeStep(DATOS_CONTACTO_STEP));
    }

    const nextStep = async () => {
        const valid = validateForm(form);
        if (valid.length === 0) {
            const value = formatForm(form);
            const body = JSON.parse(JSON.stringify(registroSt.empresaSeleccionada));
            if (body.idSubsectorInegi2) {
                body.idSubsectorInegi2.idSector2.idIndustria = value.idIndustria;
                body.idSubsectorInegi2.idSector = value.idSector;
            } else {
                body.idSubsectorInegi2 = {
                    idSector2: {idIndustria: value.idIndustria},
                    idSector: value.idSector
                }
            }
            body.idSectorSe = value.idSectorSe;
            body.idSubsectorInegi = value.idSubsectorInegi;
            body.numeroEmpleados = value.numeroEmpleados;
            dispatch(setLoading(true));
            const data = {
                ...appSt.serviceData,
                idEntidad: registroSt.empresaSeleccionada.idEntidad
            }
            const resp = await putEntidad(data, body);
            if (resp.error) {
                const empty = resp.error.find(item => item.claveError === 'EMPTY_REQUEST'); 
                if (empty) {
                    dispatch(setLoading(false));
                    dispatch(setAlert({
                        show: true,
                        message: empty.descripcionError,
                        type: ALERT_WARN
                    }));
                }
            } else {
                if (resp.payload) {
                    const updateData = {
                        ...appSt.serviceData,
                        idEntidad: registroSt.empresaSeleccionada.idEntidad
                    }
                    dispatch(updateEmpresaSeleccionada(updateData))
                    dispatch(setLoading(false));
                    dispatch(setAlert({
                        show: true,
                        message: 'Datos Guardados',
                        type: ALERT_SUCCESS
                    }));
                    dispatch(changeStep(REDES_STEP));
                } else {
                    dispatch(setLoading(false));
                    dispatch(setAlert({
                        show: true,
                        message: 'Error al guardar Datos',
                        type: ALERT_FAIL
                    }))
                }
            }

        } else {
            dispatch(setAlert({
                show: true,
                message: 'Favor de llenar todos los campos de Entidad y Tamaño.',
                type: ALERT_WARN
            }));
        }
    }

    const handleResponse = (resp, action, type) => {
        if (resp.payload) {
            const updateData = {
                ...appSt.serviceData,
                idEntidad: registroSt.empresaSeleccionada.idEntidad
            }
            dispatch(updateEmpresaSeleccionada(updateData));
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: `Exito al ${action} ${type}`,
                type: ALERT_SUCCESS
            }))
        } else {
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: `Error en el servicio`,
                type: ALERT_FAIL
            }))
        }
    }

    const addItem = async (type) => {
        dispatch(setLoading(true));

        let body = {
            idEntidad: registroSt.empresaSeleccionada.idEntidad,
        }

        let resp;

        if (type === 'producto') {
            body.descProductoEntidad = productoValue;
            document.getElementById('productoAdd').value = '';
            setProductoValue('');
            resp = await postProductoEntidad(appSt.serviceData, body);
        } else {
            body.descServicioEntidad = servicioValue;
            setServicioValue('')
            document.getElementById('servicioAdd').value = '';
            resp = await postServicioEntidad(appSt.serviceData, body);
        }
        handleResponse(resp, 'Agregar', type);
    }

    // Confirmar Eliminacion
    const confirmDelete = (type, id) => {
        setModalData({
            show: true,
            title: `Eliminar ${type}`,
            desc: `¿Seguro deseas eliminar este ${type}?`,
            info: { type, id },
            action: () => deleteItem(type, id)
        })
    }

    const deleteItem = async (type, id) => {
        dispatch(setLoading(true));

        let resp;
        let body = {
            ...appSt.serviceData,
            idItem: id
        }

        if (type === 'producto') {
            resp = await deleteProductoEntidad(body);
        } else {
            resp = await deleteServicioEntidad(body);
        }

        handleResponse(resp, 'Eliminar', type);
        closeModal();
    }

    /**
     * Agrega los valores del servicio a los inputs
     */
    const populateInputs = async () => {
        let f = fillForm(form, registroSt.empresaSeleccionada);
        if (registroSt.empresaSeleccionada.idSubsectorInegi2) {
            const subResp = await getCatalogos(appSt.serviceData.area, 'subsector', appSt.serviceData.token, registroSt.empresaSeleccionada.idSubsectorInegi2.idSector);
            const sectResp = await getCatalogos(appSt.serviceData.area, 'sector', appSt.serviceData.token);
            if (subResp && subResp.length > 0) {
                const body = {
                    ...appSt.serviceData,
                    idSector: registroSt.empresaSeleccionada.idSubsectorInegi2.idSector,
                    idEntidad: registroSt.empresaSeleccionada.idEntidad
                }
                dispatch(getSubsectorCatalogo(body));
                f.idSector.value = registroSt.empresaSeleccionada.idSubsectorInegi2.idSector;
            }
            if (sectResp && sectResp.length > 0) {
                const filt = sectResp.filter(sec => sec.idSector === registroSt.empresaSeleccionada.idSubsectorInegi2.idSector);
                if (filt.length > 0) {
                    f.idIndustria.value = filt[0].idIndustria;
                }
            }
        }
        setForm(f);
    }

    const setUpper = (type, e) => {
        const value = e.target.value.toUpperCase();
        e.target.value = value;

        if (type === 'producto') {
            setProductoValue(value);
        } else {
            setServicioValue(value)
        }

    }

    const getToolTips = async () => {
        const respServ = await getTips({
            ...appSt.serviceData,
            claveCatalogo: 'parametro',
            clave: 'MENSAJEAGREGARSERVICIO'
        })
        const respProd = await getTips({
            ...appSt.serviceData,
            claveCatalogo: 'parametro',
            clave: 'MENSAJEAGREGARPRODUCTO'
        })
        const tamanio = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'REGISTRO', 'MENSAJENUMEROEMPLEADO');
        const topMessage = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'REGISTRO', 'MENSAJEINSTRUMENTO');

        const obj = {
            servicios: {tool: respServ.payload ? respServ.payload.valor : ''},
            productos: {tool: respProd.payload ? respProd.payload.valor : ''},
            tamanio: formatValor(tamanio),
            topMessage: formatValor(topMessage)
        }

        setMessages({
            ...messages,
            ...obj
        })
        
    }

    const validateNumber = (e) => {
        if (e.target.value === '1') {
            dispatch(setAlert({
                show: true,
                message: 'El número de empleado no se encuentra dentó de un rango',
                type: ALERT_WARN
            }))
        }

        if (e.target.value.includes('-')) {
            e.target.value = '';
            const clone = JSON.parse(JSON.stringify(form));
            clone.numeroEmpleados.value = '';
            setForm(clone)
            dispatch(setAlert({
                show: true,
                message: 'El número de empleado no se puede ser negativo',
                type: ALERT_WARN
            }))
        }
    }

    const formatTableHeaders = () => {
        const tamanios = [];
        for (const tamanio of catalogosSt.tableData[0].tamanioInfoVo) {
            tamanios.push(tamanio);
        }
        console.log(tamanios);
        setTamanios(tamanios)
    }

    useEffect(() => {
        if (catalogosSt.industrias === null && !catalogosSt.loading && appSt.serviceData) {
            dispatch(getIndustriaCatalogo({...appSt.serviceData, idEntidad: registroSt.empresaSeleccionada.idEntidad}));
        }
        if (catalogosSt.tamanioSectorSe === null && !catalogosSt.loading && appSt.serviceData) {
            dispatch(getTamanioSectorSeCatalogo({...appSt.serviceData, idEntidad: registroSt.empresaSeleccionada.idEntidad}));
        }
        if (catalogosSt.sectorSe === null && !catalogosSt.loading && appSt.serviceData) {
            dispatch(getSectorSeCatalogo({...appSt.serviceData, idEntidad: registroSt.empresaSeleccionada.idEntidad}));
        }
        if (catalogosSt.tableData === null && !catalogosSt.loading && appSt.serviceData) {
            dispatch(getTable(appSt.serviceData));
        }
        getToolTips();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (catalogosSt.tableData) {
            formatTableHeaders();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogosSt.tableData])

    useEffect(() => {
        if (isValueNullOrEmpty(form.idIndustria.value)) {
            if (!catalogosSt.loading && appSt.serviceData) {
                const body = {
                    ...appSt.serviceData,
                    idIndustria: form.idIndustria.value,
                    idEntidad: registroSt.empresaSeleccionada.idEntidad
                }
                dispatch(getSectorCatalogo(body));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.idIndustria.value])

    useEffect(() => {
        if (isValueNullOrEmpty(form.idSector.value)) {
            if (!catalogosSt.loading && appSt.serviceData) {
                const body = {
                    ...appSt.serviceData,
                    idSector: form.idSector.value,
                    idEntidad: registroSt.empresaSeleccionada.idEntidad
                }
                dispatch(getSubsectorCatalogo(body));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.idSector.value])

    useEffect(() => {
        if (registroSt.empresaSeleccionada && appSt.serviceData) {
            populateInputs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registroSt.empresaSeleccionada, appSt.serviceData])

    useEffect(() => {
        if (catalogosSt.parametros !== null && catalogosSt.parametros !== undefined) {
            console.log(catalogosSt.parametros)
            catalogosSt.parametros.forEach(item => {
                if (item.nombreParametro === 'MENSAJE_ACTIVIDADES_EMPRESA') {
                    messages.actividadesEmpresa.tool = item.valor;
                }
                if (item.nombreParametro === 'MENSAJE_SERVICIOS_PRODUCTOS') {
                    messages.serviciosProductos.tool = item.valor;
                }
                if (item.nombreParametro === 'TOOLTIP_NUMERO_EMPLEADOS') {
                    form.numeroEmpleados.tool = item.valor;
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogosSt.parametros])

    useEffect(() => {
        console.log(messages)
    }, [messages])

    return (
        <div className="registro-step datos-industria">
            <div className="tip">
                <InfoIcon />
                <div>
                    <p>Favor de llenar todos los campos que contengan: <span>*</span></p>
                    <p>Para fines estadísticos selecciona la industria, sector, subsector, a la cual pertenece la organización participante.</p>
                </div>
            </div>

            <div className="card-registro">
                <div className="head">
                    <h3>Organización</h3>
                </div>
                <div className="content">
                    {messages.topMessage &&
                        <div className="tip">
                            <InfoIcon />
                            {messages.topMessage.map((item, index) => {
                                return <p key={index}>{item}</p>
                            })}
                        </div>
                    
                    }
                    {catalogosSt.industrias &&
                        <InputComp disabled={registroSt.isSede} label="Industria" name="idIndustria" type="select" form={form} setForm={setForm} catalogos={catalogosSt.industrias} catalogoValue={'idIndustria'} catalogoLabel={'descIndustria'} />
                    }
                    {catalogosSt.sectores &&
                        <InputComp disabled={form.idIndustria.value === '' || registroSt.isSede} label="Sector" name="idSector" type="select" form={form} setForm={setForm} catalogos={catalogosSt.sectores} catalogoValue={'idSector'} catalogoLabel={'descSector'} />
                    }
                    {catalogosSt.subsectores &&
                        <InputComp disabled={form.idSector.value === '' || registroSt.isSede} label="Subsector" name="idSubsectorInegi" type="select" form={form} setForm={setForm} catalogos={catalogosSt.subsectores} catalogoValue={'idSubsectorInegi'} catalogoLabel={'descSubsectorInegi'} />
                    }

                    <div className="tip">
                        <InfoIcon />
                        <p>{messages.serviciosProductos.tool}</p>
                    </div>

                    <div className="two-col">
                        <div className="add-list">
                            <div className="head">
                                <label>Productos</label>
                            </div>
                            <div className="add-list-item">
                                <span className="tool-tip" data-tool={messages.productos.tool}>?</span>
                                <input disabled={registroSt.isSede} id="productoAdd" type="text" placeholder="Agregar Producto" onChange={(e) => setUpper('producto', e)} />
                                <button disabled={productoValue === ''} onClick={(e) => addItem('producto')}>+</button>
                            </div>
                            <div className="items-list">
                                {registroSt.empresaSeleccionada && registroSt.empresaSeleccionada.cemProductoEntidads &&
                                    <ul>
                                        {registroSt.empresaSeleccionada.cemProductoEntidads.map((producto, index) => {
                                            return producto.idEstatusRegistro !== 0 && <li key={index}>
                                                <p>{producto.descProductoEntidad}</p>
                                                {!registroSt.isSede && 
                                                    <button onClick={() => confirmDelete('producto', producto.idProductoEntidad)}>
                                                        <DeleteIcon />
                                                    </button>
                                                }
                                            </li>
                                        })}
                                    </ul>
                                }
                            </div>
                        </div>

                        <div className="add-list">
                            <div className="head">
                                <label>Servicios</label>
                            </div>
                            <div className="add-list-item">
                                <span className="tool-tip" data-tool={messages.servicios.tool}>?</span>
                                <input disabled={registroSt.isSede}id="servicioAdd" type="text" placeholder="Agregar Servicio" onChange={(e) => setUpper('servicio', e)} />
                                <button disabled={servicioValue === ''} onClick={(e) => addItem('servicio')}>+</button>
                            </div>
                            <div className="items-list">
                                {registroSt.empresaSeleccionada && registroSt.empresaSeleccionada.cemServicioEntidads &&
                                    <ul>
                                        {registroSt.empresaSeleccionada.cemServicioEntidads.map((servicio, index) => {
                                            return servicio.idEstatusRegistro !== 0 && <li key={index}>
                                                <p>{servicio.descServicioEntidad}</p>
                                                {!registroSt.isSede && 
                                                    <button onClick={() => confirmDelete('servicio', servicio.idServicioEntidad)}>
                                                        <DeleteIcon />
                                                    </button>                                                
                                                }
                                            </li>
                                        })}
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-registro">
                <div className="head">
                    <h3>Tamaño</h3>
                </div>
                {messages.tamanio && 
                    <div className="tip">
                        <InfoIcon />
                        <div>
                            {messages.tamanio.map((item, index) => {
                                return <p key={index}>{item}</p>
                            })}
                        </div>
                    </div>                
                }
                <div className="content">
                    {catalogosSt.sectorSe &&
                        <InputComp disabled={registroSt.isSede} label="Sector de secretaria de economía" name="idSectorSe" type="select" form={form} setForm={setForm} catalogos={catalogosSt.sectorSe} catalogoValue={'idSectorSe'} catalogoLabel={'descSectorSe'} />
                    }
                    {catalogosSt.tamanioSectorSe &&
                        <InputComp  label="Número de empleados" name="numeroEmpleados" type="number" form={form} setForm={setForm} onBlurAction={validateNumber}/>
                    }
                    {catalogosSt.tableData &&
                        <table className="tabla-sector">
                            <thead>
                                <tr>
                                    <td>Sector</td>
                                    {tamanios && tamanios.map((tamanio, index) => {
                                        return <th key={index}>{tamanio.descTamanio}</th>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {catalogosSt.tableData.map((item, index) => {
                                    return item.tamanioInfoVo && <tr key={index}>
                                        <th>{item.descSectorSe}</th>
                                        {item.tamanioInfoVo[0] && 
                                            <td>{item.tamanioInfoVo[0].rangoNumEmpleados}</td>
                                        }
                                        {item.tamanioInfoVo[1] && 
                                            <td>{item.tamanioInfoVo[1].rangoNumEmpleados}</td>                                        
                                        }
                                        {item.tamanioInfoVo[2] && 
                                            <td>{item.tamanioInfoVo[2].rangoNumEmpleados}</td>                                        
                                        }
                                        {item.tamanioInfoVo[3] && 
                                            <td>{item.tamanioInfoVo[3].rangoNumEmpleados}</td>
                                        }
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    }

                </div>
            </div>

            <div className="actions">
                <button onClick={prevStep}>Regresar</button>
                <button onClick={nextStep}>Guardar</button>
            </div>
            <ConfirmModal data={modalData} cancel={closeModal} />
        </div>
    )
}