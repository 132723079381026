export const pagosIncialForm = {
  metodoPago: {
      value: '',
      required: false,
      message: 'Dato requerido',
      tool: '',
      invalid: false,
  },
  facturaAnticipada: {
    value: '',
    required: false,
    message: 'Dato requerido',
    tool: '',
    invalid: false,
},
}

export const catalogoFactura = [
  {
      label: 'Si',
      value: true
  }, 
  {
      label: 'No',
      value: false
  }
]

export const catalogoPago = [
  {
      label: 'Pago en linea',
      value: 'pagoLinea'
  }, 
  {
      label: 'Deposito bancario',
      value: 'depositoBancario'
  }
]