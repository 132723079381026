import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SECTIONS } from '../app/constantes';
import { getCobroCursosComprobantes, getCursosCobros } from '../services/cursos';

const initialState = {
    loading: false,
    currentConvocatoria: false,
    cobrosAplicados: null,
    pagoDetails: null,
    viewCobroCurso: SECTIONS.one,
    esquema: null,
    modalFactura: {
        show: false,
        data: null
    },
    modalRevertir: {
        show: false,
        data: null
    },
    modalServiciosAdicionales: {
        show: false,
        data: null
    }
}

export const getCobrosCursos = createAsyncThunk('pagosCursos/getCobrosCursos', async (data, {rejectWithValue}) => {
  let resp;
  try {
      resp = await getCobroCursosComprobantes(data); 
  } catch (error) {
      console.log(error.response); 
      return rejectWithValue({success: false})
  }
  return resp;
})

export const getPagosCursosDetails = createAsyncThunk('pagosCursos/getPagosCursosDetails', async (data, {rejectWithValue}) => {
  let resp;
  try {
      resp = await getCursosCobros(data); 
  } catch (error) {
      console.log(error.response); 
      return rejectWithValue({success: false})
  }
  return resp;
})

export const pagosCursosSlice = createSlice({
    name: 'pagosCursos',
    initialState,    
    reducers: {
        setCurrentConvocatoriaCurso: (state, action) => {
            state.currentConvocatoria = action.payload;
        },
        setModalFactura: (state, action) => {
            state.modalFactura = action.payload;
        },
        setModalRevertir: (state, action) => {
            state.modalRevertir = action.payload;
        },
        setModalServiciosAdicionales: (state, action) => {
            state.modalServiciosAdicionales = action.payload;
        },
        setInitial: (state, action) => {
            state.initial = action.payload;
        },
        changeSectionCobroCurso: (state, action) => {
            state.viewCobroCurso = action.payload;
        },
        changeEsquemaCurso: (state, action) => {
            state.esquema = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase((
            getPagosCursosDetails.pending 
        ), (state) => {
            state.loading = true
        })

        builder.addCase((
            getCobrosCursos.pending 
        ), (state) => {
            state.loading = true
        })

        builder.addCase((
            getPagosCursosDetails.rejected 
        ), (state) => {
            state.loading = false
        })

        builder.addCase((
            getCobrosCursos.rejected 
        ), (state) => {
            state.loading = true
        })

        builder.addCase(getCobrosCursos.fulfilled, (state, action) => {
            state.loading = false;
            state.cobrosAplicados = action.payload;
        })

        builder.addCase(getPagosCursosDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.pagoDetails = action.payload;
        })
    },
})

export const { 
    setCurrentConvocatoriaCurso,
    setModalFactura,
    setModalServiciosAdicionales,
    setInitial,
    changeSectionCobroCurso,
    setModalRevertir,
    changeEsquemaCurso
 } = pagosCursosSlice.actions;
export const pagosCursosState = (state) => state.pagosCursos;

export default pagosCursosSlice.reducer;