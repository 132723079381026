/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux"
import { catalogosServiceState } from "../../slices/catalogosServiceSlice"
import DocumentComp from "../registro-consultor/documento-comp"
import { useEffect, useState } from "react"
import { appState, setAlert, setLoading } from "../../slices/appSlice"
import { getBase64, getContentName } from "../../app/commons"
import { getDocument, uploadRelacionDoc } from "../../services/entidad"
import { ALERT_FAIL, ALERT_SUCCESS, PROGRAMAS } from "../../app/constantes"
import { deleteDocumentoRelacion } from "../../services/convocatorias"

export default function ModalCartaProvedura({ modalData, setModalData, search, type }) {
    const dispatch = useDispatch()
    const appSt = useSelector(appState)
    const catalogosSt = useSelector(catalogosServiceState)
    const [docs, setDocs] = useState(null)
    const [documentosCargados, setDocumentosCargados] = useState(null)
    const [canEdit, setCanEdit] = useState(true)

    const closeModal = () => {
        setModalData({
            show: false,
            data: null
        })
        search()
    }

    const deleteDoc = async (registro) => {
        dispatch(setLoading(true))
        try {
            await deleteDocumentoRelacion({
                ...appSt.serviceData,
                idDocumentoRelacionPrograma: registro.idDocumentoRelacionPrograma
            })
            dispatch(setLoading(false))
            dispatch(setAlert({
                show: true,
                message: "Documento eliminado",
                type: ALERT_SUCCESS
            }))
            closeModal()
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))    
            dispatch(setAlert({
                show: true,
                message: "Error al eliminar documento",
                type: ALERT_FAIL
            }))        
        }
    }

    const downloadDoc = async () => {
        dispatch(setLoading(true))
        try {
            const docResp = await getDocument({
                ...appSt.serviceData,
                idEntidad: modalData.data.idEntidad,
                idContent: modalData.data.documentoRelacionPrograma.idContent
            });
            
            if (docResp.payload) {
                const type = modalData.data.documentoRelacionPrograma.idContent.split('.').pop();
                const dataType = type === 'pdf' ? 'data:application/pdf' : `data:image/${type}`;
                // const type = 'data:image/png'
                const file64 = docResp.payload.base64;
                const linkSource = `${dataType};base64,${file64}`;
                const downloadLink = document.createElement('a');
                //falta poner el nombre correcto
                const fileName = getContentName(modalData.data.documentoRelacionPrograma.idContent);
    
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click()
            }
            dispatch(setLoading(false))
            
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))
            dispatch(setAlert({
                show: true,
                message: "Error al obtener documento",
                type: ALERT_FAIL
            }))    
        }
    }

    const handleSubmit = async (e) => {
        dispatch(setLoading(true))
        try {
            const file = e.target.files[0];
            if (file) {
                const base64 = await getBase64(file);
                const body = {
                    cveDocumentoCemefi: type === PROGRAMAS.cv ? "CARTAPROVEDURIA" : "CARTAINVITACION",
                    archivo: {
                        nombreArchivo: file.name.replace('#', ''),
                        base64: base64
                    }
                }
                await uploadRelacionDoc({
                    ...appSt.serviceData,
                    idRelacionPrograma: modalData.data.idRelacionPrograma
                }, body)
            }
            dispatch(setAlert({
                show: true,
                message: "Documento Guardado",
                type: ALERT_SUCCESS
            }))
            closeModal()
            dispatch(setLoading(false))            
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))     
            dispatch(setAlert({
                show: true,
                message: "Error al guardar documento",
                type: ALERT_FAIL
            }))       
            
        }
        e.target.value = '';
    }

    useEffect(() => {
        if (modalData && catalogosSt.documentosRelacion && type === PROGRAMAS.cv) {
            setDocs([catalogosSt.documentosRelacion.payload])
        }
        if (modalData && catalogosSt.documentosPromotora && type === PROGRAMAS.ep) {
            setDocs([catalogosSt.documentosPromotora.payload])
        }
        if (modalData.data && modalData.data.documentoRelacionPrograma) {
            setDocumentosCargados([modalData.data.documentoRelacionPrograma])
        } else {
            setDocumentosCargados([])
        }
        if (modalData.data && modalData.data.descEstatusInscripcion !== "ACEPTADA" && modalData.data.descEstatusInscripcion !== "INSCRITA") {
            setCanEdit(true)
        } else {
            setCanEdit(false)
        }
    }, [modalData, catalogosSt.documentosRelacion, type])
    

    return(<div className={modalData.show ? "modal-wrapper active" : "modal-wrapper"}>
        <div className="modal">
            <div className="head">
                <h3>Documentos</h3>
                <button onClick={closeModal}>
                        x
                </button>
            </div>

            <div className="content">
            {modalData && documentosCargados && docs && docs.map((item, key) => {
                    return  <DocumentComp 
                        documentosCargados={documentosCargados}
                        descDoc={"descDocumentoCemefi"}
                        doc={item}
                        key={key}
                        onlyView={!canEdit}
                        idDoc={"idDocumentoCemefi"}
                        deleteDoc={deleteDoc}
                        download={downloadDoc}
                        upload={handleSubmit}
                    />
                })
                 }
            </div>
        </div>
    </div>)
}