/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux"
import { getConsultorData, registroConsultorState, setActiveSection, setValidations } from "../slices/registroConsultor"
import { CVE_TIPODOC_CONSULTOR, SECTIONS } from "../app/constantes"
import '../styles/registro-consultor.scss';
import DatosBasicosConsultor from "../components/registro-consultor/datos-basicos";
import { useEffect } from "react";
import { catalogosServiceState, getCriteriosCatalogo, getDocumentosConsultorAcreditado, getEstatusConsultorAcredito, getTamanioSectorSeCatalogo } from "../slices/catalogosServiceSlice";
import { appState } from "../slices/appSlice";
import { usuariosServicesState } from "../slices/usuariosServicesSlice"
import AreasExperienciaConsultor from "../components/registro-consultor/areas-experiencia";
import DocumentosConsultor from "../components/registro-consultor/documentos-consultor";
import { ReactComponent as CompletedIcon } from '../assets/icons/completed.svg';
import { ReactComponent as ProgressIcon } from '../assets/icons/in-progress.svg';
import { DatosBasicosForm } from "../app/forms/registro/datos-basicos";
import { fillForm, validateForm } from "../app/commons";
import { useState } from "react";
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg';

export default function RegistroConsultor() {
    const dispatch = useDispatch()
    const registroConsultorSt = useSelector(registroConsultorState)
    const appSt = useSelector(appState)
    const userSt = useSelector(usuariosServicesState)
    const catalogosSt = useSelector(catalogosServiceState)
    const [onlyView, setOnlyView] = useState(false)

    const changeSection = (section) => {
        dispatch(setActiveSection(section))
    }

    useEffect(() => {
        if (appSt.serviceData) {
            if (!catalogosSt.estatusConsultorAcreditado) {
                dispatch(getEstatusConsultorAcredito(appSt.serviceData))
            }
            if (!catalogosSt.criterios) {
                dispatch(getCriteriosCatalogo(appSt.serviceData))
            }
            if (!catalogosSt.tamanioSectorSe) {
                dispatch(getTamanioSectorSeCatalogo(appSt.serviceData))
            }
            if (!catalogosSt.documentosConsultorAcreditado) {
                dispatch(getDocumentosConsultorAcreditado({ ...appSt.serviceData, cveTipoDocumento: CVE_TIPODOC_CONSULTOR }))
            }
        }
    }, [appSt.serviceData])

    useEffect(() => {
        if (appSt.serviceData && userSt.persona) {
            dispatch(getConsultorData({
                ...appSt.serviceData,
                idConsultorAcreditado: userSt.persona.idPersona
            }))
        }
    }, [userSt.persona])

    useEffect(() => {
        if (registroConsultorSt.consultorData && catalogosSt.documentosConsultorAcreditado && catalogosSt.idsEstatusConsultor) {
            setOnlyView(
                registroConsultorSt.consultorData.idEstatusConsultorAcreditado !== catalogosSt.idsEstatusConsultor.invitado && 
                registroConsultorSt.consultorData.idEstatusConsultorAcreditado !== catalogosSt.idsEstatusConsultor.rechazado
            )
            const form = JSON.parse(JSON.stringify(DatosBasicosForm))
            const clone = fillForm(form, registroConsultorSt.consultorData)
            const validForm = validateForm(clone)
            const datosBasicosValid = validForm.length === 0
            const areasValid = registroConsultorSt.consultorData.cemAreaExperienciaConsultors.length === 3
            const documentosValid = registroConsultorSt.consultorData.cemDocumentoConsultorAcreditados.length === catalogosSt.documentosConsultorAcreditado.length
            dispatch(setValidations({
                documentosValid,
                datosBasicosValid,
                areasValid
            }))
        }
    }, [registroConsultorSt.consultorData, catalogosSt.documentosConsultorAcreditado,  registroConsultorSt.activeSection])

    return (<main className="registro-consultor">
        <div className="registro-title">
            <h3>Registro Consultor</h3>
        </div>
        <nav>
            <button 
                className={registroConsultorSt.activeSection === SECTIONS.one ? "active" : ""} 
                onClick={() => changeSection(SECTIONS.one)}>
                {registroConsultorSt.validations.datosBasicosValid ? <CompletedIcon className="success" /> : <ProgressIcon />}
                Datos básicos
            </button>
            <button 
                className={registroConsultorSt.activeSection === SECTIONS.two ? "active" : ""} 
                onClick={() => changeSection(SECTIONS.two)}>
                {registroConsultorSt.validations.areasValid ? <CompletedIcon className="success" /> : <ProgressIcon />}                
                Áreas de experiencia
            </button>
            <button 
                className={registroConsultorSt.activeSection === SECTIONS.three ? "active" : ""} 
                onClick={() => changeSection(SECTIONS.three)}>
                {registroConsultorSt.validations.documentosValid ? <CompletedIcon className="success" /> : <ProgressIcon />}                
                Documentos
            </button>
        </nav>
        <div className="registro-consultor-card">
            {userSt.usuario && (
                <div className="consultor-info">
                    <div className="two-col">
                        <div>
                            <small>Consultor</small>
                            <h3>{userSt.usuario.data.nombre} {userSt.usuario.data.apellidoPaterno} {userSt.usuario.data.apellidoMaterno}</h3>
                        </div>
                        <div className="status">
                            <small>Estatus</small>
                            {registroConsultorSt.consultorData &&
                                <h3>{registroConsultorSt.consultorData.descEstatusConsultorAcreditado}</h3>
                            }
                        </div>
                    </div>
                </div>
            )}
            {registroConsultorSt.consultorData && registroConsultorSt.consultorData.motivoRechazo && <div className="tip danger">
                <InfoIcon />
                <p>Motivo Rechazo: {registroConsultorSt.consultorData.motivoRechazo}</p>
            </div>}
            {registroConsultorSt.activeSection === SECTIONS.one && <DatosBasicosConsultor onlyView={onlyView} />}
            {registroConsultorSt.activeSection === SECTIONS.two && <AreasExperienciaConsultor onlyView={onlyView} />}
            {registroConsultorSt.activeSection === SECTIONS.three && <DocumentosConsultor onlyView={onlyView} />}
        </div>
    </main>)
}