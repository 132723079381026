import { useDispatch, useSelector } from 'react-redux';
import { changeModalVinculacionEntidad, registroState } from '../../slices/registroSlice';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { useState } from 'react';
import { appState, setAlert, setLoading } from '../../slices/appSlice';
import { postColaboradorEntidad, postEntidadByFilters } from '../../services/entidad';
import { ALERT_FAIL, ALERT_SUCCESS, ALERT_WARN } from '../../app/constantes';
import { getPersonaById, usuariosServicesState } from '../../slices/usuariosServicesSlice';
import { postUsuarioToGroup } from '../../services/usuarios';
import { postPersona } from "../../services/persona";
import { useEffect } from 'react';
import { obtenerMisEmpresas } from '../../slices/entidadServicesSlice';


export default function ModalVinculacionEntidad() {
    const appSt = useSelector(appState);
    const registroSt = useSelector(registroState);
    const usuariosSt = useSelector(usuariosServicesState);

    const dispatch = useDispatch();

    const [rfc, setRfc] = useState('');
    const [results, setResults] = useState(null);

    const closeModal = () => {
        dispatch(changeModalVinculacionEntidad({
            show: false, 
            data: null
        }));
    }

    const searchRfc = async () => {
        if (rfc !== '') {
            dispatch(setLoading(true));
    
            const body = {
                rfc,
                esParaVinculacion: 1
            }
    
            try {
                const resp = await postEntidadByFilters(appSt.serviceData, body);
                if (resp.payload) {
                    setResults(resp.payload);
                }
                dispatch(setLoading(false));
            } catch (error) {
                dispatch(setLoading(false));
                dispatch(setAlert({
                    show: true,
                    message: 'Error al buscar RFC',
                    type: ALERT_FAIL
                }))
            }
        } else {
            dispatch(setAlert({
                show: true,
                message: 'Favor de llenar el campo de RFC',
                type: ALERT_WARN
            }))
        }
    }

    const solicitarVinculacion = async (entidad) => {
        dispatch(setLoading(true));
        try {            
            // damos de alta al usuario loggeado como colaborador
            await postPersona(appSt.serviceData, {
                rfc: usuariosSt.persona.rfc,
                cveRegimenPersona: usuariosSt.persona.idRegimenPersona.cveRegimenPersona,
                isSedeMarca: 0,
                colaborador: {
                    nombre: usuariosSt.usuario.data.nombre,
                    apellidoPaterno: usuariosSt.usuario.data.apellidoPaterno,
                    apellidoMaterno: usuariosSt.usuario.data.apellidoMaterno,
                    email: usuariosSt.usuario.data.email,
                }
            });
            // hacemos la union entre el colaborador y la entidad
            await postColaboradorEntidad(appSt.serviceData, {
                idColaborador: usuariosSt.persona.idPersona,
                idEntidad: entidad.idEntidad,
                isEntidadPrincipal: 0
            });
            // agregamos al usuario al grupo correspondiente
            await postUsuarioToGroup(appSt.serviceData, {
                cvePerfil: 'Colaborador',
                idUsuario: usuariosSt.usuario.data.idUsuario,
                idEntidad: entidad.idEntidad,
                cveEstatusRegistro: 'EN_VINCULACION'
            });

            dispatch(setAlert({
                show: true,
                message: 'Solicitud enviada',
                type: ALERT_SUCCESS
            }));
            setRfc('');
            setResults('');
            closeModal();
            dispatch(setLoading(false));
            dispatch(obtenerMisEmpresas({
                ...appSt.serviceData,
                idUsuario: appSt.tokenInfo.sub
            }))

        } catch (error) {
            console.log(error)
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: 'Error al enviar solicitud',
                type: ALERT_FAIL
            })); 
        }
    }

    useEffect(() => {
        if (usuariosSt.usuario && usuariosSt.usuario.data) {
            if (usuariosSt.persona === null && appSt.serviceData) {
                dispatch(getPersonaById({
                    id: usuariosSt.usuario.data.idUsuario,
                    ...appSt.serviceData
                }))
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuariosSt.usuario, appSt.serviceData])
    
    return (
      <div className={registroSt.modalVinculacionEntidad.show ? 'modal-wrapper active' : 'modal-wrapper'}>
        <div className="modal vinculacion-entidad">
            <div className="head">
                <h3>Solicitar Vinculacion a Entidad</h3>
                <button onClick={closeModal}>
                    x
                </button>
            </div>
            <div className="content">
                <div className="search-input">
                    <input type="text" placeholder='RFC' onChange={(e) => setRfc(e.target.value)}/>
                    <button onClick={searchRfc}>
                        <SearchIcon />
                    </button>                        
                </div>

                {results && results.length > 0 &&
                    <div>
                        <p>Resultados: </p>
                        <ul>
                            {results.map((item, index) => {
                                return <li key={index}>
                                    <p>{item.idEntidad2.rfc}</p>
                                    <button onClick={() => solicitarVinculacion(item)}>
                                        <AddIcon />
                                    </button>
                                </li>
                            })}
                        </ul>
                    </div>
                }

                {results && results.length === 0 && 
                    <p>Sin resultados</p>
                }
            </div>

            <div className="actions">
                <button onClick={closeModal}>Cancelar</button>
            </div>
        </div>
      </div>
    );
  }