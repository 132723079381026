import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usuariosServicesState,  getContactosCemefi } from "../slices/usuariosServicesSlice";
import { appState, setLoading } from "../slices/appSlice";
import { ReactComponent as UsuariosIcon } from '../assets/icons/usuarios.svg';
import { ReactComponent as MailIcon } from '../assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../assets/icons/phone.svg';
import { ReactComponent as ContactoIcon } from '../assets/icons/contacto_1.svg';
import '../styles/contactos.scss';
import { useState } from "react";
import { getPhotoContacto } from "../services/entidad";


export default function ContactosCemefi() {
    const dispatch = useDispatch();
    const usuarioSt = useSelector(usuariosServicesState);
    const appSt = useSelector(appState);
    const [contactos, setContactos] = useState(null);

    const getPreview = async (contacto) => {
        dispatch(setLoading(true));
        const data = {
            ...appSt.serviceData,
            idContactoCemefi: contacto.idContactoCemefi,
            idContent: contacto.idContent
        }
        const docBase = await getPhotoContacto(data);
        let img = null;
        if (docBase.payload) {
            const type = contacto.idContent.split('.').pop().toLowerCase();
            if (type === 'png' || type === 'jpg' || type === 'jpeg') {
                const urlBase = `data:image/${type};base64, ${docBase.payload.base64}`;
                img = urlBase
            }
        }
        dispatch(setLoading(false));
        return img;
    }

    const getPreviews = async () => {
        const array = []
        for (const contacto of usuarioSt.contactosCemefi) {
            let img = null
            if (contacto.idContent && contacto.idContent !== '') {
                img = await getPreview(contacto);
            }
            array.push({
                img,
                ...contacto
            })
        }
        setContactos(array);
    }

    useEffect(() => {
        if (appSt.serviceData) {
            dispatch(getContactosCemefi({
                ...appSt.serviceData,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSt.serviceData])

    useEffect(() => {
        if (usuarioSt.contactosCemefi && usuarioSt.contactosCemefi.length > 0) {
            getPreviews()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuarioSt.contactosCemefi])

    return (
        <section className="contactos-cemefi">
            <div className="wrapper">
                <div className="main-section-title">
                    <UsuariosIcon />
                    <div className="text">
                        <h3>Contactos CEMEFI</h3>
                        <p>Información de los contactos de CEMEFI</p>
                    </div>
                </div>
                <div className="contactos-wrapper">
                    {usuarioSt.contactosCemefi && contactos && contactos.length > 0 && contactos.map((contacto, index) => {
                        return <div className="contacto" key={index}>
                            <div className="icon">
                                
                                { contacto && contacto.idContent && contacto.img ? <img src={contacto.img} alt="" /> : <ContactoIcon /> }
                            </div>
                            <div className="info">
                                <h3><b>{contacto.nombre}</b> {contacto.apellidoPaterno} {contacto.apellidoMaterno}</h3>
                                <h4>{contacto.area} - {contacto.funcion}</h4>
                                <p><MailIcon /> {contacto.email}</p>
                                {contacto.telefonoExt && 
                                    <p><PhoneIcon /> {contacto.telefonoExt} {contacto.idPaisLada2 ? `, Lada: +${contacto.idPaisLada2.cvePaisLada}` : ''}</p>
                                }
                            </div>
                        </div>               
                    })
                    }
                </div>
            </div>
        </section>
    )
}