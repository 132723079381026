import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SECTIONS } from '../app/constantes';
import { getConsultorById } from '../services/entidad';

const initialState = {
    loading: false,
    activeSection: SECTIONS.one,
    consultorData: null,
    validations: {
        datosBasicosValid: false,
        areasValid: false,
        documentosValid: false
    }
}

export const getConsultorData = createAsyncThunk('registroConsultor/getConsultorData', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getConsultorById(data);
    } catch (error) {
        console.log(error.response, error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const registroConsultorSlice = createSlice({
    name: 'registroConsultor',
    initialState,    
    reducers: {
        setActiveSection: (state, action) => {
            state.activeSection = action.payload;
        },
        setValidations: (state, action) => {
            state.validations = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getConsultorData.pending, (state) => {
            state.loading = true
        })
        builder.addCase((
            getConsultorData.rejected),
        (state) => {
            state.loading = false
        })
        builder.addCase(getConsultorData.fulfilled, (state, action) => {
            state.loading = false;
            // let datosBasicosValid = false
            // let areasValid = false
            // let documentosValid = false
            // if (action.payload) {
            //     const form = JSON.parse(JSON.stringify(DatosBasicosForm))
            //     const clone = fillForm(form, action.payload)
            //     const validForm = validateForm(clone)
            //     datosBasicosValid = validForm.length === 0
            //     areasValid = action.payload.cemAreaExperienciaConsultors.length === 3
            //     console.log(action.payload.cemAreaExperienciaConsultors.length, action.payload, areasValid)
            // }
            // state.consultorData = {
            //     datosBasicosValid,
            //     areasValid,
            //     documentosValid
            // }
            state.consultorData = action.payload;
        })
    }
})

export const { 
    setActiveSection,    
    setValidations,
 } = registroConsultorSlice.actions;
export const registroConsultorState = (state) => state.registroConsultor;

export default registroConsultorSlice.reducer;