/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import InputComp from "../generales/inputComponent";
import { useDispatch, useSelector } from "react-redux";
import { findCatalogById, formatForm, validateForm } from "../../app/commons";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { ALERT_FAIL, SECTIONS } from "../../app/constantes";
import { seleccionContactosForm } from "../../app/forms/publicacion/seleccion-contacto";
import { getContactoEntidad, postContactoEntidad } from "../../services/entidad";
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import { publicacionState } from "../../slices/publicacionSlice";
import { getCartaDictamen } from "../../services/publicacion";
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { catalogosServiceState, getPaisCatalogo, getTipoContactoCatalogo } from "../../slices/catalogosServiceSlice";
import { datosContactoForm } from "../../app/forms/registro/datos-contacto";

export default function ModalSeleccionarContacto({modalData, close}) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const publicacionSt = useSelector(publicacionState);
    const catalogosSt = useSelector(catalogosServiceState);
    const [contactos, setContactos] = useState(null);
    const [contactoSelected, setContactoSelected] = useState(null);
    const [form, setForm] = useState(JSON.parse(JSON.stringify(seleccionContactosForm)));
    const [section, setSection] = useState(SECTIONS.one);
    const [contactoForm, setContactoForm] = useState(JSON.parse(JSON.stringify(datosContactoForm)));

    const handleSubmit = async () => {
        const validForm = validateForm(contactoForm)
        if (form.contacto.value !== "" || validForm.length === 0) {
            if (section === SECTIONS.one) {
                const valid = validateForm(form);
                if (valid.length === 0) {
                    dispatch(setLoading(true));
                    await downloadFile(contactoSelected.idContactoEntidad)
                    closeModal()
                    dispatch(setLoading(false));
                } else {
                    dispatch(setAlert({
                        show: true, 
                        message: 'Favor de llenar todos los campos requeridos',
                        type: ALERT_FAIL
                    }))
                }
            } else {
                const value = formatForm(contactoForm);
                const valid = validateForm(contactoForm);
                console.log(valid)
    
                if (valid.length === 0) {
                    if (value.email !== value.emailConfirm) {
                        dispatch(setAlert({
                            show: true,
                            message: 'Los correos no coinciden',
                            type: ALERT_FAIL
                        }))
                    } else {
                        saveContact(value);
                    }
                } else {
                    dispatch(setAlert({
                        show: true,
                        message: 'Favor de llenar todos los campos requeridos',
                        type: ALERT_FAIL
                    }))
                }
            }
        } else {
            console.log(validForm, form)
            dispatch(setAlert({
                show: true,
                message: 'Favor de llenar todos los campos requeridos',
                type: ALERT_FAIL
            }))
        }
        
    }

    const changeSection = (sec) => {
        setContactoForm(JSON.parse(JSON.stringify(datosContactoForm)))
        setForm(JSON.parse(JSON.stringify(seleccionContactosForm)))
        setSection(sec)
    }

    /**
     * Se guarda el contacto en la base de datos
     * @param {*} value 
     */
    const saveContact = async (value) => {
        dispatch(setLoading(true));
        const data = appSt.serviceData
        let body = {
            ...value,
            idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad
        }
        delete body.ayudo_aliado;
        delete body.ayudo_consultor;
        delete body.emailConfirm;

        let resp;

        resp = await postContactoEntidad(data, body);

        if (!resp.error) {
            await downloadFile(resp.payload.id)
            dispatch(setLoading(false));
            closeModal()
        } else {
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: "Error al guardar contacto",
                type: ALERT_FAIL
            }))
        }
    }

    const downloadFile = async (idContactoEntidad) => {
        const blob = await getCartaDictamen({
            ...appSt.serviceData,
            idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria,
            idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad,
            idContactoEntidad
        });

        console.log(blob)

        if (blob) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Carta Dictamen';
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
    }

    const closeModal = () => {
        setForm(JSON.parse(JSON.stringify(seleccionContactosForm)));
        close();
    }

    const getContactos = async () => {
        dispatch(setLoading(true))
        const resp = await getContactoEntidad({...appSt.serviceData, idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad})
        const array = [];
        if (resp.payload) {
            resp.payload.forEach(element => {
                array.push({
                    ...element,
                    nombreCompleto: `${element.nombre} ${element.apellidoPaterno} ${element.apellidoMaterno}`
                })
            });
        }
        setContactos(array)
        dispatch(setLoading(false));
        console.log(resp)
    }

    useEffect(() => {
        if (modalData.data) {
            getContactos()
        }
    }, [modalData])

    useEffect(() => {
        if (form.contacto.value !== '') {
            const contacto = contactos.find(item => Number(item.idContactoEntidad) === Number(form.contacto.value))
            console.log(contacto, contactos, form)
            if (contacto) {
                setContactoSelected(contacto);
            }
        } else {
            setContactoSelected(null)
        }
    }, [form.contacto])

    useEffect(() => {
        if (!catalogosSt.pais) {
            dispatch(getPaisCatalogo(appSt.serviceData))
        }
        if (!catalogosSt.tipoContacto) {
            dispatch(getTipoContactoCatalogo(appSt.serviceData))
        }
    }, [])

    return (
        <div className={modalData.show ? 'modal-wrapper active' : 'modal-wrapper'}>
            <div className="modal">
                <div className="head">
                    <h3>Selecciona al Director General</h3>
                    <button onClick={closeModal}>
                        x
                    </button>
                </div>
                <div className="content">
                    <div className="tip">
                        <InfoIcon />
                        <p>Para descargar la carta de dictamen es requerido que se seleccione el Nombre del Director General</p>
                    </div>
                    <div className="tabs">
                        <button className={`tab ${section === SECTIONS.one ? 'active' : ''}`} onClick={() => changeSection(SECTIONS.one)}>Seleccionar Contacto</button>
                        <button className={`tab ${section === SECTIONS.two ? 'active' : ''}`} onClick={() => changeSection(SECTIONS.two)}>Agregar Contacto</button>
                    </div>
                    <div className="tabs-container">
                        {section === SECTIONS.one &&
                            <div className="tab-content">
                                {contactos &&
                                    <InputComp label="Nombre de Director General" name="contacto" type="select" form={form} setForm={setForm} catalogos={contactos} catalogoLabel={'nombreCompleto'} catalogoValue={'idContactoEntidad'}/>
                                }
                                {contactoSelected &&
                                <div className="dato">
                                    <label>Puesto</label>
                                    <p>{contactoSelected.puesto}</p>
                                </div>
                                }
                            </div>
                        }
                        {section === SECTIONS.two &&
                            <div className="tab-content">
                                <InputComp label="Nombre" name="nombre" type="upper" form={contactoForm} setForm={setContactoForm} />
                                <InputComp label="Apellido Paterno" name="apellidoPaterno" type="upper" form={contactoForm} setForm={setContactoForm} />
                                <InputComp label="Apellido Materno" name="apellidoMaterno" type="upper" form={contactoForm} setForm={setContactoForm} />
                                <InputComp label="Puesto que ocupa en su empresa" name="puesto" type="upper" form={contactoForm} setForm={setContactoForm} />
                                <div className="two-col">
                                    {catalogosSt.pais &&
                                        <InputComp label="País" name="cvePais" type="select" form={contactoForm} setForm={setContactoForm} catalogos={catalogosSt.pais} catalogoValue={'cvePais'} catalogoLabel={'descPais'} />
                                    }
                                    {form.cvePais && <div className="dato">
                                        <label>Lada</label>
                                        <p>+{findCatalogById(form.cvePais.value, catalogosSt.pais, 'cvePais', 'cvePaisLada')}</p>                                
                                    </div>}
                                </div>
                                <InputComp max="10" label="Teléfono" name="telefono" type="number" form={contactoForm} setForm={setContactoForm} />
                                <InputComp max='10' label="Teléfono celular" name="celular" type="number" form={contactoForm} setForm={setContactoForm} />
                                <InputComp label="Email" name="email" type="lower" form={contactoForm} setForm={setContactoForm} />
                                <InputComp label="Confirmar Email" name="emailConfirm" type="text" form={contactoForm} setForm={setContactoForm} />
                                {catalogosSt.tipoContacto &&
                                    <InputComp label="Tipo Contacto" name="idTipoContactoEntidad" type="select" form={contactoForm} setForm={setContactoForm} catalogos={catalogosSt.tipoContacto} catalogoValue={'idTipoContactoEntidad'} catalogoLabel={'descTipoContactoEntidad'} />
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="actions">
                    <button onClick={closeModal}>Cancelar</button>
                    <button onClick={handleSubmit}>Descargar Dictamen</button>
                </div>
            </div>
        </div>
    )
}