import { formatCurrency } from "../../../app/commons";

const decimalCorrectionFactor = (x, y) => {
    const cf = 100;
    return parseInt(x * cf * y * cf) / (cf * cf);
};

const Total = ({ selectedItems, costoInicial, adicionales }) => {
    let cobros = [];
    let inicial = costoInicial;

    if (adicionales && adicionales.length > 0) {
        for (const add of adicionales) {
            if (add.monto) {
                inicial = inicial + add.monto;
            }
        }
    }

    for (let idEntidad in selectedItems) {
        for (let conceptoCobro in selectedItems[idEntidad]) {
            cobros.push(selectedItems[idEntidad][conceptoCobro]);
        }
    }

    let totalCobros = cobros.reduce((prev, curr) => {
        let calculation;
        if (curr) {
            calculation = decimalCorrectionFactor(
                curr.montoCobro,
                curr.cantidad 
            );
        } else {
            calculation = 0;
        }
        return (calculation * 100 + prev * 100) / 100;

    }, 0);

    let total = totalCobros + inicial;

    return (
        <div className="chip chip--fixed">
            <div className="text" style={{ borderRight: 'none' }}>
                <label>Total:</label>
                <p>{formatCurrency(total)}</p>
            </div>
        </div>
    );
};

export default Total;