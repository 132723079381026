/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import InputComp from "../generales/inputComponent";
import { useDispatch, useSelector } from "react-redux";
import { catalogosServiceState, getEsquemaCobroCatalogo } from "../../slices/catalogosServiceSlice";
import { findCatalogById, formatForm, validateForm } from "../../app/commons";
import { appState, setAlert } from "../../slices/appSlice";
import { ALERT_FAIL, CVE_DIRECCION, MEXICO_ID } from "../../app/constantes";
import { pagosCursosState } from "../../slices/pagosCursosSlice";
import { getEntidad, putDireccionEntidad } from "../../services/entidad";
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import { datosFiscalesForm } from "../../app/forms/cobros/datosFiscales";
import DireccionComponent from "../generales/direccionComponent";
import { direccionForm } from "../../app/forms/registro/direccion";
import { getFormasPagosDinamico, getTips } from "../../services/catalogos";

export default function DatosFacturacion({ type, callback, cancel, hidden, idEntidad }) {
  const dispatch = useDispatch();
  const catalogosSt = useSelector(catalogosServiceState);
  const pagosSt = useSelector(pagosCursosState);
  const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
  const appSt = useSelector(appState);
  const [form, setForm] = useState(JSON.parse(JSON.stringify(datosFiscalesForm)));
  const [registros, setRegistros] = useState(null);
  const [formDireccion, setFormDireccion] = useState(JSON.parse(JSON.stringify(direccionForm)));
  const [catalogoFormaPago, setCatalogoFormaPago] = useState(null)
  const [disabledSubmit, setDisable] = useState(false);
  const [tooltip, setTootltip] = useState(null);


  const handleCancel = () => {
    setForm(JSON.parse(JSON.stringify(datosFiscalesForm)))
    setFormDireccion(JSON.parse(JSON.stringify(direccionForm)))
    cancel()
  }

  /**
   * Guardamos la direccion
   */
  const saveDireccion = async () => {
    try {
      const formatedForm = formatForm(formDireccion);
      let body = {
        idPais: formatedForm.idPais,
        calle: formatedForm.calle,
        numeroExterior: formatedForm.numeroExterior,
        numeroInterior: formatedForm.numeroInterior,
        idEntidad,
        idTipoDireccion: catalogosSt.idsTiposDireccion.fiscal
      }
      if (formatedForm.idPais === MEXICO_ID || formatedForm.idPais === Number(MEXICO_ID)) {
        body = {
          ...body,
          idCodigoPostal: formatedForm.idCodigoPostal,
        }
      } else {
        body = {
          ...body,
          idCodigoPostal: null,
          codigoPostal: formatedForm.codigoPostal.length === 4 ? `0${formatedForm.codigoPostal}` : formatedForm.codigoPostal,
          ciudad: formatedForm.ciudad,
          estado: formatedForm.estado,
          colonia: formatedForm.colonia,
          municipioAlcaldia: formatedForm.municipioAlcaldia
        }
      }
      const filt = registroConvocatoriaSt?.empresaSeleccionada?.cemDireccionEntidads.find(dir => dir.idTipoDireccion === catalogosSt.idsTiposDireccion.fiscal);
      if (filt) {
        const putData = {
          ...appSt.serviceData,
          idDireccion: filt.idDireccionEntidad
        }
        await putDireccionEntidad(putData, body);
      }
    } catch (error) {
      console.log(error)
      throw new Error('Error al guardar dirección')
    }
  }

  /**
   * Generamos los conceptos q se enviaran a facturar
   */
  const formatArray = async () => {
    try {
      let array = [];
      if (type) {
        switch (type) {
          case "normal":
            for (const item of pagosSt.pagoDetails.cobroCursosDet) {
              if (
                item.id_estatus_cobro_aplicado === catalogosSt.idsEstatusCobro.confirmado &&
                item.fac_anticipada !== 1 
              ) {
                array.push(item)
              }
            }          
            break;
          case "anticipada":
          case "enLinea":
            for (const item of pagosSt.pagoDetails.cobroCursosDet) {
              if (
                item.id_estatus_cobro_aplicado === catalogosSt.idsEstatusCobro.registrado && item.fac_anticipada !== 1 
              ) {
                array.push(item)
              }
            }
            break;
          default:
            break;
        }
      } else {
      }
      return array      
    } catch (error) {
      console.log(error); 
      throw new Error("No existe monto de participación")
    }
  }

  const formatPagos = (items, type) => {
    let body
    const detalles = []
    if (items && items.length > 0) {
      const formatDir = formatForm(formDireccion)
      items.forEach(item => {
        detalles.push({
          idCobroCurso: item.id_cobro_curso,
          idCobroCursoAplicado: item.id_cobro_curso_aplicado,
          cantidad: item.cantidad,
          unidadMedida: "SERVICIO",
          descripcion: form.folioOdc.value ? `${item.desc_cobro_curso} OC ${form.folioOdc.value}` : item.desc_cobro_curso,
          valorUnitario: item.monto_cobro,
          cveProdServ: item.clave_prod_serv || "86101705",
          cveUnidadMedida: item.unidad_medida || "E48",
          objetoImpuesto: "02"  
        })
      })
      body = {
        idAreaCemefi: appSt.serviceData.area,
        // idAreaCemefi: 1,
        idEntidad,
        // idEntidad: 1935,
        folioOdc: form.folioOdc.value ? form.folioOdc.value : null,
        formaPago: type === 'online' ? form.cveFormaPago.value : '99',
        condicionesPago: "30 Dias",
        metodoPago: type === 'online' ? 'PUE' : 'PPD',
        // metodoPago: "PPD",
        receptor: {
          usoCfdi: form.cveUsoCfdi.value,
          regimenFiscal: findCatalogById(Number(form.idRegimenFiscal.value), catalogosSt.regimen, 'idRegimenFiscal', 'cveRegimenFiscal'),
          rfc: form.rfc.value,
          razonSocial: form.razonSocial.value,
          // rfc: "GEJL890117EX1",
          // razonSocial: "LILIANA GERONIMO JIMENEZ",
          calle: formatDir.calle,
          noExterior: formatDir.numeroExterior,
          colonia: formatDir.colonia,
          municipio: formatDir.municipioAlcaldia,
          estado: formatDir.estado,
          pais: "MEXICO",
          codigoPostal: formatDir.codigoPostal.length === 4 ? `0${formatDir.codigoPostal}` : formatDir.codigoPostal,
          contactoEmail: "prueba@prueba.com.mx"
        },
        detalles
      }
    }
    return items && items.length > 0 ? {...body, detalles} : null
  }

  /**
   * Solicitamos factura
   */
  const handleSubmit = async () => {
    setDisable(true)
    const valid = validateForm(form);
    const validDic = validateForm(formDireccion);
    if ((valid.length === 0 && validDic.length === 0 && registros) || hidden) {
      try {
        const pagosOnline = registros.filter(item => {
          return (!item.idEsquemaCobro && type !== 'anticipada') || 
          (item.idEsquemaCobro && item.idEsquemaCobro === catalogosSt.idsEsquemaCobro.online)
        })
        const pagosOffline = registros.filter(item => {
          return (!item.idEsquemaCobro && type === 'anticipada') || 
          (item.idEsquemaCobro && item.idEsquemaCobro === catalogosSt.idsEsquemaCobro.offline)
        })
        const PUE = formatPagos(pagosOnline, 'online')
        const PPD = formatPagos(pagosOffline, 'offline')
        
        await saveDireccion()
        
        callback({ 
          online: {
            data: PUE, 
            registros: pagosOnline
          }, 
          offline: {
            data: PPD, 
            registros: pagosOffline
          } })
        setDisable(false)
      } catch (error) {
        setDisable(false)
        console.log(error)
        dispatch(setAlert({
          show: true,
          message: error.payload,
          type: ALERT_FAIL
        }))
      }

    } else {
      setDisable(false)
      dispatch(setAlert({
        show: true,
        message: 'Favor de llenar todos los campos requeridos',
        type: ALERT_FAIL
      }))
    }
  }

  const getCatalogoFormaPago = async (type) => {
    try {
      const resp = await getFormasPagosDinamico({
        ...appSt.serviceData,
        tipoConsulta: type
      })
      setCatalogoFormaPago(resp.payload)      
    } catch (error) {
      console.log(error)
      dispatch(setAlert({
        show: true,
        message: "Error al consultar catalogo",
        type: ALERT_FAIL
      }))
    }
  }

  const populateForm = async () => {
    try {
      if (type) {
        const data = await getEntidad({ ...appSt.serviceData, idEntidad })
        const clone = JSON.parse(JSON.stringify(form));
        if (data.payload) {
          clone.razonSocial.value = data.payload.razonSocial;
          clone.rfc.value = data.payload.idEntidad2.rfc;
          // clone.rfc.value = "GEJL890117EX1";
          // clone.razonSocial.value = "LILIANA GERONIMO JIMENEZ";
        }
        if (pagosSt.pagoDetails && pagosSt.pagoDetails.cobroCursosDet) {
          const array = await formatArray()
          // aqui vienen del array q ya hace el filtro de anticipadas
          const pagosOnline = array.filter(item => {
            return (!item.idEsquemaCobro && type !== 'anticipada') || 
            (item.idEsquemaCobro && item.idEsquemaCobro === catalogosSt.idsEsquemaCobro.online)
          })
          const pagosOffline = array.filter(item => {
            return (!item.idEsquemaCobro && type === 'anticipada') || 
            (item.idEsquemaCobro && item.idEsquemaCobro === catalogosSt.idsEsquemaCobro.offline)
          })
  
          const PUE = formatPagos(pagosOnline, 'online')
          const PPD = formatPagos(pagosOffline, 'offline')
          await getCatalogoFormaPago(PPD && !PUE ? 2 : 1)
          if (PPD && !PUE) {
            clone.cveFormaPago.value = '99'
            clone.cveMetodoPago.value = 'PPD';
          } else {
            clone.cveMetodoPago.value = 'PUE';
          }
          setRegistros(array)
        }
        if (tooltip) {
          clone.folioOdc.tool = tooltip
        }
        setForm(clone);
      }      
    } catch (error) {
      console.log(error);
      dispatch(setAlert({
        show: true,
        message: "No existe monto de participación",
        type: ALERT_FAIL
      }))
    }
  }

  const getTooltips = async () => {
    try {
      const resp = await getTips({
        ...appSt.serviceData,
        claveCatalogo: "parametro",
        clave: "MENSAJEORDENCOMPRA"
      });
      setTootltip(resp.payload.valor)  
    } catch (error) {
      console.log(error)      
    }
  }

  useEffect(() => {
    populateForm();    
  }, [type, pagosSt.pagoDetails])

  useEffect(() => {
    if (!catalogosSt.esquemaCobro) {
      dispatch(getEsquemaCobroCatalogo(appSt.serviceData))
    }
    getTooltips()
  }, [])

  return (<>
    <div className={hidden ? "content hidden" : 'content'}>
      {registros && registros.length > 0 && catalogosSt.esquemaCobro && <>
        <table>
          <thead>
            <tr>
              <th>Descripción</th>
              <th>Metodo de pago</th>
            </tr>
          </thead>
          <tbody>
            {registros.map((item, index) => {
              return <tr key={index}>
                <td>{item.desc_cobro_curso}</td>
                <td>
                  {
                  (item.id_esquema_cobro && item.id_esquema_cobro === catalogosSt.idsEsquemaCobro.offline) || 
                  (!item.id_esquema_cobro && type === 'anticipada') 
                    ? 'PPD' : 'PUE'} 
                 
                </td>
              </tr>
            })}
            <tr></tr>
          </tbody>
        </table>
      </>}
      {type &&
        <DireccionComponent aviso={null} empresaSeleccionada={registroConvocatoriaSt.empresaSeleccionada} cveDireccion={CVE_DIRECCION.fiscal} form={formDireccion} setForm={setFormDireccion} isFiscal={true}/>
      }
      <div className="two-col">
        <InputComp disabled={true} label="RFC" name="rfc" type="upper" form={form} setForm={setForm} />
        <InputComp disabled={true} label="Razón Social" name="razonSocial" type="upper" form={form} setForm={setForm} />
        {catalogosSt.usoCfdi &&
          <InputComp label="Uso CFDI" name="cveUsoCfdi" type="select" form={form} setForm={setForm} catalogos={catalogosSt.usoCfdi} catalogoValue={'cveUsoCfdi'} catalogoLabel={'descUsoCfdi'} secundaryLabel={'cveUsoCfdi'}/>
        }
        {catalogoFormaPago &&
          <InputComp label="Forma de Pago" name="cveFormaPago" type="select" form={form} setForm={setForm} catalogos={catalogoFormaPago} catalogoValue={'cveFormaPago'} catalogoLabel={'descFormaPago'} secundaryLabel={'cveFormaPago'} />
        }
        {catalogosSt.regimen &&
          <InputComp label="Regimen Fiscal" name="idRegimenFiscal" type="select" form={form} setForm={setForm} catalogos={catalogosSt.regimen} catalogoValue={'idRegimenFiscal'} catalogoLabel={'descRegimenFiscal'} secundaryLabel={'cveRegimenFiscal'} />
        }
        <InputComp label="Agrega tu Orden de Compra Interna" name="folioOdc" type="upper" form={form} setForm={setForm} />
      </div>
    </div>
    <div className="actions">
      <button onClick={handleCancel}>Cancelar</button>
      <button onClick={handleSubmit} disabled={disabledSubmit}>Continuar</button>
    </div>
  </>
  )
}