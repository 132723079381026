import { deleteRequest, getRequest, postRequest } from "./http-service";

const url = process.env.REACT_APP_MEMBRESIAS_SERVICE;

export const getMembresias = async (data) => {
    const requestUrl = `${url}/area/${data.area}?idTamanio=${data.idTamanio}&idTipoEntidad=${data.idTipoEntidad}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getActiveMembresiaByEntidad = async (data) => {
    const requestUrl = `${url}/area/${data.area}/membresia/${data.idMembresia}/entidad/${data.idEntidad} `;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getMembresiaDetails = async (data) => {
    const requestUrl = `${url}/area/${data.area}/membresia/${data.idMembresia}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};


export const getMembresiaById = async (data) => {
    const requestUrl = `${url}/membresia-entidad/area/${data.area}?idMembresia=${data.idMembresia}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};


export const getMembresiaByEntidad = async (data) => {
    const requestUrl = `${url}/membresia-entidad/area/${data.area}?idEntidad=${data.idEntidad}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postMembresiaEntidad = async (data, body) => {
    const requestUrl = `${url}/membresia-entidad/area/${data.area}/entidad/${data.idEntidad}/membresia/${data.idMembresia}`;

    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteMembresiaEntidad = async (data) => {
    const requestUrl = `${url}/membresia-entidad/area/${data.area}/membresia-entidad/${data.idMembresiaEntidad}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

