import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATOS_PARTICIPACION_STEP, EMPRESAS_LIST } from '../app/constantes';
import { getCobros, getListCobroAplicado } from '../services/cobro';
import { getCobroRegistroConvocatoria, getConvocatoriasByIdEntidad, getDocumentosConvocatoria } from '../services/convocatorias';
import { getEntidad } from '../services/entidad';

const initialState = {
    activeView: EMPRESAS_LIST,
    empresaSeleccionada: null,
    convocatoriaSeleccionada: null,
    etapaRegistro: DATOS_PARTICIPACION_STEP,
    entidadesAdicionales: null,
    listaConvocatorias: null,
    isLoading: false,
    isError: false,
    error: null,
    serviciosAdicionales: null,
    membresiaActiva: null,
    saveDir: false,
    showConfirmModal: false,
    documentosConvocatoria: null,
    visibilidadData: null,
    validations: {
        datosParticipacionCompleted: false,
        direccionEntregaCompleted: false,
        documentosCompleted: false,
        invitacionCompleted: false
    }
};

export const getDocsConvocatoria = createAsyncThunk('registroConvocatoria/getDocsConvocatoria', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getDocumentosConvocatoria(data); 
    } catch (error) {
        console.log(error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const updateEmpresaSeleccionada = createAsyncThunk('registroConvocatoria/updateEmpresaSeleccionada', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getEntidad(data); 
    } catch (error) {
        console.log(error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getConvocatorias = createAsyncThunk(
    'registroConvocatoria/obtenerConvocatorias',
    async (data, { rejectWithValue }) => {
        let resp;
        try {
            resp = await getConvocatoriasByIdEntidad(data);
        } catch (error) {
            console.error(error);
            return rejectWithValue({ success: false, ...error });
        }
        return resp;
    }
);

export const getServiciosAdicionalesCobro = createAsyncThunk(
    'registroConvocatoria/getServiciosAdicionalesCobro',
    async (data, { rejectWithValue }) => {
        let resp;
        try {
            resp = await getCobroRegistroConvocatoria(data);
        } catch (error) {
            console.error(error);
            return rejectWithValue({ success: false, ...error });
        }
        return resp;
    }
);

export const registroConvocatoriaSlice = createSlice({
    name: 'registroConvocatoria',
    initialState,
    reducers: {
        changeStep: (state, action) => {
            state.etapaRegistro = action.payload;
        },
        triggerSaveDir: (state, action) => {
            state.saveDir = action.payload;
        },
        changeView: (state, action) => {
            state.activeView = action.payload;
        },
        selectEmpresa: (state, action) => {
            state.empresaSeleccionada = action.payload;
        },
        selectConvocatoria: (state, action) => {
            state.convocatoriaSeleccionada = action.payload;
        },
        setEntidadesAdicionales: (state, action) => {
            state.entidadesAdicionales = action.payload;
        },
        setValidations: (state, action) => {
            state.validations = action.payload;
        },
        changeConfirmModal: (state, action) => {
            state.showConfirmModal = action.payload;
        },
        changeListaConvocatorias: (state, action) => {
            state.listaConvocatorias = action.payload;
        },
        setVisibilidadData: (state, action) => {
            state.visibilidadData = action.payload;
        },
        resetConvocatoria: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(
            getDocsConvocatoria.pending || 
            getConvocatorias.pending || 
            getServiciosAdicionalesCobro.pending || 
            updateEmpresaSeleccionada.pending, 
        (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            getDocsConvocatoria.rejected || 
            getConvocatorias.rejected || 
            getServiciosAdicionalesCobro.rejected || 
            updateEmpresaSeleccionada.rejected, 
        (state, action) => {
            state.isLoading = false;
            state.listaConvocatorias = [];
            state.isError = true;
            state.error = action.payload;
        });
        builder.addCase(getDocsConvocatoria.fulfilled, (state, action) => {
            state.isLoading = false;
            state.documentosConvocatoria = action.payload;
        });
        builder.addCase(getConvocatorias.fulfilled, (state, action) => {
            state.isLoading = false;
            state.listaConvocatorias = action.payload;
        });
        builder.addCase(updateEmpresaSeleccionada.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload.payload) {
                state.empresaSeleccionada = action.payload.payload
                const membresia = action.payload.payload.cemMembresiaCrcEntidads.find(member => member.idEstatusRegistro === 1);
                if (membresia) {
                    state.membresiaActiva = membresia;
                } else {
                    state.membresiaActiva = null;
                }
            } else {
                state.empresaSeleccionada = {
                    error: true
                }
            }
        })
        builder.addCase(getServiciosAdicionalesCobro.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload.payload) {
                state.serviciosAdicionales = action.payload.payload
            } else {
                state.serviciosAdicionales = {
                    error: true
                }
            }
        })
    },
});

export const { changeListaConvocatorias, changeStep, changeView, selectEmpresa, selectConvocatoria, setEntidadesAdicionales, setValidations, triggerSaveDir, changeConfirmModal, resetConvocatoria, setVisibilidadData } =
    registroConvocatoriaSlice.actions;
export const registroConvocatoriaState = (state) => state.registroConvocatoria;

export default registroConvocatoriaSlice.reducer;