export default function Counter({ handleEstatuaChange, counter }) {
    const addCount = () => {
        handleEstatuaChange('add');
    };

    const minusCount = () => {
        handleEstatuaChange('subtract');
    };

    return (
        <div className="counter-wrapper">
            <button disabled={counter === 0} onClick={minusCount}>
                -
            </button>
            <p>{counter}</p>
            <button onClick={addCount}>+</button>
        </div>
    );
}