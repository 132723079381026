export const datosContactoForm = {
    nombre: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    apellidoPaterno: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    apellidoMaterno: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    puesto: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    telefono: {
        value: '',
        required: true,
        message: 'El Teléfono debe ser de 10 digitos',
        tool: '',
        invalid: false,
    },
    celular: {
        value: '',
        required: true,
        message: 'El Celular debe ser de 10 digitos',
        tool: '',
        invalid: false,
    },
    email: {
        value: '',
        required: true,
        message: 'Email Invalido',
        tool: '',
        invalid: false,
    },
    emailConfirm: {
        value: '',
        required: true,
        message: 'Email Invalido',
        tool: '',
        invalid: false,
    },
    idTipoContactoEntidad: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    ayudo_aliado: {
        value: 'no',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    ayudo_consultor: {
        value: 'no',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    cvePais: {
        value: 'MX',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    isNotificaCorreo: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    }
}

export const aliadoFormFields = {
    idAliadoRegional: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
}

export const consultorFormFields = {
    idConsultorAcreditado: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
}