/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as EmpresaIcon } from '../../assets/icons/empresa.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import { entidadServicesState, obtenerMisEmpresas } from '../../slices/entidadServicesSlice';
import { useState } from 'react';
import { desvincularEntidadCorp, getCorporativo,  getEntidad,  postIdCorporativo, postIdMiembros, postListaCoporativos, putDesasignarEntidad } from '../../services/entidad';
import { appState, setAlert, setLoading } from '../../slices/appSlice';
import { ALERT_FAIL, ALERT_SUCCESS, CVE_TAMANIO, CVE_TIPODOC_EVIDENCIA_INSTITUCIONAL, TIPOS_GRUPO} from '../../app/constantes';
import { useEffect } from 'react';
import { catalogosServiceState, getDocumentosEvidenciaCatalogo, getEstatusCorpSede } from '../../slices/catalogosServiceSlice';
import DocumentoEvidencia from '../generales/document-evidencia';

export default function ModalRegistroCorporativo({ data, cancel }) {
    const dispatch = useDispatch();
    const entidadSt = useSelector(entidadServicesState);
    const catalogosSt = useSelector(catalogosServiceState);
    const appSt = useSelector(appState);
    const [entidadPrincipal, setEntidadPrincipal] = useState(null);
    const [idEntidadPrincipal, setIdEntidadPrincipal] = useState('');
    const [entidadPrincipalData, setEntidadPrincipalData] = useState(null);
    const [evidenciaDoc, setDoc] = useState(null);
    const [onlyView, setOnlyView] = useState(false);
    
    const [misEntidadesValidas, setEntidadesValidas] = useState([]);
    const [empresasSeleccionadas, setEmpresasSeleccionadas] = useState([]);

    /**
     * Agregamos una entidad a la lista de seleccionadas
     */
    const addEmpresa = (empresa) => {
        const clone = JSON.parse(JSON.stringify(empresasSeleccionadas));
        const clonseMisEntidades = JSON.parse(JSON.stringify(misEntidadesValidas));
        const index = clonseMisEntidades.findIndex(entidad => (entidad.id_entidad === empresa.id_entidad) || (entidad.id_entidad === empresa.idEntidad));

        clone.push(empresa);
        clonseMisEntidades.splice(index, 1);
        setEmpresasSeleccionadas(clone);
        setEntidadesValidas(clonseMisEntidades);

    }

    /**
     * Quitamos una entidad de la lista de seleccionadas
     * Si ya estaba vinculada, deshacemos la vinculacion
     */
    const removeEmpresa = async (index) => {
        dispatch(setLoading(true));
        const clone = JSON.parse(JSON.stringify(empresasSeleccionadas));
        const clonseMisEntidades = JSON.parse(JSON.stringify(misEntidadesValidas));

        try {
            await desvincularEntidadCorp({...appSt.serviceData, idEntidad: clone[index].id_entidad ? clone[index].id_entidad : clone[index].idEntidad})
            clonseMisEntidades.push(clone[index]);
            clone.splice(index, 1);
            setEmpresasSeleccionadas(clone);
            setEntidadesValidas(clonseMisEntidades);
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: 'Error al desvincular la entidad',
                type: ALERT_FAIL
            }))            
        }

    }

    /**
     * Enviamos a validar
     */
    const handleSend = async () => {
        if (empresasSeleccionadas.length >= 1) {
            dispatch(setLoading(true));
            const idsEntidades = [];
    
            empresasSeleccionadas.forEach(empresa => {
                idsEntidades.push(empresa.id_entidad ? empresa.id_entidad : empresa.idEntidad);
            });
    
            const bodyLista = {
                idEntidadPadre: entidadPrincipal.id_entidad,
                listIdEntidadHijos: idsEntidades
            }

            let respId;
            if (data.type === TIPOS_GRUPO.corp) {
                respId = await postIdCorporativo(appSt.serviceData, entidadPrincipal.id_entidad);
            } else {
                respId = await postIdMiembros(appSt.serviceData, entidadPrincipal.id_entidad);
            }
            
            const respLista = await postListaCoporativos(appSt.serviceData, bodyLista);
    
            if (respLista.payload && respId.payload) {
                const data = {
                    idUsuario: appSt.tokenInfo.sub,
                    ...appSt.serviceData
                }
                dispatch(obtenerMisEmpresas(data));
                dispatch(setAlert({
                    show: true,
                    message: 'Exito al crear corporativo',
                    type: ALERT_SUCCESS
                }))
                cancel()
                dispatch(setLoading(false));
            } else {
                dispatch(setAlert({
                    show: true,
                    message: 'Errro al crear corporativo',
                    type: ALERT_FAIL
                }))
            }
        } else {
            dispatch(setAlert({
                show: true,
                message: 'Debe seleccionar 1 entidad para crear el corporativo',
                type: ALERT_FAIL
            }))
        }

    }

    /**
     * Obtenemos los hijos del corporativo y actualizamos la lista de seleccionadas
     */
    const populateCorporativo = async () => {
        const dataService = {
            ...appSt.serviceData,
            idEntidad: entidadPrincipal.id_entidad
        }
        const resp = await getCorporativo(dataService);
        if (resp.payload) {
            let clone
            if (data.type === TIPOS_GRUPO.corp) {
                clone = entidadSt.misEmpresas.filter(ent => ent.disponible && ent.tieneMembresia === "0")
            } else {
                clone = entidadSt.misEmpresas.filter(ent => ent.disponible && ent.tieneMembresia === "1")
            }
            const filter = []
            clone.forEach(item => {
                const exist = resp.payload.find(ent => ent.idEntidad === item.id_entidad)
                if (exist === undefined) {
                    filter.push(item)
                }
            })
            setEntidadesValidas(filter)
            setEmpresasSeleccionadas(resp.payload)
        } else {
            setEmpresasSeleccionadas([])
        }
    }

    /**
     * Seleccionamos una entidad principal del select
     * @param {*} e 
     */
    const selectEntidadPrincipal = (e) => {
        const value = e.target.value;
        if (value) {
            const entidad = entidadSt.misEmpresas.find(item => Number(item.id_entidad) === Number(value));
            setEntidadPrincipal(entidad);
        } else {
            setEntidadPrincipal(null);
            setIdEntidadPrincipal("")
        }
    }

    /**
     * Obtenemos la informacion completa de la entidad principal
     */
    const getEntidadPrincipalData = async () => {
        try {
            dispatch(setLoading(true));
            const resp = await getEntidad({...appSt.serviceData, idEntidad: entidadPrincipal.id_entidad});
            setEntidadPrincipalData(resp.payload);
            dispatch(setLoading(false));            
        } catch (error) {
            console.log(error);
            dispatch(setLoading(false));            
        }
    }

    /**
     * checamos si ya tiene un documento
     */
    const checkDoc = () => {
        const evidenciaInst = catalogosSt.documentosEvidenciaInstitucional.find(
            doc => doc.cveDocumentoCemefi === CVE_TIPODOC_EVIDENCIA_INSTITUCIONAL
        );
        if (evidenciaInst) {
            const docExist = entidadPrincipalData.cemDocumentoCrcEntidads.find(
                docEnt => 
                    docEnt.idDocumentoCemefi === evidenciaInst.idDocumentoCemefi && 
                    docEnt.idEstatusRegistro !== 0
            );
            if (docExist) {
                setDoc(docExist);
            } else {
                setDoc(null)
            }
        }
    }

    /**
     * Hacemos la desvinculacion del corporativo y limpiamos el estado
     */
    const desvincularCorp = async () => {
        dispatch(setLoading(true));
        try {
            await putDesasignarEntidad({...appSt.serviceData, idEntidad: entidadPrincipalData.idEntidad})
            dispatch(setLoading(false));
            getEntidadPrincipalData()
            setEntidadPrincipalData(null)
            setEntidadPrincipal(null)
            setDoc(null)
            setIdEntidadPrincipal(null)
            cancel()
        } catch (error) {
            console.log(error);
            dispatch(setLoading(false));            
            dispatch(setAlert({
                show: true,
                message: 'Error al desasignar entidad',
                type: ALERT_FAIL
            }))
        }
    }

    const closeModal = () => {
        setEntidadesValidas(null)
        cancel()
    }

    /**
     * Checamos documento
     */
    useEffect(() => {
        if (entidadPrincipalData && catalogosSt.documentosEvidenciaInstitucional && entidadPrincipal) {
            checkDoc();
            setIdEntidadPrincipal(entidadPrincipal.id_entidad)
        } else {
            setIdEntidadPrincipal('')
        }

    }, [entidadPrincipalData, catalogosSt.documentosEvidenciaInstitucional])


    /**
     * Si la entidad principal cambia
     */
    useEffect(() => {
        if (entidadPrincipal) {
            populateCorporativo();
            getEntidadPrincipalData()
        }
        if (entidadPrincipal && catalogosSt.idsEstatusCorpSede) {
            if (
                entidadPrincipal.id_estatus_corporativo_sede === catalogosSt.idsEstatusCorpSede.enRevision || 
                entidadPrincipal.id_estatus_corporativo_sede === catalogosSt.idsEstatusCorpSede.confirmada
            ) {
                setOnlyView(true);
            } else {
                setOnlyView(false)
            }
        }
        if (!entidadPrincipal && entidadSt.misEmpresas) {
            let clone;
                if (data.type === TIPOS_GRUPO.corp) {
                    clone = entidadSt.misEmpresas.filter(ent => ent.disponible && ent.tieneMembresia === '0')
                } else {
                    clone = entidadSt.misEmpresas.filter(ent => ent.disponible && ent.tieneMembresia === '1')
                }
                setEntidadesValidas(clone);
        }

    }, [data, entidadPrincipal, catalogosSt.idsEstatusCorpSede])        

    /**
     * Filtramos las entidades disponibles
     */
    useEffect(() => {
        if (entidadSt.misEmpresas) {
            let principalExist;
            if (data.type === TIPOS_GRUPO.corp) {
                principalExist = entidadSt.misEmpresas.find(
                    entidad => entidad.is_corporativo === 1 && entidad.id_estatus_registro === 1
                );                
            } else {
                principalExist = entidadSt.misEmpresas.find(
                    entidad => entidad.is_miembro === 1 && entidad.id_estatus_registro === 1
                ); 
            }
            if (principalExist) {
                setEntidadPrincipal(principalExist);
            } else {
                let clone;
                if (data.type === TIPOS_GRUPO.corp) {
                    clone = entidadSt.misEmpresas.filter(ent => ent.disponible && ent.tieneMembresia === '0')
                } else {
                    clone = entidadSt.misEmpresas.filter(ent => ent.disponible && ent.tieneMembresia === '1')
                }
                setEntidadesValidas(clone);
            }
        }
    }, [entidadSt.misEmpresas, data])

    useEffect(() => {
        if (entidadPrincipalData) {
            const array = []
            if (entidadPrincipalData.idTamanio2.cveTamanio === CVE_TAMANIO.grande) {
                misEntidadesValidas.forEach(item => {
                    if (item.cve_tamanio === CVE_TAMANIO.grande) {
                        array.push(item)
                    }
                })
            } else {
                misEntidadesValidas.forEach(item => {
                    if (item.cve_tamanio !== CVE_TAMANIO.grande) {
                        array.push(item)
                    }
                })
            }
            setEntidadesValidas(array)
        }         
    }, [entidadPrincipalData])

    /**
     * obtenemos catalogos
     */
    useEffect(() => {
        if (appSt.serviceData) {
            dispatch(getDocumentosEvidenciaCatalogo({ ...appSt.serviceData, cveTipoDocumento: CVE_TIPODOC_EVIDENCIA_INSTITUCIONAL }))
        }
        if (appSt.serviceData && !catalogosSt.estatusCorpSede) {
            dispatch(getEstatusCorpSede(appSt.serviceData))
        }
    }, [appSt.serviceData])

    return (
        <div className={data.show ? 'modal-wrapper active' : 'modal-wrapper'}>
            <div className="modal registro-corporativo">
                {data &&
                    <>
                        <div className="head">
                            <h3>Nuevo {data.type === TIPOS_GRUPO.corp ? "Corporativo" : "Grupo Miembros"}</h3>
                            <button onClick={closeModal}>
                                x
                            </button>
                        </div>

                        <div className="content">
                            {entidadPrincipalData && (entidadPrincipalData.isCorporativo || entidadPrincipalData.isMiembro) && <>
                                <div className="dato">
                                    <label>Entidad Principal</label>
                                    <p>{entidadPrincipalData.razonSocial} - {entidadPrincipalData.idEntidad2.rfc}</p>
                                </div>
                                <button onClick={desvincularCorp} disabled={empresasSeleccionadas.length !== 0}>Cambiar Entidad Principal</button>
                            </>}
                            {(!entidadPrincipalData || (!entidadPrincipalData.isCorporativo && !entidadPrincipalData.isMiembro)) && <>
                                <div className="input-wrapper">
                                    <label>Entidad Principal</label>
                                    <select value={idEntidadPrincipal} onChange={(e) => selectEntidadPrincipal(e)}> 
                                        <option value=""></option>
                                        {misEntidadesValidas && misEntidadesValidas.map((entidad, index) => {
                                            return <option value={entidad.id_entidad} key={index}>{entidad.razon_social} - {entidad.rfc}</option>
                                        })}
                                    </select>
                                </div>                            
                            </>}
                            {entidadPrincipal &&
                                <DocumentoEvidencia entidadPrincipal={entidadPrincipal} onlyView={onlyView} evidenciaDoc={evidenciaDoc} callback={getEntidadPrincipalData}/>
                            }
                            {entidadPrincipal && <>
                                {!onlyView 
                                    ? <p>Selecciona las entidades</p>
                                    : <p>Entidades</p>
                                }
                                <div className="two-col">
                                    {!onlyView &&
                                        <div className="card-registro">
                                            <div className="head">
                                                <SearchIcon />
                                                <label>Mis Entidades</label>
                                            </div>
                                            <div className="list">

                                                {misEntidadesValidas && <ul>
                                                    {misEntidadesValidas.map((empresa, index) => {
                                                        return empresa.id_entidad !== entidadPrincipal.id_entidad && <li key={index}>
                                                            <p>{empresa.razon_social ? empresa.razon_social : empresa.razonSocial}</p>
                                                            <button onClick={() => addEmpresa(empresa)}>
                                                                <AddIcon />
                                                            </button>                                                    
                                                        </li>
                                                    })}
                                                </ul>}
                                            </div>
                                        </div>                                    
                                    }
                                    <div className="card-registro">
                                        <div className="head">
                                            <EmpresaIcon />
                                            <label>Entidades Seleccionadas</label>
                                        </div>
                                        <div className="list">
                                            <ul>
                                                {empresasSeleccionadas.map((empresa, index) => {
                                                    return <li key={index}>
                                                        <p>{empresa.razon_social ? empresa.razon_social : empresa.razonSocial}</p>
                                                        {!onlyView &&
                                                            <button onClick={() => removeEmpresa(index)}>
                                                                <DeleteIcon />
                                                            </button>
                                                        }
                                                    </li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>                            
                            </>}
                        </div>
                        <div className="actions">
                            <button onClick={closeModal}>Cancelar</button>
                            {!onlyView &&
                                <button disabled={!evidenciaDoc || empresasSeleccionadas.length === 0} onClick={() => handleSend()}>Enviar a Validar</button>
                            }
                        </div>
                    </>
                }

            </div>
        </div>
    )
}