import { CONTACTOS_COMPONENT, CUOTAS_COMPONENT, DOCUMENTOS_COMPONENT, EMPRESAS_TABLE_COMPONENT, ETAPAS_COMPONENT, PORTADA_COMPONENT, TEXT_IMAGE_COMPONENT, TEXT_SIMPLE_COMPONENT, TWO_COL_COMPONENT } from "../../app/constantes";

import ContentPreview from "./content";
import ContentImagePreview from "./contentImage";
import TwoColumnsPreview from "./twoColumns";
import EtapasPreview from "./etapas";
import TablaEmpresasPreview from "./tablaEmpresas";
import ContactosPreview from "./contactos";
import DocumentosPreview from "./documentos";
import CuotasPreview from "./cuotas";


export default function Module({ data  }) {


  return (
    <section className={`${data.layout}`}>
      {(data.layout === PORTADA_COMPONENT || data.layout === TEXT_SIMPLE_COMPONENT ) &&
        <ContentPreview data={data}/>
      }
      {data.layout === TEXT_IMAGE_COMPONENT && 
        <ContentImagePreview data={data}/>
      }
      {data.layout === TWO_COL_COMPONENT && 
        <TwoColumnsPreview data={data} />
      }
      {data.layout === ETAPAS_COMPONENT && 
        <EtapasPreview data={data} />
      }
      {data.layout === EMPRESAS_TABLE_COMPONENT && 
        <TablaEmpresasPreview data={data} />
      }
      {data.layout === CONTACTOS_COMPONENT && 
        <ContactosPreview data={data} />
      }
      {data.layout === DOCUMENTOS_COMPONENT && 
        <DocumentosPreview data={data} />
      }
      {data.layout === CUOTAS_COMPONENT && 
        <CuotasPreview data={data} />
      }
    </section>
  );
}