import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modalUsuario: {
        show: false,
        data: null
    },
    modalVinculacion: {
        show: false,
        data: null
    }
}

export const usuariosSlice = createSlice({
    name: 'usuarios',
    initialState,
    reducers: {
        setModalUser: (state, action) => {
            state.modalUsuario = action.payload
        },
        setModalVinculacion: (state, action) => {
            state.modalVinculacion = action.payload
        },
    }
})

export const { setModalUser, setModalVinculacion } = usuariosSlice.actions;
export const usuariosState = (state) => state.usuarios;

export default usuariosSlice.reducer;