/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';

import {
    DATOS_PARTICIPACION_STEP,
    DOCUMENTOS_STEP,
    DIRECCION_ENTREGA_STEP,
    CONVOCATORIAS_DISPONIBLES,
    SERVICIO_ADICIONALES_STEP,
    CVE_TIPODOC_CONVOCATORIA,
    INVITACIONES_STEP,
    IS_INVITACION,
    MEMBRESIA_STEP,
    CONVOCATORIAS_ID,
    LOGO_EMPRESA,
    CVE_DIRECCION,
} from '../app/constantes';

import { ReactComponent as EmpresaIcon } from '../assets/icons/empresa.svg';
import { ReactComponent as ParticipacionIcon } from '../assets/icons/participacion.svg';
import { ReactComponent as DocumentosIcon } from '../assets/icons/download.svg';
import { ReactComponent as BackIcon } from '../assets/icons/back.svg';
import { ReactComponent as PayIcon } from '../assets/icons/pay.svg';
import { ReactComponent as MembresiaIcon } from '../assets/icons/membresia.svg';

import Documentos from '../components/registro/documentos';

import '../styles/registro.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import {
    registroConvocatoriaState,
    changeStep,
    changeView,
    setEntidadesAdicionales,
    selectConvocatoria,
    getDocsConvocatoria,
    setValidations,
    changeListaConvocatorias,
} from '../slices/registroConvocatoriaSlice';
import DireccionEntrega from '../components/registro/direccion-entrega';
import DatosParticipacion from '../components/registro-convocatoria/datos-participacion';
import ServiciosAdicionales from '../components/registro-convocatoria/serviciosAdicionales';
import DualListSelector from '../components/registro-convocatoria/datos-participacion/DualListSelector';
import MembresiasConvocatoria from '../components/registro-convocatoria/membresia';
import { catalogosServiceState, getDocumentosCatalogo, getRegistroConvocatorias, getTipoDireccionCatalogo } from '../slices/catalogosServiceSlice';
import { appState, changeLogo, setLoading } from '../slices/appSlice';
import { isConvocatoriaInsignia, validaConvocatoriaDoc } from '../app/commons';
import { usuariosServicesState } from "../slices/usuariosServicesSlice";
import { getEntidadRegistroConvocatoriaById } from '../services/convocatorias';

export default function RegistroConvocatoria() {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const registroSt = useSelector(registroConvocatoriaState);
    const catalogosSt = useSelector(catalogosServiceState);
    const usuariosSt = useSelector(usuariosServicesState);
    const [stepStatus, setStepStatus] = useState(null);

    const selectedEntity = registroSt.empresaSeleccionada;
    const [progress, setProgress] = useState(0);

    const goToStep = (step) => {
        dispatch(changeStep(step));
    };

    const closeNewEntry = () => {
        dispatch(changeLogo(LOGO_EMPRESA.cemefi));
        dispatch(selectConvocatoria(null));
        dispatch(changeListaConvocatorias(null))
        dispatch(setEntidadesAdicionales(null));
        dispatch(changeView(CONVOCATORIAS_DISPONIBLES));
        dispatch(changeStep(DATOS_PARTICIPACION_STEP));
    };

    const invitacionValid = async (convocatoria) => {
        let valid = false;
        dispatch(setLoading(true));
        if (convocatoria.isInvitacion && convocatoria.minimoInvitacion) {
            const data = {
                ...appSt.serviceData,
                idRegistroConvocatoria:
                    convocatoria.idRegistroConvocatoria,
            };
            const resp = await getEntidadRegistroConvocatoriaById(data);
            if (resp && resp.length >= convocatoria.minimoInvitacion) {
                valid = true;
            }
        } else {
            valid = true;
        }
        dispatch(setLoading(false));
        return valid
    }

    const calculateProgress = async () => {
        let progress = 0;
        let total = 0;
        let valid = 0;
        let count = 0;
        if (registroSt.empresaSeleccionada) {
            const entregaDir = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.entrega);
            if (!entregaDir) {
                return console.log('sin catalogos', catalogosSt.tipoDireccion);
            }
            const validations = {
                datosParticipacionCompleted: registroSt.convocatoriaSeleccionada.idRegistroConvocatoria !== null,
                invitacionCompleted: await invitacionValid(registroSt.convocatoriaSeleccionada),
                direccionEntregaCompleted: 
                    (registroSt.empresaSeleccionada.cemDireccionEntidads.find(dir => dir.idTipoDireccion === entregaDir.idTipoDireccion) || 
                    isConvocatoriaInsignia(registroSt.convocatoriaSeleccionada)),
                documentosCompleted: validaConvocatoriaDoc(catalogosSt.documentosRegistroConvocatoria, registroSt.documentosConvocatoria)
            }
    
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(validations)) {
            count = count + 1;
            if (value) {
              valid = valid + 1;
            }
          }
    
          total = count;
    
          progress = (100 / total) * valid;
          dispatch(setValidations(validations));
        }
        setProgress(progress);
      }

    useEffect(() => {
        if (
            registroSt.informacionGeneralMissing !== null &&
            registroSt.informacionGeneralMissing > 0
        ) {
            setStepStatus('incomplete');
        } else if (
            registroSt.informacionGeneralMissing !== null &&
            registroSt.informacionGeneralMissing === 0
        ) {
            setStepStatus('completed');
        }
    }, [registroSt.informacionGeneralMissing]);

    useEffect(() => {
        calculateProgress();
        if (usuariosSt.usuario && usuariosSt.usuario.data) {
            if (catalogosSt.parametrosConvocatoria === null && !catalogosSt.loading) {
                dispatch(getRegistroConvocatorias(appSt.serviceData))
            }
            if (catalogosSt.tipoDireccion === null && !catalogosSt.loading) {
                dispatch(getTipoDireccionCatalogo(appSt.serviceData))
            }
        }
    }, [registroSt.etapaRegistro, catalogosSt.documentosRegistroConvocatoria, registroSt.documentosConvocatoria, registroSt.empresaSeleccionada]);

    useEffect(() => {
        const data = {
            ...appSt.serviceData,
            cveTipoDocumento: CVE_TIPODOC_CONVOCATORIA,
        };        
        dispatch(getDocumentosCatalogo(data));
        dispatch(getDocsConvocatoria({
            ...appSt.serviceData, 
            idRegistroConvocatoria: registroSt.convocatoriaSeleccionada.idRegistroConvocatoria
        }))
    }, [registroSt.convocatoriaSeleccionada])

    return (
        <main className="registro">
            <div className="registro-convocatoria-head">
                <button onClick={closeNewEntry}>
                    <BackIcon />
                </button>
                <h2>{selectedEntity.nombreComercial}</h2>
            </div>
            <div className="steper">
                <div
                    onClick={() => goToStep(DATOS_PARTICIPACION_STEP)}
                    className={
                        registroSt.etapaRegistro === DATOS_PARTICIPACION_STEP
                            ? 'step active'
                            : 'step'
                    }>
                    <div className={`icon ${registroSt.validations.datosParticipacionCompleted ? 'completed' : 'incomplete'}`}>
                        <ParticipacionIcon />
                    </div>
                    <h2>Datos de Participación</h2>
                </div>
                {/* {registroSt.convocatoriaSeleccionada.isInvitacion ===
                    IS_INVITACION.TRUE && (
                    <button
                        disabled={
                            !registroSt.convocatoriaSeleccionada
                                .idRegistroConvocatoria
                        }
                        onClick={() => goToStep(INVITACIONES_STEP)}
                        className={
                            registroSt.etapaRegistro === INVITACIONES_STEP
                                ? 'step active'
                                : 'step'
                        }>
                        <div className={`icon ${registroSt.validations.invitacionCompleted ? 'completed' : 'incomplete'}`}>
                            <EmpresaIcon />
                        </div>
                        <h2>Invitaciones</h2>
                    </button>
                )} */}

                <button
                    disabled={
                        !registroSt.convocatoriaSeleccionada
                            .idRegistroConvocatoria
                    }
                    onClick={() => goToStep(SERVICIO_ADICIONALES_STEP)}
                    className={
                        registroSt.etapaRegistro === SERVICIO_ADICIONALES_STEP
                            ? 'step active'
                            : 'step'
                    }>
                    <div className="icon">
                        <PayIcon />
                    </div>
                    <h2>Orden de Pago</h2>
                </button>

                {registroSt.convocatoriaSeleccionada.idConvocatoria !== CONVOCATORIAS_ID.insigniaInclusionSocial  && registroSt.convocatoriaSeleccionada.idConvocatoria !== CONVOCATORIAS_ID.insigniaInversionSocial && 
                    <button
                        disabled={
                            !registroSt.convocatoriaSeleccionada
                                .idRegistroConvocatoria
                        }
                        onClick={() => goToStep(DIRECCION_ENTREGA_STEP)}
                        className={
                            registroSt.etapaRegistro === DIRECCION_ENTREGA_STEP
                                ? 'step active'
                                : 'step'
                        }>
                        <div className={`icon ${registroSt.validations.direccionEntregaCompleted ? 'completed' : 'incomplete'}`}>
                            <EmpresaIcon />
                        </div>
                        <h2>Dirección de Entrega</h2>
                    </button>
                }


                {registroSt.membresiaActiva && registroSt.empresaSeleccionada && !registroSt.empresaSeleccionada.isMiembro && appSt.serviceData.area === 1 &&
                    <button
                        disabled={
                            !registroSt.convocatoriaSeleccionada
                                .idRegistroConvocatoria
                        }
                        onClick={() => goToStep(MEMBRESIA_STEP)}
                        className={
                            registroSt.etapaRegistro === MEMBRESIA_STEP
                                ? 'step active'
                                : 'step'
                        }>
                        <div className="icon">
                            <MembresiaIcon />
                        </div>
                        <h2>Membresía</h2>
                    </button>                
                }


                <button
                    disabled={
                        !registroSt.convocatoriaSeleccionada
                            .idRegistroConvocatoria
                    }
                    onClick={() => goToStep(DOCUMENTOS_STEP)}
                    className={
                        registroSt.etapaRegistro === DOCUMENTOS_STEP
                            ? 'step active'
                            : 'step'
                    }>
                    <div className={`icon ${registroSt.validations.documentosCompleted ? 'completed' : 'incomplete'}`}>
                        <DocumentosIcon />
                    </div>
                    <h2>Documentos</h2>
                </button>
            </div>

            <div className="step-content">
                {registroSt.etapaRegistro === DATOS_PARTICIPACION_STEP && (
                    <DatosParticipacion />
                )}
                {registroSt.etapaRegistro === INVITACIONES_STEP && (
                    <DualListSelector />
                )}
                {registroSt.etapaRegistro === SERVICIO_ADICIONALES_STEP && (
                    <ServiciosAdicionales />
                )}
                {registroSt.etapaRegistro === DIRECCION_ENTREGA_STEP && (
                    <DireccionEntrega calculateProgress={calculateProgress} />
                )}
                {registroSt.etapaRegistro === MEMBRESIA_STEP && (
                    <MembresiasConvocatoria />
                )}
                {registroSt.etapaRegistro === DOCUMENTOS_STEP && (
                    <Documentos empresaSeleccionada={registroSt.empresaSeleccionada} type={CVE_TIPODOC_CONVOCATORIA} goBack={closeNewEntry} convocatoriaSeleccionada={registroSt.convocatoriaSeleccionada} entidadesAdicionales={registroSt.entidadesAdicionales}/>
                )}
            </div>
        </main>
    );
}
