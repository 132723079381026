import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert } from "../../slices/appSlice";

import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ALERT_FAIL, ALERT_SUCCESS, ALERT_WARN } from "../../app/constantes";
import { useEffect } from "react";


export default function Alert() {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);

    useEffect(() => {
        if (appSt.alert.show) {
            setTimeout(() => {
                dispatch(setAlert({
                    ...appSt.alert,
                    show: false,                    
                }))
            }, 6000)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSt.alert.show])

    return (
        <div className={`alert ${appSt.alert.show ? 'active' : ''} ${appSt.alert.type}`}>
            {appSt.alert.type === ALERT_SUCCESS && <CheckIcon />}
            {appSt.alert.type === ALERT_WARN && <InfoIcon />}
            {appSt.alert.type === ALERT_FAIL && <CancelIcon />}

            <p>{appSt.alert.message}</p>
        </div>
    )
}