export const DatosBasicosForm = {
    cveEstatusConsultorAcreditado: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    genero: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    fechaNacimiento: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    correoPersonal: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    correoProfesional: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    ultimoGradoEstudio: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    fechaInicioAcreditacion: {
        value: '',
        required: false,
        message: 'Email Invalido',
        tool: '',
        invalid: false,
    },
    fechaFinAcreditacion: {
        value: '',
        required: false,
        message: 'Email Invalido',
        tool: '',
        invalid: false,
    },
    fechaInhabilitacion: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    aniosConsultor: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    fechaInvitacion: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    telefonoFijo: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    extension: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    telefonoMovil: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
}

export const AreasExperienciaForm = {
    cveCriterio: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    cveAmbito: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    cveSubambito: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    cveTamanio: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
}


export const CatalogoGenero = [
    {
        label: "MASCULINO",
        value: "MASCULINO"
    },
    {
        label: "FEMENINO",
        value: "FEMENINO"
    },
    {
        label: "BINARIO",
        value: "BINARIO"
    }
]