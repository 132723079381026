/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { appState, setAlert, setLoading } from '../../slices/appSlice';
import { ALERT_FAIL } from '../../app/constantes';
import { postCambioEstatusEntidad } from '../../services/entidad';
import { catalogosServiceState } from '../../slices/catalogosServiceSlice';
import { useNavigate } from 'react-router-dom';
import { registroConvocatoriaState } from '../../slices/registroConvocatoriaSlice';
import { useEffect } from 'react';


export default function ModalActualizarEntidad({ modalData, setModal, goBack }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appSt = useSelector(appState);
    const catalgosSt = useSelector(catalogosServiceState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);


    const closeModal = async () => {
        dispatch(setLoading(true))
        try {
            await postCambioEstatusEntidad({
                ...appSt.serviceData,
                idEntidad: modalData.data.idEntidad
            }, {
                idEstatusEntidad: catalgosSt.idsEstatusEntidad.registrada
            })
            console.log(registroConvocatoriaSt.empresaSeleccionada.idsHijos.length > 0)
            if (registroConvocatoriaSt.empresaSeleccionada && registroConvocatoriaSt.empresaSeleccionada.idsHijos.length > 0) {
                const promises = []
                registroConvocatoriaSt.empresaSeleccionada.idsHijos.forEach(item => {
                    promises.push(postCambioEstatusEntidad({
                        ...appSt.serviceData,
                        idEntidad: item
                    }, {idEstatusEntidad: catalgosSt.idsEstatusEntidad.registrada}))
                })
                await Promise.allSettled(promises)
            }
            setModal({
                show: false,
                data: null
            })
            dispatch(setLoading(false))   
            setTimeout(() => {
                goBack()
                navigate('/mis-entidades');
            }, 500)     
        } catch (error) {
            console.log(error)
            dispatch(setAlert({
                show: true,
                message: "Error al actualizar estatus de la entidad",
                type: ALERT_FAIL
            }))
            dispatch(setLoading(false))                
        }
    }

    useEffect(() => {
        console.log(modalData, registroConvocatoriaSt)
    }, [modalData, registroConvocatoriaSt.convocatoriaSeleccionada])
    
    return (
      <div className={modalData.show ? 'modal-wrapper active' : 'modal-wrapper'}>
        <div className="modal vinculacion-entidad">
            <div className="head">
                <h3>Actualizar Entidad</h3>
                <button onClick={closeModal}>
                    x
                </button>
            </div>
            <div className="content">
                <p>Favor de actualizar los datos del Registro de la Entidad.</p>
                <p>Nota: Las solicitudes de edición posteriores a la validación del registro sólo podrán solicitarse al correo distintivo@cemefi.org</p>
            </div>

            <div className="actions">
                <button onClick={closeModal}>Cerrar</button>
            </div>
        </div>
      </div>
    );
  }