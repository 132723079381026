/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { ALERT_FAIL, ALERT_SUCCESS, ALERT_WARN, CVE_DIRECCION,  MEXICO_ID } from "../../app/constantes";
import { direccionEntregaForm } from "../../app/forms/registro/direccion-entrega";
import DireccionComponent from "../generales/direccionComponent";

import { ReactComponent as EmpresaIcon } from '../../assets/icons/empresa.svg';
import { useEffect } from "react";
import { formatForm, validateForm } from "../../app/commons";
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { getEntidad, postDireccionEntidad, putDireccionEntidad } from "../../services/entidad";
import { changeConfirmModal, registroConvocatoriaState, setEntidadesAdicionales, triggerSaveDir, updateEmpresaSeleccionada } from "../../slices/registroConvocatoriaSlice";
import { catalogosServiceState, getTipoDireccionCatalogo } from "../../slices/catalogosServiceSlice";

export default function DireccionEntregaAdicional({ empresa, adicional }) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const catalogosSt = useSelector(catalogosServiceState);
    const [sameDir, setSameDir] = useState(true);
    const [triggerSetCorrespondencia, setCorrespondencia] = useState(false);
    const [dirEntrega, setDirEntrega] = useState(null);
    const [form, setForm] = useState(JSON.parse(JSON.stringify(direccionEntregaForm)));

    const saveDir = async () => {
        const valid = validateForm(form);
        
        if (valid.length === 0) {
            dispatch(setLoading(true));
            const formatedForm = formatForm(form);
            let body;
            const dirValue = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.entrega);
            if (dirValue) {
                const prebody = {
                    idPais: formatedForm.idPais,
                    calle: formatedForm.calle,
                    numeroExterior: formatedForm.numeroExterior,
                    numeroInterior: formatedForm.numeroInterior,
                    idEntidad: empresa.idEntidad,
                    idTipoDireccion: dirValue.idTipoDireccion
                }
    
                if (formatedForm.idPais === MEXICO_ID || formatedForm.idPais === Number(MEXICO_ID)) {
                    body = {
                        ...prebody,
                        idCodigoPostal: formatedForm.idCodigoPostal,
                    }
                } else {
                    body = {
                        ...prebody,
                        idCodigoPostal: null,
                        codigoPostal: formatedForm.codigoPostal,
                        ciudad: formatedForm.ciudad,
                        estado: formatedForm.estado,
                        colonia: formatedForm.colonia,
                        municipioAlcaldia: formatedForm.municipioAlcaldia
                    }
                }
    
                let resp;
                const filt = empresa.cemDireccionEntidads.filter(dir => dir.idTipoDireccion === dirValue.idTipoDireccion);
    
                if (filt.length > 0) {
                    const putData = {
                        ...appSt.serviceData,
                        idDireccion: filt[0].idDireccionEntidad
                    }
                    resp = await putDireccionEntidad(putData, body);
                } else {
                    resp = await postDireccionEntidad(appSt.serviceData, body);
                }
    
                if (resp.payload) {
                    dispatch(setLoading(false));
                    dispatch(setAlert({
                        show: true,
                        message: 'Exito al guardar la información',
                        type: ALERT_SUCCESS
                    }))
                    if (!adicional) {
                        const convData = {
                            ...appSt.serviceData,
                            idEntidad: empresa.idEntidad,
                        };
                        dispatch(updateEmpresaSeleccionada(convData));
                    } else {
                        updateEmpresasAdicional()
                    }
                    dispatch(triggerSaveDir(false));
    
                } else {
                    dispatch(setLoading(false));
                    dispatch(setAlert({
                        show: true,
                        message: 'Error al guardar la información',
                        type: ALERT_FAIL
                    }))
                }            
            } else {
                console.log('no hay direccion de entrega en los catalogos', catalogosSt.tipoDireccion)
            }

        } else {
            dispatch(changeConfirmModal(true));
            dispatch(setAlert({
                show: true,
                message: 'Favor de llenar todos los campos',
                type: ALERT_WARN
            }));
        }
    }

    const updateEmpresasAdicional = async () => {
        const entidadesAdicionalesActuales = JSON.parse(JSON.stringify(registroConvocatoriaSt.entidadesAdicionales));
        const entidadActual = registroConvocatoriaSt.entidadesAdicionales.find(ent => {
            return ent.idEntidad === empresa.idEntidad
        });
        const entidadActualIndex = registroConvocatoriaSt.entidadesAdicionales.findIndex(entity => entity.idEntidad === empresa.idEntidad);
        const newInfo = await getEntidad({...appSt.serviceData, idEntidad: entidadActual.idEntidad});
        entidadesAdicionalesActuales[entidadActualIndex] = newInfo.payload;

        dispatch(setEntidadesAdicionales(entidadesAdicionalesActuales));

    }

    const changeDir = (value) => {
        setSameDir(value);
        setCorrespondencia(value)
    }

    useEffect(() => {
        if (!sameDir) {
            setForm(JSON.parse(JSON.stringify(direccionEntregaForm)))
        } 
    }, [sameDir, triggerSetCorrespondencia])

    useEffect(() => {
        if (registroConvocatoriaSt.saveDir) {
            saveDir();
        }
    }, [registroConvocatoriaSt.saveDir])

    useEffect(() => {
        if (empresa.cemDireccionEntidads.length > 0 && catalogosSt.tipoDireccion) {
            const entregaId = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.entrega);
            const correspondenciaId = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.correspondencia);
            const dirEntrega = empresa.cemDireccionEntidads.find(emp => emp.idTipoDireccion === entregaId.idTipoDireccion);
            if (entregaId) {
                const dirCorrespondencia = empresa.cemDireccionEntidads.find(emp => emp.idTipoDireccion === correspondenciaId.idTipoDireccion);
                
                if (dirEntrega && dirCorrespondencia) {
                    if ((dirEntrega.idCodigoPostal === dirCorrespondencia.idCodigoPostal) || (dirEntrega.codigoPostal !== null && dirEntrega.codigoPostal === dirCorrespondencia.codigoPostal)) {
                        setDirEntrega(true);
                        setSameDir(true);
                    } else {
                        setDirEntrega(true);
                        setSameDir(false);
                    }
                } else {
                    setDirEntrega(false);
                    changeDir(true);
                }
            } else {
                console.log('no hay direccion de correspondencia en los catalogos', catalogosSt.tipoDireccion)
            }
        } else {
            changeDir(true);
        }
    }, [empresa, catalogosSt.tipoDireccion])

    useEffect(() => {
        if (catalogosSt.tipoDireccion === null && !catalogosSt.loading) {
            dispatch(getTipoDireccionCatalogo(appSt.serviceData))
        }
    }, [])

    return <div className="card-registro">
        <div className="head">
            <EmpresaIcon />
            <h3>Entidad: {empresa.nombreComercial}</h3>
        </div>
        <div className="content">
            <div className="radio-wrapper">
                <label>¿La dirección de entrega es la misma que la de correspondencia?</label>
                <div className="radios">
                    <div className="radio-input">
                        <input checked={sameDir} value='si' type="radio" onChange={(event) => changeDir(event.target.checked)} />
                        <div className="check"></div>
                        <label>Sí</label>
                    </div>
                    <div className="radio-input">
                        <input checked={!sameDir} value='no' type="radio" onChange={(event) => changeDir(!event.target.checked)} />
                        <div className="check"></div>
                        <label>No</label>
                    </div>
                </div>
            </div>
        </div>
        {dirEntrega !== null && 
            <DireccionComponent empresaSeleccionada={empresa} cveDireccion={CVE_DIRECCION.entrega} form={form} setForm={setForm} setCorrespondencia={triggerSetCorrespondencia} setDirEntrega={dirEntrega}/>        
        }
        {/* <div className="actions">
            <button onClick={saveDir}>Guardar</button>
        </div> */}
    </div>
}