export const postRequest = async (url, token, body) => {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
        },
        body: JSON.stringify(body)
    }

    const resp = await fetch(url, options);
    const respJson = await resp.json();

    return respJson;
}

export const postRequestPublic = async (url, body) => {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    }

    const resp = await fetch(url, options);
    const respJson = await resp.json();

    return respJson;
}

export const postFileRequest = async (url, token, body, type) => {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
        },
        body: JSON.stringify(body)
    }
    
    const resp = await fetch(url, options);
    const respJson = await resp.blob();

    return respJson;
}

export const getRequest = async (url, token) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
        },
    }

    const resp = await fetch(url, options);
    const respJson = await resp.json();

    return respJson;
}

export const getRequestPublic = async (url) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }

    const resp = await fetch(url, options);
    const respJson = await resp.json();

    return respJson;
}

export const getFileRequest = async (url, token, type) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': type ? type : 'application/pdf',
            'Authorization': token ? `Bearer ${token}` : '',
        },
    }

    const resp = await fetch(url, options);
    const respJson = await resp.blob();

    return respJson;
}

export const putRequest = async (url, token, body) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
        },
        body: JSON.stringify(body)
    }

    const resp = await fetch(url, options);
    const respJson = await resp.json();

    return respJson;
}

export const deleteRequest = async (url, token) => {
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
        }
    }
    const resp = await fetch(url, options);
    const respJson = await resp.json();

    return respJson;
}