/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import InputComp from "../generales/inputComponent";
import { useDispatch, useSelector } from "react-redux";
import { catalogosServiceState, getEsquemaCobroCatalogo } from "../../slices/catalogosServiceSlice";
import { findCatalogById, formatForm, getMontosRegalias, validateForm } from "../../app/commons";
import { appState, setAlert } from "../../slices/appSlice";
import { ALERT_FAIL, CVE_DIRECCION, MEXICO_ID } from "../../app/constantes";
import { pagosState } from "../../slices/pagosSlice";
import { getEntidad, putDireccionEntidad } from "../../services/entidad";
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import { datosFiscalesForm } from "../../app/forms/cobros/datosFiscales";
import DireccionComponent from "../generales/direccionComponent";
import { direccionForm } from "../../app/forms/registro/direccion";
import { getProgramaConvocatoria } from "../../services/convocatorias";
import { getCatalogos, getFormasPagosDinamico, getMessageByModulo, getTips } from "../../services/catalogos";

export default function DatosFacturacion({ type, callback, cancel, hidden }) {
  const dispatch = useDispatch();
  const catalogosSt = useSelector(catalogosServiceState);
  const pagosSt = useSelector(pagosState);
  const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
  const appSt = useSelector(appState);
  const [form, setForm] = useState(JSON.parse(JSON.stringify(datosFiscalesForm)));
  const [registros, setRegistros] = useState(null);
  const [formDireccion, setFormDireccion] = useState(JSON.parse(JSON.stringify(direccionForm)));
  const [catalogoFormaPago, setCatalogoFormaPago] = useState(null)
  const [disabledSubmit, setDisable] = useState(false);
  const [tooltip, setTootltip] = useState(null);
  // Modal confirmar
  const [isShowModalConfirmar, setIsShowModalConfirmar] = useState(false);


  const handleCancel = () => {
    setForm(JSON.parse(JSON.stringify(datosFiscalesForm)))
    setFormDireccion(JSON.parse(JSON.stringify(direccionForm)))
    cancel()
  }

  /**
   * Guardamos la direccion
   */
  const saveDireccion = async () => {
    try {
      const formatedForm = formatForm(formDireccion);
      let body = {
        idPais: formatedForm.idPais,
        calle: formatedForm.calle,
        numeroExterior: formatedForm.numeroExterior,
        numeroInterior: formatedForm.numeroInterior,
        idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad,
        idTipoDireccion: catalogosSt.idsTiposDireccion.fiscal
      }
      if (formatedForm.idPais === MEXICO_ID || formatedForm.idPais === Number(MEXICO_ID)) {
        body = {
          ...body,
          idCodigoPostal: formatedForm.idCodigoPostal,
        }
      } else {
        body = {
          ...body,
          idCodigoPostal: null,
          codigoPostal: formatedForm.codigoPostal.length === 4 ? `0${formatedForm.codigoPostal}` : formatedForm.codigoPostal,
          ciudad: formatedForm.ciudad,
          estado: formatedForm.estado,
          colonia: formatedForm.colonia,
          municipioAlcaldia: formatedForm.municipioAlcaldia
        }
      }
      const filt = registroConvocatoriaSt.empresaSeleccionada.cemDireccionEntidads.find(dir => dir.idTipoDireccion === catalogosSt.idsTiposDireccion.fiscal);
      if (filt) {
        const putData = {
          ...appSt.serviceData,
          idDireccion: filt.idDireccionEntidad
        }
        await putDireccionEntidad(putData, body);
      }
    } catch (error) {
      console.log(error)
      throw new Error('Error al guardar dirección')
    }
  }

  /**
   * Formateamos el concepto de la cuota
   */
  const formatCuota = async (item, array) => {
    const programa = await getProgramaConvocatoria({ ...appSt.serviceData, idRegistroConvocatoria: item.idRegistroConvocatoria })
    if (programa) {
      let montoRegalia
      let montoCouta
      const clone = JSON.parse(JSON.stringify(item))
      //Caso 1 solo se envia couta con montoCobro
      if (programa.montoParticipacion === item.montoCobro) {
        montoRegalia = item.montoCobro
        montoCouta = item.montoCobro
        array.push({
          ...clone, montoCobro: montoCouta
        })
      }
      // Caso 2 si el monto es menor q la regalia se envia solo regalia con el montoCobro
      if (programa.montoParticipacion > item.montoCobro) {
        montoRegalia = item.montoCobro
        montoCouta = item.montoCobro
        array.push({
          ...clone,
          descCobroRegistroConvocatoria: "REGALÍA POR LA LICENCIA DE USO NO EXCLUSIVO DE DISTINTIVO ESR®",
          montoCobro: montoCouta,
          claveProdServRegalias: programa.claveProdServRegalias,
          isRegalia: true,
        })
      }
      //Caso 3 se queda igual
      if (programa.montoParticipacion < item.montoCobro) {
        montoCouta = programa.montoParticipacion
        montoRegalia = item.montoCobro - programa.montoParticipacion
        array.push({
          ...clone, montoCobro: montoCouta
        })
        array.push({
          ...clone,
          descCobroRegistroConvocatoria: "REGALÍA POR LA LICENCIA DE USO NO EXCLUSIVO DE DISTINTIVO ESR®",
          montoCobro: montoRegalia,
          claveProdServRegalias: programa.claveProdServRegalias,
          isRegalia: true,
        })
      }
      return array;
    } else {
      throw new Error("No existe monto de participación")
      array.push(item)
    }
  }

  /**
   * Generamos los conceptos q se enviaran a facturar
   */
  const formatArray = async () => {
    try {
      let array = [];
      if (type) {
        switch (type) {
          case "normal":
            for (const item of pagosSt.pagoDetails.cobroRegistroConvocatoriaDet) {
              if (
                item.idEstatusCobro === catalogosSt.idsEstatusCobro.confirmado &&
                item.cobroAplicado.facAnticipada !== 1 && !item.descCobroRegistroConvocatoria.includes("CUOTA")
              ) {
                array.push(item)
              }
              if (
                item.idEstatusCobro === catalogosSt.idsEstatusCobro.confirmado &&
                item.cobroAplicado.facAnticipada !== 1 && item.descCobroRegistroConvocatoria.includes("CUOTA")
              ) {
                await formatCuota(item, array);
              }
            }          
            break;
  
          case "anticipada":
          case "enLinea":
            for (const item of pagosSt.pagoDetails.cobroRegistroConvocatoriaDet) {
              if (
                item.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado &&
                item.cobroAplicado.facAnticipada !== 1 && !item.descCobroRegistroConvocatoria.includes("CUOTA")
              ) {
                array.push(item)
              }
              if (
                item.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado &&
                item.cobroAplicado.facAnticipada !== 1 && item.descCobroRegistroConvocatoria.includes("CUOTA")
              ) {
                await formatCuota(item, array);
              }
      
            }
            break;
          default:
            break;
        }
      } else {
      }
      return array      
    } catch (error) {
      throw new Error("No existe monto de participación")
    }
  }

  const formatPagos = (items, type) => {
    let body
    const detalles = []
    if (items && items.length > 0) {
      const formatDir = formatForm(formDireccion)
      items.forEach(item => {
        detalles.push({
          idCobroRegistroConvocatoria: item.idCobroRegistroConvocatoria,
          idCobroAplicado: item.cobroAplicado.idCobroAplicado,
          cantidad: item.cantidad,
          unidadMedida: "SERVICIO",
          descripcion: form.folioOdc.value ? `${item.descCobroRegistroConvocatoria} OC ${form.folioOdc.value}` : item.descCobroRegistroConvocatoria,
          valorUnitario: item.montoCobro,
          cveProdServ: item.claveProdServRegalias ? item.claveProdServRegalias : item.idConceptoCobroConvocatoria2.idConceptoCobro2.claveProdServ,
          isRegalia: item.isRegalia ? item.isRegalia : false,
          cveUnidadMedida: "E48",
          objetoImpuesto: "02"
        })
      })
  
      body = {
        idAreaCemefi: appSt.serviceData.area,
        // idAreaCemefi: 1,
        idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad,
        // idEntidad: 1935,
        folioOdc: form.folioOdc.value ? form.folioOdc.value : null,
        formaPago: type === 'online' ? form.cveFormaPago.value : '99',
        condicionesPago: "30 Dias",
        metodoPago: type === 'online' ? 'PUE' : 'PPD',
        // metodoPago: "PPD",
        receptor: {
          usoCfdi: form.cveUsoCfdi.value,
          regimenFiscal: findCatalogById(Number(form.idRegimenFiscal.value), catalogosSt.regimen, 'idRegimenFiscal', 'cveRegimenFiscal'),
          rfc: form.rfc.value,
          razonSocial: form.razonSocial.value,
          calle: formatDir.calle,
          noExterior: formatDir.numeroExterior,
          colonia: formatDir.colonia,
          municipio: formatDir.municipioAlcaldia,
          estado: formatDir.estado,
          pais: "MEXICO",
          codigoPostal: formatDir.codigoPostal.length === 4 ? `0${formatDir.codigoPostal}` : formatDir.codigoPostal,
          contactoEmail: "prueba@prueba.com.mx"
        },
        detalles
      }
    }

    return items && items.length > 0 ? {...body, detalles} : null
  }

  /**
   * Solicitamos factura
   */
  const handleSubmit = async () => {
    setIsShowModalConfirmar(false);
    setDisable(true)
    const valid = validateForm(form);
    const validDic = validateForm(formDireccion);
    if ((valid.length === 0 && validDic.length === 0 && registros) || hidden) {
      try {
        const pagosOnline = registros.filter(item => {
          return (!item.idEsquemaCobro && type !== 'anticipada') || 
          (item.idEsquemaCobro && item.idEsquemaCobro === catalogosSt.idsEsquemaCobro.online)
        })
        const pagosOffline = registros.filter(item => {
          return (!item.idEsquemaCobro && type === 'anticipada') || 
          (item.idEsquemaCobro && item.idEsquemaCobro === catalogosSt.idsEsquemaCobro.offline)
        })

        const PUE = formatPagos(pagosOnline, 'online')
        const PPD = formatPagos(pagosOffline, 'offline')
        
        await saveDireccion()
        
        callback({ 
          online: {
            data: PUE, 
            registros: pagosOnline
          }, 
          offline: {
            data: PPD, 
            registros: pagosOffline
          } })
        setDisable(false)
      } catch (error) {
        setDisable(false)
        console.log(error)
        dispatch(setAlert({
          show: true,
          message: error.payload,
          type: ALERT_FAIL
        }))
      }

    } else {
      setDisable(false)
      dispatch(setAlert({
        show: true,
        message: 'Favor de llenar todos los campos requeridos',
        type: ALERT_FAIL
      }))
    }
  }

  const getCatalogoFormaPago = async (type) => {
    try {
      const resp = await getFormasPagosDinamico({
        ...appSt.serviceData,
        tipoConsulta: type
      })
      setCatalogoFormaPago(resp.payload)      
    } catch (error) {
      console.log(error)
      dispatch(setAlert({
        show: true,
        message: "Error al consultar catalogo",
        type: ALERT_FAIL
      }))
    }
  }

  const populateForm = async () => {
    try {
      if (type) {
        const data = await getEntidad({ ...appSt.serviceData, idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad })
        const clone = JSON.parse(JSON.stringify(form));
        if (data.payload) {
          clone.rfc.value = data.payload.idEntidad2.rfc;
          clone.razonSocial.value = data.payload.razonSocial;
        }
        
  
        if (pagosSt.pagoDetails && pagosSt.pagoDetails.cobroRegistroConvocatoriaDet) {
          const array = await formatArray()
          // aqui vienen del array q ya hace el filtro de anticipadas
          const pagosOnline = array.filter(item => {
            return (!item.idEsquemaCobro && type !== 'anticipada') || 
            (item.idEsquemaCobro && item.idEsquemaCobro === catalogosSt.idsEsquemaCobro.online)
          })
          const pagosOffline = array.filter(item => {
            return (!item.idEsquemaCobro && type === 'anticipada') || 
            (item.idEsquemaCobro && item.idEsquemaCobro === catalogosSt.idsEsquemaCobro.offline)
          })
  
          const PUE = formatPagos(pagosOnline, 'online')
          const PPD = formatPagos(pagosOffline, 'offline')
          await getCatalogoFormaPago(PPD && !PUE ? 2 : 1)
          if (PPD && !PUE) {
            clone.cveFormaPago.value = '99'
            clone.cveMetodoPago.value = 'PPD';
          } else {
            clone.cveMetodoPago.value = 'PUE';
          }
          setRegistros(array)
        }
        if (tooltip) {
          clone.folioOdc.tool = tooltip
        }
        setForm(clone);
      }      
    } catch (error) {
      dispatch(setAlert({
        show: true,
        message: "No existe monto de participación",
        type: ALERT_FAIL
      }))
    }
  }

  const getTooltips = async () => {
    try {
      const resp = await getTips({
        ...appSt.serviceData,
        claveCatalogo: "parametro",
        clave: "MENSAJEORDENCOMPRA"
      });
      setTootltip(resp.payload.valor)  
    } catch (error) {
      console.log(error)      
    }
  }

  useEffect(() => {
    populateForm();    
  }, [type, pagosSt.pagoDetails])

  useEffect(() => {
    if (!catalogosSt.esquemaCobro) {
      dispatch(getEsquemaCobroCatalogo(appSt.serviceData))
    }
    getTooltips()
  }, [])

  return (<>
    <div className={hidden ? "content hidden" : 'content'}>
      {registros && registros.length > 0 && catalogosSt.esquemaCobro && <>
        <table>
          <thead>
            <tr>
              <th>Descripción</th>
              <th>Metodo de pago</th>
            </tr>
          </thead>
          <tbody>
            {registros.map((item, index) => {
              return <tr key={index}>
                <td>{item.descCobroRegistroConvocatoria}</td>
                <td>{
                  (item.idEsquemaCobro && item.idEsquemaCobro === catalogosSt.idsEsquemaCobro.offline) || 
                  (!item.idEsquemaCobro && type === 'anticipada') 
                    ? 'PPD' : 'PUE'}</td>
              </tr>
            })}
            <tr></tr>
          </tbody>
        </table>
      </>}
      {type &&
        <DireccionComponent aviso={null} empresaSeleccionada={registroConvocatoriaSt.empresaSeleccionada} cveDireccion={CVE_DIRECCION.fiscal} form={formDireccion} setForm={setFormDireccion} isFiscal={true}/>
      }
      <div className="two-col">
        <InputComp disabled={true} label="RFC" name="rfc" type="upper" form={form} setForm={setForm} />
        <InputComp disabled={true} label="Razón Social" name="razonSocial" type="upper" form={form} setForm={setForm} />
        {catalogosSt.usoCfdi &&
          <InputComp label="Uso CFDI" name="cveUsoCfdi" type="select" form={form} setForm={setForm} catalogos={catalogosSt.usoCfdi} catalogoValue={'cveUsoCfdi'} catalogoLabel={'descUsoCfdi'} secundaryLabel={'cveUsoCfdi'}/>
        }
        {catalogoFormaPago &&
          <InputComp label="Forma de Pago" name="cveFormaPago" type="select" form={form} setForm={setForm} catalogos={catalogoFormaPago} catalogoValue={'cveFormaPago'} catalogoLabel={'descFormaPago'} secundaryLabel={'cveFormaPago'} />
        }
        {catalogosSt.regimen &&
          <InputComp label="Regimen Fiscal" name="idRegimenFiscal" type="select" form={form} setForm={setForm} catalogos={catalogosSt.regimen} catalogoValue={'idRegimenFiscal'} catalogoLabel={'descRegimenFiscal'} secundaryLabel={'cveRegimenFiscal'} />
        }
        <InputComp label="Agrega tu Orden de Compra Interna" name="folioOdc" type="upper" form={form} setForm={setForm} />
      </div>
    </div>
    <div className="actions">
      <button onClick={handleCancel}>Cancelar</button>
      <button onClick={() => setIsShowModalConfirmar(true)} disabled={disabledSubmit}>Continuar</button>
    </div>
    {isShowModalConfirmar 
      ? <ModalConfirmar 
        mensaje={`Está seguro que quiere facturar el rfc ${form.rfc.value} razón social ${form.razonSocial.value}`}
        show={isShowModalConfirmar}
        closeModal={() => setIsShowModalConfirmar(false)}
        handleSubmit={handleSubmit}
      />
      : <></>
    }
  </>
  )
}

const ModalConfirmar = ({ titulo, mensaje, show, closeModal, hidden, handleSubmit }) => {
  return <div className={show ? 'modal-wrapper active' : 'modal-wrapper'}>
    <div className="modal factura">
        <div className="head">
            <h3>{titulo || "Confirmar"}</h3>
            <button onClick={closeModal}>
                x
            </button>
        </div>
        <div className={hidden ? "content hidden" : 'content'}>
          {mensaje || "¿Estás seguro de realizar la acción?"}
        </div>
        <div className="actions">
        <button onClick={closeModal}>Cancelar</button>
        <button onClick={handleSubmit}>Aceptar</button>
      </div>
      </div>
  </div>
}