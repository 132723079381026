import { getRequest } from "./http-service";

const url = process.env.REACT_APP_CONTACOS_CEMEFI_SERVICE;

export const getAllContactosCemefi = async (data) => {
    const requestUrl = `${url}/area/${data.area}/contacto-cemefi`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};