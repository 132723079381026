import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalVinculacion } from '../slices/usuariosSlice';
import { usuariosServicesState, getUsuarios } from "../slices/usuariosServicesSlice";
import { appState, changeLogo, setAlert } from "../slices/appSlice";

import RegistroUsuario from '../components/registro/registroUsuario';
import ConfirmModal from '../components/generales/confirmModal';

import { ReactComponent as MoreIcon } from '../assets/icons/more.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';
import { ReactComponent as FileIcon } from '../assets/icons/file.svg';
import { ReactComponent as UsuariosIcon } from '../assets/icons/usuarios.svg';

import '../styles/usuarios.scss';
import VinculacionUsuario from "../components/usuarios/vinculacionUsuario";
import { ALERT_FAIL, ALERT_SUCCESS, ESTATUS_REGISTRO, LOGO_EMPRESA } from "../app/constantes";
import { putUsuarioToGroup } from "../services/usuarios";


export default function Usuarios() {
    const dispatch = useDispatch();
    const usuarioSt = useSelector(usuariosServicesState);
    const [modalData, setModalData] = useState({
        show: false,
        title: '',
        desc: '',
        list: null
    });

    const appSt = useSelector(appState);

    useEffect(() => {
        if (appSt.serviceData && usuarioSt.usuario && usuarioSt.usuario.data) {
            dispatch(getUsuarios({
                ...appSt.serviceData,
                idUsuario: usuarioSt.usuario.data.idUsuarioFederado
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuarioSt.usuario, appSt.serviceData])


    const closeModal = () => {
        setModalData({
            ...modalData,
            show: false
        })
    }

    const confirmDelete = (user) => {
        setModalData({
            show: true,
            title: 'Desvincular',
            desc: '¿Seguro deseas desvincular a este usuario?',
            action: () => desvincularUsuario(user)
        })
    }

    const desvincularUsuario = async(user) => {
        console.log(user)
        const body = {
            cvePerfil: user.cvePerfil,
            cveEstatusRegistro: ESTATUS_REGISTRO.rechazada,
            idEntidad: user.idEntidad,
            idUsuario: user.idUsuario
        }
        try {
            await putUsuarioToGroup({
                ...appSt.serviceData, 
                idPerfilUsuario: user.idPerfilUsuario
            }, body);
            dispatch(setAlert({
                show: true,
                message: 'Usuario Desvinculado',
                type: ALERT_SUCCESS
            }));
            dispatch(getUsuarios({
                ...appSt.serviceData,
                idUsuario: usuarioSt.usuario.data.idUsuarioFederado
            }));
            closeModal();
        } catch (error) {
            console.log(error)
            dispatch(setAlert({
                show: true,
                message: 'Error al desvincular usuario',
                type: ALERT_FAIL
            })) 
        }
    }

    const vincularUsuario = (user) => {
        dispatch(setModalVinculacion({
            show: true,
            data: user
        }))
    }

    useEffect(() => {
        dispatch(changeLogo(LOGO_EMPRESA.cemefi));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className="mis-empresas">
            <div className="wrapper">
                <div className="main-section-title">
                    <UsuariosIcon />
                    <div className="text">
                        <h3>Usuarios</h3>
                        <p>Aqui podras gestionar a los usuarios registrados</p>
                    </div>
                </div>
                {usuarioSt.usuarios && usuarioSt.usuarios.length > 0 &&
                    <table>
                        <thead>
                            <tr>
                                <th>Nombre Completo</th>
                                <th>RFC Usuario</th>
                                <th>Perfil</th>
                                <th>Entidad Vinculada</th>
                                <th>RFC Entidad</th>
                                <th>Estatus</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usuarioSt.usuarios.map((user, index) => {
                                return <tr key={index}>
                                    <td>{user.nombre} {user.apellidoPaterno}</td>
                                    <td>{user.rfc}</td>
                                    <td>{user.descPerfil}</td>
                                    <td>{user.nombreComercial}</td>
                                    <td>{user.rfcEntidad}</td>
                                    <td>{user.descEstatusRegistro}</td>
                                    <td className='acciones'>
                                        <button><MoreIcon /></button>
                                        <ul>
                                            <li onClick={() => vincularUsuario(user)}>
                                                <FileIcon />
                                                <p>Cambiar Perfil</p>
                                            </li>
                                            {user.cveEstatusRegistro !== ESTATUS_REGISTRO.rechazada && 
                                                <li onClick={() => confirmDelete(user)}>
                                                    <DeleteIcon />
                                                    <p>Desvincular</p>
                                                </li>                                            
                                            }
                                        </ul>
                                    </td>

                                </tr>
                            })}
                            <tr>
                            </tr>
                        </tbody>
                    </table>                
                }


                <ConfirmModal data={modalData} cancel={closeModal} />
                <RegistroUsuario />
                <VinculacionUsuario />
            </div>
        </section>
    )
}