/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import '../../styles/publicacion.scss';
import { CONVOCATORIAS_DISPONIBLES, SECTIONS } from "../../app/constantes";
import Aprobacion from "./aprobacion";
import CartaLogo from "./carta-logo";
import ReporteResultados from "./reporte-resultados";
import { ReactComponent as AcreditadasIcon } from '../../assets/icons/acreditadas.svg';
import { ReactComponent as CartaLogoIcon } from '../../assets/icons/carta-logo.svg';
import { ReactComponent as ReporteIcon } from '../../assets/icons/report-results.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { useDispatch, useSelector } from "react-redux";
import { changeView } from "../../slices/registroConvocatoriaSlice";
import { publicacionState } from "../../slices/publicacionSlice";
import { ListCursos } from "./cursos";
import { getCatalogos } from "../../services/catalogos";
import { appState } from "../../slices/appSlice";

export default function Publicacion({ empresaSeleccionada }) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const publicacionSt = useSelector(publicacionState);
    const [view, setView] = useState(SECTIONS.one);
    const [catCursos, setCatCursos] = useState([]);

    useEffect(() => {
        const obtenerCat = async () => {
            const cursosT = await getCatalogos(appSt.areaData.idAreaCemefi, "curso", appSt.serviceData.token, null);
            setCatCursos(cursosT);
        }
        obtenerCat();
    }, [])

    const goToView = (section) => {
        setView(section);
    }

    const goBack = () => {
        dispatch(changeView(CONVOCATORIAS_DISPONIBLES));
    }

    return (<div className="publicacion">
        {view === SECTIONS.one &&
            <div className="wrapper main-menu">
                <div className="head">
                    <button onClick={goBack}>
                        <BackIcon />
                    </button>
                    <h3>Publicación</h3>
                </div>
                <div className="four-col">
                    <div className="card" onClick={() => goToView(SECTIONS.two)}>
                        <AcreditadasIcon />
                        <h3>Entidades Acreditadas</h3>
                    </div>
                    {!publicacionSt.currentConvocatoria.publicacion.data 
                    || (publicacionSt.currentConvocatoria.publicacion.data && publicacionSt.currentConvocatoria.publicacion.data.publicada !== "CONDICIONADA")
                        ? <div className="card" onClick={() => goToView(SECTIONS.three)}>
                            <CartaLogoIcon />
                            {publicacionSt.currentConvocatoria.publicacion 
                                && 
                                publicacionSt.currentConvocatoria.publicacion.isPublicada 
                                && <h3>Carta de uso de Logotipo</h3>
                            }
                            {publicacionSt.currentConvocatoria.publicacion 
                                && (
                                    publicacionSt.currentConvocatoria.publicacion.isPublicada === null 
                                    || publicacionSt.currentConvocatoria.publicacion.isPublicada === false
                                ) &&
                                <h3>Carta de dictamen</h3>
                            }
                        </div>
                        : <></>
                    }
                    {publicacionSt.currentConvocatoria.publicacion 
                        && publicacionSt.currentConvocatoria.publicacion.isPublicada !== null 
                        && (publicacionSt.currentConvocatoria.publicacion.data 
                            && (publicacionSt.currentConvocatoria.publicacion.data.publicada === "SI" 
                            || publicacionSt.currentConvocatoria.publicacion.data.publicada === "NO"
                            || publicacionSt.currentConvocatoria.publicacion.data.publicada === "CONDICIONADA")
                        ) 
                        && <div className="card" onClick={() => goToView(SECTIONS.four)}>
                            <ReporteIcon />
                            <h3>Reporte de Resultados</h3>
                        </div>
                    }
                    {catCursos.length > 0 && publicacionSt?.currentConvocatoria?.tieneCursoConvocatoria === "SI"
                        ? <div className="card" onClick={() => goToView(SECTIONS.five)}>
                            <ReporteIcon />
                            <h3>Cursos</h3>
                        </div>
                        : <></>
                    }
                    <div className="card link">
                        <CartaLogoIcon />
                        <h3>Encuesta Consultor</h3>
                        <a href="https://forms.gle/r8Ba61U8uX3QCBye7" target="_blank" rel="noreferrer">Encuesta Consultor</a>
                    </div>
                </div>
            </div>
        }

        {view === SECTIONS.two &&
            <Aprobacion back={() => goToView(SECTIONS.one)} />
        }
        {view === SECTIONS.three &&
            <CartaLogo empresaSeleccionada={empresaSeleccionada} back={() => goToView(SECTIONS.one)} />
        }
        {view === SECTIONS.four &&
            <ReporteResultados empresaSeleccionada={empresaSeleccionada} back={() => goToView(SECTIONS.one)} />
        }
        {view === SECTIONS.five &&
            <ListCursos back={() => goToView(SECTIONS.one)} />
        }
    </div>)
}