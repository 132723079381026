/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { getCobrosAplicados, getPagosDetails, pagosState, setModalServiciosAdicionales } from "../../slices/pagosSlice";
import { ALERT_FAIL, ALERT_SUCCESS, CVE_ESTATUS_COBRO, ERROR_MESSAGE, ESTATUILLA_CONCEPTO, RETROALIMENTACION_CONCEPTO, SUCESS_MESSAGE } from "../../app/constantes";
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { getConvocatoriaByIdConvocatoria, getServiciosExtemporaneos, postServicioExtemporaneo } from "../../services/convocatorias";
import { CobrosEstatuillas } from "../registro-convocatoria/ServiciosAdicionales/CobrosEstatuillas";
import { ReactComponent as RetroIcon } from '../../assets/icons/retro.svg';
import { formatCurrency } from "../../app/commons";
import { getTips } from "../../services/catalogos";

export default function ModalServiciosAdicionales({ convocatoria }) {
  const dispatch = useDispatch();
  const catalogosSt = useSelector(catalogosServiceState);
  const pagosSt = useSelector(pagosState);
  const appSt = useSelector(appState);
  const [servicios, setServicios] = useState(null);
  const [estatuillas, setEstatuillas] = useState(0);
  const [retro, setRetro] = useState(false);
  const [tips, setTips] = useState(null);

  const closeModal = () => {
    dispatch(getPagosDetails({
      ...appSt.serviceData,
      idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
      idModalidadPago: catalogosSt.idModoPagoTotal
    }))
    dispatch(getCobrosAplicados({
      ...appSt.serviceData,
      idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
    }))
    dispatch(setModalServiciosAdicionales({
      show: false,
      data: null
    }))
  }

  const handleSubmit = async () => {
    if (retro || estatuillas > 0) {
      dispatch(setLoading(true));
      const promises = []
      servicios.forEach(cobro => {
        const body = {
          idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
          cveEsquemaCobro: 'OFFLINE',
          cveEstatusCobro: CVE_ESTATUS_COBRO.registrado,
          idConceptoCobroConvocatoria: cobro.idConceptoCobroConvocatoria,
          descCobroRegistroConvocatoria: cobro.descConceptoCobroConvocatoria,
          montoCobro: cobro.monto,
          idAreaCemefi: appSt.serviceData.area,
          idCobroRegistroConvocatoria: null,
          cantidad: cobro.descConceptoCobroConvocatoria.includes(RETROALIMENTACION_CONCEPTO) ? 1 : estatuillas
        }
        if (cobro.descConceptoCobroConvocatoria.includes(RETROALIMENTACION_CONCEPTO) && retro) {
          promises.push(postServicioExtemporaneo(appSt.serviceData, body))
        } else if (cobro.descConceptoCobroConvocatoria.includes(ESTATUILLA_CONCEPTO) && estatuillas > 0) {
          promises.push(postServicioExtemporaneo(appSt.serviceData, body))        
        }
      })
  
      try {
        await Promise.allSettled(promises)
        dispatch(setAlert({
          show: true,
          message: SUCESS_MESSAGE,
          type: ALERT_SUCCESS
        }))      
        closeModal()
        dispatch(setLoading(false));
      } catch (error) {
        console.log(error)
        dispatch(setAlert({
          show: true,
          message: ERROR_MESSAGE,
          type: ALERT_FAIL
        })) 
        dispatch(setLoading(false));
      }
    } else {
      dispatch(setAlert({
        show: true,
        message: 'Agrege al menos un servicio para continuar',
        type: ALERT_FAIL
      }))
    }
  }
  const checkServicios = async () => {
    let resp
    if (!pagosSt.currentConvocatoria.isConvocatoriaRegistro) {
      resp = await getServiciosExtemporaneos({
        ...appSt.serviceData,
        idConvocatoria: convocatoria.idRegistroConvocatoria,
        idPrograma: convocatoria.idPrograma
      })
    } else {
      resp = await getConvocatoriaByIdConvocatoria({
        ...appSt.serviceData,
        idConvocatoria: convocatoria.idRegistroConvocatoria,
        idPrograma: convocatoria.idPrograma
      })
    }

  
    const retro = pagosSt.pagoDetails.cobroRegistroConvocatoriaDet.find(items => {
      return items.descCobroRegistroConvocatoria.includes(RETROALIMENTACION_CONCEPTO)
    })
    
    let serviciosArray;

    if (retro) {
      serviciosArray = resp.filter(item => !item.descConceptoCobroConvocatoria.includes(RETROALIMENTACION_CONCEPTO))
    } else {
      serviciosArray = resp;
    }

    setServicios(serviciosArray)
  }

  const handleEstatuaChange = (e) => {
    if (e === 'add') {
      setEstatuillas(estatuillas + 1)
    } else {
      setEstatuillas(estatuillas - 1)
    }
  }

  const handleRetroChange = () => {
    setRetro(!retro)
  }

  const populateTips = async () => {
    const tipRetroResp = await getTips({
      ...appSt.serviceData,
      claveCatalogo: 'parametro',
      clave: 'RETROALIMENTACIÓN'
    });

    const estatuillaResp = await getTips({
      ...appSt.serviceData,
      claveCatalogo: 'parametro',
      clave: 'ESTATUILLAS'
    });

    setTips({
      retro: tipRetroResp.payload ? tipRetroResp.payload.valor : '',
      estatuilla: estatuillaResp.payload ? estatuillaResp.payload.valor : ''
    })

  }

  useEffect(() => {
    if (pagosSt.pagoDetails && pagosSt.pagoDetails.cobroRegistroConvocatoriaDet) {
      checkServicios();
    }
  }, [pagosSt.modalServiciosAdicionales])

  useEffect(() => {
    if (!tips) {
      populateTips()
    }
  }, [])

  return (
    <div className={pagosSt.modalServiciosAdicionales.show ? 'modal-wrapper active' : 'modal-wrapper'}>
      <div className="modal servicios-adicionales">
        <div className="head">
          <h3>Agregar Servicios Adicionales</h3>
          <button onClick={closeModal}>
            x
          </button>
        </div>
        <div className="content">
          <div className="two-col">
            {servicios && servicios.map((serv, index) => {
              return serv.descConceptoCobroConvocatoria.includes(RETROALIMENTACION_CONCEPTO)
                ? (<div className="chip" key={index}>
                  <div className="tooltip">
                    {tips && tips.retro && <span>{tips.retro}</span>}
                    <RetroIcon />
                  </div>
                  <div className="text">
                    <label>
                      {serv.descConceptoCobroConvocatoria}
                    </label>
                    <p>{formatCurrency(serv.monto)}</p>
                  </div>
                  <input
                    checked={retro}
                    type="checkbox"
                    onChange={() => handleRetroChange(serv)}
                  />
                </div>)
                : <CobrosEstatuillas
                  tip={tips && tips.estatuilla ? tips.estatuilla : ''}
                  key={index}
                  input={estatuillas}
                  concepto={serv}
                  type="ex"
                  handleEstatuaChange={handleEstatuaChange}
                />
            })}
          </div>
        </div>
        <div className="actions">
          <button onClick={closeModal}>Cancelar</button>
          <button onClick={handleSubmit}>Continuar</button>
        </div>
      </div>
    </div>
  )
}