export const filtrosEntidadesDestacadas = {
    idAmbito: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
}

export const filtrosPublicacionPublic = {
    idArea: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idConvocatoria: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    anio: {
        value: '2023',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
}