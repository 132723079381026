export const REGISTRO_USUARIO_ETAPA = 'registro-usuario';
export const SELECCION_EMPRESA_ETAPA = 'seleccion-empresa';
export const VINCULACION_USUARIO_ETAPA = 'vinculacion-usuario';

export const LOGO_EMPRESA = {
    cemefi: 'cemefi',
    esr: 'esr'
}

export const RFC_CEMEFI = "CMF881208TV6"

export const INFORMACION_GENERAL_STEP = 'informacion-general';
export const DATOS_CONTACTO_STEP = 'datos-contacto';
export const INVITACIONES_STEP = 'invitaciones';
export const DATOS_INDUSTRIA_STEP = 'datos-industria';
export const DATOS_PARTICIPACION_STEP = 'datos-participacion';
export const DOCUMENTOS_STEP = 'documentos';
export const DIRECCION_ENTREGA_STEP = 'direccion-entrega';
export const REDES_STEP = 'redes';
export const MEMBRESIA_STEP = 'membresia';
export const SERVICIO_ADICIONALES_STEP = 'servicio-adicionales';

export const REGISTRO_USUARIO = 'registro-usuario';
export const REGISTRO_EMPRESA = 'registro-empresa';
export const VINCULACION_USUARIO = 'vinculacion-empresa';

export const EMPRESAS_LIST = 'empresas';
export const CONVOCATORIAS_DISPONIBLES = 'convocatorias';
export const REGISTRO_CONVOCATORIA = 'registro';
export const INSTRUMENTO = 'instrumento';
export const INSTRUMENTO_PROGRESS = 'instrumento-progress';
export const SELECCION_PREGUNTA = 'seleccion-pregunta';
export const PUBLICACION_VIEW = 'publicaciones';
export const RETRO_VIEW = 'retroalimentacion';
export const PAGOS = 'pagos';
export const INVITACION_STEP = 'invitaciones';

export const PROGRAMAS = {
    cv: "CV",
    ep: "EP"
}

export const CVE_PROGRAMAS = {
    cv: "CADENAVALOR",
    ep: "ENTIDADPROMOTORA",
    entidadesPromotoras: "ENTIDADESPROMOTORAS"
}

export const CVE_ESTATUS_RELACION = {
    disponible: "DISPONIBLE",
    inscrita: "INSCRITA"
}

export const ESTATUS_REGISTRO = {
    enVinculacion: 'EN_VINCULACION',
    rechazada: 'Vinculacion_Rechazada'
}

export const CVE_TIPO_CONTACTO = {
    principal: 'PRINCIPAL', 
    directorGeneral: 'DIRECTORGENERAL'
}

export const CVE_ESTATUS_CONSULTOR = {
    invitado: 'INVITADO', 
    revision: 'REVISION',
    rechazado: 'RECHAZADO',
    aceptado: 'ACEPTADO'
}

export const DOCUMENTOS_CONSULTOR = {
    review: "review",
    consentimiento: "consentimiento",
    codigoEtica: "codigoEtica",
    cv: "cv",
    reacreditacion: "reacreditacion",
}

// Alerts
export const ALERT_SUCCESS = 'success';
export const ALERT_WARN = 'warn';
export const ALERT_FAIL = 'fail';
export const SUCESS_MESSAGE = 'Datos Guardados';
export const ERROR_MESSAGE = 'Error al guaradar los datos';

// Service
export const USUARIO_NO_REGISTRADO = 'USERSERV-CREATEUSUARIO';
export const CVE_PERSONA_FISICA = 'PERSONAFISICA';
export const CVE_PERSONA_MORAL = 'PERSONAMORAL';

// Documentos service
export const CVE_TIPODOC_ENTIDAD = 'DOCUMENTOENTIDAD';
export const CVE_TIPODOC_CONVOCATORIA = 'REGISTROCONVOCATORIA';
export const CVE_TIPODOC_COBRO = 'DOCUMENTOCOBRO';
export const CVE_TIPODOC_CEMEFI = {
    evidenciaNm: 'EVIDENCIANM',
    evidencia: 'EVIDENCIA',
    membresia: 'PAGOMEMBRESIAS',
    comprobantePago:  'COMPROBANTEPAGO',
    actaConstitutiva: 'ACTACONSTITUTIVA'
}
export const CVE_TIPODOC_EVIDENCIA_INSTITUCIONAL = 'EVIDENCIAINSTITUCIONAL';
export const CVE_TIPODOC_REVERTIRPAGO = 'DOCUMENTOREVERTIRPAGO';
export const CVE_TIPODOC_CONSULTOR = 'CONSULTORACREDITADO';

// Tipos direccion
export const MEXICO_ID = '42';

export const CVE_DIRECCION = {
    correspondencia: 'CORRESPONDENCIA',
    fiscal: 'FISCAL',
    entrega: 'ENTREGA'
}

export const CVE_CONCEPTO_COBRO = {
    retro: 'SERDISRET'
}

// Service Entidad
export const CVE_ESTATUS_ENTIDAD = {
    registrado: 'REGISTRADA',
    enRevision: 'ENREVISION',
    confirmada: 'CONFIRMADA',
    rechazada: 'RECHAZADA'
}

// Modo Participacion CVE
export const CVE_MODO_CORPORATIVO = 'GRUPOCORPORATIVO';
export const CVE_MODO_SEDEMARCA = 'SEDEMARCA';
export const CVE_MODO_ADICIONAL_SEDE = 'ADICIONALSEDEMARCA';
export const CVE_MODO_ADICIONAL = 'ADICIONALCORPORATIVO';
export const CVE_MODO_INDIVIDUAL = 'INDIVIDUAL';

// Claves
export const ID_IS_SEDE = 1;

// Claves de status registro convocatoria
export const CVE_ETATUS_REGISTRO_CONVOCATORIA = 'REGISTRADA';
export const CVE_ETATUS_REVISION_CONVOCATORIA = 'REVISION';
export const CVE_ETATUS_ACEPTADA_CONVOCATORIA = 'ACEPTADA';
export const CVE_ETATUS_RECHAZADA_CONVOCATORIA = 'RECHAZADA';

// Claves de status cobro
export const CVE_ESTATUS_COBRO = {
    registrado: 'REGISTRADO',
    revision: 'ENREVISION',
    confirmado: 'CONFIRMADO',
    rechazado: 'RECHAZADO',
    revertir: 'REVERTIRPAGO',
    exento: 'EXENTO'
};

// Conceptos de cobro
export const RETROALIMENTACION_CONCEPTO = 'RETROALIMENTACIÓN';
export const CUOTA_CONCEPTO = 'CUO';
export const ESTATUILLA_CONCEPTO = 'RÉPLICA ESTATUILLA';
export const ESCULTURA_CONCEPTO = 'RÉPLICA DE ESCULTURA CARACOL DE PLATA';
export const CONCEPTO_CUOTA = "CUOTA PARTICIPACIÓN AL PROCESO DE DISTINTIVO ESR®"

export const IDS_TIPO_PROGRAMA = {
    distintivoESR: 1,
    cadena_valor: 2
}

export const CVE_ESTATUS_REGISTRO_CONVOCATORIA = {
    registrada: 'REGISTRADA',
    aceptada: 'ACEPTADA',
    rechazada: 'RECHAZADA',
    revision: 'REVISION'
}

// is Invitacion
export const IS_INVITACION = {
    TRUE: 1,
    FALSE: 0
}

export const CVE_TIPO_RESPUESTA = {
    opcionMultiple: 'OPCIONMULTIPLE' ,
    textoAbierto: 'TEXTOABIERTO',
    nivelMadurez: 'NIVELMADUREZ',
    magnitud: 'MAGNITUD',
    cumplimiento: 'CUMPLIMIENTO',
}

export const CVE_MODO_PAGO = {
    total: 'TOTAL' ,
    parcial: 'PARCIAL',
}

export const DUAL_LIST = {
    membresia: 'membresia'
}

export const CVE_PERFILES_USUARIO = {
    colaboradorEntidad: 'Colaborador',
    encargadoEntidad: 'ResponsableEmpresa',
    consultorAcreditado: 'ConsultorAcreditado'
}

export const FIREBASE = {
    instrumentProgressCollection: 'instrumentoProgreso',
    convocatorias: 'convocatorias'

};

export const INSTRUMENTOS_CVE_ESTATUS = {
    asignado: 'ASIGNADO',
    enProceso: 'ENPROCESO',
    liberado: 'LIBERADO'
}

export const CONVOCATORIAS_ID = {
    empresasGrandes: '1',
    pymes: '2',
    insigniaInclusionSocial: '5',
    insigniaInversionSocial: '6',
}

export const TIPO_REGISTRO_CORP_INV = {
    corporativo: 'CORPORATIVO',
    invitacion: 'PROGRAMA',
    sede: 'SEDE'
}

export const TAMANIOS_IDS = {
    micro: 1,
    small: 2,
    medium: 3,
    large: 4,
}

export const CVE_TAMANIO = {
    grande: "GRANDE"
}

// Notificaciones
export const NOTIFICACIONES = {
    defaultFrom: {
        email: "distintivo@cemefi.org",
        name: "CEMEFI"
    },
    defaultTo: {
        email: "jonathan.zuniga@bitintegration.com.mx",
        name: "Jonathan"
    },
    templatesIds: {
        registroUsuario: 'd-3c431f2dbaf849d1a554929d17071f56',
        revisionRegistroEntidad: 'd-d9495c8727aa4370a7a2b20deb3c3eb4',
        revisionRegistroConvocatoria: 'd-9cda012825904100b6ad6b643acb6b74',
        revisionInvitado: 'd-aa4bdb19d49849ba84d7b9bb93cde162',
        revisionPago: 'd-3e5efaacffa446d99e80ab0e28b8e432',
        liberacionInstrumento: 'd-b42051e886c9445c960049383804ef7f',
        validacionCobro: 'd-170ece59b30c449887b55382e3337deb',
        pasarelaExitoso: 'd-0bda4d5fc01a4ece81c356aca7546769'
    }
}

// Publicacion
export const PORTADA_COMPONENT = 'portada';
export const TEXT_SIMPLE_COMPONENT = 'text-simple';
export const TEXT_IMAGE_COMPONENT = 'text-image';
export const TWO_COL_COMPONENT = 'two-col-comp';
export const ETAPAS_COMPONENT = 'etapas';
export const EMPRESAS_TABLE_COMPONENT = 'tabla-empresas';
export const CONTACTOS_COMPONENT = 'contactos';
export const DOCUMENTOS_COMPONENT = 'documentos';
export const CUOTAS_COMPONENT = 'cuotas';

// sections
export const SECTIONS = {
    one: 'one',
    two: 'two',
    three: 'three',
    four: 'four'
}

export const CVE_TIPODOC_USOLOGO = 'PUBLICACION';

export const CVES_TIPODOCS_USOLOGO = {
    cartaLogo: 'CARTAACEPTACION',
    logo: 'LOGOTIPO',
    cartaDictamen: 'CARTADICTAMEN',
    manualUso: 'MANUALUSOLOGOTIPO',
    reglasUso: 'REGLASUSOLOGOTIPO',
    instruccionesUso: 'INSTRUCCIONREGLASUSOLOGOTIPO',
    logoEsr: 'LOGOSESR',
}

export const CVE_ESTATUS_CARTALOGO = {
    aceptado: 'ACEPTADO',
    rechazado: 'RECHAZADO',
    sinValidar: 'SINVALIDAR',    
    pendiente: 'PENDIENTE',    
}

export const GENERAL_TABLE = {
    callbacks: {
        cleanSelection: 'clean-selection'
    },
    columnTypes: {
        check: 'check',
        date: 'date',
        custom: 'custom',
        actions: 'actions',
        currency: 'currency'
    },
    actionType: {
        sublist: 'sublist',
        button: 'button',
        iconBtn: 'icon-btn',
        select: 'select',
        multiselect: 'multiselect',
        tooltipBtn: 'tootip-btn'
    }
}

export const CVE_ESTATUS_CORP_SEDE = {
    registrada: "REGISTRADA",
    enRevision: "ENREVISION",
    confirmada: "CONFIRMADA",
    rechazada: "RECHAZADA"
}

export const TIPOS_GRUPO = {
    corp: 'corporativo',
    membresia: 'membresia'
}

export const PAGOS_SECTIONS = {
    pagos: 'pagos',
    facturas: 'facturas',
    datos: 'datos'
}

export const DESC_ESQUEMA_PAGO = {
    manual: "MANUAL (OFFLINE)",
    enLinea: "PASARELA DE PAGO"
}

export const CVE_ESQUEMA_PAGO = {
    offline: "OFFLINE",
    online: "ONLINE"
}