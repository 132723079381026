import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    showPayment: false,
}

export const misEmpresasSlice = createSlice({
    name: 'misEmpresas',
    initialState,
    reducers: {
        togglePayments: (state) => {
            state.showPayment = !state.showPayment;
        },
        setMisEmpresas: (state, action) => {
            state.misEmpresas = action.payload
        },       
    }
})

export const { togglePayments, setMisEmpresas } = misEmpresasSlice.actions;
export const misEmpresasState = (state) => state.misEmpresas;

export default misEmpresasSlice.reducer;