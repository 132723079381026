export const filtrosReporteResultados = {
    idEstado: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idTamanio: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idIndustria: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idSector: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idSubsector: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    anioEsr: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
}