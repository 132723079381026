import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatForm, validateForm } from '../../app/commons';
import { ALERT_FAIL, ALERT_SUCCESS } from '../../app/constantes';
import { putUsuarioToGroup } from '../../services/usuarios';
import { appState, setAlert } from '../../slices/appSlice';
import { catalogosServiceState, getEstatusVinculacion, getPerfilesUsuario } from '../../slices/catalogosServiceSlice';
import { getUsuarios, usuariosServicesState } from '../../slices/usuariosServicesSlice';
import { setModalVinculacion, usuariosState } from '../../slices/usuariosSlice';
import InputComp from '../generales/inputComponent';

export default function VinculacionUsuario() {
    const appSt = useSelector(appState);
    const dispatch = useDispatch();
    const usuariosSt = useSelector(usuariosState);
    const usuarioServiceSt = useSelector(usuariosServicesState);
    const catalogosSt = useSelector(catalogosServiceState);
    const [perfiles, setPerfiles] = useState(null);

    const [form, setForm] = useState({
        cvePerfil: {
            value: '',
            required: true,
            message: 'Dato requerido',
            tool: '',
            invalid: false,
        },
        cveEstatusRegistro: {
            value: '',
            required: true,
            message: 'Dato requerido',
            tool: '',
            invalid: false,
        },
    })

    const closeModal = () => {
        dispatch(setModalVinculacion({
            show: false, 
            data: null
        }));
    }

    const filterPerfiles = () => {
        const perfilesFiltered = catalogosSt.perfilUsuario.filter(perfil => {
            return perfil.idPerfil === catalogosSt.idsPerfilUsuario.colaboradorEntidad || perfil.idPerfil === catalogosSt.idsPerfilUsuario.encargadoEntidad || perfil.idPerfil === catalogosSt.idsPerfilUsuario.consultorAcreditado
        })
        setPerfiles(perfilesFiltered);
    }

    const saveUser = async () => {
        const valid = validateForm(form);
        if (valid.length === 0) {
            const formatedForm = formatForm(form);
            const body = {
                ...formatedForm,
                idEntidad: usuariosSt.modalVinculacion.data.idEntidad,
                idUsuario: usuariosSt.modalVinculacion.data.idUsuario
            }
            try {
                await putUsuarioToGroup({
                    ...appSt.serviceData, 
                    idPerfilUsuario: usuariosSt.modalVinculacion.data.idPerfilUsuario
                }, body);
                dispatch(setAlert({
                    show: true,
                    message: 'Usuario Actualizado',
                    type: ALERT_SUCCESS
                }));
                dispatch(getUsuarios({
                    ...appSt.serviceData,
                    idUsuario: usuarioServiceSt.usuario.data.idUsuarioFederado
                }));
                closeModal();
            } catch (error) {
                console.log(error)
                dispatch(setAlert({
                    show: true,
                    message: 'Error al actualizar usuario',
                    type: ALERT_FAIL
                }))                
            }
        } else {
            dispatch(setAlert({
                show: true,
                message: 'Favor de llenar todos los campos',
                type: ALERT_FAIL
            }))
        }
    }

    useEffect(() => {
        if (catalogosSt.perfilUsuario === null && appSt.serviceData) {
            dispatch(
                getPerfilesUsuario(appSt.serviceData)
            )
        }
        if (catalogosSt.estatusVinculacion === null && appSt.serviceData) {
            dispatch(
                getEstatusVinculacion(appSt.serviceData)
            )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSt.serviceData])

    useEffect(() => {
        if (usuariosSt.modalVinculacion.data) {
            const clone = JSON.parse(JSON.stringify(form));
            clone.cvePerfil.value = usuariosSt.modalVinculacion.data.cvePerfil;
            clone.cveEstatusRegistro.value = usuariosSt.modalVinculacion.data.cveEstatusRegistro;
            setForm(clone)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuariosSt.modalVinculacion])

    useEffect(() => {
        if(catalogosSt.perfilUsuario) {
            filterPerfiles();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogosSt.perfilUsuario])
    
    return (
      <div className={usuariosSt.modalVinculacion.show ? 'modal-wrapper active' : 'modal-wrapper'}>
        <div className="modal vinculacion">
            <div className="head">
                <h3>Vincular Usuario</h3>
                <button onClick={closeModal}>
                    x
                </button>
            </div>
            {usuarioServiceSt.usuario && 
                <div className="content">
                    {usuariosSt.modalVinculacion.data && 
                        <div className="four-col">
                            <div className="dato">
                                <label>Nombre Completo</label>                        
                                <p>{usuariosSt.modalVinculacion.data.nombre} {usuariosSt.modalVinculacion.data.apellidoPaterno} {usuariosSt.modalVinculacion.data.apellidoMaterno}</p>
                            </div>
                            <div className="dato">
                                <label>Email</label>
                                <p>{usuariosSt.modalVinculacion.data.email}</p>
                            </div>
                            <div className="dato">
                                <label>Teléfono</label>
                                <p>{usuariosSt.modalVinculacion.data.telefono}</p>
                            </div>
                        </div>
                    }
                    <div className="four-col">
                        {catalogosSt.perfilUsuario && 
                            <InputComp label="Perfil" name="cvePerfil" type="select" form={form} setForm={setForm} catalogos={perfiles} catalogoLabel="descPerfil" catalogoValue="cvePerfil"/>
                        }
                        {catalogosSt.estatusVinculacion && 
                            <InputComp label="Estatus" name="cveEstatusRegistro" type="select" form={form} setForm={setForm} catalogos={catalogosSt.estatusVinculacion} catalogoLabel="descEstatusVinculacion" catalogoValue="cveEstatusVinculacion"/>
                        }
                    </div>
                </div>
            
            }

            <div className="actions">
                <button onClick={closeModal}>Cancelar</button>
                <button onClick={saveUser}>Guardar</button>
            </div>
        </div>
      </div>
    );
  }