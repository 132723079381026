import { useSelector } from "react-redux";
import { GENERAL_TABLE } from "../../app/constantes";
import { useState } from "react";
import { useEffect } from "react";
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import '../../styles/generalTable.scss';
import { ReactComponent as SelectAll } from '../../assets/icons/selectAll.svg';
import { ReactComponent as UnselectAll } from '../../assets/icons/unselectAll.svg';
import moment from "moment";
import { formatCurrency } from "../../app/commons";

export default function GeneralTable({ registros,  headers, disabledSelection, quickActions, showMaxItems, showBuscador }) {
    const catalogosSt = useSelector(catalogosServiceState);
    const defaultMaxItems = 10;

    const [selectedList, setSelectedList] = useState([]);
    const [data, setData] = useState(null); // datos dinamicos
    const [showSelection, setShowSelection] = useState(null); // mostrar checks y botones de seleccion
    const [pageData, setPageData] = useState(null); // table de la pagina actual
    const [formatedHeaders, setFormatedHeaders] = useState(null); // headers dinamicos 
    const [maxItems, setMaxItems] = useState(defaultMaxItems); // numero maximo de resultados TODO: numero maximo de resultados dinamico
    const [currentPage, setCurrentPage] = useState(1); // pagina actual
    const [paginator, setPaginator] = useState(null); // botones de la paginacion
    const [totalPages, setTotalPages] = useState(null); // total de bloques por resultados
    const [resultados, setResultados] = useState(null)

    /**
     * Se resetan los valores de la tabla
     */
    const resetTable = () => {
        setCurrentPage(1);
        setPaginator(null);
    }

    const changeMaxItems = (e) => {
        const value = e.target.value;
        console.log(value)
        setMaxItems(Number(value));
    }

    const filterSearch = (e) => {
        const clone = JSON.parse(JSON.stringify(registros));
        if (e.target.value === "") {
            setResultados(JSON.parse(JSON.stringify(registros)))
        } else {
            e.target.value = e.target.value.toUpperCase()
            const array = []
            clone.forEach(item => {
                headers.forEach(head => {
                    const value = item[head.key].toString()
                    if (value.includes(e.target.value)) {
                        const exist = array.find(result => result.id_entidad === item.id_entidad)
                        if (!exist) {
                            array.push(item)
                        } else {
                            console.log(exist)
                        }
                    }
                })
                return array;
            });
            setResultados(array)
        }
    }

    /**
     * Seleccionamos un registro
     * @param {*} e 
     * @param {*} registro 
     */
    const updateSelectList = (e, registro) => {
        const list = JSON.parse(JSON.stringify(selectedList));
        const check = registro.find(item => item.type === GENERAL_TABLE.columnTypes.check);
        if (e.target.checked) {
            check.checked = true;
            list.push(check.meta);
        } else {
            check.checked = false;
            const index = list.findIndex(item => {
                return item.idEntidad === check.meta.idEntidad
            });
            if (index !== -1) {
                check.checked = false
                list.splice(index, 1);
            }
        }
        setSelectedList(list);
    }

    /**
     * Seleccionamos todos los registros
     */
    const selectAll = () => {
        const selection = [];
        data.forEach(item => {
            const check = item.find(item => item.type === GENERAL_TABLE.columnTypes.check);
            check.checked = true;
            selection.push(item[0].meta);
        });
        setSelectedList(selection);
    }

    /**
     * Deseleccionamos todos los registro
     */
    const deselectAll = () => {
        data.forEach(item => {
            const check = item.find(item => item.type === GENERAL_TABLE.columnTypes.check);
            check.checked = false;    
        });
        setSelectedList([]);
    }

    /**
     * Loop de destructurar datos
     * @param {*} keys 
     * @param {*} registro 
     * @returns 
     */
    const deepLevels = (keys, registro) => {
        let level = keys.length;
        let value;
        for (let index = 0; index < level; index++) {
            let prop = keys[index]
            value = value ? value[prop] : registro[prop];            
        }
        return value;
    }

    /**
     * Formateamos los registros con los headers
     */
    const formatData = () => {
        const table = [];
        for (const registro of resultados) {
            const tr = [];

            //Creamos los headers
            for (const head of headers) {
                let item = {};
                item.label = head.label;
                //Si el item tiene un key para ligarlo con los datos
                if (head.key && head.key !== '') {
                    const keys = head.key.split('.');
                    if (keys.length > 1) {
                        //Loop para desctructurar el objeto
                        item.data =  deepLevels(keys, registro);
                    } else {
                        if (registro[head.key] || registro[head.key] === 0) {
                            item.data = registro[head.key];
                        } 
                    }
                }
                if (head.type === GENERAL_TABLE.columnTypes.check) {
                    item.checked = false;
                }
                tr.push({
                    ...item, 
                    meta: registro, 
                    orden: head.orden, 
                    type: head.type,
                    customFormat: (registro) => head.customFormat(registro),
                    actions: head.actions
                });
            }   
            table.push(tr.sort((a,b) => a.orden - b.orden));
        }
        setData(table);
        if (table.length > maxItems) {
            handlePagination(table);
        } else {
            setPageData(table)
        }
    }
    
    /**
     * Partimos los resultados en bloques dependiendo del maximo de resultados visibles
     * @param {*} table 
     */
    const handlePagination = (table) => {
        const result = table.reduce((resultArray, item, index) => { 
            const chunkIndex = Math.floor(index/maxItems)
          
            if(!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = [] // inicia un nuevo bloque
            }
          
            resultArray[chunkIndex].push(item)
          
            return resultArray
        }, [])
        //Si hay mas paginas que las maximas permitidas visiblemente
        // setteamos los botones visibles
        if (result.length > maxItems) {
            createPageButtons(0, maxItems);
        } else {
            createPageButtons(0, result.length);
        }

        setTotalPages(result); // todos los bloques
        setPageData(result[0]); // pagina inicial
    }

    /**
     * Creamos los botones para la navegacion de paginas
     * @param {*} start 
     * @param {*} end 
     */
    const createPageButtons = (start, end) => {
        const pagesItems = [];
        for (let index = start !== 0 ? start-1 : 0; index < end; index++) {
            pagesItems.push({
                page: index+1,
                index
            });
        }
        setPaginator(pagesItems);
    }

    /**
     * Vamos al bloque de la respectiva pagina seleccionada
     * @param {*} page 
     */
    const goToPage = (page) => {
        const lastItem = paginator[paginator.length-1];
        const fisrtItem = paginator[0];
        setCurrentPage(page);
        setPageData(totalPages[page-1]);
        if (lastItem.page === page) {
            const max = page+maxItems;
            createPageButtons(page-1, max >= totalPages.length ? totalPages.length : max-1)
        }
        if (fisrtItem.page === page) {
            const minum = page-maxItems;
            createPageButtons(minum < 0 ? 0 : minum, page+1)
        }
    }

    /**
     * Ir a la ultima pagina
     */
    const goToLastPage = () => {
        setCurrentPage(totalPages.length);
        setPageData(totalPages[totalPages.length-1]);
        createPageButtons(totalPages.length-defaultMaxItems, totalPages.length)
    }

    /**
     * Ir a la ultima pagina
     */
    const goToFirstPage = () => {
        setCurrentPage(1);
        setPageData(totalPages[0]);
        createPageButtons(0, defaultMaxItems)
    }

    /**
     * Acciones rapidas (arriba de la tabla)
     * Se genera la accion y su callback (si tiene)
     * @param {*} button 
     */
    const triggerAction = (button) => {
        button.action({ selectedList, data });
        if (button.callback) {
            switch (button.callback) {
                case GENERAL_TABLE.callbacks.cleanSelection:
                    deselectAll();
                    break;
            
                default:
                    break;
            }
        }
    }

    /**
     * Regla para mostrar boton de accion
     * @param {*} btn 
     * @param {*} registro 
     * @returns 
     */
    const canShowBtn = (btn, registro) => {
        let canShow = true;
        if (btn.rule) {
            canShow = btn.rule(registro.meta)
        }
        return canShow;
    }

    /**
     * Cada q se actualizan los datos de la busqueda o la tabla
     * se formatea de nuevo la tabla y se resetea 
     */
    useEffect(() => {
        setSelectedList([]);
        resetTable();
        if (headers && resultados) {
            console.log('enyr')
            formatData();
            setFormatedHeaders(headers.sort((a,b) => a.orden - b.orden));
            const check = headers.find(item => item.type === GENERAL_TABLE.columnTypes.check);
            setShowSelection(check ? true : false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultados, headers, maxItems])

    const tableActions = (btn, i, item) => {
        if (canShowBtn(btn, item)) {
            switch (btn.type) {
                case GENERAL_TABLE.actionType.iconBtn:
                    return <button key={i} onClick={() => btn.action(item.meta)}>{btn.icon}</button>
                case GENERAL_TABLE.actionType.tooltipBtn:
                    return <button className="tooltip" data-tool={btn.tooltip} key={i} onClick={() => btn.action(item.meta)}>{btn.icon}</button>
            
                default:
                    break;
            }
        } else {
            return
        }
    }

    useEffect(() => {
        if (registros) {
            setResultados(registros);
        }
    }, [registros])

    return <div className={resultados ? 'general-table active' : 'general-table'}>
        {registros && registros.length === 0 
        ?  <p className="empty">Sin Registros</p>
        : <>
            <div className="top-table">
                {((quickActions && quickActions.length > 0) || showBuscador) &&
                    <div className="quick-actions">
                        <div className="tools">
                            {showMaxItems && <div className="max-results">
                                <div className="input-wrapper">
                                    <label>Resultados</label>
                                    <select onChange={(e) => changeMaxItems(e)}>
                                        <option value="10">10</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>}
                            {showBuscador && <div className="search-input">
                                <div className="input-wrapper">
                                    <label>Buscador</label>
                                    <input type="text" onChange={(e) => filterSearch(e)} />
                                </div>
                            </div>}
                        </div>
                        {showSelection && <>
                            <button className="icon-btn" disabled={disabledSelection} onClick={selectAll}>
                                <SelectAll />
                            </button>
                            <button className="icon-btn" disabled={selectedList.length === 0} onClick={deselectAll}>
                                <UnselectAll />
                            </button>                 
                        </>}
                        {quickActions && quickActions.length > 0 && quickActions.map((quickAction, index) => {
                            switch (quickAction.type) {
                                case GENERAL_TABLE.actionType.sublist:
                                    return <button key={index} className="sublist">
                                        <p>{quickAction.label}</p>
                                        {quickAction.actions && <ul>
                                            {quickAction.actions.map((subAction, index) => {
                                                return <li key={index} onClick={() => triggerAction(subAction)}>{subAction.label}</li>
                                            })}
                                        </ul>}
                                    </button>
                                case GENERAL_TABLE.actionType.select:
                                    return <div className="input-wrapper" key={index}>
                                        <label>{quickAction.label}</label>
                                        <select name="" id="" onChange={(e) => quickAction.action(e)}>
                                            <option value=""></option>
                                            {quickAction.catalog.map((item, index) => {
                                                return <option value={item[quickAction.itemValue]} key={index}>{item[quickAction.itemDesc]}</option>
                                            })}
                                        </select>
                                    </div>
                                case GENERAL_TABLE.actionType.multiselect:
                                    return <div className="input-wrapper" key={index}>
                                        <label>{quickAction.label}</label>
                                        <select name="" id="" onChange={(e) => quickAction.action(e)} multiple>
                                            <option value=""></option>
                                            {quickAction.catalog.map((item, index) => {
                                                return <option value={item[quickAction.itemValue]} key={index}>{item[quickAction.itemDesc]}</option>
                                            })}
                                        </select>
                                    </div>                            
                                default:
                                    return <button disabled={quickAction.disabled} key={index} onClick={() => triggerAction(quickAction)}>{quickAction.label}</button>
                            }
                        })}
                    </div>                
                }
                {showSelection && 
                    <p>Selección: {selectedList.length}/{resultados ? resultados.length : 0}</p>            
                }                
            </div>
            <table className='select-action'>
                <thead>
                    <tr>
                        {formatedHeaders && formatedHeaders.map((head, index) => {
                            return <th key={index}>{head.label}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {pageData && pageData.map((registro, index) => {
                        return <tr key={index}>
                            {registro.map((item, registroIndex) => {
                                switch (item.type) {
                                    case GENERAL_TABLE.columnTypes.date:
                                        return <td key={registroIndex}>{item.data && item.data !== '' ? moment(item.data).format('YYYY-MM-DD') : ''}</td>  
                                    case GENERAL_TABLE.columnTypes.currency:
                                        return <td key={registroIndex}>{formatCurrency(item.data)}</td>      
                                    case GENERAL_TABLE.columnTypes.check:
                                        return <td key={registroIndex} >
                                            <input checked={item.checked} onChange={(e) => updateSelectList(e, registro)} disabled={disabledSelection} type="checkbox" />
                                        </td>   
                                    case GENERAL_TABLE.columnTypes.custom:
                                        return <td key={registroIndex}>
                                            {item.customFormat(item.meta)}
                                        </td> 
                                    case GENERAL_TABLE.columnTypes.actions:
                                        return <td className="actions" key={registroIndex}>
                                            {item.actions && item.actions.length > 0 && item.actions.map((btn, i) => {
                                                return tableActions(btn, i, item);
                                            })}
                                        </td>                                                         
                                    default:
                                        return <td key={registroIndex}>{item.data === 0 ? '0' : item.data}</td>
                                }
                            })}
                        </tr>
                    })}
                </tbody>
            </table>

            {paginator && 
                <div className="paginator">
                    {currentPage >= defaultMaxItems && 
                        <div onClick={() => goToFirstPage()} className={totalPages.length === currentPage ? 'page active' : 'page'}>
                            1...
                        </div>                
                    }
                    {paginator.map((item, index) => {
                        return <div onClick={() => goToPage(item.page)} className={item.page === currentPage ? 'page active' : 'page'} key={index}>{item.page}</div>
                    })}
                    {totalPages.length > paginator.length && currentPage+defaultMaxItems <= totalPages.length && 
                        <div onClick={() => goToLastPage()} className={totalPages.length === currentPage ? 'page active' : 'page'}>
                            ...{totalPages.length}
                        </div>                
                    }
                </div>
            }
        </>
        }
    </div>

}