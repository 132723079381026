/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeIndicadorSelected, changePregunta, changePreguntas, changeRespuestaSeleccionada, instrumentoServicesState, obtenerInstrumentoMedicionConvocatoria, setInstrumento, setQuery } from '../../slices/instrumentoServiceSlice';
import { useEffect } from 'react';
import { ALERT_FAIL, ALERT_SUCCESS, ALERT_WARN, FIREBASE, INSTRUMENTOS_CVE_ESTATUS } from '../../app/constantes';
import { catalogosServiceState, getAmbitosInstrumentoCatalogo, getCriteriosInstrumentoCatalogo, getIndicadoresInstrumentoCatalogo, getNivelMadurezCatalogo, getSubambitosInstrumentoCatalogo } from '../../slices/catalogosServiceSlice';
import { appState, setAlert, setLoading } from '../../slices/appSlice';
import { deleteEvidenciaRespuestaNm, deletePreguntaInstrumento, deleteRespuestaInstrumento, deleteRespuestaInstrumentoNMRegistro, getEvidenciaRespuestaNm, putInstrumentoRegistro, deleteEvidenciaRespuesta } from '../../services/instrumento';
import { registroConvocatoriaState } from '../../slices/registroConvocatoriaSlice';
import EtapasNav from './etapas-nav';
import SubirEvidencia from './subir-evidencia';
import ConfirmModal from '../generales/confirmModal';
import { firebaseDb} from '../../firebaseConfig';

export default function PreguntaComponent({preguntas, onlyView}) {
    //Redux
    const dispatch = useDispatch();
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const instrumentoSt = useSelector(instrumentoServicesState);
    const catalogosSt = useSelector(catalogosServiceState);
    const appSt = useSelector(appState);

    //State local
    const [tipoRespuesta, setTipoRespuesta] = useState(false);
    const {respuestaSeleccionada} = instrumentoSt
    // const [respuestaSeleccionada, setRespuestaSeleccionada] = useState(null);
    const [nivelMadureza, setNivelMadurez] = useState(null);
    const [cumplimientoValue, setCumplimientoValue] = useState(false);
    const [freeTextValue, setFreeTextValue] = useState('');
    const [optionValue, setOptionValue] = useState('');
    const [finalLevel, setFinalLevel] = useState(0);
    const [showEvidencias, setShowEvidencias] = useState(false);
    const [modalData, setModalData] = useState({
        show: false,
        title: '',
        desc: '',
        info: null,
        list: null
    });

    // Cierra Modal de confirmacion
    const closeModal = () => {
        setModalData({
            ...modalData,
            show: false
        })
    }

    /**
     * Cambiamos el valor de la pregunta sencilla (si, no)
     * @param {*} e 
     */
    const changeCumplimiento = (e) => {
        setCumplimientoValue(e.target.value === 'si' && e.target.checked);        
    }

    /**
     * Cambio de valor para preguntas de opcion multiple
     * @param {*} e 
     */
    const changeOption = (e) => {
        setOptionValue(e.target.value);
    }

    /**
     * Cambio de valor para pregunta de texto libre
     * @param {*} e 
     */
    const changeFreeText = (e) => {
        setFreeTextValue(e.target.value);
    }

    // Confirmar Continuar
    const confirmContinue = () => {
        console.log(appSt)
        setModalData({
            show: true,
            title: 'Continuar',
            desc: appSt.areaData.idAreaCemefi === 2 ? '¿Desea cargar el siguiente documento del indicador?' : '¿Desea pasar al siguiente nivel de desarrollo?',
            info: '',
            action: () => siguienteNivel()
        })
    }

    /**
     * obtenemos los contadores y catalogos de los segmentos actualizados
     */
    const getCounters = () => {
        const idEntidad = registroConvocatoriaSt.empresaSeleccionada.idEntidad;
        const idConvocatoria = registroConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria
        //Criterio
        dispatch(getCriteriosInstrumentoCatalogo({...appSt.serviceData, idEntidad, idConvocatoria}));
        //Ambito
        dispatch(getAmbitosInstrumentoCatalogo({ ...appSt.serviceData, idCriterio: instrumentoSt.criterioSelected.idCriterio, idEntidad, idConvocatoria }));
        //Subambito
        dispatch(getSubambitosInstrumentoCatalogo(
            { ...appSt.serviceData, idCriterio: instrumentoSt.criterioSelected.idCriterio, idAmbito: instrumentoSt.ambitoSelected.idAmbito, idEntidad, idConvocatoria }
        ));
        //Indicador
        dispatch(getIndicadoresInstrumentoCatalogo({ ...appSt.serviceData, idCriterio: instrumentoSt.criterioSelected.idCriterio, idAmbito: instrumentoSt.ambitoSelected.idAmbito, idSubAmbito: instrumentoSt.subambitoSelected.idSubambito, idEntidad, idConvocatoria }));
    }

    /**
     * Boton de continuar
     */
    const siguienteNivel = () => {
        const nivel = respuestaSeleccionada.valorRespuesta;
        if (nivel !== finalLevel) {
            const nextLevel = instrumentoSt.preguntaSeleccionada.respuesta.find(resp => resp.valorRespuesta === nivel+1);
            dispatch(changeRespuestaSeleccionada(nextLevel));
            // setRespuestaSeleccionada(nextLevel);
        }
        setShowEvidencias(false);
        updateInstrumento();
        getCounters();
        closeModal();
    }

    /**
     * Siguiente Indicador
     */
    const nextIndicador = () => {
        const indexIndicador = catalogosSt.indicadores.findIndex(ind => {
            return ind.idIndicador === instrumentoSt.indicadorSelected.idIndicador
        })
        if (indexIndicador !== catalogosSt.indicadores.length-1) {
            const nextIndicador = catalogosSt.indicadores[indexIndicador+1];
            dispatch(changePregunta(null));
            dispatch(changePreguntas(null));
            dispatch(changeIndicadorSelected(nextIndicador));
            dispatch(changeRespuestaSeleccionada(null));
            const query = {
                cveCriterio: instrumentoSt.criterioSelected.cveCriterio,
                cveAmbito: instrumentoSt.ambitoSelected.cveAmbito,
                cveSubambito: instrumentoSt.subambitoSelected.cveSubambito,
                cveIndicador: nextIndicador.cveIndicador
            }
            const data = {
                ...appSt.serviceData,
                idTipoEntidad: registroConvocatoriaSt.empresaSeleccionada.idTipoEntidad,
                idTamanio: registroConvocatoriaSt.empresaSeleccionada.idTamanio,
                idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
            }
            updateInstrumento();
            dispatch(obtenerInstrumentoMedicionConvocatoria({...data, query}));
            dispatch(setQuery(query));
        } else {
            dispatch(setAlert({
                show: true,
                message: 'No existe un siguiente indicador',
                type: ALERT_WARN
            }))
        }
    }

    /**
     * Siguiente Pregunta
     */
    const siguientePregunta = () => {
        const index = preguntas.findIndex(pre => {
            return pre.idPreguntaIndicador === instrumentoSt.preguntaSeleccionada.idPreguntaIndicador
        });
        setShowEvidencias(false);
        if (index === preguntas.length-1) {
            //Ultima pregunta del indicador, tiene q pasar al siguiente indicador si no es el ultimo
            nextIndicador();
        } else {
            const next = preguntas[index+1];
            dispatch(changeRespuestaSeleccionada(null));
            dispatch(changePregunta(next));
        }
        getCounters();
    }
    
    const updateInstrumento = async () => {
        if (instrumentoSt.instrumento && instrumentoSt.instrumento.idEstatusInstrumentoRegistro === catalogosSt.idsEstatusInstrumentoRegistro.asignado) {
            dispatch(setLoading(true));
            try {
                const resp = await putInstrumentoRegistro({...appSt.serviceData, idInstrumentoRegistro: instrumentoSt.instrumento.idInstrumentoRegistro}, {
                    cveEstatusInstrumentoRegistro: INSTRUMENTOS_CVE_ESTATUS.enProceso
                });
                console.log('puede q sea aqui?', resp);
                if(resp) {
                    dispatch(setInstrumento(resp));
                }
               dispatch(setLoading(false));                
            } catch (error) {
                dispatch(setLoading(false));                
                dispatch(setAlert({
                    show: true, 
                    message: 'Error al cambiar de estatus el instrumento',
                    type: ALERT_WARN
                }))
                
            }
        }
    }

    /**
     * Abrimos modal de confirmacion de descartar nivel
     */
    const confirmDescartarNivel = () => {
        setModalData({
            show: true,
            title: appSt.areaData.idAreaCemefi === 2 ? 'Descartar Documento' : 'Descartar Nivel',
            desc: '¿Al descartar se borrara el progreso de niveles subsecuentes y el actual, desea continuar?',
            info: null,
            action: () => descartarNivel()
        })

    }

    /**
     * Eliminamos la info de un nivel y de los nivels subsecuentes si es q hay
     */
    const descartarNivel = async () => {
        const promises = [];
        const respuesta = instrumentoSt.respuestaSeleccionada;
        console.log(instrumentoSt.lastProgress, respuesta.valorRespuesta, instrumentoSt.evidencias)
        // Eliminamos las evidencias
        if (instrumentoSt.evidencias) {
            for (const ev of instrumentoSt.evidencias) {
                await deleteFile(ev);           
            }
        }
        // Eliminamos respuesta
        promises.push(
            deleteRespuestaInstrumentoNMRegistro({ ...appSt.serviceData, idRespuestaNmInstrumentoRegistro: respuesta.idRespuestaNmInstrumentoRegistro })
        )
        promises.push(
            deleteRespuestaInstrumento({ ...appSt.serviceData, idRespuestaInstrumentoRegistro: respuesta.idRespuestaInstrumentoRegistro })
        )
        if (respuesta.valorRespuesta === 1) {
            console.log('borramos todo')
            promises.push(
                deletePreguntaInstrumento({ ...appSt.serviceData, idPreguntaInstrumentoRegistro: respuesta.idPreguntaInstrumentoRegistro })
            )
        }
        // // Eliminamos respuestas posteriores si es q hay
        if (instrumentoSt.lastProgress > respuesta.valorRespuesta) {
            deleteAllEvidences()
        }
        getCounters();
        saveFirebaseProgress();
        closeModal();
    }

    /**
     * Eliminamos una evidencia
     * @param {*} evidencia 
     */
    const deleteFile = async (evidencia) => {
        console.log(evidencia)
        dispatch(setLoading(true));
        try {
            if (evidencia.idEvidenciaRespuesta) {
                await deleteEvidenciaRespuesta({
                    ...appSt.serviceData,
                    idEvidenciaRespuesta: evidencia.idEvidenciaRespuesta
                })
            } else {
                await deleteEvidenciaRespuestaNm({
                    ...appSt.serviceData,
                    idEvidenciaRespuestaNm: evidencia.idEvidenciaRespuestaNm
                })
            }

            const data = {
                ...appSt.serviceData,
                idTipoEntidad: registroConvocatoriaSt.empresaSeleccionada.idTipoEntidad,
                idTamanio: registroConvocatoriaSt.empresaSeleccionada.idTamanio,
                idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
            }
            dispatch(obtenerInstrumentoMedicionConvocatoria({ ...data, query: instrumentoSt.query }));

            dispatch(setAlert({
                show: true,
                message: 'Documento Eliminado',
                type: ALERT_SUCCESS
            }));
            dispatch(setLoading(false));
            getCounters();
        } catch (error) {
            console.log(error)
            dispatch(setAlert({
                show: true,
                message: 'Error al eliminar documento',
                type: ALERT_FAIL
            }));
            dispatch(setLoading(false));
        }
    }

    /**
     * Eliminamos el progreso de los niveles subsecuentes
     * @param {*} evidencia 
     */
    const deleteAllEvidences = async (evidencia) => {
        //obtenemos unicamente las evidencias de niveles superiores para eliminarlos
        const current = instrumentoSt.respuestaSeleccionada.valorRespuesta;
        const toDelete = instrumentoSt.preguntaSeleccionada.respuesta.filter(q => q.valorRespuesta > current && q.idPreguntaInstrumentoRegistro);
        const promisesEvidencias = [];
        console.log(toDelete, current)
        //llamamos al servicio para obtener las evidencias de los niveles superiores
        try {
            toDelete.forEach(nivel => {
                promisesEvidencias.push(
                    getEvidenciaRespuestaNm({ ...appSt.serviceData, idRespuestaNivelMadurez: nivel.idRespuestaNmInstrumentoRegistro })
                )
            })
            const evidenciasResponse = await Promise.allSettled(promisesEvidencias);
            const evidencias = [];
            //formateamos la respuesta para agrupar todas las evidencias por eliminar
            evidenciasResponse.forEach(resp => {
                resp.value.forEach(ev => {
                    evidencias.push(ev);
                })
            })
            //Eliminamos las evidencias
            const evidenciasDeletePromises = [];
            const nmRespuestaRegistroPromises = [];
            const respuestaRegistroPromises = [];
            const preguntaRegistroPromises = [];
            evidencias.forEach(file => {
                evidenciasDeletePromises.push(
                    deleteEvidenciaRespuestaNm({ ...appSt.serviceData, idEvidenciaRespuestaNm: file.idEvidenciaRespuestaNm })
                )
            });
            toDelete.forEach(respuesta => {
                nmRespuestaRegistroPromises.push(
                    deleteRespuestaInstrumentoNMRegistro({ ...appSt.serviceData, idRespuestaNmInstrumentoRegistro: respuesta.idRespuestaNmInstrumentoRegistro })
                )
                respuestaRegistroPromises.push(
                    deleteRespuestaInstrumento({ ...appSt.serviceData, idRespuestaInstrumentoRegistro: respuesta.idRespuestaInstrumentoRegistro })
                )
                // preguntaRegistroPromises.push(
                //     deletePreguntaInstrumento({ ...appSt.serviceData, idPreguntaInstrumentoRegistro: respuesta.idPreguntaInstrumentoRegistro })
                // )

            });
            await Promise.allSettled(evidenciasDeletePromises);
            await Promise.allSettled(nmRespuestaRegistroPromises);
            await Promise.allSettled(respuestaRegistroPromises);
            await Promise.allSettled(preguntaRegistroPromises);

            if (evidencia) {
                await deleteFile(evidencia);
            }

            closeModal();
        } catch (error) {
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: 'Error al eliminar evidencias',
                type: ALERT_FAIL
            }))

        }
    }

    /**
     * Guardamos el progreso, segementos, pregunta, nivel seleccionado
     * igual, se movio a evidencia
     */
    const saveFirebaseProgress = () => {
        const firebaseBody = {
            indicadorSelected: instrumentoSt.indicadorSelected,
            subambitoSelected: instrumentoSt.subambitoSelected,
            ambitoSelected: instrumentoSt.ambitoSelected,
            criterioSelected: instrumentoSt.criterioSelected,
            preguntaSeleccionada: instrumentoSt.preguntaSeleccionada,
            respuestaSeleccionada: instrumentoSt.respuestaSeleccionada,
            query: instrumentoSt.query    
        }
        firebaseDb.collection(FIREBASE.instrumentProgressCollection).doc(registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria.toString()).set(firebaseBody).then(doc => {
            dispatch(setAlert({
                show: true,
                message: 'Progreso Guardado',
                type: ALERT_SUCCESS
            }))
        })
    }

    /**
     * Checamos el valor de la respuesta para mostrarla 
     */
    const checkRespuestaRegistrada = () => {

        if (respuestaSeleccionada.valorRespInstrReg) {
            switch (respuestaSeleccionada.idTipoRespuesta) {
                case catalogosSt.idsTipoRespuesta.cumplimiento:
                    setCumplimientoValue(respuestaSeleccionada.valorRespInstrReg === '1');
                    break;
                case catalogosSt.idsTipoRespuesta.nivelMadurez:
                    setCumplimientoValue(respuestaSeleccionada.valorRespInstrReg !== null) 
                    break;
                case catalogosSt.idsTipoRespuesta.opcionMultiple:      
                case catalogosSt.idsTipoRespuesta.magnitud:      
                    setOptionValue(respuestaSeleccionada.valorRespInstrReg);    
                    break;
                case catalogosSt.idsTipoRespuesta.textoAbierto:
                    setFreeTextValue(respuestaSeleccionada.valorRespInstrReg);                    
                    break;

                default:
                    break;
            } 
        } else {
            setCumplimientoValue(false);
            setFreeTextValue('');
            setOptionValue('');
        }
    }

    /**
     * Actualizamos la respuesta seleccionada
     */
    const updateRespuesta = () => {
        if (respuestaSeleccionada && respuestaSeleccionada.idTipoRespuesta === catalogosSt.idsTipoRespuesta.nivelMadurez) {
            const index = instrumentoSt.preguntaSeleccionada.respuesta.findIndex(nivel => nivel.valorRespuesta === respuestaSeleccionada.valorRespuesta);
            dispatch(changeRespuestaSeleccionada(instrumentoSt.preguntaSeleccionada.respuesta[index]))
            // setRespuestaSeleccionada(instrumentoSt.preguntaSeleccionada.respuesta[index])
        } else {
            dispatch(changeRespuestaSeleccionada(instrumentoSt.preguntaSeleccionada.respuesta[0]))
            // setRespuestaSeleccionada(instrumentoSt.preguntaSeleccionada.respuesta[0]);
        }

        if (catalogosSt.nivelMadurez === null) {
            dispatch(getNivelMadurezCatalogo(appSt.serviceData));
        }
    }

    /**
     * Validamos la respuesta para habilidar/deshabilitar el boton de continuar 
     * @returns 
     */
    const validateResponse = () => {
        return (tipoRespuesta === catalogosSt.idsTipoRespuesta.nivelMadurez && (respuestaSeleccionada.valorRespInstrReg === null || (instrumentoSt.evidencias && instrumentoSt.evidencias.length === 0))) ||
            (tipoRespuesta !== catalogosSt.idsTipoRespuesta.nivelMadurez && respuestaSeleccionada.valorRespInstrReg && respuestaSeleccionada.evidenciaResp)
    }

    /**
     * Checamos si el ya existe una respuesta del servicio
     */
    useEffect(() => {
        if (respuestaSeleccionada) {
            checkRespuestaRegistrada();
        }
    }, [respuestaSeleccionada])

    /**
     * Actualizamos la respuesta en la seleccion de una pregunta
     */
    useEffect(() => {
        if (instrumentoSt.preguntaSeleccionada) {
            updateRespuesta();
        }
    }, [instrumentoSt.preguntaSeleccionada]);
    
    /**
     * Setteamos el tipo de respuesta cuando se selecciona una pregunta o respuesta
     */
    useEffect(() => {
        console.log(appSt)
        if (instrumentoSt.preguntaSeleccionada) {
            let level = 0;
            instrumentoSt.preguntaSeleccionada.respuesta.forEach(nivel => {
                if (nivel.valorRespuesta > level) {
                    level = nivel.valorRespuesta
                }
            })
            setFinalLevel(level);
        }
        if (respuestaSeleccionada) {
            setShowEvidencias(false)
            setTipoRespuesta(respuestaSeleccionada.idTipoRespuesta);
            if (respuestaSeleccionada.idTipoRespuesta === catalogosSt.idsTipoRespuesta.nivelMadurez) {
                setNivelMadurez(respuestaSeleccionada.valorRespuesta);
            } else {
                setNivelMadurez(null);
            }    
        }
    }, [respuestaSeleccionada, instrumentoSt.preguntaSeleccionada])

    return <div className="pregunta-wrapper">
        {instrumentoSt.preguntaSeleccionada &&
            <>
                {tipoRespuesta === catalogosSt.idsTipoRespuesta.nivelMadurez &&
                    <EtapasNav respuestaSeleccionada={respuestaSeleccionada} />
                }

                {respuestaSeleccionada &&
                    <div className="pregunta-section">
                        <div className=''>                            
                            <div className="info-card">
                                {instrumentoSt.preguntaSeleccionada.objetivos && instrumentoSt.preguntaSeleccionada.objetivos.length !== 0 &&
                                    <div className="tool-section">
                                        <p><b>ODS</b></p>
                                        <div className="objetivos">
                                            {instrumentoSt.preguntaSeleccionada.objetivos.map((obj, index) => {
                                                return <small key={index}>{obj.idObjetivo2.descObjetivo}</small>
                                            })}
                                        </div>
                                    </div>
                                }                                
                            </div>

                            <div className="pregunta">
                                {instrumentoSt.preguntaSeleccionada.ayuda && instrumentoSt.preguntaSeleccionada.ayuda !== '' && 
                                    <div className="tool-tip">
                                        <p>?</p>
                                        <div className="tool">
                                            <div className="info-section">
                                                <p><b>Ayuda</b></p>
                                                <p>{instrumentoSt.preguntaSeleccionada.ayuda}</p>
                                            </div>
                                        </div>
                                    </div>                                
                                }
                                <div className="pregunta-head">
                                    <h2>{instrumentoSt.preguntaSeleccionada.descPreguntaIndicador}</h2>
                                </div>
                                <div className="respuesta-content">
                                    {(tipoRespuesta === catalogosSt.idsTipoRespuesta.cumplimiento || tipoRespuesta === catalogosSt.idsTipoRespuesta.nivelMadurez) &&
                                        <div className="respuesta">
                                            <div className={cumplimientoValue ? 'radio-wrapper active' : 'radio-wrapper'}>
                                                <input disabled={instrumentoSt.instrumento && onlyView} value="si" type="radio" name="respuesta" onChange={(e) => changeCumplimiento(e)} onClick={(e)=> changeCumplimiento(e)}/>
                                                <label>Sí</label>
                                            </div>
                                            {/* <div className={!cumplimientoValue ? 'radio-wrapper active' : 'radio-wrapper'}>
                                                <input value="no" type="radio" name="respuesta" onChange={(e) => changeCumplimiento(e)} onClick={(e)=> changeCumplimiento(e)}/>
                                                <label>No</label>
                                            </div> */}
                                        </div>
                                    }
                                    {tipoRespuesta === catalogosSt.idsTipoRespuesta.textoAbierto &&
                                        <textarea disabled={instrumentoSt.instrumento && onlyView} value={freeTextValue} onChange={(e) => changeFreeText(e)} name="" id="" cols="30" rows="10"></textarea>
                                    }

                                    {(tipoRespuesta === catalogosSt.idsTipoRespuesta.opcionMultiple || tipoRespuesta === catalogosSt.idsTipoRespuesta.magnitud) && instrumentoSt.preguntaSeleccionada.respuesta.map((option, index) => {
                                        return <div className="radio-wrapper" key={index}>
                                                <input disabled={instrumentoSt.instrumento && onlyView} checked={String(option.valorRespuesta) === optionValue} type="radio" name="option" value={option.valorRespuesta} onChange={(e) => changeOption(e)} />
                                                <label>{option.descRespuesta} {tipoRespuesta === catalogosSt.idsTipoRespuesta.magnitud && <span>{option.valorRespuesta}</span>}</label>
                                            </div>
                                        })
                                    }
                                </div>
                                {instrumentoSt.instrumento && instrumentoSt.instrumento.idEstatusInstrumentoRegistro !== catalogosSt.idsEstatusInstrumentoRegistro.liberado && 
                                    <div className="pregunta-action">
                                        <button disabled={!cumplimientoValue && freeTextValue === '' && optionValue === ''} onClick={() => setShowEvidencias(true)}>Cargar Evidencia</button>
                                    </div>                                
                                }
                            </div>
                            {instrumentoSt.preguntaSeleccionada.referencia && instrumentoSt.preguntaSeleccionada.referencia !== '' &&
                                <div className="info-card">
                                    <div className="info-section">
                                        <p><b>Referencia</b></p>
                                        <p>{instrumentoSt.preguntaSeleccionada.referencia}</p>
                                    </div>
                                </div>                            
                            }
                        </div>

                        {(showEvidencias || respuestaSeleccionada.valorRespInstrReg) &&
                            <SubirEvidencia 
                                descartarNivel={confirmDescartarNivel}
                                deleteFile={deleteFile}
                                deleteAllEvidences={deleteAllEvidences}
                                respuestaRegistrada={respuestaSeleccionada} 
                                tipoRespuesta={tipoRespuesta} 
                                cumplimientoValue={cumplimientoValue} 
                                optionValue={optionValue} 
                                freeTextValue={freeTextValue} 
                                nivelMadureza={nivelMadureza} 
                                saveFirebaseProgress={saveFirebaseProgress}
                                onlyView={onlyView}
                                getCounters={getCounters}/>
                        }
                    </div>
                }

                {instrumentoSt.instrumento && !onlyView &&
                    <div className="actions">
                        {tipoRespuesta === catalogosSt.idsTipoRespuesta.nivelMadurez && 
                            <button disabled={instrumentoSt.respuestaSeleccionada && !instrumentoSt.respuestaSeleccionada.valorRespInstrReg} onClick={confirmDescartarNivel}>
                                {appSt.areaData.idAreaCemefi === 2 ? 'Descartar Documento' : 'Descartar Nivel'}
                            </button>                    
                        }
                        {respuestaSeleccionada && respuestaSeleccionada.valorRespuesta !== finalLevel && 
                            <button onClick={siguientePregunta}>Siguiente Pregunta</button>                                        
                        }
                        {respuestaSeleccionada && respuestaSeleccionada.valorRespuesta !== finalLevel && 
                            <button disabled={validateResponse()} onClick={confirmContinue}>Continuar</button>                                        
                        }
                        {(tipoRespuesta !== catalogosSt.idsTipoRespuesta.nivelMadurez || (tipoRespuesta === catalogosSt.idsTipoRespuesta.nivelMadurez && respuestaSeleccionada && respuestaSeleccionada.valorRespuesta === finalLevel))  && 
                            <button onClick={siguientePregunta}>Guardar</button>                                        
                        }
                        {/* {instrumentoSt.preguntaSeleccionada.idPreguntaIndicador !== preguntas[preguntas.length - 1].idPreguntaIndicador && 
                        } */}
                    </div>                
                }


            </>
        }

        <ConfirmModal data={modalData} cancel={closeModal}/>
    </div>
}