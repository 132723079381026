export const pagoLineaForm = {
  nombre: {
      value: '',
      required: true,
      message: 'Dato requerido',
      tool: '',
      invalid: false,
  },
  email: {
    value: '',
    required: true,
    message: 'Dato requerido',
    tool: '',
    invalid: false,
  },
  telefono: {
    value: '',
    required: true,
    message: 'Dato requerido',
    tool: '',
    invalid: false,
  },
  formaPago: {
    value: '',
    required: false,
    message: 'Dato requerido',
    tool: '',
    invalid: false,
  },
}