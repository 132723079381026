import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteDocumentoEntidad, getDocument, getEntidadesFilter, postDocumentoEntidad, putDocumentoEntidad, uploadDocument } from "../../services/entidad";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download_file.svg';
import { getBase64 } from "../../app/commons";
import { ALERT_FAIL, ALERT_SUCCESS, ALERT_WARN, CVE_TIPODOC_CONVOCATORIA, RFC_CEMEFI } from "../../app/constantes";
import { updateEmpresaSeleccionada } from "../../slices/registroSlice";
import { getDocsConvocatoria, registroConvocatoriaState, updateEmpresaSeleccionada as updateEmpresaSeleccionadaConvocatoria } from "../../slices/registroConvocatoriaSlice";
import { deleteDocumentosConvocatoria, getRegistrosConvocatoriaHijos, postDocumentoConvocatoria, putDocumentoConvocatoria } from "../../services/convocatorias";

export default function DocumentComponent({ doc, empresaSeleccionada, type, convocatoriaSeleccionada, adicional, getAdicionalesDocs, disabled }) {
    const appSt = useSelector(appState);
    const dispatch = useDispatch();
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const [preview, setPreview] = useState(null);
    const [acceptExt, setAcceptExt] = useState(null);
    const [acceptArray, setAcceptArray] = useState(null);
    const [convocatoriaSeleccionadaAdicional, setConvocatoriaSeleccionadaAdicional] = useState(null);

    const getPreview = async () => {
        const data = {
            ...appSt.serviceData,
            idEntidad: empresaSeleccionada.idEntidad,
            idContent: doc.idContent
        }
        const docBase = await getDocument(data);
        if (docBase.payload) {
            const type = doc.idContent.split('.').pop().toLowerCase();
            if (type === 'png' || type === 'jpg' || type === 'jpeg') {
                const urlBase = `data:image/${type};base64, ${docBase.payload.base64}`;
                setPreview(urlBase);
            }
        }
    }

    const downloadFile = async (evidencia) => {
        const docResp = await getDocument({
            ...appSt.serviceData,
            idEntidad: empresaSeleccionada.idEntidad,
            idContent: doc.idContent
        });

        
        if (docResp.payload) {
            const type = doc.idContent.split('.').pop();
            const dataType = type === 'pdf' ? 'data:application/pdf' : `data:image/${type}`;
            // const type = 'data:image/png'
            const file64 = docResp.payload.base64;
            const linkSource = `${dataType};base64,${file64}`;
            const downloadLink = document.createElement('a');
            const fileName = doc.descDocumentoCemefi;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click()
        }
    }

    const downloadPlantilla = async () => {
        try {
            const cemefi = await getEntidadesFilter({
                ...appSt.serviceData,
                body: {
                    rfc: RFC_CEMEFI
                }
            })

            const docResp = await getDocument({
                ...appSt.serviceData,
                idEntidad: cemefi[0].idEntidad,
                idContent: doc.plantilla
            });
    
            
            if (docResp.payload) {
                const type = doc.plantilla.split('.').pop();
                console.log(type)
                let dataType
                switch (type) {
                    case "pdf":
                        dataType = "data:application/pdf"
                        break;
                    case "xml":
                        dataType = "data:application/xml"
                        break;
                    case "docx":
                        console.log("entra")
                        dataType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        break;
                    default:
                        dataType = `data:image/${type}`
                        break;
                }
                // const type = 'data:image/png'
                const file64 = docResp.payload.base64;
                const linkSource = `${dataType};base64,${file64}`;
                console.log(linkSource)
                const downloadLink = document.createElement('a');
                const fileName = doc.descDocumentoCemefi;
    
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click()
            }
            
        } catch (error) {
            console.log(error)
        }
    }

    const deleteDocHijos = async () => {
        const resp = await getRegistrosConvocatoriaHijos({
            ...appSt.serviceData, 
            idEntidad: empresaSeleccionada.idEntidad,
            idConvocatoria: convocatoriaSeleccionada.idConvocatoria
        })

        if (resp.length > 0) {
            const promises = [];
            resp.forEach(registro => {
                promises.push(deleteDocumentosConvocatoria({
                    ...appSt.serviceData,
                    idRegistroConvocatoriaDocumento: registro.idRegistroConvocatoriaDocumento
                }))
            })

            await Promise.allSettled(promises);
        }
    }

    const deleteFile = async (doc) => {
        dispatch(setLoading(true));
        try {
            dispatch(setLoading(false));
            if (type === CVE_TIPODOC_CONVOCATORIA) {
                await deleteDocumentosConvocatoria({
                    ...appSt.serviceData,
                    idRegistroConvocatoriaDocumento: doc.idRegistroConvocatoriaDocumento
                });
                if (empresaSeleccionada.isCorporativo || empresaSeleccionada.isSedeMarca || empresaSeleccionada.isMiembro) {
                    await deleteDocHijos()
                }
            } else {
                await deleteDocumentoEntidad({
                    ...appSt.serviceData,
                    idDocumentoEntidad: doc.idDocumentoEntidad
                });
            }
            setPreview(null);
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: 'Documento Eliminado',
                type: ALERT_SUCCESS
            }))
            if (type === CVE_TIPODOC_CONVOCATORIA && !adicional) {
                dispatch(updateEmpresaSeleccionadaConvocatoria({
                    ...appSt.serviceData,
                    idEntidad: empresaSeleccionada.idEntidad
                }))
                dispatch(getDocsConvocatoria({
                    ...appSt.serviceData, 
                    idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria
                }))
            } else {
                dispatch(updateEmpresaSeleccionada({
                    ...appSt.serviceData,
                    idEntidad: empresaSeleccionada.idEntidad
                }));
            }
            if (type === CVE_TIPODOC_CONVOCATORIA && adicional) {
                getAdicionalesDocs();
            }
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: 'Error al eliminar documento',
                type: ALERT_FAIL
            }))            
        }
    }

    const uploadFile = async (e, doc) => {
        const file = e.target.files[0];
        const maxSize = 4000000;
        const extencion = `.${file.name.split('.').pop().toLowerCase()}`;
        let valid = false;
       /*  if (file.size > maxSize) {
            dispatch(setAlert({
                show: true,
                message: 'El tamaño del archivo supera los 4MB',
                type: ALERT_FAIL
            }))
            return
        } */
        acceptArray.forEach(ext => {
            if (extencion.includes(ext)) {
                
                valid = true;
            }            
        })
        if (file && valid) {
            dispatch(setLoading(true));
            const base64 = await getBase64(file);
            const body = {
                cveTipoDocumento: type,
                cveDocumentoCemefi: doc.cveDocumentoCemefi,
                archivo: {
                    nombreArchivo: file.name.replace('#', ''),
                    tipo: file.type,
                    base64: base64
                }
            }
            const data = {
                ...appSt.serviceData,
                idEntidad: empresaSeleccionada.idEntidad
            }
    
            const resp = await uploadDocument(data, body);
    
            if (resp.payload) {
                const bodyDocumentoEntidad = {
                    idDocumentoCemefi: doc.idDocumentoCemefi,
                    idContent: resp.payload.contentId
                }
                let respDocument;
                if (type !== CVE_TIPODOC_CONVOCATORIA) {
                    // caso documento entidad
                    if (doc.idDocumentoEntidad) {
                        respDocument = await putDocumentoEntidad(
                            {
                                ...appSt.serviceData, 
                                idDocumentoEntidad: doc.idDocumentoEntidad
                            }, 
                            {
                                idEntidad: empresaSeleccionada.idEntidad,
                                ...bodyDocumentoEntidad,
                            });
                    } else {
                        respDocument = await postDocumentoEntidad(appSt.serviceData, {
                            ...bodyDocumentoEntidad,
                            idEntidad: empresaSeleccionada.idEntidad,
                        });
                    }
                } else if (type === CVE_TIPODOC_CONVOCATORIA && !adicional) {
                    // caso documento convocatoria
                    if (doc.idRegistroConvocatoriaDocumento) {
                        respDocument = await putDocumentoConvocatoria(
                            {
                                ...appSt.serviceData, 
                                idRegistroConvocatoriaDocumento: doc.idRegistroConvocatoriaDocumento
                            }, {
                                ...bodyDocumentoEntidad,
                                idRegistroConvocatoria: convocatoriaSeleccionada.idRegistroConvocatoria
                            });
                    } else {
                        respDocument = await postDocumentoConvocatoria(appSt.serviceData, {
                            ...bodyDocumentoEntidad,
                            idRegistroConvocatoria: convocatoriaSeleccionada.idRegistroConvocatoria
                        });
                    }
                } else {
                    // caso documento convocatoria adicional
                    if (doc.idRegistroConvocatoriaDocumento) {
                        respDocument = await putDocumentoConvocatoria(
                            {
                                ...appSt.serviceData, 
                                idRegistroConvocatoriaDocumento: doc.idRegistroConvocatoriaDocumento
                            }, {
                                ...bodyDocumentoEntidad,
                                idRegistroConvocatoria: convocatoriaSeleccionadaAdicional.idRegistroConvocatoria
                            });
                    } else {
                        respDocument = await postDocumentoConvocatoria(appSt.serviceData, {
                            ...bodyDocumentoEntidad,
                            idRegistroConvocatoria: convocatoriaSeleccionadaAdicional.idRegistroConvocatoria
                        });
                    }
                }
    
                if (respDocument.payload || respDocument.idContent) {
                    dispatch(setAlert({
                        show: true,
                        message: 'Documento Cargado',
                        type: ALERT_SUCCESS
                    }));
                    if (type !== CVE_TIPODOC_CONVOCATORIA ) {
                        dispatch(updateEmpresaSeleccionada({
                            ...appSt.serviceData,
                            idEntidad: empresaSeleccionada.idEntidad
                        }));
                    } else if (type === CVE_TIPODOC_CONVOCATORIA && !adicional) {
                        dispatch(updateEmpresaSeleccionadaConvocatoria({
                            ...appSt.serviceData,
                            idEntidad: empresaSeleccionada.idEntidad
                        }))
                        dispatch(getDocsConvocatoria({
                            ...appSt.serviceData, 
                            idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria
                        }))
                    } else if (type === CVE_TIPODOC_CONVOCATORIA && adicional)  {
                        await getAdicionalesDocs();
                    }
                    dispatch(setLoading(false));
                } else {
                    dispatch(setAlert({
                        show: true,
                        message: 'Error al subir documento',
                        type: ALERT_FAIL
                    }));
                    dispatch(setLoading(false));
                }
    
            } else {
                dispatch(setAlert({
                    show: true,
                    message: 'Error al subir documento',
                    type: ALERT_FAIL
                }));
                dispatch(setLoading(false));
            }
        } else {
            dispatch(setAlert({
                show: true,
                message: `El tipo de archivo no es valido, el archivo tiene que ser: ${acceptExt}` ,
                type: ALERT_WARN
            }))
        }

        e.target.value = '';
    }

    const setDocRestrictions = (doc) => {
        if (doc.extension) {
            const exts = doc.extension.split(',');
            let accept = '';
            let acceptArray = [];
            exts.forEach(ext => {
                accept = `${accept} .${ext.toLowerCase()}`;
                acceptArray.push(`.${ext.replace(' ', '').toLowerCase()}`);
            });
            setAcceptExt(accept);
            setAcceptArray(acceptArray);
        }
    }

    const getConvocatoriaAdicional = () => {
        const convocatoria = empresaSeleccionada.cemRegistroConvocatorias.find(conv => conv.idConvocatoria === convocatoriaSeleccionada.idConvocatoria);

        if (convocatoria) {
            setConvocatoriaSeleccionadaAdicional(convocatoria);
        }
    }

    const getEvidenciaName = (filePath) => {
        const path = filePath.split('/');
        return path.slice(-1);
    }

    useEffect(() => {
        setDocRestrictions(doc);
        if (convocatoriaSeleccionada) {
            getConvocatoriaAdicional();
        }
        if (doc.idContent) {
            getPreview();
        } else {
            setPreview(null);
        }
        console.log(doc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doc, empresaSeleccionada]);

    return <div className={doc.idContent || doc.plantilla ? 'document uploaded' : 'document'}>
        <div className="doc-content">
            <div className="head">
                {/* <p>{preview ? 'si' : 'no'}</p> */}
                <p>{doc.descDocumentoCemefi}</p>
            </div>
            {preview && doc.idContent &&
                <img src={preview} alt="" />
            }
            {!preview && !doc.plantilla &&
                <UploadIcon />
            }
            {doc.plantilla && <DownloadIcon />}
            {!disabled && !doc.plantilla &&
                <p>{doc.idContent ? 'Reemplazar Documento' : 'Subir Documento'}</p>
            }
            {!doc.plantilla && (
                <input disabled={disabled} type="file" onChange={(e) => uploadFile(e, doc)} />
            )}
            {doc && doc.idContent &&
                <p>{getEvidenciaName(doc.idContent)}</p>
            }
            {doc.legend && doc.legend.map((item, index) => {
                return <p key={index}>{item}</p>
            })}
            
        </div>
        {doc.idContent && 
            <div className="doc-actions">
                <button disabled={disabled} onClick={() => deleteFile(doc)}>Eliminar</button>
                <button onClick={downloadFile}>Descargar</button>
            </div>        
        }
        {doc.plantilla && (
                <button onClick={downloadPlantilla}>Descargar</button>
        )}
    </div>
}