import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { changeView, registroConvocatoriaState, selectConvocatoria, setVisibilidadData, updateEmpresaSeleccionada } from "../../slices/registroConvocatoriaSlice";

import bg from '../../assets/convocatoria.jpg';

import { ReactComponent as UpIcon } from '../../assets/icons/go-up.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as PagosIcon } from '../../assets/icons/pay.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';

import { ALERT_FAIL, ALERT_SUCCESS, CONVOCATORIAS_ID, CVE_DIRECCION, CVE_ESTATUS_CORP_SEDE, CVE_ESTATUS_REGISTRO_CONVOCATORIA, CVE_ETATUS_ACEPTADA_CONVOCATORIA, CVE_ETATUS_RECHAZADA_CONVOCATORIA, CVE_ETATUS_REGISTRO_CONVOCATORIA, CVE_TIPODOC_CONVOCATORIA, INSTRUMENTOS_CVE_ESTATUS, INVITACION_STEP, LOGO_EMPRESA, NOTIFICACIONES, PAGOS, PUBLICACION_VIEW, REGISTRO_CONVOCATORIA, RETRO_VIEW, SELECCION_PREGUNTA } from "../../app/constantes";
import { deleteRegistroConvocatoria, getDocumentosConvocatoria, getEntidadRegistroConvocatoriaById, getEsquemaCobroConvocatoria, putRegistroConvocatoria } from "../../services/convocatorias";
import { appState, changeLogo, setAlert, setLoading } from "../../slices/appSlice";
import { getToNotification, isEntidadInvididual, validaConvocatoriaDoc } from "../../app/commons";
import { catalogosServiceState, getCriteriosInstrumentoCatalogo, getDocumentosCatalogo, getTipoDireccionCatalogo } from "../../slices/catalogosServiceSlice";
import { getCorporativo, getDocumentosConv, getEntidad } from "../../services/entidad";
import { getInstrumentoReport } from "../../slices/instrumentoServiceSlice";
import { postNotificacion } from "../../services/notificaciones";
import { usuariosServicesState } from "../../slices/usuariosServicesSlice";
import { getInstrumentoByConvocatoriaId, getInstrumentoVisible, getInstrumentosByEntidad, postInstrumentoRegistro } from "../../services/instrumento";
import moment from "moment";
import { setCurrentConvocatoria } from "../../slices/publicacionSlice";
import { setCurrentConvocatoria as setCurrentConvocatoriaRetro } from "../../slices/retroSlice";
import { setCurrentConvocatoria as setCurrentConvocatoriaPublicacion } from "../../slices/publicacionSlice";
import { getEsPublicada } from "../../services/publicacion";
import { changeEsquema, setCurrentConvocatoria as setCurrentConvocatoriaPagos } from "../../slices/pagosSlice";
import { setCurrentConvocatoriaCurso, changeEsquemaCurso } from "../../slices/pagosCursosSlice";
import { useNavigate } from "react-router-dom";

export default function ConvocatoriaCard({ convocatoria, setModalData, setModalActualizar, search }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const usuarioSt = useSelector(usuariosServicesState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const catalogosSt = useSelector(catalogosServiceState);
    const appSt = useSelector(appState);

    const [registro, setRegistro] = useState(null);
    const [showCard, setShowCard] = useState(false);
    const [canRegister, setCanRegister] = useState(false)

    const showActualiza = () => {
        return convocatoria.actualizarEntidad && convocatoria.cveEstatusRegistroConvocatoria !== CVE_ESTATUS_REGISTRO_CONVOCATORIA.aceptada
    }

    const goToView = (view) => {
        if (showActualiza()) {
            setModalActualizar({
                show: true,
                data: convocatoria
            })
            return
        }
        if (convocatoria.cvePrograma.includes('ESR')) {
            dispatch(changeLogo(LOGO_EMPRESA.esr));
        } else {
            dispatch(changeLogo(LOGO_EMPRESA.cemefi));
        }
        dispatch(changeView(view));
        const convocatoriaRegistrada = getConvocatoriaRegistrada()
        if (convocatoriaRegistrada) {
            const dataConv = {
                ...convocatoriaRegistrada,
                isInvitacion: convocatoria.isInvitacion,
                minimoInvitacion: convocatoria.minimoInvitacion,
                descConvocatoria: convocatoria.descConvocatoria,
                motivoRechazo: convocatoria.motivoRechazo,
                aniosParticipacionEP: convocatoria.aniosParticipacionEP,
                entidadesHijos: convocatoria.entidadesHijos,
                cvePrograma: convocatoria.cvePrograma
            }
            dispatch(selectConvocatoria(dataConv));
        } else {
            dispatch(selectConvocatoria(convocatoria));
        }
    };

    //Confirmar individual
    const showConfirmIndividual = (view) => {
        setModalData({
            show: true,
            title: 'Confirmación de Postulación Individual',
            desc: 'Confirmo que deseo postularme a la Convocatoria de manera particular e independiente a un grupo empresarial (una razón social)',
            action: () => goToView(view)
        })
    }

    const confirmIndividual = (view) => {
        if (showActualiza()) {
            setModalActualizar({
                show: true,
                data: convocatoria
            })
            return
        }
        if (isEntidadInvididual(registroConvocatoriaSt.empresaSeleccionada) && !registroConvocatoriaSt.empresaSeleccionada.idEntidadPadre && convocatoria.cvePrograma !== "CADENAVALOR" && convocatoria.cvePrograma !== "ENTIDADPROMOTORA") {
            showConfirmIndividual(view)
        } else {
            goToView(view)
        }

    }

    const invitacionValid = async (convocatoria) => {
        let valid = false;
        dispatch(setLoading(true));
        if (convocatoria.isInvitacion && convocatoria.minimoInvitacion) {
            const data = {
                ...appSt.serviceData,
                idRegistroConvocatoria:
                    convocatoria.idRegistroConvocatoria,
            };
            const resp = await getEntidadRegistroConvocatoriaById(data);
            if (resp && resp.length >= convocatoria.minimoInvitacion) {
                valid = true;
            }
        } else {
            valid = true;
        }
        dispatch(setLoading(false));
        return valid
    }

    const handleNotificationsInv = async () => {
        const data = {
            ...appSt.serviceData,
            idRegistroConvocatoria:
                convocatoria.idRegistroConvocatoria
        };
        const invitadas = await getEntidadRegistroConvocatoriaById(data);
        if (invitadas && invitadas.length > 0) {
            for (const entidad of invitadas) {
                const data = await getEntidad({ ...appSt.serviceData, idEntidad: entidad.idEntidad });
                if (data) {
                    postNotificacion(appSt.serviceData, {
                        to: getToNotification(data, catalogosSt.idsTipoContacto),
                        templateData: {
                            convocatoria: convocatoria.descConvocatoria,
                            entidad: registroConvocatoriaSt.empresaSeleccionada.nombreComercial,
                            programa: convocatoria.descConvocatoria
                        },
                        templateId: NOTIFICACIONES.templatesIds.revisionInvitado
                    })
                }
            }
            // Se comento porq parece q solo se le debe enviar correo a las invitadas y no a la q invita
            // postNotificacion(appSt.serviceData, {
            //     to: [
            //         {email: usuarioSt.usuario.data.email,name: usuarioSt.usuario.data.nombre}
            //     ],
            //     templateData: {
            //         convocatoria: convocatoria.descConvocatoria,
            //         entidad: registroConvocatoriaSt.empresaSeleccionada.nombreComercial,
            //         programa: convocatoria.descConvocatoria
            //     },
            //     templateId: NOTIFICACIONES.templatesIds.revisionInvitado
            // })
        }

    }

    const registrarInstrumento = async (entidad) => {
        let resp;
        try {
            const respInstrumento = await getInstrumentosByEntidad({
                ...appSt.serviceData,
                idEntidad: entidad.idEntidad,
                idConvocatoria: convocatoria.idConvocatoria
            });
            const promises = []
            await respInstrumento.forEach(async (instrumento) => {
                const instrumentoBody = {
                    idInstrumentoMedicionConvocatoria: instrumento.idInstrumentoMedicionConvocatoria,
                    idRegistroConvocatoria: instrumento.idRegistroConvocatoria,
                    cveEstatusInstrumentoRegistro: INSTRUMENTOS_CVE_ESTATUS.asignado,
                    fechaAsignacionEntidad: moment().format('YYYY-MM-DD'),
                    isProrrogaEntidad: 0,
                    fechaProrrogaEntidad: null
                }
                promises.push(postInstrumentoRegistro(appSt.serviceData, instrumentoBody))
            })
            resp = Promise.allSettled(promises);
        } catch (error) {
            dispatch(setAlert({
                show: true,
                message: 'Error al registrar instrumento',
                type: ALERT_FAIL
            }))
        }
        return resp
    }

    const sendToValidate = async () => {
        if (showActualiza()) {
            setModalActualizar({
                show: true,
                data: convocatoria
            })
            return
        }
        dispatch(setLoading(true));
        const respConv = await getDocumentosConvocatoria({
            ...appSt.serviceData,
            idRegistroConvocatoria: convocatoria.idRegistroConvocatoria
        })
        const convData = {
            ...appSt.serviceData,
            idConvocatoria: convocatoria.idConvocatoria,
            cveEtapa: 'INSCRIPCION',
            cveTipoDocumento: 'REGISTROCONVOCATORIA'
        }
        const docsConvocatoria = await getDocumentosConv(convData);
        if (respConv && docsConvocatoria) {
            const convocatoriaDatos = convocatoria.idRegistroConvocatoria !== null;
            const invitacionCompleted = await invitacionValid(convocatoria);
            const entregaDir = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.entrega);
            if (!entregaDir) {
                dispatch(setAlert({
                    show: true,
                    message: 'Error al ubicar catalogo de direccion',
                    type: ALERT_FAIL
                }))
                return console.log('sin catalogo', catalogosSt.tipoDireccion);
            }
            const direccionValida = (convocatoria.idConvocatoria === CONVOCATORIAS_ID.insigniaInclusionSocial ||
                convocatoria.idConvocatoria === CONVOCATORIAS_ID.insigniaInversionSocial ||
                registroConvocatoriaSt.empresaSeleccionada.cemDireccionEntidads.find(dir => dir.idTipoDireccion === entregaDir.idTipoDireccion))
            const documentosValidos = validaConvocatoriaDoc(docsConvocatoria, respConv)

            if (convocatoriaDatos && direccionValida && documentosValidos && invitacionCompleted) {
                const data = {
                    ...appSt.serviceData,
                    idRegistroConvocatoria: convocatoria.idRegistroConvocatoria
                }
                const body = {
                    idEstatusRegistroConvocatoria: catalogosSt.idsEstatusRegistroConvocatoria.revision
                }

                try {
                    await putRegistroConvocatoria(data, body);
                    if (registroConvocatoriaSt.empresaSeleccionada.isCorporativo) {
                        await handleCorp('validar');
                    }
                    dispatch(setAlert({
                        show: true,
                        message: 'Registro enviado a validacion',
                        type: ALERT_SUCCESS
                    }))
                    const convData = {
                        ...appSt.serviceData,
                        idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad
                    }
                    dispatch(updateEmpresaSeleccionada(convData));
                    search()
                    if (convocatoria.isInvitacion) {
                        handleNotificationsInv()
                    }
                    await postNotificacion(appSt.serviceData, {
                        to: [
                            { email: usuarioSt.usuario.data.email, name: usuarioSt.usuario.data.nombre }
                        ],
                        templateData: {
                            convocatoria: convocatoria.descConvocatoria,
                        },
                        templateId: NOTIFICACIONES.templatesIds.revisionRegistroConvocatoria
                    })
                    await registrarInstrumento(registroConvocatoriaSt.empresaSeleccionada);
                } catch (error) {
                    console.log(error)
                    dispatch(setAlert({
                        show: true,
                        message: 'Error al validar registro',
                        type: ALERT_FAIL
                    }))
                }
            } else {
                const faltantes = [];
                if (!convocatoriaDatos) { faltantes.push('Datos de Participación') }
                if (!direccionValida) { faltantes.push('Dirección de Entrega') }
                if (!documentosValidos) { faltantes.push('Documentos') }
                if (!invitacionCompleted) { faltantes.push('Invitación') }
                dispatch(setAlert({
                    show: true,
                    message: `Falta información (${faltantes.join()})`,
                    type: ALERT_FAIL
                }))
            }
        }
        dispatch(setLoading(false));
    }

    const checkFecha = async (instrumento) => {
        let visible = null;
        try {
            const resp = await getInstrumentoVisible({
                ...appSt.serviceData,
                idInstrumentoRegistro: instrumento.idInstrumentoRegistro
            });
            visible = resp;
        } catch (error) {
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: 'Error al consultar fecha fin',
                type: ALERT_FAIL
            }))
        }

        return visible;
    }

    const openSelection = async () => {
        try {
            dispatch(setLoading(true));
            const respInst = await registrarInstrumento(registroConvocatoriaSt.empresaSeleccionada);
            if (respInst.length > 0) {
                const response = respInst[0].value;
                const instrumento = await getInstrumentoByConvocatoriaId({ ...appSt.serviceData, idRegistroConvocatoria: response.idRegistroConvocatoria });
                if (instrumento && instrumento.length > 0) {
                    const resp = await checkFecha(instrumento[0]);
                    dispatch(setVisibilidadData(resp))
                    if (convocatoria.cvePrograma.includes('ESR')) {
                        dispatch(changeLogo(LOGO_EMPRESA.esr));
                    } else {
                        dispatch(changeLogo(LOGO_EMPRESA.cemefi));
                    }
                    dispatch(getInstrumentoReport({ ...appSt.serviceData, idRegistroConvocatoria: convocatoria.idRegistroConvocatoria }));
                    goToView(SELECCION_PREGUNTA);
                    const convocatoriaRegistrada = getConvocatoriaRegistrada()
                    dispatch(getCriteriosInstrumentoCatalogo({
                        ...appSt.serviceData,
                        idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad,
                        idConvocatoria: convocatoriaRegistrada.idConvocatoria
                    }));
                    dispatch(setLoading(false));
                    // if (resp.isVisible) {
                    // } else {
                    //     dispatch(setLoading(false));
                    //     dispatch(
                    //         setAlert({
                    //             show: true, 
                    //             message: 
                    //                 `La fecha para postulación al instrumento ya expiró, el instrumento se encuentra en estatus 
                    //                     ${instrumento[0].idEstatusInstrumentoRegistro2.descEstatusInstrumentoRegistro}
                    //                     ${instrumento[0].idEstatusInstrumentoRegistro2.cveEstatusInstrumentoRegistro === INSTRUMENTOS_CVE_ESTATUS.liberado ? instrumento[0].fechaLiberacionEntidad : ''}
                    //                 `,
                    //             type: ALERT_FAIL
                    //         })
                    //     )
                    // }
                } else {
                    dispatch(setLoading(false));
                    dispatch(
                        setAlert({
                            show: true,
                            message: 'Error al consultar instrumento',
                            type: ALERT_FAIL
                        })
                    )
                }
            }
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false));
            dispatch(
                setAlert({
                    show: true,
                    message: 'Error al consultar instrumento',
                    type: ALERT_FAIL
                })
            )
        }

    }

    const openPublicaciones = async () => {
        if (convocatoria.cvePrograma.includes('ESR')) {
            dispatch(changeLogo(LOGO_EMPRESA.esr));
        } else {
            dispatch(changeLogo(LOGO_EMPRESA.cemefi));
        }
        dispatch(setLoading(true));
        const instrumento = await getInstrumentoByConvocatoriaId({ ...appSt.serviceData, idRegistroConvocatoria: convocatoria.idRegistroConvocatoria });

        let conv = convocatoria
        if (instrumento && instrumento.length > 0) {
            const publicacion = await getEsPublicada({ ...appSt.serviceData, idInstrumentoRegistro: instrumento[0].idInstrumentoRegistro });
            conv = {
                ...convocatoria,
                idInstrumentoRegistro: instrumento[0].idInstrumentoRegistro,
                publicacion: publicacion
            }
        }
        dispatch(setCurrentConvocatoriaCurso(conv));
        dispatch(setCurrentConvocatoriaPublicacion(conv));
        goToView(PUBLICACION_VIEW);
        dispatch(setLoading(false));
    }

    const openRetro = async () => {
        if (convocatoria.cvePrograma.includes('ESR')) {
            dispatch(changeLogo(LOGO_EMPRESA.esr));
        } else {
            dispatch(changeLogo(LOGO_EMPRESA.cemefi));
        }
        dispatch(setLoading(true));
        let conv = convocatoria
        dispatch(setCurrentConvocatoriaRetro(conv));
        goToView(RETRO_VIEW);
        dispatch(setLoading(false));
    }

    const openInvitacion = async () => {
        let conv = convocatoria
        dispatch(setCurrentConvocatoria(conv));
        goToView(INVITACION_STEP);
    }

    const openPagos = async () => {
        dispatch(setLoading(true))
        const resp = await getEsquemaCobroConvocatoria({ ...appSt.serviceData, idConvocatoria: convocatoria.idConvocatoria })
        // await putAsignarCuentas({
        //     ...appSt.serviceData,
        //     idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
        //     body: {}
        // })
        dispatch(setCurrentConvocatoriaPagos(convocatoria))
        dispatch(setLoading(false))
        if (resp.length > 0) {
            dispatch(changeEsquema(resp))
            if (convocatoria.cvePrograma.includes('ESR')) {
                dispatch(changeLogo(LOGO_EMPRESA.esr));
            } else {
                dispatch(changeLogo(LOGO_EMPRESA.cemefi));
            }
            goToView(PAGOS);
        } else {
            dispatch(setAlert({
                show: true,
                message: "No hay esquema de pago configurados",
                type: ALERT_FAIL
            }))
        }
    }

    const openPagosCursos = async () => {
        dispatch(setLoading(true))
        const resp = await getEsquemaCobroConvocatoria({...appSt.serviceData, idConvocatoria: convocatoria.idConvocatoria})
        // todo Checar servicio para asignar cuenta
        // await putAsignarCuentasCursos({
        //     ...appSt.serviceData,
        //     idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
        //     body: {}
        // })
        dispatch(setCurrentConvocatoriaCurso(convocatoria));
        dispatch(setLoading(false))
        if (resp.length > 0) {
            dispatch(changeEsquemaCurso(resp))
            navigate(`/${convocatoria.idEntidad}/pagosCursos`);
        } else {
            dispatch(setAlert({
                show: true,
                message: "No hay esquema de pago configurados",
                type: ALERT_FAIL
            }))
        }
    }

    const handleCorp = async (type) => {
        try {
            const resp = await getCorporativo({
                ...appSt.serviceData,
                idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad
            });

            if (resp.payload && convocatoria.idRegistroConvocatoria) {
                const promises = [];
                resp.payload.forEach((ent) => {
                    const entRegistro = ent.cemRegistroConvocatorias.find(registro => registro.idConvocatoria === convocatoria.idConvocatoria);
                    if (entRegistro && entRegistro.isEstatusRegistro !== 0 && type === 'delete') {
                        promises.push(deleteRegistroConvocatoria({
                            ...appSt.serviceData,
                            idRegistroConvocatoria: entRegistro.idRegistroConvocatoria
                        }))
                    }
                    if (entRegistro && entRegistro.isEstatusRegistro !== 0 && type === 'validar') {
                        const data = {
                            ...appSt.serviceData,
                            idRegistroConvocatoria: entRegistro.idRegistroConvocatoria
                        }
                        const body = {
                            idEstatusRegistroConvocatoria: catalogosSt.idsEstatusRegistroConvocatoria.revision
                        }
                        promises.push(putRegistroConvocatoria(data, body))
                    }
                })
                await Promise.allSettled(promises);
            }
        } catch (error) {
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: 'Error al eliminar convocatorias adicionales',
                type: ALERT_FAIL
            }));
        }

    }

    const getConvocatoriaRegistrada = () => {
        let registrada = null
        if (registroConvocatoriaSt.empresaSeleccionada.cemRegistroConvocatorias.length > 0) {
            const exists = registroConvocatoriaSt.empresaSeleccionada.cemRegistroConvocatorias.find(conv => conv.idConvocatoria === convocatoria.idConvocatoria && conv.idEstatusRegistro !== 0);
            if (exists && exists !== undefined) {
                registrada = exists
            }
        } 
        return registrada
    }

    useEffect(() => {
        switch (convocatoria.cveEstatusRegistroConvocatoria) {
            case CVE_ETATUS_REGISTRO_CONVOCATORIA:
                setRegistro('registrada')
                break;

            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registroConvocatoriaSt.empresaSeleccionada, registroConvocatoriaSt.listaConvocatorias])

    useEffect(() => {
        const data = {
            ...appSt.serviceData,
            cveTipoDocumento: CVE_TIPODOC_CONVOCATORIA,
        };
        dispatch(getDocumentosCatalogo(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registroConvocatoriaSt.empresaSeleccionada])

    useEffect(() => {
        if (catalogosSt.tipoDireccion === null && !catalogosSt.loading) {
            dispatch(getTipoDireccionCatalogo(appSt.serviceData))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (convocatoria && registroConvocatoriaSt.empresaSeleccionada) {
            const isNotGroup = !registroConvocatoriaSt.empresaSeleccionada.isCorporativo && !registroConvocatoriaSt.empresaSeleccionada.isSedeMarca && !registroConvocatoriaSt.empresaSeleccionada.isMiembro 
            const isGroup = registroConvocatoriaSt.empresaSeleccionada.isMiembro || registroConvocatoriaSt.empresaSeleccionada.isCorporativo || registroConvocatoriaSt.empresaSeleccionada.isSedeMarca

            const validEnt = (isGroup && registroConvocatoriaSt.empresaSeleccionada.cveEstatusCorporativoSede === CVE_ESTATUS_CORP_SEDE.confirmada) || isNotGroup
            const validGrupo = isGroup && registroConvocatoriaSt.empresaSeleccionada.cveEstatusCorporativoSede && registroConvocatoriaSt.empresaSeleccionada.cveEstatusCorporativoSede !== "CONFIRMADO"
            const validConv = (validGrupo || isNotGroup) && convocatoria.idEntidadPadre === '0' && convocatoria.isConvocatoriaRegistro === 1

            setCanRegister(validConv && validEnt && (validGrupo || isNotGroup))
        }
    }, [convocatoria, registroConvocatoriaSt.empresaSeleccionada])

    return <div
        className={`convocatoria 
            ${convocatoria.cveEstatusRegistroConvocatoria ? convocatoria.cveEstatusRegistroConvocatoria.toLowerCase() : ''}
            ${showCard ? 'show' : ''}`
        }>
        <div className="head">
            <h3>{convocatoria.descConvocatoria}</h3>
            <div className="status">
                {convocatoria.descEstatusRegistroConvocatoria &&
                    <span>{convocatoria.descEstatusRegistroConvocatoria}</span>
                }
            </div>
        </div>
        {convocatoria.base64 ?
            <img onLoad={() => setShowCard(true)} src={`data:image/jpeg;base64,${convocatoria.base64}`} alt="convocatoria" /> : <img src={bg} alt="convocatoria" onLoad={() => setShowCard(true)} />
        }
        {canRegister && !convocatoria.cveEstatusRegistroConvocatoria &&
            <div className="actions">
                <div className="action-btn">
                    <button
                        onClick={() =>
                            confirmIndividual(
                                REGISTRO_CONVOCATORIA,
                                convocatoria
                            )
                        }>
                        Registrarse
                    </button>
                </div>
            </div>
        }
        {(convocatoria.cveEstatusRegistroConvocatoria === CVE_ETATUS_REGISTRO_CONVOCATORIA || convocatoria.cveEstatusRegistroConvocatoria === CVE_ETATUS_RECHAZADA_CONVOCATORIA) && convocatoria.idEntidadPadre === '0' &&
            <div className="actions">
                <div className="action-btn">
                    <button className="up">
                        <UpIcon />
                    </button>
                </div>

                <ul>
                    {convocatoria.isConvocatoriaRegistro === 1 && canRegister &&
                        <li onClick={() => goToView(REGISTRO_CONVOCATORIA, registro)}>
                            <EditIcon />
                            <p>Editar Registro</p>
                        </li>
                    }
                    {convocatoria.isConvocatoriaRegistro !== 0 && canRegister && (
                        <li onClick={() => sendToValidate()}>
                            <CheckIcon />
                            <p>Enviar a validacion</p>
                        </li>
                    )}
                </ul>
            </div>
        }

        {/* {convocatoria.cveEstatusRegistroConvocatoria === CVE_ETATUS_REVISION_CONVOCATORIA && convocatoria.idEntidadPadre === '0' &&
            <div className="actions">
                <div className="action-btn">
                    <button className="up">
                        <UpIcon />
                    </button>
                </div>

                <ul>
                </ul>
            </div>
        } */}

        {convocatoria.cveEstatusRegistroConvocatoria === CVE_ETATUS_ACEPTADA_CONVOCATORIA &&
            <div className="actions">
                <div className="action-btn">
                    <button className="up">
                        <UpIcon />
                    </button>
                </div>

                <ul>
                    <li onClick={() => openPagos()}>
                        <PagosIcon className={convocatoria.tieneEsquema !== "SI" ? 'error' : ''} />
                        <p>Ir a pagos</p>
                    </li>
                    {convocatoria.tieneCobroCursoConvocatoria === "SI"
                        ? <li onClick={() => openPagosCursos()}>
                            <PagosIcon className={convocatoria.tieneEsquema !== "SI" ? 'error' : ''} />
                            <p>Ir a pagos cursos</p>
                        </li>
                        : <></>
                    }
                    <li onClick={() => openSelection()}>
                        <CheckIcon className={convocatoria.descEstatusInstrumentoRegistro !== INSTRUMENTOS_CVE_ESTATUS.liberado ? 'error' : ''} />
                        <p>Ir al instrumento</p>
                    </li>
                    {convocatoria.isPublicacion === 1 
                        ? <li onClick={() => openPublicaciones()}>
                            <CheckIcon />
                            <p>Ir a publicaciones</p>
                        </li>
                        : <></>
                    }
                    {convocatoria.isRetroalimentacion &&
                        <li onClick={() => openRetro()}>
                            <FileIcon />
                            <p>Ir a retroalimentación</p>
                        </li>
                    }
                    {convocatoria.isInvitacion === 1 &&
                        <li onClick={() => openInvitacion()}>
                            <FileIcon />
                            <p>Invitaciones</p>
                        </li>
                    }
                </ul>
            </div>
        }
    </div>
}