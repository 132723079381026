/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { filtrosIndicador } from "../../app/forms/publicacion/filtros-indicador";
import InputComp from "../generales/inputComponent";
import { formatForm } from "../../app/commons";
import { ALERT_FAIL } from "../../app/constantes";
import { getAmbitosByAnioPrograma, getIndicadorByAnioPrograma, getInstrumentoCatalogosIndicador, getInstrumentoCatalogosSubambito, getSubAmbitosByAnioPrograma } from "../../services/catalogos";
import { publicacionState } from "../../slices/publicacionSlice";
import { downloadResultReportInvitados, postReporteIndicadoresInvitaciones, postReporteResultadosInvitaciones } from "../../services/publicacion";

export default function ModalReporteResultados({ empresaSeleccionada, modalData, setModalData }) {
    const dispatch = useDispatch()
    const appSt = useSelector(appState);
    const publicacionSt = useSelector(publicacionState);

    const [tab, setTab] = useState('criterio')
    const [ambitos, setAmbitos] = useState([]);
    const [subambitos, setSubambitos] = useState([]);
    const [indicadores, setIndicadores] = useState([]);
    const [registros, setRegistros] = useState(null);
    const [registrosIndicadores, setRegistrosIndicadores] = useState(null);

    const [formIndicador, setFormIndicador] = useState(JSON.parse(JSON.stringify(filtrosIndicador)));

    const closeModal = () => {
        setModalData({
            show: false,
            data: null
        })
    }

    const search = async () => {
        if (modalData.data) {
            dispatch(setLoading(true))
            try {
                const array = []
                modalData.data.forEach((item) => {
                    if (item.idDictamen) {
                        array.push(item.idInstrumentoRegistro)
                    }
                })
                const resp = await postReporteResultadosInvitaciones({
                    ...appSt.serviceData,
                    idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria,
                    body: {
                        idsInstrumentoRegistro: array
                    }
                })
                setRegistros(resp)
                dispatch(setLoading(false));
            } catch (error) {
                console.log(error)
                dispatch(setLoading(false));
                dispatch(setAlert({
                    show: true,
                    message: 'Error al consultar información',
                    type: ALERT_FAIL
                }))
            }
        }
    }

    const changeTab = (select) => {
        setTab(select);
    }

    const downloadReport = async () => {
        let idTipoConsulta = null;
        dispatch(setLoading(true));
        const formatedIndicador = formatForm(formIndicador);

        let body = {
            idEstado: null,
            idTamanio: null,
            idIndustria: null,
            idSector: null,
            idSubsector: null,
            anioEsr: null,
            isGlobal: true
        }
        switch (tab) {
            case 'indicador':
                idTipoConsulta = 1;
                body = {
                    ...body,
                    idsAmbito: formatedIndicador.idAmbito ? [formatedIndicador.idAmbito] : [],
                    idsSubambito: formatedIndicador.idSubambito ? [formatedIndicador.idSubambito] : [],
                    idsIndicador: formatedIndicador.idIndicador ? [formatedIndicador.idIndicador] : [],
                }
                break;
            case 'ambitos':
                idTipoConsulta = 3;
                break;
            case 'subambitos':
                idTipoConsulta = 2;
                break;
            case 'criterio':
                idTipoConsulta = 4;
                break;
            default:
                break;
        }
        const array = []
        modalData.data.forEach((item) => {
            if (item.idDictamen) {
                array.push(item.idInstrumentoRegistro)
            }
        })

        const blob = await downloadResultReportInvitados({
            ...appSt.serviceData,
            idTipoConsulta,
            body: {
                ...body,
                idsInstrumentoRegistro: array
            }
        });
    dispatch(setLoading(false));
    if (blob) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Reporte de resultados';
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }
}

const searchIndicadores = async () => {
    dispatch(setLoading(true));
    try {
        const formatedIndicador = formatForm(formIndicador);
        const array = []
        modalData.data.forEach((item) => {
            if (item.idDictamen) {
                array.push(item.idInstrumentoRegistro)
            }
        })
        console.log(formIndicador)
        const body = {
            isGlobal: true,
            idEstado: null,
            idTamanio: null,
            idIndustria: null,
            idSector: null,
            idSubsector: null,
            anioEsr: null,
            idsAmbito: formatedIndicador.idAmbito ? [formatedIndicador.idAmbito] : [],
            idsSubambito: formatedIndicador.idSubambito ? [formatedIndicador.idSubambito] : [],
            idsIndicador: formatedIndicador.idIndicador ? [formatedIndicador.idIndicador] : [],
            idsInstrumentoRegistro: array
        }
        const resp = await postReporteIndicadoresInvitaciones({
            ...appSt.serviceData,
            body
        });
        setRegistrosIndicadores(resp)
        dispatch(setLoading(false));
    } catch (error) {
        console.log(error);
        dispatch(setLoading(false));
        dispatch(setAlert({
            show: true,
            message: 'Error al consultar entidades',
            type: ALERT_FAIL
        }))
    }
}

const getSubambitos = async () => {
    dispatch(setLoading(true));
    setIndicadores([])
    const resp = await getSubAmbitosByAnioPrograma({
        ...appSt.serviceData,
        idAmbito: formIndicador.idAmbito.value,
        body: {
            anio: publicacionSt.currentConvocatoria.anio,
            idPrograma: publicacionSt.currentConvocatoria.idPrograma
        }
    })
    dispatch(setLoading(false));
    if (resp) {
        setSubambitos(resp)
    }
}

const getIndicadores = async () => {
    dispatch(setLoading(true));
    const resp = await getIndicadorByAnioPrograma({
        ...appSt.serviceData,
        idSubambito: formIndicador.idSubambito.value,
        body: {
            anio: publicacionSt.currentConvocatoria.anio,
            idPrograma: publicacionSt.currentConvocatoria.idPrograma
        }
    })
    dispatch(setLoading(false));
    if (resp) {
        setIndicadores(resp)
    }
}

const getAmbitos = async () => {
    try {
        const resp = await getAmbitosByAnioPrograma({
            ...appSt.serviceData,
            body: {
                anio: publicacionSt.currentConvocatoria.anio,
                idPrograma: publicacionSt.currentConvocatoria.idPrograma
            }
        })
        setAmbitos(resp)
    } catch (error) {
        console.log(error)
    }
}

useEffect(() => {
    if (ambitos.length === 0) {
        getAmbitos()
    }
}, [])

useEffect(() => {
    if (formIndicador.idAmbito.value !== "") {
        getSubambitos()
    }
}, [formIndicador.idAmbito.value])

useEffect(() => {
    if (formIndicador.idSubambito.value !== "") {
        getIndicadores()
    }
}, [formIndicador.idSubambito.value])

useEffect(() => {
    if (modalData.show) {
        search()
    }
}, [modalData])

return (<div className={modalData.show ? "modal-wrapper active" : "modal-wrapper"}>
    <div className="modal mid-width">
        <div className="head">
            <h3>Reporte de Resultados</h3>
            <button onClick={closeModal}>
                x
            </button>
        </div>
        <div className="content">
            {registros && (
                <div className="card results">
                    <nav>
                        <button onClick={() => changeTab('criterio')} className={tab === 'criterio' ? 'active' : ''}>Criterio</button>
                        <button onClick={() => changeTab('ambitos')} className={tab === 'ambitos' ? 'active' : ''}>Ambito</button>
                        <button onClick={() => changeTab('subambitos')} className={tab === 'subambitos' ? 'active' : ''}>Subambito</button>
                        <button onClick={() => changeTab('indicador')} className={tab === 'indicador' ? 'active' : ''}>Indicador</button>
                    </nav>
                    {tab !== 'indicador' &&
                        <div className="table-container">
                            <div className="actions">
                                <button onClick={downloadReport}>Descargar Reporte</button>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Criterio</th>
                                        {(tab === 'ambitos' || tab === 'subambitos') && <th>Ambito</th>}
                                        {tab === 'subambitos' && <th>Subambitos</th>}
                                        <th>Nivel Desarrollo de Entidades</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {registros[tab].map((item, index) => {
                                        return tab !== 'indicador' && <tr key={index}>
                                            <td>{item.desc_criterio}</td>
                                            {(tab === 'ambitos' || tab === 'subambitos') && <td>{item.desc_ambito}</td>}
                                            {tab === 'subambitos' && <td>{item.desc_subambito}</td>}
                                            <td>{item.nivelDesarrolloEntidades}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                    {tab === 'indicador' &&
                        <div className="table-conatiner">
                            <div className="filtros">
                                <div className="four-col">
                                    {ambitos &&
                                        <InputComp label="Ambito" name="idAmbito" type="select" form={formIndicador} setForm={setFormIndicador} catalogos={ambitos} catalogoLabel={'descAmbito'} catalogoValue={'idAmbito'} />
                                    }
                                    <InputComp disabled={subambitos.length === 0} label="Subambito" name="idSubambito" type="select" form={formIndicador} setForm={setFormIndicador} catalogos={subambitos} catalogoLabel={'desc_subambito'} catalogoValue={'id_subambito'} />
                                    <InputComp disabled={indicadores.length === 0} label="Indicador" name="idIndicador" type="select" form={formIndicador} setForm={setFormIndicador} catalogos={indicadores} catalogoLabel={'desc_indicador'} catalogoValue={'id_indicador'} />
                                    <button disabled={formIndicador.idIndicador.value === ""} onClick={searchIndicadores}>Filtrar</button>
                                </div>
                            </div>
                            {registrosIndicadores && <>
                                <div className="actions">
                                    <button onClick={downloadReport}>Descargar Reporte</button>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Criterio</th>
                                            <th>Ambito</th>
                                            <th>Subambitos</th>
                                            <th>Nivel Desarrollo Entidades</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {registrosIndicadores.map((item, index) => {
                                            return <tr key={index}>
                                                <td>{item.desc_criterio}</td>
                                                <td>{item.desc_ambito}</td>
                                                <td>{item.desc_subambito}</td>
                                                <td>{item.nivelDesarrolloEntidades}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </>}
                        </div>
                    }
                </div>
            )}
        </div>
    </div>
</div>)
}