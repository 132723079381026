import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";

import { ALERT_FAIL, DIRECCION_ENTREGA_STEP, DOCUMENTOS_STEP, DUAL_LIST } from "../../app/constantes";
import { getActiveMembresiaByEntidad, getMembresiaDetails } from "../../services/membresias";

import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import DualListSelector from "./datos-participacion/DualListSelector";
import { getMessageByModulo } from "../../services/catalogos";

export default function MembresiasConvocatoria() {
    const dispatch = useDispatch();
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const appSt = useSelector(appState);
    const [messages, setMessages] = useState(null);
    const [maxEntidades, setMax] = useState(0);

    /**
     * Obtenemos los datos de la membresia para saber el maximo de entidades a invitar
     */
    const getDetails = async () => {
        dispatch(setLoading(true));
        const membresia = await getMembresiaDetails({
            ...appSt.serviceData,
            idMembresia: registroConvocatoriaSt.membresiaActiva.idMembresia
        });
        if (membresia && membresia.numeroEntidades) {
            const membresiasActivas = await getActiveMembresiaByEntidad({
                ...appSt.serviceData, 
                idMembresia: registroConvocatoriaSt.membresiaActiva.idMembresia, 
                idEntidad: registroConvocatoriaSt.convocatoriaSeleccionada.idEntidad
            })
            if (membresiasActivas) {
                setMax(membresia.numeroEntidades - Number(membresiasActivas.entidadesInvitadas));
            } else {
                setMax(membresia.numeroEntidades);
            }
        } else {
            setAlert({
                show: true,
                message: 'Error al obtener datos de la membresía',
                type: ALERT_FAIL
            });
        }


        dispatch(setLoading(false));
    }

    /**
     * Tips
     */
    const getMessages = async () => {
        if (!messages) {
            let messageBody = {}
            const resp = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'REGISTROCONVOCATORIA', 'MENSAJECOMPLETONUMENTIDADES');
            if (resp.payload && resp.payload.valor) {
                messageBody = {...messageBody, topMessage: resp.payload.valor}
            }
            const respMaxEntidades = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'REGISTROCONVOCATORIA', 'MENSAJEISINVITACION');
            if (respMaxEntidades.payload && respMaxEntidades.payload.valor) {
                messageBody = {...messageBody, maxEntidades: respMaxEntidades.payload.valor}
            }
            setMessages(messageBody)
        }
    }

    /**
     * Llenamos catalogos
     */
    useEffect(() => {
        getDetails();
        getMessages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registroConvocatoriaSt.membresiaActiva]);

    return (
        <div className="registro-step membresias-convocatoria">
            {maxEntidades !== 0 && messages && messages.maxEntidades &&
                <p className="tip">
                    <InfoIcon />
                    Número de entidades que puede invitar: {maxEntidades}
                </p>            
            }
            {maxEntidades === 0 && messages && messages.topMessage && 
            <p className="tip">
                <InfoIcon />
                {messages.topMessage}
            </p>  
            }

            <DualListSelector prev={DIRECCION_ENTREGA_STEP} next={DOCUMENTOS_STEP} type={DUAL_LIST.membresia} max={maxEntidades} getDetailsMembresia={getDetails}/>
        </div>
    )
}