/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux"
import { usuariosServicesState } from "../../slices/usuariosServicesSlice"
import { useEffect, useState } from "react";
import { deleteDocumentoConsultor, getDocument, postDocumentoConsultor, putConsultor } from "../../services/entidad";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { getBase64, getContentName } from "../../app/commons";
import { ALERT_FAIL, ALERT_SUCCESS, CVE_ESTATUS_CONSULTOR, DOCUMENTOS_CONSULTOR } from "../../app/constantes";
import { getConsultorData, registroConsultorState } from "../../slices/registroConsultor";
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg"
import DocumentComp from "./documento-comp";
import ResenaDoc from '../../assets/review.pptm';
import ConsentimientoDoc from '../../assets/consentimiento.pdf';
import CodigoEticaDoc from '../../assets/codigoEtica.pdf';
import CVDoc from '../../assets/CV.doc';
import ReacreditacionDoc from '../../assets/politicaReacreditacion.pdf';


export default function DocumentosConsultor({onlyView}) {
    const dispatch = useDispatch()
    const appSt = useSelector(appState)
    const userSt = useSelector(usuariosServicesState)
    const consultorSt = useSelector(registroConsultorState)
    const catalogosSt = useSelector(catalogosServiceState)
    const [isDisabled, setDisabled] = useState(false)

    const handleSubmit = async (e, doc) => {
        const file = e.target.files[0];
        if (file) {
            const extencion = `${file.name.split('.').pop().toLowerCase()}`;
            if (doc.extension.toLowerCase().includes(extencion)) {
                dispatch(setLoading(true))
                try {
                    const base64 = await getBase64(file);
                    const body = {
                        cveDocumentoCemefi: doc.cveDocumentoCemefi,
                        archivo: {
                            nombreArchivo: file.name.replace('#', ''),
                            tipo: file.type,
                            base64: base64
                        }
                    }
                    await postDocumentoConsultor({...appSt.serviceData, idConsultorAcreditado: userSt.persona.idPersona}, body);

                    dispatch(setLoading(false))
                    dispatch(setAlert({
                        show: true,
                        message: "Documento guardado",
                        type: ALERT_SUCCESS
                    }))
                    dispatch(getConsultorData({
                        ...appSt.serviceData,
                        idConsultorAcreditado: userSt.persona.idPersona
                    }))
                    
                } catch (error) {            
                    dispatch(setAlert({
                        show: true,
                        message: "Error al guardar documento",
                        type: ALERT_FAIL
                    }))
                }
            } else {
                dispatch(setAlert({
                    show: true,
                    message: `Archivo invalido, archivos validos: ${doc.extension}`,
                    type: ALERT_FAIL
                }))
            }
        }
        e.target.value = '';
    }

    const deleteDoc = async (registro) => {
        dispatch(setLoading(true))
        try {
            await deleteDocumentoConsultor({
                ...appSt.serviceData,
                idDocumentoConsultor: registro.idDocumentoConsultor
            })
            dispatch(setLoading(false))
            dispatch(setAlert({
                show: true,
                message: "Documento eliminado",
                type: ALERT_SUCCESS
            }))
            dispatch(getConsultorData({
                ...appSt.serviceData,
                idConsultorAcreditado: userSt.persona.idPersona
            }))
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))    
            dispatch(setAlert({
                show: true,
                message: "Error al eliminar documento",
                type: ALERT_FAIL
            }))        
        }
    }

    const downloadDoc = async (doc) => {
        dispatch(setLoading(true))
        try {
            const docResp = await getDocument({
                ...appSt.serviceData,
                idEntidad: userSt.persona.idPersona,
                idContent: doc.idContent
            });
            
            if (docResp.payload) {
                const type = doc.idContent.split('.').pop();
                const dataType = type === 'pdf' ? 'data:application/pdf' : `data:image/${type}`;
                // const type = 'data:image/png'
                const file64 = docResp.payload.base64;
                const linkSource = `${dataType};base64,${file64}`;
                const downloadLink = document.createElement('a');
                //falta poner el nombre correcto
                const fileName = getContentName(doc.idContent);
    
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click()
            }
            dispatch(setLoading(false))
            
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))
            dispatch(setAlert({
                show: true,
                message: "Error al obtener documento",
                type: ALERT_FAIL
            }))    
        }
    }

    /**
     * Descargamos documentos
     * @param {*} item 
     */
    const downloadUserDoc = async (item) => {
        let linkSource;
        const downloadLink = document.createElement('a');
        let fileName;

        switch (item) {
            case DOCUMENTOS_CONSULTOR.codigoEtica:
                linkSource = CodigoEticaDoc
                fileName = "Codigo_Etica"
                break;
            case DOCUMENTOS_CONSULTOR.consentimiento:
                linkSource = ConsentimientoDoc
                fileName = "Consentimiento"
                break;
            case DOCUMENTOS_CONSULTOR.cv:
                linkSource = CVDoc
                fileName = "CV"
                break;
            case DOCUMENTOS_CONSULTOR.review:
                linkSource = ResenaDoc
                fileName = "Reseña"     
                break;
            case DOCUMENTOS_CONSULTOR.reacreditacion:
                linkSource = ReacreditacionDoc
                fileName = "Politica_Reacreditacion"     
                break;
            default:
                break;
        }

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click()
    }

    const validate = async () => {
        console.log(catalogosSt.documentosConsultorAcreditado, consultorSt.consultorData.cemDocumentoConsultorAcreditados)
        if (catalogosSt.documentosConsultorAcreditado.length !== consultorSt.consultorData.cemDocumentoConsultorAcreditados.length) {
            dispatch(setAlert({
                show: true,
                message: "Favor de verificar que no falte llenar algún campos obligatorios y que los documentos estén completos.",
                type: ALERT_FAIL
            }))
        } else {
            dispatch(setLoading(true))
            try {
                await putConsultor({
                    ...appSt.serviceData,
                    idConsultorAcreditado: userSt.persona.idPersona,
                    body: {
                        cveEstatusConsultorAcreditado: CVE_ESTATUS_CONSULTOR.revision,
                        idConsultorAcreditado: userSt.persona.idPersona
                    }
                })
                dispatch(setLoading(false))
                dispatch(getConsultorData({
                    ...appSt.serviceData,
                    idConsultorAcreditado: userSt.persona.idPersona
                }))
                dispatch(setAlert({
                    show: true,
                    message: "Registro enviado a revisión",
                    type: ALERT_SUCCESS
                })) 
            } catch (error) {
                console.log(error)
                dispatch(setLoading(false))   
                dispatch(setAlert({
                    show: true,
                    message: "Error al cambiar de estatus",
                    type: ALERT_FAIL
                }))          
            }
        }
    }

    useEffect(() => {
        if (consultorSt.consultorData) {
            setDisabled(
                !consultorSt.validations.datosBasicosValid ||
                !consultorSt.validations.areasValid ||
                !consultorSt.validations.documentosValid
            )
        }
    }, [consultorSt.validations])

    return (<section>
            <div className="sub-section download-docs">
                <h4>Descargar</h4>
                <p>Descarga los documento para su llenado</p>
                <div className="three-col">
                    <button className="btn-icon" onClick={() => downloadUserDoc(DOCUMENTOS_CONSULTOR.review)}>
                        <DownloadIcon />
                        Reseña
                    </button>
                    <button className="btn-icon" onClick={() => downloadUserDoc(DOCUMENTOS_CONSULTOR.consentimiento)}>
                        <DownloadIcon />
                        Consentimiento del uso de datos Cemefi
                    </button>
                    <button className="btn-icon" onClick={() => downloadUserDoc(DOCUMENTOS_CONSULTOR.codigoEtica)}>
                        <DownloadIcon />
                        Código etica Consultores Cemefi VF
                    </button>
                    <button className="btn-icon" onClick={() => downloadUserDoc(DOCUMENTOS_CONSULTOR.cv)}>
                        <DownloadIcon />
                        CV
                    </button>
                    <button className="btn-icon" onClick={() => downloadUserDoc(DOCUMENTOS_CONSULTOR.reacreditacion)}>
                        <DownloadIcon />
                        Política de reacreditación de Consultores
                    </button>
                </div>

            </div>
        <div className="sub-section">
            <h4>Documentos</h4>
            <div className="three-col">
                {catalogosSt.documentosConsultorAcreditado && catalogosSt.documentosConsultorAcreditado.map((item, key) => {
                    return item.cveDocumentoCemefi !== "CONSULTORACREDITADO" && <DocumentComp 
                        documentosCargados={consultorSt.consultorData.cemDocumentoConsultorAcreditados}
                        descDoc={"descDocumentoCemefi"}
                        doc={item}
                        key={key}
                        onlyView={onlyView}
                        idDoc={"idDocumentoCemefi"}
                        deleteDoc={deleteDoc}
                        download={downloadDoc}
                        upload={handleSubmit}
                    />
                })
                 }
            </div>
            {!onlyView &&
                <div className="actions">
                    <button disabled={isDisabled} onClick={validate}>Enviar a validar</button>
                </div>
            }
        </div>
    </section>)
}