/* eslint-disable react-hooks/exhaustive-deps */

import { useState } from "react";
import { ALERT_FAIL, CVE_DIRECCION, MEXICO_ID } from "../../app/constantes"
import DireccionComponent from "../generales/direccionComponent"
import { direccionForm } from "../../app/forms/registro/direccion";
import { useDispatch, useSelector } from "react-redux";
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { formatForm, validateForm } from "../../app/commons";
import { putDireccionEntidad } from "../../services/entidad";
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { appState, setAlert } from "../../slices/appSlice";
import InputComp from "../generales/inputComponent";
import { datosFiscalesMinForm } from "../../app/forms/cobros/datosFiscales";
import { putCobroRegistroConvocatoria } from "../../services/convocatorias";


export default function ModalDatosFiscales({ validatePay, setModalData, modalData, formatedPagos }) {
  const appSt = useSelector(appState);
  const dispatch = useDispatch()
  const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
  const catalogosSt = useSelector(catalogosServiceState);
  const [formDireccion, setFormDireccion] = useState(JSON.parse(JSON.stringify(direccionForm)));
  const [form, setForm] = useState(JSON.parse(JSON.stringify(datosFiscalesMinForm)));

  const closeModal = () => {
    setModalData({
      show: false,
      data: null
    })
  }

  const handleSave = async () => {
    const validDir = validateForm(formDireccion)
    const validForm = validateForm(form)
    if (validDir.length === 0 && validForm.length === 0) {
      try {
        const formatedForm = formatForm(formDireccion);
        let body = {
          idPais: formatedForm.idPais,
          calle: formatedForm.calle,
          numeroExterior: formatedForm.numeroExterior,
          numeroInterior: formatedForm.numeroInterior,
          idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad,
          idTipoDireccion: catalogosSt.idsTiposDireccion.fiscal
        }
        if (formatedForm.idPais === MEXICO_ID || formatedForm.idPais === Number(MEXICO_ID)) {
          body = {
            ...body,
            idCodigoPostal: formatedForm.idCodigoPostal,
          }
        } else {
          body = {
            ...body,
            idCodigoPostal: null,
            codigoPostal: formatedForm.codigoPostal.length === 4 ? `0${formatedForm.codigoPostal}` : formatedForm.codigoPostal,
            ciudad: formatedForm.ciudad,
            estado: formatedForm.estado,
            colonia: formatedForm.colonia,
            municipioAlcaldia: formatedForm.municipioAlcaldia
          }
        }
        const filt = registroConvocatoriaSt.empresaSeleccionada.cemDireccionEntidads.find(dir => dir.idTipoDireccion === catalogosSt.idsTiposDireccion.fiscal);
        if (filt) {
          const putData = {
            ...appSt.serviceData,
            idDireccion: filt.idDireccionEntidad
          }
          await putDireccionEntidad(putData, body);
        }
        let valid = true
        const filtered = formatedPagos.filter(item => item.idEstatusCobro !== catalogosSt.idsEstatusCobro.revertir)
        filtered.forEach(item => {
          if (item.cemDocumentoCrcCobroRegistros.length === 0) {
            valid = false
          }
        })
        console.log(filtered, valid)
        if (valid) {
          let promises = []
          filtered.forEach(item => {
            promises.push(putCobroRegistroConvocatoria({
              ...appSt.serviceData,
              idCobroRegistroConvocatoria: item.idCobroRegistroConvocatoria,
            }, {
              cveUsoCfdi: form.cveUsoCfdi.value,
              cveFormaPago: 99,
              cveMetodoPago: "PPD",
              cveRegimenFiscal: form.idRegimenFiscal.value,
              idCobroRegistroConvocatoria: item.idCobroRegistroConvocatoria
            }))
          })
          console.log(promises)
          await Promise.allSettled(promises)
        } else {
          dispatch(setAlert({
            show: true,
            message: "Favor de subir al menos un comprobante para todos los cobros",
            type: ALERT_FAIL
          }))
        }
        validatePay()
        closeModal()
      } catch (error) {
        console.log(error)
        throw new Error('Error al guardar dirección')
      }
    } else {
      dispatch(setAlert({
        show: true,
        message: "Favor de llenar los campos obligatorios",
        type: ALERT_FAIL
      }))
    }
  }

  return (
    <div className={modalData.show ? 'modal-wrapper active' : 'modal-wrapper'}>
      <div className="modal modal-datos-fiscales">
        <div className="head">
          <h3>Datos Fiscales</h3>
          <button onClick={closeModal}>
            x
          </button>
        </div>
        <div className="content">
          <div className="tip">
            <InfoIcon />
            <div>
              <p>Validar que los Datos Fiscales sean correctos para la factura. Si lo datos no son correctos actualizarlos por favor.</p>
              <p>Al seleccionar "Continuar"acepta que la información es correcta y se guardará la información.</p>
            </div>
          </div>
          <DireccionComponent aviso={null} empresaSeleccionada={registroConvocatoriaSt.empresaSeleccionada} cveDireccion={CVE_DIRECCION.fiscal} form={formDireccion} setForm={setFormDireccion} isFiscal={true} />
          <div className="two-col">
            {catalogosSt.usoCfdi &&
              <InputComp label="Uso CFDI" name="cveUsoCfdi" type="select" form={form} setForm={setForm} catalogos={catalogosSt.usoCfdi} catalogoValue={'cveUsoCfdi'} catalogoLabel={'descUsoCfdi'} secundaryLabel={'cveUsoCfdi'} />
            }
            {catalogosSt.regimen &&
              <InputComp label="Regimen Fiscal" name="idRegimenFiscal" type="select" form={form} setForm={setForm} catalogos={catalogosSt.regimen} catalogoValue={'cveRegimenFiscal'} catalogoLabel={'descRegimenFiscal'} secundaryLabel={'cveRegimenFiscal'} />
            }
          </div>
        </div>
        <div className="actions">
          <button onClick={closeModal}>Cerrar</button>
          <button onClick={handleSave}>Continuar</button>
        </div>
      </div>
    </div>
  )
}