/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detectMimeType, findCatalogById, formatCurrency, getBase64, getContentName, getToNotification } from "../../app/commons";
import { ALERT_FAIL, ALERT_SUCCESS, CVE_ESTATUS_COBRO, CVE_TIPODOC_CEMEFI, CVE_TIPODOC_COBRO, ERROR_MESSAGE, NOTIFICACIONES, SUCESS_MESSAGE } from "../../app/constantes";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import { postNotificacion } from "../../services/notificaciones";
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { ReactComponent as RevertIcon } from '../../assets/icons/revert.svg';
import moment from "moment/moment";
import { getDocument, getEntidad, uploadDocument } from "../../services/entidad";
import ModalRevertirPago from "./modalRevertir";
import ModalDatosFiscales from "./modalDatosFIscales";
import { getCobrosCursos, getPagosCursosDetails, pagosCursosState, setModalRevertir } from "../../slices/pagosCursosSlice";
import { deleteCobroCursoAplicadoDocumento, postCobroCursoAplicadoDocumento, putCobroCursoAplicado } from "../../services/cursos";

export default function ListaCobrosCursos({ idEntidad }) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const catalogosSt = useSelector(catalogosServiceState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const pagosSt = useSelector(pagosCursosState);
    const [formatedPagos, setFormatedPagos] = useState(null);
    const [allSet, setAllSet] = useState(false);
    const [showSend, setShowSend] = useState(true);
    const [modalData, setModalData] = useState({
        show: false,
        data: null
    })

    const openRevert = (registro) => {
        dispatch(setModalRevertir({
            show: true,
            data: registro
        }))
    }

    const checkPopUp = () => {
        let show = false
        const pendientesSinFactura = pagosSt.cobrosAplicados.filter(item => (
            (item.id_estatus_cobro === catalogosSt.idsEstatusCobro.registrado ||
                item.id_estatus_cobro === catalogosSt.idsEstatusCobro.rechazado) && 
                !item.is_revertir_pago &&
                !item.fac_anticipada
        ))
        if (pendientesSinFactura.length > 0) {
            setModalData({
                show: true,
                data: null
            })
        } else {
            validatePay()
        }
        return show
    }

    /**
     * Mandamos el comprobante a validar
     */
    const validatePay = async () => {
        const body = [];
        // formatedPagos.find(item => item.descConceptoCobroConvocatoria.includes('CUOTA'));
        let valid = true;
        const filtered = formatedPagos.filter(item => item.id_estatus_cobro !== catalogosSt.idsEstatusCobro.revertir)
        filtered.forEach(item => {
            if (item.cemDocumentoCrcCobroCurso.length === 0) {
                valid = false
            }
        })
        if (valid) {
            filtered.forEach(item => {
                console.log(item);
                body.push({
                    idCobroCursoAplicado: item.id_cobro_curso_aplicado,
                    idCobroCurso: item.id_cobro_curso,
                    cveEstatusCobro: CVE_ESTATUS_COBRO.revision,
                    monto: item.monto,
                })
            })
            dispatch(setLoading(true));
            try {
                await putCobroCursoAplicado(appSt.serviceData, body);
                refreshData()
                dispatch(setAlert({
                    show: true,
                    message: SUCESS_MESSAGE,
                    type: ALERT_SUCCESS
                }));
                dispatch(setLoading(false));
                // todo enviar notificación
                // const data = await getEntidad({ ...appSt.serviceData, idEntidad });
                // if (cuota) {
                //     postNotificacion(appSt.serviceData, {
                //         to: getToNotification(data, catalogosSt.idsTipoContacto),
                //         templateData: {
                //             convocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria
                //         },
                //         templateId: NOTIFICACIONES.templatesIds.revisionPago
                //     })
                // } else {
                //     postNotificacion(appSt.serviceData, {
                //         to: getToNotification(data, catalogosSt.idsTipoContacto),
                //         templateData: {
                //             convocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria
                //         },
                //         templateId: NOTIFICACIONES.templatesIds.validacionCobro
                //     })
                // }
            } catch (error) {
                console.log(error);
                dispatch(setLoading(false));
                dispatch(setAlert({
                    show: true,
                    message: ERROR_MESSAGE,
                    type: ALERT_FAIL
                }));
            }
        } else {
            dispatch(setAlert({
                show: true,
                message: "Favor de subir al menos un comprobante para todos los cobros",
                type: ALERT_FAIL
            }))
        }
    }

    // OK
    const deleteComprobante = async (cobro) => {
        dispatch(setLoading(true));
        try {
            await deleteCobroCursoAplicadoDocumento({
                ...appSt.serviceData,
                idDocumentoCobroCurso: cobro.id_documento_cobro_curso
            })
            dispatch(setLoading(false));
            refreshData()
            dispatch(
                setAlert({ show: true, message: 'Se elimino el registro', type: ALERT_SUCCESS })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                setAlert({ show: true, message: 'error al eliminar registro', type: ALERT_FAIL })
            );
        }
    }

    // OK
    const downloadDoc = async (file) => {
        dispatch(setLoading(true));
        const docResp = await getDocument({
            ...appSt.serviceData,
            idEntidad,
            idContent: file.id_content
        });


        if (docResp.payload) {
            const dataType = detectMimeType(docResp.payload.base64, 'Evidencia Institucional');
            // const type = 'data:image/png'
            const file64 = docResp.payload.base64;
            const linkSource = `data:${dataType};base64,${file64}`;
            const downloadLink = document.createElement('a');
            const fileName = 'Evidencia Institucional';

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click()
        }
        dispatch(setLoading(false));
    }

    /**
     * vemos si el pago ya tiene un cobrante
     * puede q los comprobantes puedan venir de un servicio para evitar hacer esta logica 
     */
    const formatPagos = async () => {
        const array = [];
        console.log(pagosSt.cobrosAplicados);
        pagosSt.cobrosAplicados.forEach(item => {
            if (item.id_estatus_cobro !== catalogosSt.idsEstatusCobro.confirmado && item.id_estatus_cobro !== catalogosSt.idsEstatusCobro.exento) {
                const obj = { ...item }
                const registro = item;
                obj.descCobroCurso = item.desc_cobro_curso;
                obj.cantidad = registro.cantidad;
                obj.idCobroCurso = registro.id_cobro_curso;
                obj.montoCobro = registro.monto_cobro;
                array.push(obj);
            }
        });
        const pendientes = pagosSt.cobrosAplicados.filter(item => (
            (item.id_estatus_cobro === catalogosSt.idsEstatusCobro.registrado ||
                item.id_estatus_cobro === catalogosSt.idsEstatusCobro.rechazado) && !item.is_revertir_pago
        ))
        const conComprobante = pagosSt.cobrosAplicados.filter(item => (item.id_estatus_cobro === catalogosSt.idsEstatusCobro.registrado || item.id_estatus_cobro === catalogosSt.idsEstatusCobro.rechazado));
        setAllSet(conComprobante.length === pendientes.length);
        setShowSend(pendientes.length > 0);
        setFormatedPagos(array);
    }

    // OK
    const refreshData = () => {
        dispatch(getPagosCursosDetails({
            ...appSt.serviceData,
            idEntidad,
            idModalidadPago: catalogosSt.idModoPagoTotal
        }))
        dispatch(getCobrosCursos({
            ...appSt.serviceData,
            idEntidad,
        }))
    }

    const uploadComprobante = async (e, registro) => {
        try {
            const value = e.target.files[0];
            const base64 = await getBase64(value);
            const file = {
                cveTipoDocumento: CVE_TIPODOC_COBRO,
                cveDocumentoCemefi: CVE_TIPODOC_CEMEFI.comprobantePago,
                archivo: {
                    nombreArchivo: value.name,
                    tipo: value.type,
                    base64: base64
                }
            }
            console.log(registro);
            const body = {
                monto: Number(registro.monto_cobro) * Number(registro.cantidad),
                facAnticipada: registro.fac_anticipada,
                cveEstatusCobro: CVE_ESTATUS_COBRO.registrado,
                idCobroCursoAplicado: registro.id_cobro_curso_aplicado,
                idCobroCurso: registro.id_cobro_curso
            }
            const data = {
                ...appSt.serviceData,
                idEntidad
            }
            dispatch(setLoading(true));
            await putCobroCursoAplicado(appSt.serviceData, [body]);
            const docResp = await uploadDocument(data, file)
            await postCobroCursoAplicadoDocumento(appSt.serviceData, {
                idCobroCurso: registro.id_cobro_curso,
                cveDocumentoCemefi: file.cveDocumentoCemefi,
                idContent: docResp.payload.contentId,
            })
            refreshData();
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: SUCESS_MESSAGE,
                type: ALERT_SUCCESS
            }));
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: ERROR_MESSAGE,
                type: ALERT_FAIL
            }));
        }
        e.target.value = '';
    }

    useEffect(() => {
        dispatch(setLoading(false))
        if (pagosSt.cobrosAplicados && pagosSt.pagoDetails && catalogosSt.idsTipoDocumentoCemefi) {
            formatPagos();
        }

    }, [pagosSt.cobrosAplicados, pagosSt.pagoDetails, catalogosSt.idsTipoDocumentoCemefi])

    return (
        <div className="cobros">
            <h3>Subir Comprobantes</h3>
            <p>Para efecto de realizar tu transferencia, deberás indicar la referencia y pagar el importe exacto que señala la Orden de Pago. <br />Una vez cargados los comprobantes enviar a validar..</p>
            <table>
                <thead>
                    <tr>
                        <th>Estatus</th>
                        <th>Motivo de Rechazo</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Fecha</th>
                        <th>Monto Unitario</th>
                        <th>Monto Total</th>
                        <th>Orden de Pago</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {formatedPagos && formatedPagos.map((cobro, index) => {
                        return cobro.idEstatusRegistro !== 0 && <tr key={index}>
                            <td>{findCatalogById(cobro.id_estatus_cobro, catalogosSt.estatusCobro, 'idEstatusCobro', 'descEstatusCobro')}</td>
                            <td>{cobro.motivo_rechazo}</td>
                            <td>{cobro.descCobroCurso}</td>
                            <td>{cobro.cantidad}</td>
                            <td>{moment(cobro.fecha).format('YYYY-MM-DD')}</td>
                            <td>{formatCurrency(cobro.monto_cobro)}</td>
                            <td>{formatCurrency(cobro.monto_cobro * cobro.cantidad)}</td>
                            <td>{cobro.folio_orden_pago}</td>
                            <td>
                                <div className="table-actions">
                                    <button
                                        disabled={!(cobro.is_revertir_pago && cobro.id_estatus_cobro === catalogosSt.idsEstatusCobro.rechazado)}
                                        className={`small-icon-btn tooltip ${cobro.tiene_documento ? 'success' : ''}`}
                                        data-tool="Revertir pago"
                                        onClick={() => openRevert(cobro)}>
                                        <RevertIcon />
                                    </button>
                                    <div className="sublist">
                                        <div className="small-icon">
                                            <FileIcon />
                                        </div>
                                        <ul className="list">
                                            {cobro.cemDocumentoCrcCobroCurso.length > 0 && cobro.cemDocumentoCrcCobroCurso.map((file, i) => {
                                                return <li key={i}>
                                                    <p>{getContentName(file.id_content)}</p>
                                                    <button className="small-icon-btn tooltip" data-tool="Descargar" onClick={() => downloadDoc(file)}>
                                                        <DownloadIcon />
                                                    </button>
                                                    <button
                                                        onClick={() => deleteComprobante(file)}
                                                        disabled={!((cobro.id_estatus_cobro === catalogosSt.idsEstatusCobro.registrado || cobro.id_estatus_cobro === catalogosSt.idsEstatusCobro.rechazado) &&
                                                            cobro.is_revertir_pago !== 1)}
                                                        className="small-icon-btn tooltip"
                                                        data-tool="Eliminar">
                                                        <DeleteIcon />
                                                    </button>
                                                </li>
                                            })}
                                            <li className="upload">
                                                <button
                                                    disabled={cobro.cemDocumentoCrcCobroCurso.length > 2 || !((cobro.id_estatus_cobro === catalogosSt.idsEstatusCobro.registrado || cobro.id_estatus_cobro === catalogosSt.idsEstatusCobro.rechazado) &&
                                                        cobro.is_revertir_pago !== 1)}
                                                    className="icon-btn file-up"
                                                >
                                                    <p>Subir comprobante</p>
                                                    <input type="file" onChange={(e) => uploadComprobante(e, cobro)} />
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>

            {showSend &&
                <div className="options">
                    <button disabled={!allSet} onClick={checkPopUp}>Enviar a Validar</button>
                </div>
            }

            <ModalRevertirPago />
            <ModalDatosFiscales idEntidad={idEntidad} formatedPagos={formatedPagos} validatePay={validatePay} setModalData={setModalData} modalData={modalData}/>
        </div>
    )
}