import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    registroConvocatoriaState,
    changeStep,
    setEntidadesAdicionales,
    updateEmpresaSeleccionada,
    getConvocatorias,
    selectConvocatoria,
} from '../../slices/registroConvocatoriaSlice';

import InputComp from '../generales/inputComponent';
import { datosParticipacionForm } from '../../app/forms/registro/datos-participacion';

import {
    ALERT_FAIL,
    CVE_ESTATUS_CONSULTOR,
    CVE_ETATUS_REGISTRO_CONVOCATORIA,
    CVE_MODO_ADICIONAL,
    CVE_MODO_ADICIONAL_SEDE,
    CVE_MODO_CORPORATIVO,
    CVE_MODO_INDIVIDUAL,
    CVE_MODO_SEDEMARCA,
    CVE_PROGRAMAS,
    INSTRUMENTOS_CVE_ESTATUS,
    SERVICIO_ADICIONALES_STEP,
    TIPO_REGISTRO_CORP_INV,
} from '../../app/constantes';

import { useEffect } from 'react';
import {
    catalogosServiceState,
    getAreasCatalogo,
    getCantidadCompanion,
    getIndustriaCatalogo,
    getModalidadParticipacion,
    getProgramasCatalogo,
    getSectorCatalogo,
    getSubsectorCatalogo,
    getTamanioSectorSeCatalogo,
} from '../../slices/catalogosServiceSlice';
import { appState, setAlert, setLoading } from '../../slices/appSlice';
import { findCatalogById, formatForm, isEntidadInvididual, validateForm } from '../../app/commons';
import {
    postRegistroConvocatoria,
    putRegistroConvocatoria,
    deleteRegistroConvocatoria,
} from '../../services/convocatorias';
import ModalidadCorporativo from './datos-participacion/modalidad-corporativo';
import { getInstrumentosByEntidad, postInstrumentoRegistro } from '../../services/instrumento';
import moment from 'moment/moment';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { getTips } from '../../services/catalogos';
import { postEntidadRegistroConvocatoria, putEntidad } from '../../services/entidad';
import { getConsultores } from '../../services/persona';
import { consultorFormFields } from '../../app/forms/registro/datos-contacto';

export default function DatosParticipacion() {
    const dispatch = useDispatch();
    const registrosConvocatoriaSt = useSelector(registroConvocatoriaState);
    const catalogosSt = useSelector(catalogosServiceState);
    const appSt = useSelector(appState);

    const [form, setForm] = useState(datosParticipacionForm);
    const [isEntidadIndividual, setisEntidadIndividual] = useState(true);
    const [empresas, setEmpresas] = useState([]);
    const [misEmpresas, setMisEmpresas] = useState([]);
    const [catalogoModalidad, setCatalogoModalidad] = useState([]);
    const [messages, setMessages] = useState(null);
    const [counter, setCounter] = useState(0);
    const [consultoresCatalogo, setConsultoresCatalogo] = useState(null);
    const [consultorHelp, setConsultor] = useState(false);
    const [editAnios, setEditAnios] = useState(true);
    const [aniosLabel, setAniosLabel] = useState("Años de Obtención");
    const [consultorForm, setConsultorForm] = useState(JSON.parse(JSON.stringify(consultorFormFields)));

    const getDataForEntidad = (empresa) => {
        const convocatoria = registrosConvocatoriaSt.convocatoriaSeleccionada;
        return {
            idEntidad: empresa.idEntidad,
            idConvocatoria: convocatoria.id_convocatoria
                ? convocatoria.id_convocatoria
                : convocatoria.idConvocatoria,
            cvePrograma: convocatoria.cve_programa
                ? convocatoria.cve_programa
                : findCatalogById(
                    convocatoria.idPrograma,
                    catalogosSt.programas,
                    'idPrograma',
                    'cvePrograma'
                ),
            cveSectorSe: empresa.idSectorSe2.cveSectorSe,
            cveTamanio: empresa.idTamanio2.cveTamanio,
            cveTipoEntidad: empresa.idTipoEntidad2
                ? empresa.idTipoEntidad2.cveTipoEntidad
                : 'EMPRESA',
            cveModoParticipacion: form.modalidad.value,
            aniosParticipacion: counter,
            cveAreaCemefi: findCatalogById(
                empresa.idAreaCemefi,
                catalogosSt.areas,
                'idAreaCemefi',
                'cveAreaCemefi'
            ),
            idAliadoRegional: empresa.idAliadoRegional,
            idConsultorAcreditado: empresa.idConsultorAcreditado,
            isAplicaMembresia: empresa.isAplicaMembresia,
            isAcompaniante: empresa.isAcompaniante,
            isAdicional: empresa.isAdicional,
            isPrincipal:
                empresa.isAdicional || empresa.isAcompaniante ? 0 : 1,
            cveEstatusRegistroConvocatoria: CVE_ETATUS_REGISTRO_CONVOCATORIA,
            idEstatusRegistro: 1,
        };
    };

    /**
     * Se hace la vinculacion de la convocatoria padre con las hijas
     * @param {*} respPrincipal 
     * @param {*} idRegistroConvocatoria 
     * @param {*} emp 
     */
    const linkConvocatoria = async (respPrincipal, idRegistroConvocatoria, emp) => {
        const bodyRef = {
            idRegistroConvocatoria,
            idRegistroConvocatoriaReferenciada:
                respPrincipal.idRegistroConvocatoria,
            idEntidad: emp.idEntidad,
            tpOrigen: registrosConvocatoriaSt.empresaSeleccionada.isSedeMarca ? TIPO_REGISTRO_CORP_INV.sede : TIPO_REGISTRO_CORP_INV.corporativo
        }
        await postEntidadRegistroConvocatoria(appSt.serviceData, bodyRef);
    }

    const handleConsultor = async (idEntidad, idRegistroConvocatoria) => {
        const consultorValid = validateForm(consultorForm);
        if (consultorValid && consultorHelp) {
            try {
                const consultorValue = formatForm(consultorForm);
                const body = consultorValue
                await putEntidad({...appSt.serviceData, idEntidad }, body);
                const resp = await putRegistroConvocatoria({
                    ...appSt.serviceData, 
                    idRegistroConvocatoria, 
                }, { idConsultorAcreditado: consultorValue.idConsultorAcreditado })
                if (resp.isPrincipal) {
                    dispatch(selectConvocatoria({ 
                        ...resp, 
                        minimoInvitacion: registrosConvocatoriaSt.convocatoriaSeleccionada.minimoInvitacion,
                        descConvocatoria: registrosConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria,
                        aniosParticipacionEP: registrosConvocatoriaSt.convocatoriaSeleccionada.aniosParticipacionEP,
                        entidadesHijos: registrosConvocatoriaSt.convocatoriaSeleccionada.entidadesHijos,
                        cvePrograma: registrosConvocatoriaSt.convocatoriaSeleccionada.cvePrograma,
                        isInvitacion: registrosConvocatoriaSt.convocatoriaSeleccionada.isInvitacion, 
                        motivoRechazo: registrosConvocatoriaSt.convocatoriaSeleccionada.motivoRechazo,
                    }));
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    /**
     * Siguiente paso
     */
    const nextStep = async () => {
        if (!isEntidadIndividual && misEmpresas.length !== 0) {
            dispatch(setAlert({
                show: true,
                message: 'Favor de agregar modo de participación a todas las entidades',
                type: ALERT_FAIL
            }))
            return
        }
        const empresa = registrosConvocatoriaSt.empresaSeleccionada;
        const dataPrincipal = getDataForEntidad(empresa);
        dispatch(setLoading(true));
        let respPrincipal;
        const exists = empresa.cemRegistroConvocatorias.find(
            (reg) =>
                reg.idConvocatoria ===
                registrosConvocatoriaSt.convocatoriaSeleccionada
                    .idConvocatoria ||
                (registrosConvocatoriaSt.convocatoriaSeleccionada.id_convocatoria && registrosConvocatoriaSt.convocatoriaSeleccionada.isEstatusRegistro !== 0)
        );

        if (
            registrosConvocatoriaSt.convocatoriaSeleccionada
                .idRegistroConvocatoria ||
            registrosConvocatoriaSt.convocatoriaSeleccionada
                .id_registro_convocatoria ||
            exists
        ) {
            const idRegistroConvocatoria = registrosConvocatoriaSt.convocatoriaSeleccionada
            .idRegistroConvocatoria ||
        exists.idRegistroConvocatoria
            const putData = {
                ...appSt.serviceData,
                idRegistroConvocatoria                    
            };
            respPrincipal = await putRegistroConvocatoria(
                putData,
                dataPrincipal
            );
            await handleConsultor(registrosConvocatoriaSt.empresaSeleccionada.idEntidad, idRegistroConvocatoria)

        } else {
            respPrincipal = await postRegistroConvocatoria(
                appSt.serviceData,
                dataPrincipal
            );
            await registrarInstrumento(registrosConvocatoriaSt.empresaSeleccionada);
        }

        if (respPrincipal) {
            const convData = {
                ...appSt.serviceData,
                idEntidad: empresa.idEntidad,
            };
            dispatch(getConvocatorias(convData));
            dispatch(updateEmpresaSeleccionada(convData));
            dispatch(selectConvocatoria({
                ...respPrincipal,
                minimoInvitacion: registrosConvocatoriaSt.convocatoriaSeleccionada.minimoInvitacion,
                descConvocatoria: registrosConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria,
                aniosParticipacionEP: registrosConvocatoriaSt.convocatoriaSeleccionada.aniosParticipacionEP,
                entidadesHijos: registrosConvocatoriaSt.convocatoriaSeleccionada.entidadesHijos,
                cvePrograma: registrosConvocatoriaSt.convocatoriaSeleccionada.cvePrograma,
                isInvitacion: registrosConvocatoriaSt.convocatoriaSeleccionada.isInvitacion,
                motivoRechazo: registrosConvocatoriaSt.convocatoriaSeleccionada.motivoRechazo,
            }));
            await handleConsultor(registrosConvocatoriaSt.empresaSeleccionada.idEntidad, respPrincipal.idRegistroConvocatoria)
        }

        if (!isEntidadIndividual && empresas.length > 0) {
            empresas.forEach(async (emp) => {
                const dataAd = getDataForEntidad(emp);
                if (registrosConvocatoriaSt.empresaSeleccionada.isSedeMarca) {
                    dataAd.cveModoParticipacion = emp.isAdicional ? CVE_MODO_ADICIONAL_SEDE : CVE_MODO_SEDEMARCA;
                } else {
                    dataAd.cveModoParticipacion = emp.isAdicional ? CVE_MODO_ADICIONAL : CVE_MODO_CORPORATIVO;
                }
                dataAd.aniosParticipacion = emp.aniosParticipacion ? emp.aniosParticipacion : '0';

                if (emp.cemRegistroConvocatorias.length > 0) {
                    const exists = emp.cemRegistroConvocatorias.find(
                        (reg) =>
                            reg.idConvocatoria ===
                            registrosConvocatoriaSt.convocatoriaSeleccionada
                                .idConvocatoria && reg.idEstatusRegistro !== 0
                    );
                    if (exists) {
                        const putData = {
                            ...appSt.serviceData,
                            idRegistroConvocatoria:
                                exists.idRegistroConvocatoria,
                        };
                        await putRegistroConvocatoria(
                            putData,
                            dataAd
                        );
                        await linkConvocatoria(respPrincipal, exists.idRegistroConvocatoria, emp);
                        await handleConsultor(emp.idEntidad, exists.idRegistroConvocatoria)

                    } else {
                        const respConv = await postRegistroConvocatoria(
                            appSt.serviceData,
                            dataAd
                        );
                        await linkConvocatoria(respPrincipal, respConv.idRegistroConvocatoria, emp);
                        await handleConsultor(emp.idEntidad, respConv.idRegistroConvocatoria)
                        registrarInstrumento(emp);
                    }
                } else {

                    const respConv = await postRegistroConvocatoria(
                        appSt.serviceData,
                        dataAd
                    );
                    await linkConvocatoria(respPrincipal, respConv.idRegistroConvocatoria, emp);
                    registrarInstrumento(emp);
                }
            });
        }


        if (!isEntidadIndividual && misEmpresas.length > 0) {
            misEmpresas.forEach(async (empresa) => {
                const exist = empresa.cemRegistroConvocatorias.find(
                    (registro) =>
                        registro &&
                        registro.idConvocatoria ===
                        registrosConvocatoriaSt.convocatoriaSeleccionada
                            .idConvocatoria
                );
                if (
                    exist &&
                    exist.idRegistroConvocatoria &&
                    (exist.isAdicional || exist.isAcompaniante)
                ) {
                    const data = {
                        ...appSt.serviceData,
                        idRegistroConvocatoria: exist.idRegistroConvocatoria,
                    };
                    try {
                        await deleteRegistroConvocatoria(data);
                    } catch (error) {
                        dispatch(
                            setAlert({
                                show: true,
                                message:
                                    'Error al eliminar registro de adicional',
                                type: ALERT_FAIL,
                            })
                        );
                    }
                }
            });
        }

        dispatch(setEntidadesAdicionales(empresas));
        dispatch(setLoading(false));
        dispatch(changeStep(SERVICIO_ADICIONALES_STEP));
    };

    const registrarInstrumento = async (entidad) => {
        try {
            const respInstrumento = await getInstrumentosByEntidad({
                ...appSt.serviceData,
                idEntidad: entidad.idEntidad,
                idConvocatoria: registrosConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria
            });
            respInstrumento.forEach(async (instrumento) => {
                const instrumentoBody = {
                    idInstrumentoMedicionConvocatoria: instrumento.idInstrumentoMedicionConvocatoria,
                    idRegistroConvocatoria: instrumento.idRegistroConvocatoria,
                    cveEstatusInstrumentoRegistro: INSTRUMENTOS_CVE_ESTATUS.asignado,
                    fechaAsignacionEntidad: moment().format('YYYY-MM-DD'),
                    isProrrogaEntidad: 0,
                    fechaProrrogaEntidad: null
                }
                await postInstrumentoRegistro(appSt.serviceData, instrumentoBody);
            })
        } catch (error) {
            dispatch(setAlert({
                show: true,
                message: 'Error al registrar instrumento',
                type: ALERT_FAIL
            }))
        }
    }

    const getToolTips = async () => {
        if (messages === null) {
            const resp = await getTips({
                ...appSt.serviceData,
                claveCatalogo: 'parametro',
                clave: 'DATOSPARTICIPACIONCORPORATIVO'
            });
            if (resp.payload) {
                setMessages({
                    corporativoTip: resp.payload.valor
                })
            }
        }
    }

    const changeAnios = (type, empresa) => {
        const clone = JSON.parse(JSON.stringify(empresas));
        const item = clone.find(item => item.idEntidad === empresa.idEntidad);
        if (type === 'minus') {
            item.aniosParticipacion = Number(item.aniosParticipacion) - 1;
        } else {
            item.aniosParticipacion = item.aniosParticipacion ? Number(item.aniosParticipacion) + 1 : 1;
        }
        setEmpresas(clone);
    }

    const addCount = () => {
        setCounter(counter + 1)
    };

    const minusCount = () => {
        setCounter(counter - 1);
    };

    const checkAniosLabel = () => {
        let label = appSt.areaData.idAreaCemefi === 2 ? 'Años Acreditación' : 'Años de Obtención'

        if (registrosConvocatoriaSt.convocatoriaSeleccionada.cvePrograma === CVE_PROGRAMAS.cv) {
            label = "Años de Participación como Empresa Impulsora"
        }

        if (registrosConvocatoriaSt.convocatoriaSeleccionada.cvePrograma === CVE_PROGRAMAS.ep) {
            label = "Años de Participación como Entidad Promotora"
        }

        setAniosLabel(label)
    }

    /**
     * populamos el campo de consultores en el seccion de acompaniamento
     */
    const populateConsultores = async () => {
        const data = {
            ...appSt.serviceData,
            idEntidad: registrosConvocatoriaSt.empresaSeleccionada.idEntidad,
        }
        const resp = await getConsultores(data);

        if (resp.payload) {
            const activos = resp.payload.consultores.filter(item => item.cveEstatusConsultorAcreditado === CVE_ESTATUS_CONSULTOR.aceptado)
            setConsultoresCatalogo(activos);
            if (registrosConvocatoriaSt.convocatoriaSeleccionada && registrosConvocatoriaSt.convocatoriaSeleccionada.idConsultorAcreditado) {
                const clone = JSON.parse(JSON.stringify(consultorForm))
                clone.idConsultorAcreditado.value = registrosConvocatoriaSt.convocatoriaSeleccionada.idConsultorAcreditado
                setConsultor(true)
                setConsultorForm(clone)
            }
        }
    }

    /**
     * Obtenemos catalogos
     */
    useEffect(() => {
        if (!catalogosSt.programas) {
            dispatch(getProgramasCatalogo(appSt.serviceData));
        }

        if (!catalogosSt.areas) {
            dispatch(getAreasCatalogo(appSt.serviceData));
        }

        if (catalogosSt.modoParticipacion === null) {
            dispatch(getModalidadParticipacion(appSt.serviceData));
        }

        if (!catalogosSt.cantidadCompanion) {
            dispatch(getCantidadCompanion(appSt.serviceData));
        }

        if (!catalogosSt.industrias && registrosConvocatoriaSt.empresaSeleccionada) {
            dispatch(getIndustriaCatalogo({ ...appSt.serviceData, idEntidad: registrosConvocatoriaSt.empresaSeleccionada.idEntidad }));
        }

        if (!catalogosSt.sectores) {
            dispatch(getSectorCatalogo({ ...appSt.serviceData, idEntidad: registrosConvocatoriaSt.empresaSeleccionada.idEntidad }));
        }

        if (!catalogosSt.subsectores) {
            dispatch(getSubsectorCatalogo({ ...appSt.serviceData, idEntidad: registrosConvocatoriaSt.empresaSeleccionada.idEntidad }));
        }

        if (!catalogosSt.tamanioSectorSe) {
            dispatch(getTamanioSectorSeCatalogo(appSt.serviceData));
        }

        getToolTips();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSt.serviceData]);

    /**
     * filtramos la modalidad de participacion para no mostar la adicional
     */
    useEffect(() => {
        if (catalogosSt.modoParticipacion) {
            setCatalogoModalidad(catalogosSt.modoParticipacion);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogosSt.modoParticipacion]);

    /**
     * Settemos la modalidad a individual si no es corporativo o sede
     */
    useEffect(() => {
        const clone = JSON.parse(JSON.stringify(form));
        if (registrosConvocatoriaSt.empresaSeleccionada) {
            if (
                isEntidadInvididual(registrosConvocatoriaSt.empresaSeleccionada) && !registrosConvocatoriaSt.empresaSeleccionada.idEntidadPadre
            ) {
                console.log("individual")
                setisEntidadIndividual(true);
                clone.modalidad.value = CVE_MODO_INDIVIDUAL;
            } else {
                console.log("grupo")
                if (registrosConvocatoriaSt.empresaSeleccionada.isSedeMarca) {
                    clone.modalidad.value = CVE_MODO_SEDEMARCA;
                } else {
                    clone.modalidad.value = CVE_MODO_CORPORATIVO;
                }
                setisEntidadIndividual(false);
            }

            setForm(clone);
            if (
                registrosConvocatoriaSt.convocatoriaSeleccionada && catalogosSt.modoParticipacion
            ) {
                const convocatoriaS =
                    registrosConvocatoriaSt.convocatoriaSeleccionada;
                const clone = JSON.parse(JSON.stringify(form));
                const modo = findCatalogById(
                    convocatoriaS.idModoParticipacion,
                    catalogosSt.modoParticipacion,
                    'idModoParticipacion',
                    'cveModoParticipacion'
                );
                setCounter(convocatoriaS.aniosParticipacionEP ? convocatoriaS.aniosParticipacionEP : 0)
                setEditAnios(convocatoriaS.aniosParticipacionEP && convocatoriaS.aniosParticipacionEP >= 0 ? false : true)
                clone.modalidad.value = modo;
                if (modo) {
                    clone.modalidad.value = modo;
                    setForm(clone);
                }
            }

            populateConsultores()
        }

        checkAniosLabel()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        registrosConvocatoriaSt.convocatoriaSeleccionada,
        catalogosSt.modoParticipacion,
    ]);

    return (
        <div className="registro-step datos-participacion">
            {registrosConvocatoriaSt.convocatoriaSeleccionada !==
                'por-invitacion' && (
                    <>
                        <div className="content">
                            <div className="two-col">
                                {catalogosSt.programas && (
                                    <div className="dato">
                                        <label>Programa</label>
                                        <p>
                                            {' '}
                                            {findCatalogById(
                                                registrosConvocatoriaSt
                                                    .convocatoriaSeleccionada
                                                    .id_programa
                                                    ? registrosConvocatoriaSt
                                                        .convocatoriaSeleccionada
                                                        .id_programa
                                                    : registrosConvocatoriaSt
                                                        .convocatoriaSeleccionada
                                                        .idPrograma,
                                                catalogosSt.programas,
                                                'idPrograma',
                                                'descPrograma'
                                            )}
                                        </p>
                                    </div>
                                )}
                                {registrosConvocatoriaSt.empresaSeleccionada &&
                                    <>
                                        <div className="dato">
                                            <label>Sector</label>
                                            <p>{registrosConvocatoriaSt.empresaSeleccionada.idSectorSe2.descSectorSe}</p>
                                        </div>
                                        <div className="dato">
                                            <label>Sub Sector</label>
                                            <p>{registrosConvocatoriaSt.empresaSeleccionada.idSubsectorInegi2.descSubsectorInegi}</p>
                                        </div>
                                        <div className="dato">
                                            <label>Tamaño</label>
                                            <p>{registrosConvocatoriaSt.empresaSeleccionada.idTamanio2.descTamanio}</p>
                                        </div>
                                        <div className="dato">
                                            <label>Industria</label>
                                            <p>{registrosConvocatoriaSt.empresaSeleccionada.idSubsectorInegi2.idSector2.idIndustria2.descIndustria}</p>
                                        </div>
                                    </>
                                }
                                {/* {registrosConvocatoriaSt.convocatoriaSeleccionada && 
                                    <div className="dato">
                                        <label>Años de Participación</label>
                                        <p>{registrosConvocatoriaSt.convocatoriaSeleccionada.aniosParticipacion ? registrosConvocatoriaSt.convocatoriaSeleccionada.aniosParticipacion : '0'}</p>
                                    </div>
                                } */}
                            </div>
                            {form.modalidad.value === CVE_MODO_CORPORATIVO && messages && messages.corporativoTip &&
                                <div className="tip">
                                    <InfoIcon />
                                    <p>{messages.corporativoTip}</p>
                                </div>
                            }

                            {catalogosSt.modoParticipacion && (
                                <InputComp
                                    disabled={true}
                                    label="Modalidad de participación"
                                    name="modalidad"
                                    type="select"
                                    form={form}
                                    setForm={setForm}
                                    catalogos={catalogoModalidad}
                                    catalogoLabel="descModoParticipacion"
                                    catalogoValue="cveModoParticipacion"
                                />
                            )}

                            <div className="input-wrapper">
                                <label>{aniosLabel}</label>
                                <div className="counter-wrapper">
                                    <button disabled={true} onClick={minusCount}>
                                        -
                                    </button>
                                    <p>{counter === 0 ? 'Primera vez' : counter}</p>
                                    <button disabled={true} onClick={addCount}>+</button>
                                </div>
                            </div>

                            {(form.modalidad.value === CVE_MODO_CORPORATIVO || form.modalidad.value === CVE_MODO_SEDEMARCA) && empresas.length > 0 && empresas.map((empresa, index) => {
                                return <div className='input-wrapper' key={index}>
                                    <label><span>*</span> {aniosLabel} - {empresa.nombreComercial}</label>
                                    <div className="counter-wrapper">
                                        <button disabled={!empresa.aniosParticipacion || empresa.aniosParticipacion === 0 || !empresa.canEditAnios } onClick={() => changeAnios('minus', empresa)}>
                                            -
                                        </button>
                                        <p>{!empresa.aniosParticipacion || empresa.aniosParticipacion === 0 ? 'Primera vez' : empresa.aniosParticipacion}</p>
                                        <button disabled={!empresa.canEditAnios} onClick={() => changeAnios('plus', empresa)}>+</button>
                                    </div>
                                </div>
                            })}

                            {(form.modalidad.value === CVE_MODO_CORPORATIVO || form.modalidad.value === CVE_MODO_SEDEMARCA) && !registrosConvocatoriaSt.empresaSeleccionada.idEntidadPadre && (
                                <ModalidadCorporativo
                                    form={form}
                                    setForm={setForm}
                                    empresas={empresas}
                                    setEmpresas={setEmpresas}
                                    misEmpresas={misEmpresas}
                                    setMisEmpresas={setMisEmpresas}
                                />
                            )}

                            {consultoresCatalogo && consultoresCatalogo.length > 0 &&
                                <div className="company">
                                    <div className="radio-wrapper">
                                        <div className="flex">
                                            <label>¿Estás recibiendo acompañamiento de un consultor acreditado?</label>
                                            <a target='_blank' href="https://www.cemefi.org/directorio-consultorse/" rel="noreferrer">https://www.cemefi.org/directorio-consultorse/</a>
                                        </div>
                                        <div className="radios">
                                            <div className="radio-input">
                                                <input checked={consultorHelp} value='si' name="ayudo_consultor" type="radio" onChange={() => setConsultor(true)} />
                                                <div className="check"></div>
                                                <label>Sí</label>
                                            </div>
                                            <div className="radio-input">
                                                <input checked={!consultorHelp} value='no' name="ayudo_consultor" type="radio" onChange={() => setConsultor(false)} />
                                                <div className="check"></div>
                                                <label>No</label>
                                            </div>
                                        </div>
                                    </div>
                                    {consultorHelp &&
                                        <InputComp label="Consultor Acreditado" name="idConsultorAcreditado" type="select" form={consultorForm} setForm={setConsultorForm} catalogos={consultoresCatalogo} catalogoValue={'idConsultorAcreditado'} catalogoLabel={'combine'} />
                                    }
                                </div>
                            }
                            {registrosConvocatoriaSt.convocatoriaSeleccionada && registrosConvocatoriaSt.convocatoriaSeleccionada.motivoRechazo && <div className='tip'>
                                <InfoIcon />
                                <span><b>Motivo de Rechazo:</b> {registrosConvocatoriaSt.convocatoriaSeleccionada.motivoRechazo}</span>
                            </div>}
                        </div>
                    </>
                )}


            <div className="actions">
                <button onClick={nextStep}>Continuar</button>
            </div>
        </div>
    );
}