import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import { ReactComponent as EmpresaIcon } from '../../../assets/icons/empresa.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registroConvocatoriaState, setEntidadesAdicionales } from '../../../slices/registroConvocatoriaSlice';
import { catalogosServiceState } from '../../../slices/catalogosServiceSlice';
import { appState } from '../../../slices/appSlice';
import { CONVOCATORIAS_ID, CVE_MODO_CORPORATIVO, CVE_MODO_SEDEMARCA, TAMANIOS_IDS } from '../../../app/constantes';
import { getCorporativo, getEntidadesHijas } from '../../../services/entidad';

export default function ModalidadCorporativo({form, empresas, setEmpresas, misEmpresas, setMisEmpresas}) {
    const dispatch = useDispatch();
    const registrosConvocatoriaSt = useSelector(registroConvocatoriaState);
    const catalogosSt = useSelector(catalogosServiceState);
    const appSt = useSelector(appState);

    const [isCompanionDisabled, setIsCompanionDisabled] = useState(false);

    /**
     * Movemos items del corporativo
     */
     const moveItem = (empresa, index, direction, type) => {
        const empresaClone = JSON.parse(JSON.stringify(empresa));
        const from = JSON.parse(JSON.stringify(direction === 'add' ? misEmpresas : empresas));
        const to = JSON.parse(JSON.stringify(direction === 'add' ? empresas : misEmpresas));

        from.splice(index, 1);
        
        to.push(empresaClone);
        
        if (direction === 'add') {
            if (type === 'companion') { empresaClone.isAcompaniante = 1; };
            if (type === 'aditional') { empresaClone.isAdicional = 1; };
            setEmpresas(to);
            setMisEmpresas(from);
        } else {
            if (empresaClone.isAcompaniante === 1) { empresaClone.isAcompaniante = 0; };
            if (empresaClone.isAdicional === 1) { empresaClone.isAdicional = 0; };
            setEmpresas(from)
            setMisEmpresas(to);
        }            
    }

    /**
     * Obtenemos las entidades de un corporativo
     * Checamos si las entidades tienen un registro con el mismo q se selecciono
     */
     const getCorp = async () => {
        const data = {
            ...appSt.serviceData,
            idEntidad: registrosConvocatoriaSt.empresaSeleccionada.idEntidad
        }
        let resp
        if (registrosConvocatoriaSt.empresaSeleccionada.isCorporativo || registrosConvocatoriaSt.empresaSeleccionada.isMiembro) {
            resp = await getCorporativo(data);
        } else {
            resp = await getEntidadesHijas({...appSt.serviceData, idEntidadPadre: registrosConvocatoriaSt.empresaSeleccionada.idEntidad})
        }
        const empresasResp = registrosConvocatoriaSt.empresaSeleccionada.isCorporativo || registrosConvocatoriaSt.empresaSeleccionada.isMiembro ? resp.payload : resp;
        if (resp && registrosConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria) {
            const misEmpresasA = [];
            const empresasA = [];            
            empresasResp.forEach(emp => {
                let anioHijo = null
                const empRegistro = emp.cemRegistroConvocatorias.find(registro => registro.idConvocatoria === registrosConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria && registro.idEstatusRegistro !== 0);
                if (registrosConvocatoriaSt.convocatoriaSeleccionada.entidadesHijos) {
                    anioHijo = registrosConvocatoriaSt.convocatoriaSeleccionada.entidadesHijos.find(i => i.idEntidad === emp.idEntidad)
                }
                if (empRegistro && empRegistro.idEstatusRegistro !== 0) {
                    emp.isAcompaniante = empRegistro.isAcompaniante;
                    emp.isAdicional = empRegistro.isAdicional;
                    emp.aniosParticipacion = anioHijo.aniosParticipacion
                    emp.canEditAnios = !anioHijo
                }
                if (emp.isAcompaniante || emp.isAdicional) {
                    empresasA.push(emp);
                } else {
                    misEmpresasA.push(emp);
                }
            });
            setEmpresas(empresasA);
            setMisEmpresas(misEmpresasA);
            dispatch(setEntidadesAdicionales(empresasA));

        } else if (empresasResp) {
            const array = []
            for (const item of empresasResp) {
                let anioHijo = null
                let canEditAnios = false
                let aniosParticipacion = 0
                if (registrosConvocatoriaSt.convocatoriaSeleccionada.entidadesHijos) {
                    anioHijo = registrosConvocatoriaSt.convocatoriaSeleccionada.entidadesHijos.find(i => i.idEntidad === item.idEntidad)
                    canEditAnios = anioHijo === undefined
                    aniosParticipacion = anioHijo ? anioHijo.aniosParticipacion : 0
                }
                array.push({
                    ...item, 
                    canEditAnios,
                    aniosParticipacion
                })                
            }
            setMisEmpresas(array);            
        }
    }

    const checkValidations = (entidad) => {
        let valid = false;
        if (Number(registrosConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria) === Number(CONVOCATORIAS_ID.pymes) && 
            (Number(entidad.idTamanio) === Number(TAMANIOS_IDS.micro) || Number(entidad.idTamanio) === Number(TAMANIOS_IDS.small) || Number(entidad.idTamanio) === Number(TAMANIOS_IDS.medium))) {
            valid = true;
        }
        if (Number(registrosConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria) === Number(CONVOCATORIAS_ID.empresasGrandes) && 
            (Number(entidad.idTamanio) === Number(TAMANIOS_IDS.large))) {
            valid = true;
        }
        if (
            Number(registrosConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria) !== Number(CONVOCATORIAS_ID.pymes) && 
            Number(registrosConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria) !== Number(CONVOCATORIAS_ID.empresasGrandes)) {
            valid = true;
        }
        return valid;
    }

    /**
     * Checamos si la cantidad de acompañaste no exede lo setteado en el servicio
     */
     useEffect(() => {
        if (empresas && catalogosSt.cantidadCompanion) {
            const companions = empresas.filter(emp => emp.isAcompaniante === 1);
            setIsCompanionDisabled(String(companions.length) === catalogosSt.cantidadCompanion.valor);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empresas, catalogosSt.cantidadCompanion])

    /**
     * si es corporativo mandamos a llamar a sus entidades
     */
     useEffect(() => {
        if (form.modalidad.value === CVE_MODO_CORPORATIVO || form.modalidad.value === CVE_MODO_SEDEMARCA) {
            getCorp()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.modalidad, registrosConvocatoriaSt.convocatoriaSeleccionada])
    return <>
        <div className="tip">
            <InfoIcon />
            <p>Sí no ves la entidad que requieres agregar, por favor concluye el registro o espera a que esté aprobada.</p>
        </div>
        <div className="two-col">
            <div className="card-registro">
                <div className="head">
                    <EmpresaIcon />
                    <h3>Entidades</h3>
                </div>
                <ul>
                    {misEmpresas.map((empresa, index) => {
                        return checkValidations(empresa) && <li key={index}>
                            <p>{empresa.nombreComercial}</p>
                            <div className="sublist">
                                <AddIcon />
                                <ul>
                                    <li>
                                        <button disabled={isCompanionDisabled} onClick={() => moveItem(empresa, index, 'add', 'companion')}>Acompañante</button>
                                    </li>
                                    {((misEmpresas.length > 1 || empresas.length !== 0)) &&
                                        <li>
                                            <button onClick={() => moveItem(empresa, index, 'add', 'aditional')}>Adicional</button>
                                        </li>                                    
                                    }
                                </ul>
                            </div>
                        </li>
                    })}
                </ul>
            </div>

            <div className="card-registro">
                <div className="head">
                    <EmpresaIcon />
                    <h3>Entidades Agregadas</h3>
                </div>
                <ul>
                    {empresas.map((empresa, index) => {
                        return <li key={index}>
                            <p>{empresa.nombreComercial} <span>{empresa.isAcompaniante ? 'Acompañante' : 'Adicional'}</span></p>
                            <button onClick={() => moveItem(empresa, index, 'remove')}>
                                <DeleteIcon />
                            </button>
                        </li>
                    })}
                </ul>
            </div>
        </div>
    </>
}