import { getRequest, getRequestPublic, postRequest } from "./http-service";

const url = process.env.REACT_APP_CATALOGOS_SERVICE_URL;

export const getCatalogos = async (area, claveCatalogo, token, idPadre, idEntidad) => {
    let request = `${url}area/${area}/catalogo/${claveCatalogo}?idEstatusRegistro=1`;
    if (idPadre) { request = `${request}&idCatalogoPadre=${idPadre}` };
    if (idEntidad) { request = `${request}&idEntidad=${idEntidad}` };
    return await getRequest(request, token);
}

export const getTips = async (data) => {
    let request = `${url}area/${data.area}/catalogo/${data.claveCatalogo}/clave/${data.clave}?estatusRegistro=1`;
    return await getRequest(request, data.token);
}

export const getInstrumentoCatalogosCriterio = async (data) => {
    let request = `${url}catalogo/convocatoria/${data.idConvocatoria}/entidad/${data.idEntidad}/criterio`;
    return await getRequest(request, data.token);
}

export const getInstrumentoCatalogosAmbito = async (data) => {
    let request = `${url}catalogo/convocatoria/${data.idConvocatoria}/entidad/${data.idEntidad}/criterio/${data.idCriterio}/ambito`;
    return await getRequest(request, data.token);
}

export const getInstrumentoCatalogosSubambito = async (data) => {
    let request = `${url}catalogo/convocatoria/${data.idConvocatoria}/entidad/${data.idEntidad}/ambito/${data.idAmbito}/sub-ambito`;
    return await getRequest(request, data.token);
}

export const getInstrumentoCatalogosIndicador = async (data) => {
    let request = `${url}catalogo/convocatoria/${data.idConvocatoria}/entidad/${data.idEntidad}/sub-ambito/${data.idSubAmbito}/indicador`;
    return await getRequest(request, data.token);
}

export const getDocumentCatalog = async (area, token, idPadre, idEstatusRegistro) => {
    let request = `${url}area/${area}/catalogo/documentoCemefi?idCatalogoPadre=${idPadre}&idEstatusRegistro=1`;
    return await getRequest(request, token);
}

export const getMessageByModulo = async (area, token, modulo, parametro) => {
    let request = `${url}area/${area}/parametro/modulo/${modulo}/${parametro}`;
    return await getRequest(request, token);
}

export const getParametrosPorModulo = async (area, token, cveModulo) => {
    const requestUrl = `${url}parametro/area/${area}/modulo/${cveModulo}`;
    return await getRequest(requestUrl, token);
}

export const getAreas = async (token) => {
    const requestUrl = `${url}catalogo/area-cemefi`;
    return await getRequest(requestUrl, token);
}

export const getTableSector = async (area, token) => {
    const requestUrl = `${url}area/${area}/tamanio-sector/detail?idEstatusRegistro=1`;

    const resp = await getRequest(requestUrl, token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { area },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getNivelesByInstrumentoRegistro = async (data) => {
    const requestUrl = `${url}area/${data.area}/catalogo/nivel-madurez/instrumento-registro/${data.idInstrumentoRegistro}`;
    return await getRequest(requestUrl, data.token);
}

export const getTamanioByConvocatoria = async (data) => {
    const requestUrl = `${url}area/${data.area}/catalogo/tamanio/convocatoria/${data.idConvocatoria}`;
    return await getRequest(requestUrl, data.token);
}

//https://convocatoriasvc-dev.cemefi.org/convocatoria/api/v1/area/:idAreaCemefi/convocatoria-publica
export const getAreasPublicas = async (data) => {
    const requestUrl = `${url}catalogo-publico/area-cemefi`;
    return await getRequestPublic(requestUrl);
}

export const getAmbitosPublicas = async (data) => {
    const requestUrl = `${url}catalogo-publico/area/${data.idAreaCemefi}/ambito`;
    return await getRequestPublic(requestUrl);
}
export const getAmbitosPublicas2 = async (data) => {
    let requestUrl = `${url}catalogo-publico/convocatoria/${data.idConvocatoria}/ambito`;
    // const requestUrl = `${url}catalogo-publico/area/${data.idAreaCemefi}/ambito`;
    return await getRequest(requestUrl, data.token);
}
//https://catalogosvc-dev.cemefi.org/catalogo/api/v1/area/:idAreaCemefi/catalogo/informacion/forma-pago?tipoConsulta=1
export const getFormasPagosDinamico = async (data) => {
    const requestUrl = `${url}area/${data.area}/catalogo/informacion/forma-pago?tipoConsulta=${data.tipoConsulta}`;
    return await getRequest(requestUrl, data.token);
}

export const getAmbitosByConvocatoria = async (data) => {
    const requestUrl = `${url}catalogo/convocatoria/${data.idConvocatoria}/entidad/${data.idEntidad}/ambito`;
    return await getRequest(requestUrl, data.token);
}

export const getAmbitosByAnioPrograma = async (data) => {
    const requestUrl = `${url}area/${data.area}/catalogo/ambito/multiple`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body})
    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            payload: resp.payload,
        };
    }

    return resp.payload;
}

export const getSubAmbitosByAnioPrograma = async (data) => {
    const requestUrl = `${url}area/${data.area}/catalogo/ambito/${data.idAmbito}/sub-ambito/multiple`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body})
    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            payload: resp.payload,
        };
    }

    return resp.payload;
}

export const getIndicadorByAnioPrograma = async (data) => {
    const requestUrl = `${url}area/${data.area}/catalogo/sub-ambito/${data.idSubambito}/indicador/multiple`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body})
    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            payload: resp.payload,
        };
    }

    return resp.payload;
}
