import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatForm, validateForm, isValueNullOrEmpty } from '../../app/commons';
import { CVE_PERSONA_FISICA, CVE_PERSONA_MORAL, NOTIFICACIONES } from '../../app/constantes';
import { registroUsuarioForm } from '../../app/forms/registro/registro-usuario';
import { postNotificacion } from '../../services/notificaciones';
import { getPersonaByRfc, postPersona } from '../../services/persona';
import { postUsuario, putUsuario } from '../../services/usuarios';
import { appState, setAreaData, setLoading, setServiceData } from '../../slices/appSlice';
import { catalogosServiceState, getAllAreas, getPaisCatalogo, resetCatalogos, resetRegistro } from '../../slices/catalogosServiceSlice';
import { getUsuarioPorId, usuariosServicesState } from '../../slices/usuariosServicesSlice';
import { setModalUser, usuariosState } from '../../slices/usuariosSlice';
import '../../styles/registro.scss';
import InputComp from '../generales/inputComponent';
import { resetInstrumentoState } from '../../slices/instrumentoServiceSlice';
import { resetConvocatoria } from '../../slices/registroConvocatoriaSlice';
import { resetEntidad } from '../../slices/entidadServicesSlice';
import { useNavigate } from 'react-router-dom';

export default function RegistroUsuario() {
    const userSt = useSelector(usuariosState);
    const appSt = useSelector(appState);
    const navigate = useNavigate();
    const catalogosSt = useSelector(catalogosServiceState);
    const usuarioSt = useSelector(usuariosState);
    const usuarioServiceSt = useSelector(usuariosServicesState);

    const dispatch = useDispatch();

    const [edit, setEdit] = useState(false);
    const [form, setForm] = useState(registroUsuarioForm);
    const [error, setError] = useState({
        show: false,
        message: ''
    });

    /**
     * Cierra modal
     */
    const closeModal = () => {
        setEdit(false);
        dispatch(setModalUser({
            show: false,
            data: null
        }))
    }

    const checkValue = (e) => {
        const formatedForm = formatForm(form);
        setError({
            show: false,
            message: ''
        });
        if (formatedForm.email !== formatedForm.emailConfirmar) {
            dispatch(setLoading(false));
            setError({
                show: true,
                message: 'El correo no coincide'
            })

        }
    }

    /**
     * Se hacen validaciones antes de llamar el servicio
     */
    const handleSave = async () => {
        const valid = validateForm(form);
        console.log(form, valid)
        setError({
            show: false,
            message: ''
        })
        dispatch(setLoading(true));
        if (valid.length === 0) {
            const formatedForm = formatForm(form);
            if (!edit) {
                if (formatedForm.email === formatedForm.emailConfirmar) {
                    if (form.rfc.value.length !== 13) {
                        dispatch(setLoading(false));
                        setError({
                            show: true,
                            message: 'El RFC tiene que ser de una persona física'
                        })
                    } else {
                        const dataRfc = {
                            token: appSt.token,
                            rfc: form.rfc.value,
                            area: 1
                        };
                        const resp = await getPersonaByRfc(dataRfc);
                        if (!!resp.payload) {
                            dispatch(setLoading(false));
                            setError({
                                show: true,
                                message: 'El RFC ya ha sido registrado anteriormente.'
                            })
                        } else {
                            setError({
                                show: false,
                                message: ''
                            })
                            await registerUser(formatedForm);
                            dispatch(setLoading(false));
                        }
                    }
                } else {
                    dispatch(setLoading(false));
                }
            } else {
                updateUsuario(formatedForm);
            }
        } else {
            dispatch(setLoading(false));
            setError({
                show: true,
                message: 'Favor de llenar todos los campos'
            })
        }
    }

    /**
     * Guardamos el usuario
     * @param {*} form 
     */
    const registerUser = async (form) => {
        dispatch(setLoading(true));
        const data = {
            token: appSt.token,
            area: 1
        }
        const usuarioBody = {
            ...form,
            idUsuarioFederado: appSt.tokenInfo.sub,
            username: appSt.tokenInfo.preferred_username,
            origen: 'cliente'
        }
        const personaBody = {
            rfc: form.rfc,
            cveRegimenPersona: form.rfc.length === 12 ? CVE_PERSONA_MORAL : CVE_PERSONA_FISICA
        }
        delete usuarioBody.rfc;
        const respUsuario = await postUsuario(data, usuarioBody);
        const respPersona = await postPersona(data, personaBody);

        if (!respPersona.error && !respUsuario.error) {
            closeModal();
            dispatch(setLoading(false));
            postNotificacion({token: appSt.token}, {
                to: [
                    {email: respUsuario.payload.email,name: respUsuario.payload.nombre}
                ],
                templateData: {
                    hipervinculo: "https://outlook.office.com/mail/"
                },
                templateId: NOTIFICACIONES.templatesIds.registroUsuario
            })
            dispatch(getUsuarioPorId({
                ...data,
                idUsuario: appSt.tokenInfo.sub,
            }))
        } else {
            dispatch(setLoading(false));
            setError({
                show: true,
                message: 'Error al guardar usuario'
            })
        }
    }

    /**
     * Actualizamos el usuario
     * @param {*} form 
     */
    const updateUsuario = async (form) => {
        dispatch(setLoading(true));
        const usuarioBody = {
            nombre: form.nombre,
            apellidoPaterno: form.apellidoPaterno,
            apellidoMaterno: form.apellidoMaterno,
            telefono: form.telefono,
            idAreaCemefiDefault: form.idAreaCemefiDefault
        }
        const respUsuario = await putUsuario({...appSt.serviceData, idUsuario: usuarioServiceSt.usuario.data.idUsuario}, usuarioBody);
        
        if (!respUsuario.error) {
            closeModal();
            dispatch(setLoading(false));
            dispatch(getUsuarioPorId({
                ...appSt.serviceData,
                idUsuario: appSt.tokenInfo.sub,
            }))
            changeArea(form.idAreaCemefiDefault);
        } else {
            dispatch(setLoading(false));
            setError({
                show: true,
                message: 'Error al guardar usuario'
            })
        }
    }


    useEffect(() => {
        if (catalogosSt.pais === null && appSt.token) {
            const data = {
                area: 1,
                token: appSt.token
            }
            dispatch(getPaisCatalogo(data));
        }
        if (appSt.tokenInfo) {
            const updateForm = JSON.parse(JSON.stringify(form));
            updateForm.nombre.value = appSt.tokenInfo.given_name ? appSt.tokenInfo.given_name : '';
            updateForm.apellidoPaterno.value = appSt.tokenInfo.family_name ? appSt.tokenInfo.family_name : '';
            updateForm.email.value = appSt.tokenInfo.email ? appSt.tokenInfo.email : '';
            updateForm.cvePais.value = 'MX'
            setForm(updateForm);
            if (!catalogosSt.listadoAreas) {
                dispatch(getAllAreas({token: appSt.token}))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSt.token]);

    /**
     * Caso para cuando se edita al usuario
     */
    useEffect(() => {
        if (usuarioServiceSt.usuario && usuarioServiceSt.usuario.data) {
            const updateForm = JSON.parse(JSON.stringify(form));
            const object = usuarioServiceSt.usuario.data;
            console.log(object)

            for (const key in object) {
                if (Object.hasOwnProperty.call(object, key)) {
                    const element = object[key];
                    if (updateForm[key] && isValueNullOrEmpty(element)) {
                        updateForm[key].value = element;
                        updateForm[key].invalid = false;
                    }
                    if (updateForm[key] && (key === 'nombre' || key === 'apellidoPaterno' || key === 'apellidoMaterno' || key === 'telefono')) {
                        updateForm[key].required = true;
                    } else if (updateForm[key]) {
                        updateForm[key].required = false;
                    }
                }
            }
            updateForm.rfc.required = false;
            updateForm.emailConfirmar.required = false;
            updateForm.cvePais.required = false
            setForm(updateForm);
            setEdit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuarioSt.modalUsuario])

    const changeArea = async(idArea) => {
        const clone = JSON.parse(JSON.stringify(appSt.serviceData));
        clone.area = idArea;
        navigate('inicio');
        dispatch(resetInstrumentoState());
        dispatch(resetCatalogos());
        dispatch(resetRegistro());
        dispatch(resetConvocatoria());
        dispatch(resetEntidad());
        dispatch(setServiceData(clone));
        const areaDisp = catalogosSt.listadoAreas.find(item => Number(item.idAreaCemefi) === Number(idArea));
        dispatch(setAreaData(areaDisp));
    }

    return (
        <div className={userSt.modalUsuario.show ? 'modal-wrapper active' : 'modal-wrapper'}>
            <div className="modal">
                <div className="head">
                    <h3>{edit ? 'Editar Usuario' : 'Registro de Usuario'}</h3>
                    <button onClick={closeModal}>x</button>
                </div>
                {error.show &&
                    <p className='message-error'>{error.message}</p>
                }
                <div className="content">
                    {!edit ? <div className="two-col">
                        <InputComp label="Nombre" name="nombre" type="upper" form={form} setForm={setForm} />
                        <InputComp label="Apellido Paterno" name="apellidoPaterno" type="upper" form={form} setForm={setForm} />
                        <InputComp label="Apellido Materno" name="apellidoMaterno" type="upper" form={form} setForm={setForm} />
                        {catalogosSt.pais !== null && <InputComp label="País" name="cvePais" type="select" form={form} setForm={setForm} catalogos={catalogosSt.pais} catalogoValue={'cvePais'} catalogoLabel={'descPais'} />}
                        <InputComp disabled label="Email" name="email" type="text" form={form} setForm={setForm} />
                        <InputComp label="Confirmar Email" name="emailConfirmar" type="text" form={form} setForm={setForm} onBlurAction={checkValue} />
                        <InputComp max='10' label="Teléfono" name="telefono" type="number" form={form} setForm={setForm} />
                        <InputComp label="RFC Persona Física" name="rfc" type="upper" form={form} setForm={setForm} validation={'^.{12,13}$'} />
                    </div>
                        : <div className="two-col">
                            <InputComp label="Nombre" name="nombre" type="upper" form={form} setForm={setForm} />
                            <InputComp label="Apellido Paterno" name="apellidoPaterno" type="upper" form={form} setForm={setForm} />
                            <InputComp label="Apellido Materno" name="apellidoMaterno" type="upper" form={form} setForm={setForm} />
                            <InputComp max='10' label="Teléfono" name="telefono" type="number" form={form} setForm={setForm} />
                            <InputComp disabled={true} label="RFC" name="rfc" type="upper" form={form} setForm={setForm} />
                            <InputComp disabled={true} label="Correo" name="email" type="upper" form={form} setForm={setForm} />
                        </div>

                    }
                    {catalogosSt.listadoAreas  &&  
                        <InputComp label="Área Seleccionada" name="idAreaCemefiDefault" type="select" form={form} setForm={setForm} catalogos={catalogosSt.listadoAreas} catalogoValue={'idAreaCemefi'} catalogoLabel={'descAreaCemefi'} />
                    }

                    {/* <p>Cambiar área</p>
                    <div className='areaChange'>
                        { appSt.areaData && appSt.areaData.descAreaCemefi &&
                            <p>{appSt.areaData.descAreaCemefi}</p>
                        }
                        {appSt.areasDisponibles && appSt.areasDisponibles.length > 1 &&
                            <div>
                                <ul className='submenu'>
                                {appSt.areasDisponibles.map((area, index) => {
                                    return Number(appSt.serviceData.area) !== Number(area.idAreaCemefi) && <li onClick={() => changeArea(area.idAreaCemefi)} key={index}>{area.descAreaCemefi}</li>
                                })}                      
                                </ul>
                            </div>                 
                        }
                    </div> */}
                </div>

                <div className="actions">
                    
                    <button onClick={closeModal}>Cancelar</button>
                    <button onClick={handleSave}>Guardar</button>
                </div>
            </div>
        </div>
    );
}