import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { changeStep, registroState, updateEmpresaSeleccionada } from "../../slices/registroSlice";

import { findCatalogById, formatForm, formatValor, validateForm } from "../../app/commons";
import { ALERT_FAIL, ALERT_SUCCESS, DOCUMENTOS_STEP, REDES_STEP, SUCESS_MESSAGE } from "../../app/constantes";
import { deleteMembresiaEntidad, getMembresiaByEntidad, getMembresias, postMembresiaEntidad } from "../../services/membresias";
import { membresiasForm } from "../../app/forms/registro/memebresia";

import moment from "moment/moment";
import InputComp from "../generales/inputComponent";

import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as MembresiaIcon } from '../../assets/icons/membresia.svg';
import ConfirmModal from "../generales/confirmModal";
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { getMessageByModulo } from "../../services/catalogos";

export default function Membresias() {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const registroSt = useSelector(registroState);
    const catalogosSt = useSelector(catalogosServiceState);

    const [membresiasCatalogo, setMembresias] = useState(null);
    const [currentMembership, setCurrentMembership] = useState(null);
    const [form, setForm] = useState(JSON.parse(JSON.stringify(membresiasForm)));
    const [messages, setMessages] = useState(null);
    // Local States
    const [modalData, setModalData] = useState({
        show: false,
        title: '',
        desc: '',
        info: null,
        list: null
    });

    // Cierra Modal de confirmacion
    const closeModal = () => {
        setModalData({
            ...modalData,
            show: false
        })
    }

    const nextStep = () => {
        selectMembresia();
        dispatch(changeStep(DOCUMENTOS_STEP));

    }
    const prevStep = () => {
        dispatch(changeStep(REDES_STEP));
    }

    /**
     * Obtenemos la membresia del usuario
     */
    const fillMembresias = async () => {
        const data = {
            ...appSt.serviceData,
            idTipoEntidad: registroSt.empresaSeleccionada.idTipoEntidad,
            idTamanio: registroSt.empresaSeleccionada.idTamanio,
            // idModoParticipacion: registroSt.empresaSeleccionada.idModoParticipacion,
        }

        try {
            const resp = await getMembresias(data);
            if (resp.length > 0) {
                setMembresias(resp);
            }
        } catch (error) {
            console.log(error)
            dispatch(setAlert({
                show: true,
                message: error.payload.length === 0 ? 'No se encontraron membresías' : 'Error al traer membresias actuales',
                type: ALERT_FAIL
            }))
        }
    }

    /**
     * Se selecciona una membresia para guardar
     */
    const selectMembresia = async () => {
        dispatch(setLoading(true));
        const valid = validateForm(form);

        if (valid.length === 0) {
            const formatedForm = formatForm(form);
            if (formatedForm.idMembresia === '') {
                dispatch(setLoading(false));
                dispatch(setAlert({
                        show: true,
                        message: SUCESS_MESSAGE,
                        type: ALERT_SUCCESS
                    }))

            } else {
                const data = {
                    ...appSt.serviceData,
                    idEntidad: registroSt.empresaSeleccionada.idEntidad,
                    idMembresia: formatedForm.idMembresia
                }
                try {
                    const body = {
                        fechaAdquisicion: moment().format('YYYY-MM-DD')
                    }
                    await postMembresiaEntidad(data, body);
                    getCurrentMembresias();            
                    dispatch(setLoading(false));
                    dispatch(updateEmpresaSeleccionada({
                        ...appSt.serviceData,
                        idEntidad: registroSt.empresaSeleccionada.idEntidad
                    }));
                    dispatch(setAlert({
                        show: true,
                        message: 'Membresía guardada',
                        type: ALERT_SUCCESS
                    }))
                    setForm(JSON.parse(JSON.stringify(membresiasForm)))
                } catch (error) {
                    dispatch(setLoading(false));
                    dispatch(setAlert({
                        show: true,
                        message: 'Error al guardar membresía',
                        type: ALERT_FAIL
                    }))
                    
                }
            }
        }
    }

    // Confirmar Eliminacion
    const confirmDelete = (id) => {
        const membresiaDoc = registroSt.empresaSeleccionada.cemDocumentoCrcEntidads.find(doc => doc.idDocumentoCemefi === catalogosSt.idsTipoDocumentoCemefi.membresia && doc.idEstatusRegistro === 1);
        console.log(membresiaDoc)
        if (membresiaDoc) {
            dispatch(setAlert({
                show: true,
                type: ALERT_FAIL,
                message: 'Favor de borrar el documento de la membresía antes de eliminarla'
            }))
        } else {
            setModalData({
                show: true,
                title: 'Eliminar membresía',
                desc: '¿Seguro deseas eliminar la membresía?',
                info: id,
                action: () => deleteMembresia()
            })
        }
    }

    /**
     * Eliminamos la membresia
     */
    const deleteMembresia = async () => {
        dispatch(setLoading(true));

        try {
            await deleteMembresiaEntidad({
                ...appSt.serviceData,
                idMembresiaEntidad: currentMembership.idMembresiaEntidad
            });
            dispatch(setLoading(false));
            dispatch(updateEmpresaSeleccionada({
                ...appSt.serviceData,
                idEntidad: registroSt.empresaSeleccionada.idEntidad
            }));
            dispatch(setAlert({
                show: true,
                message: 'Membresía eliminada',
                type: ALERT_SUCCESS
            }));
            getCurrentMembresias(); 
            closeModal();           
        } catch (error) {
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: 'Error al eliminar membresía',
                type: ALERT_FAIL
            }))
        }
        
    }

    /**
     * Obtenemos la membresia vigente
     */
    const getCurrentMembresias = async () => {
        try {
            const resp = await getMembresiaByEntidad({
                ...appSt.serviceData,
                idEntidad: registroSt.empresaSeleccionada.idEntidad
            });
            if (resp.length > 0) {
                setCurrentMembership(resp[0]);
            }    
            
        } catch (error) {
            setCurrentMembership(null)
        }
    }

    const getMessages = async () => {
        const resp = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'REGISTRO', 'MEMBRESIA ');
        if (resp.payload && resp.payload.valor) {
            setMessages({...messages, topMessage: formatValor(resp)});
        }
    }

    /**
     * Llenamos catalogos
     */
    useEffect(() => {
        if (registroSt.empresaSeleccionada && membresiasCatalogo === null) {
            fillMembresias();
        }
        if (registroSt.empresaSeleccionada) {
            getCurrentMembresias();
        }
        if (!messages) {
            getMessages()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="registro-step membresias">
            {!currentMembership && messages && messages.topMessage &&
                <div className="tip">
                    <InfoIcon />
                    <div>
                        {messages.topMessage.map((item, index) => {
                            return <p key={index}>{item}</p>
                        })}
                    </div>
                </div>            
            }
            <div className="content">
                {!currentMembership && 
                    <div>
                        {membresiasCatalogo &&
                            <InputComp disabled={currentMembership !== null || registroSt.isSede} label="Membresía" name="idMembresia" type="select" form={form} setForm={setForm} catalogos={membresiasCatalogo} catalogoValue={'idMembresia'} catalogoLabel={'descMembresia'} emptyValue="Sin Membresía" />
                        }
                        {!membresiasCatalogo && 
                            <InputComp disabled={true} label="Membresía" name="idMembresia" type="select" form={form} setForm={setForm} emptyValue="Sin Membresía" />                    
                        }
                        {/* <button disabled={currentMembership !== null} onClick={selectMembresia}>Guardar</button> */}
                    </div>                
                }

                {currentMembership && 
                    <div className="membresia-card card">
                        <div className="icon">
                            <MembresiaIcon />
                        </div>
                        <div className="info">
                            {membresiasCatalogo && currentMembership && 
                                <h3>{findCatalogById(currentMembership.idMembresia, membresiasCatalogo, 'idMembresia', 'descMembresia')}</h3>                            
                            }
                            <p>Miembro desde: {currentMembership.fechaAdquisicion}</p>
                        </div>
                        <div className="icon delete" onClick={confirmDelete}>
                            <DeleteIcon />
                        </div>
                    </div>
                }
            </div>
            <div className="actions">
                <button onClick={prevStep}>Regresar</button>
                <button onClick={nextStep}>Guardar</button>
            </div>

            <ConfirmModal data={modalData} cancel={closeModal} />
        </div>
    )
}