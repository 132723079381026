/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import InputComp from "../generales/inputComponent";
import { pagoLineaForm } from "../../app/forms/cobros/pagoLinea";
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import moment from "moment";
import { formatCurrency, formatForm, validateForm } from "../../app/commons";
import { postNuevaOrden } from "../../services/cobro";
import { ALERT_FAIL, SECTIONS } from "../../app/constantes";
import ModalPagoLinea from "./modalPagoLinea";
import DatosFacturacion from "./datosFacturacion";
import { getFormasPagosDinamico } from "../../services/catalogos";
import { pagosCursosState, changeSectionCobroCurso } from "../../slices/pagosCursosSlice";

export default function PagoEnLinea({ idEntidad, convocatoria }) {
  const dispatch = useDispatch()
  const pagosSt = useSelector(pagosCursosState)
  const appSt = useSelector(appState)
  const catalogosSt = useSelector(catalogosServiceState)
  const [form, setForm] = useState(JSON.parse(JSON.stringify(pagoLineaForm)))
  const [formatedPagos, setFormatedPagos] = useState(null)
  const [checkoutId, setChekoutId] = useState(null)
  const [datosFacturacion, setDatosFacturacion] = useState(null)
  const [type, setType] = useState(null)
  const [showFacturas, setShowFacturas] = useState(false)
  const [catalogoFormaPago, setCatalogoFormaPago] = useState(null)

  const goBack = () => {
    setType(null)
    dispatch(changeSectionCobroCurso(SECTIONS.one))
  }

  const getName = (desc) => {
    let label = desc
    if (desc.includes("CUOTA")) {
      label = `${label}, REGALÍA POR LA LICENCIA DE USO NO EXCLUSIVO DE DISTINTIVO ESR®`
    }
    return label
  }

  const handleSubmit = async (data) => {
    const valid = validateForm(form)
    if (!formatedPagos) {
      dispatch(setAlert({
        show: true,
        message: "Sin cobros",
        type: ALERT_FAIL
      }))
      return
    }
    if (valid.length === 0) {
      dispatch(setLoading(true));
      try {
        const formated = formatForm(form)
        const detalles = []
        const descripciones = []
        formatedPagos.forEach(item => {
          detalles.push({
            nombre: getName(item.descCobroCurso),
            precioUnidad: item.montoCobro,
            cantidad: item.cantidad
          })
          if (!descripciones.includes(item.descCobroCurso)) {
            descripciones.push(item.descCobroCurso)
          }
        })
        const body = {
          nombre: formated.nombre,
          email: formated.email,
          telefono: formated.telefono,
          concepto: descripciones.join(", "),
          urlExito: `${window.location.href}`
        }
        const resp = await postNuevaOrden({ ...appSt, body: { ...body, detalles } })
        dispatch(setLoading(false));
        if (resp && resp.payload) {
          setChekoutId(resp.payload.checkout.id)
        }
        setDatosFacturacion({ 
          data: data.online.data, 
          array: data.online.registros, 
          formatedPagos: formatedPagos,
          confirmarDatos: formated
        })
      } catch (error) {
        console.log(error)
        dispatch(setLoading(false));
        dispatch(setAlert({
          show: true,
          message: error.payload ? error.payload : "Error en el servicio",
          type: ALERT_FAIL
        }))
      }
    } else {
      dispatch(setAlert({
        show: true,
        message: "Favor de llenar los campos obligatorios",
        type: ALERT_FAIL
      }))
    }
  }

  const formatPago = (item, array, programa) => {
    const clone = JSON.parse(JSON.stringify(item))
    array.push({
      ...clone, 
      montoCobro: item.monto_cobro,
      cantidad: item.cantidad,
      idCobroCurso: item.id_cobro_curso,
      descCobroCurso: item.desc_cobro_curso
    });
  }

  /**
     * vemos si el pago ya tiene un cobrante
     * puede q los comprobantes puedan venir de un servicio para evitar hacer esta logica 
     */
  const formatPagos = async () => {
    try {
      const array = [];
      let PUE = false;
      for (const item of pagosSt.cobrosAplicados) {
        if ((item.id_estatus_cobro === catalogosSt.idsEstatusCobro.registrado || item.id_estatus_cobro === catalogosSt.idsEstatusCobro.rechazado) && !item.is_revertir_pago) {
          formatPago(item, array)
        }      
      }
      if (array.find(item => !item.fac_anticipada)) {
        PUE = true
      }
      setShowFacturas(PUE);
      setFormatedPagos(array);      
    } catch (error) {
      console.log(error);
      dispatch(setAlert({
        show: true,
        message: "No existe monto de participación",
        type: ALERT_FAIL
      }))
      throw new Error(error)      
    }
  }

  const getCatalogoFormaPago = async (type) => {
    try {
      const resp = await getFormasPagosDinamico({
        ...appSt.serviceData,
        tipoConsulta: type
      })
      setCatalogoFormaPago(resp.payload)
    } catch (error) {
      console.log(error)
      dispatch(setAlert({
        show: true,
        message: "Error al consultar catalogo",
        type: ALERT_FAIL
      }))
    }
  }

  const populateForm = async () => {
    await getCatalogoFormaPago(1)
  }

  useEffect(() => {
    if (pagosSt.cobrosAplicados && pagosSt.pagoDetails && catalogosSt.idsTipoDocumentoCemefi) {
      formatPagos();
    }
    setType("enLinea")
  }, [pagosSt.cobrosAplicados, pagosSt.pagoDetails, catalogosSt.idsTipoDocumentoCemefi])

  useEffect(() => {
    if (!showFacturas) {
      populateForm()
      const clone = JSON.parse(JSON.stringify(form))
      clone.formaPago.required = true
      setForm(clone)
    } else {
      const clone = JSON.parse(JSON.stringify(form))
      clone.formaPago.required = false;
      setForm(clone)
    }
  }, [showFacturas])

  return (
    <div className="pago-linea">
      <h3>Pago en linea</h3>
      <div className="card">
        <h4>Confirmar datos</h4>
        <div className="form">
          <div className="four-col">
            <InputComp
              label="Nombre Completo"
              name="nombre"
              type="upper"
              form={form}
              setForm={setForm}
            />
            <InputComp
              label="Email"
              name="email"
              type="text"
              form={form}
              setForm={setForm}
            />
            <InputComp
              label="Teléfono a 10 digitos"
              name="telefono"
              type="text"
              max={10}
              form={form}
              setForm={setForm}
            />
            {catalogoFormaPago && !showFacturas &&
              <InputComp 
                label="Forma de Pago" 
                name="formaPago" 
                type="select" 
                form={form} 
                setForm={setForm} 
                catalogos={catalogoFormaPago} 
                catalogoValue={'cveFormaPago'} 
                catalogoLabel={'descFormaPago'} 
                secundaryLabel={'cveFormaPago'} 
              />
            }
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Descripción</th>
            <th>Cantidad</th>
            <th>Fecha</th>
            <th>Monto Unitario</th>
            <th>Monto Total</th>
          </tr>
        </thead>
        <tbody>
          {formatedPagos && formatedPagos.map((cobro, index) => {
            return cobro.idEstatusRegistro !== 0 && <tr key={index}>
              <td>{cobro.descCobroCurso}</td>
              <td>{cobro.cantidad}</td>
              <td>{moment(cobro.fecha).format('YYYY-MM-DD')}</td>
              <td>{formatCurrency(cobro.montoCobro)}</td>
              <td>{formatCurrency(cobro.montoCobro * cobro.cantidad)}</td>
            </tr>
          })}
        </tbody>
      </table>
      {showFacturas &&
        <div className="card">
          <h4>Datos de facturación</h4>
          <DatosFacturacion type={type} callback={handleSubmit} cancel={goBack} idEntidad={idEntidad} />
        </div>
      }
      {!showFacturas &&
        <DatosFacturacion type={type} callback={handleSubmit} cancel={goBack} hidden={true} idEntidad={idEntidad} />
      }

      {/* <div className="actions">
        <button onClick={goBack}>Cancelar</button>
        <button onClick={handleSubmit}>Pagar</button>
      </div> */}
      <ModalPagoLinea
        convocatoria={convocatoria}
        checkoutId={checkoutId}
        datosFacturacion={datosFacturacion}
        setCheckoutId={setChekoutId}
        showFacturas={showFacturas}
        idEntidad={idEntidad}
        pagos={formatedPagos} />
    </div>
  )
}