export const BuscarRfcForm = {
    rfc: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
}

export const FilterInvitadas = {
    cveEstatusInscripcion: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
}
