import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { changeDocumentosCompleted, changeStep, registroState, resetStatus, selectEmpresa, setSede } from "../slices/registroSlice";

import { INFORMACION_GENERAL_STEP, DATOS_CONTACTO_STEP, DATOS_INDUSTRIA_STEP, DOCUMENTOS_STEP, REDES_STEP, CVE_TIPODOC_ENTIDAD, MEMBRESIA_STEP } from "../app/constantes";

import { ReactComponent as EmpresaIcon } from '../assets/icons/empresa.svg';
import { ReactComponent as IndustriaIcon } from '../assets/icons/industria.svg';
import { ReactComponent as ContactoIcon } from '../assets/icons/contactos.svg';
import { ReactComponent as DocumentosIcon } from '../assets/icons/download.svg';
import { ReactComponent as RedesIcon } from '../assets/icons/network.svg';
import { ReactComponent as BackIcon } from '../assets/icons/back.svg';
import { ReactComponent as MembresiaIcon } from '../assets/icons/membresia.svg';

import InformacionGeneral from "../components/registro/informacion-general";
import DatosContacto from "../components/registro/datos-contacto";
import DatosIndustria from "../components/registro/datos-industria";
import Documentos from "../components/registro/documentos";

import '../styles/registro.scss';
import RedesSociales from "../components/registro/redes-sociales";
import { useNavigate } from "react-router-dom";
import { appState } from "../slices/appSlice";
import { entidadServicesState, obtenerMisEmpresas } from "../slices/entidadServicesSlice";
import Membresias from "../components/registro/membresia";
import { catalogosServiceState, getDocumentosCatalogo, resetRegistro } from "../slices/catalogosServiceSlice";
import { validateContactos, validateDocs, validateIndustria } from "../app/commons";
import { useState } from "react";


export default function Registro() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const registroSt = useSelector(registroState);
  const catalogosSt = useSelector(catalogosServiceState);
  const appSt = useSelector(appState);
  const entidadServicesSt = useSelector(entidadServicesState);

  const [progress, setProgress] = useState(10);
  const [validations, setValidations] = useState({
    informacionGeneralCompleted: false,
    datosContactoCompleted: false,
    datosIndustriaCompleted: false,
    documentosCompleted: false
  })
  /**
   * Cambiar de paso con la navegacion de arriba
   */
  const goToStep = (step) => {
    dispatch(changeStep(step));
  }

  /**
   * volver a la vista de mis entidades y se reseta la info
   */
  const goBack = () => {
    goToStep(INFORMACION_GENERAL_STEP);
    dispatch(selectEmpresa(null));
    dispatch(setSede(false));
    dispatch(resetRegistro());
    dispatch(resetStatus());
    const data = {
      ...appSt.serviceData,
      idUsuario: appSt.tokenInfo.sub
    }
    dispatch(obtenerMisEmpresas(data));
    if (entidadServicesSt.principalSede) {
      navigate('/sedes')
    } else {
      navigate('/mis-entidades')
    }
  }

  const calculateProgress = () => {
    let progress = 0;
    let total = 0;
    let valid = 0;
    let count = 0;
    if (registroSt.empresaSeleccionada) {
      const validations = {
        informacionGeneralCompleted: registroSt.empresaSeleccionada.cemDireccionEntidads.length >= 2,
        datosContactoCompleted: validateContactos(registroSt.empresaSeleccionada.cemContactoCrcEntidads, catalogosSt.idsTipoContacto),
        datosIndustriaCompleted: validateIndustria(registroSt.empresaSeleccionada),
        documentosCompleted: validateDocs(catalogosSt.tipoDocumentos, registroSt.empresaSeleccionada, catalogosSt.idsTipoDocumentoCemefi && catalogosSt.idsTipoDocumentoCemefi.membresia ? catalogosSt.idsTipoDocumentoCemefi.membresia : null)
      }

      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(validations)) {
        count = count + 1;
        if (value) {
          valid = valid + 1;
        }
      }

      total = count;

      progress = (100 / total) * valid;
      setValidations(validations);
    }
    setProgress(progress);
  }

  /**
   * obtenemos catalogos
   */
  useEffect(() => {
    if (appSt.serviceData) {
      const data = {
        ...appSt.serviceData,
        cveTipoDocumento: CVE_TIPODOC_ENTIDAD,
      };
      dispatch(getDocumentosCatalogo(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registroSt.empresaSeleccionada, appSt.serviceData])

  /**
   * Mandamos a llamar la validacion de documentos
   */
  useEffect(() => {
    if ((catalogosSt.tipoDocumentos !== null || catalogosSt.tipoDocumentos !== undefined) && registroSt.empresaSeleccionada) {
      const valid = validateDocs(catalogosSt.tipoDocumentos, registroSt.empresaSeleccionada, catalogosSt.idsTipoDocumentoCemefi && catalogosSt.idsTipoDocumentoCemefi.membresia ? catalogosSt.idsTipoDocumentoCemefi.membresia : null);
      dispatch(changeDocumentosCompleted(valid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogosSt.tipoDocumentos]);

  useEffect(() => {
    if (catalogosSt.tipoDocumentos && catalogosSt.idsTipoContacto) {
      calculateProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registroSt.etapa, registroSt.empresaSeleccionada, catalogosSt.tipoDocumentos, catalogosSt.idsTipoContacto])
  
  useEffect(() => {
    const item = document.getElementById('step-content');
    item.scrollTo(0,0);

  }, [registroSt.etapa])

  return (
    <main className="registro">
      <div className="registro-title">
        <button onClick={goBack}>
          <BackIcon />
        </button>
        {registroSt.empresaSeleccionada &&
          <h3 className="title">Entidad: {registroSt.empresaSeleccionada.razonSocial}</h3>
        }
        {!registroSt.empresaSeleccionada &&
          <h3>Nueva Entidad</h3>
        }
        <div className="progress">
          <p>Completado: {progress}%</p>
          <div className="progress-bar">
            <span className="fill" style={{ maxWidth: `${progress}%` }}></span>
          </div>
        </div>
      </div>
      <div className="steper">
        <div onClick={() => goToStep(INFORMACION_GENERAL_STEP)} className={registroSt.etapa === INFORMACION_GENERAL_STEP ? 'step active' : 'step'}>
          <div className={`icon ${validations.informacionGeneralCompleted ? 'completed' : 'incomplete'}`}>
            <EmpresaIcon />
          </div>
          <h2>Información General</h2>
        </div>

        <button disabled={(!registroSt.empresaSeleccionada && !registroSt.isSede) || (registroSt.isSede && registroSt.etapa === INFORMACION_GENERAL_STEP)} onClick={() => goToStep(DATOS_CONTACTO_STEP)} className={registroSt.etapa === DATOS_CONTACTO_STEP ? 'step active' : 'step'}>
          <div className={`icon ${validations.datosContactoCompleted ? 'completed' : 'incomplete'}`}>
            <ContactoIcon />
          </div>
          <h2>Datos de Contacto</h2>
        </button>

        <button disabled={(!registroSt.empresaSeleccionada && !registroSt.isSede) || (registroSt.isSede && registroSt.etapa === INFORMACION_GENERAL_STEP)} onClick={() => goToStep(DATOS_INDUSTRIA_STEP)} className={registroSt.etapa === DATOS_INDUSTRIA_STEP ? 'step active' : 'step'}>
          <div className={`icon ${validations.datosIndustriaCompleted ? 'completed' : 'incomplete'}`}>
            <IndustriaIcon />
          </div>
          <h2>Datos de Industria</h2>
        </button>

        <button disabled={(!registroSt.empresaSeleccionada && !registroSt.isSede) || (registroSt.isSede && registroSt.etapa === INFORMACION_GENERAL_STEP)} onClick={() => goToStep(REDES_STEP)} className={registroSt.etapa === REDES_STEP ? 'step active' : 'step'}>
          <div className="icon">
            <RedesIcon />
          </div>
          <h2>Redes Sociales</h2>
        </button>

        <button disabled={(!registroSt.empresaSeleccionada && !registroSt.isSede) || (registroSt.isSede && registroSt.etapa === INFORMACION_GENERAL_STEP)} onClick={() => goToStep(MEMBRESIA_STEP)} className={registroSt.etapa === MEMBRESIA_STEP ? 'step active' : 'step'}>
          <div className="icon">
            <MembresiaIcon />
          </div>
          <h2>Membresía</h2>
        </button>

        <button disabled={(!registroSt.empresaSeleccionada && !registroSt.isSede) || (registroSt.isSede && registroSt.etapa === INFORMACION_GENERAL_STEP)} onClick={() => goToStep(DOCUMENTOS_STEP)} className={registroSt.etapa === DOCUMENTOS_STEP ? 'step active' : 'step'}>
          <div className={`icon ${validations.documentosCompleted ? 'completed' : 'incomplete'}`}>
            <DocumentosIcon />
          </div>
          <h2>Documentos</h2>
        </button>
      </div>
      <div className="step-content" id="step-content">
        {registroSt.etapa === INFORMACION_GENERAL_STEP && <InformacionGeneral />}
        {registroSt.etapa === DATOS_CONTACTO_STEP && registroSt.empresaSeleccionada && <DatosContacto />}
        {registroSt.etapa === DATOS_INDUSTRIA_STEP && registroSt.empresaSeleccionada && <DatosIndustria />}
        {registroSt.etapa === REDES_STEP && registroSt.empresaSeleccionada && <RedesSociales />}
        {registroSt.etapa === MEMBRESIA_STEP && registroSt.empresaSeleccionada && <Membresias />}
        {registroSt.etapa === DOCUMENTOS_STEP && registroSt.empresaSeleccionada && <Documentos disabled={(!registroSt.empresaSeleccionada && registroSt.isSede) || (registroSt.isSede && registroSt.empresaSeleccionada && registroSt.empresaSeleccionada.cemRegistroConvocatorias.length === 0)} goBack={goBack} type={CVE_TIPODOC_ENTIDAD} empresaSeleccionada={registroSt.empresaSeleccionada} />}
      </div>
    </main>
  );
}