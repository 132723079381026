import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FIREBASE } from "../app/constantes";
import Module from "../components/convocotaria-preview/module"
import { firebaseDb } from "../firebaseConfig";
import { setLoading } from "../slices/appSlice";

export default function Publicacion() {
    const dispatch = useDispatch();
    const [convocatoriaData, setConvocatorias] = useState(null);
    const [modules, setModules] = useState(null);

    /**
   * obtenemos convocatorias
   */
    const getConvocatorias = async () => {
        dispatch(setLoading(true));
        const snap = await firebaseDb.collection(FIREBASE.convocatorias).get()
        if (!snap.empty) {
            const data = [];
            snap.forEach(item => {
                const conv = item.data();
                data.push({ ...conv, id: item.id });
            })
            // obtenemos todas las publicaciones de firebase, por el momento agarramos la primera para mostrar,
            // falta definicion en como se mostrarian las publicaciones
            setConvocatorias(data[0]);
            const sorted = data[0].modulos.sort((a, b) => a.orden - b.orden);
            console.log(sorted)
            setModules(sorted);            
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        getConvocatorias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="preview-wrapper">
            <div className="preview">
                {modules && modules && modules.map((module, index) => {
                    return <Module key={module.orden} data={module} />
                })}
            </div>
        </div>
    )
}