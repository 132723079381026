/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { catalogosServiceState, getDocumentosCatalogo } from "../../slices/catalogosServiceSlice";

import { formatCurrency, validateForm } from "../../app/commons";
import { ALERT_FAIL, ALERT_SUCCESS, DESC_ESQUEMA_PAGO, CVE_TIPODOC_COBRO, SECTIONS } from "../../app/constantes";

import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';

import { getMessageByModulo } from "../../services/catalogos";
import { findCatalogById } from "../../app/commons";
import { changeSectionCobroCurso, getCobrosCursos, getPagosCursosDetails, pagosCursosState, setModalFactura } from "../../slices/pagosCursosSlice";
import { catalogoFactura, pagosIncialForm } from "../../app/forms/cobros/pagoIncial";
import InputComp from "../generales/inputComponent";
import ModalDatosFactura from "./modalDatosFactura";
import { deleteCursoServicioExtemporaneo } from "../../services/cursos";

export default function PagosCursosInicial({ idEntidad }) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const catalogosSt = useSelector(catalogosServiceState);
    const pagosSt = useSelector(pagosCursosState);
    const [form, setForm] = useState(pagosIncialForm);
    const [registros, setRegistros] = useState(null);
    const [messages, setMessages] = useState(null);
    const [categorias, setCategorias] = useState(null);
    const [validations, setValidations] = useState(null);
    const [catalogoMetodoPago, setCatalogoMetodoPago] = useState(null);

    /**
     * Tips
     */
    const getMessages = async () => {
        if (!messages) {
            const resp = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'MISPAGOS', 'MENSAJEMISPAGOS ');
            if (resp.payload && resp.payload.valor) {
                setMessages({...messages, topMessage: resp.payload.valor})
            }
        }
    }

    const openModalFacturas = (type) => {
        dispatch(setModalFactura({
            show: true,
            data: {type}
        }))
    }
    
    const handleContinue = () => {
        try {
            const valid = validateForm(form);
            if (valid.length === 0) {
                if (form.facturaAnticipada.value === 'true') {
                    openModalFacturas('anticipada')
                }
                if (form.metodoPago.value === 'depositoBancario') {
                    dispatch(changeSectionCobroCurso(SECTIONS.two))
                }
                if (form.metodoPago.value === 'pagoLinea') {
                    dispatch(changeSectionCobroCurso(SECTIONS.four))
                }
            } else {
                dispatch(setAlert({
                    show: true,
                    message: 'Favor de llenar los campos requeridos',
                    type: ALERT_FAIL
                }))
            }
        } catch (error) {
            console.log(error);
        }
    }
    // console.log(pagosSt.esquema);

    /**
     * formateamos el form y seateamos las validaciones
     * @param {*} cat 
     */
    const doValidations = (cat) => {
        const validationsObj = {
            showFacturaAnticipada: false,
            showMetodoPago: false,
        }
        const clone = JSON.parse(JSON.stringify(form))
        const catalogo = []
        if (pagosSt.esquema.find(esq => esq.descEsquemaCobro === DESC_ESQUEMA_PAGO.manual)) {
            catalogo.push({
                label: 'Deposito bancario',
                value: 'depositoBancario'
            })
        }
        if (pagosSt.esquema.find(esq => esq.descEsquemaCobro === DESC_ESQUEMA_PAGO.enLinea)) {
            catalogo.push({
                label: 'Pago en linea',
                value: 'pagoLinea'
            })
        }
        setCatalogoMetodoPago(catalogo)
        const isPagoManual = pagosSt.esquema.find(item => item.descEsquemaCobro === DESC_ESQUEMA_PAGO.manual);
        // const isPagoManual = true;
        if (cat.pendientesSinFactura.length > 0 && isPagoManual) {
            validationsObj.showFacturaAnticipada = true
            clone.facturaAnticipada.required = true
        }
        if (cat.pendientes.length > 0 ) {
            validationsObj.showMetodoPago = true
            clone.metodoPago.required = true
        }
        setForm(clone)
        setValidations(validationsObj)
    }

   /**
    * Categorisamos los cobros
    * @param {*} cobros 
    */
    const checkValidations = (cobros) => {
        const pendientesSinFactura = []
        const pendientes = []
        const pagados = []
        const pagadosSinFactura = []
        
        cobros.forEach(cobro => {
            if (
                cobro.fac_anticipada !== 1 && 
                cobro.id_estatus_cobro_aplicado === catalogosSt.idsEstatusCobro.registrado
            ) {
                pendientesSinFactura.push(cobro)
            }
            if (cobro.id_estatus_cobro_aplicado === catalogosSt.idsEstatusCobro.registrado) {
                pendientes.push(cobro)
            }
            if (cobro.id_estatus_cobro_aplicado === catalogosSt.idsEstatusCobro.confirmado) {
                pagados.push(cobro)
            }
            if (
                cobro.fac_anticipada !== 1 &&
                cobro.id_estatus_cobro_aplicado === catalogosSt.idsEstatusCobro.confirmado
            ) {
                pagadosSinFactura.push(cobro)
            }
        })
        const body = {
            pendientesSinFactura,
            pendientes,
            pagados,
            pagadosSinFactura
        }
        setCategorias(body);
        doValidations(body)        
    }

    const deleteServicio = async (pago) => {
        console.log(pago);
        dispatch(setLoading(true));
        try {
            await deleteCursoServicioExtemporaneo({
                ...appSt.serviceData,
                idCursoEntidad: pago.id_curso_entidad,
                idCobroCursoAplicado: pago.id_cobro_curso_aplicado,
                idCobroCurso: pago.id_cobro_curso,
            })
            dispatch(setAlert({
                show: true,
                message: "Servicio eliminado",
                type: ALERT_SUCCESS
            }))
            dispatch(getPagosCursosDetails({
                ...appSt.serviceData,
                idEntidad,
                idModalidadPago: catalogosSt.idModoPagoTotal
            }))
            dispatch(getCobrosCursos({
                ...appSt.serviceData,
                idEntidad,
            }))       
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error)
            dispatch(setAlert({
                show: true,
                message: "Error al eliminar servicio",
                type: ALERT_SUCCESS
            }))
            dispatch(setLoading(false));
            
        }
    }

    useEffect(() => {
        dispatch(getDocumentosCatalogo({...appSt.serviceData, cveTipoDocumento: CVE_TIPODOC_COBRO}));
        getMessages();
    }, [idEntidad]);

    useEffect(() => {
        if (pagosSt.pagoDetails && pagosSt.pagoDetails.cobroCursosDet && catalogosSt.idsEstatusCobro) {
            const newDet = [];
            for (let r of pagosSt.pagoDetails.cobroCursosDet) {
                if (r.cantidad && r.cantidad > 0)
                    newDet.push(r);
            }
            setRegistros(newDet)
            checkValidations(newDet)
        }
    }, [pagosSt.pagoDetails, catalogosSt.idsEstatusCobro])

    useEffect(() => {
        const clone = JSON.parse(JSON.stringify(form));
        if (form.facturaAnticipada.value === 'true') {
            clone.metodoPago.required = false;
            clone.metodoPago.invalid = false;
        } else {
            clone.metodoPago.required = true;
            clone.metodoPago.invalid = false;
        }
        setForm(clone)

    }, [form.facturaAnticipada.value])
    
    return (
        <div className="pago-inicial">
            <h3>Historial de Pagos</h3>
            {messages && messages.topMessage && 
                <div className="tip">
                    <InfoIcon />
                    <p>Para efecto de realizar tu transferencia, deberás indicar la referencia y pagar el importe exacto que señala la Orden de Pago.</p>
                </div>            
            }
            <table>
                <thead>
                    <tr>
                        <th>Estatus</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th className="date">Fecha</th>
                        <th>Monto Unitario</th>
                        <th>Referencia Pasarela</th>
                        <th>Monto</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {catalogosSt.idsEstatusCobro && registros && registros.map((pago, index) => {
                        return <tr key={index}>
                            <td>{findCatalogById(pago.id_estatus_cobro_aplicado, catalogosSt.estatusCobro, 'idEstatusCobro', 'descEstatusCobro')}</td>
                            <td>{pago.desc_curso}</td>
                            <td>{pago.cantidad}</td>
                            <td>{moment(pago.created_at).format('YYYY-MM-DD')}</td>
                            <td>{formatCurrency(pago.monto_cobro)}</td>
                            <td>{pago.referencia_pasarela}</td>
                            <td>{formatCurrency(Number(pago.monto_cobro) * pago.cantidad)}</td>
                            <td>
                                {pago.id_estatus_cobro_aplicado === catalogosSt.idsEstatusCobro.registrado && !pago.fac_anticipada && 
                                    <button onClick={() => deleteServicio(pago)} className="small-icon-btn">
                                        <DeleteIcon />
                                    </button>
                                }
                            </td>
                        </tr>
                    })}
                    {pagosSt.pagoDetails && 
                        <tr>
                            <th className="center" colSpan={2}>Monto Total</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="warning">{formatCurrency(pagosSt.pagoDetails.sumaFinal)}</td>
                            <td></td>
                        </tr>                    
                    }
                </tbody>
            </table>    
            <div className="form">
                {categorias && 
                    <div className="two-col">
                        {/* Si hay pendientes sin factura anticipada */}
                        {validations && validations.showFacturaAnticipada &&
                            <InputComp 
                                label="Factura anticipada" 
                                name="facturaAnticipada" 
                                type="select" 
                                catalogos={catalogoFactura}
                                catalogoLabel={'label'}
                                catalogoValue={'value'}
                                form={form} 
                                setForm={setForm} 
                            />                    
                        }
                        {/* Si pendientes es mayor a 0 y el valor de factura anticipada no sea si o factura anticipada no se muestra */}
                        {validations && validations.showMetodoPago && (form.facturaAnticipada.value !== 'true' || !validations.showFacturaAnticipada) &&
                            <InputComp 
                                label="Metodo de pago" 
                                name="metodoPago" 
                                type="select" 
                                catalogos={catalogoMetodoPago}
                                catalogoLabel={'label'}
                                catalogoValue={'value'}
                                form={form} 
                                setForm={setForm} 
                            />                        
                        }
                    </div>                
                }
            </div>   
            <div className="actions">
                {categorias && categorias.pendientes.length > 0 &&
                    <button onClick={handleContinue}>Continuar</button>
                }
                {categorias && categorias.pagadosSinFactura.length > 0 &&
                    <button onClick={() => openModalFacturas('normal')}>Solicitar Factura</button>
                }
            </div> 

            <ModalDatosFactura idEntidad={idEntidad} isFacturaAnticipada={form.facturaAnticipada.value === "true" ? true : false} />
        </div>
    )
}