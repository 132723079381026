import { direccionForm } from "./direccion"

export const direccionEntregaForm = {
    personaDestinataria: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    ...direccionForm
}