import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getContactoEntidad, getEntidad, getMisEmpresas } from '../services/entidad';

const initialState = {
    loading: false,
    misEmpresas: null,
    contactosEntidad: null,
    principalSede: null
}

export const obtenerMisEmpresas = createAsyncThunk('misEmpresas/obtenerMisEmpresas', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getMisEmpresas(data);        
    } catch (error) {
        console.log(error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const obtenerContactosEntidad = createAsyncThunk('misEmpresas/obtenerContactosEntidad', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getContactoEntidad(data);        
    } catch (error) {
        console.log(error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getEntidadPrincipal = createAsyncThunk('misEmpresas/getEntidadPrincipal', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getEntidad(data);        
    } catch (error) {
        console.log(error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const entidadServicesSlice = createSlice({
    name: 'entidadServices',
    initialState,
    reducers: {
        resetEntidad: () => initialState,
        selectSedePrincipal: (state, action) => {
            state.principalSede = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEntidadPrincipal.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(obtenerMisEmpresas.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(obtenerContactosEntidad.pending, (state) => {
            state.loading = true;
        })
        
        builder.addCase(getEntidadPrincipal.rejected, (state) => {
            state.loading = false;
        })
        builder.addCase(obtenerMisEmpresas.rejected, (state) => {
            state.loading = false;
        })
        builder.addCase(obtenerContactosEntidad.rejected, (state) => {
            state.loading = false;
        })

        builder.addCase(getEntidadPrincipal.fulfilled, (state, action) => {
            state.loading = false;
            state.principalSede = action.payload.payload
        })
        builder.addCase(obtenerMisEmpresas.fulfilled, (state, action) => {
            state.loading = false;
            state.misEmpresas = action.payload.payload
        })
        builder.addCase(obtenerContactosEntidad.fulfilled, (state, action) => {
            state.loading = false;
            state.contactosEntidad = action.payload.payload
        })
    }
})

export const { resetEntidad, selectSedePrincipal } = entidadServicesSlice.actions;
export const entidadServicesState = (state) => state.entidadServices;

export default entidadServicesSlice.reducer;