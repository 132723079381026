import DOMPurify from "dompurify";
import parse from 'html-react-parser';
import { useEffect, useState } from "react";

import '../../styles/template-styles/content-image.scss';
import '../../styles/template-styles/templates.scss';

import convocatoria from '../../assets/images/background-image-100.jpg';

export default function ContentImagePreview({ data }) {
    /**
     * Set Configuration state
     */
    const [moduleStyles, setModuleStyles] = useState({
        backgroundColor: ''
    });
    const [contentStyles, setContentStyles] = useState({
        backgroundColor: '',
        color: ''
    });

  /**
     * Clean HTML string
     * @returns 
     */
    const cleanHTML = (htmlString) => {
    const clean = DOMPurify.sanitize(htmlString, {USE_PROFILES: {html: true}});
    return parse(clean);
  }

  /**
   * Check config values
   */
  useEffect(() => {
    if (data) {
        setModuleStyles({
            backgroundColor: data.backgroundColor,
        })
        setContentStyles({
            backgroundColor: data.contenido.backgroundColor,
            color: data.contenido.color
        })
    }
  }, [data])

  return (
      <div className={`${data.layout}-conv`} style={moduleStyles}>
          <div className={data.wrapContent ? 'main-wrap-conv wrapper-conv' : 'main-wrap-conv'}>
            <div className="content-conv" style={contentStyles}>
                {cleanHTML(data.contenido.htmlString)}
            </div>
            <div className="image-conv">
                {data.image.imageUrl && 
                    <img src={convocatoria} alt="contenido" />                
                }
            </div>
          </div>
      </div>
  );
}