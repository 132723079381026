import { getFileRequest, getRequest, postRequest, putRequest } from "./http-service";

const url = process.env.REACT_APP_EVALUACION_SERVICE;

export const setLiberacionInstrumento = async (data, body) => {
  const requestUrl = `${url}/area/${data.area}/instrumento-registro/${data.idInstrumentoRegistro}`;

  const resp = await postRequest(requestUrl, data.token, {payload: body});

  if (resp.payload.length === 0) {
      // eslint-disable-next-line no-throw-literal
      throw {
          message: 'Received an empty array',
          requestUrl,
          params: { data },
          payload: resp.payload,
      };
  }

  return resp.payload;
};
//https://evaluacionsvc-dev.cemefi.org/evaluacion/api/v1/area/:idAreaCemefi/estatus-evaluacion/:idInstrumentoRegistro 
export const getEstatusEvaluacion = async (data) => {
  const requestUrl = `${url}/area/${data.area}/estatus-evaluacion/${data.idInstrumentoRegistro}`;

  const resp = await getRequest(requestUrl, data.token);

  if (!resp.payload) {
      // eslint-disable-next-line no-throw-literal
      throw {
          message: 'Received an empty array',
          requestUrl,
          params: { data },
          payload: resp.payload,
      };
  }

  return resp.payload;
};

export const postEvaluacionInstrumentoRegistro = async (data) => {
  const requestUrl = `${url}/area/${data.area}/instrumento-registro/${data.idInstrumentoRegistro}`;

  const resp = await postRequest(requestUrl, data.token, {payload: []});

  if (!resp.payload) {
      // eslint-disable-next-line no-throw-literal
      throw {
          message: 'Received an empty array',
          requestUrl,
          params: { data },
          payload: resp.payload,
      };
  }

  return resp.payload;
};

export const getDetalleRetro = async (data) => {
  const requestUrl = `${url}/area/${data.area}/retroalimentacion-service/retroalimentacion-detalle/idRegistroConvocatoria/${data.idRegistroConvocatoria}`;

  const resp = await getRequest(requestUrl, data.token);

  if (!resp.payload) {
      // eslint-disable-next-line no-throw-literal
      throw {
          message: 'Received an empty array',
          requestUrl,
          params: { data },
          payload: resp.payload,
      };
  }

  return resp.payload;
};

//{{url}}/area/:idAreaCemefi/retroalimentacionInstrumento/:idRetroalimentacionInstrumento
export const putDocumentoRetro = async (data) => {
  const requestUrl = `${url}/area/${data.area}/retroalimentacionInstrumento/${data.idRetroalimentacionInstrumento}`;

  const resp = await putRequest(requestUrl, data.token, {payload: data.body});

  if (!resp.payload) {
      // eslint-disable-next-line no-throw-literal
      throw {
          message: 'Received an empty array',
          requestUrl,
          params: { data },
          payload: resp.payload,
      };
  }

  return resp.payload;
};

export const getReportRetro = async (data) => {
  const requestUrl = `${url}/area/${data.area}/reporte-retroalimentacion/entidad/${data.idEntidad}`;

    const resp = await getFileRequest(requestUrl, data.token);

    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp;
};