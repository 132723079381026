import { deleteRequest, getFileRequest, getRequest, postFileRequest, postRequest, postRequestPublic, putRequest } from "./http-service";

const url = process.env.REACT_APP_PUBLICACION_SERVICE;

//{{url}}/area/:idAreaCemefi/publicacion-service/entidades/filter/:idConvocatoria
export const postEntidadesAprobadas = async (data, noDestacadas) => {
    let requestUrl = `${url}/area/${data.area}/publicacion-service/entidades/filter/${data.idConvocatoria}`;
    if (noDestacadas) {
        requestUrl = `${requestUrl}?isNoDestacadas=1`
    }

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

//{{url}}/area/:idAreaCemefi/carta-service 
export const getCarta = async (data) => {
    const requestUrl = `${url}/area/${data.area}/carta-service/${data.idRegistroConvocatoria}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postCarta = async (data) => {
    const requestUrl = `${url}/area/${data.area}/carta-service`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putCarta = async (data) => {
    const requestUrl = `${url}/area/${data.area}/carta-service/${data.idRegistroConvocatoria}`;

    const resp = await putRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postDocumentoUsoLogo = async (data) => {
    const requestUrl = `${url}/area/${data.area}/documento-uso-logo`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getDocumentoUsoLogo = async (data) => {
    const requestUrl = `${url}/area/${data.area}/documento-uso-logo/${data.idDocumentoUsoLogo}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteDocumentoUsoLogo = async (data) => {
    const requestUrl = `${url}/area/${data.area}/documento-uso-logo/${data.idDocumentoUsoLogo}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putDocumentoUsoLogo = async (data) => {
    const requestUrl = `${url}/area/${data.area}/documento-uso-logo/${data.idDocumentoUsoLogo}`;

    const resp = await putRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteCarta = async (data) => {
    const requestUrl = `${url}/area/${data.area}/carta-service/${data.idRegistroConvocatoria}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getReporteResultados = async (data) => {
    const requestUrl = `${url}/area/${data.area}/publicacion-service/comparativo/${data.idInstrumentoRegistro}/${data.idConvocatoria}?idTipoConsulta=1`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getIndicadorReporte = async (data) => {
    const requestUrl = `${url}/area/${data.area}/publicacion-service/comparativo-indicador/${data.idInstrumentoRegistro}/${data.idConvocatoria}`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

//{{url}}/area/:idAreaCemefi/reporte-resultados/convocatoria/:idConvocatoria/instrumento-registro/:idInstrumentoRegistro/:idTipoConsulta
export const downloadResultReport = async (data) => {
    const requestUrl = `${url}/area/${data.area}/reporte-resultados/convocatoria/${data.idConvocatoria}/instrumento-registro/${data.idInstrumentoRegistro}/${data.idTipoConsulta}`;

    const resp = await postFileRequest(requestUrl, data.token, {payload: data.body}, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8');
    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp;
};

export const getEsPublicada = async (data) => {
    const requestUrl = `${url}/area/${data.area}/publicacion-service/es-publicada/idInstrumentoRegistro/${data.idInstrumentoRegistro}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getCartaDictamen = async (data) => {
    const requestUrl = `${url}/area/${data.area}/carta-obtencion-distintivo/convocatoria/${data.idConvocatoria}/entidad/${data.idEntidad}/contacto-entidad/${data.idContactoEntidad}`;

    const resp = await getFileRequest(requestUrl, data.token);
    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp;
};
//https://publicacionsvc-dev.cemefi.org/publicacion/api/v1/area/:idAreaCemefi/carta-no-obtencion/registro-convocatoria/:idRegistroConvocatoria
export const getCartaNoObtencion = async (data) => {
    const requestUrl = `${url}/area/${data.area}/carta-no-obtencion/registro-convocatoria/${data.idRegistroConvocatoria}`;

    const resp = await getFileRequest(requestUrl, data.token);
    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp;
};

export const getReglasUsoLogo = async (data) => {
    const requestUrl = `${url}/area/${data.area}/reglas-logo/entidad/${data.idEntidad}`;

    const resp = await getFileRequest(requestUrl, data.token);
    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp;
};

//https://publicacionsvc-dev.cemefi.org/publicacion/api/v1/area/:idAreaCemefi/publicacion-service/entidades/filter-publica/:idConvocatoria?isNoDestacadas=1 
export const postEntidadesAprobadasPubli = async (data, noDestacadas) => {
    let requestUrl = `${url}/area/${data.area}/publicacion-service/entidades/filter-publica/${data.idConvocatoria}`;
    if (noDestacadas) {
        requestUrl = `${requestUrl}?isNoDestacadas=1`
    }

    const resp = await postRequestPublic(requestUrl, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postReporteResultadosInvitaciones = async (data) => {
    let requestUrl = `${url}/area/${data.area}/publicacion-service/comparativo-multiple?idTipoConsulta=1`;
    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postReporteIndicadoresInvitaciones = async (data) => {
    let requestUrl = `${url}/area/${data.area}/publicacion-service/comparativo-indicador-multiple`;
    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const downloadResultReportInvitados = async (data) => {
    const requestUrl = `${url}/area/${data.area}/reporte-resultados-multiples/${data.idTipoConsulta}`;

    const resp = await postFileRequest(requestUrl, data.token, {payload: data.body}, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8');
    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp;
};