import { useState } from "react";
import { ReactComponent as NetworkIcon } from '../../assets/icons/network.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { catalogosServiceState, getRedesCatalogo } from "../../slices/catalogosServiceSlice";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { redesForm } from "../../app/forms/registro/redes";
import InputComp from "../generales/inputComponent";
import { findCatalogById, formatForm, validateForm } from "../../app/commons";
import { changeStep, registroState, updateEmpresaSeleccionada } from "../../slices/registroSlice";
import { deleteRedesEntidad, postRedesEntidad } from "../../services/entidad";
import { ALERT_FAIL, ALERT_SUCCESS, DATOS_INDUSTRIA_STEP, MEMBRESIA_STEP } from "../../app/constantes";
import ConfirmModal from "../generales/confirmModal";

export default function RedesSociales() {
    const dispatch = useDispatch();
    const catalogosSt = useSelector(catalogosServiceState);
    const appSt = useSelector(appState);
    const registroSt = useSelector(registroState);

    const [form, setForm] = useState(JSON.parse(JSON.stringify(redesForm)));
    // Local States
    const [modalData, setModalData] = useState({
        show: false,
        title: '',
        desc: '',
        info: null,
        list: null
    });

    // Cierra Modal de confirmacion
    const closeModal = () => {
        setModalData({
            ...modalData,
            show: false
        })
    }

    /**
     * Siguiente paso
     */
    const nextStep = () => {
        dispatch(changeStep(MEMBRESIA_STEP));

    }

    /**
     * Paso anterior
     */
    const prevStep = () => {
        dispatch(changeStep(DATOS_INDUSTRIA_STEP));
    }

    /**
     * Agregar un red social
     */
    const addRed = async () => {
        const valid = validateForm(form);

        if (valid.length === 0) {
            dispatch(setLoading(true));
            const formatedForm = formatForm(form);
            const body = {
                ...formatedForm,
                idEntidad: registroSt.empresaSeleccionada.idEntidad 
            }
            const resp = await postRedesEntidad(appSt.serviceData, body);
            if (resp.payload) {
                dispatch(setLoading(false));
                dispatch(setAlert({
                    show: true,
                    message: 'Red Guardada',
                    type: ALERT_SUCCESS
                }));
                const updateData = {
                    ...appSt.serviceData,
                    idEntidad: registroSt.empresaSeleccionada.idEntidad
                }
                dispatch(updateEmpresaSeleccionada(updateData));
                setForm(JSON.parse(JSON.stringify(redesForm)))
            } else {
                dispatch(setLoading(false));
                dispatch(setAlert({
                    show: true,
                    message: 'Error al guardar red',
                    type: ALERT_FAIL
                }))
            }
        } else {
            dispatch(setAlert({
                show: true,
                message: 'Favor de llenar los campos obligatorios',
                type: ALERT_FAIL
            }))
        }
    }

    // Confirmar Eliminacion
    const confirmDelete = (id) => {
        setModalData({
            show: true,
            title: 'Eliminar Red',
            desc: '¿Seguro deseas eliminar esta Red?',
            info: id,
            action: () => deleteRed(id)
        })
    }

    /**
     * Eliminar red social
     */
    const deleteRed = async (id) => {
        dispatch(setLoading(true));

        try {
            await deleteRedesEntidad({
                ...appSt.serviceData,
                idRedContactoEntidad: id
            })
            dispatch(setAlert({
                show: true,
                message: 'Red eliminada',
                type: ALERT_SUCCESS
            }))
            dispatch(setLoading(false));
            const updateData = {
                ...appSt.serviceData,
                idEntidad: registroSt.empresaSeleccionada.idEntidad
            }
            dispatch(updateEmpresaSeleccionada(updateData));
            closeModal();
        } catch (error) {
            dispatch(setAlert({
                show: true,
                message: 'Error al eliminar la red',
                type: ALERT_FAIL
            }))
            dispatch(setLoading(false));
        }
    }

    /**
     * obtenemos catalogos
     */
    useEffect(() => {
        if (catalogosSt.redes === null && !catalogosSt.loading) {
            const data = appSt.serviceData
            dispatch(getRedesCatalogo(data));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogosSt.redes]);

    return (
        <div className="registro-step redes">
            <div className="content">
                <div className="add-network">
                    <div className="inputs-wrapper">
                        {catalogosSt.redes && 
                            <InputComp label="Red Social" name="idRedContacto" type="select" form={form} setForm={setForm} catalogos={catalogosSt.redes} catalogoValue={'idRedContacto'} catalogoLabel={'descRedContacto'}/>                                                
                        }
                        <InputComp label="URL" name="url" type="text" form={form} setForm={setForm}/>
                        <button onClick={addRed}>Guardar</button>
                    </div>

                </div>

                <div className="card-registro">
                    <div className="head">
                        <NetworkIcon />
                        <h3>Redes Sociales Agregadas</h3>
                    </div>
                    {registroSt.empresaSeleccionada && registroSt.empresaSeleccionada.cemRedContactoCrcEntidads && catalogosSt.redes &&
                        <ul>
                            {registroSt.empresaSeleccionada.cemRedContactoCrcEntidads.map((item, index) => {
                                return item.idEstatusRegistro !== 0 && <li key={index}>
                                    <p>{`${findCatalogById(item.idRedContacto, catalogosSt.redes, 'idRedContacto', 'descRedContacto')}`} - {item.url}</p>
                                    <button onClick={() => confirmDelete(item.idRedContactoEntidad)}>
                                        <DeleteIcon />
                                    </button>
                                </li>
                            })}
                        </ul>
                    }
                </div>
            </div>


            <div className="actions">
                <button onClick={prevStep}>Regresar</button>
                <button onClick={nextStep}>Guardar</button>
            </div>

            <ConfirmModal data={modalData} cancel={closeModal} />
        </div>
    )
}