import { createSlice } from '@reduxjs/toolkit';
import { LOGO_EMPRESA } from '../app/constantes';

const initialState = {
    loading: false,
    alert: {
        show: false,
        message: '',
        type: null,
    },
    tokenInfo: null,
    token: null,
    serviceData: null,
    logo: LOGO_EMPRESA.cemefi,
    areaData: null,
    areasDisponibles: null
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setTokenInfo: (state, action) => {
            state.tokenInfo = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setAlert: (state, action) => {
            state.alert = action.payload
        },
        changeLogo: (state, action) => {
            state.logo = action.payload
        },
        setServiceData: (state, action) => {
            state.serviceData = action.payload
        },
        setAreaData: (state, action) => {
            state.areaData = action.payload
        },
        setAreasDisponibles: (state, action) => {
            state.areasDisponibles = action.payload
        }
    }
})

export const { setLoading, setTokenInfo, setToken, setAlert, setServiceData, changeLogo, setAreaData, setAreasDisponibles } = appSlice.actions;
export const appState = (state) => state.app;

export default appSlice.reducer;