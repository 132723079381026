/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { getCobrosAplicados, getPagosDetails, pagosState, setModalRevertir } from "../../slices/pagosSlice";
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import { getDocumentosCobros, putCobroAplicado } from "../../services/cobro";
import { ALERT_FAIL, ALERT_SUCCESS, CVE_ESTATUS_COBRO, CVE_TIPODOC_REVERTIRPAGO, ERROR_MESSAGE, NOTIFICACIONES, SUCESS_MESSAGE } from "../../app/constantes";
import { postNotificacion } from "../../services/notificaciones";
import { usuariosServicesState } from "../../slices/usuariosServicesSlice";
import { catalogosServiceState, getDocumentosRevertirCatalogo } from "../../slices/catalogosServiceSlice";
import DocumentoRevertir from "../generales/documento-revertir";
import { revertirPagoForm } from "../../app/forms/cobros/revertirPago";
import InputComp from "../generales/inputComponent";
import { validateForm } from "../../app/commons";

export default function ModalRevertirPago({convocatoria}) {
    const dispatch = useDispatch();
    const catalogosSt = useSelector(catalogosServiceState);
    const usuarioSt = useSelector(usuariosServicesState);
    const pagosSt = useSelector(pagosState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const appSt = useSelector(appState);
    const [documentos, setDocuments] = useState(null);
    const [form, setForm] = useState(JSON.parse(JSON.stringify(revertirPagoForm)));

    const closeModal = () => {
        dispatch(getPagosDetails({
            ...appSt.serviceData,
            idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
            idModalidadPago: catalogosSt.idModoPagoTotal
        }))
        dispatch(getCobrosAplicados({
            ...appSt.serviceData,
            idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
        }))
        dispatch(setModalRevertir({
            show: false,
            data: null
        }))
    }

    const handleSubmit = async () => {
        let valid = true;
        documentos.forEach(item => {
            if (!item.idContent) {
                valid = false;
            }
        })
        const formValid = validateForm(form)
        if (valid && formValid.length === 0) {
            dispatch(setLoading(true));
            const body = [];
            const cuota = pagosSt.modalRevertir.data.descConceptoCobroConvocatoria.includes('CUOTA');
            body.push({
                idCobroAplicado: pagosSt.modalRevertir.data.idCobroAplicado,
                idRegistroConvocatoria: pagosSt.modalRevertir.data.idRegistroConvocatoria,
                idConceptoCobroConvocatoria: pagosSt.modalRevertir.data.idConceptoCobroConvocatoria,
                cveEstatusCobro: CVE_ESTATUS_COBRO.revertir,
                motivoRevertido: form.motivoRevertido.value,
                monto: pagosSt.modalRevertir.data.monto
            })
    
            try {
                await putCobroAplicado(appSt.serviceData, body);
                dispatch(setLoading(false));
                closeModal()
                dispatch(setAlert({
                    show: true,
                    message: SUCESS_MESSAGE,
                    type: ALERT_SUCCESS
                }));
                if (cuota) {
                    postNotificacion(appSt.serviceData, {
                        to: [
                            { email: usuarioSt.usuario.data.email, name: usuarioSt.usuario.data.nombre }
                        ],
                        templateData: {
                            convocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria
                        },
                        templateId: NOTIFICACIONES.templatesIds.revisionPago
                    })
                }
            } catch (error) {
                console.log(error);
                dispatch(setLoading(false));
                dispatch(setAlert({
                    show: true,
                    message: ERROR_MESSAGE,
                    type: ALERT_FAIL
                }));
            }
        } else {
            dispatch(setAlert({
                show: true,
                message: 'Favor de subir todos los documentos y llenar el motivo de revertir',
                type: ALERT_FAIL
            }))
        }
    }

    const getDocsRevertir = async () => {
        dispatch(setLoading(true));
        try {
            const resp = await getDocumentosCobros({
                ...appSt.serviceData,
                idCobroAplicado: pagosSt.modalRevertir.data.idCobroAplicado,
                cveTipoDocumento: 'DOCUMENTOREVERTIRPAGO'
            });
            const array = []
            if (resp.length > 0) {
                catalogosSt.documentosRevertir.forEach(catDoc => {
                    const doc = { ...catDoc }
                    const data = resp.find(item => item.idDocumentoCemefi === catDoc.idDocumentoCemefi);
                    if (data) {
                        doc.idContent = data.idContent;
                        doc.idDocumentoCobroRegistro = data.idDocumentoCobroRegistro;
                    }
                    array.push(doc)
                });
                setDocuments(array)
            } else {
                setDocuments(catalogosSt.documentosRevertir)
            }
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: 'Error al traer las facturas',
                type: ALERT_FAIL
            }))
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        // Aqui se supone q todos los seleccionados comparten la misma info, 
        // Entonces para traer la info tomare el primer registro para popular la info 
        if (pagosSt.modalRevertir.data) {
            console.log(pagosSt.modalRevertir.data)
            getDocsRevertir();
        }
        console.log(pagosSt)
    }, [pagosSt.modalRevertir.data])

    useEffect(() => {
        if (!catalogosSt.documentosRevertir) {
            dispatch(getDocumentosRevertirCatalogo({ ...appSt.serviceData, cveTipoDocumento: CVE_TIPODOC_REVERTIRPAGO }))
        }
    }, [])

    return (
        <div className={pagosSt.modalRevertir.show ? 'modal-wrapper active' : 'modal-wrapper'}>
            <div className="modal factura">
                <div className="head">
                    <h3>Revertir Pago</h3>
                    <button onClick={closeModal}>
                        x
                    </button>
                </div>
                <div className="content">
                    <p>Favor de subir los siguientes documentos</p>
                    <div className="three-col">
                        {documentos && documentos.map((doc, index) => {
                            return <DocumentoRevertir
                                key={index}
                                entidadPrincipal={registroConvocatoriaSt.empresaSeleccionada}
                                doc={doc}
                                cobro={pagosSt.modalRevertir.data}
                                callback={getDocsRevertir}
                            />
                        })}
                    </div>
                </div>
                <div className="content">
                    <InputComp form={form} setForm={setForm} label={"Motivo Revertir"} type={"text"} name={"motivoRevertido"}/>
                </div>
                <div className="actions">
                    <button onClick={closeModal}>Cancelar</button>
                    <button onClick={handleSubmit}>Continuar</button>
                </div>
            </div>
        </div>
    )
}