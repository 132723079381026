import { deleteRequest, getFileRequest, getRequest, postRequest, putRequest } from "./http-service";

const url = process.env.REACT_APP_COBROS_SERVICE;


export const getCobros = async (data) => {
    const requestUrl = `${url}/area/${data.area}/calculoCobro/resumenpagos/cuotaParticipacion/${data.idRegistroConvocatoria}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

// Cobros aplicados

export const getListCobroAplicado = async (data) => {
    const requestUrl = `${url}/area/${data.area}/cobro-aplicado/${data.idRegistroConvocatoria}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postCobroAplicado = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/cobro-aplicado`;

    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putCobroAplicado = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/cobro-aplicado`;

    const resp = await putRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteCobroAplicado = async (data) => {
    const requestUrl = `${url}/area/${data.area}/cobro-aplicado/${data.idCobroAplicado}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

// Cobros aplicados documentos

export const getCobroAplicadoDocumento = async (data) => {
    const requestUrl = `${url}/area/${data.area}/documento-cobro-registro/${data.idDocumentoCobroRegistro}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postCobroAplicadoDocumento = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/documento-cobro-registro`;

    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putCobroAplicadoDocumento = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/documento-cobro-registro`;

    const resp = await putRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteCobroAplicadoDocumento = async (data) => {
    const requestUrl = `${url}/area/${data.area}/documento-cobro-registro/${data.idDocumentoCobroRegistro}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getCobroConvocatoria = async (data) => {
    const requestUrl = `${url}/area/${data.area}/calculoCobro/cuota-participacion/modalidad-pago/${data.idModalidadPago}/modo-participacion/${data.idModoParticipacion}/convocatoria/${data.idConvocatoria}/entidad/${data.idEntidad}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const triggerCalculo = async (data) => {
    let requestUrl = `${url}/area/${data.area}/calculoCobro/cuotaParticipacion/${data.idRegistroConvocatoria}/${data.idModalidadPago}`;

    if (data.invitada) {
        requestUrl = `${requestUrl}?flagDescuento=1`
    }

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const removeDiscount = async (data) => {
    let requestUrl = `${url}/area/${data.area}/calculoCobro/cuotaParticipacion/sindescuento/${data.idRegistroConvocatoria}/${data.idModalidadPago}`;
    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getDocumentosCobros = async (data) => {
    const requestUrl = `${url}/area/${data.area}/concepto-cobro/evidencia/${data.idCobroAplicado}/documento-cemefi/${data.cveTipoDocumento}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getCobroReport = async (data) => {
    const requestUrl = `${url}/area/${data.area}/reporte-cobro/orden-pago/${data.idRegistroConvocatoria}`;

    const resp = await getFileRequest(requestUrl, data.token, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp;
};

export const getCobroFacturas = async (data) => {
    const requestUrl = `${url}/area/${data.area}/cobro-aplicado/${data.idRegistroConvocatoria}/documento-factura`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp;
};

export const getCobroComprobantes = async (data) => {
    const requestUrl = `${url}/area/${data.area}/cobro-aplicado/${data.idRegistroConvocatoria}/documento-comprobante-pago`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

//https://cobrosvc-dev.cemefi.org/cobro/api/v1/facturacion/emitirFactura

export const postEmitirFactura = async (data) => {
    const requestUrl = `${url}/facturacion/emitirFactura`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});
    if (resp.error && resp.error.length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.error[0] && resp.error[0].descripcionError && resp.error[0].descripcionError.message ? resp.error[0].descripcionError.message : "Error en el servicio",
        };
    }

    return resp;
};

//https://cobrosvc-dev.cemefi.org/cobro/api/v1/area/:idAreaCemefi/cobro-aplicado/factura-anticipada
export const putFacturaAnticipada = async (data) => {
    const requestUrl = `${url}/area/${data.area}/cobro-aplicado/factura-anticipada`;

    const resp = await putRequest(requestUrl, data.token, {payload: data.body});
    if (resp.error && resp.error.length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.error[0].descripcionError,
        };
    }

    return resp;
};

//urlCemefi/cobro/api/v1/pago/nuevaOrden
export const postNuevaOrden = async (data) => {
    const requestUrl = `${url}/pago/nuevaOrden`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});
    if (resp.error && resp.error.length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.error[0].descripcionError,
        };
    }

    return resp;
};

export const deleteServicioExtemporaneo = async (data) => {
    const requestUrl = `${url}/area/${data.area}/servicio-extemporaneo/cobro-aplicado/${data.idCobroAplicado}/cobro-registro-convocatoria/${data.idCobroRegistroConvocatoria}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

//https://cobrosvc-dev.cemefi.org/cobro/api/v1/area/:idAreaCemefi/cobro-aplicado/asignar-cuentas/registro-convocatoria/:idRegistroConvocatoria
export const putAsignarCuentas = async (data) => {
    const requestUrl = `${url}/area/${data.area}/cobro-aplicado/asignar-cuentas/registro-convocatoria/${data.idRegistroConvocatoria}`;

    const resp = await putRequest(requestUrl, data.token, {payload: data.body});
    if (resp.error && resp.error.length > 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.error[0].descripcionError,
        };
    }

    return resp;
};

export const postComplementoPago = async (data) => {
    const requestUrl = `${url}/facturacion/emitirComplementoPago`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (resp.error) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.error[0].descripcionError
        };
    }

    return resp.payload;
};
