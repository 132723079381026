import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { ALERT_FAIL } from "../../app/constantes";
import { getCobrosCursos, getPagosCursosDetails, pagosCursosState, setModalFactura } from "../../slices/pagosCursosSlice";
import { postEmitirFactura, putFacturaAnticipada } from "../../services/cobro";
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { putCobroFactura } from "../../services/convocatorias";
import DatosFacturacion from "./datosFacturacion";

export default function ModalDatosFactura({ idEntidad, isFacturaAnticipada }) {
    const dispatch = useDispatch();
    const catalogosSt = useSelector(catalogosServiceState);
    const pagosSt = useSelector(pagosCursosState);
    const appSt = useSelector(appState);
    const [type, setType] = useState(null);

    /**
     * Cerramos modal
     */
    const closeModal = () => {
        dispatch(setModalFactura({
            show: false,
            data: null
        }))
    }

    /**
     * Descargamos la factura
     */
    const donwloadDoc = (file, type) => {
        const link = document.createElement('a');
        link.href = `data:application/${type};base64,`  + file;
        link.download = 'Factura' + type;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }

    /**
     * Guardamos la factura en BD
     */
    const saveFactura = async (array, data, resp) => {
        const cobros = []
        const promises = []
        array.forEach(item => {
            cobros.push({
                idCobroAplicado: item.cobroAplicado.idCobroAplicado,
                folio: resp.payload.folio,
                serie: resp.payload.serie,
                uuid: resp.payload.uuid,
            })
            promises.push(putCobroFactura({
                ...appSt.serviceData,
                idCobroRegistroConvocatoria: item.idCobroRegistroConvocatoria,
                body: {
                    cveUsoCfdi: data.receptor.usoCfdi,
                    cveFormaPago: data.formaPago,
                    cveMetodoPago: data.metodoPago,
                    cveRegimenFiscal: data.receptor.regimenFiscal,
                    idCobroRegistroConvocatoria: item.idCobroRegistroConvocatoria
                }
            }))
        })
        await Promise.allSettled(promises)
        await putFacturaAnticipada({...appSt.serviceData, body: cobros})
    }
    
    const handleFactura = async (type) => {
        try {
            const resp = await postEmitirFactura({
                ...appSt.serviceData,
                body: {
                    ...type.data,
                    isFacturaCurso: true,
                    isFacturaAnticipada,
                }
            });
            if (resp) {
                donwloadDoc(resp.payload.archivoPDF, 'pdf',)
                donwloadDoc(resp.payload.archivoXML, 'xml')
                console.log(resp)
                // await saveFactura(type.registros, type.data, resp)                
            }
        } catch (error) {
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: error?.payload?.message || "Ha ocurrido un error al emitir la factura",
                type: ALERT_FAIL
            }))   
            throw new Error("Error");
        }
    }


    /**
     * Solicitamos factura
     */
    const handleSubmit = async (data) => {
        if (data) {
            try {
                console.log(data);
                dispatch(setLoading(true));
                if (data.online.data) {
                    await handleFactura(data.online)
                }

                if (data.offline.data) {
                    await handleFactura(data.offline)
                }
                try {
                    dispatch(getPagosCursosDetails({
                        ...appSt.serviceData,
                        idEntidad,
                        idModalidadPago: catalogosSt.idModoPagoTotal
                    }))
                    dispatch(getCobrosCursos({
                        ...appSt.serviceData,
                        idEntidad
                    }))
                    dispatch(setLoading(false));            
                    closeModal()
                } catch (error) {
                    dispatch(setAlert({
                        show: true,
                        message: error.payload,
                        type: ALERT_FAIL
                    }))   
                }
                
            } catch (error) {
                dispatch(setLoading(false));
                // dispatch(setAlert({
                //     show: true,
                //     message: error.payload,
                //     type: ALERT_FAIL
                // }))   
            }            
        } 
    }

    const checkType = async () => {
        if (pagosSt.modalFactura.show && pagosSt.modalFactura.data) {
            setType(pagosSt.modalFactura.data.type)
        }
    }

    useEffect(() => {
        if (!pagosSt.modalFactura.show) {
            setType(null)
        } else {
            checkType();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagosSt.modalFactura.data])    

    return (
        <div className={pagosSt.modalFactura.show ? 'modal-wrapper active' : 'modal-wrapper'}>
            <div className="modal factura">
                <div className="head">
                    <h3>Datos de Facturación</h3>
                    <button onClick={closeModal}>
                        x
                    </button>
                </div>
                <div className="tip">
                    <InfoIcon />
                    <p>Una vez validados los datos fiscales, la factura será emitida y no habrá cancelaciones. <br /> Al modificar la dirección fiscal los datos se guardan en automático al seleccionar el botón continuar.</p>
                </div>
                <DatosFacturacion type={type} callback={handleSubmit} cancel={closeModal} idEntidad={idEntidad} />
            </div>
        </div>
    )
}