/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { ALERT_FAIL, CONCEPTO_CUOTA, NOTIFICACIONES } from "../../app/constantes";
// import { putCobroFactura } from "../../services/convocatorias";
import { postEmitirFactura } from "../../services/cobro";
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import { postNotificacion } from "../../services/notificaciones";
import { getEntidad } from "../../services/entidad";
import { getToNotification } from "../../app/commons";
import { getCobrosCursos, getPagosCursosDetails, setModalFactura } from "../../slices/pagosCursosSlice";
import { postComplementoPagoCurso, putCursoReferenciaPasarela } from "../../services/cursos";

export default function ModalPagoLinea({ idEntidad, checkoutId, setCheckoutId, datosFacturacion, showFacturas }) {
  const dispatch = useDispatch();
  const catalogosSt = useSelector(catalogosServiceState);
  const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
  const appSt = useSelector(appState);
  const [isSuccess, setIsSuccess] = useState(null)

  const closeModal = () => {
    dispatch(getPagosCursosDetails({
      ...appSt.serviceData,
      idEntidad,
      idModalidadPago: catalogosSt.idModoPagoTotal
    }))
    dispatch(getCobrosCursos({
      ...appSt.serviceData,
      idEntidad,
    }))
    dispatch(setModalFactura({
      show: false,
      data: null
    }))
    setIsSuccess(null)
    setCheckoutId(null)
  }

  const successPayment = async (e) => {
    setIsSuccess(e.id)
    try {
      dispatch(setLoading(true))
      for (const item of datosFacturacion.formatedPagos) {
        await putCursoReferenciaPasarela({
          ...appSt.serviceData,
          idCobroCurso: item.idCobroCurso,
          body: [{
            idCursoEntidad: item.id_curso_entidad,
            idCobroCurso: item.id_cobro_curso,
            idCobroCursoAplicado: item.id_cobro_curso_aplicado,
            numeroCompra: item.numero_compra,
            referenciaPasarela: e.id,
            uuid: item.uuid,
            idFormaPago: datosFacturacion.data && datosFacturacion.data.formaPago ? datosFacturacion.data.formaPago : datosFacturacion.confirmarDatos.formaPago
          }]
        })        
      }
      dispatch(setLoading(false))
      const PPD = datosFacturacion.formatedPagos.find(item => item.fac_anticipada)
      if (PPD) {
        await handleComplemento()
      }
      if (showFacturas) {
        await handleFactura()
      }
      await handleNotificacion()
    } catch (error) {
      console.log(error)
      dispatch(setAlert({
        show: true,
        message: "Error al guardar referencias/factura",
        type: ALERT_FAIL
      }))
    }
  }

  const handleNotificacion = async () => {
    try {
      const entidadData = await getEntidad({ ...appSt.serviceData, idEntidad: idEntidad || registroConvocatoriaSt?.empresaSeleccionada?.idEntidad })
      if (entidadData && datosFacturacion?.formatedPagos) {
        postNotificacion(appSt.serviceData, {
          to: getToNotification(entidadData, catalogosSt.idsTipoContacto),
          templateData: {
              convocatoria: datosFacturacion?.formatedPagos ? datosFacturacion?.formatedPagos[0] ? datosFacturacion?.formatedPagos[0].descCobroCurso : "" : ""
          },
          templateId: NOTIFICACIONES.templatesIds.pasarelaExitoso
        })      
      } else {
        throw new Error('Sin contacto principal')
      }
    } catch (error) {
      console.log(error)
      dispatch(setAlert({
        show: true,
        message: "Error al enviar notificación",
        type: ALERT_FAIL
      }))
    }
  }

  const errorPayment = (e) => {
    setIsSuccess(false)
    closeModal()
    dispatch(setAlert({
      show: true,
      message: e,
      type: ALERT_FAIL
    }))
  }

  /**
   * Descargamos la factura
   */
  const donwloadDoc = (file, type) => {
    const link = document.createElement('a');
    link.href = `data:application/${type};base64,` + file;
    link.download = 'Factura' + type;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  }

  /**
     * Guardamos la factura en BD 
     * Ya no se usa puesto que lo guardamos desde que se emite
     */
  // const saveFactura = async (array, data, resp) => {
  //   const cobros = []
  //   const promises = []
  //   array.forEach(item => {
  //     cobros.push({
  //       idCobroAplicado: item.cobroAplicado.idCobroAplicado,
  //       folio: resp.payload.folio,
  //       serie: resp.payload.serie,
  //       uuid: resp.payload.uuid,
  //     })
  //     promises.push(putCobroFactura({
  //       ...appSt.serviceData,
  //       idCobroCurso: item.idCobroCurso,
  //       body: {
  //         cveUsoCfdi: data.receptor.usoCfdi,
  //         cveFormaPago: data.formaPago,
  //         cveMetodoPago: data.metodoPago,
  //         cveRegimenFiscal: data.receptor.regimenFiscal,
  //         folioOdc: data.folioOdc,
  //         idCobroCurso: item.idCobroCurso
  //       }
  //     }))
  //   })
  //   await Promise.allSettled(promises)
  //   await putFacturaAnticipada({ ...appSt.serviceData, body: cobros })
  // }

  /**
   * Solicitamos factura
   */
  const handleFactura = async () => {
    if (datosFacturacion) {
      try {
        dispatch(setLoading(true));
        datosFacturacion.data.detalles.forEach((item) => {
          if (item.descripcion.includes("CUOTA")) {
            item.descripcion = CONCEPTO_CUOTA
          }
        })
        const resp = await postEmitirFactura({
          ...appSt.serviceData,
          body: {
            ...datosFacturacion.data,
            isFacturaCurso: true,
            isFacturaAnticipada: true,
          }
        });
        dispatch(setLoading(false));
        if (resp) {
          donwloadDoc(resp.payload.archivoPDF, 'pdf',)
          donwloadDoc(resp.payload.archivoXML, 'xml')
        }

        // await saveFactura(datosFacturacion.array, datosFacturacion.data, resp)

        dispatch(setLoading(false));
        dispatch(getPagosCursosDetails({
          ...appSt.serviceData,
          idEntidad,
          idModalidadPago: catalogosSt.idModoPagoTotal
        }))
        dispatch(getCobrosCursos({
          ...appSt.serviceData,
          idEntidad,
        }))

      } catch (error) {
        dispatch(setLoading(false));
        dispatch(setAlert({
          show: true,
          message: error.payload,
          type: ALERT_FAIL
        }))
      }
    }
  }

  const handleComplemento = async () => {
    try {
      dispatch(setLoading(true));
      const uuids = []
      for (const item of datosFacturacion.formatedPagos) {
        const exist = uuids.find(i => i.uuid === item.uuid);
        if (!exist) {
          uuids.push(item)
        }
      }
      for (const item of uuids) {
        if (item.fac_anticipada) {
          const resp = await postComplementoPagoCurso({
            ...appSt.serviceData,
            body: {
              idAreaCemefi: appSt.serviceData.area,
              idEntidad: idEntidad || registroConvocatoriaSt.empresaSeleccionada.idEntidad,
              idCobroCursoAplicado: item.id_cobro_curso_aplicado,
              uuid: item.uuid,
              idCursoEntidad: item.id_curso_entidad
            }
          })
  
          if (resp) {
            donwloadDoc(resp.archivoPDF, 'pdf',)
            donwloadDoc(resp.archivoXML, 'xml')
          }
  
          dispatch(setLoading(false));
        }        
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setAlert({
        show: true,
        message: error.payload ? error.payload : 'Error al complementar pago',
        type: ALERT_FAIL
      }))
    }
  }

  useEffect(() => {
    dispatch(setLoading(false));
  }, [datosFacturacion])

  useEffect(() => {
    if (checkoutId) {
      const config = {
        checkoutRequestId: checkoutId, //Checkout ID enviado desde el servidor para inicializar el componente de pago.
        publicKey: process.env.REACT_APP_CONEKTA_KEY,
        targetIFrame: 'pago',
        locale: "es",
      };

      const callbacks = {
        onFinalizePayment: (event) => successPayment(event),
        onErrorPayment: (event) => errorPayment(event),
        onGetInfoSuccess: (event) => {},
      };

      window.ConektaCheckoutComponents.Integration({ config, callbacks });
    }

  }, [checkoutId])

  return (
    <div className={checkoutId ? 'modal-wrapper active' : 'modal-wrapper'}>
      <div className="modal modal-pago-linea">
        <div className="head">
          <h3>Pagar</h3>
          <button onClick={closeModal}>
            x
          </button>
        </div>
        <div className="content">
          {isSuccess && <div className="referencia">
            <h3>Referencia de pago: <b>{isSuccess}</b></h3>
          </div>}
          <div id="pago"></div>
        </div>
        <div className="actions">
          <button onClick={closeModal}>Cerrar</button>
        </div>
      </div>
    </div>
  )
}