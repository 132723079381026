import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { appState, setAlert, setLoading } from '../../slices/appSlice';
import { publicacionState } from '../../slices/publicacionSlice';
import { actualizarCursosEntidades, obtenerCursosCobrosPorEntidad, obtenerCursosPorEntidades, registrarCursosCobros, registrarEntidadesCursos } from '../../services/cursos';
import { getCatalogos } from '../../services/catalogos';
import { ALERT_FAIL, ALERT_SUCCESS } from '../../app/constantes';
import { getDocument } from '../../services/entidad';
import { detectMimeType } from '../../app/commons';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { changeEsquemaCurso } from '../../slices/pagosCursosSlice';

export const ListCursos = ({ idEntidad, back }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appSt = useSelector(appState);
    const publicacionSt = useSelector(publicacionState);
    const [catCursos, setCatCursos] = useState([]);
    const [catCursosTotales, setCatCursosTotales] = useState([]);
    const [catCobrosCursos, setCatCobrosCursos] = useState([]);
    const [totalPagar, setTotalPagar] = useState(0);
    const [valuesSeleccion, setValuesSeleccion] = useState({});
    const [cantidadAsistentes, setCantidadAsistentes] = useState({});
    const [rowCursos, setRowCursos] = useState([]);
    const [isModalVerCursosEntidad, setIsModalVerCursosEntidad] = useState(false);
    // State para volver a cargar la info
    const [isConfirmar, setIsConfirmar] = useState(false);
    useEffect(() => {
        const obtenerCatalogos = async () => {
            dispatch(setLoading(true));
            setValuesSeleccion({});
            setCantidadAsistentes({});
            const cursosT = await getCatalogos(appSt.areaData.idAreaCemefi, "curso", appSt.serviceData.token, null);
            const cursos = await obtenerCursosPorEntidades(
                appSt.serviceData, 
                { idEntidad: idEntidad || publicacionSt.currentConvocatoria?.idEntidad },
                { idConvocatoria: !idEntidad ? publicacionSt?.currentConvocatoria?.idConvocatoria || "" : ""}
            );
            const dataCursosCobros = await obtenerCursosCobrosPorEntidad(
                appSt.serviceData, 
                { idEntidad: idEntidad || publicacionSt.currentConvocatoria?.idEntidad,}, 
                { idConvocatoria: !idEntidad ? publicacionSt?.currentConvocatoria?.idConvocatoria || "" : "" }
            )
            setCatCursosTotales(cursosT);
            setCatCursos(cursos);
            let data = null;
            if (dataCursosCobros.cobrosCurso.length > 0) {
                setCatCobrosCursos(dataCursosCobros.cobrosCurso);
                data = establecerValuesDataCursos(dataCursosCobros.cobrosCurso);
            } else {
                data = establecerValuesDataCursos(cursos);
            }
            if (data) {
                setValuesSeleccion(data.newValuesSeleccion);
                setCantidadAsistentes(data.newCantidadAsistentes);
            }
            dispatch(setLoading(false));
        }
        obtenerCatalogos();
    }, [idEntidad, appSt.serviceData, dispatch, publicacionSt.currentConvocatoria?.idEntidad, publicacionSt.currentConvocatoria?.idConvocatoria, appSt.areaData.idAreaCemefi, isConfirmar])

    // Efecto para calcular el total de la compra
    useEffect(() => {
        let suma = 0;
        for (let c of catCursosTotales) {
            if (valuesSeleccion[c.idCurso] && valuesSeleccion[c.idCurso].checked) {
                const cantidad = cantidadAsistentes[c.idCurso] ? cantidadAsistentes[c.idCurso].cantidad : 0;
                suma += c.monto * cantidad;
            }
        }
        setTotalPagar(suma);
    }, [catCursosTotales, cantidadAsistentes, valuesSeleccion])

    const establecerValuesDataCursos = (cursos) => {
        const newValuesSeleccion = {};
        const newCantidadAsistentes = {};
        for (let c of cursos) {
            // if (c.cve_estatus_cobro === "REGISTRADO") {
                // Cantidad
                if (newCantidadAsistentes[c.id_curso] && newCantidadAsistentes[c.id_curso].cantidad) {
                    newCantidadAsistentes[c.id_curso].cantidad = newCantidadAsistentes[c.id_curso].cantidad + c.cantidad;
                } else {
                    newCantidadAsistentes[c.id_curso] = {};
                    newCantidadAsistentes[c.id_curso].cantidad = c.cantidad;
                }
                // Seleccion
                if (newValuesSeleccion[c.id_curso] && newValuesSeleccion[c.id_curso].checked) {
                    // newValuesSeleccion[c.id_curso].checked = false;
                } else {
                    newValuesSeleccion[c.id_curso] = {};
                    newValuesSeleccion[c.id_curso].checked = true;
                }
            // }
        }
        return { newValuesSeleccion, newCantidadAsistentes }
    }
    
    const onChangeSeleccion = (e, idCurso) => {
        const newValuesSeleccion = { ...valuesSeleccion };
        if (newValuesSeleccion[idCurso] && newValuesSeleccion[idCurso].checked) {
            newValuesSeleccion[idCurso].checked = false;
        } else {
            newValuesSeleccion[idCurso] = {};
            newValuesSeleccion[idCurso].checked = true;
        }
        // Se vuelve a calcular la suma
        let suma = 0;
        for (let c of catCursosTotales) {
            if (newValuesSeleccion[c.idCurso] && newValuesSeleccion[c.idCurso].checked) {
                suma += c.monto;
            }
        }
        setTotalPagar(suma);
        setValuesSeleccion(newValuesSeleccion);
    }
    const onConfirmar = async () => {
        try {
            dispatch(setLoading(true));
            // Se asignan primero los cursos
            const isOk = await onAsignar();
            if (isOk) {
                // Se vuelven a obtener los cursos de la entidad
                const cursos = await obtenerCursosPorEntidades(
                    appSt.serviceData, 
                    { idEntidad: idEntidad || publicacionSt.currentConvocatoria?.idEntidad },
                    { idConvocatoria: !idEntidad ? publicacionSt?.currentConvocatoria?.idConvocatoria || "" : "" }
                )
                setCatCursos(cursos);
                const newData = [];
                for (let c of catCursosTotales) {
                    if (valuesSeleccion[c.idCurso] && valuesSeleccion[c.idCurso].checked) {
                        const objCurso = cursos.find(cr => (
                            cr.id_curso === c.idCurso &&
                            (cr.cve_estatus_curso_entidad === "ASIGNADO" || cr.cve_estatus_curso_entidad === "SOLICITADO")
                        ));
                        if (objCurso) {
                            const cantAsistentes = cantidadAsistentes[c.idCurso] ? cantidadAsistentes[c.idCurso].cantidad : 0;
                            if (cantAsistentes > 0) {
                                newData.push({
                                    idEntidad: idEntidad || publicacionSt.currentConvocatoria?.idEntidad,
                                    idCurso: c.idCurso,
                                    idCursoEntidad: objCurso.id_curso_entidad,
                                    cantidad: cantAsistentes,
                                    descCurso: objCurso.desc_curso,
                                    montoCobro: c.monto,
                                    montoTotalCobro: (cantAsistentes * c.monto),
                                })
                            }
                        }
                    }
                }
                for (let n of newData) {
                    if (n.cantidad === 0) {
                        dispatch(setAlert({
                            show: true,
                            message: `No puedes tener cursos con cantidad 0`,
                            type: ALERT_FAIL
                        }))
                        return;
                    }
                }
                if (newData.length > 0) {
                    await actualizarCursosEntidades(appSt.serviceData, { cursosEntidad: newData, isSinInstrumento: idEntidad ? true : false });
                    await registrarCursosCobros(appSt.serviceData, { cursosEntidad: newData, isSinInstrumento: idEntidad ? true : false });
                    dispatch(setAlert({
                        show: true,
                        message: `Se ha confirmado exitosamente`,
                        type: ALERT_SUCCESS
                    }))
                    setIsConfirmar(!isConfirmar);
                } else {
                    dispatch(setLoading(false));
                    dispatch(setAlert({
                        show: true,
                        message: `Por favor selecciona al menos un curso o introduce una cantidad mayor a 0`,
                        type: ALERT_FAIL
                    }))
                    return;
                }
            }
        } catch (error) {

        }
        dispatch(setLoading(false));
    }
    const onIrPagos = async () => {
        const esquemaCobros = await getCatalogos(appSt.areaData.idAreaCemefi, "esquemaCobro", appSt.serviceData.token, null);
        dispatch(changeEsquemaCurso(esquemaCobros));
        navigate(`/${idEntidad || publicacionSt.currentConvocatoria?.idEntidad}/pagosCursos`);
    }
    const onAsignar = async () => {
        try {
            const body = {
                cursos: [],
                entidades: [
                    { 
                        id_entidad: idEntidad || publicacionSt.currentConvocatoria?.idEntidad, 
                        id_instrumento_registro_segmento_muestra: (publicacionSt && publicacionSt.currentConvocatoria && publicacionSt.currentConvocatoria.publicacion && publicacionSt.currentConvocatoria.publicacion.data) 
                            ? publicacionSt.currentConvocatoria.publicacion.data.id_instrumento_registro_segmento_muestra : null
                    }
                ],
                cveEstatusCursoEntidad: "SOLICITADO"
            }
            // Se obtienen los cursos no asignados
            for (let c of catCursosTotales) {
                const objCurso = catCursos.find(cr => (
                    cr.id_curso === c.idCurso &&
                    (cr.cve_estatus_curso_entidad === "ASIGNADO" || cr.cve_estatus_curso_entidad === "SOLICITADO")
                ));
                if (
                    !objCurso
                    && valuesSeleccion[c.idCurso] && valuesSeleccion[c.idCurso].checked
                    && cantidadAsistentes[c.idCurso] && cantidadAsistentes[c.idCurso].cantidad > 0
                ) {
                    body.cursos.push({ idCurso: c.idCurso, });
                }
            }
            if (body.cursos.length > 0) {
                await registrarEntidadesCursos(appSt.serviceData, body);
            }
            return true;
        } catch (error) {
            throw error;
        }
    }
    const onOperacionAsistentes = (tipo, idCurso) => {
        let newCantidadAsistentes = { ...cantidadAsistentes };
        if (newCantidadAsistentes[idCurso] && newCantidadAsistentes[idCurso].cantidad) {
            if (tipo === "suma")
                newCantidadAsistentes[idCurso].cantidad++;
            if (tipo === "resta") {
                // Se obtienen los cursos
                const objCursosEntidad = catCursos.filter(c => c.id_curso === idCurso);
                if (objCursosEntidad.length > 0) {
                    // Se obtiene el último registro
                    let cantidadPendientes = 0;
                    for (let c of objCursosEntidad) {
                        if (c.cve_estatus_curso_entidad === "ASIGNADO" || c.cve_estatus_curso_entidad === "SOLICITADO") {
                            cantidadPendientes++;
                        }
                    }
                    let cantidadNoPendientes = 0;
                    for (let c of objCursosEntidad) {
                        if (!(c.cve_estatus_curso_entidad === "ASIGNADO" || c.cve_estatus_curso_entidad === "SOLICITADO")) {
                            cantidadNoPendientes += c.cantidad;
                        }
                    }
                    if (cantidadPendientes === 0) {
                        dispatch(setAlert({
                            show: true,
                            message: `No puedes quitar cursos debido a que no están en estatus válido`,
                            type: ALERT_FAIL
                        }))
                        return;
                    }
                    if (cantidadNoPendientes === newCantidadAsistentes[idCurso].cantidad) {
                        dispatch(setAlert({
                            show: true,
                            message: `No puedes quitar más cursos debido a que no están en estatus válido`,
                            type: ALERT_FAIL
                        }))
                        return;
                    }
                }
                newCantidadAsistentes[idCurso].cantidad--;
            }
        } else {
            if (tipo === "suma") {
                newCantidadAsistentes[idCurso] = {};
                newCantidadAsistentes[idCurso].cantidad = 1;
            }
        }
        setCantidadAsistentes(newCantidadAsistentes);
    }
    const downloadDoc = async (idContent) => {
        try {
            dispatch(setLoading(true))
            const body = {
                ...appSt.serviceData,
                idEntidad: idEntidad || publicacionSt.currentConvocatoria?.idEntidad,
                idContent: idContent
            }
            const docResp = await getDocument(body);
            if (docResp.payload) {
                let type = detectMimeType(docResp.payload.base64, 'doc');
                if (type === 'unknown') {
                    type = 'application/pdf'
                }
                const file64 = docResp.payload.base64;
                const linkSource = `data:${type};base64,${file64}`;
                const downloadLink = document.createElement('a');
                downloadLink.href = linkSource;
                let path = idContent.split('/');
                path = path.slice(-1);
                downloadLink.download = path;
                downloadLink.click()
            }
            dispatch(setLoading(false))
        } catch (error) {
            console.log(error);
        }
    }
    const handleVerCursosEntidad = (idCurso) => {
        const objCursosEntidad = catCursos.filter(c => c.id_curso === idCurso);
        setRowCursos(objCursosEntidad);
        setIsModalVerCursosEntidad(true)
    }
    const handleCerrarVerCursosEntidad = () => {
        setRowCursos([]);
        setIsModalVerCursosEntidad(false)
    }
    return <div className="aprobacion">
        <div className="wrapper">
            {back
                ? <div className="head">
                    <button onClick={back}>
                        <BackIcon />
                    </button>
                    <h3>Cursos</h3>
                </div>
                : <></>
            }
            <div className="card">
                <div style={{ margin: "50px 100px", padding: 15, background: "#ece6f0", borderRadius: 10, }}>
                    <h2>Campus ESR</h2>
                    <br />
                    <p>
                        Campus ESR es una iniciativa del Programa de Responsabilidad Social Empresarial (RSE), de Cemefi, que busca
                        posicionarse como una opción de fortalecimiento de capacidades para ejecutivos de empresas, organizaciones
                        de sociedad civil y académicos en temas de RSE. Conoce nuestra oferta de capacitación y las fechas en que
                        se llevarán a cabo las sesiones informativas para postular al distintivo ESR.
                    </p>
                    <br />
                    <br />
                    {(publicacionSt?.currentConvocatoria?.idConvocatoria && catCobrosCursos.length === 0 && catCursos.length === 0)
                        ? <strong>Sin Cursos</strong>
                        : <table>
                            <thead></thead>
                            <tbody>
                                {catCursosTotales && catCursosTotales.map(c => {
                                    const objCurso = catCursos.find(cr => (
                                        cr.id_curso === c.idCurso
                                    ));
                                    const isObligIsRec = objCurso && (objCurso.is_obligatorio || objCurso.is_recomendado);
                                    return <tr key={c.idCurso} >
                                        <td width="60%">
                                            <div >
                                                <div className={isObligIsRec ? "two-col" : "one-col"}>
                                                    <div width={isObligIsRec ? "65%" : "100%"}>
                                                        <span 
                                                            style={{ cursor: "pointer", color: "#007bff" }}
                                                            onClick={() => handleVerCursosEntidad(c.idCurso)}
                                                        >
                                                            {c.descCurso}
                                                        </span>
                                                    </div>
                                                    {isObligIsRec 
                                                        ? <div width={"35%"}>
                                                            {objCurso && objCurso.is_obligatorio
                                                                ? <span style={{ color: "red" }}>- Obligatorio -</span> : ""
                                                            }
                                                            {objCurso && objCurso.is_recomendado
                                                                ? <span style={{ color: "red" }}>- Recomendado -</span> : ""
                                                            }
                                                        </div>
                                                        : <></>
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                        <td width="30%">
                                            <div className='three-col'>
                                                {c.idContent
                                                    // ? <span onClick={(() => downloadDoc(c.idContent))}>Archivo</span>
                                                    ? <div style={{ display: "flex", width: 32, marginLeft: 10 }} >
                                                        {/* <div style={{ display: "flex", width: "32px !important" }} > */}
                                                        <FileIcon 
                                                            onClick={(() => downloadDoc(c.idContent))} 
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                        {/* </div> */}
                                                    </div>
                                                    : <span></span>
                                                }
                                                <span>${Number(c.monto).toFixed(2)}</span>
                                                <FormInputText
                                                    onChange={(e) => onChangeSeleccion(e, c.idCurso)}
                                                    value={valuesSeleccion[c.idCurso] && valuesSeleccion[c.idCurso].checked ? true : false}
                                                    name={`isSeleccionCurso_${c.idCurso}`}
                                                    type="checkbox"
                                                />
                                            </div>
                                            <hr />
                                            <div className='four-col'>
                                                <span>Asistentes</span>
                                                {cantidadAsistentes[c.idCurso] && cantidadAsistentes[c.idCurso].cantidad > 0
                                                    ? <button
                                                        style={{ minWidth: "40px" }}
                                                        onClick={() => onOperacionAsistentes("resta", c.idCurso)}
                                                    >-</button>
                                                    : <div></div>
                                                }
                                                <span style={{ textAlign: "center" }}>{cantidadAsistentes[c.idCurso] ? cantidadAsistentes[c.idCurso].cantidad : 0}</span>
                                                <button
                                                    style={{ minWidth: "40px" }}
                                                    onClick={() => onOperacionAsistentes("suma", c.idCurso)}
                                                >+</button>
                                            </div>
                                        </td>
                                    </tr>
                                })}
                                {catCursosTotales.length > 0
                                    ? <>
                                        <tr>
                                            <td><strong>Total</strong></td>
                                            <td><strong>${totalPagar.toFixed(2)}</strong></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td colSpan={2}>
                                                <div className='two-col'>
                                                    <button onClick={onConfirmar} >
                                                        Confirmar
                                                    </button>
                                                    <button onClick={onIrPagos} >
                                                        Pagos
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                    : <></>
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </div>
            {isModalVerCursosEntidad
                ? <ModalVerCursosEntidad 
                    show={isModalVerCursosEntidad}
                    closeModal={handleCerrarVerCursosEntidad}
                    cursosEntidad={rowCursos}
                />
                : <></>
            }
        </div>
    </div>
}

export const FormInputText = ({
    disabled, errorClassName, className, name, required, label,
    type = "text", placeholder, max, isNumber, value, onChange,
}) => {
    const props = {};
    if (isNumber) {
        props.pattern = "[0-9]{1,5}";
    }
    if (onChange) {
        props.onChange = onChange;
    }
    if (value) {
        if (type === "checkbox") {
            props.checked = value;
        }
        else props.value = value;
    }
    return <div className={`input-wrapper ${errorClassName} ${className ? className : ''}`}>
        {label &&
            <label>
                {required &&
                    <span>*</span>
                }
                {label}
            </label>
        }
        <input
            {...props}
            disabled={disabled}
            id={name}
            maxLength={max ? max : ''}
            name={name}
            placeholder={placeholder}
            type={type === 'upper' ? 'text' : type}
        />
    </div>
}

const ModalVerCursosEntidad = ({ show, closeModal, cursosEntidad }) => {
    return <div className={show ? 'modal-wrapper active' : 'modal-wrapper'}>
        <div className="modal">
            <div className="head">
                <h3 style={{ color: "black"}}>Listado Cursos Seleccionados</h3>
                <button onClick={closeModal}>
                    x
                </button>
            </div>
            <div className="content">
                <div className="tip">
                    <InfoIcon />
                    <p>Listado de cursos seleccionados {cursosEntidad && cursosEntidad[0] ? cursosEntidad[0].desc_curso : ""}</p>
                </div>
                <div className='one-col'>
                    {cursosEntidad.length > 0
                        ? <table>
                            <tr>
                                <th>Estatus Cobro</th>
                                <th>Estatus Curso</th>
                                <th>Cantidad</th>
                            </tr>
                            {cursosEntidad.map(c =>
                                <tr>
                                    <td>{c.desc_estatus_cobro}</td>
                                    <td>{c.desc_estatus_curso_entidad}</td>
                                    <td>{c.cantidad}</td>
                                </tr>
                            )}
                        </table>
                        : <span>Sin Cursos Seleccionados</span>
                    }
                </div>
            </div>
        </div>
    </div>
}