import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCobroComprobantes, getCobros } from '../services/cobro';
import { SECTIONS } from '../app/constantes';

const initialState = {
    loading: false,
    currentConvocatoria: false,
    cobrosAplicados: null,
    pagoDetails: null,
    view: SECTIONS.one,
    esquema: null,
    modalFactura: {
        show: false,
        data: null
    },
    modalRevertir: {
        show: false,
        data: null
    },
    modalServiciosAdicionales: {
        show: false,
        data: null
    }
}

export const getCobrosAplicados = createAsyncThunk('pagos/getCobrosAplicados', async (data, {rejectWithValue}) => {
  let resp;
  try {
      resp = await getCobroComprobantes(data); 
  } catch (error) {
      console.log(error.response); 
      return rejectWithValue({success: false})
  }
  return resp;
})

export const getPagosDetails = createAsyncThunk('pagos/getPagosDetails', async (data, {rejectWithValue}) => {
  let resp;
  try {
      resp = await getCobros(data); 
  } catch (error) {
      console.log(error.response); 
      return rejectWithValue({success: false})
  }
  return resp;
})

export const pagosSlice = createSlice({
    name: 'pagos',
    initialState,    
    reducers: {
        setCurrentConvocatoria: (state, action) => {
            state.currentConvocatoria = action.payload;
        },
        setModalFactura: (state, action) => {
            state.modalFactura = action.payload;
        },
        setModalRevertir: (state, action) => {
            state.modalRevertir = action.payload;
        },
        setModalServiciosAdicionales: (state, action) => {
            state.modalServiciosAdicionales = action.payload;
        },
        setInitial: (state, action) => {
            state.initial = action.payload;
        },
        changeSection: (state, action) => {
            state.view = action.payload;
        },
        changeEsquema: (state, action) => {
            state.esquema = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase((
            getPagosDetails.pending 
        ), (state) => {
            state.loading = true
        })

        builder.addCase((
            getCobrosAplicados.pending 
        ), (state) => {
            state.loading = true
        })

        builder.addCase((
            getPagosDetails.rejected 
        ), (state) => {
            state.loading = false
        })

        builder.addCase((
            getCobrosAplicados.rejected 
        ), (state) => {
            state.loading = true
        })

        builder.addCase(getCobrosAplicados.fulfilled, (state, action) => {
            state.loading = false;
            state.cobrosAplicados = action.payload;
        })

        builder.addCase(getPagosDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.pagoDetails = action.payload;
        })
    },
})

export const { 
    setCurrentConvocatoria,
    setModalFactura,
    setModalServiciosAdicionales,
    setInitial,
    changeSection,
    setModalRevertir,
    changeEsquema
 } = pagosSlice.actions;
export const pagosState = (state) => state.pagos;

export default pagosSlice.reducer;