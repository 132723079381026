/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { catalogosServiceState, getDocumentosCatalogo } from "../../slices/catalogosServiceSlice";

import { formatCurrency, validateForm } from "../../app/commons";
import { ALERT_FAIL, ALERT_SUCCESS, DESC_ESQUEMA_PAGO, CVE_TIPODOC_COBRO, SECTIONS } from "../../app/constantes";

import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';

import moment from "moment/moment";
import { getMessageByModulo } from "../../services/catalogos";
import { findCatalogById } from "../../app/commons";
import { changeSection, getCobrosAplicados, getPagosDetails, pagosState, setModalFactura, setModalServiciosAdicionales } from "../../slices/pagosSlice";
import { catalogoFactura, pagosIncialForm } from "../../app/forms/cobros/pagoIncial";
import InputComp from "../generales/inputComponent";
import ModalDatosFactura from "./modalDatosFactura";
import ModalServiciosAdicionales from "./modalServicioAdicionales";
import { deleteServicioExtemporaneo } from "../../services/cobro";

export default function PagosInicial({ convocatoria }) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const catalogosSt = useSelector(catalogosServiceState);
    const pagosSt = useSelector(pagosState);
    const [form, setForm] = useState(pagosIncialForm);
    const [registros, setRegistros] = useState(null);
    const [messages, setMessages] = useState(null);
    const [categorias, setCategorias] = useState(null);
    const [validations, setValidations] = useState(null);
    const [catalogoMetodoPago, setCatalogoMetodoPago] = useState(null);
    const [mensajeInvitacion, setMensajeInvitacion] = useState(null)
    const [canEdit, setCanEdit] = useState(false)

    /**
     * Tips
     */
    const getMessages = async () => {
        if (!messages) {
            const resp = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'MISPAGOS', 'MENSAJEMISPAGOS ');
            if (resp.payload && resp.payload.valor) {
                setMessages({ ...messages, topMessage: resp.payload.valor })
            }
        }
    }

    const openModalFacturas = (type) => {
        dispatch(setModalFactura({
            show: true,
            data: { type }
        }))
    }

    const openServiciosAdicionales = () => {
        dispatch(setModalServiciosAdicionales({
            show: true,
            data: null
        }))
    }

    const handleContinue = () => {
        const valid = validateForm(form)
        if (valid.length === 0) {
            if (form.facturaAnticipada.value === 'true') {
                openModalFacturas('anticipada')
            }
            if (form.metodoPago.value === 'depositoBancario') {
                dispatch(changeSection(SECTIONS.two))
            }
            if (form.metodoPago.value === 'pagoLinea') {
                dispatch(changeSection(SECTIONS.four))
            }
        } else {
            dispatch(setAlert({
                show: true,
                message: 'Favor de llenar los campos requeridos',
                type: ALERT_FAIL
            }))
        }
    }

    /**
     * formateamos el form y seateamos las validaciones
     * @param {*} cat 
     */
    const doValidations = (cat) => {
        const validationsObj = {
            showFacturaAnticipada: false,
            showMetodoPago: false,
        }
        const clone = JSON.parse(JSON.stringify(form))
        const catalogo = []
        if (pagosSt.esquema.find(esq => esq.descEsquemaCobro === DESC_ESQUEMA_PAGO.manual)) {
            catalogo.push({
                label: 'Deposito bancario',
                value: 'depositoBancario'
            })
        }
        if (pagosSt.esquema.find(esq => esq.descEsquemaCobro === DESC_ESQUEMA_PAGO.enLinea)) {
            catalogo.push({
                label: 'Pago en linea',
                value: 'pagoLinea'
            })
        }
        setCatalogoMetodoPago(catalogo)
        const isPagoManual = pagosSt.esquema.find(item => item.descEsquemaCobro === DESC_ESQUEMA_PAGO.manual)
        if (cat.pendientesSinFactura.length > 0 && isPagoManual) {
            validationsObj.showFacturaAnticipada = true
            clone.facturaAnticipada.required = true
        }
        if (cat.pendientes.length > 0) {
            validationsObj.showMetodoPago = true
            clone.metodoPago.required = true
        }
        setForm(clone)
        setValidations(validationsObj)
    }

    /**
     * Categorisamos los cobros
     * @param {*} cobros 
     */
    const checkValidations = (cobros) => {
        const pendientesSinFactura = []
        const pendientes = []
        const pagados = []
        const pagadosSinFactura = []
        console.log(cobros)

        cobros.forEach(cobro => {
            if (
                cobro.cobroAplicado.facAnticipada !== 1 &&
                cobro.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado
            ) {
                pendientesSinFactura.push(cobro)
            }
            if (cobro.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado) {
                pendientes.push(cobro)
            }
            if (cobro.idEstatusCobro === catalogosSt.idsEstatusCobro.confirmado) {
                pagados.push(cobro)
            }
            if (
                cobro.cobroAplicado.facAnticipada !== 1 &&
                cobro.idEstatusCobro === catalogosSt.idsEstatusCobro.confirmado
            ) {
                pagadosSinFactura.push(cobro)
            }
        })
        const body = {
            pendientesSinFactura,
            pendientes,
            pagados,
            pagadosSinFactura
        }
        setCategorias(body);
        doValidations(body)
    }

    const deleteServicio = async (pago) => {
        dispatch(setLoading(true));
        try {
            await deleteServicioExtemporaneo({
                ...appSt.serviceData,
                idCobroAplicado: pago.cobroAplicado.idCobroAplicado,
                idCobroRegistroConvocatoria: pago.idCobroRegistroConvocatoria
            })
            dispatch(setAlert({
                show: true,
                message: "Servicio eliminado",
                type: ALERT_SUCCESS
            }))
            dispatch(getPagosDetails({
                ...appSt.serviceData,
                idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
                idModalidadPago: catalogosSt.idModoPagoTotal
            }))
            dispatch(getCobrosAplicados({
                ...appSt.serviceData,
                idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
            }))
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error)
            dispatch(setAlert({
                show: true,
                message: "Error al eliminar servicio",
                type: ALERT_SUCCESS
            }))
            dispatch(setLoading(false));

        }
    }

    const checkInvitacion = () => {
        let mensaje = null
        if (pagosSt.pagoDetails.razonSocialInvito) {
            mensaje = `Su entidad ha sido beneficiada con un descuento del 10% en su participación por ser parte de la Cadena de Valor de la empresa Impulsora: ${pagosSt.pagoDetails.razonSocialInvito}`
        }
        setMensajeInvitacion(mensaje)
    }

    useEffect(() => {
        dispatch(getDocumentosCatalogo({ ...appSt.serviceData, cveTipoDocumento: CVE_TIPODOC_COBRO }));
        getMessages();
    }, [convocatoria]);

    useEffect(() => {
        console.log(pagosSt)
        if (pagosSt.pagoDetails && pagosSt.pagoDetails.cobroRegistroConvocatoriaDet && catalogosSt.idsEstatusCobro) {
            setRegistros(pagosSt.pagoDetails.cobroRegistroConvocatoriaDet)
            checkValidations(pagosSt.pagoDetails.cobroRegistroConvocatoriaDet)
        }

        if (pagosSt.pagoDetails) {
            checkInvitacion()
        }
    }, [pagosSt.pagoDetails, catalogosSt.idsEstatusCobro])

    useEffect(() => {
        const clone = JSON.parse(JSON.stringify(form));
        if (form.facturaAnticipada.value === 'true') {
            clone.metodoPago.required = false;
            clone.metodoPago.invalid = false;
        } else {
            clone.metodoPago.required = true;
            clone.metodoPago.invalid = false;
        }
        setForm(clone)

    }, [form.facturaAnticipada.value])

    useEffect(() => {
        setCanEdit(convocatoria && Number(convocatoria.misPagos) === 1)
    }, [convocatoria])


    return (
        <div className="pago-inicial">
            <h3>Historial de Pagos</h3>
            {messages && messages.topMessage &&
                <div className="tip">
                    <InfoIcon />
                    <p>Para efecto de realizar tu transferencia, deberás indicar la referencia y pagar el importe exacto que señala la Orden de Pago.</p>
                </div>
            }
            {mensajeInvitacion &&
                <div className="tip">
                    <InfoIcon />
                    <p>{mensajeInvitacion}</p>
                </div>
            }
            <table>
                <thead>
                    <tr>
                        <th>Estatus</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th className="date">Fecha</th>
                        <th>Monto Unitario</th>
                        <th>Referencia Pasarela</th>
                        <th>Monto</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {catalogosSt.idsEstatusCobro && registros && registros.map((pago, index) => {
                        return <tr key={index}>
                            <td>{findCatalogById(pago.idEstatusCobro, catalogosSt.estatusCobro, 'idEstatusCobro', 'descEstatusCobro')}</td>
                            <td>{pago.descCobroRegistroConvocatoria}</td>
                            <td>{pago.cantidad}</td>
                            <td>{moment(pago.createdAt).format('YYYY-MM-DD')}</td>
                            <td>{formatCurrency(pago.montoCobro)}</td>
                            <td>{pago.cobroAplicado.referenciaPasarela}</td>
                            <td>{formatCurrency(Number(pago.montoCobro) * pago.cantidad)}</td>
                            <td>
                                {pago.idEstatusCobro === catalogosSt.idsEstatusCobro.registrado && !pago.cobroAplicado.facAnticipada && !pago.descCobroRegistroConvocatoria.includes('CUOTA') && canEdit &&
                                    <button onClick={() => deleteServicio(pago)} className="small-icon-btn">
                                        <DeleteIcon />
                                    </button>
                                }
                            </td>
                        </tr>
                    })}
                    {pagosSt.pagoDetails &&
                        <tr>
                            <th className="center" colSpan={2}>Monto Total</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="warning">{formatCurrency(pagosSt.pagoDetails.sumaFinal)}</td>
                            <td></td>
                        </tr>
                    }
                </tbody>
            </table>
            {canEdit && <>
                <div className="form">
                    {categorias &&
                        <div className="two-col">
                            {/* Si hay pendientes sin factura anticipada */}
                            {validations && validations.showFacturaAnticipada &&
                                <InputComp
                                    label="Factura anticipada"
                                    name="facturaAnticipada"
                                    type="select"
                                    catalogos={catalogoFactura}
                                    catalogoLabel={'label'}
                                    catalogoValue={'value'}
                                    form={form}
                                    setForm={setForm}
                                />
                            }
                            {/* Si pendientes es mayor a 0 y el valor de factura anticipada no sea si o factura anticipada no se muestra */}
                            {validations && validations.showMetodoPago && (form.facturaAnticipada.value !== 'true' || !validations.showFacturaAnticipada) &&
                                <InputComp
                                    label="Metodo de pago"
                                    name="metodoPago"
                                    type="select"
                                    catalogos={catalogoMetodoPago}
                                    catalogoLabel={'label'}
                                    catalogoValue={'value'}
                                    form={form}
                                    setForm={setForm}
                                />
                            }
                        </div>
                    }
                </div>
                <div className="actions">
                    {categorias && categorias.pendientes.length > 0 &&
                        <button onClick={handleContinue}>Continuar</button>
                    }
                    {categorias && categorias.pagadosSinFactura.length > 0 &&
                        <button onClick={() => openModalFacturas('normal')}>Solicitar Factura</button>
                    }
                    <button onClick={openServiciosAdicionales}>Solicitar Servicios Adicionales</button>
                </div>
            </>}

            <ModalDatosFactura convocatoria={convocatoria} />
            <ModalServiciosAdicionales convocatoria={convocatoria} />
        </div>
    )
}