import { getRequest } from "./http-service";

const url = process.env.REACT_APP_SEPOMEX_SERVICE_URL;

export const getByCP = async (data) => {
    const requestUrl = `${url}codigoPostal/${data.cp}/sepomex`
    return await getRequest(requestUrl, data.token);
}

export const getCPbyId = async (data) => {
    const requestUrl = `${url}codigoPostal/idcodigoPostal/${data.idCp}`
    return await getRequest(requestUrl, data.token);
}