export const datosFiscalesForm = {
  rfc: {
      value: '',
      required: false,
      message: 'Dato requerido',
      tool: '',
      invalid: false,
  },
  razonSocial: {
    value: '',
    required: false,
    message: 'Dato requerido',
    tool: '',
    invalid: false,
  },
  folioOdc: {
    value: '',
    required: false,
    message: 'Dato requerido',
    tool: '',
    invalid: false,
  },
  cveUsoCfdi: {
    value: '',
    required: true,
    message: 'Dato requerido',
    tool: '',
    invalid: false,
  },
  cveFormaPago: {
    value: '',
    required: false,
    message: 'Dato requerido',
    tool: '',
    invalid: false,
  },
  cveMetodoPago: {
    value: '',
    required: false,
    message: 'Dato requerido',
    tool: '',
    invalid: false,
  },
  idRegimenFiscal: {
    value: '',
    required: true,
    message: 'Dato requerido',
    tool: '',
    invalid: false,
  },
}

export const datosFiscalesMinForm = {
  cveUsoCfdi: {
    value: '',
    required: true,
    message: 'Dato requerido',
    tool: '',
    invalid: false,
  },
  idRegimenFiscal: {
    value: '',
    required: true,
    message: 'Dato requerido',
    tool: '',
    invalid: false,
  },
}