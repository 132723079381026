import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { catalogosServiceState, getDocumentosCatalogo } from "../../slices/catalogosServiceSlice";
import { getCobroFacturas, postCobroAplicado, putCobroAplicado } from "../../services/cobro";
import { formatCurrency } from "../../app/commons";
import { ALERT_FAIL, ALERT_SUCCESS, CVE_TIPODOC_COBRO, ERROR_MESSAGE, SUCESS_MESSAGE } from "../../app/constantes";

import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';

import moment from "moment/moment";
import { getDocument } from "../../services/entidad";
import ModalDatosFactura from "./modalDatosFactura";
import { findCatalogById } from "../../app/commons";
import { getCobrosAplicados, getPagosDetails, pagosState } from "../../slices/pagosSlice";
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";


export default function Facturas({ convocatoria }) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const catalogosSt = useSelector(catalogosServiceState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const pagosSt = useSelector(pagosState);

    const [selectedListPago, setSelectedListPago] = useState([]);
    const [formatedPagos, setFormatedPagos] = useState(null);
    const [modalData, setModalData] = useState({
        show: false,
        list: null
    });

    /**
     * Subimos el file del comprobante y llamamos a los servicios de referencia
     */
    const solicitarFactura = async () => {
        dispatch(setLoading(true));
        const postBody = [];
        const putBody = [];
        selectedListPago.forEach(item => {
            const body = {
                idRegistroConvocatoria: item.idRegistroConvocatoria,
                idConceptoCobroConvocatoria: item.idConceptoCobroConvocatoria,
                monto: Number(item.montoCobro) * item.cantidad,
                facAnticipada: 1
            }
            item.idCobroAplicado ? putBody.push({...body, idCobroAplicado: item.idCobroAplicado}) : postBody.push(body);
        })
        try {
            if (postBody.length > 0) {
                await postCobroAplicado(appSt.serviceData, postBody);
            }
            if (putBody.length > 0) {
                await putCobroAplicado(appSt.serviceData, putBody);
            }
            
            dispatch(setLoading(false));
            dispatch(getPagosDetails({
                ...appSt.serviceData,
                idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
                idModalidadPago: catalogosSt.idModoPagoTotal
            }))
            dispatch(getCobrosAplicados({
                ...appSt.serviceData,
                idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
            }))
            dispatch(setAlert({
                show: true,
                message: SUCESS_MESSAGE,
                type: ALERT_SUCCESS
            }));           
        } catch (error) {
            console.log(error);
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: ERROR_MESSAGE,
                type: ALERT_FAIL
            }));            
        }        
    }

    const getFacturas = async () => {
        dispatch(setLoading(true));
        let facturas = null;
        try {
            const resp = await getCobroFacturas({...appSt.serviceData, idRegistroConvocatoria: convocatoria.idRegistroConvocatoria });
            formatPagos(resp.payload)
            facturas = resp;
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: 'Error al traer las facturas',
                type: ALERT_FAIL
            }))
            dispatch(setLoading(false));            
        }

        return facturas;
    }

    /**
     * vemos si el pago ya tiene un cobrante
     */
     const formatPagos = async (resp) => {
        const array = [];
        if (resp) {
            resp.forEach(item => {
                const obj = {...item}
                const registro = item.idConceptoCobroConvocatoria2.cemCobroCrcRegistroConvocatorias[0]
                const notas = registro.cemDocumentoCrcCobroRegistros.filter(item => {
                    return item.idDocumentoCemefi2.cveDocumentoCemefi.includes("NOTACREDITO")
                })
                const complementarios = registro.cemDocumentoCrcCobroRegistros.filter(item => {
                    return item.idDocumentoCemefi2.cveDocumentoCemefi.includes("COMPLEMENTOPAAGO")
                })
                const facturas = registro.cemDocumentoCrcCobroRegistros.filter(item => {
                    return item.idDocumentoCemefi2.cveDocumentoCemefi.includes("FACTURA")
                })
                const hasCobro = item.idConceptoCobroConvocatoria2 && item.idConceptoCobroConvocatoria2.cemCobroCrcRegistroConvocatorias && item.idConceptoCobroConvocatoria2.cemCobroCrcRegistroConvocatorias.length > 0
                obj.descConceptoCobroConvocatoria = item.idConceptoCobroConvocatoria2.descConceptoCobroConvocatoria
                obj.cantidad = hasCobro ? item.idConceptoCobroConvocatoria2.cemCobroCrcRegistroConvocatorias[0].cantidad : registro.cantidad
                obj.montoUnitario = hasCobro ? item.idConceptoCobroConvocatoria2.cemCobroCrcRegistroConvocatorias[0].montoCobro : null
                obj.facturas = facturas
                obj.complementarios = complementarios
                obj.notas = notas
                array.push(obj)
            });
        }
        console.log(array, resp)
        setFormatedPagos(array);
    }

    const getFileName = (filePath) => {
        let path, name;
        if (filePath.includes("XX")) {
            path = filePath.split("XX");
            name = path.slice(-1)
        } else {
            path = filePath.split('/');
            name = path.slice(-1)
        }
        return name;
    }

    /**
     * Descargamos una evidencia
     * @param {*} evidencia 
     */
    const descargarFactura = async (file) => {
        dispatch(setLoading(true));
        const docResp = await getDocument({
            ...appSt.serviceData,
            idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad,
            idContent: file.idContent
        });
        const name = getFileName(file.idContent);
        const fileName = name[0]
        const ext = fileName.split('.').pop();

        if (docResp.payload) {
            const type = ext === 'pdf' ? 'data:application/pdf' : `data:application/xml`;
            console.log(type, ext, fileName, file, name)
            // const type = 'data:application/png'
            const file64 = docResp.payload.base64;
            const linkSource = `${type};base64,${file64}`;
            const downloadLink = document.createElement('a');

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            dispatch(setLoading(false));
        }
    }

    const closeModal = () => {
        setModalData({show: false, list: null});
    }

    const openModal = () => {
        setModalData({
            show: true,
            list: selectedListPago,
            callback: () => solicitarFactura()
        })
    }

    useEffect(() => {
        dispatch(getDocumentosCatalogo({...appSt.serviceData, cveTipoDocumento: CVE_TIPODOC_COBRO}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [convocatoria]);

    useEffect(() => {
        getFacturas();     

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    return (
        <div className="facturas-view">
            <h3>Facturas</h3>
            {/* <p>Selecciona los pagos para solicitar una factura anticipada</p>
            <div className="options">
                <button disabled={selectedListPago.length === 0} onClick={openModal}>Solicitar Factura</button>
            </div> */}
            <table>
                <thead>
                    <tr>
                        <th>Estatus</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Fecha</th>
                        <th>Monto Unitario</th>
                        <th>Total</th>
                        <th>Facturas</th>
                        <th>Nota de Crédito</th>
                        <th>Complemento de Pago</th>
                    </tr>
                </thead>
                <tbody>
                    {formatedPagos && formatedPagos.map((pago, index) => {
                        return <tr key={index}>
                            <td>{findCatalogById(pago.idEstatusCobro, catalogosSt.estatusCobro, 'idEstatusCobro', 'descEstatusCobro')}</td>
                            <td>{pago.descConceptoCobroConvocatoria}</td>
                            <td>{pago.cantidad}</td>
                            <td>{moment(pago.createdAt).format('YYYY-MM-DD')}</td>
                            <td>{formatCurrency(pago.montoUnitario)}</td>
                            {/* este se cambio por q montoUnitario ya viene con el descuento  formatCurrency(pagosSt.pagoDetails.sumaFinal)*/}
                            <td>{formatCurrency(pago.montoUnitario * pago.cantidad)}</td>
                            <td>
                                {pago.facturas && pago.facturas.length > 0 &&
                                    <div className="submenu">
                                        <DownloadIcon />
                                        <ul className="menu">
                                            {pago.facturas.map((factura, index) => {
                                                return <li className="factura" key={index} onClick={() => descargarFactura(factura)}>
                                                    <DownloadIcon />                                                    
                                                    <p>{getFileName(factura.idContent)}</p>
                                                </li>
                                            })}
                                        </ul>

                                    </div>
                                }
                            </td>
                            <td>
                                {pago.notas && pago.notas.length > 0 &&
                                    <div className="submenu">
                                        <DownloadIcon />
                                        <ul className="menu">
                                            {pago.notas.map((factura, index) => {
                                                return <li className="factura" key={index} onClick={() => descargarFactura(factura)}>
                                                    <DownloadIcon />                                                    
                                                    <p>{getFileName(factura.idContent)}</p>
                                                </li>
                                            })}
                                        </ul>

                                    </div>
                                }
                            </td>
                            <td>
                                {pago.complementarios && pago.complementarios.length > 0 &&
                                    <div className="submenu">
                                        <DownloadIcon />
                                        <ul className="menu">
                                            {pago.complementarios.map((factura, index) => {
                                                return <li className="factura" key={index} onClick={() => descargarFactura(factura)}>
                                                    <DownloadIcon />                                                    
                                                    <p>{getFileName(factura.idContent)}</p>
                                                </li>
                                            })}
                                        </ul>

                                    </div>
                                }
                            </td>
                        </tr>
                    })}
                    {formatedPagos && formatedPagos.length === 0 && <p>Sin facturas disponibles</p>}
                </tbody>
            </table>
            {/* <div className="list">
                <h3>Lista de pagos</h3>
                {formatedPagos && formatedPagos.map((pago, index) => {
                    return pago.cantidad !== 0 &&  <div className="pago" key={index}>
                        <input disabled={pago.facAnticipada === 1} type="checkbox" onChange={(e) => updateSelectList(e, pago, selectedListPago, setSelectedListPago)} />
                        <div className="info">
                            <div className="top-status">
                                <small className={getEstatus(pago.idEstatusCobro)}>Cantidad: {pago.cantidad}</small>
                                {pago.facAnticipada && pago.facAnticipada === 1 && 
                                    <small className={pago.facAnticipada === 1 ? 'revision' : ''}>Factura Anticipada: Solicitada</small>
                                }
                                <small>Fecha: {moment(pago.createdAt).format('YYYY-MM-DD')}</small>
                                <small className={getEstatus(pago.idEstatusCobro)}>Estatus: {getEstatus(pago.idEstatusCobro)}</small>
                                {pago.idContent &&
                                    <small>pago</small>
                                }

                            </div>
                            <div className="desc">
                                <div className="dato">
                                    <small>Descripción</small>
                                    <p>{pago.descCobroRegistroConvocatoria}</p>
                                </div>
                                <div className="monto">
                                    <small>Monto</small>
                                    <p>{formatCurrency(pago.montoCobro)}</p>
                                </div>
                            </div>
                            {pago.facturas && pago.facturas.length > 0 &&
                                <div className="facturas">
                                    <h4>Facturas Disponibles</h4>
                                    {pago.facturas.map((factura, index) => {
                                        return <div className="factura" key={index}>
                                            <button onClick={() => descargarFactura(factura)}>
                                                <DownloadIcon />
                                            </button>
                                            <p>{getFileName(factura.idContent)}</p>
                                        </div>
                                    })}
                                </div>                            
                            }
                        </div>
                        
                    </div>
                })}
                {formatedPagos && formatedPagos.length === 0 && <p>Sin pagos para mostrar</p>}
            </div> */}
            <ModalDatosFactura data={modalData} cancel={closeModal} convocatoria={convocatoria}/>
        </div>
    )
}