import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatValor, isUserRegistered } from '../app/commons';
import RegistroUsuario from '../components/registro/registroUsuario';
import { appState, changeLogo, setAreaData, setServiceData } from '../slices/appSlice';
import { entidadServicesState, obtenerMisEmpresas, resetEntidad } from '../slices/entidadServicesSlice';
import { getUsuarioPorId, usuariosServicesState } from '../slices/usuariosServicesSlice';
import { setModalUser } from '../slices/usuariosSlice';
import { ReactComponent as EntidadesIcon } from '../assets/icons/empresas-main.svg';
import { ReactComponent as RegistroIcon } from '../assets/icons/registro-main.svg';
import '../styles/inicio.scss';
import { CVE_ESTATUS_ENTIDAD, LOGO_EMPRESA } from '../app/constantes';
import { Link, useNavigate } from 'react-router-dom';
import { getMessageByModulo } from '../services/catalogos';
import { resetInstrumentoState } from '../slices/instrumentoServiceSlice';
import { catalogosServiceState, resetCatalogos } from '../slices/catalogosServiceSlice';
import { resetRegistro } from '../slices/registroSlice';
import { resetConvocatoria } from '../slices/registroConvocatoriaSlice';
import { putUsuario } from '../services/usuarios';

export default function Inicio() {
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const appSt = useSelector(appState);
    const usuariosServiceSt = useSelector(usuariosServicesState);
    const entidadServicesSt = useSelector(entidadServicesState);
    const navigate = useNavigate();
    const [entidadesAprobadas, setEntidadesAprobadas] = useState();
    const [entidadesEnRevision, setEntidadesEnRevision] = useState();
    const [entidadesRegistradas, setEntidadesRegitradas] = useState();
    const [messages, setMessages] = useState();
    const catalogosSt = useSelector(catalogosServiceState);

    const completeInfo = () => {
        dispatch(setModalUser({
            show: true,
            data: appSt.tokenInfo
        }))
    }

    const getMessages = async () => {
        const resp = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'HOME', 'HOME ');
        if (resp.payload && resp.payload.valor) {
            setMessages({...messages, direction: formatValor(resp)});
        }
    }
    useEffect(() => {
        // Obtendremos las empresas que se tienen registradas para pintar el inicio dinamico
        if (appSt.serviceData !== null  && usuariosServiceSt.usuario && usuariosServiceSt.usuario.data) {
            dispatch(obtenerMisEmpresas({
                ...appSt.serviceData,
                idUsuario: usuariosServiceSt.usuario.data.idUsuarioFederado
            }))
            getMessages(appSt.serviceData);
        }
        dispatch(changeLogo(LOGO_EMPRESA.cemefi));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSt.serviceData, usuariosServiceSt.usuario]);

    useEffect(() => {
        if (entidadServicesSt.misEmpresas) {
            const aprobadas = [];
            const enRevision = [];
            const registradas = [];
            entidadServicesSt.misEmpresas.forEach(entidad => {
                if (entidad.cve_estatus_entidad === CVE_ESTATUS_ENTIDAD.confirmada) {
                    aprobadas.push(entidad);
                }
                if (entidad.cve_estatus_entidad === CVE_ESTATUS_ENTIDAD.enRevision) {
                    enRevision.push(entidad);
                }
                if (entidad.cve_estatus_entidad === CVE_ESTATUS_ENTIDAD.registrado) {
                    registradas.push(entidad);
                }
            })
            setEntidadesAprobadas(aprobadas);
            setEntidadesEnRevision(enRevision);
            setEntidadesRegitradas(registradas);
        }

    }, [entidadServicesSt.misEmpresas]);

    const changeArea = async (idArea) => {
        const usuarioBody = {
            idAreaCemefiDefault: idArea,
        }
        await putUsuario({
            ...appSt.serviceData, 
            area: 1, 
            idUsuario: usuariosServiceSt.usuario.data.idUsuario
        }, usuarioBody);
        const clone = JSON.parse(JSON.stringify(appSt.serviceData));
        clone.area = idArea;
        navigate('inicio');
        dispatch(getUsuarioPorId({...appSt.serviceData, area: 1, idUsuario: appSt.tokenInfo.sub,}))
        dispatch(resetInstrumentoState());
        dispatch(resetCatalogos());
        dispatch(resetRegistro());
        dispatch(resetConvocatoria());
        dispatch(resetEntidad());
        dispatch(setServiceData(clone));
        const areaDisp = catalogosSt.listadoAreas.find(item => Number(item.idAreaCemefi) === Number(idArea));
        dispatch(setAreaData(areaDisp));
    }

    return (
        <section className="inicio">
            <div className="wrapper">
                <div className="two-col">                    
                    <div className="welcome-action">
                        {/* Pantalla para cambiar el area */}
                        {appSt.token && usuariosServiceSt != null && isUserRegistered(usuariosServiceSt.usuario) && usuariosServiceSt.usuario.data && usuariosServiceSt.usuario.data.idAreaCemefiDefault === null &&
                            <div className='completar'>
                                <h3>Para continuar, seleccione un área con la que desee iniciar.</h3>
                                <p>posteriormente puede cambiar el area desde el menu de edición de usuario.</p>
                                    {appSt.areasDisponibles && appSt.areasDisponibles.length > 1 &&
                                        <ul className='areas-disponibles'>
                                            {appSt.areasDisponibles.map((area, index) => {
                                                return Number(appSt.serviceData.area) !== Number(area.idAreaCemefi) && <li onClick={() => changeArea(area.idAreaCemefi)} key={index}>{area.descAreaCemefi}</li>
                                            })}                      
                                        </ul>                  
                                    }
                            </div>
                        }
                        {/* Completar registro */}
                        {appSt.token && !isUserRegistered(usuariosServiceSt.usuario) &&
                            <div className='completar'>
                                <h3>Da click en "Completar Registro" para poder continuar.</h3>
                                <button onClick={completeInfo}>Completar Registro</button>
                            </div>
                        }
                        {/* Inicio de sesion */}
                        {!appSt.token && <div className='completar'>
                            <h3>Inicia sesion para continuar</h3>
                            <button onClick={() => keycloak.login()}>Iniciar Sesión</button>
                            <button onClick={() => keycloak.login()}>Registrarse</button>
                        </div>}
                        {/* Pantalla de inicio */}
                        {entidadServicesSt.misEmpresas && usuariosServiceSt.usuario.data && usuariosServiceSt.usuario.data.idAreaCemefiDefault != null && 
                            <div className='resume'>
                                <h5>Resumen de Entidades</h5>
                                {entidadesEnRevision && entidadesEnRevision.length > 0 && 
                                    <div className="card">
                                        <div className="icon">
                                            <EntidadesIcon />
                                        </div>
                                        <div className="info">
                                            <p>{entidadesEnRevision.length}</p>
                                            <div>
                                                <h4>Entidades En Revisión</h4>
                                                <span>En un plazo de 24 a 48 horas se te dara una respuestas de estas entidades</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {entidadesRegistradas && entidadesRegistradas.length > 0 && 
                                    <div className="card">
                                        <div className="icon">
                                            <EntidadesIcon />
                                        </div>
                                        <div className="info">
                                            <p>{entidadesRegistradas.length}</p>
                                            <div>
                                                <h4>Entidades Registradas</h4>
                                                <span>Completa el Registro para poder participar en las convocatorias</span>
                                                <Link to="/mis-entidades">Ir a Mis Entidades</Link>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {entidadesAprobadas && entidadesAprobadas.length > 0 && 
                                    <div className="card">
                                        <div className="icon">
                                            <RegistroIcon />
                                        </div>
                                        <div className="info">
                                            <p>{entidadesAprobadas.length}</p>
                                            <div>
                                                <h4>Entidades Aprobadas</h4>
                                                <span>Ya puedes registrarte, completar o ver estatus de las convocatorias</span>
                                                <Link to='/convocatoria'>Ir a Convocatorias</Link>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {entidadesRegistradas && entidadesRegistradas.length === 0 && 
                                    <div className="card">
                                        <div className="icon">
                                            <EntidadesIcon />
                                        </div>
                                        <div className="info">
                                            <p>{entidadesRegistradas.length}</p>
                                            <div>
                                                <h4>Entidades Registradas</h4>
                                                <span>Crea tu primera entidad, o solicita una vinculación, para poder empezar a participar en las convocatorias.</span>
                                                <Link to="/mis-entidades">Ir a Mis Entidades</Link>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <div className="welcome">
                        <h2><b>Te damos la bienvenida a la plataforma Cemefi</b></h2>
                        {appSt.tokenInfo && !usuariosServiceSt.usuario && <h2>{appSt.tokenInfo.preferred_username}</h2>}
                        {appSt.tokenInfo && usuariosServiceSt.usuario && usuariosServiceSt.usuario.data && <h2>{usuariosServiceSt.usuario.data.nombre} {usuariosServiceSt.usuario.data.apellidoPaterno} {usuariosServiceSt.usuario.data.apellidoMaterno}</h2>}
                        <h3><b>Cemefi</b> habilita y activa la responsabilidad ciudadana para promover valor social y público centrado en las personas</h3>
                    </div>
                </div>
                {/* <div className="vid">
                     <iframe src="https://www.youtube.com/embed/-JGmks4o9qk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> 
                </div> */}
                <footer>
                    {messages && messages.direction &&
                        <div className='wrapper location'>
                            {messages.direction.map((item, index) => {
                                return <p key={index}>{item}</p>
                            })}
                        </div>
                    }
                </footer>
            </div>

            {!isUserRegistered(usuariosServiceSt.usuario) && 
                <RegistroUsuario />
            }
        </section>
    )
}