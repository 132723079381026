import { deleteRequest, getFileRequest, getRequest, postRequest, putRequest } from "./http-service";

const url = process.env.REACT_APP_INSTRUMENTO_SERVICE;
const urlDocs = process.env.REACT_APP_DOCUMENTOS_SERVICE;

export const getObjetivoPregunta = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/objetivo/pregunta-indicador/${data.idPreguntaIndicador}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getTotalCriterio = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/total-criterio/${data.idRegistroConvocatoria}/${data.cveCriterio}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getProgresoCriterio = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/total-respuesta-criterio/${data.idRegistroConvocatoria}/${data.cveCriterio}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getTotalAmbito = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/total-ambito/${data.idRegistroConvocatoria}/${data.cveAmbito}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getProgresoAmbito = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/total-respuesta-ambito/${data.idRegistroConvocatoria}/${data.cveAmbito}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};


export const getTotalIndicador = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/total-indicador/${data.idRegistroConvocatoria}/${data.cveIndicador}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getProgresoIndicador = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/total-respuesta-indicador/${data.idRegistroConvocatoria}/${data.cveIndicador}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getTotalSubAmbito = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/total-sub-ambito/${data.idRegistroConvocatoria}/${data.cveSubambito}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getProgresoSubAmbito = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/total-respuesta-sub-ambito/${data.idRegistroConvocatoria}/${data.cveSubambito}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getInstrumentoByConvocatoriaId = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/${data.idRegistroConvocatoria}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putInstrumentoRegistro = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/${data.idInstrumentoRegistro}`;

    const resp = await putRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getInstrumentoRegistro = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/${data.idInstrumentoRegistro}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getInstrumentoMedicionConvocatoriaById = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoMedicionConvocatoria?idInstrumentoMedicionConvocatoria=${data.idInstrumentoMedicionConvocatoria}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getInstrumento = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumento-medicion/${data.idInstrumentoMedicion}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getInstrumentosByEntidad = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumento-medicion/entidad/${data.idEntidad}/convocatoria/${data.idConvocatoria}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.error) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postInstrumentoRegistro = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro`;

    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

// Preguntas

export const postRespuestaInstrumento = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/respuesta-instrumento-registro`;

    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putRespuestaInstrumento = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/respuesta-instrumento-registro/${data.idRespuestaInstrumentoRegistro}`;

    const resp = await putRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteRespuestaInstrumento = async (data) => {
    const requestUrl = `${url}/area/${data.area}/respuesta-instrumento-registro/${data.idRespuestaInstrumentoRegistro}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postRespuestaInstrumentoNM = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/respuesta-nivel-madurez`;

    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putRespuestaInstrumentoNM = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/respuesta-nivel-madurez/${data.idRespuestaNivelMadurez}`;

    const resp = await putRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postRespuestaInstrumentoNMRegistro = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/respuesta-nm-instrumento-registro`;

    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putRespuestaInstrumentoNMRegistro = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/respuesta-nm-instrumento-registro/${data.idRespuestaNmInstrumentoRegistro}`;

    const resp = await putRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteRespuestaInstrumentoNMRegistro = async (data) => {
    const requestUrl = `${url}/area/${data.area}/respuesta-nm-instrumento-registro/${data.idRespuestaNmInstrumentoRegistro}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postPreguntaInstrumento = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/pregunta-instrumento-registro`;

    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

//https://instrumentosvc-dev.cemefi.org/instrumento/api/v1/area/:idAreaCemefi/pregunta-respuesta-nm-instrumento-registro
export const postPreguntaRespuestaInstrumentoRegistro = async (data) => {
    const requestUrl = `${url}/area/${data.area}/pregunta-respuesta-nm-instrumento-registro`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deletePreguntaInstrumento = async (data) => {
    const requestUrl = `${url}/area/${data.area}/pregunta-instrumento-registro/${data.idPreguntaInstrumentoRegistro}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putPreguntaInstrumento = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/pregunta-instrumento-registro/${data.idPreguntaInstrumentoRegistro}`;

    const resp = await putRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getRegistroInstrumento = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/pregunta-respuesta-instrumento/${data.idRegistroConvocatoria}?`;
    const searchParams = new URLSearchParams(data.query);

    const resp = await getRequest(requestUrl + searchParams, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postEvidenciaRespuesta = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/evidencia-respuesta`;

    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putEvidenciaRespuesta = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/evidencia-respuesta/${data.idEvidenciaRespuesta}`;

    const resp = await putRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getEvidenciaRespuesta = async (data) => {
    const requestUrl = `${url}/area/${data.area}/evidencia-respuesta/respuesta-instrumento-registro/${data.idRespuestaInstrumentoRegistro}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteEvidenciaRespuesta = async (data) => {
    const requestUrl = `${url}/area/${data.area}/evidencia-respuesta/${data.idEvidenciaRespuesta}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postEvidenciaDocument = async (data, body) => {
    const requestUrl = `${urlDocs}/documento-service/${data.area}/entidad/${data.idEntidad}/instrumento-registro/${data.idInstrumentoRegistro}/pregunta-instrumento-registro/${data.idPreguntaInstrumentoRegistro}/respuesta-instrumento-registro/${data.idRespuestaInstrumentoRegistro}/evidencia-respuesta/${data.idEvidenciaRespuesta}`;

    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postEvidenciaNMRespuesta = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/evidencia-respuesta-nm`;

    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putEvidenciaNMRespuesta = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/evidencia-respuesta-nm/${data.idEvidenciaRespuestaNm}`;

    const resp = await putRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteEvidenciaNMRespuesta = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/evidencia-respuesta-nm/${data.idEvidenciaRespuestaNm}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getEvidenciaRespuestaNm = async (data) => {
    const requestUrl = `${url}/area/${data.area}/evidencia-respuesta-nm/respuesta-instrumento-registro/${data.idRespuestaNivelMadurez}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteEvidenciaRespuestaNm = async (data) => {
    const requestUrl = `${url}/area/${data.area}/evidencia-respuesta-nm/${data.idEvidenciaRespuestaNm}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postEvidenciaNMDocument = async (data, body) => {
    const requestUrl = `${urlDocs}/documento-service/${data.area}/entidad/${data.idEntidad}/instrumento-registro/${data.idInstrumentoRegistro}/pregunta-instrumento-registro/${data.idPreguntaInstrumentoRegistro}/respuesta-instrumento-registro/${data.idRespuestaInstrumentoRegistro}/respuesta-nivel-madurez/${data.idRespuestaNivelMadurez}/respuesta-nm-instrumento-registro/${data.idRespuestaNMInstrumentoRegistro}/evidencia-respuesta-nm/${data.idEvidenciaRespuestaNM}`;

    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getInstrumentoReport = async (data) => {
    const requestUrl = `${url}/area/${data.area}/pregunta/instrumento-habilitado/instrumento-medicion/${data.idInstrumentoMedicion}/tipo-respuesta/${data.cveTipoRespuesta}/instrumento-medicion-convocatoria/${data.idInstrumentoMedicionConvocatoria}/instrumento-registro/${data.idInstrumentoRegistro}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getInstrumentoProgressReport = async (data) => {
    const requestUrl = `${url}/area/${data.area}/gestion-avance/registro-convocatoria/${data.idRegistroConvocatoria}`;

    const resp = await getFileRequest(requestUrl, data.token);

    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp;
};

export const getInstrumentoProgressReportJSON = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/pregunta-respuesta-instrumento/${data.idRegistroConvocatoria}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp;
};

//https://instrumentosvc-dev.cemefi.org/instrumento/api/v1/area/:idAreaCemefi/instrumentoRegistro/:idInstrumentoRegistro/fecha-liberacion
export const setFechaLiberacion = async (data, body) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/${data.idInstrumentoRegistro}/fecha-liberacion`;

    const resp = await putRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

//https://instrumentosvc-dev.cemefi.org/instrumento/api/v1/area/:idAreaCemefi/instrumentoRegistro/instrumento-visible/:idInstrumentoRegistro
export const getInstrumentoVisible = async (data) => {
    const requestUrl = `${url}/area/${data.area}/instrumentoRegistro/instrumento-visible/${data.idInstrumentoRegistro}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

//https://instrumentosvc.cemefi.org/instrumento/api/v1/area/1/evidencia-respuesta-nm/809136 
export const getInstrumentoRegistroEvidencia = async (data) => {
    const requestUrl = `${url}/area/${data.area}/evidencia-respuesta-nm/respuesta-nm-instrumento-registro/${data.idRespuestaNmInstrumentoRegistro}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};