import { useSelector } from "react-redux"
import { appState } from "../../slices/appSlice"
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { entidadServicesState } from "../../slices/entidadServicesSlice";
import { instrumentoServicesState } from "../../slices/instrumentoServiceSlice";
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import { usuariosServicesState } from "../../slices/usuariosServicesSlice";
import { pagosState } from "../../slices/pagosSlice";
import { registroConsultorState } from "../../slices/registroConsultor";

export default function Loader() {
    const appSt = useSelector(appState);
    const entidadServices = useSelector(entidadServicesState);
    const usuariosServices = useSelector(usuariosServicesState);
    const catalogosServices = useSelector(catalogosServiceState);
    const convocatoriaServices = useSelector(registroConvocatoriaState);
    const instrumentoSt = useSelector(instrumentoServicesState);
    const pagosSt = useSelector(pagosState);
    const consultorSt = useSelector(registroConsultorState);

    const isActive = 
        appSt.loading || 
        entidadServices.loading || 
        usuariosServices.loading ||
        convocatoriaServices.isLoading ||
        instrumentoSt.loading ||
        pagosSt.loading ||
        consultorSt.loading ||
        catalogosServices.loading 
            ? 'active' : '';

    return (
        <div className={`loader ${isActive}`}>
            <div className="main-loader">
                <span className="spinner"></span>
                <span className="spinner"></span>
            </div>
        </div>
    )
}