/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux"
import { changeModalReport, instrumentoServicesState } from "../../slices/instrumentoServiceSlice"

import SeguimientoInstrumento from "./seguimiento";


export default function InstrumentoReporte() {
    const dispatch = useDispatch();
    const instrumentoSt = useSelector(instrumentoServicesState);

    const closeModal = () => {
        dispatch(changeModalReport({
            show: false,
            data: null
        }))        
    }

    return (
        <div className={instrumentoSt.modalReporte && instrumentoSt.modalReporte.show ? 'modal-wrapper active' : 'modal-wrapper'}>
            <div className="modal full-width modal-reporte">
                <div className="head">
                    <h3>Reporte Instrumento</h3>
                    <button onClick={closeModal}>
                        x
                    </button>
                </div>
                <SeguimientoInstrumento afterSelection={closeModal} />
                <div className="actions">
                    <button onClick={closeModal}>Cerrar</button>
                </div>
            </div>
        </div>
    )
}