import { getRequest, postRequest } from "./http-service";

const url = process.env.REACT_APP_PERSONA_SERVICE_URL;

export const postPersona = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/persona`;
    return await postRequest(requestUrl, data.token, {payload: body});
}

export const getPersona = async (data) => {
    const requestUrl = `${url}area/${data.area}/persona/${data.id}`
    return await getRequest(requestUrl, data.token);
}

export const getPersonaByRfc = async (data, params) => {
    let requestUrl = `${url}area/${data.area}/persona/rfc/${data.rfc}`;
    if (params) {
        requestUrl = `${requestUrl}${params}`
    }
    return await getRequest(requestUrl, data.token);
}

export const getAliadosPersonales = async (data) => {
    const requestUrl = `${url}area/${data.area}/persona/aliados-regionales/entidad/${data.idEntidad}`
    return await getRequest(requestUrl, data.token);
}

export const getConsultores = async (data) => {
    const requestUrl = `${url}area/${data.area}/persona/aliados-regionales/${data.idAliado}/entidad/${data.idEntidad}/consultores-acreditados`
    return await getRequest(requestUrl, data.token);
}