/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from "react"
import {
    changeListaConvocatorias,
    changeView,
    getConvocatorias,
    registroConvocatoriaState,
    selectConvocatoria,
    selectEmpresa,
} from '../../slices/registroConvocatoriaSlice';

import {
    ALERT_FAIL,
    EMPRESAS_LIST,
} from '../../app/constantes';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';

import '../../styles/seleccion-convocatoria.scss';
import ConvocatoriaCard from './convocatoria';
import ConfirmModal from "../generales/confirmModal";
import { AnioConvocatoriaForm } from '../../app/forms/convocatoria/seleccionConvocatoria';
import InputComp from '../generales/inputComponent';
import { validateForm } from '../../app/commons';
import { appState, setAlert, setLoading } from '../../slices/appSlice';
import { getAniosConvocatoria } from '../../services/convocatorias';
import ModalActualizarEntidad from './modal-actualizar';
import moment from 'moment';
import { getDocument, getEntidadesFilter } from '../../services/entidad';

export default function SeleccionConvocatoria() {
    const dispatch = useDispatch();
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const appSt = useSelector(appState);
    const [form, setForm] = useState(JSON.parse(JSON.stringify(AnioConvocatoriaForm)))
    const [anios, setAnios] = useState(null)
    const [registros, setRegistros] = useState(null)
    const [modalData, setModalData] = useState({
        show: false,
        title: '',
        desc: '',
        info: null,
        list: null
    });
    const [modalActualizar, setModalActualizar] = useState({
        show: false,
        data: null
    });

    const goToView = (view, convocatoria) => {
        setAnios(null)
        setForm(JSON.parse(JSON.stringify(AnioConvocatoriaForm)))
        dispatch(changeView(view));
        dispatch(selectEmpresa(null))
        dispatch(changeListaConvocatorias(null))
        dispatch(selectConvocatoria(convocatoria));
    };

    const search = () => {
        const valid = validateForm(form)

        if (valid.length === 0) {
            dispatch(getConvocatorias({
                ...appSt.serviceData,
                idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad,
                query: {
                    anio: form.anio.value
                }
            }))
        } else {
            dispatch(setAlert({
                show: true,
                message: "Favor de llenar los campos requeridos",
                type: ALERT_FAIL
            }))
        }
    }

    // Cierra Modal de confirmacion
    const closeModal = () => {
        setModalData({
            ...modalData,
            show: false
        })
    }

    const getAnios = async () => {
        dispatch(setLoading(true))
        try {
            const resp = await getAniosConvocatoria(appSt.serviceData)
            dispatch(setLoading(false))
            const clone = JSON.parse(JSON.stringify(AnioConvocatoriaForm))
            clone.anio.value = moment().format("YYYY")
            setForm(clone)
            setAnios(resp)
        } catch (error) {  
            console.log(error)            
            dispatch(setLoading(false))
            dispatch(setAlert({
                show: true, 
                message: "Error al consultar años"
            }))
        }
    }

    const formatRegistros = async () => {
        const array = []
        for (const item of registroConvocatoriaSt.listaConvocatorias) {
            
            if (item.logoConvocatoria) {
              const entidadCemefi = await getEntidadesFilter({
                  ...appSt.serviceData,
                  body: {
                    rfc: "CMF881208TV6"
                  }
              })
              const docBase64 = await getDocument({
                ...appSt.serviceData,
                idEntidad: entidadCemefi[0].idEntidad,
                idContent: item.logoConvocatoria
              })
              array.push({...item, base64: docBase64.payload.base64})          
            } else {
              array.push(item)
            }
        }
        setRegistros(array)

    }

    useEffect(() => {
        if (!anios) {
            getAnios()
        }
    }, [registroConvocatoriaSt.empresaSeleccionada])

    useEffect(() => {
        if (registroConvocatoriaSt.listaConvocatorias) {
            if (registroConvocatoriaSt.listaConvocatorias.length > 0) {
                formatRegistros()
                setRegistros(registroConvocatoriaSt.listaConvocatorias)
            } else {
                setRegistros([])
            }
        } else {
            setRegistros(null)
        }
    }, [registroConvocatoriaSt.listaConvocatorias, registroConvocatoriaSt.empresaSeleccionada])
    return (
        <div className="seleccion-convocatoria">
            <div className="wrapper">
                <div className="main-section-title">
                    <button onClick={() => goToView(EMPRESAS_LIST)}>
                        <BackIcon />
                    </button>
                    <div className="text">
                        {registroConvocatoriaSt.empresaSeleccionada &&
                            <h3><b>{registroConvocatoriaSt.empresaSeleccionada.razonSocial}:</b> Convocatorias Disponibles</h3>
                        }
                        <p>
                            Selecciona una convocatoria para participar o ve el
                            estatus de las convocatorias en las que ya estás
                            registrado.
                        </p>
                    </div>
                </div>

                <div className="filters">
                    <h5>Selecciona un Año</h5>
                    {anios && (
                        <div className="four-col">
                            <InputComp 
                                label="Año" 
                                name="anio" 
                                form={form} 
                                setForm={setForm} 
                                catalogos={anios}
                                catalogoLabel={"anio"}
                                catalogoValue={"anio"}
                                type="select" />
                            <button className='primary' onClick={search}>Buscar</button>
                        </div>
                    )}
                </div>
                
                {registros && registros.length === 0 && <p className='empty'>Sin Resultados</p>}

                <div className={registros && registroConvocatoriaSt.empresaSeleccionada ? 'convocatorias-wrapper loaded' : 'convocatorias-wrapper'}>
                    {registros && registroConvocatoriaSt.empresaSeleccionada &&
                        registros.map((convocatoria, index) => (
                            ((convocatoria.cvePrograma.includes("CADENAVALOR") && convocatoria.isCadenaValorVisible) || !convocatoria.cvePrograma.includes("CADENAVALOR")) && <ConvocatoriaCard setModalActualizar={setModalActualizar} convocatoria={convocatoria} key={index} modalData={modalData} setModalData={setModalData} search={search} />
                        ))}
                </div>
            </div>
            <ConfirmModal data={modalData} cancel={closeModal} />
            <ModalActualizarEntidad modalData={modalActualizar} setModal={setModalActualizar} goBack={() => goToView(EMPRESAS_LIST)}/>
        </div>
    );
}