import { useEffect } from "react"

export default function ConfirmModal({data, cancel}) {
    useEffect(() => {
        console.log(data)
    }, [data])
    return (
        <div className={data.show ? 'modal-wrapper active' : 'modal-wrapper'}>
            <div className="modal">
                <div className="head">
                    <h3>{data.title}</h3>
                    <button onClick={cancel}>
                        x
                    </button>
                </div>
                <div className="content">
                    { data.desc && <p>{data.desc}</p> }
                    { data.list && <ul className="no-list">
                        {data.list.map(item => {
                            return <li>{item}</li>
                        })}    
                    </ul>}
                    {data.link && <a href={data.link.url} target="_blank" rel="noreferrer">{data.link.label}</a>}
                </div>
                <div className="actions">
                    <button onClick={cancel}>Cancelar</button>
                    <button onClick={() => data.action(data.info)}>Continuar</button>
                </div>
            </div>
        </div>
    )
}