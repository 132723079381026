
export const registroUsuarioForm = {
    nombre: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    apellidoPaterno: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    apellidoMaterno: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    email: {
        value: '',
        required: true,
        message: 'Email invaildo, ej. nombre@empresa.com',
        tool: '',
        invalid: false,
    },
    emailConfirmar: {
        value: '',
        required: true,
        message: 'Email invaildo, ej. nombre@empresa.com',
        tool: '',
        invalid: false,
    },
    username: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    telefono: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    cvePais: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    cveEstatusUsuario: {
        value: 'ACTIVO',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    rfc: {
        value: '',
        required: true,
        message: 'El RFC debe tener 13 caracteres',
        tool: '',
        invalid: false,
    },
    idAreaCemefiDefault: {
        value: '',
        required: true,
        message: 'Dato Requerido',
        tool: '',
        invalid: false,
    },
}