import { useEffect, useState } from "react";

import '../../styles/template-styles/etapas.scss';
import '../../styles/template-styles/templates.scss';

import convocatoria from '../../assets/images/background-image-100.jpg';

export default function EtapasPreview({ data }) {
    /**
     * Set Configuration state
     */
    const [moduleStyles, setModuleStyles] = useState({
        backgroundColor: '',
    });
    const [contentStyles, setContentStyles] = useState({
        '--textColor': '',
        '--dateColor': '',
        '--etapaBackgroundColor': '',
    });

  /**
   * Check config values
   */
  useEffect(() => {
    if (data) {
        const styles = {
            backgroundColor: data.backgroundColor,            
        };
        if (data.alturaFija) {
            styles.height = `${data.height}px`
        }
        console.log(styles)
        setModuleStyles(styles)
        setContentStyles({
            '--textColor': data.contenido.textColor,
            '--dateColor': data.contenido.dateColor,
            '--etapaBackgroundColor': data.contenido.backgroundColor,
        })
    }
  }, [data])

  return (
      <div className={`${data.layout}-conv`} style={moduleStyles}>
          {data.backgroundImage &&
              <img src={convocatoria} alt="fondo" />
          }
          <div className={data.wrapContent ? 'wrapper-conv' : ''}>
            <div className={`content-conv ${data.contenido.estilo}`} style={contentStyles}>
                {data.contenido.etapas && data.contenido.etapas.map((etapa, index) => {
                    return <div key={index} className="etapa">
                        <div className="etapa-info">
                            <h4>{etapa.fechaInicio} {etapa.fechaFin && <span>- {etapa.fechaFin}</span>}</h4>
                            <p>{etapa.cveEtapa}</p>
                        </div>
                    </div>
                })}
            </div>
          </div>
      </div>
  );
}