import { useEffect, useState } from "react";

import '../../styles/template-styles/cuotas.scss';
import '../../styles/template-styles/templates.scss';

import convocatoria from '../../assets/images/background-image-100.jpg';

export default function CuotasPreview({ data }) {
    /**
     * Set Configuration state
     */
    const [moduleStyles, setModuleStyles] = useState({
        backgroundColor: '',
    });
    const [contentStyles, setContentStyles] = useState({
        '--cardBackground': '',
        '--textColor': '',
        '--modalidadColor': '',
        '--modalidadBackgroundColor': '',
        '--tamanoColor': '',
        '--tamanoBackgroundColor': '',
    });

  /**
   * Check config values
   */
  useEffect(() => {
    if (data) {
        const styles = {
            backgroundColor: data.backgroundColor,            
        };
        if (data.alturaFija) {
            styles.height = `${data.height}px`
        }
        setModuleStyles(styles)
        setContentStyles({
            '--cardBackground': data.contenido.cardBackground,
            '--textColor': data.contenido.textColor,
            '--modalidadColor': data.contenido.modalidadColor,
            '--modalidadBackgroundColor': data.contenido.modalidadBackgroundColor,
            '--tamanoColor': data.contenido.tamanoColor,
            '--tamanoBackgroundColor': data.contenido.tamanoBackgroundColor,
        })
    }
  }, [data])

  return (
      <div className={`${data.layout}-conv`} style={moduleStyles}>
          {data.backgroundImage &&
              <img src={convocatoria} alt="fondo" />
          }
          <div className={data.wrapContent ? 'wrapper-conv' : ''}>
            <div className={`content-conv ${data.contenido.estilo}`} style={contentStyles}>
                {data.contenido.cuotas && data.contenido.cuotas.map((cuota, index) => {
                    return <div key={index} className="cuota">
                        <div className="document'info">
                            <p className="modalidad">{cuota.modalidad.nombre}</p>
                            <p className="nombre">{cuota.nombre}</p>
                            <p className="costo">${cuota.costo}</p>
                            <p className="size">Tamaño de Empresa: {cuota.tamano.nombre}</p>
                        </div>
                    </div>
                })}
            </div>
          </div>
      </div>
  );
}