/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as MoreIcon } from '../assets/icons/more.svg';
import { ReactComponent as EditIcon } from '../assets/icons/edit.svg';
import { ReactComponent as CompleteIcon } from '../assets/icons/completed.svg';
// import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';
import { ReactComponent as FileIcon } from '../assets/icons/file.svg';
import { ReactComponent as EmpresaIcon } from '../assets/icons/empresa.svg';
import { ReactComponent as NuevaEmpresaIcon } from '../assets/icons/nueva-empresa.svg';

import '../styles/mis-empresas.scss';
import ConfirmModal from '../components/generales/confirmModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appState, changeLogo, setAlert, setLoading } from '../slices/appSlice';
import { entidadServicesState, obtenerMisEmpresas, selectSedePrincipal } from '../slices/entidadServicesSlice';
import { ALERT_FAIL, ALERT_SUCCESS, CONVOCATORIAS_DISPONIBLES, CVE_ESTATUS_ENTIDAD, CVE_TIPODOC_ENTIDAD, ESTATUS_REGISTRO, LOGO_EMPRESA, NOTIFICACIONES, TIPOS_GRUPO } from '../app/constantes';
import { registroState, setSede, updateEmpresaSeleccionada } from '../slices/registroSlice';
import { getEntidad, getRegistroEntidadDocument, postCambioEstatusEntidad } from '../services/entidad';
import { usuariosServicesState } from '../slices/usuariosServicesSlice';
import ModalRegistroCorporativo from '../components/registro/modal-registro-corporativo';
import { catalogosServiceState, getDocumentosCatalogo, getEstatusCorpSede } from '../slices/catalogosServiceSlice';
import { formatValor, validateDocs, validateIndustria } from '../app/commons';
import aviso from '../assets/AvisoDePrivacidad.pdf';
import { changeView, updateEmpresaSeleccionada as updateConvocatoriaEmpresaSeleccionada } from '../slices/registroConvocatoriaSlice';
import { postNotificacion } from '../services/notificaciones';
import { getCatalogos, getMessageByModulo } from '../services/catalogos';
import { changeEsquemaCurso, setCurrentConvocatoriaCurso } from '../slices/pagosCursosSlice';

export default function MisEmpresas() {
    // Redux States
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appSt = useSelector(appState);
    const registroSt = useSelector(registroState);
    const ususarioSt = useSelector(usuariosServicesState);
    const entidadServicesSt = useSelector(entidadServicesState);
    const catalgosSt = useSelector(catalogosServiceState);
    const [misEntidades, setMisEntidades] = useState(null);
    const [catCursos, setCatCursos] = useState([]);

    // Local States
    const [validacionMessage, setValidacionMessage] = useState(null);
    const [entidadPadre, setEntidadPadre] = useState(null);
    const [enitdadesDisponiblesSinMembresia, setEntidadesDispSinMem] = useState([]);
    const [enitdadesDisponiblesConMembresia, setEntidadesDispConMem] = useState([]);
    // const [corporativoActivo, setCorporativoActivo] = useState(false);
    // const [miembroActivo, setMiembroActivo] = useState(false);

    /**
     * Modal de confirmacion para validacion
     */
    const [modalData, setModalData] = useState({
        show: false,
        title: '',
        desc: '',
        info: null,
        list: null
    });

    /**
     * Modal Corporativo/Miembros
     */
    const [modalCorporativoData, setModalCorporativoData] = useState({
        show: false,
        data: null
    })

    // Cierra Modal de confirmacion
    const closeModalCorporativo = () => {
        setModalCorporativoData({
            ...modalCorporativoData,
            show: false
        })
        const data = {
            idUsuario: appSt.tokenInfo.sub,
            ...appSt.serviceData
        }
        dispatch(obtenerMisEmpresas(data));
    }

    /**
     * Se abre el modal de corporativo/miembro
     * @param {*} empresa 
     */
    const openModalGrupo = (type) => {
        setModalCorporativoData({
            show: true,
            type
        })
    }

    // Cierra Modal de confirmacion
    const closeModal = () => {
        setModalData({
            ...modalData,
            show: false
        })
    }

    // Confirmar Eliminacion
    // const confirmDelete = (idEntidad) => {
    //     setModalData({
    //         show: true,
    //         title: 'Eliminar Entidad',
    //         desc: '¿Está seguro que desea  eliminar esta entidad, su cambio será irreversible?',
    //         info: idEntidad,
    //         action: () => deleteEmpresa(idEntidad)
    //     })
    // }

    /**
     * Eliminacion de una entidad
     * @param {*} idEntidad 
     */
    // const deleteEmpresa = async (idEntidad) => {
    //     dispatch(setLoading(true));
    //     const resp = await deleteEntidad({
    //         area: ususarioSt.usuario.data.idAreaCemefi,
    //         token: appSt.token,
    //         idEntidad
    //     });

    //     if (resp.payload) {
    //         dispatch(setLoading(false));
    //         closeModal();
    //         const data = {
    //             idUsuario: appSt.tokenInfo.sub,
    //             token: appSt.token
    //         }
    //         dispatch(obtenerMisEmpresas(data));
    //     }

    // }

    /**
     * Editamos una entidad
     * @param {*} empresa 
     */
    const editEmpresa = (empresa) => {
        const data = {
            ...appSt.serviceData,
            idEntidad: empresa.id_entidad
        }
        if (empresa.id_entidad_padre !== null && empresa.id_entidad_padre !== '' && empresa.pertenece === 'SEDE') {
            dispatch(setSede(true))
        } else {
            dispatch(setSede(false))
        }
        dispatch(updateEmpresaSeleccionada(data));
    }


    /**
     * Se envia a validar un entidad
     * @param {*} idEntidad 
     */
    const sendToValidate = async (idEntidad) => {
        dispatch(setLoading(true));
        const putData = {
            ...appSt.serviceData,
            idEntidad
        }
        const body = {
            idEstatusEntidad: catalgosSt.idsEstatusEntidad.enRevision
        }
        const resp = await postCambioEstatusEntidad(putData, body);
        if (resp.payload) {
            closeModal();
            dispatch(setAlert({
                show: true,
                message: 'Solicitud Enviada',
                type: ALERT_SUCCESS
            }))
            const data = {
                idUsuario: appSt.tokenInfo.sub,
                ...appSt.serviceData
            }
            dispatch(obtenerMisEmpresas(data));
            dispatch(setLoading(false));
            postNotificacion(appSt.serviceData, {
                to: [
                    { email: ususarioSt.usuario.data.email, name: ususarioSt.usuario.data.nombre }
                ],
                templateId: NOTIFICACIONES.templatesIds.revisionRegistroEntidad
            })
        } else {
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: 'Error al Enviar Solicitud',
                type: ALERT_FAIL
            }))
        }
    }

    /**
     * Descargamos archivo de registro
     * @param {*} idEntidad 
     */
    const donwloadRegistroDoc = async (idEntidad) => {
        dispatch(setLoading(true));
        const blob = await getRegistroEntidadDocument({ ...appSt.serviceData, idEntidad });
        if (blob) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Registro';
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
        dispatch(setLoading(false));
    }

    // Confirmar Enviar a Validacion
    const confirmValidation = async (idEntidad) => {
        dispatch(setLoading(true));
        const entidadData = await getEntidad({
            ...appSt.serviceData,
            idEntidad
        });

        if (entidadData.payload) {
            dispatch(setLoading(false));
            const informacionGeneralCompleted = entidadData.payload.cemDireccionEntidads.length >= 2;
            const datosContactoCompleted = entidadData.payload.cemContactoCrcEntidads.length > 1;
            const datosIndustriaCompleted = validateIndustria(entidadData.payload);
            const documentosCompleted = validateDocs(catalgosSt.tipoDocumentos, entidadData.payload, catalgosSt.idsTipoDocumentoCemefi && catalgosSt.idsTipoDocumentoCemefi.membresia ? catalgosSt.idsTipoDocumentoCemefi.membresia : null);

            if (
                informacionGeneralCompleted &&
                datosContactoCompleted &&
                datosIndustriaCompleted &&
                documentosCompleted
            ) {
                setModalData({
                    show: true,
                    title: 'Validar Registro de Empresa',
                    list: validacionMessage,
                    link: {
                        label: 'Al continuar estas aceptando nuestro Aviso de Privacidad, da clic aqui para descargarlo',
                        url: aviso
                    },
                    action: () => sendToValidate(idEntidad)
                })
            } else {
                const faltantes = [];
                if (!informacionGeneralCompleted) { faltantes.push('Datos de Generales') }
                if (!datosContactoCompleted) { faltantes.push('Datos de Contacto') }
                if (!datosIndustriaCompleted) { faltantes.push('Datos de Industria') }
                if (!documentosCompleted) { faltantes.push('Documentos') }

                dispatch(setAlert({
                    show: true,
                    message: `Falta información del registro (${faltantes.join()})`,
                    type: ALERT_FAIL
                }))
            }
        } else {
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: 'Error al validar el registro',
                type: ALERT_FAIL
            }))
        }
    }

    /**
     * Label si es empresa o sede
     * @param {*} empresa 
     * @returns 
     */
    const isEmpresaSede = (empresa) => {
        if (empresa.id_entidad_padre !== null) {
            return `Pertenece a ${empresa.pertenece}` 
        }
        if (empresa.is_corporativo) {
            return 'Empresa Principal Corporativo'
        }

        if (empresa.is_sede_marca) {
            return 'Empresa Principal Sede'
        }

        if (empresa.is_miembro) {
            return 'Empresa Principal de Miembros'
        }
    }

    /**
     * Abrimos convocatoria
     * @param {*} entityObj 
     */
    const openConvocatorias = (entityObj) => {
        const data = {
            ...appSt.serviceData,
            idEntidad: entityObj.id_entidad
        }
        dispatch(updateConvocatoriaEmpresaSeleccionada(data));
        // dispatch(getConvocatorias(data));
        dispatch(changeView(CONVOCATORIAS_DISPONIBLES));
        navigate('/convocatoria');
    }

    const getValidationMessage = async () => {
        const resp = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'REGISTRO', 'VALIDARREGISTRO');
        if (resp && resp.payload && resp.payload.valor) {
            setValidacionMessage(formatValor(resp));
        }
    }

    const irACursos = (entityObj) => {
        const data = {
            ...appSt.serviceData,
            idEntidad: entityObj.id_entidad
        }
        dispatch(setCurrentConvocatoriaCurso(null));
        dispatch(updateConvocatoriaEmpresaSeleccionada(data));
        navigate(`/${entityObj.id_entidad}/cursos`);
    }
    
    const irAPagosCursos = async (entityObj) => {
        const data = {
            ...appSt.serviceData,
            idEntidad: entityObj.id_entidad
        }
        dispatch(setCurrentConvocatoriaCurso(null));
        dispatch(updateConvocatoriaEmpresaSeleccionada(data));
        const esquemaCobros = await getCatalogos(appSt.areaData.idAreaCemefi, "esquemaCobro", appSt.serviceData.token, null);
        dispatch(changeEsquemaCurso(esquemaCobros));
        navigate(`/${entityObj.id_entidad}/pagosCursos`);
    }

    /**
     * Abrimos sede
     */
    const goToSedes = () => {
        navigate("/sedes");
    }

    /**
     * Validaciones
     * @param {*} empresa 
     * @returns 
     */
    const estatusCorp = (empresa) => {
        return empresa.cveEstatusCorporativoSede ? empresa.cveEstatusCorporativoSede.toLowerCase() : '';
    }

    const isConfirmada = (empresa) => {       
        const isSedeConfirmada = (!empresa.is_sede_marca && !empresa.id_entidad_padre  && !empresa.is_miembro && !empresa.is_corporativo) || 
            ((empresa.is_sede_marca === 1 || empresa.is_corporativo === 1 || empresa.is_miembro === 1) && catalgosSt.idsEstatusCorpSede && empresa.id_estatus_corporativo_sede === catalgosSt.idsEstatusCorpSede.confirmada);
        return empresa.cve_estatus_entidad === CVE_ESTATUS_ENTIDAD.confirmada && isSedeConfirmada
    }

    const isCorpDisabled = () => {
        return (!entidadPadre && enitdadesDisponiblesSinMembresia.length >= 2) || 
        (entidadPadre && entidadPadre.is_corporativo)
    }

    const isMembresiaDisabled = () => {
        return (!entidadPadre && enitdadesDisponiblesConMembresia.length >= 2) || 
        (entidadPadre && entidadPadre.is_miembro)
    }

    const isSedeDisabled = () => {
        console.log(entidadPadre, enitdadesDisponiblesSinMembresia)
        return (!entidadPadre && enitdadesDisponiblesSinMembresia.length > 0) || 
        (entidadPadre && entidadPadre.is_sede_marca)
    }

    useEffect(() => {
        const obtenerCat = async () => {
            const cursosT = await getCatalogos(appSt.areaData.idAreaCemefi, "curso", appSt.serviceData.token, null);
            setCatCursos(cursosT);
        }
        obtenerCat();
    }, [])

    /**
     * Setteamos informacion inicial
     */
    useEffect(() => {
        if (appSt.token !== null && appSt.serviceData) {
            const data = {
                idUsuario: appSt.tokenInfo.sub,
                ...appSt.serviceData
            }
            dispatch(obtenerMisEmpresas(data));
            dispatch(selectSedePrincipal(null));
            dispatch(setSede(false))
        }
        if (catalgosSt.tipoDocumentos === null && appSt.serviceData) {
            dispatch(getDocumentosCatalogo({
                ...appSt.serviceData,
                cveTipoDocumento: CVE_TIPODOC_ENTIDAD
            }));
        }
        if (!catalgosSt.estatusCorpSede && appSt.serviceData) {
            dispatch(getEstatusCorpSede(appSt.serviceData))
        }
        dispatch(changeLogo(LOGO_EMPRESA.cemefi));

        if (!validacionMessage && appSt.serviceData) {
            getValidationMessage();
        }

    }, [appSt.token, appSt.serviceData]);

    useEffect(() => {
        if (registroSt.empresaSeleccionada) {
            navigate('/registro');
        }
    }, [registroSt.empresaSeleccionada]);

    useEffect(() => {
        if (entidadServicesSt.misEmpresas && entidadServicesSt.misEmpresas.length > 0 && catalgosSt.idsEstatusEntidad) {
            const entidadesActivas = entidadServicesSt.misEmpresas.filter(ent => ent.id_estatus_registro === 1);
            const entidadPadre = entidadesActivas.find((entidad) => {
                return entidad.is_corporativo || entidad.is_miembro || entidad.is_sede_marca
            })
            const conMembresia = entidadesActivas.filter((entidad) => {
                return entidad.tieneMembresia === '1' && entidad.disponible
            })
            const sinMembresia = entidadesActivas.filter((entidad) => {
                return entidad.tieneMembresia === '0' && entidad.disponible
            })
            console.log(entidadesActivas)
            setEntidadPadre(entidadPadre);
            setEntidadesDispConMem(conMembresia)
            setEntidadesDispSinMem(sinMembresia)
            setMisEntidades(entidadesActivas);
        }

    }, [entidadServicesSt.misEmpresas, catalgosSt.idsEstatusEntidad])
    
    return appSt.token && (
        <section className="mis-empresas">
            <div className="wrapper">
                <div className="main-section-title">
                    <EmpresaIcon />
                    <div className="text">
                        <h3>Mis Entidades</h3>
                        <p>Aquí aparecerán las empresas que tienes registradas y su estatus, una vez aprobada la empresa, ya no se podrá editar su información y podrás proceder a registrarte a una convocatoria.</p>
                    </div>
                </div>
                <div className="table-actions">
                    <button onClick={() => navigate('/registro')} className='btn-icon'>
                        <NuevaEmpresaIcon />
                        <p>Nueva Entidad</p>
                    </button>
                    <div className='btn-icon sublist'>
                        <NuevaEmpresaIcon />
                        <p>Gestión de Grupo</p>

                        <ul>                            
                            <li>
                                <button disabled={!isCorpDisabled()} onClick={() => openModalGrupo(TIPOS_GRUPO.corp)}>Grupo Corporativo</button>
                            </li>
                            <li>
                                <button disabled={!isSedeDisabled()} onClick={goToSedes}>Grupo Sede</button>
                            </li>
                            <li>
                                <button disabled={!isMembresiaDisabled()} onClick={() => openModalGrupo(TIPOS_GRUPO.membresia)}>Grupo Miembros</button>
                            </li>
                        </ul>
                    </div>
                </div>
                {(!misEntidades || misEntidades.length === 0) && <p>Sin Entidades</p>}
                {misEntidades && misEntidades.length > 0 &&
                    <table>
                        <thead>
                            <tr>
                                <th>Razón Social</th>
                                <th>Nombre Comercial</th>
                                <th>RFC</th>
                                <th>Validación del Registro</th>
                                <th>Validación Sede/Corporativo</th>
                                <th>Corporativo/Sede</th>
                                <th>Perfil</th>
                                {/* <th>Estatus</th> */}
                                <th>Estatus Vinculación Entidad</th>
                                <th>Motivo de Rechazo</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {misEntidades.map((empresa, index) => {
                                return empresa.id_estatus_registro !== 0 &&
                                    <tr key={index}>
                                        <td>{empresa.razon_social}</td>
                                        <td>{empresa.nombre_comercial}</td>
                                        <td>{empresa.rfc}</td>
                                        <td className={empresa.id_estatus_entidad === catalgosSt.idsEstatusEntidad.confirmada ? 'aprobado' : empresa.id_estatus_entidad === catalgosSt.idsEstatusEntidad.rechazada ? 'rechazado' : 'validacion'}>
                                            {empresa.desc_estatus_entidad}
                                        </td>
                                        <td className={estatusCorp(empresa)}>
                                            {empresa.descEstatusCorporativoSede}
                                        </td>
                                        <td>{isEmpresaSede(empresa)}</td>
                                        <td>{empresa.desc_perfil}</td>
                                        <td>{empresa.desc_estatus_registro && empresa.id_usuario !== null && empresa.id_usuario !== ususarioSt.usuario.data.idUsuario ? empresa.desc_estatus_registro : ''}</td>
                                        <td>{empresa.motivo_rechazo}</td>
                                        <td className='acciones'>
                                            {(empresa.cve_estatus_registro !== ESTATUS_REGISTRO.enVinculacion && empresa.cve_estatus_registro !== ESTATUS_REGISTRO.rechazada) &&
                                                <>
                                                    <button><MoreIcon /></button>
                                                    <ul>
                                                        {isConfirmada(empresa) &&
                                                            <>
                                                                <li onClick={() => openConvocatorias(empresa)}>
                                                                    <FileIcon />
                                                                    <p>Ir a convocatorias</p>
                                                                    {/* <Link to="/convocatoria">
                                                                    </Link> */}
                                                                </li>

                                                                {catCursos.length > 0
                                                                    ? <>
                                                                        <li onClick={() => irACursos(empresa)}>
                                                                            <EditIcon />
                                                                            Cursos
                                                                        </li>
                                                                        <li onClick={() => irAPagosCursos(empresa)}>
                                                                            <EditIcon />
                                                                            Pagos Cursos
                                                                        </li>
                                                                    </>
                                                                    : <></>
                                                                }

                                                                <li onClick={() => donwloadRegistroDoc(empresa.id_entidad)}>
                                                                    <EditIcon />
                                                                    Descargar Registro
                                                                </li>
                                                            </>
                                                        }
                                                        {(empresa.cve_estatus_entidad === CVE_ESTATUS_ENTIDAD.registrado || empresa.cve_estatus_entidad === CVE_ESTATUS_ENTIDAD.rechazada) &&
                                                            <>
                                                                <li onClick={() => editEmpresa(empresa)}>
                                                                    <EditIcon />
                                                                    Editar
                                                                </li>
                                                                <li onClick={() => confirmValidation(empresa.id_entidad)}>
                                                                    <CompleteIcon />
                                                                    <p>Validar Registro</p>
                                                                </li>
                                                            </>
                                                        }
                                                        {(empresa.cve_estatus_entidad === CVE_ESTATUS_ENTIDAD.enRevision || empresa.id_entidad_padre) &&
                                                            <li onClick={() => donwloadRegistroDoc(empresa.id_entidad)}>
                                                                <EditIcon />
                                                                Descargar Registro
                                                            </li>
                                                        }
                                                        {/* <li onClick={() => confirmDelete(empresa.id_entidad)}>
                                                            <DeleteIcon />
                                                            <p>Eliminar</p>
                                                        </li> */}
                                                    </ul>
                                                </>
                                            }
                                        </td>
                                    </tr>
                            })
                            }
                        </tbody>
                    </table>
                }

                <ConfirmModal data={modalData} cancel={closeModal} />
                <ModalRegistroCorporativo data={modalCorporativoData} cancel={closeModalCorporativo} />
            </div>
        </section>
    )
}