import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../app/commons';
import {
    CUOTA_CONCEPTO,
    ESCULTURA_CONCEPTO,
    ESTATUILLA_CONCEPTO,
    RETROALIMENTACION_CONCEPTO,
} from '../../../app/constantes';
import { ReactComponent as RetroIcon } from '../../../assets/icons/retro.svg';
import { getCobroRegistroConvocatoria } from '../../../services/convocatorias';
import { appState } from '../../../slices/appSlice';
import { registroConvocatoriaState } from '../../../slices/registroConvocatoriaSlice';

import { CobrosEstatuillas } from './CobrosEstatuillas';

export function Cobros({ conceptos, handleChange, entidad, retroTip, estatuillaTip, setMontoDescuento }) {
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const appSt = useSelector(appState);

    const [cobroExistente, setCobroExistente] = useState(null);

    const [input, setInput] = useState({
        retro: false,
        estatuillas: 0,
    });

    const conceptosObj = useRef({});

    /*TODO: definir cveEstatusCobro, cveEsquemaCobro y idRegistroConvocatoria, quién decide cuál se va a usar? La convocatoria? */
    const getMappedObj = (concepto) => {
        const registro = entidad.cemRegistroConvocatorias.find(
            (reg) =>
                reg.idConvocatoria ===
                registroConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria
        );
        if (registro) {
            return {
                idRegistroConvocatoria: registro.idRegistroConvocatoria,
                cveEsquemaCobro: 'OFFLINE',
                cveEstatusCobro: 'REGISTRADO',
                idConceptoCobroConvocatoria:
                    concepto.idConceptoCobroConvocatoria,
                descCobroRegistroConvocatoria:
                    concepto.descConceptoCobroConvocatoria,
                montoCobro: concepto.monto,
                idAreaCemefi: entidad.idAreaCemefi,
                idCobroRegistroConvocatoria: cobroExistente
                    ? cobroExistente.idCobroRegistroConvocatoria
                    : null,
            };
        }
    };

    const handleRetroChange = (concepto) => {
        setInput((prev) => ({ ...prev, retro: !prev.retro }));

        if (
            !conceptosObj.current.hasOwnProperty(
                concepto.idConceptoCobroConvocatoria
            )
        ) {
            conceptosObj.current[concepto.idConceptoCobroConvocatoria] =
                getMappedObj(concepto);
        }

        const obj = conceptosObj.current[concepto.idConceptoCobroConvocatoria];
        if (!input.retro) {
            obj.cantidad = 1;
        } else {
            obj.cantidad = 0;
        }
        handleChange(entidad.idEntidad, conceptosObj.current);
    };
    const sendEstatuaData = (concepto) => {
        if (
            !conceptosObj.current.hasOwnProperty(
                concepto.idConceptoCobroConvocatoria
            )
        ) {
            conceptosObj.current[concepto.idConceptoCobroConvocatoria] =
                getMappedObj(concepto);
        } else if (
            conceptosObj.current.hasOwnProperty(concepto.idConceptoCobroConvocatoria) &&
            conceptosObj.current[concepto.idConceptoCobroConvocatoria] === undefined
        ) {
            conceptosObj.current[concepto.idConceptoCobroConvocatoria] =
                getMappedObj(concepto);
        }

        const obj = conceptosObj.current[concepto.idConceptoCobroConvocatoria];

        if (obj) {
            obj.cantidad = input.estatuillas;
        }

        handleChange(entidad.idEntidad, conceptosObj.current);
    };
    const handleEstatuaChange = (action) => {
        if (action === 'add') {
            setInput((prev) => {
                return {
                    ...prev,
                    estatuillas: prev.estatuillas + 1,
                };
            });
        } else {
            setInput((prev) => {
                return {
                    ...prev,
                    estatuillas: prev.estatuillas - 1,
                };
            });
        }
    };

    const populateData = async () => {
        const registro = entidad.cemRegistroConvocatorias.find(
            (reg) =>
                reg.idConvocatoria ===
                registroConvocatoriaSt.convocatoriaSeleccionada.idConvocatoria
        );

        if (registro) {
            const data = {
                ...appSt.serviceData,
                idRegistroConvocatoria: registro.idRegistroConvocatoria,
            };
            try {
                const resp = await getCobroRegistroConvocatoria(data);
                const retro = resp.find((item) =>
                    item.descCobroRegistroConvocatoria.includes(
                        RETROALIMENTACION_CONCEPTO
                    )
                );
                const descuento = resp.find((item) => {
                    let monto = null;
                    if (item.idConceptoCobroConvocatoria2 && item.idConceptoCobroConvocatoria2.idConceptoCobro2 && item.idConceptoCobroConvocatoria2.idConceptoCobro2.cveConceptoCobro) {
                        return item.idConceptoCobroConvocatoria2.idConceptoCobro2.cveConceptoCobro.includes(
                            CUOTA_CONCEPTO
                        )
                    }                  
                    return monto;
                });
                const estatuillas = resp.find((item) =>
                    item.descCobroRegistroConvocatoria.includes(
                        ESTATUILLA_CONCEPTO
                    ) ||
                    item.descCobroRegistroConvocatoria.includes(
                        ESCULTURA_CONCEPTO
                    )
                );
                if (descuento && setMontoDescuento) {
                    setMontoDescuento(descuento.montoCobro);
                }
                let clone = JSON.parse(JSON.stringify(input));
                setCobroExistente(resp);

                if (retro) {
                    clone.retro = retro.cantidad > 0;
                    const concepto = {
                        idConceptoCobroConvocatoria:
                            retro.idConceptoCobroConvocatoria,
                        descConceptoCobroConvocatoria:
                            retro.descConceptoCobroConvocatoria,
                        monto: retro.montoCobro,
                    };
                    conceptosObj.current[retro.idConceptoCobroConvocatoria] =
                        getMappedObj(concepto);
                    conceptosObj.current[
                        retro.idConceptoCobroConvocatoria
                    ].idCobroRegistroConvocatoria =
                        retro.idCobroRegistroConvocatoria;
                    conceptosObj.current[
                        retro.idConceptoCobroConvocatoria
                    ].descCobroRegistroConvocatoria =
                        retro.descCobroRegistroConvocatoria;
                    conceptosObj.current[
                        retro.idConceptoCobroConvocatoria
                    ].cantidad = retro.cantidad;
                }
                if (estatuillas) {
                    clone.estatuillas = estatuillas.cantidad;
                    conceptosObj.current[
                        estatuillas.idConceptoCobroConvocatoria
                    ].idCobroRegistroConvocatoria =
                        estatuillas.idCobroRegistroConvocatoria;
                }
                setInput(clone);

                handleChange(entidad.idEntidad, conceptosObj.current);
            } catch (error) {
                console.log('error al traer los datos', error);
            }
        }
    };

    useEffect(() => {
        if (entidad.cemRegistroConvocatorias.length > 0) {
            populateData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {input &&
                conceptos.map((concepto, index) =>
                    concepto.descConceptoCobroConvocatoria.includes(
                        RETROALIMENTACION_CONCEPTO
                    ) ? (
                        <div className="chip" key={index}>
                            <div className="tooltip">
                                {retroTip && <span>{retroTip}</span>}
                                <RetroIcon />
                            </div>
                            <div className="text">
                                <label>
                                    {concepto.descConceptoCobroConvocatoria}
                                </label>
                                <p>{formatCurrency(concepto.monto)}</p>
                            </div>
                            <input
                                checked={input.retro}
                                type="checkbox"
                                onChange={() => handleRetroChange(concepto)}
                            />     
                        </div>
                    ) : (
                        <CobrosEstatuillas
                            tip={estatuillaTip}
                            concepto={concepto}
                            input={input.estatuillas}
                            key={index}
                            handleEstatuaChange={handleEstatuaChange}
                            sendEstatuaData={sendEstatuaData}
                        />
                    )
                )}
        </>
    );
}