/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';

import { changeView, registroConvocatoriaState } from '../../slices/registroConvocatoriaSlice';
import { ALERT_FAIL, ALERT_SUCCESS, CONVOCATORIAS_DISPONIBLES, CVE_ESTATUS_RELACION, GENERAL_TABLE, PROGRAMAS } from '../../app/constantes';
import { useEffect, useState } from 'react';
import { getEntidadToInvite, getEntidadesInvitadas } from '../../services/convocatorias';
import { appState, setAlert, setLoading } from '../../slices/appSlice';
import { BuscarRfcForm, FilterInvitadas } from '../../app/forms/convocatoria/buscarRfc';
import InputComp from '../generales/inputComponent';
import '../../styles/invitaciones.scss';
import { deleteEntidadInivitada, deleteRelacionPrograma, downloadInvitadasReport, inviteEntity, putTriggerDescuento } from '../../services/entidad';
import { catalogosServiceState, getCatalogoDocumentosPromotora, getCatalogoDocumentosRelacion, getEstatusRelacionPrograma } from '../../slices/catalogosServiceSlice';
import ModalCartaProvedura from './modal-carta-provedura';
import ProveeduriaDoc from '../../assets/proveeduria-requisitos.docx';
import EPDoc from '../../assets/CARTA_INVITACION-EP.docx';
import GeneralTable from '../generales/generalTable';
import ConfirmModal from '../generales/confirmModal';
import ModalReporteResultados from './modal-reporte-resultados';

export default function InvitacionesView({ empresaSeleccionada }) {
    const dispatch = useDispatch()
    const appSt = useSelector(appState);
    const catalogosSt = useSelector(catalogosServiceState);
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const [form, setForm] = useState(JSON.parse(JSON.stringify(BuscarRfcForm)))
    const [formFilter, setFormFilter] = useState(JSON.parse(JSON.stringify(FilterInvitadas)))
    const [registros, setRegistros] = useState(null)
    const [tableFormat, setTableFormat] = useState(null)
    const [tableActions, setTableActions] = useState(null)
    const [type, setType] = useState(PROGRAMAS.cv)
    const [modalData, setModalData] = useState({
        show: false,
        data: null
    })
    const [modalReporte, setModalReporte] = useState({
        show: false,
        data: null
    })
    const [confimModal, setConfirmModal] = useState({
        show: false,
        title: 'Continuar',
        desc: '¿Esta seguro que desea eliminar el registro?',
    })

    const closeModal = () => {
        setConfirmModal({
            ...confimModal,
            show: false,
        })
    }

    const goBack = () => {
        dispatch(changeView(CONVOCATORIAS_DISPONIBLES));
    }

    // Confirmar Continuar
    const confirmContinue = (registro) => {
        setConfirmModal({
            ...confimModal,
            show: true,
            info: registro,
            action: (registro) => deleteInvitacion(registro)
        })
    }

    const getRegistros = async () => {
        dispatch(setLoading(true))
        try {
            const resp = await getEntidadesInvitadas({
                ...appSt.serviceData,
                idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
                body: {
                    cveEstatusInscripcion: formFilter.cveEstatusInscripcion.value !== "" ? formFilter.cveEstatusInscripcion.value : null
                }
            })
            setRegistros(resp)
            dispatch(setLoading(false))
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))
        }
    }

    const changeEstatus = async (array, cveEstatusReferencia) => {
        dispatch(setLoading(true))
        try {
            const promises = []
            for (const obj of array) {
                promises.push(inviteEntity({
                    ...appSt.serviceData,
                    body: {
                        idRegistroConvocatoria: obj.idRegistroConvocatoria,
                        idRegistroConvocatoriaReferenciada: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
                        idEntidad: obj.idEntidad,
                        tpOrigen: "PROGRAMA",
                        cveEstatusReferencia
                    }
                }))
                if (type === PROGRAMAS.cv) {
                    promises.push(putTriggerDescuento({
                        ...appSt.serviceData,
                        body: {
                            idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria
                        }
                    }))
                }
            }
            await Promise.allSettled(promises)
            setAlert({
                show: true,
                message: cveEstatusReferencia === CVE_ESTATUS_RELACION.disponible ? "Entidad Invitada" : "Enviada a validar",
                type: ALERT_SUCCESS
            })
            dispatch(setLoading(false))
            await getRegistros()
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))
            setAlert({
                show: true,
                message: "Error al cambiar estatus",
                type: ALERT_FAIL
            })
        }
    }

    const deleteInvitacion = async (registro) => {
        dispatch(setLoading(true))
        try {
            await deleteEntidadInivitada({
                ...appSt.serviceData,
                idEntidadRegistroConvocatoria: registro.idEntidadRegistroConvocatoria
            })
            await deleteRelacionPrograma({
                ...appSt.serviceData,
                idRelacionPrograma: registro.idRelacionPrograma
            })
            dispatch(setLoading(false))
            setAlert({
                show: true,
                message: "Éxito al guardar la información",
                type: ALERT_SUCCESS
            })
            setConfirmModal({
                ...confimModal,
                show: false
            })
            getRegistros()
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))
            setAlert({
                show: true,
                message: "Error al guardar información",
                type: ALERT_FAIL
            })
        }
    }


    const checkResponse = (obj) => {
        if (Number(obj[0].tieneConvPorInvitacion)) {
            dispatch(setAlert({
                show: true,
                message: "El RFC  ya está asociado a una Cadena de Valor",
                type: ALERT_FAIL
            }))
        }
        if (Number(obj[0].tieneMembresiaEntida)) {
            dispatch(setAlert({
                show: true,
                message: "El RFC  no se puede asociar por que cuenta con Membresia",
                type: ALERT_FAIL
            }))

        }
        if (Number(obj[0].tieneCobroAplicado)) {
            dispatch(setAlert({
                show: true,
                message: "El RFC  no se puede asociar por que ya cuenta con un cobro aplicado",
                type: ALERT_FAIL
            }))

        }

        if (Number(obj[0].tieneInvitacionReciproca)) {
            dispatch(setAlert({
                show: true,
                message: "No se puede asociar una entidad que lo Invito a Participar en Cadena de Valor",
                type: ALERT_FAIL
            }))

        }

        if (!Number(obj[0].tieneConvPorInvitacion) && !Number(obj[0].tieneMembresiaEntida) && !Number(obj[0].tieneCobroAplicado) && !Number(obj[0].tieneInvitacionReciproca)) {
            changeEstatus(obj, CVE_ESTATUS_RELACION.disponible)
        }
    }

    const search = async () => {
        dispatch(setLoading(true))
        try {
            const resp = await getEntidadToInvite({
                ...appSt.serviceData,
                rfc: form.rfc.value,
                rfcInvitante: registroConvocatoriaSt.empresaSeleccionada.idEntidad2.rfc,
                idPrograma: registroConvocatoriaSt.convocatoriaSeleccionada.idPrograma
            })
            dispatch(setLoading(false))
            if (resp && resp[0]) {
                checkResponse(resp)
            } else {
                throw new Error("Sin resultados")
            }
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))
            dispatch(setAlert({
                show: true,
                message: error.message ? error.message : "Error al consultar información",
                type: ALERT_FAIL
            }))
        }
    }

    const openModalDoc = (data) => {
        setModalData({
            show: true,
            data
        })
    }

    /**
     * Descargamos documentos
     * @param {*} item 
     */
    const downloadComprobante = async () => {
        const downloadLink = document.createElement('a');
        const fileName = type === PROGRAMAS.cv ? "Proveeduría Requisitos" : "Carta Invitación"
        downloadLink.href = type === PROGRAMAS.cv ? ProveeduriaDoc : EPDoc;
        downloadLink.download = fileName;
        downloadLink.click()
    }

    const downloadReport = async () => {
        dispatch(setLoading(true))
        const blob = await downloadInvitadasReport({
            ...appSt.serviceData,
            idRegistroConvocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.idRegistroConvocatoria,
            body: {
                cveEstatusInscripcion: formFilter.cveEstatusInscripcion.value ? formFilter.cveEstatusInscripcion.value : null
            }
        });
        dispatch(setLoading(false));
        if (blob) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Reporte de resultados';
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
    }

    const openReporteResultados = (data) => {
        setModalReporte({
            show: true,
            data: registros
        })
    }

    useEffect(() => {
        if (!catalogosSt.estatusRelacionPrograma) {
            dispatch(getEstatusRelacionPrograma(appSt.serviceData))
        }
        if (!catalogosSt.documentoRelacionPrograma) {
            dispatch(getCatalogoDocumentosRelacion({
                ...appSt.serviceData,
                claveCatalogo: "documentoCemefi",
                clave: "CARTAPROVEDURIA"
            }))
        }
        if (!catalogosSt.documentosPromotora) {
            dispatch(getCatalogoDocumentosPromotora({
                ...appSt.serviceData,
                claveCatalogo: "documentoCemefi",
                clave: "CARTAINVITACION"
            }))
        }
        setTableFormat([
            {
                label: 'RFC',
                key: 'rfc',
                orden: 2
            },
            {
                label: 'Razon Social',
                key: 'razonSocial',
                orden: 2
            },
            {
                label: 'Estatus Registro Convocatoria',
                key: 'descEstatusRegistroConvocatoria',
                orden: 2
            },
            {
                label: 'Motivo Rechazo',
                key: 'motivoRechazo',
                orden: 4
            },
            {
                label: 'Estatus Invitada',
                key: 'descEstatusInscripcion',
                orden: 5
            },
            {
                label: 'Acciones',
                type: GENERAL_TABLE.columnTypes.actions,
                actions: [
                    {
                        type: GENERAL_TABLE.actionType.tooltipBtn,
                        tooltip: "Eliminar",
                        icon: <DeleteIcon />,
                        action: (registro) => confirmContinue(registro),
                        rule: (registro) => registro.descEstatusInscripcion !== "ACEPTADA" && registro.descEstatusInscripcion !== "INSCRITA" && registro.cveEstatusInscripcion !== "REFERENCIADAANTERIORMENTE"
                    },
                    {
                        type: GENERAL_TABLE.actionType.tooltipBtn,
                        tooltip: "Enviar a validar",
                        rule: (registro) => registro.documentoRelacionPrograma && registro.descEstatusInscripcion !== "ACEPTADA" && registro.descEstatusInscripcion !== "INSCRITA" && registro.cveEstatusInscripcion !== "REFERENCIADAANTERIORMENTE",
                        icon: <CheckIcon />,
                        action: (registro) => changeEstatus([registro], CVE_ESTATUS_RELACION.inscrita)
                    },
                    {
                        type: GENERAL_TABLE.actionType.tooltipBtn,
                        tooltip: "Documento",
                        icon: <FileIcon />,
                        rule: (registro) => registro.cveEstatusInscripcion !== "REFERENCIADAANTERIORMENTE",
                        action: (registro) => openModalDoc(registro)
                    },
                ],
                orden: 11
            },
        ])
    }, [])

    useEffect(() => {
        getRegistros()
        if (registroConvocatoriaSt.convocatoriaSeleccionada.cvePrograma === "CADENAVALOR") {
            setType(PROGRAMAS.cv)
        } else {
            setType(PROGRAMAS.ep)
        }
    }, [formFilter.cveEstatusInscripcion.value])

    useEffect(() => {
        if (registros) {
            const hasDictamen = registros.find(item => item.idDictamen && item.cveEstatusInscripcion !== "REFERENCIADAANTERIORMENTE")
            if (hasDictamen) {
                setTableActions([
                    {
                        type: GENERAL_TABLE.actionType.button,
                        label: 'Reporte',
                        action: (data) => downloadReport(data),
                    },
                    {
                        type: GENERAL_TABLE.actionType.button,
                        label: 'Reporte Resultados',
                        action: (data) => openReporteResultados(data),
                    },
                ])
            } else {
                setTableActions([
                    {
                        type: GENERAL_TABLE.actionType.button,
                        label: 'Reporte',
                        action: (data) => downloadReport(data),
                    },
                ])
            }
        }
    }, [registros])

    return (
        <section className="wrapper invitaciones-view">
            <div className="main-section-title">
                <button onClick={goBack}>
                    <BackIcon />
                </button>
                <div className="text">
                    <h3>Invitaciones</h3>
                </div>
            </div>

            <div className="card">
                <div className="head">
                    <h2>Agregar</h2>
                </div>
                <div className="filtros">
                    <div className="four-col">
                        <InputComp type="text" name="rfc" label="RFC" form={form} setForm={setForm} />
                        <button onClick={search}>Agregar</button>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="head">
                    <h2>Invitadas</h2>
                </div>
                <div className="content">
                    <div className="top-actions">
                        {catalogosSt.estatusRelacionPrograma && <>
                            <InputComp
                                type="select"
                                name="cveEstatusInscripcion"
                                label="Estatus"
                                catalogos={catalogosSt.estatusRelacionPrograma}
                                catalogoLabel={"descEstatusRelacionPrograma"}
                                catalogoValue={"cveEstatusRelacionPrograma"}
                                form={formFilter}
                                setForm={setFormFilter}
                            />
                        </>
                        }
                        <button onClick={downloadComprobante}>{type === PROGRAMAS.cv ? "Comprobante Proveduría" : "Carta Invitación"}</button>
                    </div>
                    <GeneralTable quickActions={tableActions} registros={registros} headers={tableFormat} showMaxItems={true} showBuscador={false} />

                </div>
            </div>
            <ModalCartaProvedura modalData={modalData} setModalData={setModalData} search={getRegistros} type={type} />
            <ConfirmModal data={confimModal} cancel={closeModal} />
            <ModalReporteResultados empresaSeleccionada={empresaSeleccionada} modalData={modalReporte} setModalData={setModalReporte} />
        </section>
    )
}