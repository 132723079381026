import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postEntidadesAprobadas } from '../services/publicacion';

const filterDestacadas = (payload, destacada) => {
    const array = [];
    if (payload) {
        payload.forEach(element => {
            if (Number(element.destacada) === destacada) {
                array.push(element)
            }
        });
    }
    return array;
}

const initialState = {
    loading: false,
    currentConvocatoria: false,
    entidadesAprobadas: null,
    entidadesDestacadas: null
}

export const getEntidadesAprobadas = createAsyncThunk('publicacion/getEntidadesAprobadas', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await postEntidadesAprobadas(data, true);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const getEntidadesDestacadas = createAsyncThunk('publicacion/getEntidadesDestacadas', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await postEntidadesAprobadas(data);
    } catch (error) {
        console.log('error', error); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const publicacionSlice = createSlice({
    name: 'publicacion',
    initialState,    
    reducers: {
        setCurrentConvocatoria: (state, action) => {
            state.currentConvocatoria = action.payload;
        },
        setEntidadesDestacadas: (state, action) => {
            state.entidadesDestacadas = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase((
            getEntidadesAprobadas.pending ||
            getEntidadesDestacadas.pending 
        ), (state) => {
            state.loading = true
        })

        builder.addCase((
            getEntidadesAprobadas.rejected ||
            getEntidadesDestacadas.rejected 
        ), (state) => {
            state.loading = false
        })

        builder.addCase(getEntidadesAprobadas.fulfilled, (state, action) => {
            state.loading = false;
            state.entidadesAprobadas = filterDestacadas(action.payload, 0);
        })

        builder.addCase(getEntidadesDestacadas.fulfilled, (state, action) => {
            state.loading = false;
            state.entidadesDestacadas = filterDestacadas(action.payload, 1);
        })
    },
})

export const { 
    setCurrentConvocatoria,
    setEntidadesDestacadas
 } = publicacionSlice.actions;
export const publicacionState = (state) => state.publicacion;

export default publicacionSlice.reducer;