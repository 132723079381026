import DOMPurify from "dompurify";
import parse from 'html-react-parser';
import { useEffect, useState } from "react";

import '../../styles/template-styles/two-col.scss';
import '../../styles/template-styles/templates.scss';

import convocatoria from '../../assets/images/background-image-100.jpg';

export default function TwoColumnsPreview({ data }) {
    /**
     * Set Configuration state
     */
    const [moduleStyles, setModuleStyles] = useState({
        backgroundColor: '',
    });
    const [contentOneStyles, setContentOneStyles] = useState({
        backgroundColor: '',
        color: ''
    });
    const [contentTwoStyles, setContentTwoStyles] = useState({
        backgroundColor: '',
        color: ''
    });

  /**
     * Clean HTML string
     * @returns 
     */
    const cleanHTML = (htmlString) => {
    const clean = DOMPurify.sanitize(htmlString, {USE_PROFILES: {html: true}});
    return parse(clean);
  }

  /**
   * Check config values
   */
  useEffect(() => {
    if (data) {
        const styles = {
            backgroundColor: data.backgroundColor,            
        };
        if (data.alturaFija) {
            styles.height = `${data.height}px`
        }
        console.log(styles)
        setModuleStyles(styles);
        setContentOneStyles({
            backgroundColor: data.contenidoOne.backgroundColor,
            color: data.contenidoOne.color
        });
        setContentTwoStyles({
            backgroundColor: data.contenidoTwo.backgroundColor,
            color: data.contenidoTwo.color
        });
    }
  }, [data])

  return (
      <div className={`${data.layout}-conv`} style={moduleStyles}>
          {data.backgroundImage &&
              <img src={convocatoria} alt="fondo" />
          }
          <div className={data.wrapContent ? 'wrapper-conv' : ''}>
            <div className="two-col-wrap-conv">
                <div className='content-conv one' style={contentOneStyles}>
                    {cleanHTML(data.contenidoOne.htmlString)}
                </div>
                <div className='content-conv two' style={contentTwoStyles}>
                    {cleanHTML(data.contenidoTwo.htmlString)}
                </div>            
            </div>           
          </div>
      </div>
  );
}