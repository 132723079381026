export const filtrosIndicador = {
    idEstado: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idTamanio: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idIndustria: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idSector: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idSubsector: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    anioEsr: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idAmbito: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idSubambito: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idIndicador: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
}