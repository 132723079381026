import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import PrivateRoute from './app/helpers/PrivateRoute';
import {
  Routes,
  Route,
  NavLink,
  Link,
  useLocation,
  Navigate,
  useNavigate
} from "react-router-dom";
import './App.scss';
import Registro from './views/registro';
import MisEmpresas from './views/misEmpresas';
import Inicio from './views/inicio';
import Convocatorias from './views/convocatorias';

import logoEsr from './assets/esr-logo.png';
import logoCemefi from './assets/cemefi-logo.png';
import inicioBg from './assets/fondo.jpg';

import { ReactComponent as HomeIcon } from './assets/icons/home.svg';
import { ReactComponent as FileIcon } from './assets/icons/file.svg';
import { ReactComponent as EmpresaIcon } from './assets/icons/empresa.svg';
import { ReactComponent as AyudaIcon } from './assets/icons/help.svg';
import { ReactComponent as UsuariosIcon } from './assets/icons/usuarios.svg';
import { ReactComponent as SimpleArrowIcon } from './assets/icons/simple-arrow.svg';
import { ReactComponent as CampusIcon } from './assets/icons/user.svg';

import Usuarios from './views/usuarios';
import { useDispatch, useSelector } from 'react-redux';
import { appState, setAreaData, setAreasDisponibles, setLoading, setServiceData, setToken, setTokenInfo } from './slices/appSlice';
import Loader from './components/generales/loader';
import { getUsuarioPorId, usuariosServicesState } from './slices/usuariosServicesSlice';
import { isUserRegistered } from './app/commons';
import Alert from './components/generales/alert';
import { changePregunta, resetInstrumento } from './slices/instrumentoServiceSlice';
import { changeView } from './slices/registroConvocatoriaSlice';
import { EMPRESAS_LIST, LOGO_EMPRESA } from './app/constantes';
import RegistroUsuario from './components/registro/registroUsuario';
import { setModalUser } from './slices/usuariosSlice';
import ContactosCemefi from './views/contactosCemefi';
import Publicacion from './views/publicacion';
import { catalogosServiceState, getAllAreas, getAreasCatalogo, getEstatusCobro, getEstatusEntidad, getEstatusInstrumentoRegistro, getEstatusRegistroConvocatoria, getModalidadPago, getTipoContactoCatalogo, getTipoDireccionCatalogo, getTipoRespuesta } from './slices/catalogosServiceSlice';
import Sedes from './views/sedes';
import { changeModalVinculacionEntidad } from './slices/registroSlice';
import ModalVinculacionEntidad from './components/registro/modal-vinculacion-entidad';
import PublicacionResultados from './views/publicacion-resultados-publica';
import RegistroConsultor from './views/registroConsultor';
import Cursos from './views/cursos';
import MisPagorCursos from './views/misPagosCursos';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const appSt = useSelector(appState);
  const usuariosServiceSt = useSelector(usuariosServicesState);
  const catalogosSt = useSelector(catalogosServiceState);

  const showMenuOptions = keycloak &&
    keycloak.authenticated &&
    isUserRegistered(usuariosServiceSt.usuario) && usuariosServiceSt.usuario.data.idAreaCemefiDefault !== null;

  const goToLink = (link) => {
    dispatch(changePregunta(null));
    closePreguntas();
    navigate(link);
  }

  const logout = () => {
    navigate('inicio');
    keycloak.logout();
  }

  const closePreguntas = () => {
    dispatch(changeView(EMPRESAS_LIST));
    dispatch(resetInstrumento());
  }

  const editUser = () => {
    dispatch(setModalUser({
      show: true,
      data: appSt.tokenInfo
    }))
  }

  const openModalVinculacionEntidad = () => {
        dispatch(changeModalVinculacionEntidad({
            show: true,
            data: null
        }))
  }

  const isConsultor = () => {
    let value = false
    if (usuariosServiceSt.usuario && usuariosServiceSt.usuario.consultor && appSt.serviceData) {
      // hay q consultar si el area coinside con la trae el consultor
      const consultorActivo = usuariosServiceSt.usuario.consultor.find(item => item.isConsultorAcreditado && Number(item.idAreaCemefi) === Number(appSt.serviceData.area))
      value = consultorActivo ? true : false
    }
    return value
  }

  /**
   * Setteamos token e informacion de keycloak
   */
  useEffect(() => {
    if (keycloak.authenticated) {
      dispatch(setTokenInfo(keycloak.tokenParsed));
      dispatch(setToken(keycloak.token));
    }

    if (!keycloak.authenticated && !usuariosServiceSt.usuario && location.pathname !== '/inicio') {
      console.log('inicio')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak.authenticated])

  /**
   * Checamos la session del usuario para ver si esta registrado o no
   */
  useEffect(() => {
    if (appSt.token !== null && usuariosServiceSt.usuario === null && appSt.tokenInfo) {
      const data = {
        token: appSt.token,
        idUsuario: appSt.tokenInfo.sub,
        area: 1
      }
      dispatch(getUsuarioPorId(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSt.token]);

  useEffect(() => {
    if (usuariosServiceSt.usuario && usuariosServiceSt.usuario.data) {
      dispatch(setServiceData({
        token: appSt.token,
        area: usuariosServiceSt.usuario.data.idAreaCemefiDefault
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuariosServiceSt.usuario])

  useEffect(() => {
    if (usuariosServiceSt.loading) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoading(false));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuariosServiceSt.loading]);

  useEffect(() => {
    if (catalogosSt.listadoAreas && usuariosServiceSt.usuario && usuariosServiceSt.usuario.meta && usuariosServiceSt.usuario.meta.areas && usuariosServiceSt.usuario && usuariosServiceSt.usuario.meta && usuariosServiceSt.usuario.meta.areas.length > 0) {
      const areasDisponibles = [];
      for (const areaUsuario of usuariosServiceSt.usuario.meta.areas) {
        const areaDisp = catalogosSt.listadoAreas.find(item => Number(item.idAreaCemefi) === Number(areaUsuario));
        if (areaDisp) {
          areasDisponibles.push(areaDisp);
        }
      }
      dispatch(setAreasDisponibles(catalogosSt.listadoAreas));
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogosSt.listadoAreas, usuariosServiceSt.usuario]);
  
  useEffect(() => {
    if (appSt.areaData === null && usuariosServiceSt.usuario && usuariosServiceSt.usuario.data && catalogosSt.listadoAreas) {
      const areaDefault = catalogosSt.listadoAreas.find(item => Number(item.idAreaCemefi) === Number(usuariosServiceSt.usuario.data.idAreaCemefiDefault));
      dispatch(setAreaData(areaDefault));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSt.areasDisponibles])

  /**
   * Catalogos escenciales para constantes de ids
   */
  useEffect(() => {
    if (appSt.serviceData && catalogosSt.estatusEntidad === null) {
      dispatch(getEstatusEntidad(appSt.serviceData));
    }

    if (appSt.serviceData && catalogosSt.tipoDireccion === null) {
      dispatch(getTipoDireccionCatalogo(appSt.serviceData));
    }

    if (appSt.serviceData && catalogosSt.estatusRegistroConvocatoria === null) {
      dispatch(getEstatusRegistroConvocatoria(appSt.serviceData));
    }

    if (appSt.serviceData && catalogosSt.estatusCobro === null) {
      dispatch(getEstatusCobro(appSt.serviceData));
    }

    if (appSt.serviceData && catalogosSt.tipoRespuesta === null) {
      dispatch(getTipoRespuesta(appSt.serviceData));
    }

    if (appSt.serviceData && catalogosSt.modoPago === null) {
      dispatch(getModalidadPago(appSt.serviceData));
    }

    if (appSt.serviceData && catalogosSt.areas === null) {
      dispatch(getAreasCatalogo(appSt.serviceData));
    }

    if (appSt.serviceData && catalogosSt.listadoAreas === null) {
      dispatch(getAllAreas({token: appSt.token}));
    }

    if (appSt.serviceData && catalogosSt.tipoContacto === null) {
      dispatch(getTipoContactoCatalogo(appSt.serviceData));
    }
    if (!catalogosSt.estatusInstrumentoRegistro && appSt.serviceData) {
      dispatch(getEstatusInstrumentoRegistro(appSt.serviceData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuariosServiceSt.usuario, appSt.serviceData, appSt.token])

  return (
    <div className="App">
      <nav className='main-nav'>
        <div className='main-background'>
          <img className={location.pathname === '/inicio' ? 'active' : ''} src={inicioBg} alt="fondo" />
        </div>
        <div className='wrapper'>
          <div className='logo'>
            {appSt.logo === LOGO_EMPRESA.cemefi && <img src={logoCemefi} alt="logo" />}
            {appSt.logo === LOGO_EMPRESA.esr && <img src={logoEsr} alt="logo" />}            
          </div>
          <div className='links'>
            <div className='main-links'>
              <ul>
                <li>
                  <NavLink activeclassname="active" to='/inicio' onClick={closePreguntas}>
                    <HomeIcon />
                    Inicio
                  </NavLink>
                </li>
                {showMenuOptions &&
                  <>
                    <li>
                      <NavLink activeclassname="active" to='/convocatoria' onClick={closePreguntas}>
                        <FileIcon />
                        Convocatoria
                      </NavLink>
                    </li>
                    <li className='sub-menu'>
                      <p onClick={() => goToLink('/mis-entidades')} className={location.pathname === '/registro' || location.pathname === '/mis-entidades' ? 'active' : ''}>
                        <EmpresaIcon />
                        Mis Entidades
                        <SimpleArrowIcon className='arrow' />
                      </p>
                      <ul className='submenu'>
                        <li>
                          <Link to="/registro">Nueva Entidad</Link>
                        </li>
                        <li>
                          <Link to="/mis-entidades">Mis Entidades</Link>
                        </li>
                        <li className='wrap' onClick={openModalVinculacionEntidad}>
                          <p>Solicitar acceso a una entidad existente</p>
                        </li>
                      </ul>
                      {/* <Link to='/mis-entidades' className={location.pathname === '/registro' || location.pathname === '/mis-entidades' ? 'active' : ''}>
                        Mis Entidades
                      </Link> */}
                    </li>
                    <li>
                      <Link to='/usuarios' className={location.pathname === '/usuarios' ? 'active' : ''}>
                        <UsuariosIcon />
                        Usuarios
                      </Link>
                    </li>
                    {isConsultor() && (
                      <li className='sub-menu'>
                        <p className={location.pathname === '/registro-consultor' ? 'active' : ''}>
                          <CampusIcon />
                          Campus
                          <SimpleArrowIcon className='arrow' />
                        </p>

                        <ul className='submenu'>
                          <li>
                            <Link to="registro-consultor">Registro Consultor</Link>
                          </li>
                        </ul>
                      </li>
                    )}
                  </>
                }
                <li className='sub-menu'>
                  <p>
                    <AyudaIcon />
                    Ayuda
                    <SimpleArrowIcon className='arrow' />
                  </p>
                  <ul className='submenu'>
                    <li className='sub-submenu'>
                      <p>Sitios de Interés</p>
                      <ul>
                        <li>
                          <a href='https://www.cemefi.org/' target="_blank" rel="noreferrer">Cemefi</a>
                          <a href='https://www.caracoldeplata.org/' target="_blank" rel="noreferrer">Caracol de Plata</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/contactos-cemefi">Ayuda</Link>
                    </li>
                    <li>
                      <Link to="/publicacion">Empresas Acreditadas</Link>
                    </li>
                    {usuariosServiceSt.usuario && usuariosServiceSt.usuario.data &&
                      <li>
                        <Link to="/contactos-cemefi">Contactos Cemefi</Link>
                      </li>
                    }
                  </ul>
                </li>
                
              </ul>
              {keycloak && keycloak.authenticated && usuariosServiceSt.usuario && usuariosServiceSt.usuario.meta && appSt.serviceData && appSt.serviceData.area && catalogosSt.areas && appSt.areaData &&
                <div className='area'>
                  <p>{appSt.areaData.descAreaCemefi}</p>
                </div>
              }
              {keycloak && keycloak.authenticated && usuariosServiceSt.usuario && usuariosServiceSt.usuario.data &&
                <div className='user'>
                  <div className='icon'>
                    {usuariosServiceSt.usuario.data.nombre && <p>{usuariosServiceSt.usuario.data.nombre.charAt(0)}</p>}
                  </div>
                  <div className='submenu'>
                    <button onClick={editUser}>Editar Usuario</button>
                    <button onClick={logout}>Salir</button>
                  </div>
                </div>
              }
              {appSt.token && !isUserRegistered(usuariosServiceSt.usuario) && <div className='user'>
                <div className='icon'>

                </div>
                <div className='submenu'>
                  <button onClick={logout}>Salir</button>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </nav>
      <section className='main-wrapper'>
        <Routes>
          <Route path="*" element={<Navigate to='/inicio' replace />}></Route>
          <Route path="inicio" element={
            <Inicio />
          } />
          <Route path="publicacion" element={
            <PublicacionResultados />
          } />
          <Route path="convocatoria" element={
            <PrivateRoute usuario={usuariosServiceSt.usuario}>
              <Convocatorias />
            </PrivateRoute>
          } />
          <Route path="registro" element={
            <PrivateRoute usuario={usuariosServiceSt.usuario}>
              <Registro />
            </PrivateRoute>
          } />
          <Route path="mis-entidades" element={
            <PrivateRoute usuario={usuariosServiceSt.usuario}>
              <MisEmpresas />
            </PrivateRoute>
          } />
          <Route path="/:idEntidad/cursos" element={
            <PrivateRoute usuario={usuariosServiceSt.usuario}>
              <Cursos />
            </PrivateRoute>
          } />
          <Route path="/:idEntidad/pagosCursos" element={
            <PrivateRoute usuario={usuariosServiceSt.usuario}>
              <MisPagorCursos />
            </PrivateRoute>
          } />
          <Route path="sedes" element={
            <PrivateRoute usuario={usuariosServiceSt.usuario}>
              <Sedes />
            </PrivateRoute>
          } />
          <Route path="usuarios" element={
            <PrivateRoute usuario={usuariosServiceSt.usuario}>
              <Usuarios />
            </PrivateRoute>
          } />

          <Route path="contactos-cemefi" element={
            <PrivateRoute usuario={usuariosServiceSt.usuario}>
              <ContactosCemefi />
            </PrivateRoute>
          } />

          <Route path="publicacion" element={
            <PrivateRoute usuario={usuariosServiceSt.usuario}>
              <Publicacion />
            </PrivateRoute>
          } />

          <Route path="registro-consultor" element={
            <PrivateRoute usuario={usuariosServiceSt.usuario}>
              <RegistroConsultor />
            </PrivateRoute>
          } />

        </Routes>
      </section>

      <RegistroUsuario />
      <ModalVinculacionEntidad />


      <Loader />
      <Alert />
    </div>
  );
}

export default App;
