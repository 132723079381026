/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { getCobrosAplicados, getPagosDetails, setModalFactura } from "../../slices/pagosSlice";
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { ALERT_FAIL, CONCEPTO_CUOTA, NOTIFICACIONES } from "../../app/constantes";
import { putCobroFactura, putReferenciaPasarela } from "../../services/convocatorias";
import { postComplementoPago, postEmitirFactura, putFacturaAnticipada } from "../../services/cobro";
import { registroConvocatoriaState } from "../../slices/registroConvocatoriaSlice";
import { postNotificacion } from "../../services/notificaciones";
import { getEntidad } from "../../services/entidad";
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { getToNotification } from "../../app/commons";

export default function ModalPagoLinea({ checkoutId, convocatoria, setCheckoutId, datosFacturacion, showFacturas }) {
  const dispatch = useDispatch();
  const catalogosSt = useSelector(catalogosServiceState);
  const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
  const appSt = useSelector(appState);
  const [isSuccess, setIsSuccess] = useState(null)

  const closeModal = () => {
    dispatch(getPagosDetails({
      ...appSt.serviceData,
      idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
      idModalidadPago: catalogosSt.idModoPagoTotal
    }))
    dispatch(getCobrosAplicados({
      ...appSt.serviceData,
      idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
    }))
    dispatch(setModalFactura({
      show: false,
      data: null
    }))
    setIsSuccess(null)
    setCheckoutId(null)
  }

  const successPayment = async (e) => {
    console.log("success")
    setIsSuccess(e.id)
    try {
      dispatch(setLoading(true))
      for (const item of datosFacturacion.formatedPagos) {
        console.log(item)
        if (!item.isRegalia) {
          await putReferenciaPasarela({
            ...appSt.serviceData,
            idCobroRegistroConvocatoria: item.idCobroRegistroConvocatoria,
            body: [{
              idRegistroConvocatoria: item.idRegistroConvocatoria,
              idConceptoCobroConvocatoria: item.idConceptoCobroConvocatoria,
              referenciaPasarela: e.id,
              uuid: item.uuid,
              idFormaPago: datosFacturacion.data && datosFacturacion.data.formaPago ? datosFacturacion.data.formaPago : datosFacturacion.confirmarDatos.formaPago
            }]
          })
        }
      }
      dispatch(setLoading(false))
      const PPD = datosFacturacion.formatedPagos.find(item => item.facAnticipada)
      if (PPD) {
        await handleComplemento()
      }
      if (showFacturas) {
        await handleFactura()
      }

      await handleNotificacion()

    } catch (error) {
      console.log(error)
      dispatch(setAlert({
        show: true,
        message: "Error al guardar referencias/factura",
        type: ALERT_FAIL
      }))
    }
  }

  const handleNotificacion = async () => {
    try {
      const entidadData = await getEntidad({ ...appSt.serviceData, idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad })
      if (entidadData) {
        postNotificacion(appSt.serviceData, {
          to: getToNotification(entidadData, catalogosSt.idsTipoContacto),
          templateData: {
            convocatoria: registroConvocatoriaSt.convocatoriaSeleccionada.descConvocatoria
          },
          templateId: NOTIFICACIONES.templatesIds.pasarelaExitoso
        })
      } else {
        throw new Error('Sin contacto principal')
      }
    } catch (error) {
      console.log(error)
      dispatch(setAlert({
        show: true,
        message: "Error al enviar notificación",
        type: ALERT_FAIL
      }))
    }
  }

  const errorPayment = (e) => {
    setIsSuccess(false)
    closeModal()
    dispatch(setAlert({
      show: true,
      message: e,
      type: ALERT_FAIL
    }))
  }

  /**
   * Descargamos la factura
   */
  const donwloadDoc = (file, type) => {
    const link = document.createElement('a');
    link.href = `data:application/${type};base64,` + file;
    link.download = 'Factura' + type;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  }

  /**
     * Guardamos la factura en BD
     */
  const saveFactura = async (array, data, resp) => {
    const cobros = []
    const promises = []
    array.forEach(item => {
      cobros.push({
        idCobroAplicado: item.cobroAplicado.idCobroAplicado,
        folio: resp.payload.folio,
        serie: resp.payload.serie,
        uuid: resp.payload.uuid,
      })
      promises.push(putCobroFactura({
        ...appSt.serviceData,
        idCobroRegistroConvocatoria: item.idCobroRegistroConvocatoria,
        body: {
          cveUsoCfdi: data.receptor.usoCfdi,
          cveFormaPago: data.formaPago,
          cveMetodoPago: data.metodoPago,
          cveRegimenFiscal: data.receptor.regimenFiscal,
          folioOdc: data.folioOdc,
          idCobroRegistroConvocatoria: item.idCobroRegistroConvocatoria
        }
      }))
    })
    await Promise.allSettled(promises)
    await putFacturaAnticipada({ ...appSt.serviceData, body: cobros })
  }

  /**
   * Solicitamos factura
   */
  const handleFactura = async () => {
    if (datosFacturacion) {
      try {
        dispatch(setLoading(true));
        datosFacturacion.data.detalles.forEach((item) => {
          if (item.descripcion.includes("CUOTA")) {
            item.descripcion = CONCEPTO_CUOTA
          }
        })
        const resp = await postEmitirFactura({
          ...appSt.serviceData,
          body: datosFacturacion.data
        });
        dispatch(setLoading(false));
        if (resp) {
          donwloadDoc(resp.payload.archivoPDF, 'pdf',)
          donwloadDoc(resp.payload.archivoXML, 'xml')
        }

        // await saveFactura(datosFacturacion.array, datosFacturacion.data, resp)

        dispatch(setLoading(false));
        dispatch(getPagosDetails({
          ...appSt.serviceData,
          idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
          idModalidadPago: catalogosSt.idModoPagoTotal
        }))
        dispatch(getCobrosAplicados({
          ...appSt.serviceData,
          idRegistroConvocatoria: convocatoria.idRegistroConvocatoria,
        }))

      } catch (error) {
        console.log(error)
        dispatch(setLoading(false));
        dispatch(setAlert({
          show: true,
          message: error.payload,
          type: ALERT_FAIL
        }))
      }
    }
  }

  const handleComplemento = async () => {
    try {
      dispatch(setLoading(true));
      const uuids = []
      for (const item of datosFacturacion.formatedPagos) {
        const exist = uuids.find(i => i.uuid === item.uuid);
        if (!exist) {
          uuids.push(item)
        }
      }
      for (const item of uuids) {
        if (item.facAnticipada) {
          const resp = await postComplementoPago({
            ...appSt.serviceData,
            body: {
              idAreaCemefi: appSt.serviceData.area,
              idEntidad: registroConvocatoriaSt.empresaSeleccionada.idEntidad,
              idCobroAplicado: item.idCobroAplicado,
              uuid: item.uuid,
              idRegistroConvocatoria: item.idRegistroConvocatoria
            }
          })

          if (resp) {
            donwloadDoc(resp.archivoPDF, 'pdf',)
            donwloadDoc(resp.archivoXML, 'xml')
          }

          dispatch(setLoading(false));
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setAlert({
        show: true,
        message: error.payload ? error.payload : 'Error al complementar pago',
        type: ALERT_FAIL
      }))
    }
  }

  useEffect(() => {
    console.log(datosFacturacion)
  }, [datosFacturacion])

  useEffect(() => {
    console.log(checkoutId)
    if (checkoutId) {
      const config = {
        checkoutRequestId: checkoutId, //Checkout ID enviado desde el servidor para inicializar el componente de pago.
        publicKey: process.env.REACT_APP_CONEKTA_KEY,
        targetIFrame: 'pago',
        locale: "es",
      };

      const callbacks = {
        onFinalizePayment: (event) => successPayment(event),
        onErrorPayment: (event) => errorPayment(event),
        onGetInfoSuccess: (event) => console.log("GetInfo", event),
      };

      window.ConektaCheckoutComponents.Integration({ config, callbacks });
    }

  }, [checkoutId])

  return (
    <div className={checkoutId ? 'modal-wrapper active' : 'modal-wrapper'}>
      <div className="modal modal-pago-linea">
        <div className="head">
          <h3>Pagar</h3>
          <button onClick={closeModal}>
            x
          </button>
        </div>
        <div className="content">
          {isSuccess === null && <div className="tip sticky">
            <InfoIcon />
            <div>
              <p>Favor de no actualizar el navegador, ni cerrar sesión hasta que termine el proceso de pago en lineal y genere la factura ya que puede duplicarse el pago.</p>
            </div>
          </div>}
          {isSuccess && <div className="referencia">
            <h3>Referencia de pago: <b>{isSuccess}</b></h3>
          </div>}
          <div id="pago"></div>
        </div>
        <div className="actions">
          <button onClick={closeModal}>Cerrar</button>
        </div>
      </div>
    </div>
  )
}