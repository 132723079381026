export const datosIndustriaForm = {
    idIndustria: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idSector: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idSectorSe: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idSubsectorInegi: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    numeroEmpleados: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
}

export const datosIndustriaMessages = {
    actividadesEmpresa: {
        tool: ''
    },
    serviciosProductos: {
        tool: ''
    },
    productos: {
        tool: ''
    },
    servicios: {
        tool: ''
    }
}