import {
    deleteRequest,
    getFileRequest,
    getRequest,
    postFileRequest,
    postRequest,
    putRequest,
} from './http-service';

const url = process.env.REACT_APP_ENTIDAD_SERVICE_URL;
const urlDocs = process.env.REACT_APP_DOCUMENTOS_SERVICE;

//Entidades
export const getAllEmpresas = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad`;
    return await getRequest(requestUrl, data.token);
};

export const getEntidad = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad/${data.idEntidad}`;
    return await getRequest(requestUrl, data.token);
};

export const getMisEmpresas = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad/idUsuario/${data.idUsuario}`;
    return await getRequest(requestUrl, data.token);
};

export const postEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/entidad`;
    return await postRequest(requestUrl, data.token, { payload: body });
};

export const putEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/entidad/${data.idEntidad}`;
    return await putRequest(requestUrl, data.token, { payload: body });
};

export const deleteEntidad = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad/${data.idEntidad}`;
    return await deleteRequest(requestUrl, data.token);
};

//Entidad Direccion
export const postDireccionEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/direccionEntidad`;
    return await postRequest(requestUrl, data.token, { payload: body });
};
export const putDireccionEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/direccionEntidad/${data.idDireccion}`;
    return await putRequest(requestUrl, data.token, { payload: body });
};

// Entidad Contacto
export const postContactoEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/contactoEntidad`;
    return await postRequest(requestUrl, data.token, { payload: body });
};

export const putContactoEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/contactoEntidad/${data.idContactoEntidad}`;
    return await putRequest(requestUrl, data.token, { payload: body });
};

export const getContactoEntidad = async (data) => {
    const requestUrl = `${url}area/${data.area}/contactoEntidad/${data.idEntidad}`;
    return await getRequest(requestUrl, data.token);
};

export const deleteContactoEntidad = async (data) => {
    const requestUrl = `${url}area/${data.area}/contactoEntidad/${data.idContactoEntidad}`;
    return await deleteRequest(requestUrl, data.token);
};

//Entidad Producto
export const postProductoEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/productoEntidad`;
    return await postRequest(requestUrl, data.token, { payload: body });
};

export const getProductoEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/productoEntidad`;
    return await getRequest(requestUrl, data.token, { payload: body });
};

export const deleteProductoEntidad = async (data) => {
    const requestUrl = `${url}area/${data.area}/productoEntidad/${data.idItem}`;
    return await deleteRequest(requestUrl, data.token);
};

//Entidad Producto
export const postServicioEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/servicioEntidad`;
    return await postRequest(requestUrl, data.token, { payload: body });
};

export const getServiciosEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/servicioEntidad`;
    return await getRequest(requestUrl, data.token, { payload: body });
};

export const deleteServicioEntidad = async (data) => {
    const requestUrl = `${url}area/${data.area}/servicioEntidad/${data.idItem}`;
    return await deleteRequest(requestUrl, data.token);
};

// Entidad Redes
export const postRedesEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/redContactoEntidad`;
    return await postRequest(requestUrl, data.token, { payload: body });
};

export const deleteRedesEntidad = async (data) => {
    const requestUrl = `${url}area/${data.area}/redContactoEntidad/${data.idRedContactoEntidad}`;
    const resp = await deleteRequest(requestUrl, data.token);
    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
}

// Entidad Colaborador
export const postColaboradorEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/colaboradorEntidad`;
    return await postRequest(requestUrl, data.token, { payload: body });
};

export const postListaCoporativos = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/entidad/listaCorporativo`;
    return await postRequest(requestUrl, data.token, { payload: body });
};

export const postIdCorporativo = async (data, idEntidad) => {
    const requestUrl = `${url}area/${data.area}/entidad/corporativo/${idEntidad}`;
    return await postRequest(requestUrl, data.token);
};

export const postIdMiembros = async (data, idEntidad) => {
    const requestUrl = `${url}area/${data.area}/entidad/corporativo/${idEntidad}?isMiembro=1`;
    return await postRequest(requestUrl, data.token);
};

export const getCorporativo = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad/inCorporativo/${data.idEntidad}?idEstatusRegistro=1`;
    return await getRequest(requestUrl, data.token);
};


export const desvincularEntidadCorp = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad/desasociar/corporativo/${data.idEntidad}`;
    return await deleteRequest(requestUrl, data.token);
};

// Documents
export const uploadDocument = async (data, body) => {
    console.log(data, body)
    const requestUrl = `${urlDocs}/documento-service/${data.area}/entidad/${data.idEntidad}`;
    return await postRequest(requestUrl, data.token, { payload: body });
};

export const getDocument = async (data) => {
    const requestUrl = `${urlDocs}/documento-service/${data.area}/entidad/${data.idEntidad}?nombreDocumento=${encodeURIComponent(data.idContent)}`;
    return await getRequest(requestUrl, data.token);
};

export const postDocumentoEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/documentoEntidad`;
    return await postRequest(requestUrl, data.token, {payload: body});
}

export const putDocumentoEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/documentoEntidad/${data.idDocumentoEntidad}`;
    return await putRequest(requestUrl, data.token, {payload: body});
}

export const deleteDocumentoEntidad = async (data) => {
    const requestUrl = `${url}area/${data.area}/documentoEntidad/${data.idDocumentoEntidad}`;
    const resp = await deleteRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
}

// documento de registro de entidad
export const getRegistroEntidadDocument = async (data) => {
    const requestUrl = `${url}area/${data.area}/formato-entidad/entidad/${data.idEntidad}`;
    return await getFileRequest(requestUrl, data.token);
};

export const postEntidadByFilters = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/entidad/filter`;
    return await postRequest(requestUrl, data.token, { payload: body });
};

/**
 * postEntidadRegistroConvocatoria
 * Guarda las entidades que fueron invitadas en un registro de convocatoria
 *
 * @param data
 * @param data.area
 * @param data.token
 * @param body
 * @param body.idRegistroConvocatoria
 * @param body.idRegistroConvocatoriaReferenciada
 * @param body.idEntidad
 */
export const postEntidadRegistroConvocatoria = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/entidad-registro-convocatoria`;
    return await postRequest(requestUrl, data.token, { payload: body });
};

/**
 *
 * @param {*} data
 * @param {*} data.token
 * @param {*} data.area
 * @param {*} data.idEntidadRegistroConvocatoria
 * @returns
 */

export const deleteEntidadRegistroConvocatoria = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad-registro-convocatoria/${data.idEntidadRegistroConvocatoria}`;

    return await deleteRequest(requestUrl, data.token);
};

/**
 * Regresa todos los registros disponibles
 * @param {*} data
 * @returns response
 */

export const getAllEntidadRegistroConvocatoria = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad-registro-convocatoria`;
    const resp = await getRequest(requestUrl, data.token);

    return resp.payload.entidadRegistroConvocatorias;
};

export const getEntidadesReferenciadas = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad-referenciada-membresia/membresia-entidad/${data.idMembresiaEntidad}`;

    const resp = await getRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postEntidadeReferenciada = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/entidad-referenciada-membresia`;

    const resp = await postRequest(requestUrl, data.token, {payload: body});

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteEntidadeReferenciada = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad-referenciada-membresia/${data.idEntidadReferenciadaMembresia}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (resp.payload.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getDocumentosConv = async (data) => {
    const requestUrl = `${urlDocs}/documento-service/documento/convocatoria/${data.idConvocatoria}/etapa/${data.cveEtapa}/tipo-documento/${data.cveTipoDocumento}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postCambioEstatusEntidad = async (data, body) => {
    const requestUrl = `${url}area/${data.area}/entidad/${data.idEntidad}/estatus-entidad`;

    const resp = await putRequest(requestUrl, data.token, {payload: body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp;
};

export const getPhotoContacto= async (data, body) => {
    const requestUrl = `${urlDocs}/documento-service/${data.area}/contacto-cemefi/${data.idContactoCemefi}?nombreDocumento=${encodeURIComponent(data.idContent)}`;
    return await getRequest(requestUrl, data.token, { payload: body });
};


export const getDocsUsoLogo = async (data) => {
    const requestUrl = `${urlDocs}/documento-service/documento/convocatoria/${data.idConvocatoria}/etapa/PUBLICACIONRESULTADOS/tipo-documento/PUBLICACION`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};


export const getEntidadesDisponiblesCorpSede = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad/corporativo-sede/disponibles`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

//https://entidadsvc-dev.cemefi.org/entidad/api/v1/area/:idArea/entidad/entidades-hijos/entidad-padre/:idEntidadPadre
export const getEntidadesHijas = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad/entidades-hijos/entidad-padre/${data.idEntidadPadre}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putDesasignarEntidad = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad/${data.idEntidad}/desasignar-corporativo-sede`;

    const resp = await putRequest(requestUrl, data.token, {payload: {}});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getConsultorById = async (data) => {
    const requestUrl = `${url}area/${data.area}/consultorAcreditado/${data.idConsultorAcreditado}`;

    const resp = await getRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putConsultor = async (data) => {
    const requestUrl = `${url}area/${data.area}/consultorAcreditado/${data.idConsultorAcreditado}`;

    const resp = await putRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postConsultorDireccion = async (data) => {
    const requestUrl = `${url}area/${data.area}/direccion-consultor`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const putConsultorDireccion = async (data) => {
    const requestUrl = `${url}area/${data.area}/direccion-consultor/${data.idDireccionConsultor}`;

    const resp = await putRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postAreaExperiencia = async (data) => {
    const requestUrl = `${url}area/${data.area}/area-experiencia-consultor`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getAreasExperiencia = async (data) => {
    const requestUrl = `${url}area/${data.area}/area-experiencia-consultor`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteAreaExperiencia = async (data) => {
    const requestUrl = `${url}area/${data.area}/area-experiencia-consultor/${data.idAreaExperienciaConsultor}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const postDocumentoConsultor = async (data, body) => {
    const requestUrl = `${urlDocs}/documento-service/${data.area}/consultor-acreditado/${data.idConsultorAcreditado}`;
    const resp = await postRequest(requestUrl, data.token, { payload: body })
    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }
    return resp;
};

//https://entidadsvc-dev.cemefi.org/entidad/api/v1/area/:idArea/consultorAcreditado/documento-consultor/:idDocumentoConsultor
export const deleteDocumentoConsultor = async (data) => {
    const requestUrl = `${url}area/${data.area}/consultorAcreditado/documento-consultor/${data.idDocumentoConsultor}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const getEntidadesFilter = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad/filter-grid`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};


export const inviteEntity = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad-registro-convocatoria/relacion-programa`;

    const resp = await postRequest(requestUrl, data.token, { payload: data.body });

    if (resp.error) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteEntidadInivitada = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad-registro-convocatoria/${data.idEntidadRegistroConvocatoria}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const deleteRelacionPrograma = async (data) => {
    const requestUrl = `${url}area/${data.area}/relacion-programa/${data.idRelacionPrograma}`;

    const resp = await deleteRequest(requestUrl, data.token);

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const uploadRelacionDoc = async (data, body) => {
    console.log(data, body)
    const requestUrl = `${urlDocs}/documento-service/${data.area}/relacion-programa/${data.idRelacionPrograma}
    `;
    return await postRequest(requestUrl, data.token, { payload: body });
};

export const putTriggerDescuento = async (data) => {
    const requestUrl = `${url}area/${data.area}/entidad-registro-convocatoria/entidad-descuento`;

    const resp = await postRequest(requestUrl, data.token, {payload: data.body});

    if (!resp.payload) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'Received an empty array',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp.payload;
};

export const downloadInvitadasReport = async (data) => {
    const requestUrl = `${url}area/${data.area}/reporte/registro-convocatoria/${data.idRegistroConvocatoria}/invitadas`;

    const resp = await postFileRequest(requestUrl, data.token, {payload: data.body}, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8');
    if (!resp) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: 'No payload',
            requestUrl,
            params: { data },
            payload: resp.payload,
        };
    }

    return resp;
};