/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { entidadServicesState, getEntidadPrincipal, obtenerMisEmpresas, selectSedePrincipal } from '../../slices/entidadServicesSlice';
import { setSede, updateEmpresaSeleccionada } from '../../slices/registroSlice';
import { appState, setAlert, setLoading } from '../../slices/appSlice';
import { ALERT_FAIL, ALERT_SUCCESS, CVE_ESTATUS_ENTIDAD, CVE_TIPODOC_EVIDENCIA_INSTITUCIONAL } from '../../app/constantes';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { findCatalogById } from '../../app/commons';
import { deleteEntidad, getEntidadesHijas, putDesasignarEntidad, putEntidad } from '../../services/entidad';
import { catalogosServiceState, getDocumentosEvidenciaCatalogo } from '../../slices/catalogosServiceSlice';
import ConfirmModal from '../generales/confirmModal';
import DocumentoEvidencia from '../generales/document-evidencia';

export default function AdministracionSede() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appSt = useSelector(appState);
  const entidadServicesSt = useSelector(entidadServicesState);
  const catalogosSt = useSelector(catalogosServiceState);
  const [entidades, setEntidades] = useState(null);
  const [evidenciaDoc, setDoc] = useState(null);
  const [onlyView, setOnlyView] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [modalData, setModalData] = useState({
    show: false,
    title: '',
    desc: '',
    info: null,
    list: null
  });

  const checkValid = () => {
    let v = true;
    if (!entidades || entidades.length === 0) {
      v = false
    } else {
      entidades.forEach(ent => {
        if (ent.idEstatusEntidad === catalogosSt.idsEstatusEntidad.registrada) {
          v = false
        }
      })
    }
    setIsValid(v && evidenciaDoc)
  }

  // Cierra Modal de confirmacion
  const closeModal = () => {
    setModalData({
      ...modalData,
      show: false
    })
  }

  //Confirmar Eliminacion
  const confirmDelete = (idEntidad) => {
    setModalData({
      show: true,
      title: 'Eliminar Entidad',
      desc: '¿Está seguro que desea  eliminar esta entidad, su cambio será irreversible?',
      info: idEntidad,
      action: () => deleteEmpresa(idEntidad)
    })
  }

  /**
     * Eliminacion de una entidad
     * @param {*} idEntidad 
     */
  const deleteEmpresa = async (idEntidad) => {
    dispatch(setLoading(true));
    const resp = await deleteEntidad({
      ...appSt.serviceData,
      idEntidad
    });

    if (resp.payload) {
      dispatch(setLoading(false));
      closeModal();
      getHijos();
    }

  }

  /**
   * Regresamos a mis entidades
   */
  const goBack = () => {
    navigate("/mis-entidades");
    dispatch(selectSedePrincipal(null));
  }

  /**
  * Se crea una sede apartir de una entidad
  * @param {*} empresa 
  */
  const crearSede = () => {
    const data = {
      ...appSt.serviceData,
      idEntidad: entidadServicesSt.principalSede.idEntidad
    }
    dispatch(updateEmpresaSeleccionada(data));
    dispatch(setSede(true));
    navigate('/registro');
  }

  /**
  * Editamos una entidad
  * @param {*} empresa 
  */
  const editEmpresa = (empresa) => {
    const data = {
      ...appSt.serviceData,
      idEntidad: empresa.id_entidad
    }
    dispatch(updateEmpresaSeleccionada(data));
    navigate('/registro');
  }

  

  /**
   * Enviamos a validar
   */
  const sendToValidate = async () => {
    try {
      dispatch(setLoading(true));
      await putEntidad(
        { ...appSt.serviceData, idEntidad: entidadServicesSt.principalSede.idEntidad },
        { idEstatusCorporativoSede: catalogosSt.idsEstatusCorpSede.enRevision }
      )
      dispatch(setAlert({
        show: true,
        message: "Sede enviada a validar",
        type: ALERT_SUCCESS
      }));
      goBack();
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error)
      dispatch(setAlert({
        show: true,
        message: 'Error al enviar a validar',
        type: ALERT_FAIL
      }))
      dispatch(setLoading(false));
    }
  }

  const getHijos = async () => {
    try {
      dispatch(setLoading(true));
      const resp = await getEntidadesHijas({ ...appSt.serviceData, idEntidadPadre: entidadServicesSt.principalSede.idEntidad })
      console.log(resp)
      setEntidades(resp);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setAlert({
        show: true,
        message: 'Error al consultar entidades hijas',
        type: ALERT_FAIL
      }))
    }
  }

  /**
   * checamos si ya tiene un documento
   */
  const checkDoc = () => {
    const evidenciaInst = catalogosSt.documentosEvidenciaInstitucional.find(doc => doc.cveDocumentoCemefi === CVE_TIPODOC_EVIDENCIA_INSTITUCIONAL);
    if (evidenciaInst) {
      const docExist = entidadServicesSt.principalSede.cemDocumentoCrcEntidads.find(docEnt => docEnt.idDocumentoCemefi === evidenciaInst.idDocumentoCemefi && docEnt.idEstatusRegistro !== 0)
      setDoc(docExist);
    }
  }

  const getData = () => {
    dispatch(getEntidadPrincipal({ ...appSt.serviceData, idEntidad: entidadServicesSt.principalSede.idEntidad }))
  }

  const desvincularSede = async () => {
    dispatch(setLoading(true));
    try {
        await putDesasignarEntidad({...appSt.serviceData, idEntidad: entidadServicesSt.principalSede.idEntidad})
        const data = {
          idUsuario: appSt.tokenInfo.sub,
          ...appSt.serviceData
        }
        dispatch(obtenerMisEmpresas(data));
        goBack()
        dispatch(setLoading(false));
    } catch (error) {
        console.log(error);
        dispatch(setLoading(false));            
        dispatch(setAlert({
            show: true,
            message: 'Error al desasignar entidad',
            type: ALERT_FAIL
        }))
    }
}

  useEffect(() => {
    if (entidadServicesSt.principalSede && catalogosSt.documentosEvidenciaInstitucional) {
      checkDoc()
    }
  }, [entidadServicesSt.principalSede, catalogosSt.documentosEvidenciaInstitucional])

  useEffect(() => {
    setOnlyView(
      entidadServicesSt.principalSede.idEstatusCorporativoSede === catalogosSt.idsEstatusCorpSede.confirmada ||
      entidadServicesSt.principalSede.idEstatusCorporativoSede === catalogosSt.idsEstatusCorpSede.enRevision 
    )
    getHijos();
    dispatch(getDocumentosEvidenciaCatalogo({ ...appSt.serviceData, cveTipoDocumento: CVE_TIPODOC_EVIDENCIA_INSTITUCIONAL }))
  }, []);

  useEffect(() => {
    checkValid()
  }, [entidades, evidenciaDoc])

  return <div className="wrapper admin-sedes">
    <div className="main-section-title">
      <button onClick={goBack}>
        <BackIcon />
      </button>
      <div className="text">
        <h3>Sedes</h3>
        <p>Agrega las sedes de tu entidad principal</p>
      </div>
    </div>
    <div className="card entidad-principal">
      <div className="head">
        <h2>Entidad Principal</h2>
      </div>
      <div className="content">
        <div className="five-col">
          <div className="dato">
            <label>RFC</label>
            <p>{entidadServicesSt.principalSede.idEntidad2.rfc}</p>
          </div>
          <div className="dato">
            <label>Placa</label>
            <p>{entidadServicesSt.principalSede.nombrePlaca}</p>
          </div>
          <div className="dato">
            <label>Razón Social</label>
            <p>{entidadServicesSt.principalSede.razonSocial}</p>
          </div>
          <div className="dato">
            <label>Placa Quinquenio</label>
            <p>{entidadServicesSt.principalSede.nombrePlacaQuinquenio}</p>
          </div>
          <div className="dato">
            <label>Validación Sede</label>
            <p>{findCatalogById(entidadServicesSt.principalSede.idEstatusCorporativoSede, catalogosSt.estatusCorpSede, 'idEstatusCorporativoSede', 'descEstatusCorporativoSede')}</p>
          </div>
        </div>

        <div className="actions">
          <DocumentoEvidencia 
            evidenciaDoc={evidenciaDoc} 
            onlyView={onlyView} 
            entidadPrincipal={entidadServicesSt.principalSede} 
            callback={getData} 
          />
          {!onlyView && <>
            <button className='main-btn' disabled={entidades && entidades.length > 0} onClick={desvincularSede}>Cambiar Sede</button>
            <button className='main-btn' disabled={!isValid} onClick={sendToValidate}>Enviar a validar</button>
          </>}
        </div>
      </div>
    </div>

    <div className="card sedes">
      <div className="head">
        <h2>Entidades en la sede</h2>
      </div>
      <div className="content">
        {!onlyView &&
          <div className="table-actions">
            <button className='btn-icon' onClick={crearSede}>
              <AddIcon />
              <p>Agregar Entidad</p>
            </button>
          </div>
        }
        {entidades && entidades.length > 0 && <table>
          <thead>
            <tr>
              <th>Nombre Comercial</th>
              <th>Placa</th>
              <th>Validación de Registro</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {entidades.map((entidad, index) => {
              return <tr key={index}>
                <td>{entidad.nombreComercial}</td>
                <td>{entidad.nombrePlaca}</td>
                <td>{entidad.idEstatusEntidad2.descEstatusEntidad}</td>
                <td className='acciones'>
                  {!onlyView &&
                    <div className="flex">
                      {(entidad.cve_estatus_entidad === CVE_ESTATUS_ENTIDAD.registrado || entidad.cve_estatus_entidad === CVE_ESTATUS_ENTIDAD.rechazada) &&
                        <button onClick={() => editEmpresa(entidad)}>
                          <EditIcon />
                        </button>
                      }
                      <button onClick={() => confirmDelete(entidad.idEntidad)}>
                        <DeleteIcon />
                      </button>
                    </div>
                  }
                </td>
              </tr>
            })}
          </tbody>
        </table>}
      </div>
    </div>
    <ConfirmModal data={modalData} cancel={closeModal} />
  </div>
}