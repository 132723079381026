import { useEffect, useReducer, useRef } from 'react';
import { ReactComponent as EmpresaIcon } from '../../../assets/icons/empresa.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import { useSelector } from 'react-redux';
import { appState, setAlert, setLoading } from '../../../slices/appSlice';
import {
    deleteEntidadeReferenciada,
    deleteEntidadRegistroConvocatoria,
    getAllEntidadRegistroConvocatoria,    
    getEntidadesReferenciadas,
    postEntidadeReferenciada,
    postEntidadRegistroConvocatoria,
} from '../../../services/entidad';
import {
    ALERT_FAIL,
    ALERT_WARN,
    DATOS_PARTICIPACION_STEP,
    DUAL_LIST,    
    SERVICIO_ADICIONALES_STEP,
    TIPO_REGISTRO_CORP_INV,
} from '../../../app/constantes';
import {
    changeStep,
    registroConvocatoriaState,
} from '../../../slices/registroConvocatoriaSlice';
import { useDispatch } from 'react-redux';
import {
    getConvocatoriaByQuery,
    getEntidadRegistroConvocatoriaById,
} from '../../../services/convocatorias';
import { useState } from 'react';
import { getMessageByModulo } from '../../../services/catalogos';
import { catalogosServiceState } from '../../../slices/catalogosServiceSlice';
import { removeDiscount, triggerCalculo } from '../../../services/cobro';
const LIST_ACTION = {
    LIST: 'list',
    SELECT: 'select',
    DELETE: 'delete',
    CLEAR: 'clear',
};

const initialState = {
    selected: [],
    listed: [],
};

const listReducer = (state, action) => {
    let listed;
    let selected;
    let index;
    switch (action.type) {
        case LIST_ACTION.CLEAR:
            return { ...state, listed: [] };
        case LIST_ACTION.LIST:
            listed = action.payload.map((entity) => {
                let existence = state.selected.find(
                    (item) => item.idEntidad === entity.idEntidad
                );

                if (existence) {
                    entity.isSelected = true;
                } else {
                    entity.isSelected = false;
                }

                return entity;
            });
            return { ...state, listed: action.payload };
        case LIST_ACTION.SELECT:
            listed = [...state.listed];

            index = listed.findIndex(
                (entity) => entity.idEntidad === action.payload.idEntidad
            );

            if (index !== -1) listed[index].isSelected = true;
            if (Array.isArray(action.payload)) {
                selected = [...action.payload];
            } else {
                selected = [...state.selected, action.payload];
            }

            return { listed, selected };

        case LIST_ACTION.DELETE:
            listed = [...state.listed];

            index = listed.findIndex(
                (entity) => entity.idEntidad === action.payload.idEntidad
            );

            if (index !== -1) {
                listed[index].isSelected = false;
            }

            selected = state.selected.filter(
                (entity) => entity.idEntidad !== action.payload.idEntidad
            );

            return { listed, selected: [...state.selected] };

        default:
            throw new Error(`Invalid action, ${action.type}`);
    }
};

const DualListSelector = ({ type, prev, next, max, getDetailsMembresia }) => {
    const inputRef = useRef();
    const [state, dispatchState] = useReducer(listReducer, initialState);
    const dispatch = useDispatch();
    const { convocatoriaSeleccionada, membresiaActiva } = useSelector(registroConvocatoriaState);
    const registroSt = useSelector(registroConvocatoriaState);

    const appSt = useSelector(appState);
    const catalogosSt = useSelector(catalogosServiceState);
    const [searchDisabled, setSearchDisabled] = useState(true);
    const [messages, setMessages] = useState(null);

    const searchEntities = async (rfc) => {
        const data = {
            ...appSt.serviceData,
        };

        let body = {
            rfc,
            rfcInvitante: registroSt.empresaSeleccionada.idEntidad2.rfc
        };

        if (type !== DUAL_LIST.membresia) {
            body.membresia = 0;
        } else {
            body.membresia = 1;
            body.idPrograma = convocatoriaSeleccionada.idPrograma
        }

        try {
            dispatch(setLoading(false));
            const resp = await getConvocatoriaByQuery(data, body)
            const reciproca = resp.find(item => Number(item.tieneInvitacionReciproca) !== 0);
            const alreadyInvited = resp.find(item => Number(item.tieneConvPorInvitacion) !== 0 || Number(item.tieneConvPorPrograma) !== 0);
            const tieneMembresia = resp.find(item => Number(item.tieneMembresiaEntida) !== 0);
            const tieneCobro = resp.find(item => Number(item.tieneCobroAplicado) !== 0);

            if (Array.isArray(resp) && resp.length === 0) {
                dispatch(setAlert({
                    show: true,
                    message: messages && messages.emptyRfc ? messages.emptyRfc : 'Sin datos',
                    type: ALERT_WARN
                }))
            }
            if (alreadyInvited || tieneCobro || tieneMembresia || reciproca) {
                if (reciproca) {
                    dispatch(setAlert({
                        show: true,
                        message: 'No se puede asociar una entidad que lo Invitó a participar en un Programa',
                        type: ALERT_FAIL
                    }))
                }
                if (alreadyInvited) {
                    dispatch(setAlert({
                        show: true,
                        message: 'El RFC ya cuenta con una Invitación por un Programa',
                        type: ALERT_FAIL
                    }))
                }
                if (tieneCobro) {
                    dispatch(setAlert({
                        show: true,
                        message: 'El RFC no se puede asociar por que ya cuenta con un Cobro aplicado',
                        type: ALERT_FAIL
                    }))
                }
                if (tieneMembresia) {
                    dispatch(setAlert({
                        show: true,
                        message: 'El RFC no se puede asociar por que cuenta con Membresía',
                        type: ALERT_FAIL
                    }))
                }
            } else {
                dispatchState({ type: LIST_ACTION.LIST, payload: resp });
            }                
        } catch (error) {
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: error.payload,
                type: ALERT_FAIL
            }))
        }
    };

    const updateSelectedEntities = async () => {
        dispatch(setLoading(true));
        if (type === DUAL_LIST.membresia) {
            try {
                const membresia = await getEntidadesReferenciadas({
                    ...appSt.serviceData,
                    idMembresiaEntidad: membresiaActiva.idMembresiaEntidad
                })
                dispatchState({ type: LIST_ACTION.SELECT, payload: membresia.entidadRegistroConvocatorias });
                dispatch(setLoading(false));

            } catch (error) {
                dispatch(setLoading(false));

            }
        } else {
            const data = {
                ...appSt.serviceData,
                idRegistroConvocatoria:
                    convocatoriaSeleccionada.idRegistroConvocatoria,
            };

            const selectedEntities = await getEntidadRegistroConvocatoriaById(data);

            dispatchState({ type: LIST_ACTION.SELECT, payload: selectedEntities });
            dispatch(setLoading(false));
            return selectedEntities;
        }
    };

    useEffect(() => {
        updateSelectedEntities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = (evt) => {
        const { current: input } = inputRef;
        dispatch(setLoading(true));

        // if (input.value === '' && type !== DUAL_LIST.membresia) {
        //     dispatchState({ type: LIST_ACTION.CLEAR });
        //     return;
        // }

        searchEntities(input.value);
    };
    const handleSelect = async (entity) => {        
        if (max && max === state.selected.length) {
            dispatch(setAlert({
                show: true,
                message: 'Ya no puede invitar a mas entidades',
                type: ALERT_FAIL
            }));
            return
        }

        let exist;
        if (type === DUAL_LIST.membresia) {
            exist = state.selected.find(item => item.idEntidadRefenciada === entity.idEntidad);
        } else {
            exist = state.selected.find(item => item.idEntidad === entity.idEntidad);
        }

        
        
        if (exist) { 
            dispatch(setAlert({
                show: true,
                message: 'Entidad ya seleccionada',
                type: ALERT_WARN
            }))
        } else {
            const data = {
                ...appSt.serviceData,
            };
            const body = {
                idRegistroConvocatoria: entity.idRegistroConvocatoria,
                idRegistroConvocatoriaReferenciada:
                    convocatoriaSeleccionada.idRegistroConvocatoria,
                idEntidad: entity.idEntidad,
            };
            dispatch(setLoading(true));
            if (type === DUAL_LIST.membresia) {
                entity.isSelected = true;
                const bodyConv = {
                    idMembresiaEntidad: membresiaActiva.idMembresiaEntidad,
                    idRegistroConvocatoriaInvitacion: convocatoriaSeleccionada.idRegistroConvocatoria,
                    idEntidadReferenciada: entity.idEntidad,
                    idRegistroConvocatoria: entity.idRegistroConvocatoria,
                    idConceptoCobro: 1,
                    idPrograma: convocatoriaSeleccionada.idPrograma,
                    idTamanio: convocatoriaSeleccionada.idTamanio,
                    idSectorSe: convocatoriaSeleccionada.idSectorSe,
                    idTipoEntidad: convocatoriaSeleccionada.idTipoEntidad,
                    idModalidadPago: catalogosSt.idModoPagoTotal,
                    idModoParticipacion: convocatoriaSeleccionada.idModoParticipacion,
                }
                await postEntidadeReferenciada(appSt.serviceData, bodyConv);
                inputRef.value = '';
                inputRef.current.value = '';
                dispatch(setLoading(false));
    
            } else {
                inputRef.value = '';
                inputRef.current.value = '';
                await postEntidadRegistroConvocatoria(data, {...body, tpOrigen: TIPO_REGISTRO_CORP_INV.invitacion});
            }
            if (convocatoriaSeleccionada.isInvitacion) {
                await triggerCalculo({...appSt.serviceData, idRegistroConvocatoria: entity.idRegistroConvocatoria, idModalidadPago: catalogosSt.idModoPagoTotal, invitada: true})
            }
            updateSelectedEntities();
        }
    };

    const handleDelete = async (entity) => {
        const data = {
            ...appSt.serviceData,
        };
        dispatch(setLoading(true));
        if (type === DUAL_LIST.membresia) {
            try {
                await deleteEntidadeReferenciada({ ...appSt.serviceData, idEntidadReferenciadaMembresia: entity.idEntidadReferenciadaMembresia });
                updateSelectedEntities();
                dispatchState({ type: LIST_ACTION.DELETE, payload: entity });
                getDetailsMembresia();
            } catch (error) {
                console.log(error);
                dispatch(setAlert({
                    show: true,
                    message: 'Error al eliminar Entidad Referenciada',
                    type: ALERT_FAIL
                }))
            }


        } else {
            getAllEntidadRegistroConvocatoria(data)
                .then(async (resp) => {
                    const filteredRegisters = resp.filter(
                        (item) =>
                            item.idRegistroConvocatoriaReferenciada ===
                            convocatoriaSeleccionada.idRegistroConvocatoria
                    );
                    const toDelete = filteredRegisters.find(
                        (item) => entity.idEntidad === item.idEntidad
                    );

                    data.idEntidadRegistroConvocatoria =
                        toDelete.idEntidadRegistroConvocatoria;

                    if (convocatoriaSeleccionada.isInvitacion) {
                        await removeDiscount({...appSt.serviceData, idRegistroConvocatoria: entity.idRegistroConvocatoria, idModalidadPago: catalogosSt.idModoPagoTotal})
                    }
                    return deleteEntidadRegistroConvocatoria(data);
                })
                .then(() => {
                    updateSelectedEntities();
                    dispatchState({ type: LIST_ACTION.DELETE, payload: entity });
                });
        }
    };
    const prevStep = () => {
        dispatch(changeStep(prev ? prev : DATOS_PARTICIPACION_STEP));
    };

    const nextStep = () => {
        if (type !== DUAL_LIST.membresia && convocatoriaSeleccionada && convocatoriaSeleccionada.minimoInvitacion) {
            dispatch(changeStep(next ? next : SERVICIO_ADICIONALES_STEP));
        } else {
            dispatch(changeStep(next ? next : SERVICIO_ADICIONALES_STEP));
        }
    };

    const validInput = (e) => {
        if (e.target.value === '' || (max && (max === 0 || max === '0'))) {
            setSearchDisabled(true)
        } else {
            setSearchDisabled(false)
        }
    }

    /**
     * Tips
     */
    const getMessages = async () => {
        const resp = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'REGISTROCONVOCATORIA', 'MENSAJEISINVITACION');
        const emptyRfc = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'REGISTROCONVOCATORIA', 'MENSAJEBUSQUEDARFC');
        const messages = {
            topMessage: null,
            emptyRfc: null
        }
        if (resp.payload && resp.payload.valor) {
            messages.topMessage = resp.payload.valor;
        }
        if (emptyRfc.payload && emptyRfc.payload.valor) {
            messages.emptyRfc = emptyRfc.payload.valor;
        }
        setMessages(messages);
    }

    useEffect(() => {
        if (convocatoriaSeleccionada) {
            getMessages();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [convocatoriaSeleccionada])
    return (
        <>
            {type !== DUAL_LIST.membresia && convocatoriaSeleccionada && convocatoriaSeleccionada.minimoInvitacion && messages && messages.topMessage &&
                <p className='tip'>
                    <InfoIcon />
                    {messages.topMessage} {convocatoriaSeleccionada.minimoInvitacion}
                </p>
            }
            <div className="content datos-participacion por-inv">
                <p>Busca y agrega las entidades que deseas invitar:</p>
                <div className="two-col">
                    <div className="card-registro">
                        <div className="head">
                            <SearchIcon />
                            <label>Buscar entidades</label>
                        </div>
                        <div className="list">
                            <div className="search-input">
                                <input
                                    onChange={e => validInput(e)}
                                    ref={inputRef}
                                    type="text"
                                    placeholder="Buscar por RFC"
                                />
                                <button disabled={searchDisabled} onClick={handleSearch}>
                                    <SearchIcon />
                                </button>
                            </div>

                            <ul>
                                {state.listed.length === 0 ? (
                                    <li>No hay datos que mostrar</li>
                                ) : (
                                    state.listed.map((entity, index) => (
                                        <li key={index}>
                                            <p>{entity.nombreComercial}</p>
                                            <button
                                                onClick={() =>
                                                    handleSelect(entity)
                                                }>
                                                <AddIcon />
                                            </button>
                                        </li>
                                    ))
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="card-registro">
                        <div className="head">
                            <EmpresaIcon />
                            <label>Entidades Seleccionadas</label>
                        </div>
                        <div className="list">
                            <ul>
                                {state.selected.length === 0 ? (
                                    <li>No hay entidades seleccionadas</li>
                                ) : (
                                    state.selected.map((entity, index) => (
                                        <li key={index}>
                                            <p>{type === DUAL_LIST.membresia ? entity.entidadReferenciadaData.nombreComercial : entity.nombreComercial}</p>
                                            <button
                                                onClick={() =>
                                                    handleDelete(entity)
                                                }>
                                                <DeleteIcon />
                                            </button>
                                        </li>
                                    ))
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <button onClick={prevStep}>Regresar</button>
                    <button onClick={nextStep}>Continuar</button>
                </div>
            </div>
        </>
    );
};

export default DualListSelector;
