import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import { ReactComponent as EmpresaIcon } from '../../assets/icons/empresa.svg';
import { ReactComponent as EmpresaAdIcon } from '../../assets/icons/empresa_adicional.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as CompletedIcon } from '../../assets/icons/completed.svg';
import {
    changeStep,
    registroConvocatoriaState,
} from '../../slices/registroConvocatoriaSlice';
import {
    ALERT_FAIL,
    ALERT_SUCCESS,
    DATOS_PARTICIPACION_STEP,
    DIRECCION_ENTREGA_STEP,
    DOCUMENTOS_STEP,
    INVITACIONES_STEP,
    IS_INVITACION,
    MEMBRESIA_STEP,
} from '../../app/constantes';
import {
    getConvocatoriaByIdConvocatoria,
    postCobroRegistroConvocatoria,
    putCobroRegistroConvocatoria,
} from '../../services/convocatorias';
import { appState, setAlert, setLoading } from '../../slices/appSlice';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { Cobros } from './ServiciosAdicionales/Cobros';
import Total from './ServiciosAdicionales/Total';

import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { getCobroConvocatoria } from '../../services/cobro';
import { formatCurrency, isConvocatoriaInsignia } from '../../app/commons';
import { getTips } from '../../services/catalogos';
import { getEntidad } from '../../services/entidad';

export default function ServiciosAdicionales() {
    const dispatch = useDispatch();
    const registroConvocatoriaSt = useSelector(registroConvocatoriaState);
    const appSt = useSelector(appState);
    const catalogosSt = useSelector(catalogosServiceState);
    const [selectedCobros, setSelectedCobros] = useState({});
    const [montoConvocatoria, setMonto] = useState(null);
    const [montoConvocatoriaDescuento, setMontoDescuento] = useState(null);
    const [tipRetro, setRetroTip] = useState(null);
    const [tipEstatuilla, setEstatuillaTip] = useState(null);
    const [adicionales, setAdicionales] = useState(null);

    const datosServiciosAdicionales = {
        formatoDocumentos: {
            tool: ''
        },
        adicionalesNoObligatorios: {
            tool: ''
        }
    }

    const [messages] = useState(datosServiciosAdicionales);

    const handleChange = (idEntidad, serviciosSeleccionados) => {
        let obj = {};

        if (!selectedCobros.hasOwnProperty(idEntidad)) {
            obj[idEntidad] = serviciosSeleccionados;
        }

        obj = { ...selectedCobros };

        obj[idEntidad] = serviciosSeleccionados;

        setSelectedCobros((prevState) => ({ ...prevState, ...obj }));
    };

    const submitServicios = () => {
        dispatch(setLoading(true));
        const entitiesArray = [];
        for (let property in selectedCobros) {
            let cobros = [];

            for (let property2 in selectedCobros[property]) {
                if (property2 === 'current') continue;
                if (
                    selectedCobros[property][property2] &&
                    selectedCobros[property][property2].cantidad === 0 &&
                    !selectedCobros[property][property2]
                        .idCobroRegistroConvocatoria
                )
                    continue;

                cobros.push(selectedCobros[property][property2]);
            }

            entitiesArray.push({ idEntidad: property, cobros });
        }

        const promises = [];
        entitiesArray.forEach((entity) => {
            if (entity.cobros) {
                const cobros = entity.cobros.map((cobroConcept) => {
                    if (cobroConcept && !cobroConcept.idCobroRegistroConvocatoria) {
                        return postCobroRegistroConvocatoria(
                            {
                                ...appSt.serviceData,
                            },
                            cobroConcept
                        );
                    } else {
                        let putBody = cobroConcept;
                        if (cobroConcept.cantidad === 0) {
                            putBody.idEstatusRegistro = 0
                        }
                        return putCobroRegistroConvocatoria(
                            {
                                ...appSt.serviceData,
                            },
                            putBody
                        );
                    }
                });
                promises.push(...cobros);
            }
        });

        Promise.allSettled(promises).then((results) => {
            if (results.find(item => item.status === 'rejected')) {
                dispatch(setAlert({
                    show: true,
                    message: 'Error al guardar servicios adicionales',
                    type: ALERT_FAIL
                }))
            } else {
                dispatch(
                    setAlert({
                        show: true,
                        message: 'Servicios Adicionales guardados',
                        type: ALERT_SUCCESS,
                    })
                );
                if (isConvocatoriaInsignia(registroConvocatoriaSt.convocatoriaSeleccionada)) {
                    if (registroConvocatoriaSt.membresiaActiva) {
                        dispatch(changeStep(MEMBRESIA_STEP));
                    } else {
                        dispatch(changeStep(DOCUMENTOS_STEP));
                    }
                } else {
                    dispatch(changeStep(DIRECCION_ENTREGA_STEP));
                }
            }
            dispatch(setLoading(false));
        });
    };

    const {
        convocatoriaSeleccionada,
        empresaSeleccionada,
        entidadesAdicionales,
    } = registroConvocatoriaSt;
    const [conceptosCobros, setConceptosCobros] = useState(null);

    const prevStep = () => {
        if (convocatoriaSeleccionada.isInvitacion === IS_INVITACION.TRUE) {
            dispatch(changeStep(INVITACIONES_STEP));
        } else {
            dispatch(changeStep(DATOS_PARTICIPACION_STEP));
        }
    };

    const nextStep = () => {
        submitServicios();
    };

    const getCobroByEntidad = async (idEntidad, idModoParticipacion) => {
        return await getCobroConvocatoria({
            ...appSt.serviceData,
            idModalidadPago: catalogosSt.idModoPagoTotal,
            idModoParticipacion,
            idConvocatoria: convocatoriaSeleccionada.idConvocatoria,
            idEntidad
        });
    }

    /**
     * Obtenermos cuota de participacion
     */
    const getCobro = async () => {
        const resp = await getCobroByEntidad(registroConvocatoriaSt.empresaSeleccionada.idEntidad, convocatoriaSeleccionada.idModoParticipacion)
        
        if (resp) {
            setMonto(resp);
        }
    }

    const populateCobrosAdicionales = async () => {
        const adds = [];
        // la creacion de adicionales tarda un poco, por lo q se agrego el timeout para darle tiempo a la creacion de los adicionales
        setTimeout(async () => {
            dispatch(setLoading(true));
            for (const entidad of entidadesAdicionales)  {
                const entidadData = await getEntidad({...appSt.serviceData, idEntidad: entidad.idEntidad});
                if (entidadData.payload) {
                    const registro = entidadData.payload.cemRegistroConvocatorias.find(registro => registro.idConvocatoria === convocatoriaSeleccionada.idConvocatoria);
                    if (registro) {
                        const resp = await getCobroByEntidad(entidad.idEntidad, registro.idModoParticipacion);
                        if (resp && resp.monto !== null) {
                            adds.push({...entidadData.payload, monto: resp.monto, descMonto: resp.descCuotaParticipacion, isAcompaniante: entidad.isAcompaniante});
                        } else {
                            adds.push({...entidadData.payload, monto: 0});
                        }
                    } else {
                        console.log('no hay registro')
                    }
                }
            }   
            dispatch(setLoading(false));
            setAdicionales(adds);
        }, 2000)
    }

    const populateTips = async () => {
        if (!tipRetro) {
            const tipRetroResp = await getTips({
                ...appSt.serviceData,
                claveCatalogo: 'parametro',
                clave: 'RETROALIMENTACIÓN'
            });

            if (tipRetroResp.payload) {
                setRetroTip(tipRetroResp.payload.valor);
            }
        }
        if (!tipEstatuilla) {
            const estatuillaResp = await getTips({
                ...appSt.serviceData,
                claveCatalogo: 'parametro',
                clave: 'ESTATUILLAS'
            });

            if (estatuillaResp.payload) {
                setEstatuillaTip(estatuillaResp.payload.valor);
            }
        }
    }

    useEffect(() => {
        if (convocatoriaSeleccionada.idRegistroConvocatoria === null) return;

        const data = {
            ...appSt.serviceData,
            idConvocatoria: convocatoriaSeleccionada.idRegistroConvocatoria,
            idPrograma: convocatoriaSeleccionada.idPrograma
        };
        dispatch(setLoading(true));
        getCobro();
        getConvocatoriaByIdConvocatoria(data).then((resp) => {
            dispatch(setLoading(false));
            setConceptosCobros(resp);
        });
        populateTips()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (catalogosSt.parametrosConvocatoria !== null && catalogosSt.parametrosConvocatoria !== undefined) {
            catalogosSt.parametrosConvocatoria.forEach(item => {
                if (item.nombreParametro === 'MENSAJE_SERVICIOS_ADICIONALES_NO_OBLIGATORIOS') {
                    messages.adicionalesNoObligatorios.tool = item.valor;
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogosSt.parametros])
    
    useEffect(() => {
        if (entidadesAdicionales && entidadesAdicionales.length > 0) {
            populateCobrosAdicionales();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entidadesAdicionales])

    // if (conceptosCobros?.length === 0)
    //     return (
    //         <div className="registro-step servicios-adicionales">
    //             <div className="content" style={{ minHeight: '200px' }}>
    //                 <h3>
    //                     No hay servicios adicionales disponibles para esta
    //                     convocatoria.
    //                 </h3>
    //             </div>
    //             <div className="actions">
    //                 <button onClick={prevStep}>Regresar</button>
    //                 <button onClick={nextStep}>Continuar</button>
    //             </div>
    //         </div>
    //     );

    return (
        registroConvocatoriaSt.convocatoriaSeleccionada
            .idRegistroConvocatoria &&
        empresaSeleccionada.cemRegistroConvocatorias.length > 0 && (
            <div className="registro-step servicios-adicionales">
                <div className="tip">
                    <InfoIcon />
                    <p>{messages.adicionalesNoObligatorios.tool}</p>
                </div>

                {montoConvocatoria &&
                    <div className="card-registro">
                        <div className="head">
                            <CompletedIcon />
                            <h3>Cuota de Participación</h3>
                        </div>
                        <div className="content">
                            <div className="chip full">
                                <CheckIcon />
                                <div className="text">
                                    <label>{montoConvocatoria.descCuotaParticipacion}</label>
                                    <p>{formatCurrency(montoConvocatoriaDescuento ? montoConvocatoriaDescuento : montoConvocatoria.monto)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {conceptosCobros?.length === 0 ?
                    <div className="registro-step servicios-adicionales">
                        <div className="content" style={{ minHeight: '200px' }}>
                            <h3>
                                No hay servicios adicionales disponibles para esta
                                convocatoria.
                            </h3>
                        </div>
                    </div>
                    : <div className="card-registro">
                        <div className="head">
                            <EmpresaIcon />
                            <h3>Servicios Adicionales (opcionales) Empresa Principal</h3>
                        </div>
                        <div className="content">
                            <div className="two-col">
                                {conceptosCobros && (
                                    <Cobros
                                        setMontoDescuento={setMontoDescuento}
                                        retroTip={tipRetro}
                                        estatuillaTip={tipEstatuilla}
                                        entidad={empresaSeleccionada}
                                        handleChange={handleChange}
                                        conceptos={conceptosCobros}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                }



                {/* TODO: En caso "empresa acompañante" */}
                {(!!empresaSeleccionada.isCorporativo || empresaSeleccionada.isSedeMarca || empresaSeleccionada.isMiembro) &&
                    !!entidadesAdicionales && adicionales &&
                    adicionales.map((entidad, index) => {
                        return (
                            <div className="card-registro" key={index}>
                                <div className="head">
                                    <EmpresaAdIcon />
                                    <h3> 
                                        Servicios Adicionales (opcionales)
                                        {entidad.isAcompaniante
                                            ? 'Empresa Acompañante'
                                            : 'Empresa Adicional'}
                                        : {entidad.nombreComercial}
                                    </h3>
                                </div>
                                <div className="content">
                                    <div className="chip full">
                                        <CheckIcon />
                                        {montoConvocatoria &&
                                            <div className="text">
                                                <label>{entidad.descMonto}</label>
                                                <p>{formatCurrency(entidad.monto)}</p>
                                            </div>
                                        } 
                                        {montoConvocatoria && entidad.monto === null && 
                                         <div className="text">
                                            <label>Sin Monto Registrado</label>
                                            {/* <p>{formatCurrency(entidad.monto)}</p> */}
                                        </div>
                                        }
                                    </div>
                                    <div className="two-col">
                                        {conceptosCobros && (
                                            <Cobros
                                                retroTip={tipRetro}
                                                estatuillaTip={tipEstatuilla}
                                                entidad={entidad}
                                                handleChange={handleChange}
                                                conceptos={conceptosCobros}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                {montoConvocatoria &&
                    <Total adicionales={adicionales} selectedItems={selectedCobros} costoInicial={montoConvocatoriaDescuento ? montoConvocatoriaDescuento : montoConvocatoria.monto} />
                }

                <div className="actions">
                    <button onClick={prevStep}>Regresar</button>
                    <button onClick={nextStep}>Continuar</button>
                </div>
            </div>
        )
    );
}