/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux"
import { usuariosServicesState } from "../../slices/usuariosServicesSlice"
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { useEffect, useState } from "react";
import { CatalogoGenero, DatosBasicosForm } from "../../app/forms/registro/datos-basicos";
import InputComp from "../generales/inputComponent";
import DireccionComponent from "../generales/direccionComponent";
import { direccionFormRequired } from "../../app/forms/registro/direccion";
import { postConsultorDireccion, putConsultor, putConsultorDireccion } from "../../services/entidad";
import { appState, setAlert } from "../../slices/appSlice";
import { fillForm, formatForm, validateForm } from "../../app/commons";
import { ALERT_FAIL, ALERT_SUCCESS, CVE_ESTATUS_CONSULTOR } from "../../app/constantes";
import { getConsultorData, registroConsultorState } from "../../slices/registroConsultor";

export default function DatosBasicosConsultor({onlyView}) {
    const dispatch = useDispatch()
    const appSt = useSelector(appState)
    const userSt = useSelector(usuariosServicesState)
    const consultorSt = useSelector(registroConsultorState)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(DatosBasicosForm)))
    const [formDireccion, setFormDireccion] = useState(JSON.parse(JSON.stringify(direccionFormRequired)))

    const handleSubmit = async () => {
        const formValid = validateForm(form)
        const direccionValid = validateForm(formDireccion)
        if (formValid.length === 0 && direccionValid.length === 0) {
            const formatedForm = formatForm(form)
            const formatedDireccion = formatForm(formDireccion)
            await putConsultor({
                ...appSt.serviceData,
                idConsultorAcreditado: userSt.persona.idPersona,
                body: {
                    ...formatedForm, 
                    cveEstatusConsultorAcreditado: CVE_ESTATUS_CONSULTOR.invitado,
                    idConsultorAcreditado: userSt.persona.idPersona
                }
            })
            if (consultorSt.consultorData.cemDireccionConsultors.length > 0) {
                putConsultorDireccion({
                    ...appSt.serviceData,
                    idDireccionConsultor: consultorSt.consultorData.cemDireccionConsultors[0].idDireccionConsultor,
                    body: {
                        ...formatedDireccion, 
                        idConsultorAcreditado: consultorSt.consultorData.idConsultorAcreditado
                    }
                })
            } else {
                await postConsultorDireccion({
                    ...appSt.serviceData,
                    body: {
                        ...formatedDireccion, 
                        idConsultorAcreditado: consultorSt.consultorData.idConsultorAcreditado
                    }
                })
            }
            dispatch(getConsultorData({
                ...appSt.serviceData,
                idConsultorAcreditado: userSt.persona.idPersona
            }))
            dispatch(setAlert({
                show: true,
                message: "Datos guardados",
                type: ALERT_SUCCESS
            }))
        } else {
            dispatch(setAlert({
                show: true,
                message: "Favor de llenar los campos requeridos",
                type: ALERT_FAIL
            }))
        }
    }

    useEffect(() => {
        if (consultorSt.consultorData) {
            const clone = fillForm(form, consultorSt.consultorData)
            setForm(clone)
            if (consultorSt.consultorData.cemDireccionConsultors.length > 0) {
                const cloneDir = fillForm(formDireccion, consultorSt.consultorData.cemDireccionConsultors[0])
                setFormDireccion(cloneDir)
            }
        }
    }, [consultorSt.consultorData])

    return (<section>
        <div className="tip">
            <InfoIcon />
           <p>Favor de anotar su nombre completo y RFC conforme a su constancia de situación fiscal.</p>
        </div>
       
        <div className="sub-section">
            <h4>Datos Personales y de contacto</h4>
            <div className="four-col">
                {userSt.usuario && <>
                    <div className="dato">
                        <label>RFC</label>
                        <p>{userSt.usuario.data.rfc}</p>
                    </div>
                    <div className="dato">
                        <label>Nombre</label>
                        <p>{userSt.usuario.data.nombre}</p>
                    </div>
                    <div className="dato">
                        <label>Apellido Paterno</label>
                        <p>{userSt.usuario.data.apellidoPaterno}</p>
                    </div>
                    <div className="dato">
                        <label>Apellido Materno</label>
                        <p>{userSt.usuario.data.apellidoMaterno}</p>
                    </div>
                </>}
                <InputComp disabled={onlyView} label="Genero" name="genero" type="select" catalogos={CatalogoGenero} catalogoLabel={"label"} catalogoValue={"value"} form={form} setForm={setForm} />
                <InputComp disabled={onlyView} label="Fecha de Nacimiento" name="fechaNacimiento" type="date" form={form} setForm={setForm} />
                <InputComp disabled={onlyView} label="Correo Personal" name="correoPersonal" type="text" form={form} setForm={setForm} />
                <InputComp disabled={onlyView} label="Correo Profesional" name="correoProfesional" type="text" form={form} setForm={setForm} />
                <InputComp disabled={onlyView} label="Tel. Fijo" name="telefonoFijo" type="number" max={20} form={form} setForm={setForm} />
                <InputComp disabled={onlyView} label="Ext." name="extension" type="number" max={4} form={form} setForm={setForm} />
                <InputComp disabled={onlyView} label="Tel. Movil" name="telefonoMovil" type="number" max={20} form={form} setForm={setForm} />
                <InputComp disabled={onlyView} label="Último Grado de Estudio" name="ultimoGradoEstudio" type="upper" form={form} setForm={setForm} />
                <InputComp disabled={true} label="Fecha Incio Acreditación" name="fechaInicioAcreditacion" type="date" form={form} setForm={setForm} />
                <InputComp disabled={true} label="Fecha Fin Acreditación" name="fechaFinAcreditacion" type="date" form={form} setForm={setForm} />
                {/* <InputComp disabled={onlyView} label="Fecha Inhabilitación" name="fechaInhabilitacion" type="date" form={form} setForm={setForm} /> */}
                <InputComp disabled={true} label="Años de Consultor" name="aniosConsultor" type="number" form={form} setForm={setForm} />
                {/* <InputComp disabled={onlyView} label="Fecha Invitación" name="fechaInvitacion" type="date" form={form} setForm={setForm} /> */}
            </div>
        </div>

        <DireccionComponent disabled={onlyView} aviso={null}  cveDireccion={"consultor"} form={formDireccion} setForm={setFormDireccion}/>
        <div className="tip">
            <InfoIcon />
           <p>Favor de tomar en cuenta que los campos de correo profesional y teléfono móvil serán utilizados para la publicación en el directorio de consultores acreditados.</p>
        </div>
        {!onlyView &&
            <div className="actions">
                <button onClick={handleSubmit}>Guardar</button>
            </div>
        }
    </section>)
}