import React, { useEffect, useState } from 'react';
import { handleChangeGlobal } from '../../app/commons';

const InputComp = ({ 
    label, 
    name, 
    type, 
    form, 
    setForm, 
    max, 
    disabled, 
    catalogos, 
    validation, 
    placeholder, 
    catalogoValue, 
    catalogoLabel,
    onBlurAction,
    className,
    emptyValue,
    secundaryLabel
}) => {
    /**
     * State for money format
     */
    const [realValue, setRealValue] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);
    const [error, setError] = useState(false);
    const [moneyValue, setValueMoney] = useState('');
    const [check, setCheck] = useState(false);

    /**
     * Check the input so only numbers can be input
     * Clean the input for the realValue
     * @param {*} e 
     */
    const formatMoneyChange = (e) => {
        const target = e.target;
        const regexInput = /[^\d.]/g;
        target.value = target.value.replace(regexInput, '');

        const regexRealValue = /[$,]/g;
        const v = target.value.replace(regexRealValue, '')
        setRealValue(v);
    }

    /**
     * Format the currency input for the user when lost focus
     * @param {*} e 
     */
    const formatMoneyBlur = (e) => {
        const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
        const formatValue = formatter.format(realValue);
        setValueMoney(formatValue);
        setForm({ ...form, [name]: { ...form[name], value: Number(realValue) } });
    }

    /**
     * Validate Input, if is empty or a custom regex validation
     */
    const validateInput = (e) => {
        const value = e.target.value;
        setError(false);
        form[name].invalid = false;

        if (form[name].required && value === '') {
            setError(true);
        }

        if (validation && validation !== '') {
            switch (validation) {
                case "mail":
                    const regexMail = new RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,11}){1,2}$/gm);
                    const resultMail = regexMail.test(value);
                    if (!resultMail) {
                        form[name].invalid = true;
                        setError(true);
                    }                    
                    break;
                case "phone":
                    if (value.length !== 10) {
                        form[name].invalid = true;
                        setError(true);
                    }                    
                    break;
                case "cel":
                        if (value.length !== 10) {
                            form[name].invalid = true;
                            setError(true);
                        }                    
                        break;
                case "razonSocial":
                        const regexRazon = new RegExp(/\s{2,}|^ | $/g);
                        const resultRazon = regexRazon.test(value);
                        if (resultRazon) {
                            form[name].invalid = true;
                            setError(true);
                        }    
                        break;
                default:
                    const regex = new RegExp(validation);
                    const result = regex.test(value);
                    if (!result) {
                        form[name].invalid = true;
                        setError(true);
                    }
                    break;
            }
        }
    }

    /**
     * Trigger out of focus validations
     * @param {*} e 
     */
    const triggerBlur = (e) => {
        setShowTooltip(false);
        validateInput(e);
        if (onBlurAction) {
            onBlurAction(e);
        }
    }

    const handleInput = (e) => {
        let maxLength = Number(max)
        if (max && e.target.value.length > maxLength) {
            e.target.value = e.target.value.slice(0,maxLength); 
        }
    }

    const getCatalogLabel = (option) => {
        let value;
        if (catalogoLabel !== 'combine') {
            value = option[catalogoLabel];
            if (secundaryLabel) {
                value = option[secundaryLabel] + " " + value 
            }
        } else {
            value = `${option.nombre} ${option.apellidoPaterno} ${option.apellidoMaterno}`
        }
        return value
    }

    /**
     * React Hook 
     */
    useEffect(() => {
        if (type === 'money') {
            setValueMoney(`$${realValue}`);
        }
    }, [name, type, realValue])

    useEffect(() => {
        if (type === 'money' && (realValue === '' || realValue === 0) && form[name] && form[name].value !== 0) {
            setRealValue(form[name].value)
        } else if (type === 'money' && realValue !== 0 && form[name] && form[name].value === 0) {
            setRealValue(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form])

    useEffect(() => {
        if (type === 'checkbox' && form[name]) {
            form[name].value === true || form[name].value === 'true' ? setCheck(true) : setCheck(false)
        }
        if (!form[name]) {
            console.log('no hay registro', name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form[name]])

    const checkClassName = type === 'checkbox' ? 'check' : '';
    const errorClassName = error ? 'error' : '';
    return (
        <div className={`input-wrapper ${checkClassName} ${errorClassName} ${className ? className : ''}`}>
            {form[name] &&
                <>
                    {form[name].tool !== '' &&
                        <p className={showTooltip ? 'tool-desc show' : 'tool-desc'}>{form[name].tool}</p>
                    }
                    <label>
                        {form[name].required &&
                            <span>*</span>
                        }
                        {label}
                        {form[name].tool !== '' &&
                            <span onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} className='tool-tip'>?</span>
                        }
                    </label>
                    {type !== 'money' && type !== 'checkbox' && type !== 'select' &&
                        <input
                            placeholder={placeholder}
                            maxLength={max ? max : ''}
                            disabled={disabled}
                            onFocus={() => setShowTooltip(true)}
                            onBlur={(event) => triggerBlur(event)}
                            value={form[name].value}
                            onInput={(event) => handleInput(event)}
                            onChange={(event) => handleChangeGlobal(event, setForm, form, type)}
                            type={(type === 'upper' || type === 'lower') ? 'text' : type}
                            name={name} />
                    }

                    {type === 'checkbox' &&
                        <input
                            checked={check}
                            value={form[name].value}
                            onChange={(event) => handleChangeGlobal(event, setForm, form, type)}
                            type={(type === 'upper' || type === 'lower') ? 'text' : type}
                            name={name} />
                    }
                    {type === 'money' &&
                        <>
                            <input
                                type="text"
                                value={moneyValue}
                                onBlur={(e) => formatMoneyBlur(e)}
                                onChange={(e) => formatMoneyChange(e)} />
                        </>
                    }
                    {type === 'select' && 
                        <>
                            <select 
                                value={form[name].value} 
                                onFocus={() => setShowTooltip(true)}
                                onBlur={(event) => triggerBlur(event)}
                                name={name} 
                                disabled={disabled}
                                onChange={(event) => handleChangeGlobal(event, setForm, form, type)}
                            >
                                <option value="">{emptyValue ? emptyValue : ''}</option>
                                {catalogos && catalogos.map((option, index) => {
                                    return <option key={index} value={option[catalogoValue]}>{getCatalogLabel(option)}</option>
                                })}
                            </select>
                        </>
                    }

                    {error && <p className='error-message'>{form[name].value === '' ? 'Dato Requerido' : form[name].message}</p>}
                </>
            }
        </div>
    )
}

export default InputComp;