import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { INFORMACION_GENERAL_STEP } from '../app/constantes';
import { getEntidad } from '../services/entidad';

const initialState = {
    loading: false,
    etapa: INFORMACION_GENERAL_STEP,
    isSede: false,
    documentosCompleted: false,
    empresaSeleccionada: null,
    modalVinculacionEntidad: {
        show: false,
        data: null
    }
}

export const updateEmpresaSeleccionada = createAsyncThunk('registro/updateEmpresaSeleccionada', async (data, {rejectWithValue}) => {
    let resp;
    try {
        resp = await getEntidad(data); 
        console.log('ent', resp)
    } catch (error) {
        console.log(error.response); 
        return rejectWithValue({success: false})
    }
    return resp;
})

export const registroSlice = createSlice({
    name: 'registro',
    initialState,
    reducers: {
        changeStep: (state, action) => {
            state.etapa = action.payload
        },
        setSede: (state, action) => {
            state.isSede = action.payload
        },
        selectEmpresa: (state, action) => {
            state.empresaSeleccionada = action.payload
        },
        changeModalVinculacionEntidad: (state, action) => {
            state.modalVinculacionEntidad = action.payload
        },
        changeDocumentosCompleted: (state, action) => {
            state.documentosCompleted = action.payload
        },
        resetStatus: (state) => {
            state.informacionGeneralCompleted = false;
            state.datosContactoCompleted = false;
            state.datosIndustriaCompleted = false;
            state.redesCompleted = false;
        },
        resetRegistro: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(updateEmpresaSeleccionada.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(updateEmpresaSeleccionada.rejected, (state) => {
            state.loading = false;
        })
        builder.addCase(updateEmpresaSeleccionada.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload.payload) {
                state.empresaSeleccionada = action.payload.payload
            } else {
                state.empresaSeleccionada = {
                    error: true
                }
            }
        })
    }
})

export const { changeStep, selectEmpresa, setSede, resetStatus, changeModalVinculacionEntidad, changeDocumentosCompleted, resetRegistro } = registroSlice.actions;
export const registroState = (state) => state.registro;

export default registroSlice.reducer;