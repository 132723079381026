/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { publicacionState } from "../../slices/publicacionSlice";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import '../../styles/publicacion.scss';
import { ALERT_FAIL, ALERT_SUCCESS, CVES_TIPODOCS_USOLOGO, CVE_TIPODOC_ENTIDAD, CVE_TIPODOC_USOLOGO } from "../../app/constantes";
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { checkAcceptArray, getBase64 } from "../../app/commons";
import { catalogosServiceState, getDocumentosCartaLogoCatalogo, getEstatusCartaLogo, getTipoDocumentoCemefi } from "../../slices/catalogosServiceSlice";
import { deleteDocumentoUsoLogo, getCarta, getCartaNoObtencion, getReglasUsoLogo, postCarta, postDocumentoUsoLogo, putCarta, putDocumentoUsoLogo } from "../../services/publicacion";
import { deleteDocumentoEntidad, getDocsUsoLogo, getDocument, getEntidad, postDocumentoEntidad, putDocumentoEntidad, uploadDocument } from "../../services/entidad";
import ManualLogo from '../../assets/docs_dictamen/paso_4.pdf';
import CartaAcep from '../../assets/docs_dictamen/paso_1.docx';
import LogoESR from '../../assets/docs_dictamen/paso_5.zip';
import InstruccionesLogo from '../../assets/docs_dictamen/paso_2.pdf';
import ModalSeleccionarContacto from "./modal-seleccion-contacto";

export default function CartaLogo({ back, empresaSeleccionada }) {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const publicacionSt = useSelector(publicacionState);
    const catalogosSt = useSelector(catalogosServiceState);
    const [cartaAceptacion, setCartaAceptacion] = useState(null);
    const [cartaDictamen, setCartaDictamen] = useState(null);
    const [cartaUsoLogo, setCartaUsoLogo] = useState(null);
    const [reglasDoc, setReglasDoc] = useState(null);
    const [documentos, setDocuments] = useState(null);
    const [docsUsoLogo, setDocsUsoLogo] = useState(null);
    const [canEdit, setCanEdit] = useState(false);
    const [cartaConstitucional, setCartaConstitucional] = useState(null);
    const [modalData, setModalData] = useState({
        show: false,
        data: null
    });

    const sendToValidate = async() => {
        dispatch(setLoading(true))
        try {
            await putCarta({
                ...appSt.serviceData,
                idRegistroConvocatoria: publicacionSt.currentConvocatoria.idRegistroConvocatoria,
                body: {
                    idRegistroConvocatoria: publicacionSt.currentConvocatoria.idRegistroConvocatoria,
                    idEstatusCartaUsoLogo: catalogosSt.idsEstatusCartaLogo.sinValidar
                }
            })
            dispatch(setLoading(false))
            dispatch(setAlert({
                show: true,
                message: "Documentos enviados a validar",
                type: ALERT_SUCCESS
            }))
            getData()

        } catch (error) {
            dispatch(setLoading(false))     
            dispatch(setAlert({
                show: true,
                message: "Error al enviar documentos a validar",
                type: ALERT_SUCCESS
            }))       
        }
        
    }

    /**
     * Guardamos el documento en la tabla
     */
    const saveCarta = async (type, cartaData, doc) => {
        const cartaBody = {
            idDocumentoCemefi: type.idDocumentoCemefi,
            idCartaUsoLogo: cartaData[0].idCartaUsoLogo,
            rutaArchivo: doc.payload.contentId
        }

        if (type.cveDocumentoCemefi === CVES_TIPODOCS_USOLOGO.cartaLogo) {
            if (!cartaAceptacion) {
                await postDocumentoUsoLogo({
                    ...appSt.serviceData,
                    body: cartaBody
                });
            } else {
                await putDocumentoUsoLogo({
                    ...appSt.serviceData,
                    body: cartaBody,
                    idDocumentoUsoLogo: cartaAceptacion.idDocumentoUsoLogo
                })
            }
        }

        if (type.cveDocumentoCemefi === CVES_TIPODOCS_USOLOGO.reglasUso) {
            if (!reglasDoc) {
                await postDocumentoUsoLogo({
                    ...appSt.serviceData,
                    body: cartaBody
                });
            } else {
                await putDocumentoUsoLogo({
                    ...appSt.serviceData,
                    body: cartaBody,
                    idDocumentoUsoLogo: reglasDoc.idDocumentoUsoLogo
                })
            }
        }

        if (type.cveDocumentoCemefi === CVES_TIPODOCS_USOLOGO.cartaDictamen) {
            if (!cartaDictamen) {
                await postDocumentoUsoLogo({
                    ...appSt.serviceData,
                    body: cartaBody
                });
            } else {
                await putDocumentoUsoLogo({
                    ...appSt.serviceData,
                    body: cartaBody,
                    idDocumentoUsoLogo: cartaDictamen.idDocumentoUsoLogo
                })
            }
        }

    }

    /**
     * Guardamos el documento
     */
    const uploadFile = async (e, type, cveTipoDocumento) => {
        dispatch(setLoading(true));
        try {
            const file = e.target.files[0];
            const extencion = `.${file.name.split('.').pop().toLowerCase()}`;
            const extencions = checkAcceptArray(type);
            let valid = false;
            extencions.acceptArray.forEach(ext => {
                if (extencion.includes(ext)) {
                    valid = true;
                }
            })
            if (file && valid) {
                const base64 = await getBase64(file);
                const body = {
                    cveTipoDocumento,
                    cveDocumentoCemefi: type.cveDocumentoCemefi,
                    archivo: {
                        nombreArchivo: file.name.replace('#', ''),
                        tipo: file.type,
                        base64: base64
                    }
                }
                const sdata = {
                    ...appSt.serviceData,
                    idEntidad: empresaSeleccionada.idEntidad
                }

                const resp = await uploadDocument(sdata, body);

                if (resp.payload) {                    
                    if (cveTipoDocumento === CVE_TIPODOC_ENTIDAD) {
                        const bodyDocumentoEntidad = {
                            idDocumentoCemefi: cartaConstitucional.idDocumentoCemefi,
                            idContent: resp.payload.contentId,
                            idEntidad: empresaSeleccionada.idEntidad 
                        }
                        if (cartaConstitucional.idContent) {
                            await putDocumentoEntidad({
                                ...appSt.serviceData, 
                                idDocumentoEntidad: cartaConstitucional.idDocumentoEntidad
                            }, bodyDocumentoEntidad)
                        } else {
                            await postDocumentoEntidad(appSt.serviceData, bodyDocumentoEntidad)
                        }
                    } else {
                        const cartaResp = await postCarta({
                            ...appSt.serviceData,
                            body: {
                                idRegistroConvocatoria: publicacionSt.currentConvocatoria.idRegistroConvocatoria,
                                idEstatusCartaUsoLogo: catalogosSt.idsEstatusCartaLogo.pendiente,
                            }
                        });
                        await saveCarta(type, cartaResp, resp)
                    }
                    dispatch(setLoading(false));
                    getData();
                } 
            } else {
                dispatch(setLoading(false));
                dispatch(setAlert({
                    show: true,
                    message: 'Documento invalido',
                    type: ALERT_FAIL
                }))
            }
        } catch (error) {
            console.log(error);
            dispatch(setLoading(false));
            dispatch(setAlert({
                show: true,
                message: 'Error al subir carta',
                type: ALERT_FAIL
            }))
        }
    }

    /**
     * Eliminar documento
     */
    const deleteFile = async (type, idDocumentoUsoLogo) => {
        dispatch(setLoading(true));
        try {
            if (type === 'const') {
                await deleteDocumentoEntidad({
                    ...appSt.serviceData,
                    idDocumentoEntidad: cartaConstitucional.idDocumentoEntidad
                });
            } else {
                await deleteDocumentoUsoLogo({
                    ...appSt.serviceData,
                    idDocumentoUsoLogo
                });
            }
            getData();
            dispatch(setAlert({
                show: true,
                message: 'Documento Eliminado',
                type: ALERT_SUCCESS
            }));
        } catch (error) {
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: 'Error al eliminar documento',
                type: ALERT_FAIL
            }))
        }
    }

    const downloadFile = async (fileName, file) => {
        console.log(file)
        const docResp = await getDocument({
            ...appSt.serviceData,
            idEntidad: empresaSeleccionada.idEntidad,
            idContent: file.rutaArchivo ? file.rutaArchivo : file.idContent
        });


        if (docResp.payload) {
            const type = file.rutaArchivo ? file.rutaArchivo.split('.').pop() : file.idContent.split('.').pop();
            let dataType;
            switch (type) {
                case 'pdf':
                    dataType = 'data:application/pdf'
                    break;
                case 'docx':
                    dataType = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    break;
                default:
                    dataType = `data:image/${type}`
                    break;
            }
            // const type = 'data:image/png'
            const file64 = docResp.payload.base64;
            const linkSource = `${dataType};base64,${file64}`;
            const downloadLink = document.createElement('a');

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click()
        }
    }

    /**
     * Descargar no obtencion
     */
    const downloadNoObtencion = async () => {
        dispatch(setLoading(true));
        try {
            const blob = await getCartaNoObtencion({
                ...appSt.serviceData,
                idRegistroConvocatoria: publicacionSt.currentConvocatoria.idRegistroConvocatoria,
            });            
    
            if (blob) {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'Carta no obtención';
                // some browser needs the anchor to be in the doc
                document.body.append(link);
                link.click();
                link.remove();
                // in case the Blob uses a lot of memory
                setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            }            
        } catch (error) {
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: "Error al obtener carta de no obtención",
                type: ALERT_FAIL
            }))            
        }
        dispatch(setLoading(false));
    }

    /**
     * Descargar reglas
     */
    const downloadReglasUso = async () => {
        dispatch(setLoading(true));
        try {
            const blob = await getReglasUsoLogo({
                ...appSt.serviceData,
                idEntidad: empresaSeleccionada.idEntidad,
            });            
    
            if (blob) {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'Reglas de usos logotipo';
                // some browser needs the anchor to be in the doc
                document.body.append(link);
                link.click();
                link.remove();
                // in case the Blob uses a lot of memory
                setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            }            
        } catch (error) {
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: "Error al obtener archivo",
                type: ALERT_FAIL
            }))            
        }
        dispatch(setLoading(false));
    }

    /**
     * Descargamos documentos
     * @param {*} item 
     */
    const downloadDoc = async (item) => {
        let linkSource;
        const downloadLink = document.createElement('a');
        let fileName;

        switch (item.cveDocumentoCemefi) {
            case CVES_TIPODOCS_USOLOGO.manualUso:
                linkSource = ManualLogo
                fileName = "MANUAL_USO"
                break;
            case CVES_TIPODOCS_USOLOGO.cartaLogo:
                linkSource = CartaAcep
                fileName = "CARTA_ACEPTACION"
                break;
            case CVES_TIPODOCS_USOLOGO.logoEsr:
                linkSource = LogoESR
                fileName = "LOGO_ESR"
                break;
            case CVES_TIPODOCS_USOLOGO.reglasUso:
                downloadReglasUso()       
                break;
            case CVES_TIPODOCS_USOLOGO.instruccionesUso:
                linkSource = InstruccionesLogo
                fileName = "INSTRUCCIONES_LOGO"
                break;
            case CVES_TIPODOCS_USOLOGO.cartaDictamen:
                if (!publicacionSt.currentConvocatoria.publicacion.isPublicada) {
                    downloadNoObtencion()                    
                } else {
                    setModalData({
                        show: true,
                        data: item
                    })
                }
                break;

            default:
                break;
        }

        if (item.cveDocumentoCemefi !== CVES_TIPODOCS_USOLOGO.cartaDictamen && item.cveDocumentoCemefi !== CVES_TIPODOCS_USOLOGO.reglasUso) {
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click()
        }

    }

    const formatDocuments = () => {
        const docs = []
        catalogosSt.tiposDocumentoCartaLogo.forEach(item => {
            if (item.cveDocumentoCemefi !== CVES_TIPODOCS_USOLOGO.logo) {
                const formatedDoc = {
                    ...item
                }
                switch (item.cveDocumentoCemefi) {
                    case CVES_TIPODOCS_USOLOGO.cartaLogo:
                        formatedDoc.order = 1
                        formatedDoc.desc = "Paso 1: DESCARGA CARTA DE ACEPTACIÓN"
                        break;
                    case CVES_TIPODOCS_USOLOGO.instruccionesUso:
                        formatedDoc.order = 2
                        formatedDoc.desc = "Paso 2: DESCARGA INSTRUCCIONES PARA EL LLENADO Y ENVIÓ DE REGLAS DE USO DE LOGOTIPO"
                        break;
                    case CVES_TIPODOCS_USOLOGO.reglasUso:
                        formatedDoc.order = 3
                        formatedDoc.desc = "Paso 3: DESCARGA REGLAS DE USO LOGOTIPO"
                        break;
                    case CVES_TIPODOCS_USOLOGO.manualUso:
                        formatedDoc.order = 4
                        formatedDoc.desc = "Paso 4: DESCARGA MANUAL DE USO LOGOTIPO"
                        break;
                    case CVES_TIPODOCS_USOLOGO.logoEsr:
                        formatedDoc.order = 5
                        formatedDoc.desc = "Paso 5: DESCARGAR LOGOESR"
                        break;
                    case CVES_TIPODOCS_USOLOGO.cartaDictamen:
                        formatedDoc.order = 6
                        formatedDoc.desc = "Paso 6: DESCARGA CARTA DICTAMEN"
                        break;
                    default:
                        break;
                }

                docs.push(formatedDoc)
            }
        })
        setDocuments(docs.sort((a,b) => a.order - b.order))
    }

    /**
     * Obtenemos los documentos
     */
    const getData = async () => {
        dispatch(setLoading(true));
        formatDocuments()
        try {
            const resp = await getCarta({ ...appSt.serviceData, idRegistroConvocatoria: publicacionSt.currentConvocatoria.idRegistroConvocatoria });
            const entidadData = await getEntidad({...appSt.serviceData, idEntidad: empresaSeleccionada.idEntidad});
            let cartaConst = catalogosSt.tipoDocumentosCemefi.find(doc => doc.idDocumentoCemefi === catalogosSt.idsTipoDocumentoCemefi.actaConstitutiva);
            let cartaConstSaved = null;
            if (entidadData.payload.cemDocumentoCrcEntidads.length > 0) {
                cartaConstSaved = entidadData.payload.cemDocumentoCrcEntidads.find(doc => doc.idDocumentoCemefi === catalogosSt.idsTipoDocumentoCemefi.actaConstitutiva)
            }
            if (cartaConstSaved) {
                cartaConst = {...cartaConst, idDocumentoEntidad: cartaConstSaved.idDocumentoEntidad, idContent: cartaConstSaved.idContent}
            }
            setCartaConstitucional(cartaConst)
            const respDocsUso = await getDocsUsoLogo({...appSt.serviceData, idConvocatoria: publicacionSt.currentConvocatoria.idConvocatoria})
            if (resp.length > 0 && resp[0].documentoUsoLogos && resp[0].documentoUsoLogos.length > 0) {
                const array = []
                setCartaUsoLogo(resp[0])
                const carta = resp[0].documentoUsoLogos.find(item => {
                    return item.idDocumentoCemefi === catalogosSt.idsTiposDocumentoCartaLogo.cartaLogo && item.idEstatusRegistro
                });
                const reglas = resp[0].documentoUsoLogos.find(item => {
                    return item.idDocumentoCemefi === catalogosSt.idsTiposDocumentoCartaLogo.reglas && item.idEstatusRegistro
                });
                respDocsUso.forEach(item => {
                    const exist = resp[0].documentoUsoLogos.find(i => i.idDocumentoCemefi === item.idDocumentoCemefi)
                    if (exist) {
                        array.push({
                            ...item, 
                            rutaArchivo: exist.rutaArchivo,
                            idDocumentoUsoLogo: exist.idDocumentoUsoLogo,
                            idEstatusCartaUsoLogo: exist.idEstatusCartaUsoLogo
                        })
                    } else {
                        array.push(item)
                    }
                })
                const cartaD = resp.find(item => item.idEstatusCartaUsoLogo === catalogosSt.idsEstatusCartaLogo.aceptado);
                setCartaAceptacion(carta);
                setCartaDictamen(cartaD);
                setReglasDoc(reglas)
                setDocsUsoLogo(array.sort((a,b) => a.orden - b.orden))
            } else {
                setDocsUsoLogo(respDocsUso)
                setCartaDictamen(null);
                setCartaAceptacion(null);
            }
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: 'Error al consultar datos',
                type: ALERT_FAIL
            }))

        }
    }

    const getEvidenciaName = (filePath) => {
        const path = filePath.split('/');
        return path.slice(-1);
    }

    const closeModal = () => {
        setModalData({
            show: false,
            data: null
        })
    }

    const isDocDisabled = (item) => {
        const isPublicacionFalseCartaDictamen = 
            (item.idDocumentoCemefi === catalogosSt.idsTiposDocumentoCartaLogo.cartaDictamen || item.idDocumentoCemefi === catalogosSt.idsTiposDocumentoCartaLogo.logoEsr) && 
            publicacionSt.currentConvocatoria.publicacion.isPublicada && 
            !cartaDictamen;

        const isCartaDictamen = 
            (item.idDocumentoCemefi === catalogosSt.idsTiposDocumentoCartaLogo.cartaDictamen || item.idDocumentoCemefi === catalogosSt.idsTiposDocumentoCartaLogo.logoEsr) && 
            publicacionSt.currentConvocatoria.publicacion.isPublicada === true && 
            !cartaDictamen;

        const isPublicacionFalse = 
            !publicacionSt.currentConvocatoria.publicacion.isPublicada && 
            (item.idDocumentoCemefi !== catalogosSt.idsTiposDocumentoCartaLogo.cartaDictamen);

        return isCartaDictamen || isPublicacionFalse || isPublicacionFalseCartaDictamen
    }

    const showDoc = (item) => {
        const isPublicacionFalse = !publicacionSt.currentConvocatoria.publicacion.isPublicada && item.idDocumentoCemefi === catalogosSt.idsTiposDocumentoCartaLogo.cartaDictamen;
        return isPublicacionFalse || publicacionSt.currentConvocatoria.publicacion.isPublicada
    }

    useEffect(() => {
        if (!catalogosSt.tiposDocumentoCartaLogo) {
            dispatch(getDocumentosCartaLogoCatalogo({ ...appSt.serviceData, cveTipoDocumento: CVE_TIPODOC_USOLOGO }));
        }
        if (!catalogosSt.estatusCartaLogo) {
            dispatch(getEstatusCartaLogo({ ...appSt.serviceData, cveTipoDocumento: CVE_TIPODOC_USOLOGO }));
        }
        dispatch(getTipoDocumentoCemefi(appSt.serviceData));
    }, [])

    useEffect(() => {
        if (catalogosSt.tiposDocumentoCartaLogo && catalogosSt.tiposDocumentoCartaLogo) {
            getData();
        }

    }, [catalogosSt.tiposDocumentoCartaLogo, catalogosSt.tipoDocumentosCemefi])

    useEffect(() => {
        setCanEdit(publicacionSt.currentConvocatoria && Number(publicacionSt.currentConvocatoria.isPublicacion) === 1)
    }, [publicacionSt.currentConvocatoria])

    return (<div className="carta-logo">
        <div className="wrapper">
            <div className="head">
                <button onClick={back}>
                    <BackIcon />
                </button>
                <div>
                    <h3>Notificación Dictamen</h3>
                    <p className="ligth">Para obtener la carta de dictamen es necesario descargar, firmar y enviar la "carta de uso de logotipo"</p>
                </div>
            </div>
            <div className="card">
                <div className="descargas">
                    <table>
                        <thead>
                            <tr>
                                <th>Nombre del documento</th>
                                <th>Descargar formato</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documentos && documentos.map((item, index) => {
                                return showDoc(item) && <tr key={index}>
                                    <td>{publicacionSt.currentConvocatoria.publicacion.isPublicada ? item.desc : item.descDocumentoCemefi}</td>
                                    <td>
                                        <button
                                            disabled={isDocDisabled(item)}
                                            onClick={() => downloadDoc(item)}
                                            className="small-icon-btn">
                                            <DownloadIcon />
                                        </button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            {publicacionSt.currentConvocatoria.publicacion.isPublicada &&
                <div className="card">
                    <div className="tip">
                        <InfoIcon />
                        <div>
                            <p>Los documentos deben ser en formato PDF y serán enviados para su aprobación</p>
                        </div>
                    </div>
                    <h4>Al ser validada la carta de aceptación de uso de logotipo, se activará la descarga de la carta de dictamen</h4>
                    {cartaUsoLogo && catalogosSt.idsEstatusCartaLogo && cartaUsoLogo.idEstatusCartaUsoLogo === catalogosSt.idsEstatusCartaLogo.rechazado && 
                        <p className="rechazo"><b>Motivo de Rechazo:</b> {cartaUsoLogo.motivoRechazo}</p>
                    }
                    <div className="flex">
                        {/* Carta doc */}
                        {docsUsoLogo && docsUsoLogo.map((item, index) => {
                            return <div className={`document ${item.rutaArchivo ? 'pending' : ''} ${cartaDictamen ? 'uploaded' : ''}`} key={index}>
                                <div className="doc-content">
                                    <div className="head">
                                        <p>{item.descDocumentoCemefi}</p>
                                    </div>
                                    <UploadIcon />
                                    {(!item.rutaArchivo || (item.rutaArchivo && (item.rutaArchivo.idEstatusCartaUsoLogo === catalogosSt.idsEstatusCartaLogo.pendiente || cartaUsoLogo.idEstatusCartaUsoLogo === catalogosSt.idsEstatusCartaLogo.rechazado))) && <>
                                        <p>{item.rutaArchivo ? 'Reemplazar Documento' : 'Subir Documento'}</p>
                                        <input type="file" onChange={(e) => uploadFile(e, item, CVE_TIPODOC_USOLOGO)} />
                                    </>
                                     }
                                    {item.rutaArchivo && <div className="carta-info">
                                        <p>{getEvidenciaName(item.rutaArchivo)}</p>
                                        <p>{item.idEstatusCartaUsoLogo === catalogosSt.idsEstatusCartaLogo.sinValidar ? 'EN REVISIÓN' : ''}</p>
                                        <p>{item.idEstatusCartaUsoLogo === catalogosSt.idsEstatusCartaLogo.rechazado ? 'RECHAZADO' : ''}</p>
                                        <p>{item.idEstatusCartaUsoLogo === catalogosSt.idsEstatusCartaLogo.aceptado ? 'APROBADO' : ''}</p>
                                    </div>
                                    }
                                </div>
                                {item.rutaArchivo &&
                                    <div className="doc-actions">
                                        {!cartaDictamen && cartaUsoLogo && (cartaUsoLogo.idEstatusCartaUsoLogo === catalogosSt.idsEstatusCartaLogo.pendiente || cartaUsoLogo.idEstatusCartaUsoLogo === catalogosSt.idsEstatusCartaLogo.rechazado) &&
                                            <button onClick={() => deleteFile('aceptacion', item.idDocumentoUsoLogo)}>Eliminar</button>
                                        }
                                        <button onClick={() => downloadFile(item.descDocumentoCemefi, item)}>Descargar</button>
                                    </div>
                                }
                            </div>
                        })}
                        
                        {/* Reglas acta */}
                        {cartaConstitucional && 
                            <div className={`document ${!cartaConstitucional.idContent ? 'pending' : 'uploaded'}`}>
                                <div className="doc-content">
                                    <div className="head">
                                        <p>{cartaConstitucional.descDocumentoCemefi}</p>
                                    </div>
                                    <UploadIcon />
                                    <p>{cartaConstitucional.idContent ? 'Reemplazar Documento' : 'Subir Documento'}</p>
                                    <input type="file" onChange={(e) => uploadFile(e, cartaConstitucional, CVE_TIPODOC_ENTIDAD)} />
                                    {cartaConstitucional.idContent &&
                                        <p>{getEvidenciaName(cartaConstitucional.idContent)}</p>
                                    }
                                </div>
                                {cartaConstitucional.idContent &&
                                    <div className="doc-actions">
                                        {!cartaDictamen && cartaUsoLogo && cartaUsoLogo.idEstatusCartaUsoLogo === catalogosSt.idsEstatusCartaLogo.pendiente &&
                                            <button onClick={() => deleteFile('const', cartaConstitucional.idDocumentoUsoLogo)}>Eliminar</button>
                                        }
                                        <button onClick={() => downloadFile('ACTA CONSTITUTIVA', cartaConstitucional)}>Descargar</button>
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    {(!cartaUsoLogo || (cartaUsoLogo && (cartaUsoLogo.idEstatusCartaUsoLogo === catalogosSt.idsEstatusCartaLogo.pendiente || cartaUsoLogo.idEstatusCartaUsoLogo === catalogosSt.idsEstatusCartaLogo.rechazado))) && canEdit &&
                        <div className="actions">
                            <button disabled={!cartaAceptacion || !reglasDoc || (cartaConstitucional && !cartaConstitucional.idContent)} onClick={sendToValidate}>Enviar a Validar</button>
                        </div>
                    }

                </div>
            }

        </div>
        <ModalSeleccionarContacto cartaDictamen={cartaDictamen} modalData={modalData} close={closeModal} />
    </div>)
}