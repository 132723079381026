/* eslint-disable react-hooks/exhaustive-deps */
import '../../styles/retroalimentacion.scss';
import { ALERT_FAIL, CONVOCATORIAS_DISPONIBLES } from "../../app/constantes";
import { ReactComponent as DownloadFile } from '../../assets/icons/download_file.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { useDispatch, useSelector } from "react-redux";
import { changeView } from "../../slices/registroConvocatoriaSlice";
import { retroState } from "../../slices/retroSlice";
import { useEffect, useState } from 'react';
import { getDetalleRetro, getReportRetro, putDocumentoRetro } from '../../services/evaluacion';
import { appState, setAlert, setLoading } from '../../slices/appSlice';
import { getDocumentosConv, uploadDocument } from '../../services/entidad';
import { checkAcceptArray, getBase64 } from '../../app/commons';
import Encuesta from '../../assets/encuesta.pdf';

export default function Retroalimentacion({ empresaSeleccionada }) {
  const dispatch = useDispatch();
  const appSt = useSelector(appState);
  const retroSt = useSelector(retroState);
  const [details, setDetails] = useState(null)
  const [docData, setDocData] = useState(null)

  const goBack = () => {
    dispatch(changeView(CONVOCATORIAS_DISPONIBLES));
  }

  const uploadFile = async (e) => {
    dispatch(setLoading(true));
    try {
      const file = e.target.files[0];
      const extencion = `.${file.name.split('.').pop().toLowerCase()}`;
      const extencions = checkAcceptArray(docData);
      let valid = false;
      extencions.acceptArray.forEach(ext => {
        if (extencion.includes(ext)) {
          valid = true;
        }
      })
      if (file && valid) {
        const base64 = await getBase64(file);
        const body = {
          cveTipoDocumento: "DOCUMENTORETROALIMENTACION",
          cveDocumentoCemefi: docData.cveDocumentoCemefi,
          archivo: {
            nombreArchivo: file.name.replace('#', ''),
            tipo: file.type,
            base64: base64
          }
        }

        const resp = await uploadDocument({
          ...appSt.serviceData,
          idEntidad: empresaSeleccionada.idEntidad
        }, body);

        if (resp.payload) {
          await putDocumentoRetro({
            ...appSt.serviceData,
            idRetroalimentacionInstrumento: details.id_retroalimentacion_instrumento,
            body: {
              idDocumentoContestado: docData.idDocumentoCemefi,
              documentoContestado: resp.payload.contentId,
            }
          },)
          dispatch(setLoading(false));
          getDetails();
        }
      } else {
        dispatch(setLoading(false));
        dispatch(setAlert({
          show: true,
          message: 'Documento invalido',
          type: ALERT_FAIL
        }))
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
      dispatch(setAlert({
        show: true,
        message: 'Error al subir carta',
        type: ALERT_FAIL
      }))
    }
  }

  const downloadFile = (e) => {
    let linkSource;
    const downloadLink = document.createElement('a');
    let fileName = "ENCUESTA";
    linkSource = Encuesta

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click()
  }

  const downloadReport = async () => {
    const blob = await getReportRetro({
      ...appSt.serviceData,
      idEntidad: empresaSeleccionada.idEntidad
    })
    if (blob) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Reporte de Resultados';
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }
    dispatch(setLoading(false));
  }

  const getDetails = async () => {
    dispatch(setLoading(true))
    try {
      const resp = await getDetalleRetro({
        ...appSt.serviceData,
        idRegistroConvocatoria: retroSt.currentConvocatoria.idRegistroConvocatoria
      })
      dispatch(setLoading(false))
      console.log(resp[0])
      setDetails(resp[0])
      const docConv = await getDocumentosConv({
        ...appSt.serviceData,
        idConvocatoria: retroSt.currentConvocatoria.idConvocatoria,
        cveEtapa: "RETROALIMENTACION",
        cveTipoDocumento: "DOCUMENTORETROALIMENTACION"
      })
      setDocData(docConv[0])
    } catch (error) {
      console.log(error)
      dispatch(setLoading(false))
      dispatch(setAlert({
        show: true,
        message: "Error al consultar información",
        type: ALERT_FAIL
      }))
    }
  }

  useEffect(() => {
    if (retroSt.currentConvocatoria) {
      getDetails()
    }

  }, [retroSt.currentConvocatoria])

  return (<div className="retro">
    <div className="wrapper">
      <div className="head">
        <button onClick={goBack}>
          <BackIcon />
        </button>
        <div>
          <h3>Retroalimentación</h3>
        </div>
      </div>

      <div className="card">
        <div className="flex">
          <div className="step completed">
            <div className="label">PASO 1</div>
            <div className="document uploaded" onClick={() => downloadFile()}>
              <div className="doc-content">
                <div className="head">
                  <p>DESCARGA ENCUESTA</p>
                </div>
                <DownloadFile />
                <p>Descargar Encuesta</p>
              </div>
            </div>
          </div>

          <div className={`step ${details && details.documento_contestado ? 'completed' : ''}`}>
            <div className="label">PASO 2</div>
            <div className={`document ${details && details.documento_contestado ? 'uploaded' : ''}`} >
              <div className="doc-content">
                <div className="head">
                  <p> CARGA LA ENCUESTA CONTESTADA</p>
                </div>
                <UploadIcon />
                <p>{details && details.documento_contestado ? 'Documento Cargado' : 'Subir Documento'}</p>
                {details && !details.documento_contestado && <>
                  <input type="file" onChange={(e) => uploadFile(e)} />
                </>
                }
              </div>
              {details && details.documento_contestado &&
                <div className="doc-actions">
                  <button onClick={() => downloadFile()}>Descargar</button>
                </div>
              }
            </div>
          </div>

          <div className={`step ${details && details.calificacion_encuesta ? 'completed' : ''}`}>
            <div className="label">PASO 3</div>
            <div className={`document ${details && details.calificacion_encuesta ? 'uploaded' : 'disabled'}`} onClick={downloadReport}>
              <div className="doc-content">
                <div className="head">
                  <p>DESCARGA REPORTE DE RESULTADOS</p>
                </div>
                <DownloadFile />
                <p>Descargar Reporte</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)
}