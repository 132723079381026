/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findCatalogById } from "../../app/commons";
import { ReactComponent as CompletedIcon } from '../../assets/icons/completed.svg';
import { ReactComponent as ProgressIcon } from '../../assets/icons/in-progress.svg';
import { catalogosServiceState } from "../../slices/catalogosServiceSlice";
import { changeLastProgress, changeRespuestaSeleccionada, instrumentoServicesState } from "../../slices/instrumentoServiceSlice";

export default function EtapasNav() {
    const dispatch = useDispatch();
    const catalogosSt = useSelector(catalogosServiceState);
    const instrumentoSt = useSelector(instrumentoServicesState);

    const [etapas, setEtapas] = useState(null);
    const [lastProgress, setLastProgress] = useState(0)
    const {respuestaSeleccionada} = instrumentoSt;

    /**
     * Ordenamos los niveles por su valorRespuesta y verificamos en nivel en el que esta la respuesta
     */
    const setNav = () => {
        if (instrumentoSt.preguntaSeleccionada.respuesta && instrumentoSt.preguntaSeleccionada.respuesta[0].idTipoRespuesta === catalogosSt.idsTipoRespuesta.nivelMadurez) {
            const sorted = JSON.parse(JSON.stringify(instrumentoSt.preguntaSeleccionada.respuesta)).sort((a, b) => a.valorRespuesta - b.valorRespuesta);
            let currentLevel = 0;
            instrumentoSt.preguntaSeleccionada.respuesta.forEach(nivel => {
                if (nivel.valorRespuesta > currentLevel && nivel.valorRespInstrReg) {
                    currentLevel =  nivel.valorRespuesta -1;
                }
            })
            dispatch(changeLastProgress(currentLevel+1));
            setLastProgress(currentLevel);
            setEtapas(sorted);
        }
    }
    
    /**
     * Validaciones iniciales
     */
    useEffect(() => {
        setNav()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instrumentoSt.preguntaSeleccionada])
    
    /**
     * Setteamos la respuesta seleccionada dependiendo el ultimo nivel contestado
     */
    useEffect(() => {
        console.log(catalogosSt.nivelMadurez)
        if (etapas) {
            // Esto estaba generando q cada q se actualiza la pregunta, se fuera al ultimo nivel
            if (!respuestaSeleccionada) {
                dispatch(changeRespuestaSeleccionada(etapas[lastProgress]))
                // setRespuestaSeleccionada(etapas[lastProgress]);
            }
        }
    }, [lastProgress, etapas])

    return <div className="etapas">
        {etapas && respuestaSeleccionada &&  etapas.map((nivel, index) => {
            return <button key={index} onClick={() => dispatch(changeRespuestaSeleccionada(nivel))} 
                disabled={lastProgress + 1 < nivel.valorRespuesta}
                className={`
                    ${nivel.valorRespuesta === respuestaSeleccionada.valorRespuesta ? 'active' : ''} 
                    ${nivel.valorRespInstrReg  ? 'completed' : ''} 
                `}>
                    
                {nivel.valorRespInstrReg
                    ? <CompletedIcon className="completed" />
                    : <ProgressIcon />
                }
                
                {catalogosSt.nivelMadurez && 
                    <p>{findCatalogById(nivel.valorRespuesta, catalogosSt.nivelMadurez, 'idNivelMadurez', 'descNivelMadurez')}</p>                
                }
            </button>
        })}
    </div>
}