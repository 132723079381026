export const direccionForm = {
    idTipoDireccion: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idPais: {
        value: '42',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    calle: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    numeroExterior: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    numeroInterior: {
        value: '',
        required: false,
        message: '',
        tool: '',
        invalid: false,
    },
    colonia: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    estado: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    municipioAlcaldia: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    ciudad: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    codigoPostal: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idConsultorAcreditado2: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idCodigoPostal: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idEntidad: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
}

export const direccionFormRequired = {
    idPais: {
        value: '42',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    calle: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    numeroExterior: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    numeroInterior: {
        value: '',
        required: false,
        message: '',
        tool: '',
        invalid: false,
    },
    colonia: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    estado: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    municipioAlcaldia: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    ciudad: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    codigoPostal: {
        value: '',
        required: true,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
    idCodigoPostal: {
        value: '',
        required: false,
        message: 'Dato requerido',
        tool: '',
        invalid: false,
    },
}
