import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { appState, setAlert, setLoading } from "../../slices/appSlice";

import { getPersonaById, usuariosServicesState } from "../../slices/usuariosServicesSlice";
import { changeStep, registroState, setSede, updateEmpresaSeleccionada } from "../../slices/registroSlice";
import { catalogosServiceState, getPaisCatalogo, getTipoEntidadCatalogo, getRegistroEntidadInformacionGeneral, getTipoDireccionCatalogo, resetRegistro } from "../../slices/catalogosServiceSlice";
import { postColaboradorEntidad, postDireccionEntidad, postDocumentoEntidad, postEntidad, postProductoEntidad, postServicioEntidad, putDireccionEntidad, putEntidad } from "../../services/entidad";
import { getPersonaByRfc, postPersona } from "../../services/persona";
import { informacionGeneralForm } from "../../app/forms/registro/informacion-general"
import { direccionForm } from "../../app/forms/registro/direccion";

import DireccionComponent from "../generales/direccionComponent";
import InputComp from "../generales/inputComponent";

import { ALERT_FAIL, ALERT_SUCCESS, CVE_DIRECCION, CVE_PERFILES_USUARIO, CVE_PERSONA_FISICA, CVE_PERSONA_MORAL, DATOS_CONTACTO_STEP, ID_IS_SEDE, MEXICO_ID } from "../../app/constantes";
import { fillForm, formatForm, isValueNullOrEmpty, validateForm } from "../../app/commons";

import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg'
import { postUsuarioToGroup } from "../../services/usuarios";
import ConfirmModal from "../generales/confirmModal";
import { getMessageByModulo } from "../../services/catalogos";


export default function InformacionGeneral() {
    const dispatch = useDispatch();
    const appSt = useSelector(appState);
    const registroSt = useSelector(registroState);
    const usuariosSt = useSelector(usuariosServicesState);
    const catalogosSt = useSelector(catalogosServiceState);

    const [form, setForm] = useState(informacionGeneralForm);
    const [formDireccion, setFormDireccion] = useState(JSON.parse(JSON.stringify(direccionForm)));
    const [formFiscal, setFormFiscal] = useState(JSON.parse(JSON.stringify(direccionForm)));
    const [toolTipQuinqueneo, setToolTipQ] = useState(null);
    const [mensaje, setMensaje] = useState(null);
    // Local States
    const [modalData, setModalData] = useState({
        show: false,
        title: '',
        desc: '',
        info: null,
        list: null
    });

    const [isSameDirection, setIsSameDirection] = useState(true);

    // Cierra Modal de confirmacion
    const closeModal = () => {
        setModalData({
            ...modalData,
            show: false
        })
    }

    const handleResponse = (response) => {
        if (response.payload) {
            dispatch(setAlert({
                show: true,
                message: 'Exito al guardar la información',
                type: ALERT_SUCCESS
            }))
        } else {
            dispatch(setAlert({
                show: true,
                message: 'Error al guardar la información',
                type: ALERT_FAIL
            }))
        }

    }

    /**
     * Guardamos los datos de insdustria para sede
     */
    const saveDatosIndsutria = async (idEntidad) => {
        const form = {
            idIndustria: registroSt.empresaSeleccionada.idSubsectorInegi2.idSector2.idIndustria,
            idSector: registroSt.empresaSeleccionada.idSubsectorInegi2.idSector,
            idSectorSe: registroSt.empresaSeleccionada.idSectorSe,
            idSubsectorInegi: registroSt.empresaSeleccionada.idSubsectorInegi,
            numeroEmpleados: registroSt.empresaSeleccionada.numeroEmpleados
        }
        try {
            const data = {
                ...appSt.serviceData,
                idEntidad
            }
            await putEntidad(data, form);
            if (registroSt.empresaSeleccionada.cemProductoEntidads.length > 0) {
                for (const producto of registroSt.empresaSeleccionada.cemProductoEntidads) {
                    const productoBody = {
                        idEntidad,
                        descProductoEntidad: producto.descProductoEntidad
                    }

                    await postProductoEntidad(appSt.serviceData, productoBody); 
                }
            }
            for (const servicio of registroSt.empresaSeleccionada.cemServicioEntidads) {
                const servicioBody = {
                    idEntidad,
                    descServicioEntidad: servicio.descServicioEntidad
                }

                await postServicioEntidad(appSt.serviceData, servicioBody); 
            }
        } catch (error) {
            console.log(error);
            dispatch(setAlert({
                show: true,
                message: 'Error al guardar datos de industria',
                type: ALERT_FAIL
            }))            
        }
    }

    const saveDocuments = async (idEntidad) => {
        if (registroSt.empresaSeleccionada.cemDocumentoCrcEntidads.length > 0) {
            try {
                for (const doc of registroSt.empresaSeleccionada.cemDocumentoCrcEntidads) {
                    const body = {
                        idDocumentoCemefi: doc.idDocumentoCemefi,
                        idContent: doc.idContent,
                        idEntidad
                    }  
                    await postDocumentoEntidad(appSt.serviceData, body);
                }
                
            } catch (error) {
                console.log(error);
                dispatch(setAlert({
                    show: true,
                    message: 'Error al guardar documentos',
                    type: ALERT_FAIL
                }))                  
            }
        }
    }

    /**
     * Guardamos una nueva entidad
     */
    const saveNewEntidad = async (body) => {
        const bodyPersona = {
            cveRegimenPersona: body.rfc.length === 12 ? CVE_PERSONA_MORAL : CVE_PERSONA_FISICA,
            rfc: body.rfc,
            isSedeMarca: 0
            // isSedeMarca: registroSt.isSede ? ID_IS_SEDE : body.isSedeMarca
        }
        let entidadBody = {
            ...body,
            isSedeMarca: 0
            // isSedeMarca: registroSt.isSede ? ID_IS_SEDE : body.isSedeMarca
        }
        // Ponemos al papa con estatus registrada
        if (registroSt.isSede) {
            await putEntidad({
                ...appSt.serviceData,
                idEntidad: registroSt.empresaSeleccionada.idEntidad
            }, {
                idEstatusCorporativoSede: catalogosSt.idsEstatusCorpSede.registrada,
                isSedeMarca: 1
            });
            entidadBody.idEntidadPadre = registroSt.empresaSeleccionada.idEntidad
        }
        const respEntidad = await postEntidad(appSt.serviceData, entidadBody);
        const respPersona = await postPersona(appSt.serviceData, bodyPersona);

        if (respEntidad.payload && respPersona.payload) {
            // damos de alta al usuario loggeado como colaborador
            const bodyColaborador = {
                rfc: usuariosSt.persona.rfc,
                cveRegimenPersona: usuariosSt.persona.idRegimenPersona.cveRegimenPersona,
                isSedeMarca: 0,
                colaborador: {
                    nombre: usuariosSt.usuario.data.nombre,
                    apellidoPaterno: usuariosSt.usuario.data.apellidoPaterno,
                    apellidoMaterno: usuariosSt.usuario.data.apellidoMaterno,
                    email: usuariosSt.usuario.data.email,
                }
            }
            await postPersona(appSt.serviceData, bodyColaborador);

            // hacemos la union entre el colaborador y la entidad
            const bodyColaboradorEntidad = {
                idColaborador: usuariosSt.persona.idPersona,
                idEntidad: respEntidad.payload.id,
                isEntidadPrincipal: 1
            }
            const respColaboradorEntidad = await postColaboradorEntidad(appSt.serviceData, bodyColaboradorEntidad)
            await postUsuarioToGroup(appSt.serviceData, {
                cvePerfil: CVE_PERFILES_USUARIO.encargadoEntidad,
                idUsuario: usuariosSt.usuario.data.idUsuario,
                idEntidad: respEntidad.payload.id,
                cveEstatusRegistro: 'VINCULADO'
            });

            if (respColaboradorEntidad.payload) {
                const data = {
                    ...appSt.serviceData,
                    idEntidad: respEntidad.payload.id,
                }
                await checkDirectionSave(respEntidad.payload.id);
                if (registroSt.isSede) {
                    await saveDatosIndsutria(respEntidad.payload.id);
                    await saveDocuments(respEntidad.payload.id);
                }
                handleResponse(respColaboradorEntidad);
                dispatch(setLoading(false));
                dispatch(updateEmpresaSeleccionada(data));
                dispatch(changeStep(DATOS_CONTACTO_STEP));
                dispatch(resetRegistro());
            }
        }
    }

    /**
     * Actualizamos los datos de la entidad
     */
    const updateEntidad = async (body) => {
        const putData = {
            ...appSt.serviceData,
            idEntidad: registroSt.empresaSeleccionada.idEntidad
        }
        const respEntidad = await putEntidad(putData, body);

        if (respEntidad.payload) {
            await checkDirectionSave();
            handleResponse(respEntidad);
            dispatch(setLoading(false));
            dispatch(updateEmpresaSeleccionada(putData));
            dispatch(changeStep(DATOS_CONTACTO_STEP));
            dispatch(resetRegistro());
        }
    }

    // Confirmar Eliminacion
    const confirmNext = (id) => {
        const directionFormValid = validateForm(formDireccion);
        const directionFiscalFormValid = validateForm(formFiscal);
         if ((directionFormValid.length > 0) || (directionFiscalFormValid.length > 0 && !isSameDirection)) {
             setModalData({
                 show: true,
                 title: 'Continuar',
                 desc: 'Faltan campos requeridos, no se guardará la información de la dirección, ¿Desea continuar?',
                 info: id,
                 action: () => nextStep()
             })

         } else {
            nextStep()
         }
    }

    /**
     * Se guarda la info y continuamos al siguiente paso
     */
    const nextStep = async () => {
        console.log(registroSt.empresaSeleccionada)
        const invalidItems = validateForm(form);
        closeModal();
        if (invalidItems.length === 0) {
            dispatch(setLoading(true));
            const body = formatForm(form);
            delete body.direccion_fiscal_correspondencia;
            console.log(registroSt.empresaSeleccionada)
            if (!registroSt.empresaSeleccionada || (registroSt.empresaSeleccionada && registroSt.isSede && registroSt.empresaSeleccionada.idEntidadPadre === null && registroSt.empresaSeleccionada.cemRegistroConvocatorias.length === 0)) {
                console.log('create')
                const respPersona = await getPersonaByRfc({...appSt.serviceData, rfc: form.rfc.value}, '?origen=entidad');
                if (respPersona.payload && respPersona.payload.rfc && !registroSt.isSede) {
                    dispatch(setLoading(false));
                    dispatch(setAlert({
                        show: true,
                        message: 'El RFC ya ha sido registrado anteriormente.',
                        type: ALERT_FAIL
                    }))
                } else {
                    await saveNewEntidad(body);
                }
            } else {
                console.log('update')
                await updateEntidad(body);
            }
        } else {
            dispatch(setAlert({
                show: true,
                message: 'Favor de llenar todos los campos',
                type: ALERT_FAIL
            }))
        }
    }

    /**
     * Guardamos la direccion 
     * */
    const saveDirection = async (idDirection, dirForm, idEntidad) => {
        dispatch(setLoading(true));
        const formatedForm = formatForm(dirForm);
        let body;
        const prebody = {
            idPais: formatedForm.idPais,
            calle: formatedForm.calle,
            numeroExterior: formatedForm.numeroExterior,
            numeroInterior: formatedForm.numeroInterior,
            idEntidad: idEntidad ? idEntidad : registroSt.empresaSeleccionada.idEntidad,
            idTipoDireccion: idDirection
        }
        
        if (formatedForm.idPais === MEXICO_ID || formatedForm.idPais === Number(MEXICO_ID)) {
            body = {
                ...prebody,
                idCodigoPostal: formatedForm.idCodigoPostal,
            }
        } else {
            body = {
                ...prebody,
                idCodigoPostal: null,
                codigoPostal: formatedForm.codigoPostal.length === 4 ? `0${formatedForm.codigoPostal}` : formatedForm.codigoPostal,
                ciudad: formatedForm.ciudad,
                estado: formatedForm.estado,
                colonia: formatedForm.colonia,
                municipioAlcaldia: formatedForm.municipioAlcaldia
            }
        }

        let resp;

        if (registroSt.empresaSeleccionada && registroSt.empresaSeleccionada.cemDireccionEntidads.length > 0) {
            const filt = registroSt.empresaSeleccionada.cemDireccionEntidads.filter(dir => dir.idTipoDireccion === idDirection);
            if (filt.length > 0 && !registroSt.isSede) {
                const putData = {
                    ...appSt.serviceData,
                    idDireccion: filt[0].idDireccionEntidad
                }
                resp = await putDireccionEntidad(putData, body);
            } else {
                resp = await postDireccionEntidad(appSt.serviceData, body);
            }

        } else {
            resp = await postDireccionEntidad(appSt.serviceData, body);
        }

        handleResponse(resp);
        dispatch(setLoading(false));
        // console.log(resp);
        return resp;
    }

    /**
     * Agrega los valores del servicio a los inputs
     */
    const populateInputs = () => {
        const f = fillForm(form, registroSt.empresaSeleccionada);
        if (isValueNullOrEmpty(registroSt.empresaSeleccionada.idEntidad2)) {
            f.rfc.value = registroSt.empresaSeleccionada.idEntidad2.rfc
            f.rfc.invalid = false;
        }
        setForm(f);
        if (registroSt.empresaSeleccionada.isSedeMarca === ID_IS_SEDE) {
            dispatch(setSede(true));
        }

        if (registroSt.empresaSeleccionada.cemDireccionEntidads.length > 0) {
            checkDirectionDiference()
        }

    }
    
    /**
     * Checamos si la direccion fiscal coincide con la de correspondiecia, para el check
     */
    const checkDirectionDiference = () => {
        const fiscalDir = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.fiscal);
        const correspondenciaDir = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.correspondencia);

        if (!fiscalDir || !correspondenciaDir) {
            return console.log('Sin direccion en catalogos', catalogosSt.tipoDireccion);
        }

        const direccionFiscal = registroSt.empresaSeleccionada.cemDireccionEntidads.find(dir => dir.idTipoDireccion === fiscalDir.idTipoDireccion);
        const direccionCorrespondencia = registroSt.empresaSeleccionada.cemDireccionEntidads.find(dir => dir.idTipoDireccion === correspondenciaDir.idTipoDireccion);

        if (direccionFiscal && direccionCorrespondencia) {
            if (
                direccionFiscal.idPais !== direccionCorrespondencia.idPais ||
                direccionFiscal.idCodigoPostal !== direccionCorrespondencia.idCodigoPostal ||
                direccionFiscal.calle !== direccionCorrespondencia.calle ||
                direccionFiscal.numeroExterior !== direccionCorrespondencia.numeroExterior ||
                direccionFiscal.numeroInterior !== direccionCorrespondencia.numeroInterior
            ) {
                setIsSameDirection(false);
            }
        }

    }

    /**
         * Validaciones de direccion, si ya hay un registro de empresa, 
         */
    const checkDirectionSave = async (idEntidad) => {
        const directionFormValid = validateForm(formDireccion);

        const correspondenciaDir = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.correspondencia);
        const fiscalDir = catalogosSt.tipoDireccion.find(dir => dir.cveTipoDireccion === CVE_DIRECCION.fiscal);

        if (!correspondenciaDir || !fiscalDir) {
            return console.log('sin catalogos', catalogosSt.tipoDireccion)
        }

        if (directionFormValid.length === 0) {
            await saveDirection(correspondenciaDir.idTipoDireccion, formDireccion, idEntidad);
            if (isSameDirection && !registroSt.isSede) {
                await saveDirection(fiscalDir.idTipoDireccion, formDireccion, idEntidad);
            }
        }

        const directionFiscalFormValid = validateForm(formFiscal);
        if ((directionFiscalFormValid.length === 0 && !isSameDirection) || (directionFiscalFormValid.length === 0 && registroSt.isSede) ) {
            await saveDirection(fiscalDir.idTipoDireccion, formFiscal, idEntidad);
        }
    }

    const checkRfc = async (e) => {
        const value = e.target.value;
        dispatch(setLoading(true));
        try {
            const respPersona = await getPersonaByRfc({...appSt.serviceData, rfc: value}, '?origen=entidad');
            if (respPersona.payload && respPersona.payload.rfc) {
                dispatch(setAlert({
                    show: true,
                    message: 'El RFC ya existe, favor de validarlo.',
                    type: ALERT_FAIL
                }))
            }
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false));
            
        }
    }

    /**
     * Si se selecciona una empresa para editar
     */
    useEffect(() => {
        if (registroSt.empresaSeleccionada !== null) {
            populateInputs();            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registroSt.empresaSeleccionada])

    /**
     * obtener persona
     */
    useEffect(() => {
        if (usuariosSt.usuario && usuariosSt.usuario.data) {
            if (usuariosSt.persona === null && appSt.serviceData) {
                dispatch(getPersonaById({
                    id: usuariosSt.usuario.data.idUsuario,
                    ...appSt.serviceData
                }))
            }

        }
        
        if (catalogosSt.pais === null && !catalogosSt.loading) {
            dispatch(getPaisCatalogo(appSt.serviceData))
        }
    
        if (catalogosSt.tipoDireccion === null && !catalogosSt.loading) {
            dispatch(getTipoDireccionCatalogo(appSt.serviceData))
        }
    
        if (catalogosSt.tipoEntidad === null && !catalogosSt.loading) {
            dispatch(getTipoEntidadCatalogo(appSt.serviceData))
        }
    
        if (catalogosSt.parametros === null && !catalogosSt.loading) {
            dispatch(getRegistroEntidadInformacionGeneral(appSt.serviceData))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuariosSt.usuario, appSt.serviceData])

    useEffect(() => {

        if (catalogosSt.parametros !== null && catalogosSt.parametros !== undefined) {
            catalogosSt.parametros.forEach(item => {
                if (item.nombreParametro === 'RAZON_SOCIAL') {
                    form.razonSocial.tool = item.valor;
                }
                if (item.nombreParametro === 'RFC') {
                    form.rfc.tool = item.valor;
                }
                if (item.nombreParametro === 'NOMBRE_COMERCIAL') {
                    form.nombreComercial.tool = item.valor;
                }
                if (item.nombreParametro === 'PLACA') {
                    form.nombrePlaca.tool = item.valor;
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogosSt.parametros]);

    const formatValor = (resp) => {
        let value = null;
        if (resp.payload && resp.payload.valor) {
            value = resp.payload.valor.split('<br>');
        }
        return value;
    }

    const getMessages = async () => {
        const tooltip = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'REGISTROENTIDADINFORMACIONGENERAL', 'PLACAQUINQUENIO');
        const mensaje = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'REGISTROENTIDADINFORMACIONGENERAL', 'MENSAJEDIRECCIONCORRESPONDECIA');
        if (tooltip.payload && tooltip.payload.valor) {
            setToolTipQ(tooltip.payload.valor);
        }
        setMensaje({
            direccion: formatValor(mensaje)
        })
    }

    useEffect(() => {
        if (!toolTipQuinqueneo && appSt.serviceData) {
            getMessages()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (toolTipQuinqueneo) {
            const clone = JSON.parse(JSON.stringify(form));
            clone.nombrePlacaQuinquenio.tool = toolTipQuinqueneo;
            setForm(clone);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toolTipQuinqueneo])

    useEffect(() => {
    }, [registroSt.empresaSeleccionada])

    return (
        <div className="registro-step informacion-general">
            <div className="tip">
                <InfoIcon />
                <p>Favor de llenar todos los campos que contengan: <span>*</span></p>
            </div>
            
            <div className="content">
                <InputComp disabled={registroSt.isSede} label="Razón Social" name="razonSocial" type="upper" form={form} setForm={setForm} validation="razonSocial" />
                <InputComp label="Nombre Comercial" name="nombreComercial" type="upper" form={form} setForm={setForm} />
                {/* eslint-disable-next-line no-useless-escape */}
                <InputComp onBlurAction={checkRfc} disabled={registroSt.isSede} label="RFC" name="rfc" type="upper" form={form} setForm={setForm} placeholder="XXXXYYMMDDXXX" 
                validation={'^([A-ZÑ\\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])((-)?([A-Z\\d]{3})))?$|!( $)'}
                />
                {catalogosSt.tipoEntidad &&
                    <InputComp disabled={registroSt.isSede} label="Tipo de Entidad" name="idTipoEntidad" type="select" form={form} setForm={setForm} catalogos={catalogosSt.tipoEntidad} catalogoLabel="descTipoEntidad" catalogoValue="idTipoEntidad" />
                }
                <InputComp label="Placa" name="nombrePlaca" type="text" form={form} setForm={setForm} max={41}/>
                <InputComp label="Placa Quinquenio" name="nombrePlacaQuinquenio" type="text" form={form} setForm={setForm} max={35}/>
            </div>

            <DireccionComponent aviso={mensaje && mensaje.direccion ? mensaje.direccion : null}  empresaSeleccionada={registroSt.empresaSeleccionada} cveDireccion={CVE_DIRECCION.correspondencia} form={formDireccion} setForm={setFormDireccion} />
            {!registroSt.isSede && 
                <div className="content">
                    <div className="radio-wrapper">
                        <label>¿La dirección fiscal es la misma que la de correspondencia?</label>
                        <div className="radios">
                            <div className="radio-input">
                                <input checked={isSameDirection} value={true} name="direccion_fiscal_correspondencia" type="radio" onChange={(event) => setIsSameDirection(event.target.value === 'true')} />
                                <div className="check"></div>
                                <label>Sí</label>
                            </div>
                            <div className="radio-input">
                                <input checked={!isSameDirection} value={false} name="direccion_fiscal_correspondencia" type="radio" onChange={(event) => setIsSameDirection(event.target.value === 'true')} />
                                <div className="check"></div>
                                <label>No</label>
                            </div>
                        </div>
                    </div>
                </div>            
            }

            {(!isSameDirection || registroSt.isSede) &&
                <DireccionComponent disabled={registroSt.isSede} isFiscal={true} empresaSeleccionada={registroSt.empresaSeleccionada} cveDireccion={CVE_DIRECCION.fiscal} form={formFiscal} setForm={setFormFiscal} />
            }

            <ConfirmModal data={modalData} cancel={closeModal} />


            <div className="actions">
                <button onClick={confirmNext}>Guardar</button>
            </div>
        </div>
    )
}