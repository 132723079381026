import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { changeStep, registroState, updateEmpresaSeleccionada } from "../../slices/registroSlice";

import { fillForm, findCatalogById, formatForm, isValueNullOrEmpty, validateForm } from "../../app/commons";
import InputComp from "../generales/inputComponent";
import { aliadoFormFields, consultorFormFields, datosContactoForm } from "../../app/forms/registro/datos-contacto";
import { ALERT_FAIL, ALERT_SUCCESS, DATOS_INDUSTRIA_STEP, ERROR_MESSAGE, INFORMACION_GENERAL_STEP, SUCESS_MESSAGE } from "../../app/constantes";

import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as ContactoIcon } from '../../assets/icons/contacto.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { useEffect } from "react";
import { catalogosServiceState, getTipoContactoCatalogo } from "../../slices/catalogosServiceSlice";
import { appState, setAlert, setLoading } from "../../slices/appSlice";
import { usuariosServicesState } from "../../slices/usuariosServicesSlice";
import { deleteContactoEntidad, postContactoEntidad, putContactoEntidad, putEntidad } from "../../services/entidad";
import { entidadServicesState, obtenerContactosEntidad } from "../../slices/entidadServicesSlice";
import { getAliadosPersonales, getConsultores } from "../../services/persona";
import { getMessageByModulo } from "../../services/catalogos";
import ConfirmModal from "../generales/confirmModal";

export default function DatosContacto() {
    // Estados Redux
    const dispatch = useDispatch();
    const catalogosSt = useSelector(catalogosServiceState);
    const appSt = useSelector(appState);
    const usuarioSt = useSelector(usuariosServicesState);
    const registroSt = useSelector(registroState);
    const entidadSt = useSelector(entidadServicesState);

    // Estado Local
    const [aliadosCatalogo, setAliadosCatalogo] = useState(null);
    const [consultoresCatalogo, setConsultoresCatalogo] = useState(null);
    const [companionHelp, setCompanion] = useState(false);
    const [consultorHelp, setConsultor] = useState(false);
    const [form, setForm] = useState(JSON.parse(JSON.stringify(datosContactoForm)));
    const [contactoSelected, setContactoSelected] = useState(null);
    const [aliadoForm, setAliadoForm] = useState(JSON.parse(JSON.stringify(aliadoFormFields)));
    const [consultorForm, setConsultorForm] = useState(JSON.parse(JSON.stringify(consultorFormFields)));
    const [messages, setMessages] = useState(null);
    // Local States
    const [modalData, setModalData] = useState({
        show: false,
        title: '',
        desc: '',
        info: null,
        list: null
    });
    
    /**
     * Mostramos Errores en el modal
     */
    const [error, setError] = useState({
        show: false,
        message: ''
    });

    // Cierra Modal de confirmacion
    const closeModal = () => {
        setModalData({
            ...modalData,
            show: false
        })
    }

    /**
     * Paso anterior
     */
    const prevStep = () => {
        dispatch(changeStep(INFORMACION_GENERAL_STEP));
    }

    /**
     * Siguiente paso
     */
     const nextStep = async () => {
        const aliadoValid = validateForm(aliadoForm);

        if (aliadoValid.length === 0) {
            const aliadoValue = formatForm(aliadoForm);
            const consultorValid = validateForm(consultorForm);
            dispatch(setLoading(true));
            const data = {
                ...appSt.serviceData,
                idEntidad: registroSt.empresaSeleccionada.idEntidad
            }
            let body = aliadoValue;

            if (consultorValid && consultorHelp) {
                const consultorValue = formatForm(consultorForm);
                body = {
                    ...body,
                    ...consultorValue
                }
            } else {
                body = {
                    ...body,
                    idConsultorAcreditado: null
                }
            }
            
            const resp = await putEntidad(data, body);

            if (resp.payload) {
                dispatch(setLoading(false));
                dispatch(setAlert({
                    show: true,
                    message: 'Datos Guardados',
                    type: ALERT_SUCCESS
                }));
                setContactoSelected(null);
                dispatch(updateEmpresaSeleccionada(data));
                dispatch(changeStep(DATOS_INDUSTRIA_STEP));
            } else {
                dispatch(setLoading(false));
                dispatch(setAlert({
                    show: true,
                    message: 'Error al guardar',
                    type: ALERT_FAIL
                }))
            }
        }
    }

    /**
     * Muestra alerta y carga de refresca los contactos
     * @param {*} message 
     * @param {*} type 
     */
    const hanldeResponse = (message, type) => {
        dispatch(setLoading(false));
        dispatch(setAlert({
            show: true,
            message,
            type
        }));
        if (type === ALERT_SUCCESS) {
            dispatch(obtenerContactosEntidad({
                ...appSt.serviceData,
                idEntidad: registroSt.empresaSeleccionada.idEntidad
            }))
        }
    }

    /**
     * Se guarda el contacto en la base de datos
     * @param {*} value 
     */
    const saveContact = async (value) => {
        dispatch(setLoading(true));
        const data = appSt.serviceData
        let body = {
            ...value,
            isNotificaCorreo: value.isNotificaCorreo === "true" ? 1 : 0,
            idEntidad: registroSt.empresaSeleccionada.idEntidad
        }
        delete body.ayudo_aliado;
        delete body.ayudo_consultor;
        delete body.emailConfirm;

        let resp;

        if (!contactoSelected) {
            resp = await postContactoEntidad(data, body);
        } else {
            resp = await putContactoEntidad({...data, idContactoEntidad: contactoSelected.idContactoEntidad}, body)
        }

        dispatch(setLoading(false));

        if (!resp.error) {
            const updateData = {
                ...data,
                idEntidad: registroSt.empresaSeleccionada.idEntidad
            }
            hanldeResponse(SUCESS_MESSAGE, ALERT_SUCCESS);
            dispatch(updateEmpresaSeleccionada(updateData));
            setContactoSelected(null);
            clearForm();
        } else {
            hanldeResponse(ERROR_MESSAGE, ALERT_FAIL);
        }
    }

    /**
     * Se hacen validaciones antes de llamar al servicio
     */
    const addContacto = async () => {
        setError({
            show: false,
            message: ''
        })
        const value = formatForm(form);
        const valid = validateForm(form)

        if (valid.length === 0) {
            if (value.email !== value.emailConfirm) {
                setError({
                    show: true,
                    message: 'Los correos no coinciden'
                });
                dispatch(setAlert({
                    show: true,
                    message: 'Los correos no coinciden',
                    type: ALERT_FAIL
                }))
            } else {
                saveContact(value);
            }
        } else {
            setError({
                show: true,
                message: 'Favor de llenar todos los campos requeridos'
            });
            dispatch(setAlert({
                show: true,
                message: 'Favor de llenar todos los campos requeridos',
                type: ALERT_FAIL
            }))
        }
    }

    // Confirmar Eliminacion
    const confirmDelete = (idContactoEntidad) => {
        setModalData({
            show: true,
            title: 'Eliminar Contacto',
            desc: '¿Seguro deseas eliminar este contacto?',
            info: idContactoEntidad,
            action: () => deleteContacto(idContactoEntidad)
        })
    }

    /**
     * Se elimina un contacto
     */
    const deleteContacto = async (id) => {
        const resp = await deleteContactoEntidad({
            ...appSt.serviceData,
            idContactoEntidad: id
        });
        if (resp.payload) {
            const updateData = {
                ...appSt.serviceData,
                idEntidad: registroSt.empresaSeleccionada.idEntidad
            }
            dispatch(updateEmpresaSeleccionada(updateData));
            hanldeResponse('Contacto Eliminado', ALERT_SUCCESS);
            closeModal();
        } else {
            hanldeResponse('Error al eliminar usuario', ALERT_FAIL);
        }
    }

    /**
     * Populamos el campo de aliado en la seccion de acompaniamento
     */
    const populateAliados = async () => {
        const data = {
            ...appSt.serviceData,
            idEntidad: registroSt.empresaSeleccionada.idEntidad
        }
        const resp = await getAliadosPersonales(data);

        if (resp.payload) {
            setAliadosCatalogo(resp.payload.aliados);
        }
    }

    /**
     * populamos el campo de consultores en el seccion de acompaniamento
     */
    const populateConsultores = async () => {
        const data = {
            ...appSt.serviceData,
            idEntidad: registroSt.empresaSeleccionada.idEntidad,
        }
        const resp = await getConsultores(data);

        if (resp.payload) {
            setConsultoresCatalogo(resp.payload.consultores);
        }
    }

    /**
     * Catalogo de tipos de contacto
     */
    useEffect(() => {
        if (catalogosSt.tipoContacto === null && isValueNullOrEmpty(usuarioSt.usuario.data) && !catalogosSt.loading) {
            dispatch(getTipoContactoCatalogo(appSt.serviceData));
        }    

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (appSt.serviceData && aliadosCatalogo === null && registroSt.empresaSeleccionada) {
            populateAliados();
        }

        if (appSt.serviceData && consultoresCatalogo === null && registroSt.empresaSeleccionada) {
            populateConsultores();
        }
        
        if (registroSt.empresaSeleccionada.idAliadoRegional) {
            const formClone = JSON.parse(JSON.stringify(aliadoForm));
            formClone.idAliadoRegional.value = registroSt.empresaSeleccionada.idAliadoRegional;
            setCompanion(true);
            setAliadoForm(formClone);
            populateConsultores();
        } else {
            const formClone = JSON.parse(JSON.stringify(aliadoForm));
            formClone.idAliadoRegional.value = null;
            setCompanion(false);
            setAliadoForm(formClone);
        }

        if (registroSt.empresaSeleccionada.idConsultorAcreditado) {
            const formClone = JSON.parse(JSON.stringify(consultorForm));
            formClone.idConsultorAcreditado.value = registroSt.empresaSeleccionada.idConsultorAcreditado;
            setConsultor(true);
            setConsultorForm(formClone);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Obtenemos lo contactos de la entidad
     */
    useEffect(() => {
        if (registroSt.empresaSeleccionada && entidadSt.contactosEntidad === null) {
            dispatch(obtenerContactosEntidad({
                ...appSt.serviceData,
                idEntidad: registroSt.empresaSeleccionada.idEntidad
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entidadSt.contactosEntidad])

    useEffect(() => {
        if (registroSt.empresaSeleccionada.cemContactoCrcEntidads.length === 0 && catalogosSt.tipoContacto) {
            const principal = catalogosSt.tipoContacto.find(item => item.cveTipoContactoEntidad === 'PRINCIPAL');
            if (principal) {

                if (usuarioSt.usuario !== null && usuarioSt.usuario.data !== null) {
                    form.nombre.value = usuarioSt.usuario.data.nombre;
                    form.apellidoMaterno.value = usuarioSt.usuario.data.apellidoMaterno;
                    form.apellidoPaterno.value = usuarioSt.usuario.data.apellidoPaterno;
                    form.telefono.value = usuarioSt.usuario.data.telefono;
                    form.celular.value = usuarioSt.usuario.data.telefono;
                    form.email.value = usuarioSt.usuario.data.email;
                    form.emailConfirm.value = usuarioSt.usuario.data.email;
                    form.idTipoContactoEntidad.value = principal.idTipoContactoEntidad;
                    form.puesto.value = 'Contacto principal';    
                    addContacto();
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registroSt.empresaSeleccionada.cemContactoCrcEntidads, catalogosSt.tipoContacto])

    /**
     * Reseteamos el form
     */
    const clearForm = () => {
        form.nombre.value = '';
        form.apellidoMaterno.value = '';
        form.apellidoPaterno.value = '';
        form.telefono.value = '';
        form.celular.value = '';
        form.email.value = '';
        form.emailConfirm.value = '';
        form.idTipoContactoEntidad.value = '';
        form.puesto.value = '';
        form.cvePais.value = 'MX';
    }

    /**
     * Editamos un contacto seleccionado de la lista
     * @param {*} contacto 
     */
    const editContacto = (contacto) => {
        const clone = fillForm(form, contacto);
        clone.emailConfirm.value = clone.email.value;
        clone.emailConfirm.valid = true;
        clone.cvePais.value = contacto.idPaisLada2 ? contacto.idPaisLada2.cvePais : 'MX';
        clone.isNotificaCorreo.value = contacto.isNotificaCorreo ? "true" : "";
        setForm(clone);
        setContactoSelected(contacto);
    }

    /**
     * Cancelamos la edicion y reseteamos el form
     */
    const cancelEdit = () => {
        setError({
            show: false,
            message : ''
        })
        setContactoSelected(null);
        clearForm();
    }

    /**
     * Tips
     */
    const getMessages = async () => {
        const resp = await getMessageByModulo(appSt.serviceData.area, appSt.serviceData.token, 'REGISTROENTIDADINFORMACIONGENERAL', 'MENSAJEDATOSCONTACTO');
        if (resp.payload && resp.payload.valor) {
            setMessages({...messages, topMessage: resp.payload.valor})
        }
    }

    useEffect(() => {
        getMessages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (catalogosSt.parametros !== null && catalogosSt.parametros !== undefined) {
            catalogosSt.parametros.forEach(item => {
                if (item.nombreParametro === 'TOOLTIP_TELEFONO') {
                    form.telefono.tool = item.valor;
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogosSt.parametros])

    return (
        <div className="registro-step datos-contacto">
            {messages && messages.topMessage && 
                <div className="tip">
                    <InfoIcon />
                    <p>{messages.topMessage}</p>
                </div>            
            }

            <div className="two-col">

                <div className="card-registro">
                    <div className="head">
                        <ContactoIcon />
                        <h3>Contacto</h3>
                    </div>
                    {error.show && <p className="error-top">{error.message}</p>}
                    <div className="content">
                        <InputComp label="Nombre" name="nombre" type="upper" form={form} setForm={setForm} />
                        <InputComp label="Apellido Paterno" name="apellidoPaterno" type="upper" form={form} setForm={setForm} />
                        <InputComp label="Apellido Materno" name="apellidoMaterno" type="upper" form={form} setForm={setForm} />
                        <InputComp label="Puesto que ocupa en su empresa" name="puesto" type="upper" form={form} setForm={setForm} />
                        <div className="two-col">
                            {catalogosSt.pais &&
                                <InputComp label="País" name="cvePais" type="select" form={form} setForm={setForm} catalogos={catalogosSt.pais} catalogoValue={'cvePais'} catalogoLabel={'descPais'} />
                            }
                            {form.cvePais && <div className="dato">
                                <label>Lada</label>
                                <p>+{findCatalogById(form.cvePais.value, catalogosSt.pais, 'cvePais', 'cvePaisLada')}</p>                                
                            </div>}
                        </div>
                        <InputComp max="10" validation="phone" label="Teléfono" name="telefono" type="number" form={form} setForm={setForm} />
                        <InputComp max='10' validation="cel" label="Teléfono celular" name="celular" type="number" form={form} setForm={setForm} />
                        <InputComp label="Email" name="email" type="lower" form={form} setForm={setForm} />
                        <InputComp label="Confirmar Email" name="emailConfirm" type="text" form={form} setForm={setForm} />
                        {catalogosSt.tipoContacto &&
                            <InputComp label="Tipo Contacto" name="idTipoContactoEntidad" type="select" form={form} setForm={setForm} catalogos={catalogosSt.tipoContacto} catalogoValue={'idTipoContactoEntidad'} catalogoLabel={'descTipoContactoEntidad'} />
                        }
                        <InputComp label="Enviar Notificación por Correo" name="isNotificaCorreo" type="checkbox" form={form} setForm={setForm} />
                        {contactoSelected && <>
                            <button onClick={cancelEdit}>Cancelar Edición</button>
                            <button onClick={addContacto}>Actualizar</button>                        
                        </>}
                        {!contactoSelected && 
                            <button onClick={addContacto}>Agregar Contacto</button>                        
                        }
                    </div>
                </div>

                <div className="contactos-agregados">
                    <p>Contactos Agregados</p>
                    {!registroSt.empresaSeleccionada || (registroSt.empresaSeleccionada.cemContactoCrcEntidads && registroSt.empresaSeleccionada.cemContactoCrcEntidads.length === 0)
                        ? <div className="tip">
                            <InfoIcon />
                            <p>Agrega al menos un contacto</p>
                        </div>
                        : <ul>
                            {registroSt.empresaSeleccionada.cemContactoCrcEntidads && registroSt.empresaSeleccionada.cemContactoCrcEntidads.map((contacto, index) => {
                                return contacto.idEstatusRegistro !== 0 &&
                                        <li key={index}>
                                            <div>
                                                <span>{contacto.descTipoContactoEntidad}</span>
                                                <p>{contacto.nombre} {contacto.apellidoPaterno} {contacto.apellidoMaterno}</p>
                                            </div>
                                            <button onClick={() => editContacto(contacto)}><EditIcon /></button>
                                            <button onClick={() => confirmDelete(contacto.idContactoEntidad)}><DeleteIcon /></button>
                                        </li>
                                    
                            })}
                        </ul>
                    }
                </div>
            </div>
            {registroSt.empresaSeleccionada.idTipoEntidad2.isLucro !== 0 && ((consultoresCatalogo && consultoresCatalogo.length > 0) || (aliadosCatalogo && aliadosCatalogo.length > 0)) &&
                <div className="card-registro">
                    <div className="head">
                        <ContactoIcon />
                        <h3>Acompañamiento</h3>
                    </div>
                    <div className="tip">
                        <InfoIcon />
                        <p>Sí cuenta con apoyo o acompañamiento de un Aliado Regional de Cemefi o Consultor Acreditado de Cemefi, por favor indiquelo, IMPORTANTE: No es obligatorio para participar por el distintivo ESR</p>
                    </div>
                    <div className="content">
                        <div className="two-col">
                            {aliadosCatalogo && aliadosCatalogo.length > 0 &&
                                <div className="company">
                                    <div className="radio-wrapper">
                                        <label>¿Recibiste acompañamiento de un aliado regional?</label>
                                        <div className="radios">
                                            <div className="radio-input">
                                                <input checked={companionHelp} value='si' name="ayudo_aliado" type="radio" onChange={() => setCompanion(true)} />
                                                <div className="check"></div>
                                                <label>Sí</label>
                                            </div>
                                            <div className="radio-input">
                                                <input checked={!companionHelp} value='no' name="ayudo_aliado" type="radio" onChange={() => setCompanion(false)} />
                                                <div className="check"></div>
                                                <label>No</label>
                                            </div>
                                        </div>
                                    </div>

                                    {companionHelp &&
                                        <InputComp label="Aliado Regional" name="idAliadoRegional" type="select" form={aliadoForm} setForm={setAliadoForm} catalogos={aliadosCatalogo} catalogoValue={'idAlidadoRegional'} catalogoLabel={'nombre'} />
                                    }
                                </div>                            
                            }

                            {/* {consultoresCatalogo && consultoresCatalogo.length > 0 &&
                                <div className="company">
                                    <div className="radio-wrapper">
                                        <label>¿Recibiste acompañamiento de un consultor Acreditado?</label>
                                        <div className="radios">
                                            <div className="radio-input">
                                                <input checked={consultorHelp} value='si' name="ayudo_consultor" type="radio" onChange={() => setConsultor(true)} />
                                                <div className="check"></div>
                                                <label>Sí</label>
                                            </div>
                                            <div className="radio-input">
                                                <input checked={!consultorHelp} value='no' name="ayudo_consultor" type="radio" onChange={() => setConsultor(false)} />
                                                <div className="check"></div>
                                                <label>No</label>
                                            </div>
                                        </div>
                                    </div>
                                    {consultorHelp &&
                                        <InputComp label="Consultor Acreditado" name="idConsultorAcreditado" type="select" form={consultorForm} setForm={setConsultorForm} catalogos={consultoresCatalogo} catalogoValue={'idConsultorAcreditado'} catalogoLabel={'combine'} />
                                    }
                                </div>
                            } */}
                        </div>
                    </div>
                </div>      
            }


            <div className="actions">
                <button onClick={prevStep}>Regresar</button>
                <button onClick={nextStep}>Guardar</button>
            </div>

            <ConfirmModal data={modalData} cancel={closeModal} />
        </div>
    )
}